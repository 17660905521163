// ======================
// MigrateOpsGettingStartedInstructionsView
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { generatePath, useParams } from "react-router-dom";
import { Alert, AlertTitle, Avatar, Box, Button, Chip, Link, Stack, Typography, useTheme } from "@mui/material";
import { CodeCard } from "../../common/card/DarkCard";
import {
    getOperationRecipeIdString,
    getOperationRecipeLabel,
    useNavigateToNewOpBuilder,
    useNavigateToNewOpScreen,
    useNavigatetoRecipeReference,
} from "./MigrateOpsCommon";
import * as YAML from "yaml";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import React from "react";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import StringExample from "../../assets/migrateOps/examples/string-example.png";
import SourceHost from "../../assets/migrateOps/examples/source-host-example.png";
import SourceVolumes from "../../assets/migrateOps/examples/exclude-source-volumes.png";
import MigrateOpsEditor from "../../assets/migrateOps/examples/migrateops-editor.png";
import ConfigBuilder from "../../assets/migrateOps/examples/config-builder.png";
import NestedObject from "../../assets/migrateOps/examples/nested-object.png";
import NewOpPage from "../../assets/migrateOps/examples/new-op-page.png";

import { CollapsibleCard } from "../../common/collapsible/CollapsibleCard";
import { getRecipeConfig } from "./MigrateOpsDocsView";
import Grid from "@mui/material/Grid2";
import { LuExternalLink } from "react-icons/lu";
import { getProjectSubRouteTemplate, MIGRATE_OPS_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";

interface MigrateOpsGettingStartedInstructionsViewProps {}

export const MigrateOpsGettingStartedInstructionsView: React.FC<MigrateOpsGettingStartedInstructionsViewProps> = (p) => {
    const {} = p;
    const { recipeId, projectId } = useParams();
    const recipeConfig = getRecipeConfig(Number(recipeId));
    const theme = useTheme();
    const openHelpArticle = useOpenHelpArticle();
    const operationJson = `operations:
  - name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "${getOperationRecipeIdString(Number(recipeId))}"
    config:
      ${getOperationRecipeIdString(Number(recipeId)).toLowerCase()}:
        # Configuration Options
    `;

    const operationJsonWithConfig = {
        operations: [
            {
                name: "Your Operation Name",
                notes: "Your notes here",
                recipe: "RECIPE_NAME",
                config: {
                    system_name: "system name",
                },
            },
        ],
    };

    const excludeSourceVolumes = {
        operations: [
            {
                name: "Your Operation Name",
                notes: "Your notes here",
                recipe: "RECIPE_NAME",
                config: {
                    system_name: "system name",
                    migrateops_hyperv_compute: {
                        source: {
                            exclude_source_volumes: ["vol1", "vol2"],
                        },
                    },
                },
            },
        ],
    };

    const step1ClipboardText = "yaml-step-1";

    const goToEditor = useNavigateToNewOpScreen();
    const goToReference = useNavigatetoRecipeReference();
    const goToOpBuilder = () => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.BUILDER}`, {
            projectId,
        });
        window.open(p, "_blank");
    };
    const goToLearnYaml = () => {
        window.open("https://learnxinyminutes.com/docs/yaml/", "_blank");
    };

    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Getting Started with MigrateOps`} />
            <Typography variant={"h6"}>{recipeConfig.description}</Typography>
            <Box pt={1}>
                <Typography>
                    {`Welcome to MigrateOps, your data-mobility-as-code automation platform within Cirrus Data Cloud. 
                    This quick start guide will help you initiate your first MigrateOps operation using the 
                    ${recipeConfig.recipeLabel} recipe.`}
                </Typography>
            </Box>
            <Grid container pt={2} spacing={1}>
                <Grid>
                    <Stack direction={"row"} spacing={1}>
                        <Typography>{"Recipe:"}</Typography>
                        <Chip label={<Typography fontWeight={600}>{recipeConfig.recipeLabel}</Typography>} size={"small"} />
                    </Stack>
                </Grid>

                <Grid>
                    <Stack direction={"row"} spacing={1}>
                        <Typography>{"Recipe ID:"}</Typography>
                        <Chip label={<Typography fontWeight={600}>{recipeConfig.recipeKey}</Typography>} size={"small"} />
                    </Stack>
                </Grid>
            </Grid>
            <Stack pb={2} spacing={2} pt={2}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, color: "white", width: 26, height: 26 }}>
                        <Typography variant={"body1"} fontWeight={600}>
                            {"1"}
                        </Typography>
                    </Avatar>
                    <Typography variant={"h5"} fontWeight={600}>
                        {"Prepare Your MigrateOps Configuration File"}
                    </Typography>
                </Stack>
                <Typography>{`In a text editor of your choice, set up your YAML configuration.  Your configuration should look like:`}</Typography>
                <CodeCard>
                    <Box p={2}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <ClipboardText clipboardId={step1ClipboardText}>
                                <pre>{operationJson}</pre>
                            </ClipboardText>
                            <Box>
                                <ClipboardButton iconButton color={"neutral"} clipboardId={step1ClipboardText} />
                            </Box>
                        </Stack>
                    </Box>
                </CodeCard>
            </Stack>
            <Stack spacing={2} pb={2} pt={2}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, color: "white", width: 26, height: 26 }}>
                        <Typography variant={"body1"} fontWeight={600}>
                            {"2"}
                        </Typography>
                    </Avatar>
                    <Typography variant={"h5"} fontWeight={600}>
                        {"Customize your configuration based on your recipe"}
                    </Typography>
                </Stack>
                <Typography>
                    {`Using the YAML reference for the ${recipeConfig.recipeKey} recipe (linked below), 
                    set up your configuration by including the fields applicable to your migration scenario. 
                    Example configurations are also included in the recipe reference.`}
                </Typography>
                <Link variant={"h6"} onClick={() => goToReference(Number(recipeId))}>
                    {`🔗 MigrateOps Recipe Reference - ${recipeConfig.recipeLabel}`}
                </Link>

                <Box>
                    <Typography>
                        {`Here are a few examples on how to map fields from the reference onto your YAML configuration. 
                            These examples are not exhaustive and only cover specific sample fields. 
                            Refer to the recipe reference linked above for configuration fields specific to your ${recipeConfig.recipeLabel} recipe.`}
                    </Typography>
                    <CollapsibleCard
                        collapsibleElement={
                            <Box p={2} pt={0}>
                                <Typography>
                                    {`For basic data types like strings, numbers, or booleans, you can directly add them to your configuration:`}
                                </Typography>
                                <Box width={"fit-content"} sx={{ border: "1px dotted white" }} mt={1}>
                                    <img alt={"String type"} src={StringExample} height={100} width={"auto"} />
                                </Box>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"This is an example of a reference entry for a string field. The recipe you choose might look different."}
                                </Typography>
                                <Box pt={1} pb={1}>
                                    <Typography>
                                        {`Since the example reference above shows that the`}
                                        <Chip sx={{ marginLeft: 1, marginRight: 1, borderRadius: 1 }} size={"small"} label={<pre>region</pre>} />
                                        {`field is a string, you can add it to your configuration like so:`}
                                    </Typography>
                                </Box>
                                <CodeCard>
                                    <Box p={2}>
                                        <pre>
                                            {`operations:
  - name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "MIGRATEOPS_AZURE_COMPUTE"
    config:
      migrateops_azure_compute:
        region: "eastus"`}
                                        </pre>
                                    </Box>
                                </CodeCard>
                            </Box>
                        }
                        arrowPlacement={"top-right"}
                        sx={{ marginTop: 2 }}
                    >
                        <Box p={2}>
                            <Typography variant={"h6"}>{`Example : Setting simple fields`}</Typography>
                        </Box>
                    </CollapsibleCard>
                    <CollapsibleCard
                        arrowPlacement={"top-right"}
                        sx={{ marginTop: 2 }}
                        collapsibleElement={
                            <Box p={2} pt={0}>
                                <Box pb={1}>
                                    <Typography>{`Some fields within your config object will be objects themselves.`}</Typography>
                                </Box>
                                <Box width={"fit-content"} sx={{ border: "1px dotted white" }}>
                                    <img src={NestedObject} alt={"Nested Object Field"} height={400} width={"auto"} />
                                </Box>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"This is an example of an object field in a reference page. The recipe you choose might look different."}
                                </Typography>
                                <Box pt={1} pb={1}>
                                    <Typography>{`To indicate nested objects in YAML, indent the fields in the nested object as below:`}</Typography>
                                </Box>
                                <CodeCard>
                                    <Box p={2}>
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <pre>
                                                {`operations:
-   name: "Your Operation Name"
    notes: "Your notes here"
    recipe: "RECIPE_NAME"
    config:
      integration_id: 15
      migrateops_azure_compute:
        compute:
          vm_size: Standard_D2s_v3
          availability_zones: 
            - "1"
            - "2"
          vmname: "vm1"
          wait_for_dev: "true"
          `}
                                            </pre>
                                        </Stack>
                                    </Box>
                                </CodeCard>
                            </Box>
                        }
                    >
                        <Box p={2}>
                            <Typography variant={"h6"}>{`Example: Nested objects`}</Typography>
                        </Box>
                    </CollapsibleCard>
                    <CollapsibleCard
                        arrowPlacement={"top-right"}
                        sx={{ marginTop: 2 }}
                        collapsibleElement={
                            <Box p={2} pt={0}>
                                <Box pb={1}>
                                    <Typography>
                                        {`Some YAML fields are mutually exclusive. Only one field within the group can be used in a config: `}
                                    </Typography>
                                </Box>

                                <Box width={"fit-content"} sx={{ border: "1px dotted white" }}>
                                    <img alt={"Setting a oneof field to a YAML string"} src={SourceHost} height={300} width={"auto"} />
                                </Box>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"This is an example reference page. The recipe you choose might look different."}
                                </Typography>
                                <Box pb={1} pt={2}>
                                    <Typography>
                                        {`Since the reference states only one field between `}
                                        <Chip sx={{ marginLeft: 1, marginRight: 1, borderRadius: 1 }} size={"small"} label={<pre>system_id</pre>} />
                                        {` and `} <Chip sx={{ marginLeft: 1, marginRight: 1, borderRadius: 1 }} size={"small"} label={<pre>system_name</pre>} />{" "}
                                        {` can be specified at once, add only one of the fields in the YAML string format.`}
                                    </Typography>
                                </Box>
                                <CodeCard>
                                    <Box p={2}>
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <pre>
                                                {YAML.stringify(operationJsonWithConfig, {
                                                    indent: 2,
                                                    defaultKeyType: "PLAIN",
                                                    defaultStringType: "QUOTE_DOUBLE",
                                                })}
                                            </pre>
                                        </Stack>
                                    </Box>
                                </CodeCard>
                            </Box>
                        }
                    >
                        <Box p={2}>
                            <Typography variant={"h6"}>{`Example:  Mutually Exclusive Fields`}</Typography>
                        </Box>
                    </CollapsibleCard>
                </Box>
                <CollapsibleCard
                    collapsibleElement={
                        <Box p={2} pt={0}>
                            <Box pb={1}>{`Some fields are lists of a certain data type, which is indicated in the reference as an "array of [type]":`}</Box>
                            <Box width={"fit-content"} sx={{ border: "1px dotted white" }}>
                                <img alt={"Setting an array of strings"} src={SourceVolumes} height={300} width={"auto"} />
                            </Box>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {
                                    "This is an example reference in which the 'exclude_source_volumes' field is an array of strings. Your reference might look different."
                                }
                            </Typography>
                            <Box pb={1} pt={2}>
                                <Typography>
                                    {`The reference above dictates that if you want to exclude specific source volumes from the migration, add the `}{" "}
                                    <Chip sx={{ marginLeft: 1, marginRight: 1, borderRadius: 1 }} size={"small"} label={<pre>exclude_source_volumes</pre>} />
                                    {` field as an array of strings using YAML format as shown below:`}
                                </Typography>
                            </Box>

                            <CodeCard>
                                <Box p={2}>
                                    <Stack direction={"row"} justifyContent={"space-between"}>
                                        <pre>
                                            {YAML.stringify(excludeSourceVolumes, {
                                                indent: 2,
                                                defaultKeyType: "PLAIN",
                                                defaultStringType: "QUOTE_DOUBLE",
                                            })}
                                        </pre>
                                    </Stack>
                                </Box>
                            </CodeCard>
                        </Box>
                    }
                    arrowPlacement={"top-right"}
                >
                    <Box p={2}>
                        <Typography variant={"h6"}>{`Example: List fields`}</Typography>
                    </Box>
                </CollapsibleCard>
                <Box>
                    <Typography variant={"h6"}>{`Running Multiple Operations`}</Typography>
                    <Typography>
                        {`If you wish to run multiple operations from one config file, 
                        you can simply add another operation object to the array as shown below.`}
                    </Typography>
                    <CodeCard sx={{ mt: 2 }}>
                        <Box p={2}>
                            <pre>
                                {`operations:
  - name: "Operation #1"
    notes: "Operation #1 notes"
    recipe: ${recipeConfig.recipeKey}
    config:
      ${recipeConfig.recipeKey.toLowerCase()}:
        #your config here
  - name: "Operation #2"
    notes: "Operation #2 notes"
    recipe: ${recipeConfig.recipeKey}
    config: 
      ${recipeConfig.recipeKey.toLowerCase()}:
        #your config here
      `}
                            </pre>
                        </Box>
                    </CodeCard>
                </Box>
                <Box>
                    <Typography variant={"h6"}>{"Interactive Configuration Builder"}</Typography>
                    <Box pt={1} pb={1}>
                        <Typography>
                            {`To learn more about how recipe fields are mapped to the YAML syntax, you can explore our interactive configuration builder. 
                            This tool provides a live demonstration of how YAML fields get populated based on the recipe reference.`}
                        </Typography>
                    </Box>
                    <Box>
                        {/*<Box width={"fit-content"} mt={1} sx={{ border: "1px dotted white" }}>*/}
                        {/*    <img alt={"Configuration Builder"} src={ConfigBuilder} height={500} width={"auto"} />*/}
                        {/*</Box>*/}
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`Example of the MigrateOps Builder populated with some fields, with the live demonstration of the YAML config being built alongside it.`}
                        </Typography>
                    </Box>
                    <Box pt={2} pb={2}>
                        <Button startIcon={<LuExternalLink />} variant={"contained"} color={"secondary"} onClick={goToOpBuilder}>
                            {"Go To Builder"}
                        </Button>
                    </Box>
                    <Alert severity={"info"}>
                        <AlertTitle>{"Note About Builder Use"}</AlertTitle>
                        {`The configuration builder is designed primarily as a learning tool to help you understand YAML structure and field mapping. 
                        It is not intended for use at scale. 
                        For actual deployments and operations, you are recommended to manage your YAML configurations based 
                        on coding practices including the use of a YAML-aware text editor.`}
                    </Alert>
                </Box>
            </Stack>
            <Stack spacing={2} pb={2} pt={2}>
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main, color: "white", width: 26, height: 26 }}>
                        <Typography variant={"body1"} fontWeight={600}>
                            {"3"}
                        </Typography>
                    </Avatar>
                    <Typography variant={"h5"} fontWeight={600}>
                        {"Submit your configuration to Cirrus Data Cloud"}
                    </Typography>
                </Stack>
                <Typography>
                    {`Once you've prepared and customized your YAML configuration file, 
                    you're ready to submit it to Cirrus Data Cloud for execution.
                    Select "Start New Operation" below or from the main MigrateOps page, and upload or drag-and-drop your YAML file.  
                    Alternatively, you can also copy-and-paste your YAML file into the editor.
                    After reviewing the yaml configuration, select "Create Operations".`}
                </Typography>
                {/*<Box>*/}
                {/*    <Box width={"fit-content"} mt={1} sx={{ border: "1px dotted white" }}>*/}
                {/*        <img alt={"New MigrateOps Page"} src={NewOpPage} height={300} width={"auto"} />*/}
                {/*    </Box>*/}
                {/*    <Typography variant={"caption"} color={"textSecondary"}>*/}
                {/*        {"New MigrateOps Page"}*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                <Box>
                    <Button onClick={goToEditor} variant={"contained"} color={"secondary"}>
                        {"Start New Operation"}
                    </Button>
                </Box>
            </Stack>
            <Stack spacing={2} pb={2} pt={2}>
                <Box>
                    <Typography variant={"h5"} fontWeight={600}>
                        {"What happens next?"}
                    </Typography>
                    <Box pt={2}>
                        <Typography>{`After submission, Cirrus Data Cloud will:`}</Typography>
                        <ul>
                            <li>{`Validate your configuration`}</li>
                            <li>{`Initialize the necessary resources`}</li>
                            <li>{`Begin executing the migration operation`}</li>
                        </ul>

                        <Typography>
                            {`You can monitor the progress of your operation in Cirrus Data Cloud. 
                                Unless there are steps that require user approval, 
                                all necessary operation steps are completely automated and they will 
                                be executed according to your configuration.`}
                        </Typography>
                        <Box pt={1}>
                            <Typography>
                                {`To learn more about MigrateOps, please refer to our `}
                                <Link>{"Knowledge Base Article"}</Link>
                                {`.`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Stack>
        </ScreenContainer>
    );
};
