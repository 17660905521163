import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Typography } from "@mui/material";

export const getVolQosStyles = () => ({
    noDataCard: {
        width: "100%",
        height: "100%",
    },
    chartContainer: {
        height: 220,
        width: "100%",
        paddingBottom: 4,
    },
    overline: {
        lineHeight: 1,
    },
    timelineDate: {
        display: "flex",
        alignItems: "center",
        flex: 0.05,
        paddingLeft: 0,
    },
    timelineMessage: {
        paddingRight: 0,
    },
});

// ======================
// NoDataCard
// ======================

interface NoDataCardProps {
    label?: string;
}

export const NoDataCard: React.FC<NoDataCardProps> = observer((props) => {
    const styles = getVolQosStyles();

    return (
        <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Card sx={styles.noDataCard}>
                <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"} p={2}>
                    <Typography variant={"h6"}>{props.label ? props.label : "No Data"}</Typography>
                </Box>
            </Card>
        </Box>
    );
});
