// ======================
// SessionActionsButton
// ======================
import {
    ActionTriggeredState,
    CancelIcon,
    CompleteIcon,
    CutoverIcon,
    RestartIcon,
    SuspendSynchronizeIcon,
    SynchronizeIcon,
    useCompleteMigrationSession,
    useCutoverMigrationSession,
    useDeleteMigrationSession,
    useFinalizeCutoverMigrationSession,
    useNavigateToSessionEditScreen,
} from "../CmcMigrationCommon";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import { isDeploymentGteVersion } from "../../deployment/DeploymentCommon";
import { ActionConfig, ActionMenuButton } from "../../../common/actions/CommonActions";
import { DeleteIcon, EditIcon, ReportIcon } from "../../../common/CommonIcons";
import { GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { Alert, Box, Button, SvgIcon, Typography } from "@mui/material";
import { KnownArticle } from "../../help/HelpCommon";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { BsGearFill } from "react-icons/bs";
import { GalaxyMigrateMigrationService } from "../GmMigrationService";
import { DialogService, useDialogState } from "../../core/dialog/DialogService";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { useOpenHelpArticle } from "../../help/hooks/help_hooks";
import { viewReport } from "../../reports/ReportHelpers";
import { CreateMigrationSessionReport } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { useCreateMigrationSessionReport } from "../../reports/report_hooks";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { CreateReportDialog } from "../../reports/CreateReportDialog";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import { useSyncMigrationSession } from "../cmc_migration_hooks";
import { LuTestTube2 } from "react-icons/lu";
import * as xbytes from "xbytes";

interface SessionActionsButtonProps {
    actionTriggeredState: ActionTriggeredState;
}

export const GmMigrationSessionActionsButton: React.FC<SessionActionsButtonProps> = observer((p) => {
    const { gmMigrationService, dialogService, deploymentService } = useAppServices();
    const globalDialogState = useGlobalDialogState();
    const navigateToEditSession = useNavigateToSessionEditScreen();
    const createReportDialogState = useDialogState();
    const openHelpArticle = useOpenHelpArticle();
    const syncMigrationSession = useSyncMigrationSession();

    if (!gmMigrationService.currentSession.ready) {
        return null;
    }

    const currentSession = gmMigrationService.currentSession.data;
    const sessionInfo = currentSession.getSessionInfo();
    const status = sessionInfo.getSessionStatus();
    const sessionId = sessionInfo.getSessionId();
    const bootVolumeSession = sessionInfo.getBootVolumeSession();
    const changeTrackingOnlySession = sessionInfo.getChangedDataTrackingOnly();
    const computeMigration = sessionInfo.getIsComputeMigration();
    const totalDataRemaining = sessionInfo.getSessionStats().getTotalRemaining();
    const isMockMigration = sessionInfo.getMockMigrationSession();

    const deleteSession = useDeleteMigrationSession();
    const completeMigrationSession = useCompleteMigrationSession();
    const cutoverCmotionSession = useCutoverMigrationSession();
    const finalizeCutoverSession = useFinalizeCutoverMigrationSession();
    const minVer = "4.1.0";
    const reportsMinVer = "5.1.0";
    const isReportsMinVer = isDeploymentGteVersion(deploymentService.currentDeployment.data, reportsMinVer);
    const consistencyGroupMinVer = "7.0.0";
    const isConsistencyGroupMinVer = isDeploymentGteVersion(deploymentService.currentDeployment.data, consistencyGroupMinVer);

    const isAnyVolumeOver25GiB = !!sessionInfo.getSessionVolumesList().find((v) => v.getVolumeStats().getTotalRemaining() > xbytes.parseSize("25GiB"));

    const getIsDeploymentMinVer = () => {
        return isDeploymentGteVersion(deploymentService.currentDeployment.data, minVer);
    };

    const createReport = useCreateMigrationSessionReport({
        onSuccess: async (data: CreateMigrationSessionReport.Response.AsObject) => {
            await globalDialogState.addConfirmDialog({
                title: "New Report Generated",
                message: <>{"Migration session report has been generated successfully."}</>,
                onClose: (confirmed) => {
                    if (confirmed) {
                        viewReport(data.reportId);
                    }
                },
                okButtonLabel: "View Report",
                cancelButtonLabel: "Close",
                autoConfirmationQuestionLine: false,
            });
        },
    });

    const sessionActions: ActionConfig[] = [
        {
            id: "edit",
            name: "Edit Session",
            action: () => navigateToEditSession(),
            icon: <EditIcon />,
        },
        {
            id: "report",
            name: "Generate Report",
            action: () => createReportDialogState.open(),
            icon: <ReportIcon />,
            hidden: !isReportsMinVer,
        },
        {
            id: "sync",
            name: "Trigger Synchronization",
            icon: <SynchronizeIcon />,
            action: async () => {
                await gmMigrationService.syncSession(sessionId);
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            disabled:
                [
                    GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN,
                    GalaxyMigrateMigrationSessionInfo.Status.TRACKING,
                    GalaxyMigrateMigrationSessionInfo.Status.SUSPENDED,
                    GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR,
                ].includes(status) === false,
        },
        {
            id: "consistencyGroup",
            name: "Trigger Synchronization for Testing",
            icon: (
                <SvgIcon>
                    <LuTestTube2 />
                </SvgIcon>
            ),
            action: async () => {
                if (!isAnyVolumeOver25GiB) {
                    const confirmed = await dialogService.addConfirmDialog({
                        title: "Trigger Synchronization for Testing",
                        message: (
                            <Box pb={2}>
                                <Typography>
                                    {`A new synchronization will be triggered and remain continuously synchronized until all volumes are ready to return 
                                to change-tracking state. 
                                The destination data synchronized will be a consistent data image across all volumes within the session.`}
                                </Typography>
                                <br />
                                <Typography>
                                    {`Due to the nature of the continuous synchronization, there may be performance implications to be considered, 
                                especially if the destination storage's bandwidth and performance is slower than the source production storage. 
                                Therefore, you are recommended to only perform this action when  a consistent data image is needed temporarily 
                                (e.g. for`}
                                    <b>{` pre-cutover`}</b> {`destination snapshot testing). Data synchronization during cutover is`}
                                    <b>{` always`}</b> {`consistent across volumes.`}
                                </Typography>
                            </Box>
                        ),
                    });
                    if (confirmed) {
                        await syncMigrationSession.mutateAsync({
                            sessionId: sessionId,
                            consistencyGroup: true,
                        });
                    }
                } else {
                    await dialogService.addConfirmDialog({
                        title: "Unable To Trigger Synchronization for Testing",
                        message: (
                            <Box pb={2}>
                                <Typography>
                                    {`A new synchronization will be triggered and remain continuously synchronized until all volumes are ready to return 
                                to change-tracking state. 
                                The destination data synchronized will be a consistent data image across all volumes within the session.`}
                                </Typography>
                                <br />
                                <Typography>
                                    {`Due to the nature of the continuous synchronization, there may be performance implications to be considered, 
                                especially if the destination storage's bandwidth and performance is slower than the source production storage. 
                                Therefore, you are recommended to only perform this action when  a consistent data image is needed temporarily 
                                (e.g. for`}
                                    <b>{` pre-cutover`}</b> {`destination snapshot testing). Data synchronization during cutover is`}
                                    <b>{` always`}</b> {`consistent across volumes.`}
                                </Typography>
                                <br />
                                <Alert severity={"error"}>
                                    {`Your migration session has ${formatKnownDataType(totalDataRemaining, KnownDataType.CAPACITY)} of un-synchronized data. 
                                To ensure predictable consistency group synchronization behavior, please trigger a regular migration session synchronization first.`}
                                </Alert>
                            </Box>
                        ),
                        autoConfirmationQuestionLine: false,
                        okButtonLabel: "Trigger Regular Sync",
                        okButtonProps: {
                            variant: "outlined",
                        },
                        cancelButtonLabel: "Close",
                        cancelButtonProps: {
                            color: "primary",
                        },
                        onClose: async (confirmed: boolean) => {
                            if (confirmed) {
                                await syncMigrationSession.mutateAsync({
                                    sessionId: sessionId,
                                });
                            }
                        },
                    });
                }
            },
            hidden: !isConsistencyGroupMinVer,
            disabled:
                [
                    GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN,
                    GalaxyMigrateMigrationSessionInfo.Status.TRACKING,
                    GalaxyMigrateMigrationSessionInfo.Status.SUSPENDED,
                    GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR,
                ].includes(status) === false,
        },
        {
            id: "suspend",
            name: "Suspend Synchronization",
            icon: <SuspendSynchronizeIcon />,
            action: async () => {
                await gmMigrationService.suspendSession(sessionId);
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: status !== GalaxyMigrateMigrationSessionInfo.Status.SYNCING,
        },
        {
            id: "cutover-change-tracking",
            name: "Complete Change-Tracking",
            icon: <CutoverIcon />,
            action: async () => {
                const reviewMigrationGuideButton = (
                    <Button variant={"outlined"} color={"neutral"} size={"small"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_CLUSTER_VOLUMES)}>
                        {"Review Cluster Migration Guide"}
                    </Button>
                );

                const valid = await validateNotExceededMaxOutstandingIOForCompleteChangeTracking(
                    gmMigrationService,
                    dialogService,
                    sessionId,
                    totalDataRemaining,
                    reviewMigrationGuideButton
                );
                if (!valid) {
                    return;
                }

                const header = "Warning! Do NOT proceed if you still have active workload on this cluster node.";
                let message = `Before continuing, please ensure that all your workloads have already been failed-over to other cluster nodes.`;

                let confirmed = await dialogService.addConfirmDialog({
                    message: header,
                    renderAdditionalContent: () => {
                        return (
                            <>
                                <p>{message}</p>
                                <p>
                                    {
                                        "Once change-tracking is completed on this node, new changes to the volumes will NOT be migrated. Failure to do so may result in data integrity issues."
                                    }
                                </p>
                                <p>
                                    {
                                        "By continuing, you acknowledge that you have read and understand our cluster migration guide and possess proper cluster configuration knowledge for this particular host."
                                    }
                                </p>
                                <p>{reviewMigrationGuideButton}</p>
                            </>
                        );
                    },
                });
                if (confirmed) {
                    confirmed = await dialogService.addConfirmDialog({
                        message: "A final sync will be performed and once synchronized, change-tracking will end. This process is IRREVERSIBLE. ",
                    });
                }
                if (confirmed) {
                    await gmMigrationService.cutoverSession(sessionId);
                }
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: !changeTrackingOnlySession,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.TRACKING].includes(status) === false,
        },
        {
            id: "cutover-boot",
            name: "Perform Final Cutover",
            icon: <CutoverIcon />,
            action: async () => {
                const reviewMigrationGuideButton = (
                    <Button variant={"outlined"} color={"neutral"} size={"small"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES)}>
                        {"Review Boot Volume Migration Guide"}
                    </Button>
                );

                const valid = await validateNotExceededMaxOutstandingIOForBootCutover(
                    gmMigrationService,
                    dialogService,
                    sessionId,
                    totalDataRemaining,
                    reviewMigrationGuideButton
                );
                if (!valid) {
                    return;
                }

                const header = "Warning! Do NOT proceed unless you are ready to cutover your boot volumes and already stopped your applications.";
                let message = `Before continuing, please ensure that all your applications are stopped and all filesystem caches are flushed. New changes to the volumes in this session AFTER final cutover will not be migrated.`;

                let confirmed = await dialogService.addConfirmDialog({
                    message: header,
                    renderAdditionalContent: () => {
                        return (
                            <>
                                <p>{message}</p>
                                <p>
                                    {
                                        "Once cutover is completed you should immediately shutdown your system, remove your source volume and boot into the new volume"
                                    }
                                </p>
                                <p>
                                    {
                                        "By continuing, you acknowledge that you have read and understand our boot volume migration guide and possess proper boot volume configuration knowledge for this particular host."
                                    }
                                </p>
                                <p>{reviewMigrationGuideButton}</p>
                            </>
                        );
                    },
                });
                if (confirmed) {
                    confirmed = await dialogService.addConfirmDialog({
                        message: "A final sync will be performed and cutover process will ensue. This process is IRREVERSIBLE. ",
                    });
                }
                if (confirmed) {
                    await gmMigrationService.cutoverSession(sessionId);
                }
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: bootVolumeSession === false || computeMigration || changeTrackingOnlySession || isMockMigration,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.TRACKING].includes(status) === false,
        },
        {
            id: "cutover-vmware",
            name: "Cutover Host to New VMware VM",
            icon: <CutoverIcon />,
            action: async () => {
                const reviewMigrationGuideButton = (
                    <Button variant={"outlined"} color={"neutral"} size={"small"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES)}>
                        {"Review VMware Compute Migration Guide"}
                    </Button>
                );

                const valid = await validateNotExceededMaxOutstandingIOForBootCutover(
                    gmMigrationService,
                    dialogService,
                    sessionId,
                    totalDataRemaining,
                    reviewMigrationGuideButton
                );
                if (!valid) {
                    return;
                }

                const header =
                    "Warning! Do NOT proceed unless you are ready to cutover your host to new VMware environment and already stopped your applications.";
                let message = `Before continuing, please ensure that all your applications are stopped and all filesystem caches are flushed. New changes to the volumes in this session AFTER final cutover will not be migrated.`;

                let confirmed = await dialogService.addConfirmDialog({
                    message: header,
                    renderAdditionalContent: () => {
                        return (
                            <>
                                <p>{message}</p>
                                <p>
                                    {
                                        "During this operation,final host migration preparation steps will be run, and new virtual machine will be created according to the specification specified in this migration session."
                                    }
                                </p>
                                <p>{"Upon completion, new VM will be powered on and current host will be powered off."}</p>
                                <p>{"By continuing, you acknowledge that you have read and understand our VMware migration guide."}</p>
                                <p>{reviewMigrationGuideButton}</p>
                            </>
                        );
                    },
                });
                if (confirmed) {
                    confirmed = await dialogService.addConfirmDialog({
                        message: "Host cutover process is about to start. Upon completion, new VM will be POWERED ON and current host will be POWERED OFF.",
                    });
                }
                if (confirmed) {
                    await gmMigrationService.cutoverSession(sessionId);
                }
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: sessionInfo.getComputeMigrationType() !== DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.TRACKING].includes(status) === false,
        },
        {
            id: "cutover-azure",
            name: "Cutover Host to New Azure VM",
            icon: <CutoverIcon />,
            action: async () => {
                const reviewMigrationGuideButton = (
                    <Button variant={"outlined"} color={"neutral"} size={"small"} onClick={() => openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES)}>
                        {"Review Compute Migration Guide"}
                    </Button>
                );

                const valid = await validateNotExceededMaxOutstandingIOForBootCutover(
                    gmMigrationService,
                    dialogService,
                    sessionId,
                    totalDataRemaining,
                    reviewMigrationGuideButton
                );
                if (!valid) {
                    return;
                }

                const header =
                    "Warning! Do NOT proceed unless you are ready to cutover your host to your new Azure environment and already stopped your applications.";
                let message = `Before continuing, please ensure that all your applications are stopped and all filesystem caches are flushed. New changes to the volumes in this session AFTER final cutover will not be migrated.`;

                let confirmed = await dialogService.addConfirmDialog({
                    message: header,
                    renderAdditionalContent: () => {
                        return (
                            <>
                                <p>{message}</p>
                                <p>
                                    {
                                        "During this operation,final host migration preparation steps will be run, and new virtual machine will be created according to the specification specified in this migration session."
                                    }
                                </p>
                                <p>{"Upon completion, new VM will be powered on and current host will be powered off."}</p>
                                <p>{"By continuing, you acknowledge that you have read and understand our compute migration guide."}</p>
                                <p>{reviewMigrationGuideButton}</p>
                            </>
                        );
                    },
                });
                if (confirmed) {
                    confirmed = await dialogService.addConfirmDialog({
                        message: "Host cutover process is about to start. Upon completion, new VM will be POWERED ON and current host will be POWERED OFF.",
                    });
                }
                if (confirmed) {
                    await gmMigrationService.cutoverSession(sessionId);
                }
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: sessionInfo.getComputeMigrationType() !== DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.TRACKING].includes(status) === false,
        },
        {
            id: "cutover",
            name: "Trigger cMotion™",
            action: async () => {
                p.actionTriggeredState.setActionTriggered(true);
                await cutoverCmotionSession();
            },
            hidden: bootVolumeSession || changeTrackingOnlySession || isMockMigration,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.TRACKING].includes(status) === false,
        },
        {
            id: "revertCutover",
            name: "Revert cMotion™",
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    message: `By continuing, the volume will synchronize and revert cutover process. Production I/O will go back to source storage. All previous application remedies made must immediately be reconfigured so that it will access original source storage automatically on next reboot.`,
                });
                if (confirmed) {
                    await gmMigrationService.revertCutoverSession(sessionId);
                }
                await gmMigrationService.currentSession.fetchData(sessionId);
            },
            hidden: bootVolumeSession || changeTrackingOnlySession || isMockMigration,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN].includes(status) === false,
        },
        {
            id: "finalCutover",
            name: "Finalize Cutover",
            icon: <CutoverIcon />,
            action: async () => {
                p.actionTriggeredState.setActionTriggered(true);
                await finalizeCutoverSession();
            },
            hidden: bootVolumeSession || changeTrackingOnlySession || isMockMigration,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN].includes(status) === false,
        },
        {
            id: "cancelSession",
            name: "Cancel Session",
            icon: <CancelIcon />,
            action: async () => {
                const confirmed = await dialogService.addConfirmDialog({
                    message:
                        "WARNING! Once a migration session is canceled, all data tracking and migration activities will END.  Migration will start from the beginning when a canceled session is restarted.",
                });
                if (confirmed) {
                    await gmMigrationService.cancelSession(sessionId);
                    await gmMigrationService.currentSession.fetchData();
                }
            },
            hidden: getIsDeploymentMinVer()
                ? [
                      GalaxyMigrateMigrationSessionInfo.Status.TRACKING,
                      GalaxyMigrateMigrationSessionInfo.Status.IN_ERROR,
                      GalaxyMigrateMigrationSessionInfo.Status.SUSPENDED,
                      GalaxyMigrateMigrationSessionInfo.Status.SYNCING,
                  ].includes(status) === false
                : true,
        },
        {
            id: "completeSession",
            name: "Complete Session",
            icon: <CompleteIcon />,
            action: async () => {
                p.actionTriggeredState.setActionTriggered(true);
                await completeMigrationSession();
            },
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.CUTOVER].includes(status) === false, // only enable if in cutover state,
            hidden:
                isMockMigration || getIsDeploymentMinVer()
                    ? [GalaxyMigrateMigrationSessionInfo.Status.CUTOVER, GalaxyMigrateMigrationSessionInfo.Status.COMPLETED].includes(status) === false
                    : true, // show only if in cutover or already completed state
        },
        {
            id: "restart",
            name: "Restart Session",
            icon: <RestartIcon />,
            action: async () => {
                await gmMigrationService.restartSession(sessionId);
                await gmMigrationService.currentSession.fetchData();
            },
            hidden:
                isMockMigration || getIsDeploymentMinVer()
                    ? [GalaxyMigrateMigrationSessionInfo.Status.FAILED, GalaxyMigrateMigrationSessionInfo.Status.CANCELLED].includes(status) === false
                    : true,
        },
        {
            id: "delete",
            name: "Delete Session",
            action: deleteSession,
            disabled: [GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN].includes(status),
            icon: <DeleteIcon />,
        },
    ];

    return (
        <OperatorView>
            <ActionMenuButton
                actions={sessionActions}
                buttonLabel={"Session Actions"}
                buttonProps={{
                    endIcon: (
                        <SvgIcon>
                            <BsGearFill />
                        </SvgIcon>
                    ),
                    color: "secondary",
                    variant: "contained",
                }}
            />
            {createReportDialogState.isOpen && (
                <CreateReportDialog
                    reportType={ReportType.ReportType.MIGRATION_SESSION_REPORT}
                    reportArg={sessionId}
                    dialogState={createReportDialogState}
                    createReportFn={async (args: { reportArg: string; reportNotes: string }) =>
                        createReport.mutateAsync(new CreateMigrationSessionReport.Request().setSessionId(args.reportArg).setUserNotes(args.reportNotes))
                    }
                />
            )}
        </OperatorView>
    );
});

const validateNotExceededMaxOutstandingIOForBootCutover = async (
    gmMigrationService: GalaxyMigrateMigrationService,
    dialogService: DialogService,
    sessionId: string,
    totalDataRemaining: number,
    reviewMigrationGuideButton: React.ReactNode
) => {
    const MAX_OUTSTANDING_DATA_BEFORE_CUTOVER = 1 * 1000 * 1000 * 1000;
    if (totalDataRemaining > MAX_OUTSTANDING_DATA_BEFORE_CUTOVER) {
        const warningHeader = `Synchronization Required for Boot Volume Cutover`;
        const warningMsg = `This session currently has ${formatKnownDataType(
            totalDataRemaining,
            KnownDataType.CAPACITY
        )} unsynchronized data. In order to have a predictable cutover schedule to boot into the new volume, please synchronize your volumes first and try again when the amount of remaining data is less than ${formatKnownDataType(
            MAX_OUTSTANDING_DATA_BEFORE_CUTOVER,
            KnownDataType.CAPACITY
        )}.`;
        await dialogService.addAlertDialog({
            title: warningHeader,
            message: warningMsg,
            renderAdditionalContent: (closeFn) => {
                const triggerSyncButton = (
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        onClick={async () => {
                            await gmMigrationService.syncSession(sessionId);
                            await gmMigrationService.fetchSessionDetails(sessionId);
                            closeFn();
                        }}
                    >
                        {"Trigger Synchronization Now"}
                    </Button>
                );
                return (
                    <>
                        <p>{reviewMigrationGuideButton}</p>
                        <p>{triggerSyncButton}</p>
                    </>
                );
            },
        });
        return false;
    }
    return true;
};
const validateNotExceededMaxOutstandingIOForCompleteChangeTracking = async (
    gmMigrationService: GalaxyMigrateMigrationService,
    dialogService: DialogService,
    sessionId: string,
    totalDataRemaining: number,
    reviewMigrationGuideButton: React.ReactNode
) => {
    const MAX_OUTSTANDING_DATA_BEFORE_CUTOVER = 1 * 1000 * 1000 * 1000;
    if (totalDataRemaining > MAX_OUTSTANDING_DATA_BEFORE_CUTOVER) {
        const warningHeader = `Synchronization Required before Completing Change-Tracking`;
        const warningMsg = `This session currently has ${formatKnownDataType(
            totalDataRemaining,
            KnownDataType.CAPACITY
        )} unsynchronized data. In order to have a predictable completion time, please synchronize your volumes first and try again when the amount of remaining data is less than ${formatKnownDataType(
            MAX_OUTSTANDING_DATA_BEFORE_CUTOVER,
            KnownDataType.CAPACITY
        )}.`;
        await dialogService.addAlertDialog({
            title: warningHeader,
            message: warningMsg,
            renderAdditionalContent: (closeFn) => {
                const triggerSyncButton = (
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        size={"small"}
                        onClick={async () => {
                            await gmMigrationService.syncSession(sessionId);
                            await gmMigrationService.fetchSessionDetails(sessionId);
                            closeFn();
                        }}
                    >
                        {"Trigger Synchronization Now"}
                    </Button>
                );
                return (
                    <>
                        <p>{reviewMigrationGuideButton}</p>
                        <p>{triggerSyncButton}</p>
                    </>
                );
            },
        });
        return false;
    }
    return true;
};
