import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import { renderServerDataWithLoadingBox, useInitData } from "../../core/data/DataLoaderHooks";
import { Box, Typography } from "@mui/material";
import * as React from "react";
import { VmwareVmConfigForm } from "../wizard/computeMigration/GmMigrationWizardVmware";
import { useParams } from "react-router-dom";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";

import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { useState } from "react";
import { useMountEffect } from "../../../common/hooks/hookslib";

// ======================
// GmSessionDetailsEditVmScreen
// ======================

interface GmSessionDetailsEditVmScreenProps {}

export const GmSessionDetailsEditVmScreen: React.FC<GmSessionDetailsEditVmScreenProps> = observer((p) => {
    const { gmDeploymentService, gmMigrationService } = useAppServices();
    const { sessionId } = useParams();
    const [computeMigrationType, setComputeMigrationType] = useState<DeploymentHostEnvironment.DeploymentHostEnvironment>(null);

    const init = async () => {
        if (!gmMigrationService.currentSessionID) {
            gmMigrationService.setCurrentSessionID(sessionId);
        }
        await gmMigrationService.currentSession.fetchData(sessionId);
        const computeMigrationType = gmMigrationService.currentSession.data?.getSessionInfo().getComputeMigrationType();
        const spec = gmMigrationService.currentSession.data?.getSessionInfo().getComputeMigrationSpec();
        if (computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE) {
            gmMigrationService.wizardState.selectRemoteDestination(
                gmMigrationService.currentSession.data?.getSessionInfo().getDestinationDeployment().getSystemId()
            );
            gmMigrationService.wizardState.initVmwareState();
            gmMigrationService.wizardState.vmwareState.setVmSpecFromSessionDetails(spec);
            setComputeMigrationType(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE);
        }
    };

    useMountEffect(() => {
        init();
    });
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Edit Destination VM Configuration`} />
            <Typography variant={"body1"}>Please provide the following information for the configuration</Typography>
            <br />
            <Box>
                {computeMigrationType === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE && (
                    <VmwareVmConfigForm wizardState={gmMigrationService.wizardState} type={"edit"} />
                )}
            </Box>
        </ScreenContainer>
    );
});
