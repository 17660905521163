// Project: GalaxyComplete
// Created: 11/11/20 by sammy
// File: GalaxyMigrateDeploymentSettings

import * as React from "react";
import { observer } from "mobx-react-lite";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { Box, Button, Card, CardHeader, ListSubheader, SvgIcon, Theme, Typography } from "@mui/material";
import { GmDeploymentOnlineUpgradeSettingArea } from "./GalaxyMigrateDeploymentOnlineUpgrade";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { MdVpnKey } from "react-icons/md";
import { useAppServices } from "../../app/services";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { useNavigateToProjectLicenseDetailsScreen } from "../../management/ProjectManagementCommon";
import { useIsFeatureEnabled } from "../../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../../app/AppGlobalService";

// ======================
// GmDeploymentDetailsSettings
// ======================

interface GmDeploymentDetailsSettingsProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GmDeploymentDetailsSettings: React.FC<GmDeploymentDetailsSettingsProps> = observer((props) => {
    const { deployment } = props;
    const isFeatureEnabled = useIsFeatureEnabled(FeatureFlag.CMC_SOFTWARE_UPGRADE);

    return (
        <OperatorView>
            <Box pb={6}>
                <Card>
                    <ListSubheader>{"Settings"}</ListSubheader>
                    {deployment.getInfo().getDeployment().getConnected() && isFeatureEnabled && <GmDeploymentOnlineUpgradeSettingArea />}
                    <HostLicenseArea deployment={deployment} />
                </Card>
            </Box>
        </OperatorView>
    );
});

// ======================
// HostLicenseArea
// ======================

interface HostLicenseAreaProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const HostLicenseArea: React.FC<HostLicenseAreaProps> = observer((p) => {
    const { dialogService, gmDeploymentService, deploymentService, licenseService } = useAppServices();

    const goToLicenseDetails = useNavigateToProjectLicenseDetailsScreen();
    const getButtonDisplay = () => {
        if (p.deployment.getInfo().getDeployment().getLicense()?.hasActivatedAt()) {
            return (
                <Button
                    variant={"outlined"}
                    color={"success"}
                    disableRipple
                    sx={{
                        cursor: "default",
                        borderColor: (t: Theme) => t.palette.success.main,
                        "&:hover": {
                            backgroundColor: "rgba(0,0,0,0)",
                        },
                    }}
                >
                    License Activated
                </Button>
            );
        }

        const handleActivate = async () => {
            await licenseService.projectLicenseDetails.fetchData();
            const currentBalance = licenseService.projectLicenseDetails.data?.itemsList.find(
                (v) => v.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_UNLIMITED_MIGRATION
            )?.currentBalance;
            if (currentBalance > 0) {
                const confirmed = await dialogService.addConfirmDialog({
                    renderAdditionalContent: () => (
                        <Box>
                            <Typography>Host Migration License Remaining: {currentBalance}</Typography>
                            <Typography sx={{ color: (t: Theme) => t.palette.warning.dark }}>Note: this will be deducted from project license key</Typography>
                            <br />
                            <Typography>Are you sure you want to activate Host Migration License?</Typography>
                        </Box>
                    ),
                    autoConfirmationQuestionLine: false,
                    okButtonLabel: "Confirm License Activation",
                });
                if (confirmed) {
                    await gmDeploymentService.assignUnlimitedLicense(p.deployment.getInfo().getDeployment().getSystemId());
                    await deploymentService.currentDeployment.fetchData();
                }
            } else {
                await dialogService.addAlertDialog({
                    title: "License cannot be activated",
                    message: "No remaining host migration licenses available.",
                    renderAdditionalContent: () => (
                        <Box display={"flex"} justifyContent={"center"} pt={4}>
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    goToLicenseDetails();
                                    dialogService.clearAlertDialogs();
                                }}
                            >
                                view project license details
                            </Button>
                        </Box>
                    ),
                    okButtonLabel: "close",
                });
            }
        };

        return (
            <Button variant={"outlined"} onClick={handleActivate}>
                Activate License
            </Button>
        );
    };

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    avatar={
                        <SvgIcon>
                            <MdVpnKey />
                        </SvgIcon>
                    }
                    title={"Activate Host Migrate License"}
                    subheader={"Host Migration License will allow unlimited migration for this host when activated"}
                    action={getButtonDisplay()}
                />
            </Card>
        </Box>
    );
});
