/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DialogService
 */
import { makeAutoObservable, remove } from "mobx";
import { v4 as uuid } from "uuid";
import * as React from "react";
import { useIsDesktop } from "../../layout/MainLayout";
import { useCallback, useState } from "react";
import { ButtonProps } from "@mui/material";
import { formatErrorMessage } from "../../../common/utils/errorUtil";
import create from "zustand";

export class DialogService {
    alertDialogs: AlertDialogConfig[] = [];
    confirmDialogs: ConfirmDialogConfig[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    closeAllAlertDialogs() {
        this.alertDialogs = [];
    }

    async addConfirmDialog(config: ConfirmDialogConfig) {
        const id = uuid();

        let dialogConfig: ConfirmDialogConfig = null;
        const confirmed: boolean = await new Promise((resolve) => {
            dialogConfig = {
                id,
                autoConfirmationQuestionLine: true,
                ...config,
                onClose: (confirmed: boolean) => {
                    resolve(confirmed);
                    if (!!config.onClose) {
                        config.onClose(confirmed);
                    }
                },
            };
            this.confirmDialogs.push(dialogConfig);
        });
        this.confirmDialogs.splice(
            this.confirmDialogs.findIndex((a) => a.id === id),
            1
        );
        return confirmed;
    }

    async addAlertDialog(config: AlertDialogConfig) {
        const id = uuid();

        let dialogConfig = null;
        const ret = await new Promise((resolve) => {
            dialogConfig = {
                id,
                ...config,
                onClose: (a: any) => resolve(a),
            };
            this.alertDialogs.push(dialogConfig);
        });
        this.alertDialogs.splice(
            this.alertDialogs.findIndex((a) => a.id === id),
            1
        );
        return ret;
    }

    async addErrorAlertDialog(e: Error, waitTillClose = true) {
        let errorMsg = e.toString();
        if (e.message) {
            errorMsg = e.message;
        }
        const promise = this.addAlertDialog({ title: "Error", message: formatErrorMessage(errorMsg) });
        if (waitTillClose) {
            return await promise;
        }
    }

    async catchAndAlertError<T>(promise: Promise<T>, waitTillClose = false): Promise<T> {
        try {
            return await promise;
        } catch (e) {
            await this.addErrorAlertDialog(e as Error, waitTillClose);
            throw e;
        }
    }

    clearAlertDialogs() {
        this.alertDialogs = [];
    }
}

export interface AlertDialogConfig {
    id?: string;
    title?: string;
    message?: string | React.ReactNode;
    renderAdditionalContent?: (closeFn?: Function) => React.ReactNode;
    onClose?: (ret: any) => void;
    okButtonProps?: Partial<ButtonProps>;
    okButtonLabel?: string;
    closeOnBackdropClick?: boolean;
}

export interface ConfirmDialogConfig extends AlertDialogConfig {
    autoConfirmationQuestionLine?: boolean;
    onClose?: (confirmed: boolean) => void;
    typeToConfirm?: string;
    cancelButtonProps?: Partial<ButtonProps>;
    cancelButtonLabel?: string;
    hideCancelButton?: boolean;
}

export const useShouldDialogFullScreen = () => {
    if (!useIsDesktop()) {
        return true;
    }
    return false;
};

export const useDialogState = () => {
    const [isOpen, setOpen] = useState(false);
    const [dialogProps, setDialogProps] = useState(null);
    const open = useCallback(() => setOpen(true), [setOpen]);
    const close = useCallback(() => setOpen(false), [setOpen]);
    return { isOpen, open, close, dialogProps, setDialogProps };
};

export type DialogState = ReturnType<typeof useDialogState>;
