import { useCurrentProject } from "../project/CurrentProjectState";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { useAppServices } from "../app/services";
import { formatKnownDataType, formatTitleCase, KnownDataType } from "../../common/utils/formatter";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_ROUTES, LICENSE_SUBROUTE } from "../app/AppRoutes";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { Alert, AlertTitle, Box, Button, Link } from "@mui/material";
import { AppHintID } from "../help/HelpCommon";
import { LicenseVaultTransactionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_type_pb";
import { observer } from "mobx-react-lite";
import React from "react";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useNavigateToPeLicensePendingRequestScreen, useNavigateToProjectLicenseDetailsScreen } from "../management/ProjectManagementCommon";
import { useHasPendingPELicenseRequest, useIsPELicenseActivated } from "../privateEdition/PrivateEditionCommon";
import { DialogService } from "../core/dialog/DialogService";
import { useNavigateToUserLicensePage } from "../user/UserCommon";
import { useOpenHelpSearch } from "../help/hooks/help_hooks";

export const useCurrentProjectLicenseModel = () => {
    const project = useCurrentProject();
    return project?.getInfo()?.getLicenseModel();
};

export const useCurrentProjectLicenseDetails = () => {
    const { licenseService } = useAppServices();
    return licenseService.projectLicenseDetails.data;
};

// ======================
// ViewLicenseKeyButton
// ======================

interface ViewLicenseKeyButtonProps {}

export const ViewUserLicenseKeyButton: React.FC<ViewLicenseKeyButtonProps> = observer((p) => {
    const { dialogService } = useAppServices();
    const goToLicenseDetails = useNavigateToUserLicensePage();
    const onClick = () => {
        goToLicenseDetails();
        dialogService.closeAllAlertDialogs();
    };
    return (
        <Button variant={"outlined"} onClick={onClick}>
            View My License Key
        </Button>
    );
});

export const checkIfLicenseModelUndecided = async (licenseModel: LicenseModel.LicenseModel, dialogService: DialogService, projectId?: string) => {
    if (licenseModel === LicenseModel.LicenseModel.UNDECIDED) {
        await dialogService.addAlertDialog({
            title: "Migration License Required",
            message: (
                <>
                    Migration session cannot be created because this project does not have any migration licenses. If you already have additional licenses in
                    your user license key, you can transfer them into this project.
                    <br />
                    <br />
                    <ViewUserLicenseKeyButton />
                </>
            ),
        });
    }
};

// ======================
// HostBasedLicenseView
// ======================

interface HostBasedLicenseViewProps {
    children: React.ReactNode;
}

export const HostBasedLicenseView: React.FC<HostBasedLicenseViewProps> = (p) => {
    const licenseModel = useCurrentProjectLicenseModel();
    if (licenseModel === LicenseModel.LicenseModel.HOST_BASED) {
        return <>{p.children}</>;
    }
    return null;
};

// ======================
// LicensingLearnMoreLink
// ======================

interface LicensingLearnMoreLinkProps {}

export const LicensingLearnMoreLink: React.FC<LicensingLearnMoreLinkProps> = (p) => {
    const openHelpSearch = useOpenHelpSearch();
    return (
        <span>
            <Link onClick={() => openHelpSearch(AppHintID.LICENSE_KEY)}>Click here</Link> to learn more about licensing.
        </span>
    );
};

export const useProjectHasAvailableHostLicense = () => {
    const { licenseService } = useAppServices();
    if (licenseService.projectLicenseDetails.ready) {
        return !!licenseService.projectLicenseDetails.data.itemsList.find(
            (license) => license.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE && license.currentBalance > 0
        );
    }
    return false;
};

export const useProjectHasLicenseTimeExtension = () => {
    const { licenseService } = useAppServices();
    if (licenseService.projectLicenseDetails.ready) {
        return !!licenseService.projectLicenseDetails.data.itemsList.find(
            (license) =>
                license.currentBalance > 0 &&
                (license.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION ||
                    license.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION)
        );
    }
    return false;
};

export const useProjectHasLicenseCapacityExtension = () => {
    const { licenseService } = useAppServices();
    if (licenseService.projectLicenseDetails.ready) {
        return !!licenseService.projectLicenseDetails.data.itemsList.find(
            (license) =>
                license.counterType.value === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION &&
                license.currentBalance > 0
        );
    }
    return false;
};

export const getLicenseModelLabel = (licenseModel: LicenseModel.LicenseModel) => {
    if (licenseModel === LicenseModel.LicenseModel.CAPACITY_BASED) {
        return "Capacity-Based";
    } else if (licenseModel === LicenseModel.LicenseModel.HOST_BASED) {
        return "Host-Based";
    } else {
        return null;
    }
};

export const getCounterTypeDisplayValue = (counterType: LicenseVaultCounterType.LicenseVaultCounterType) => {
    if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION) {
        return "Cirrus Migrate Cloud – Local Migration";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE) {
        return "Host Migration License";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_UNLIMITED_MIGRATION) {
        return "UNUSED LEGACY HOLD UNLIMITED MIGRATION";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION) {
        return "Host Migration License Extension (30d)";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION) {
        return "Host Migration License Extension (60d)";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION) {
        return "Migration Capacity Quota Add-On (1TiB)";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.PRIVATE_EDITION_VIRTUAL_APPLIANCE) {
        return "Private Edition Virtual Appliance License (60d)";
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.UNKNOWN) {
        return "Unknown License Type";
    }
};

export enum LicenseVaultCounterUnitType {
    CAPACITY,
    HOSTS,
    LICENSES,
}

export const getLicenseValueFormat = (value: number, counterType: LicenseVaultCounterType.LicenseVaultCounterType) => {
    if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.CAPACITY) {
        return formatKnownDataType(value, KnownDataType.CAPACITY);
    } else if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.HOSTS) {
        return `${value} Host${value > 1 || value === 0 ? "s" : ""}`;
    } else if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.LICENSES) {
        return `${value} License${value > 1 || value === 0 ? "s" : ""}`;
    } else {
        return value;
    }
};

const getUnitOptionsPerCounterType = (counterType: LicenseVaultCounterType.LicenseVaultCounterType) => {
    if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.CAPACITY) {
        return ["GiB", "TiB"];
    } else if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.HOSTS) {
        return ["Hosts"];
    } else if (getUnitTypeByCounterType(counterType) === LicenseVaultCounterUnitType.LICENSES) {
        return ["Licenses"];
    } else {
        return [];
    }
};

const getUnitTypeByCounterType = (counterType: LicenseVaultCounterType.LicenseVaultCounterType) => {
    const capacityType = [LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION];
    const hostType = [
        LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE,
        LicenseVaultCounterType.LicenseVaultCounterType.HOST_UNLIMITED_MIGRATION,
    ];
    const licenseType = [
        LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION,
        LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION,
        LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION,
        LicenseVaultCounterType.LicenseVaultCounterType.PRIVATE_EDITION_VIRTUAL_APPLIANCE,
    ];
    if (capacityType.includes(counterType)) {
        return LicenseVaultCounterUnitType.CAPACITY;
    } else if (hostType.includes(counterType)) {
        return LicenseVaultCounterUnitType.HOSTS;
    } else if (licenseType.includes(counterType)) {
        return LicenseVaultCounterUnitType.LICENSES;
    }
};

interface TransactionCounterTypeDef {
    name: string;
    getValueFormat: (value: number) => string;
    unitOptions: string[];
    unitType: LicenseVaultCounterUnitType;
}

export const getTransactionCounterTypeDef = (counterType: LicenseVaultCounterType.LicenseVaultCounterType): TransactionCounterTypeDef => {
    return {
        name: getCounterTypeDisplayValue(counterType),
        getValueFormat: (value: number) => getLicenseValueFormat(value, counterType),
        unitOptions: getUnitOptionsPerCounterType(counterType),
        unitType: getUnitTypeByCounterType(counterType),
    };
};

export const useNavigateToPurchaseLicenseScreen = () => {
    const navigate = useNavigate();
    const p = generatePath(`${APP_ROUTES.USER_LICENSE}/${LICENSE_SUBROUTE.PURCHASE}`);
    return () => navigate(p);
};

export const getLicenseTransactionTypeDisplayValue = (value: any) => {
    for (let key in LicenseVaultTransactionType.LicenseVaultTransactionType) {
        if (LicenseVaultTransactionType.LicenseVaultTransactionType[key] === value) {
            return formatTitleCase(key);
        }
    }
};
