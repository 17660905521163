import { useAppServices } from "../app/services";
import * as React from "react";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import { formatBool, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { DeleteIcon, EditIcon, RefreshIcon } from "../../common/CommonIcons";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { formatServerAddressWithoutDefaultPort, formatServerAddressWithoutPort, generateDeploymentDetailsPath } from "./GalaxyMigrateCommon";
import { useDialogState } from "../core/dialog/DialogService";
import { useIsOperatorView } from "../auth/AuthenticatedViews";
import { EditConnectionDialog } from "./links/EditGalaxyMigrateLink";
import { GalaxyMigrateLinkIcon, getDeploymentConnectionStyle } from "../deployment/DeploymentCommon";
import { CreateGalaxyMigrateLinkButton } from "./links/CreateGalaxyMigrateLinkForm";
import { createColumnHelper, OnChangeFn, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../common/table/QueryTable";
import { ActionMenuButton } from "../../common/actions/CommonActions";
import { ListGalaxyMigrateLinks } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { UseQueryResult } from "@tanstack/react-query";
import EmptyTableIcon from "../../assets/empty_states/empty-h2h.svg";

// ======================
// GalaxyMigrateLinksList
// ======================

interface GalaxyMigrateLinksListProps {
    pageIndex: number;
    pageSize: number;
    setPagination: OnChangeFn<PaginationState>;
    queryResult: UseQueryResult<ListGalaxyMigrateLinks.Response.AsObject>;
}

export const GalaxyMigrateLinksList: React.FC<GalaxyMigrateLinksListProps> = (p) => {
    const { projectId, deploymentID } = useParams();
    const { pageIndex, pageSize, setPagination, queryResult } = p;
    const editDialogState = useDialogState();
    const isOperator = useIsOperatorView();
    const [currentEditRow, setCurrentEditRow] = React.useState(null);
    const { gmDeploymentService } = useAppServices();

    const columnHelper = createColumnHelper<GMLinkInfo.AsObject>();

    const cols = [
        columnHelper.accessor((r) => r.client.systemName, {
            header: "From Host",
            cell: (props) => {
                const p = generateDeploymentDetailsPath(props.row.original.client.systemId, projectId);
                const hostLinkDisabled = deploymentID ? deploymentID === props.row.original.client.systemId : false;
                if (hostLinkDisabled) {
                    return (
                        <Typography variant={"body2"} sx={getDeploymentConnectionStyle(props.row.original.connected)}>
                            {props.getValue()}
                        </Typography>
                    );
                }
                return (
                    <Link component={RouterLink} to={p}>
                        {props.getValue()}
                    </Link>
                );
            },
        }),
        columnHelper.accessor((r) => r.server.systemName, {
            header: "To Host",
            cell: (props) => {
                const p = generateDeploymentDetailsPath(props.row.original.server.systemId, projectId);
                const hostLinkDisabled = deploymentID ? deploymentID === props.row.original.server.systemId : false;
                if (hostLinkDisabled) {
                    return (
                        <Typography variant={"body2"} sx={getDeploymentConnectionStyle(props.row.original.connected)}>
                            {props.getValue()}
                        </Typography>
                    );
                }
                return (
                    <Link component={RouterLink} to={p}>
                        {props.getValue()}
                    </Link>
                );
            },
        }),
        columnHelper.accessor((r) => r.lastserveraddress, {
            header: "Connection Address",
            cell: (props) => {
                return (
                    <Typography sx={getDeploymentConnectionStyle(props.row.original.connected)}>
                        {formatServerAddressWithoutDefaultPort(props.getValue()!!)}
                    </Typography>
                );
            },
        }),
        columnHelper.accessor((r) => r.compression, {
            header: "Compression",
            cell: (props) => {
                return <Typography sx={getDeploymentConnectionStyle(props.row.original.connected)}>{formatBool(props.getValue())}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r.connected, {
            header: "Connected",
            cell: (props) => {
                return (
                    <Typography sx={getDeploymentConnectionStyle(props.row.original.connected)}>
                        {formatKnownDataType(props.getValue(), KnownDataType.BOOL)}
                    </Typography>
                );
            },
        }),
        columnHelper.accessor((r) => r.latency, {
            header: "Latency",
            cell: (props) => {
                return (
                    <Typography sx={getDeploymentConnectionStyle(props.row.original.connected)}>
                        {formatKnownDataType(props.getValue(), KnownDataType.DURATION_MILLISECONDS)}
                    </Typography>
                );
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Actions",
            cell: (props) => {
                return <ActionMenuButton actions={getRowActions(props.row.original)} />;
            },
        }),
    ];

    const getRowActions = (r: GMLinkInfo.AsObject) => [
        {
            id: "edit",
            name: "Edit Connection Information",
            action: async () => {
                setCurrentEditRow(r);
                editDialogState.open();
            },
            icon: <EditIcon />,
            hidden: !isOperator,
        },
        {
            id: "retry",
            name: "Retry Connection",
            action: async () => {
                const address = formatServerAddressWithoutPort(r.lastserveraddress);
                const serverPort = !!r.lastserveraddress.split(":")[1] ? parseInt(r.lastserveraddress.split(":")[1]) : 0;
                await gmDeploymentService.reconnectGalaxyMigrateLink(r.linkid, address, serverPort);
            },
            icon: <GalaxyMigrateLinkIcon />,
            hidden: r.connected,
        },
        {
            id: "refresh",
            name: "Refresh Connection",
            action: async () => {
                await gmDeploymentService.refreshGalaxyMigrateLink(r.linkid);
            },
            icon: <RefreshIcon />,
            hidden: !r.connected,
        },
        {
            id: "remove",
            name: `Remove Connection`,
            action: async () => {
                await gmDeploymentService.removeGalaxyMigrateLink(r.linkid);
                await gmDeploymentService.galaxyMigrateLinks.fetchData();
            },
            icon: <DeleteIcon />,
            hidden: !isOperator,
        },
    ];

    return (
        <>
            <QueryTable
                data={queryResult.data?.itemsList}
                columns={cols}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                pageCount={queryResult.data?.pagerMeta?.totalPages}
                error={queryResult.error}
                isError={queryResult.isError}
                isLoading={queryResult.isLoading}
                emptyTableConfig={{
                    title: "Host-to-Host Connections for secure remote migration",
                    iconPath: EmptyTableIcon,
                    message:
                        "H2H connections can be created to establish direct connectivity between your hosts to ensure secure data transfer during remote migration.",
                    actionButton: isOperator ? <CreateGalaxyMigrateLinkButton variant={"contained"} color={"secondary"} /> : null,
                }}
            />
            <EditConnectionDialog dialogState={editDialogState} linkInfo={currentEditRow} />
        </>
    );
};
