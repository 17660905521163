// ======================
// CmoMigrationRegistrationDialog
// ======================

import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { Box, Button, CardContent, Dialog, DialogActions, DialogContent, ListSubheader, Stack, Typography, useTheme } from "@mui/material";
import { GoDotFill } from "react-icons/go";
import { DarkFlatCard } from "../../common/card/DarkCard";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { useCurrentProject } from "../project/CurrentProjectState";
import { DEFAULT_PORTAL_HOST } from "../deployment/DeploymentCommon";
import { getApiHost } from "../grpc/grpcCommon";

interface CmoMigrationRegistrationDialogButtonProps {}

export const CmoMigrationRegistrationDialogButton: React.FC<CmoMigrationRegistrationDialogButtonProps> = (p) => {
    const dialogState = useDialogState();
    const fullScreen = useShouldDialogFullScreen();
    const theme = useTheme();
    const registrationId = "cmo-registration-id";
    const endpointId = "cmo-endpoint-id";
    const project = useCurrentProject();
    const registrationCode = project.getInfo().getSystemRegistrationCode();
    const portalHost = `${getApiHost()}`.replace(/webapi/g, "portal");
    const endpoint = DEFAULT_PORTAL_HOST !== portalHost ? `${portalHost}:443` : "";

    return (
        <>
            <Button variant={"contained"} color={"secondary"} onClick={dialogState.open}>
                {"Register New System"}
            </Button>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"md"} fullScreen={fullScreen}>
                    <DialogContent>
                        <Typography variant={"h5"}>{"Register New CMO Appliance Systems"}</Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {"Please follow the instructions below to register your CMO Systems to this Cirrus Data Cloud project."}
                        </Typography>
                        <Box pt={3}>
                            <Typography variant={"h6"}>{"1. Launch Registration Wizard from the CMO Appliance User Interface. "}</Typography>
                            <Stack direction={"row"} spacing={2} pt={1}>
                                <GoDotFill size={16} color={theme.palette.primary.main} />
                                <Box>
                                    <Typography variant={"body2"}>
                                        {"To begin registering a CMO Appliance to this project, log in to the appliance's user interface. "}
                                    </Typography>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Need help with the registration? Learn more from our docs or contact our support team through Customer Center."}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Stack direction={"row"} spacing={2} pt={1}>
                                <GoDotFill size={16} color={theme.palette.primary.main} />
                                <Typography variant={"body2"}>
                                    {"Click on the ⚙️ icon, and select “Cirrus Data Cloud” from the menu to launch the registration wizard."}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box pt={3}>
                            <Typography variant={"h6"}>{"2. Apply Registration Code from CMO."}</Typography>
                            <Typography variant={"body2"}>
                                {"To register your CMO appliance to your Cirrus Data Cloud project, enter the registration code into the CMO appliance's registration wizard. \n" +
                                    "Then, follow the instructions in the wizard to complete the registration process."}
                            </Typography>
                        </Box>
                        <Box pt={3}>
                            <DarkFlatCard>
                                <ListSubheader>{"Registration Code"}</ListSubheader>
                                <Box p={2} pt={0}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <ClipboardText clipboardId={registrationId}>{registrationCode}</ClipboardText>
                                        <ClipboardButton clipboardId={registrationId} iconButton />
                                    </Box>{" "}
                                </Box>
                            </DarkFlatCard>
                        </Box>
                        <Box pt={3}>
                            <DarkFlatCard>
                                <ListSubheader>{"Endpoint"}</ListSubheader>
                                <Box p={2} pt={0}>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <ClipboardText clipboardId={endpointId}>{endpoint}</ClipboardText>
                                        <ClipboardButton clipboardId={endpointId} iconButton />
                                    </Box>{" "}
                                </Box>
                            </DarkFlatCard>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} onClick={dialogState.close}>
                            {"Done"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
