import { Box, Link } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import React from "react";

// ======================
// MarkdownText
// ======================

interface MarkdownTextProps {
    markdown: string;
}

export const MarkdownText: React.FC<MarkdownTextProps> = (p) => {
    const { markdown } = p;
    return (
        <Box
            sx={{
                "& p": {
                    margin: 0,
                },
                "& pre>code": {
                    padding: 0,
                    margin: 0,
                    "word-break": "normal",
                    "white-space": "pre",
                    background: "transparent",
                    border: 0,
                },
                "& pre": {
                    padding: "16px",
                    overflow: "auto",
                    "font-size": "85%",
                    "line-height": "1.45",
                    "background-color": "#161b22",
                    "border-radius": "6px",
                },
            }}
        >
            <ReactMarkdown
                children={markdown}
                remarkPlugins={[remarkGfm]}
                components={{
                    a: ({ node, ...props }) => <Link {...props} target="_blank" rel="noopener noreferrer" />,
                }}
            />
        </Box>
    );
};
