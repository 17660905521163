import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { useAppServices } from "./services";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { isDevelopment } from "../../common/utils/util";
import { FullScreenLoading } from "../core/data/DataLoaders";
import { PROJECT_SUBROUTE } from "./AppRoutes";

import * as Sentry from "@sentry/react";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { FeatureFlag } from "./AppGlobalService";
import { useAuthState } from "../auth/AuthState";
import { useInitAuthentication, useIsAuthenticated } from "../auth/auth_hooks";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { isAfter } from "date-fns";
import { useGetPrivateEditionLocalStatus } from "../privateEdition/private_edition_hooks";
import app from "./App";
import { useGetMyUserInfo } from "../user/user_hooks";
import { useMountEffect } from "../../common/hooks/hookslib";

// ======================
// AppInitialization
// ======================

interface AppInitializationProps {
    children?: React.ReactNode;
}

export const AppInitialization: React.FC<AppInitializationProps> = observer(({ ...p }) => {
    const { appGlobalService, api, dialogService } = useAppServices();
    const isPrivateEdition = getIsPrivateEdition();
    const initAuthentication = useInitAuthentication();
    const authenticated = useIsAuthenticated();
    const authState = useAuthState();
    const location = useLocation();
    const onAuthError = useAuthState((s) => s.onAuthError);
    const peLocalStatus = useGetPrivateEditionLocalStatus();
    const myUserInfo = useGetMyUserInfo();

    const [initCompleted, setInitCompleted] = React.useState(false);
    const [postAuthInitCompleted, setPostAuthInitCompleted] = React.useState(false);

    const init = useCallback(
        async function init() {
            appGlobalService.setInitialized(false);
            console.debug(`Initializing App. Development: ${isDevelopment()}`);

            Object.values(PROJECT_SUBROUTE).forEach((value) => {
                if (location.pathname.includes(value)) {
                    appGlobalService.setAppSubmenu(value);
                }
            });

            await initAuthentication();
            api.onAuthError = (e) => onAuthError(e);

            // do something here if needed, check if already logged in, etc.

            //sleep
            await appGlobalService.sleep();
            console.debug("App Initialization Executed");
            setInitCompleted(true);
        },
        [appGlobalService, location.pathname, initAuthentication, setInitCompleted, api, onAuthError]
    );

    const postAuthInit = useCallback(
        async function init() {
            Sentry.setUser({ email: myUserInfo.data?.userSessionInfo?.email });
            console.debug(`Initializing App Post Authentication. Development: ${isDevelopment()}`);
            await appGlobalService.reloadEnabledFeatureFlags();
            setPostAuthInitCompleted(true);
        },
        [appGlobalService, myUserInfo.data, setPostAuthInitCompleted]
    );

    const postAuthLogout = useCallback(async function init() {
        Sentry.configureScope((scope) => scope.setUser(null));
        console.debug(`De-Initializing App Post Logout. Development: ${isDevelopment()}`);
    }, []);

    useMountEffect(() => {
        init();
    });

    // =================
    // post auth init
    // =================
    useEffect(() => {
        if (!initCompleted) {
            return;
        }

        if (authenticated) {
            postAuthInit();
        } else {
            postAuthLogout();
        }
    }, [authenticated, postAuthInit, postAuthLogout, initCompleted, authState]);

    useEffect(() => {
        if (!initCompleted) {
            return;
        }
        if (authenticated && !postAuthInitCompleted) {
            return;
        }
        // if pe and authenticated, but not fetched local status yet, don't mark completed
        if (getIsPrivateEdition() && authenticated && !peLocalStatus.isSuccess) {
            return;
        }
        appGlobalService.setInitialized(true);
    }, [appGlobalService, authenticated, postAuthInitCompleted, initCompleted, peLocalStatus.isSuccess]);

    if (!appGlobalService.initialized) {
        return <FullScreenLoading loading={true} message={"Starting Initialization..."} />;
    } else {
        return <>{p.children}</>;
    }
});
