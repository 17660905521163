// ======================
// MigrationTypeStep
// ======================

import { GmMigrationType, GmMigrationVolumeType, GmMigrationWizardState } from "../GmMigrationService";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import { useOpenHelpArticle } from "../../help/hooks/help_hooks";
import React, { useCallback } from "react";
import { getMigrationWizardStepConfigs } from "./GmMigrationWizard";
import { WizardStepProp } from "./GmMigrationWizardCommon";
import { KnownArticle } from "../../help/HelpCommon";
import { getMigrationTypeSelectionContinueDisabled } from "./GmMigrationWizardUtils";
import { getDeploymentConnectionStyle, isMinMtdiVersion } from "../../deployment/DeploymentCommon";
import { Box, Button, Card, ListSubheader, Radio, Typography } from "@mui/material";
import { SelectableCard } from "../../../common/card/SelectableCard";
import { ImTree } from "react-icons/im";
import { MdOutlineCloud, MdOutlineScience } from "react-icons/md";
import { FeatureFlag } from "../../app/AppGlobalService";
import { GrVmware } from "react-icons/gr";
import { formatServerAddressWithoutDefaultPort, getGalaxyMigrateHelperNodeOSName } from "../../galaxymigrate/GalaxyMigrateCommon";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { GmVmwareHelperSelectionTable } from "./computeMigration/GmMigrationWizardVmware";
import DataVolume from "../../../assets/migrationSession/volume.png";
import BootVolume from "../../../assets/migrationSession/boot_volume.png";
import ClusteredVolumeTracking from "../../../assets/migrationSession/clustered_volume_tracking.svg";
import { ColumnDef, DataTable } from "../../../common/table/DataTable";
import { renderServerDataWithLoadingList, useInitData } from "../../core/data/DataLoaderHooks";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { CreateGalaxyMigrateLinkButton } from "../../galaxymigrate/links/CreateGalaxyMigrateLinkForm";
import Grid from "@mui/material/Grid2";

export const GmMigrationWizardMigrationTypeStep: React.FC<WizardStepProp> = observer((props) => {
    const { wizardState } = props;

    const { progressService, appGlobalService, gmDeploymentService } = useAppServices();
    const openHelpArticle = useOpenHelpArticle();

    const onContinue = async () => {
        await progressService.track(wizardState.confirmMigrationTypeSelection());
        wizardState.stepperState.setStepConfigs(getMigrationWizardStepConfigs(wizardState));
        wizardState.stepperState.goToNextStep();
    };

    const [selectedMigrationType, setSelectedMigrationType] = React.useState(wizardState.selectedMigrationType);

    const handleSelectMigrationType = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: GmMigrationType) => {
            setSelectedMigrationType(type);
            wizardState.selectMigrationType(type);
            wizardState.deselectRemoteDestination();

            if (type === GmMigrationType.COMPUTE_VMWARE) {
                wizardState.selectMigrationVolumeType(GmMigrationVolumeType.BOOT);
                wizardState.initVmwareState();
            }
        },
        [setSelectedMigrationType, wizardState]
    );

    const [selectedMigrationVolumeType, setSelectedMigrationVolumeType] = React.useState(wizardState.selectedMigrationVolumeType);

    const handleSelectMigrationVolumeType = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>, type: GmMigrationVolumeType) => {
            setSelectedMigrationVolumeType(type);
            wizardState.selectMigrationVolumeType(type);
        },
        [setSelectedMigrationVolumeType, wizardState]
    );

    const openBootHelpArticle = useCallback(() => {
        openHelpArticle(KnownArticle.MIGRATING_BOOT_VOLUMES);
    }, [openHelpArticle]);
    const openClusterMigrationHelpArticle = useCallback(() => {
        openHelpArticle(KnownArticle.MIGRATING_CLUSTER_VOLUMES);
    }, [openHelpArticle]);

    const continueDisabled = getMigrationTypeSelectionContinueDisabled(wizardState);

    const isBootVolumeFeatureEnabled = isMinMtdiVersion(wizardState.deployment.data, "10.1.0");
    const noMigrationTypeSelected = wizardState.selectedMigrationType === null;

    return (
        <>
            <Box>
                <Typography variant={"h5"}>1. Select Migration Session Type</Typography>
                <Box pt={2} pb={2}>
                    <Grid container spacing={3}>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                                lg: 4,
                            }}
                        >
                            <SelectableCard
                                selected={selectedMigrationType === GmMigrationType.LOCAL}
                                onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.LOCAL)}
                                cardProps={{ sx: { height: "100%" } }}
                                icon={<ImTree size={"6em"} />}
                                title={"Local Migration"}
                                description={"Migration between volumes on the same host"}
                            />
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                                lg: 4,
                            }}
                        >
                            <SelectableCard
                                selected={selectedMigrationType === GmMigrationType.REMOTE}
                                onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.REMOTE)}
                                cardProps={{ sx: { height: "100%" } }}
                                icon={<MdOutlineCloud size={"6em"} />}
                                title={"Remote Migration"}
                                description={"Migration to Remote Host via H2H Connections"}
                            />
                        </Grid>
                        {appGlobalService.isFeatureEnabled(FeatureFlag.MOCK_MIGRATION) && (
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    lg: 4,
                                }}
                            >
                                <SelectableCard
                                    selected={selectedMigrationType === GmMigrationType.MOCK_MIGRATION}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.MOCK_MIGRATION)}
                                    cardProps={{ sx: { height: "100%" } }}
                                    icon={<MdOutlineScience size={"6em"} color={"white"} />}
                                    title={"Mock Migration"}
                                    description={"Test migration capabilities with Mock Migration"}
                                />
                            </Grid>
                        )}

                        {appGlobalService.isFeatureEnabled(FeatureFlag.COMPUTE_MIGRATION_VMWARE) && (
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    lg: 4,
                                }}
                            >
                                <SelectableCard
                                    selected={selectedMigrationType === GmMigrationType.COMPUTE_VMWARE}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectMigrationType(e, GmMigrationType.COMPUTE_VMWARE)}
                                    cardProps={{ sx: { height: "100%" } }}
                                    icon={<GrVmware size={"6em"} />}
                                    title={"Compute Migration - VMware"}
                                    description={"Migrate Entire Machine to VMware Environment"}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            {selectedMigrationType === GmMigrationType.REMOTE && (
                <>
                    <Typography>{"Select a remote destination host for this session"}</Typography>
                    <br />
                    <RemoteDestinationSelectionTable wizardState={wizardState} />
                </>
            )}
            {selectedMigrationType === GmMigrationType.COMPUTE_VMWARE && (
                <>
                    <Typography>
                        Select a {getGalaxyMigrateHelperNodeOSName(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE)} to receive data. To be eligible
                        for selection, helper VMs must have vCenter integration configured and have active H2H Connection with host{" "}
                        {wizardState.selectedDeploymentName}.
                    </Typography>
                    <br />
                    <GmVmwareHelperSelectionTable wizardState={wizardState} />
                </>
            )}
            {!wizardState.isComputeMigration && (
                <Box mt={2}>
                    <Typography variant={"h5"}>2. Select Migration Volume Type</Typography>
                    <Box pt={2}>
                        <Grid container spacing={3}>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    lg: 4,
                                }}
                            >
                                <SelectableCard
                                    selected={selectedMigrationVolumeType === GmMigrationVolumeType.DATA}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        handleSelectMigrationVolumeType(e, GmMigrationVolumeType.DATA)
                                    }
                                    cardProps={{ sx: { height: "100%" } }}
                                    disabled={noMigrationTypeSelected}
                                    icon={<img src={DataVolume} height={72} alt={"Data Volumes"} />}
                                    title={"Migrate Data Volumes Only"}
                                    description={"cMotion™ can be used to move your workload without downtime"}
                                />
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    lg: 4,
                                }}
                            >
                                <SelectableCard
                                    disabled={!isBootVolumeFeatureEnabled || noMigrationTypeSelected}
                                    selected={selectedMigrationVolumeType === GmMigrationVolumeType.BOOT}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        handleSelectMigrationVolumeType(e, GmMigrationVolumeType.BOOT)
                                    }
                                    cardProps={{ sx: { height: "100%" } }}
                                    icon={<img src={BootVolume} height={72} alt={"Boot Volumes"} />}
                                    title={"Migrate Boot Volumes"}
                                    description={"Move your boot volume without impacting production"}
                                    actions={[
                                        {
                                            id: "help",
                                            name: "View Boot Volume Migration Guide",
                                            action: openBootHelpArticle,
                                            buttonProps: { variant: "outlined", color: "neutral" },
                                        },
                                    ]}
                                    warning={"Note: Review migration guide before continuing."}
                                    tip={
                                        "Due to the nature of a boot volume migration, cMotion™ functionalities will not be available. You must be familiar with your system's boot volume swapping (cutover) procedure before proceeding."
                                    }
                                />
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6,
                                    lg: 4,
                                }}
                            >
                                <SelectableCard
                                    disabled={noMigrationTypeSelected}
                                    selected={selectedMigrationVolumeType === GmMigrationVolumeType.CLUSTER_TRACKING}
                                    onSelect={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                                        handleSelectMigrationVolumeType(e, GmMigrationVolumeType.CLUSTER_TRACKING)
                                    }
                                    cardProps={{ sx: { height: "100%" } }}
                                    icon={<img src={ClusteredVolumeTracking} height={72} alt={"Clustered Volumes Tracking"} />}
                                    title={"Additional Change-Tracking"}
                                    description={"Only synchronize changes from clustered volumes"}
                                    actions={[
                                        {
                                            id: "help",
                                            name: "View Cluster Migration Guide",
                                            action: openClusterMigrationHelpArticle,
                                            buttonProps: { variant: "outlined", color: "neutral" },
                                        },
                                    ]}
                                    warning={"Must only be used alongside another migration."}
                                    tip={
                                        "This migration mode should be used on secondary cluster nodes only. Only changed data will be migrated. Migration cutover must be performed on the primary migration session."
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
            <br />
            <Grid container justifyContent={"center"}>
                <Box p={1} display={"flex"}>
                    <Box pr={2}>
                        <Button variant={"outlined"} color={"neutral"} onClick={() => wizardState.stepperState.goBackOneStep()}>
                            {"Go Back"}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant={"contained"} color={"primary"} disabled={continueDisabled} onClick={onContinue}>
                            {"Continue"}
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </>
    );
});

// ======================
// RemoteDestinationSelectionTable
// ======================

interface RemoteDestinationSelectionTableProps {
    wizardState: GmMigrationWizardState;
}

const RemoteDestinationSelectionTable: React.FC<RemoteDestinationSelectionTableProps> = observer((props) => {
    const { wizardState } = props;
    const { gmDeploymentService } = useAppServices();

    useInitData({
        poll: () => gmDeploymentService.galaxyMigrateLinks.fetchData(wizardState.deploymentId, true),
        pollInterval: 10,
    });

    const cols: ColumnDef<GMLinkInfo>[] = [
        {
            id: "select",
            label: "Select",
            getter: (r) => r,
            renderer: (_, r) => {
                const currentSelectedSource = wizardState.deploymentId;
                const deploymentId = currentSelectedSource === r.getServer().getSystemId() ? r.getClient().getSystemId() : r.getServer().getSystemId();
                const selected = wizardState.selectedRemoteDestinationId === deploymentId;
                const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (selected) {
                        wizardState.deselectRemoteDestination();
                    } else {
                        wizardState.selectRemoteDestination(deploymentId);
                    }
                };
                return <Radio color={"secondary"} checked={selected} onChange={onChange} />;
            },
        },
        {
            id: "systemName",
            label: "Remote Host",
            getter: (r) => (wizardState.deploymentId === r.getServer().getSystemId() ? r.getClient().getSystemName() : r.getServer().getSystemName()),
        },
        {
            id: "connectionAddress",
            label: "Connection Address",
            getter: (r) => r.getLastserveraddress(),
            renderer: (address, r) => {
                return <Typography>{`${formatServerAddressWithoutDefaultPort(address)} (${r.getServer().getSystemName()})`}</Typography>;
            },
        },
        {
            id: "latency",
            label: "Latency",
            getter: (r) => r.getLatency().getNanos(),
            renderer: (latency: number, r) => {
                return <Typography sx={getDeploymentConnectionStyle(r.getConnected())}>{(latency / 1000 / 1000).toFixed(2)}ms</Typography>;
            },
        },
    ];
    return renderServerDataWithLoadingList(gmDeploymentService.galaxyMigrateLinks, (data) => {
        const deployments = data.getItemsList();
        return (
            <>
                <Card>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <ListSubheader>Host-to-Host Connections</ListSubheader>
                        </Box>
                        <Box p={1}>
                            <CreateGalaxyMigrateLinkButton icon />
                        </Box>
                    </Box>
                    <DataTable
                        rows={deployments}
                        rowIdGetter={(r) => r.getLinkid()}
                        state={gmDeploymentService.galaxyMigrateLinks.tableState}
                        pagerMeta={data.getPagerMeta().toObject()}
                        emptyTableTitle={"No Remote Hosts Found"}
                        emptyTableActionButton={<CreateGalaxyMigrateLinkButton variant={"contained"} color={"secondary"} />}
                        onTableStateChange={() => gmDeploymentService.galaxyMigrateLinks.fetchData(wizardState.deploymentId, true)}
                        cols={cols}
                    />
                </Card>
            </>
        );
    });
});
