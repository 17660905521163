// Project: GalaxyComplete
// Created: 9/25/20 by sammy

import * as React from "react";
import { create } from "zustand";
import { ProjectDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { ProjectMemberInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";

export const useCurrentProject = () => {
    const currentProjectState = useCurrentProjectState();
    return currentProjectState.currentProject!!;
};

export const useCurrentProjectID = () => {
    const currentProjectState = useCurrentProjectState();
    return currentProjectState.currentProject?.getInfo().getProjectId() || null;
};

export interface CurrentProjectState {
    currentProject: ProjectDetails;
    myRole: ProjectMemberInfo.Role;
    setCurrentProject: (project: ProjectDetails) => void;
    setMyRole: (role: ProjectMemberInfo.Role) => void;
    clearCurrentProject: () => void;
}
export const useCurrentProjectState = create<CurrentProjectState>((set) => ({
    currentProject: null,
    myRole: null,
    setCurrentProject: (project: ProjectDetails) => set({ currentProject: project }),
    clearCurrentProject: () => set({ currentProject: null, myRole: null }),
    setMyRole: (role: ProjectMemberInfo.Role) => set({ myRole: role }),
}));
