import { create } from "zustand";
import { AuthenticatedUserInfo } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";

export interface AuthState {
    currentUser?: AuthenticatedUserInfo.AsObject;
    isAwsAuthenticated?: boolean;
    setAwsAuthenticated?: (authenticated: boolean) => void;
    isAzureAuthenticated?: boolean;
    setAzureAuthenticated?: (authenticated: boolean) => void;
    logOut?: () => void;
    updateCurrentUser?: (user: AuthenticatedUserInfo.AsObject) => void;
    onAuthError?: (error: Error) => void;
}

export const useAuthState = create<AuthState>((set, get) => ({
    currentUser: null,
    isAwsAuthenticated: false,
    isAzureAuthenticated: false,
    storage: window.localStorage,
    setAwsAuthenticated: (authenticated: boolean) => {
        set({
            isAwsAuthenticated: authenticated,
        });
    },
    setAzureAuthenticated: (authenticated: boolean) => {
        set({
            isAzureAuthenticated: authenticated,
        });
    },
    onAuthError: (error: Error) => {
        get().logOut();
    },
    logOut: () => {
        set({
            currentUser: null,
            isAwsAuthenticated: false,
            isAzureAuthenticated: false,
        });
    },
    updateCurrentUser: (user: AuthenticatedUserInfo.AsObject) => {
        set({
            currentUser: user,
        });
    },
}));
