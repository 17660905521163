import { GetProjectDetails, ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { ProjectDetails, ProjectIdentityInfo, ProjectInfo, ProjectListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

import { mockListPagerMeta } from "./FixturesCommon";
import { ProjectMemberRole } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/project_member_role_pb";
import { MtdiOsType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";

// ======================
// Common Responses
// ======================

// ======================
// Projects List
// ======================

export const mockProjectIdentityInfo1 = new ProjectIdentityInfo().setProjectId("884a4178-099d-40dd-b7f9-8cdc550f0277").setName("Unicorn");

export const mockProjectInfo1 = new ProjectInfo()
    .setCreatedAt(new Timestamp().setSeconds(1604252821))
    .setDescription("One-horned horse.")
    .setName("Unicorn")
    .setProjectId("884a4178-099d-40dd-b7f9-8cdc550f0277")
    .setSystemRegistrationCode("0LURYJSNLILKYCQS7SHI")
    .setLicenseModel(LicenseModel.LicenseModel.CAPACITY_BASED)
    .setEnabledProducts(new ProjectInfo.EnabledProducts().setCmo(true).setCmc(true));

export const mockProjectInfo2 = new ProjectInfo()
    .setCreatedAt(new Timestamp().setSeconds(1604258678))
    .setDescription("Flying horse.")
    .setName("Pegasus")
    .setProjectId("8df3c2a5-7ea3-4c89-9be1-162acef020be")
    .setSystemRegistrationCode("PDJENTDUDDBC50HNKXPW")
    .setLicenseModel(LicenseModel.LicenseModel.HOST_BASED)
    .setEnabledProducts(new ProjectInfo.EnabledProducts().setCmo(true).setCmc(true));

export const mockProjectListItem1 = new ProjectListItem()
    .setMyProjectRole(ProjectMemberRole.ProjectMemberRole.ADMIN)
    .setProjectInfo(mockProjectInfo1)
    .setTotalDeployments(2)
    .setTotalMembers(2)
    .setGmOsTypesList([MtdiOsType.MtdiOsType.LINUX, MtdiOsType.MtdiOsType.WINDOWS])
    .setDeploymentHostEnvironmentsList([DeploymentHostEnvironment.DeploymentHostEnvironment.AWS, DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE]);

export const mockProjectListItem2 = new ProjectListItem()
    .setMyProjectRole(ProjectMemberRole.ProjectMemberRole.ADMIN)
    .setProjectInfo(mockProjectInfo2)
    .setTotalDeployments(3)
    .setTotalMembers(4)
    .setGmOsTypesList([MtdiOsType.MtdiOsType.LINUX, MtdiOsType.MtdiOsType.WINDOWS])
    .setDeploymentHostEnvironmentsList([
        DeploymentHostEnvironment.DeploymentHostEnvironment.OPENSTACK,
        DeploymentHostEnvironment.DeploymentHostEnvironment.SOFTLAYER,
    ]);

export const mockProjectsListData = new ListProjects.Response().setPagerMeta(mockListPagerMeta).setItemsList([mockProjectListItem1]);

export const mockProjectsListData2 = new ListProjects.Response().setPagerMeta(mockListPagerMeta.setPerPage(2)).setItemsList([]);

// ======================
// CurrentProject
// ======================

export const mockCurrentProjectDetails1 = new GetProjectDetails.Response()
    .setProjectDetails(new ProjectDetails().setInfo(mockProjectInfo1))
    .getProjectDetails();

export const mockCurrentProjectDetails2 = new GetProjectDetails.Response()
    .setProjectDetails(new ProjectDetails().setInfo(mockProjectInfo2))
    .getProjectDetails();
