// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: GalaxyMigrateDeploymentsList

import * as React from "react";
import { useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { alpha, Box, Button, Chip, LinearProgress, Link, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getDeploymentConnectionStyle, HostOSIcon, parseCpuCountsFromCpuString } from "../deployment/DeploymentCommon";
import { useIsOperatorView } from "../auth/AuthenticatedViews";
import { NewDeploymentButton } from "../deployment/NewDeploymentInstructions";
import { AppHintID, KnownArticle, LearnMoreButton } from "../help/HelpCommon";
import { DeleteIcon, HostEnvironmentIcon, LicenseIcon, OnlineStatusIcon } from "../../common/CommonIcons";
import { getGalaxyMigrateHelperNodeOSName, getHostEnvDisplayName, useNavigateToDeploymentDetails } from "./GalaxyMigrateCommon";
import { ConfigureGalaxyMigrateCmcHelperButton } from "./compute/GalaxyMigrateConfigureCmcHelper";
import { useOpenHelpSearch } from "../help/hooks/help_hooks";
import { LabelChip } from "../labels/labelCommon";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { MdLabelOutline } from "react-icons/md";
import { EmptyTableConfig } from "../../common/table/TableCommon";
import EmptyRelayTable from "../../assets/empty_states/empty-management-relays.svg";
import EmptyHostsTable from "../../assets/empty_states/empty-host-list.svg";
import {
    useDeleteHostLicense,
    useGetGalaxyMigrateDeploymentClusterDetails,
    useListGalaxyMigrateDeployments,
    useRemoveGmDeployment,
} from "../deployment/deployment_hooks";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../common/table/QueryTable";
import { HiOutlineClock } from "react-icons/hi";
import { useLinearProgressStyle } from "../cmcMigration/CmcMigrationCommon";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { DeploymentQueryKeys } from "../../common/QueryKeys";
import { useIsDesktop } from "../layout/MainLayout";
import { sub } from "date-fns";
import { SelectableBox } from "../../common/card/SelectableCard";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { AiOutlineCluster } from "react-icons/ai";
import { GmDeploymentClustersType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/gm_deployment_clusters_type_pb";
import { OutlinedCard } from "../../common/card/OutlinedCard";
import { GoDotFill } from "react-icons/go";
import Grid from "@mui/material/Grid2";

// ======================
// GalaxyMigrateDeploymentsList
// ======================
interface GalaxyMigrateDeploymentsListProps {
    projectId: string;
    relayOnly?: boolean;
}

export const GalaxyMigrateDeploymentsList: React.FC<GalaxyMigrateDeploymentsListProps> = observer((p) => {
    const { projectId, relayOnly } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const deploymentsList = useListGalaxyMigrateDeployments(projectId, false, pageIndex, pageSize, false, [], relayOnly, true, true);
    const openHelpSearch = useOpenHelpSearch();
    const theme = useTheme();
    const progressStyle = useLinearProgressStyle(true);
    const isOperator = useIsOperatorView();
    const deleteLicense = useDeleteHostLicense("list");
    const addConfirmDialog = useGlobalDialogState((s) => s.addConfirmDialog);
    const removeDeployment = useRemoveGmDeployment();
    const isMobileView = !useIsDesktop();

    const getEmptyTableProps = useCallback((): EmptyTableConfig => {
        if (relayOnly) {
            return {
                iconPath: EmptyRelayTable,
                title: "No Activated CDC Management Relay",
                message:
                    "Other hosts can deploy Cirrus Migrate Cloud and securely connect to Cirrus Data Cloud via any hosts with CDC Management Relay enabled.",
                actionButton: (
                    <Button color={"neutral"} variant={"outlined"} onClick={() => openHelpSearch(AppHintID.CMC_RELAY)}>
                        {"Learn More"}
                    </Button>
                ),
                hideHelpLink: true,
            };
        } else {
            return {
                title: "Deploy Cirrus Migrate Cloud",
                message: "To get started, deploy and register Cirrus Migrate Cloud on your Windows and Linux hosts.",
                iconPath: EmptyHostsTable,
                actionButton: <NewDeploymentButton />,
            };
        }
    }, [p.relayOnly, openHelpSearch]);

    const getRowActions = (r: GalaxyMigrateDeploymentInfo.AsObject): ActionConfig[] => [
        {
            id: "delete-license",
            name: "Delete Host License",
            action: async () => await deleteLicense.mutateAsync(r.deployment),
            icon: <LicenseIcon />,
            hidden: !r.deployment.license,
        },
        {
            id: "remove",
            name: `Remove Host From Project`,
            action: async () => {
                const confirmed = await addConfirmDialog({
                    title: `Remove Host From Project`,
                    message: (
                        <Box>
                            <span>
                                {`Are you sure you want to remove host`}{" "}
                                <strong>
                                    {r.deployment.systemName} ({r.deployment.connected ? "online" : "offline"})
                                </strong>{" "}
                                {`from this project?`}
                            </span>
                            <br />
                            {!!r.deployment.license && (
                                <Typography color={`warning.main`}>WARNING: Any remaining host license capacity and time will not be recouped.</Typography>
                            )}
                        </Box>
                    ),
                    autoConfirmationQuestionLine: false,
                    typeToConfirm: r.deployment.connected ? r.deployment.systemName : null,
                    okButtonProps: {
                        color: "error",
                    },
                });
                if (confirmed) {
                    await removeDeployment.mutateAsync(r.deployment.systemId);
                }
            },
            hidden: !isOperator,
            icon: <DeleteIcon />,
        },
    ];

    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();

    const getHiddenColumns = () => {
        const hiddenColumns = [];
        if (!isOperator) {
            hiddenColumns.push("actions");
        }
        if (isMobileView) {
            hiddenColumns.push("resource");
        }
        return hiddenColumns;
    };

    const cols = [
        columnHelper.accessor((c) => c, {
            id: "host",
            header: "Host",
            cell: (props) => {
                const deploymentInfo = props.row.original;
                return <HostInfoDisplay deploymentInfo={deploymentInfo} />;
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "resource",
            header: "Resource Usage",
            meta: {
                style: {
                    textAlign: "center",
                },
            },
            cell: (props) => {
                const deploymentInfo = props.row.original;
                const hasCpuPercentage = !!deploymentInfo.deployment.systemUptime?.seconds;

                const memoryUsagePercentage = (deploymentInfo.memoryUsed / deploymentInfo.memory) * 100;
                return (
                    <Stack
                        minWidth={250}
                        alignItems={"flexStart"}
                        sx={{
                            opacity: deploymentInfo.deployment.connected ? 1 : 0.3,
                        }}
                    >
                        {hasCpuPercentage ? (
                            <BlackTooltip
                                title={
                                    <Box>
                                        <Typography variant={"caption"} fontWeight={600}>
                                            {deploymentInfo.cpu}
                                        </Typography>
                                        <br />
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`CPU Model`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <Stack
                                    //sx={getDeploymentConnectionStyle(deploymentInfo.deployment.connected)}
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"flex-end"}
                                >
                                    <Typography variant={"caption"}>{"CPU "}&nbsp;</Typography>
                                    <Stack>
                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {parseCpuCountsFromCpuString(deploymentInfo.cpu).cores} {"Cores"}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}.main`}>
                                                {formatKnownDataType(deploymentInfo.cpuUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={deploymentInfo.cpuUsagePercentage}
                                            color={getResourceUsageColor(deploymentInfo.cpuUsagePercentage)}
                                        />
                                    </Stack>
                                </Stack>
                            </BlackTooltip>
                        ) : null}
                        {deploymentInfo.memoryUsed >= 0 && (
                            <Box pt={1}>
                                <Stack alignItems={"flex-start"} direction={"row"} spacing={1}>
                                    <Typography variant={"caption"}>{"Mem."}</Typography>
                                    <Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={memoryUsagePercentage}
                                            color={getResourceUsageColor(memoryUsagePercentage)}
                                        />
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {`${formatKnownDataType(deploymentInfo.memoryUsed, KnownDataType.CAPACITY)}/${formatKnownDataType(
                                                    deploymentInfo.memory,
                                                    KnownDataType.CAPACITY
                                                )}`}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(memoryUsagePercentage)}.main`}>
                                                {formatKnownDataType(memoryUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                );
            },
        }),

        columnHelper.accessor((c) => c, {
            id: "cluster",
            header: "Cluster",
            meta: {
                style: {
                    textAlign: "center",
                },
            },
            cell: (props) => {
                const deploymentInfo = props.row.original;
                const clusterInfo = deploymentInfo.clusterInfo;
                const clusterIconColor =
                    clusterInfo?.numberOfNodes > clusterInfo?.numberOfOnlineNodes
                        ? theme.palette.error.main
                        : clusterInfo?.numberOfRegisteredNodes < clusterInfo?.numberOfNodes
                        ? theme.palette.warning.main
                        : theme.palette.success.main;
                return clusterInfo ? (
                    <Box minWidth={150}>
                        <Tooltip
                            slotProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor: theme.palette.cirrus.light,
                                        border: `2px solid ${theme.palette.primary.main}`,
                                        borderRadius: 3,
                                        padding: 0,
                                    },
                                },
                            }}
                            title={
                                <Box width={250}>
                                    <ClusterInfo clusterId={clusterInfo.id} projectId={projectId} deploymentConnected={deploymentInfo.deployment.connected} />
                                </Box>
                            }
                        >
                            <Box>
                                <SelectableBox
                                    selected={false}
                                    cardProps={{
                                        sx: {
                                            border: "none",
                                        },
                                    }}
                                >
                                    <Stack
                                        p={1}
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        spacing={1}
                                        sx={{ opacity: deploymentInfo.deployment.connected ? 1 : 0.3 }}
                                    >
                                        <AiOutlineCluster size={24} color={clusterIconColor} />
                                        <Stack direction={"column"}>
                                            <Typography
                                                variant={"caption"}
                                                color={clusterInfo.numberOfRegisteredNodes < clusterInfo.numberOfNodes ? "warning.main" : "inherit"}
                                            >
                                                {`${clusterInfo.numberOfRegisteredNodes} / ${clusterInfo.numberOfNodes} Deployed`}
                                            </Typography>
                                            <Typography
                                                variant={"caption"}
                                                color={clusterInfo.numberOfOnlineNodes < clusterInfo.numberOfNodes ? "error.main" : "inherit"}
                                            >{`${clusterInfo.numberOfOnlineNodes} / ${clusterInfo.numberOfNodes} Online`}</Typography>
                                        </Stack>
                                    </Stack>
                                </SelectableBox>
                            </Box>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                        {"--"}
                    </Box>
                );
            },
        }),
        columnHelper.accessor((c) => c, {
            id: "actions",
            header: "Actions",
            cell: (props) => {
                const deploymentInfo = props.row.original;
                return <ActionMenuButton actions={getRowActions(deploymentInfo)} />;
            },
        }),
    ];

    return (
        <Box pt={2}>
            <QueryTable
                data={deploymentsList.data?.itemsList}
                columns={cols}
                emptyTableConfig={getEmptyTableProps()}
                error={deploymentsList.error}
                isLoading={deploymentsList.isLoading}
                pageCount={deploymentsList.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                refetch={deploymentsList.refetch}
                queryKey={DeploymentQueryKeys.listGmDeployments}
                hiddenColumns={getHiddenColumns()}
            />
        </Box>
    );
});

// ======================
// HostInfoDisplay
// ======================

interface HostInfoDisplayProps {
    deploymentInfo: GalaxyMigrateDeploymentInfo.AsObject;
}

export const HostInfoDisplay: React.FC<HostInfoDisplayProps> = (p) => {
    const { deploymentInfo } = p;
    const goToDeploymentDetails = useNavigateToDeploymentDetails();
    const theme = useTheme();
    return (
        <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Box>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                <OnlineStatusIcon online={deploymentInfo.deployment.connected} size={20} />

                                <Link variant={"body1"} fontWeight={600} onClick={() => goToDeploymentDetails(deploymentInfo.deployment.systemId)}>
                                    {deploymentInfo.deployment.systemName}
                                </Link>
                            </Stack>
                        </Grid>
                        <Grid>
                            <Typography color={"textSecondary"} variant={"subtitle2"}>
                                {"v"}
                                {deploymentInfo.deployment.version}
                            </Typography>
                        </Grid>
                        <Grid>
                            {deploymentInfo.deployment.labelsList.length > 0 && (
                                <Box pt={0.5}>
                                    <BlackTooltip
                                        title={
                                            <Box p={1}>
                                                <Stack spacing={1}>
                                                    {deploymentInfo.deployment.labelsList.map((l) => (
                                                        <LabelChip key={l.name} label={l.name} description={l.description} color={l.color} />
                                                    ))}
                                                </Stack>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Box>
                                            <MdLabelOutline size={20} color={theme.palette.warning.main} />
                                        </Box>
                                    </BlackTooltip>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <GalaxyMigrationDeploymentOSDisplay deploymentInfo={deploymentInfo} />
                    <Box sx={getDeploymentConnectionStyle(deploymentInfo.deployment.connected)} pt={0.5}>
                        <Grid container alignItems={"center"} spacing={1}>
                            {!!deploymentInfo.deployment.cdcRelayServerAddress && (
                                <Grid>
                                    <BlackTooltip
                                        arrow
                                        title={
                                            <Box>
                                                <Typography variant={"subtitle2"} fontWeight={600}>
                                                    {"CDC Management Relay"}
                                                </Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {deploymentInfo.deployment.cdcRelayServerAddress.split("/")[0]}
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        <Chip label={"Relay"} color={"success"} variant={"outlined"} size={"small"} />
                                    </BlackTooltip>
                                </Grid>
                            )}
                            <Grid>
                                <GalaxyMigrationDeploymentEnvironmentDisplay deploymentInfo={deploymentInfo} />
                            </Grid>
                            <Grid>
                                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                    <HiOutlineClock size={16} />
                                    <BlackTooltip
                                        title={
                                            <Box>
                                                <Typography fontWeight={600} variant={"subtitle2"}>
                                                    {"Last Check-In"}
                                                </Typography>
                                                <Typography variant={"caption"}>
                                                    {formatKnownDataType(deploymentInfo.deployment.lastCheckin, KnownDataType.DATE)}
                                                </Typography>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                                textDecoration: "underline",
                                                textDecorationStyle: "dotted",
                                            }}
                                        >
                                            {formatKnownDataType(deploymentInfo.deployment.lastCheckin, KnownDataType.DATE_RELATIVE)}
                                        </Typography>
                                    </BlackTooltip>
                                </Stack>
                            </Grid>
                            {!!deploymentInfo.deployment.systemUptime?.seconds && (
                                <Grid>
                                    <BlackTooltip
                                        title={
                                            <Box>
                                                <Typography fontWeight={600} variant={"subtitle2"}>
                                                    {"Uptime"}
                                                </Typography>
                                                <Typography variant={"caption"}>
                                                    {"Last Reboot: "}
                                                    {formatKnownDataType(
                                                        sub(new Date(), { seconds: deploymentInfo.deployment.systemUptime?.seconds }),
                                                        KnownDataType.DATE
                                                    )}
                                                </Typography>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                                textDecoration: "underline",
                                                textDecorationStyle: "dotted",
                                            }}
                                        >
                                            {"Up "}
                                            {formatDurationFromSeconds(deploymentInfo.deployment.systemUptime?.seconds)}
                                        </Typography>
                                    </BlackTooltip>
                                </Grid>
                            )}

                            <Grid>
                                <BlackTooltip
                                    title={
                                        <Typography variant={"subtitle2"} fontWeight={"600"}>
                                            {"Latency"}
                                        </Typography>
                                    }
                                    arrow
                                >
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{
                                            textDecoration: "underline",
                                            textDecorationStyle: "dotted",
                                        }}
                                    >
                                        {!!deploymentInfo.deployment.connectionLatency
                                            ? formatKnownDataType(deploymentInfo.deployment.connectionLatency, KnownDataType.DURATION_MILLISECONDS)
                                            : null}
                                    </Typography>
                                </BlackTooltip>
                            </Grid>

                            {deploymentInfo.deployment.location && (
                                <Grid>
                                    <BlackTooltip
                                        title={
                                            <Box>
                                                <Typography variant={"subtitle2"} fontWeight={600}>
                                                    {!!deploymentInfo.deployment.location.city
                                                        ? `${deploymentInfo.deployment.location.city}, ${deploymentInfo.deployment.location.region}`
                                                        : null}
                                                </Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    {deploymentInfo.deployment.location.countryName}
                                                </Typography>
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Box pt={0.5}>{deploymentInfo.deployment.location?.countryFlag}</Box>
                                    </BlackTooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
};

// ======================
// GalaxyMigrationDeploymentOSDisplay
// ======================
interface GalaxyMigrationDeploymentOSDisplayProps {
    deploymentInfo: GalaxyMigrateDeploymentInfo.AsObject;
    hideKernel?: boolean;
}

export const GalaxyMigrationDeploymentOSDisplay: React.FC<GalaxyMigrationDeploymentOSDisplayProps> = observer((p) => {
    const r = p.deploymentInfo;
    const os = `${r.osClass}${!p.hideKernel ? ` - ${r.kernel}` : ""}`;
    const isHelper = r.helperNode;
    let content = null;
    if (!isHelper) {
        content = (
            <Stack direction={"row"} alignItems={"center"} pl={0}>
                <Typography variant={"body2"}>
                    <HostOSIcon size={12} os={os} /> &nbsp;&nbsp;
                    {os}
                </Typography>
            </Stack>
        );
    } else {
        const helperName = getGalaxyMigrateHelperNodeOSName(r.helperNodeType.value);
        content = (
            <>
                {r.helperNodeConfigured && <div>{helperName}</div>}
                {!r.helperNodeConfigured && (
                    <div>
                        <ConfigureGalaxyMigrateCmcHelperButton
                            disabled={!r.deployment.connected}
                            systemID={r.deployment.systemId}
                            os={r.helperNodeType.value}
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <Box display={"flex"} alignItems={"center"} sx={getDeploymentConnectionStyle(r.deployment.connected)}>
            {content}
        </Box>
    );
});

// ======================
// GalaxyMigrationDeploymentEnvironmentDisplay
// ======================

interface GalaxyMigrationDeploymentEnvironmentDisplayProps {
    deploymentInfo: GalaxyMigrateDeploymentInfo.AsObject;
}

export const GalaxyMigrationDeploymentEnvironmentDisplay: React.FC<GalaxyMigrationDeploymentEnvironmentDisplayProps> = observer((p) => {
    const r = p.deploymentInfo;
    const env = r.deployment.hostEnvironment.value;

    return (
        <Stack direction={"row"} alignItems={"center"} sx={getDeploymentConnectionStyle(r.deployment.connected)}>
            <HostEnvironmentIcon env={env} size={16} /> &nbsp;&nbsp;
            <Typography variant={"body2"}>{getHostEnvDisplayName(env)}</Typography>
        </Stack>
    );
});

export const getResourceUsageColor = (usagePercent: number) => {
    if (usagePercent < 75) {
        return "success";
    }
    if (usagePercent < 90) {
        return "warning";
    }
    return "error";
};

// ======================
// ClusterInfo
// ======================

interface ClusterInfoProps {
    clusterId: number;
    projectId: string;
    deploymentConnected: boolean;
}

export const ClusterInfo: React.FC<ClusterInfoProps> = (p) => {
    const { clusterId, projectId, deploymentConnected } = p;
    const cluster = useGetGalaxyMigrateDeploymentClusterDetails(projectId, clusterId);
    const clusterInfo = cluster.data?.clusterDetails.clusterInfo;
    const goToHostDetails = useNavigateToDeploymentDetails();
    const theme = useTheme();

    const getUnregisteredHostsList = () => {
        const array = [];
        for (let i = 0; i < clusterInfo?.numberOfNodes - clusterInfo?.numberOfRegisteredNodes; i++) {
            array.push("Unregistered Host");
        }
        return array;
    };

    return (
        <Box
            sx={{
                opacity: deploymentConnected ? 1 : 0.3,
            }}
        >
            <QueryResultWrapper queryResult={cluster}>
                <Box p={2}>
                    <Typography fontWeight={600}>{clusterInfo?.clusterName}</Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {clusterInfo?.clusterInfo.windowsFailoverClusterInfo?.domain}
                    </Typography>
                    {clusterInfo?.clusterType === GmDeploymentClustersType.GmDeploymentClustersType.WINDOWS_FAILOVER_CLUSTER && (
                        <Box pt={1}>
                            <Chip
                                label={"Windows Failover Cluster"}
                                color={"primary"}
                                variant={"filled"}
                                size={"small"}
                                sx={{
                                    borderRadius: 1,
                                }}
                            />
                        </Box>
                    )}
                    <Box pt={2}>
                        <Typography variant={"subtitle2"}>{`Registered (${clusterInfo?.numberOfRegisteredNodes}/${clusterInfo?.numberOfNodes})`}</Typography>
                    </Box>
                    <Box pt={1} width={"100%"}>
                        <OutlinedCard sx={{ maxWidth: "100%", background: "none" }}>
                            <Box p={1}>
                                {cluster.data?.clusterDetails.registeredHostsList.map((host) => {
                                    return (
                                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                            <OnlineStatusIcon online={host.online} />
                                            <Link variant={"subtitle2"} onClick={() => goToHostDetails(host.deployment.systemId)}>
                                                {host.deployment.systemName}
                                            </Link>
                                        </Stack>
                                    );
                                })}
                                {getUnregisteredHostsList().map((h, i) => {
                                    return (
                                        <Stack
                                            key={i}
                                            direction={"row"}
                                            spacing={1}
                                            alignItems={"center"}
                                            sx={{
                                                color: alpha(`#fff`, 0.3),
                                            }}
                                        >
                                            <GoDotFill size={12} />
                                            <Typography variant={"subtitle2"}>{h}</Typography>
                                        </Stack>
                                    );
                                })}
                            </Box>
                        </OutlinedCard>
                    </Box>
                </Box>
                <Box
                    sx={{
                        background: theme.palette.cirrus.main,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        borderTop: `2px solid ${theme.palette.border.main}`,
                    }}
                >
                    <Box p={2}>
                        <Typography fontWeight={600}>{"Clustered Hosts Migration"}</Typography>
                        <Typography variant={"caption"}>{"Discover how to efficiently migrate clustered hosts with our comprehensive guide."}</Typography>
                        <Box pt={2}>
                            <LearnMoreButton articleID={KnownArticle.MIGRATING_CLUSTERS} variant={"outlined"} />
                        </Box>
                    </Box>
                </Box>
            </QueryResultWrapper>
        </Box>
    );
};
