import { create } from "zustand";
import { v4 as uuid } from "uuid";

export interface GlobalProgressState {
    trackers: ProgressTrackerState[];
    removeTracker: (id: string) => void;
    addTracker: (tracker: ProgressTrackerState) => void;
    track: <T>(promise: Promise<T>, message: string) => Promise<T>;
}

export interface ProgressTrackerState {
    id: string;
    message?: string;
}

export const useGlobalProgressState = create<GlobalProgressState>((set, get) => ({
    trackers: [],
    removeTracker: (id: string) => {
        const newTrackers = [...get().trackers];
        const t = get().trackers.findIndex((t) => t.id === id);
        if (t >= 0) {
            newTrackers.splice(t, 1);
        }
        set({
            trackers: newTrackers,
        });
    },
    addTracker: (tracker: ProgressTrackerState) => {
        const newTrackers = [...get().trackers];
        newTrackers.push(tracker);
        set({
            trackers: newTrackers,
        });
    },
    track: async <T>(promise: Promise<T>, message: string = null): Promise<T> => {
        const id = uuid();
        get().addTracker({ id, message });

        try {
            return await promise;
        } catch (e) {
            throw e;
        } finally {
            get().removeTracker(id);
        }
    },
}));
