// Project: GalaxyComplete
// Created: 10/21/20 by sammy
// File: CommonIcons

import * as React from "react";
import { Button, ButtonProps, Icon, IconProps, SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { GrStatusUnknown, GrVmware } from "react-icons/gr";
import { AiFillTool } from "react-icons/ai";
import { MdChevronLeft, MdChevronRight, MdClose, MdDelete, MdDescription, MdEdit, MdRefresh, MdVpnKey } from "react-icons/md";
import { FaUserCog } from "react-icons/fa";
import { FiExternalLink, FiHardDrive, FiLogOut } from "react-icons/fi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import CirrusPngIcon from "../assets/cirrus_icon_white.png";
import { observer } from "mobx-react-lite";
import { SiAmazonaws, SiDigitalocean, SiGooglecloud, SiIbm, SiMicrosoft, SiMicrosoftazure, SiNutanix, SiOpenstack, SiVultr } from "react-icons/si";
import { HiOutlineServer, HiOutlineClipboardList } from "react-icons/hi";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { IconBaseProps } from "react-icons";
import { GoDotFill } from "react-icons/go";

export const UnknownIcons: React.FC<Partial<IconBaseProps>> = (p) => {
    return <GrStatusUnknown {...p} />;
};

export const GenericActionIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <AiFillTool />
        </SvgIcon>
    );
};

export const DeleteIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdDelete />
        </SvgIcon>
    );
};

export const EditIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdEdit />
        </SvgIcon>
    );
};

export const CloseIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdClose />
        </SvgIcon>
    );
};

export const LeftArrow: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdChevronLeft />
        </SvgIcon>
    );
};

export const RightArrow: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdChevronRight />
        </SvgIcon>
    );
};

export const UserSettingsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FaUserCog />
        </SvgIcon>
    );
};

export const LogOutIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FiLogOut />
        </SvgIcon>
    );
};

export const ExternalLinkIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FiExternalLink />
        </SvgIcon>
    );
};

export const HelpIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoMdHelpCircleOutline {...p} />;
};

export const LicenseIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdVpnKey />
        </SvgIcon>
    );
};

export const RefreshIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdRefresh />
        </SvgIcon>
    );
};
export const RefreshButton: React.FC<Partial<ButtonProps>> = (p) => {
    return (
        <Button {...p} startIcon={<MdRefresh />}>
            {p.children || "Refresh"}
        </Button>
    );
};

export const LiveLogIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdDescription />
        </SvgIcon>
    );
};

export const ReportIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <HiOutlineClipboardList {...p} />;
};

// ======================
// HostEnvironmentIcon
// ======================

interface HostEnvironmentIconProps {
    env: DeploymentHostEnvironment.DeploymentHostEnvironment;
}

export const HostEnvironmentIcon: React.FC<HostEnvironmentIconProps & Partial<IconBaseProps>> = observer((p) => {
    const { env, ...props } = p;

    return (
        <>
            {env === DeploymentHostEnvironment.DeploymentHostEnvironment.AWS ? (
                <SiAmazonaws {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.AZURE ? (
                <SiMicrosoftazure {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE ? (
                <GrVmware {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.OPENSTACK ? (
                <SiOpenstack {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.SOFTLAYER ? (
                <SiIbm {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.DO ? (
                <SiDigitalocean {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.VULTR ? (
                <SiVultr {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.HYPERV ? (
                <SiMicrosoft {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.GCP ? (
                <SiGooglecloud {...props} />
            ) : env === DeploymentHostEnvironment.DeploymentHostEnvironment.NUTANIX ? (
                <SiNutanix {...props} />
            ) : (
                <HiOutlineServer {...props} />
            )}
        </>
    );
});

// ================
// Storage Entities
// ================

export const DiskIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FiHardDrive />
        </SvgIcon>
    );
};

// ======================
// CirrusIcon
// ======================

export const CirrusIcon: React.FC<Partial<IconProps>> = (p) => {
    return (
        <Icon
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            {...p}
        >
            <img src={CirrusPngIcon} alt={"Cirrus Data"} height={"100%"} />
        </Icon>
    );
};

// ======================
// OnlineStatusIcon
// ======================

interface OnlineStatusIconProps {
    online: boolean;
    size?: number;
}

export const OnlineStatusIcon: React.FC<OnlineStatusIconProps> = (p) => {
    const { online, size } = p;
    const theme = useTheme();
    return online ? <GoDotFill size={size || 12} color={theme.palette.success.main} /> : <GoDotFill size={size || 12} color={theme.palette.error.main} />;
};
