import * as React from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import * as yup from "yup";
import { FieldArray, Form, Formik, FormikValues } from "formik";
import { FormTextField } from "../../../../common/form/FormComponents";
import { useMountEffect } from "../../../../common/hooks/hookslib";

const useNetappStyles = () => {
    return {
        formTableCell: {
            verticalAlign: "top",
            paddingTop: 16,
        },
    };
};

// ======================
// NetAppAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

export const NetappAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.netapp;

    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setNetapp(new AutoAlloc.VolumeParams.NetApp().setQosPolicyName(defaultParams.qosPolicyName));
        }
    });

    return (
        <>
            <Typography color={"textSecondary"}>
                {`NetApp Data Disks matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            <br />
            <NetappVolumeTableForm allocateFunc={allocateNow} state={state} />
        </>
    );
});

// // ======================
// // VolumeTableForm
// // ======================

export const NetappVolumeTableForm: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const styles = useNetappStyles();
    const defaultParams = p.state.selectedIntegration.defaultVolumeParams.netapp;

    const getInitialValues = () => {
        const vols = p.state.sourceDevices.map((d) => {
            return {
                qosPolicyName: defaultParams.qosPolicyName,
            };
        });
        return {
            vols: vols,
        };
    };

    const validationSchema = yup.object({
        vols: yup.array(
            yup.object({
                qosPolicyName: yup.string().notRequired(),
            })
        ),
    });

    return (
        <Box pt={2}>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={validationSchema}
                onSubmit={async (values: FormikValues) => {
                    p.state.sourceDevices.forEach((d, i) => {
                        d.autoAllocParams.getNetapp().setQosPolicyName(values.vols[i].qosPolicyName);
                    });
                    await p.allocateFunc();
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Source Volume</TableCell>
                                            <TableCell>Capacity</TableCell>
                                            <TableCell>QOS Policy Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <FieldArray
                                            name={"vols"}
                                            render={(helpers) => {
                                                return p.state.sourceDevices.map((d, i) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell>{d.source.getBlockDevice().getDeviceName()}</TableCell>
                                                            <TableCell>
                                                                {formatKnownDataType(d.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}
                                                            </TableCell>
                                                            <TableCell sx={styles.formTableCell}>
                                                                <FormTextField label={"QOS Policy Name"} name={`vols[${i}].qosPolicyName`} />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                });
                                            }}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box pt={2} pb={2}>
                                <Button color={"primary"} variant={"contained"} type={"submit"}>
                                    {`Allocate Volumes (${p.state.sourceDevices.length})`}
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
});
