import { observer } from "mobx-react-lite";
import { useListUserSessions, useRevokeMyUserSession } from "./user_hooks";
import { QueryTable } from "../../common/table/QueryTable";
import { createColumnHelper } from "@tanstack/react-table";
import { UserSession } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { Button, Typography } from "@mui/material";
import { useAppServices } from "../app/services";

// ======================
// UserSessionsList
// ======================

interface UserSessionsListProps {}

export const UserSessionsList: React.FC<UserSessionsListProps> = observer((p) => {
    const { dialogService } = useAppServices();

    const queryResult = useListUserSessions();
    const columnHelper = createColumnHelper<UserSession.AsObject>();
    const revokeSessionFn = useRevokeMyUserSession();

    const revokeSession = async (sessionId: number, ip: string, os: string) => {
        const confirmed = await dialogService.addConfirmDialog({
            title: "Revoke Access",
            message: `This will remove access from your Cirrus Data Cloud Account from the device ${ip} (${os}).`,
            okButtonLabel: "Revoke Access",
        });
        if (confirmed) {
            revokeSessionFn.mutate(sessionId);
        }
    };

    const cols = [
        columnHelper.accessor((r) => r.createdAt, {
            id: "createdAt",
            cell: (props) => formatKnownDataType(convertTimestampObjectToDate(props.getValue()), KnownDataType.DATE),
            header: "Session Created",
        }),
        columnHelper.accessor((r) => r.ip, {
            id: "ipAddr",
            cell: (props) => props.getValue(),
            header: "IP Address",
        }),
        columnHelper.accessor((r) => r.browser, {
            id: "browser",
            cell: (props) => props.getValue(),
            header: "Browser",
        }),
        columnHelper.accessor((r) => r.os, {
            id: "os",
            cell: (props) => props.getValue(),
            header: "OS",
        }),
        columnHelper.accessor((r) => r.location, {
            id: "location",
            cell: (props) => <Typography>{`${props.getValue().city}, ${props.getValue().country} ${props.getValue().flag}`} </Typography>,
            header: "Location",
        }),
        columnHelper.accessor((r) => r, {
            id: "revoke",
            cell: (props) => (
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => revokeSession(props.row.original.id, props.row.original.ip, props.row.original.os)}
                >{`Revoke`}</Button>
            ),
            header: "Location",
        }),
    ];

    return (
        <QueryTable
            data={queryResult.data?.sessionsList}
            isLoading={queryResult.isLoading}
            isError={queryResult.isError}
            columns={cols}
            title={`Where You're Logged In`}
        />
    );
});
