// Project: GalaxyComplete
// Created: 10/27/20 by sammy
// File: GmMigrationSessionVolumeQosScreen

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../../app/services";
import { useParams } from "react-router-dom";
import { renderServerDataWithLoadingBox, useInitData } from "../../../core/data/DataLoaderHooks";
import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import { Box, Button, Card, List, ListItem, ListItemText, ListSubheader, SvgIcon, SvgIconProps, Theme, Typography, useTheme } from "@mui/material";
import { GetMigrationSessionVolumeQosStateResponse, SyncQos } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { GmMigrationSessionVolumeState } from "../../GmMigrationService";
import { formatBool, formatKnownDataType, formatProtoDuration, KnownDataType } from "../../../../common/utils/formatter";
import { format } from "date-fns";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { useIsSupportView } from "../../../auth/AuthenticatedViews";
import { AiOutlineLineChart } from "react-icons/ai";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigateToMigrationSessionDetails } from "../../CmcMigrationCommon";
import { QosStatChartsGroup } from "./GmSessionVolumeQosCharts";
import { getVolQosStyles, NoDataCard } from "./GmSessionVolumeQosCommon";
import Grid from "@mui/material/Grid2";

// ======================
// GmSessionVolumeQosScreen
// ======================
interface GmSessionVolumeQosScreenProps {}

export const GmSessionVolumeQosScreen: React.FC<GmSessionVolumeQosScreenProps> = observer((p) => {
    const { gmMigrationService } = useAppServices();
    const { sessionId, sessionVolumeUUID } = useParams();
    const navigateBack = useNavigateToMigrationSessionDetails();

    useInitData({
        init: () => gmMigrationService.currentSessionVolumeState.initVolumeState(sessionVolumeUUID, sessionId),
        poll: async () => {
            if (!!gmMigrationService.currentSessionVolumeState.currentSessionVolumeUUID) {
                await gmMigrationService.currentSessionVolumeState.sessionVolumeQoSState.fetchData();
                await gmMigrationService.currentSessionVolumeState.sessionVolumeQoSEntries.fetchData();
            }
        },
        pollInterval: 1,
    });
    return (
        <ScreenContainer>
            <Button startIcon={<FiArrowLeft />} onClick={() => navigateBack()}>
                Back To Session Details
            </Button>
            <ScreenTitleBar title={`Cirrus Migrate Cloud Session Volume iQoS`} />
            <VolQosView volumeState={gmMigrationService.currentSessionVolumeState} />
        </ScreenContainer>
    );
});

// ======================
// VolQosView
// ======================

interface VolQosViewProps {
    volumeState?: GmMigrationSessionVolumeState;
}

export const VolQosView: React.FC<VolQosViewProps> = observer((props) => {
    const { volumeState } = props;
    const { gmMigrationService } = useAppServices();
    const isInternalUser = useIsSupportView();

    return (
        <>
            <Grid container spacing={3}>
                {isInternalUser && (
                    <Grid size={12}>
                        <Box>
                            {renderServerDataWithLoadingBox(volumeState.sessionVolumeQoSState, (data) => {
                                return <CurrentConfig data={data} />;
                            })}
                        </Box>
                    </Grid>
                )}
                {isInternalUser && (
                    <Grid
                        size={{
                            xs: 12,
                            lg: 4,
                        }}
                    >
                        <Box>
                            {renderServerDataWithLoadingBox(volumeState.sessionVolumeQoSState, (data) => {
                                return <ThrottleInfoView data={data} />;
                            })}
                        </Box>
                    </Grid>
                )}

                <Grid
                    size={{
                        xs: 12,
                        lg: isInternalUser ? 8 : 12,
                    }}
                >
                    {renderServerDataWithLoadingBox(gmMigrationService.currentSessionVolumeState.sessionVolumeQoSEntries, (data) => {
                        return <QosStatChartsGroup data={data} />;
                    })}
                </Grid>
            </Grid>
        </>
    );
});

// ======================
// CurrentConfig
// ======================

interface CurrentConfigProps {
    data: GetMigrationSessionVolumeQosStateResponse;
}

export const CurrentConfig: React.FC<CurrentConfigProps> = observer((props) => {
    const { data } = props;
    const config = data.getCurrentConfig();
    return (
        <Card>
            <ListSubheader>
                <Box>Current Config | Enabled: {formatBool(config.getEnabled())}</Box>
            </ListSubheader>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                        lg: 3,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Rolling Average Period"}
                                primary={formatProtoDuration(config.getPioRollingAveragePeriod())}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Slope Calculation Period"}
                                primary={formatProtoDuration(config.getPioSlopeCalculationPeriod())}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Slope Stability Period"}
                                primary={formatProtoDuration(config.getPioSlopeStabilityPeriod())}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                        lg: 3,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Slope Stability Factor"}
                                primary={formatKnownDataType(config.getPioSlopeStabilityFactor(), KnownDataType.CAPACITY)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Rate Changes Trigger"}
                                primary={formatKnownDataType(config.getChangedPioRateTrigger(), KnownDataType.CAPACITY)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary={"Sample Period"} primary={formatProtoDuration(config.getSamplePeriod())} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                        lg: 3,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={"Migration IO Rolling Average Period"}
                                primary={formatProtoDuration(config.getCioRollingAveragePeriod())}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Migration IO Increment Percent"}
                                primary={formatKnownDataType(config.getPercentUpCio(), KnownDataType.PERCENT)}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Absolute Max Migration IO Rate"}
                                primary={formatKnownDataType(config.getAbsoluteMaxCioRate(), KnownDataType.THROUGHPUT)}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                        lg: 3,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText secondary={"Min Test Time Gap"} primary={formatProtoDuration(config.getMinTestTimeGap())} />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary={"Max Test Time Gap"} primary={formatProtoDuration(config.getMaxTestTimeGap())} />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary={"Tolerance Percent"} primary={formatKnownDataType(config.getTolerancePercent(), KnownDataType.PERCENT)} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Card>
    );
});

// ======================
// ThrottleView
// ======================

interface ThrottleInfoViewProps {
    data: GetMigrationSessionVolumeQosStateResponse;
}

export const ThrottleInfoView: React.FC<ThrottleInfoViewProps> = observer((props) => {
    const { data } = props;
    return (
        <Box>
            <TriggerMonitor data={data.getQosStateSnapshot()} />
            <br />
            <TestResult data={data.getQosStateSnapshot()} />
            <br />
            <ActionsList data={data.getQosStateSnapshot()} />
        </Box>
    );
});

// ======================
// TriggerMonitor
// ======================

interface TriggerMonitorProps {
    data: SyncQos.StateSnapshot;
}

export const TriggerMonitor: React.FC<TriggerMonitorProps> = observer((props) => {
    const { data } = props;

    return (
        <Card>
            <ListSubheader>Throttle Test Trigger Monitor</ListSubheader>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Rate Change"}
                                primary={`${formatKnownDataType(data.getTestDecision().getPioRateChangesSinceAdjustment(), KnownDataType.THROUGHPUT)}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Production IO Stability"}
                                primary={`${formatKnownDataType(data.getTestDecision().getPioRateStability(), KnownDataType.THROUGHPUT)}`}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText secondary={"Time Since Last Test"} primary={formatProtoDuration(data.getTestDecision().getDurationSinceLastTest())} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Card>
    );
});

// ======================
// TestResult
// ======================

interface TestResultProps {
    data: SyncQos.StateSnapshot;
}

export const TestResult: React.FC<TestResultProps> = observer((props) => {
    const { data } = props;
    const getCycleInfo = () => {
        if (data.getCurrentTestCycle()) {
            return data.getCurrentTestCycle();
        } else if (data.getPreviousTestCycle()) {
            return data.getPreviousTestCycle();
        } else {
            return null;
        }
    };

    if (!getCycleInfo()) {
        return <NoDataCard label={"No Test Decision Data"} />;
    }

    const getThrottleSet = () => {
        if (getCycleInfo().getThrottleThroughputBefore() === 0) {
            return "Unlimited";
        } else {
            return formatKnownDataType(getCycleInfo().getThrottleThroughputBefore(), KnownDataType.THROUGHPUT);
        }
    };

    return (
        <Card>
            <ListSubheader>Test Result</ListSubheader>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText secondary={"Current Loop"} primary={`${getCycleInfo().getCycleNumber()}`} />
                        </ListItem>
                        <ListItem>
                            <ListItemText secondary={"Throttle Set"} primary={getThrottleSet()} />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"Rate Change"}
                                primary={`${formatKnownDataType(getCycleInfo().getPioRateChangesSinceTest(), KnownDataType.THROUGHPUT)}`}
                            />
                        </ListItem>
                    </List>
                </Grid>

                <Grid
                    size={{
                        xs: 12,
                        sm: 6,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemText
                                secondary={"Decision"}
                                primary={`${formatKnownDataType(getCycleInfo().getPioRateChangesSinceTest(), KnownDataType.THROUGHPUT)}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                secondary={"New Throttle"}
                                primary={`${formatKnownDataType(getCycleInfo().getThrottleThroughputAfter(), KnownDataType.THROUGHPUT)}`}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Card>
    );
});

// ======================
// ActionsList
// ======================

interface ActionsListProps {
    data: SyncQos.StateSnapshot;
}

export const ActionsList: React.FC<ActionsListProps> = observer((props) => {
    const { data } = props;
    const styles = getVolQosStyles();
    const getCycleInfo = () => {
        if (data.getCurrentTestCycle()) {
            return data.getCurrentTestCycle();
        } else if (data.getPreviousTestCycle()) {
            return data.getPreviousTestCycle();
        } else {
            return null;
        }
    };

    if (!getCycleInfo()) {
        return <NoDataCard label={"No Actions Data"} />;
    }
    return (
        <Card>
            <ListSubheader>Actions</ListSubheader>
            <Box p={2}>
                <Timeline>
                    {getCycleInfo()
                        .getActionsList()
                        .map((action, i) => {
                            return (
                                <TimelineItem key={1}>
                                    <TimelineOppositeContent sx={styles.timelineDate}>{format(action.getTime().toDate(), "HH:mm:ss")}</TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent sx={styles.timelineMessage}>
                                        <Box p={0}>{action.getMessage()}</Box>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                </Timeline>
            </Box>
        </Card>
    );
});

const getChartColors = (t: Theme) => ({
    primary: t.palette.secondary.main,
    secondary: t.palette.secondary.light,
});
const getColors = (colors: { [key: string]: string }) => (data: any) => colors[data.color];

export const IQosIcon: React.FC<Partial<SvgIconProps>> = (props) => {
    return (
        <SvgIcon {...props}>
            <AiOutlineLineChart />
        </SvgIcon>
    );
};
