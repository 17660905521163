// Project: GalaxyComplete
// Created: 9/24/20 by sammy
// File: TopBar

import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    SvgIcon,
    Toolbar,
    Slide,
    useScrollTrigger,
    AppBar,
    Card,
    Theme,
    useTheme,
    Stack,
    ButtonBase,
    Link,
    Tooltip,
    Divider,
    Chip,
} from "@mui/material";
import { CurrentUserInfo } from "./CurrentUserInfo";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { ComponentType, useCallback, useEffect, useState } from "react";

import { useAppServices } from "../app/services";
import { CDSLogo, CDSPrivateEditionLogo, LightModeIcon } from "./LayoutCommon";
import { MdMenu, MdOutlineSupportAgent } from "react-icons/md";
import { useRightSideDrawerWidth } from "./MainLayout";
import { useMountEffect } from "../../common/hooks/hookslib";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { ProjectIcon, useNavigateToProjectListScreen } from "../project/ProjectCommon";
import { AiOutlineProject } from "react-icons/ai";
import { APP_ROUTES } from "../app/AppRoutes";
import { useMatch } from "react-router-dom";
import { CdsSupportStaffView, useNavigateToSupportProjects } from "../support/SupportCommon";
import { SupportLink } from "./SideMenu";
import { HELP_CENTER_URL, useNavigateToExternalLink } from "../help/HelpCommon";
import { HelpIcon } from "../../common/CommonIcons";
import { IconBaseProps } from "react-icons";
import { SupportAgent } from "@mui/icons-material";
import { LightDivider } from "../../common/misc";
import { useCurrentProject, useCurrentProjectID, useCurrentProjectState } from "../project/CurrentProjectState";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { useGetMyProjectRole } from "../project/project_hooks";
import { renderProjectUserRole } from "../settings/ProjectUsers";

// ======================
// TopBar
// ======================

interface TopBarProps {
    isDesktop: boolean;
    isPrivateEdition: boolean;
    isRightSideDrawerOpen: boolean;
}

export const TopBar: React.FC<TopBarProps> = observer(({ ...p }) => {
    const { isDesktop, isRightSideDrawerOpen } = p;
    const theme = useTheme();
    const rightSideDrawerWidth = useRightSideDrawerWidth();
    const isPrivateEdition = getIsPrivateEdition();
    const goBackToProjectPage = useNavigateToProjectListScreen();
    const goToSupportProjects = useNavigateToSupportProjects();

    const getTopbarStyles = useCallback(() => {
        if (isDesktop) {
            return {
                background: (t: Theme) => t.palette.cirrus.main,
                zIndex: (t: Theme) => t.zIndex.appBar,
                height: (t: Theme) => t.spacing(9),
            };
        } else {
            return {
                background: (t: Theme) => t.palette.cirrus.medium,
                zIndex: (t: Theme) => t.zIndex.appBar,
            };
        }
    }, [isDesktop]);

    const currentProject = useCurrentProject();
    const myProjectRole = useCurrentProjectState((s) => s.myRole);

    const topBarMenu = isDesktop ? (
        <>
            {isPrivateEdition ? (
                <Link onClick={goBackToProjectPage}>
                    <CDSPrivateEditionLogo
                        imageAttributes={{
                            width: "224px",
                            height: "100%",
                        }}
                    />
                </Link>
            ) : (
                <Stack direction={"row"} spacing={1}>
                    <CDSLogo
                        imageAttributes={{
                            width: "224px",
                            height: "100%",
                        }}
                    />
                    <TopBarIconButton onClick={goBackToProjectPage} icon={ProjectIcon} label={"My Projects"} tooltipLabel={"My Projects"} />
                    <CdsSupportStaffView>
                        <TopBarIconButton
                            color={"red"}
                            onClick={goToSupportProjects}
                            icon={MdOutlineSupportAgent}
                            tooltipLabel={"My Support Projects"}
                            label={"Support Projects"}
                        />
                    </CdsSupportStaffView>
                    {!!currentProject && (
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <Stack pr={1} pl={1} direction={"column"} justifyContent={"center"}>
                                <Box height={40}>
                                    <Divider sx={{ borderColor: (t: Theme) => t.palette.cirrus.light }} orientation={"vertical"} />
                                </Box>
                            </Stack>
                            <Card
                                elevation={0}
                                sx={{
                                    border: `1px solid rgba(255,255,255,.2)`,
                                }}
                            >
                                <Stack direction={"row"} alignItems={"center"} spacing={1} p={1}>
                                    <Typography fontWeight={500}>{currentProject.getInfo().getName()}</Typography>
                                    <Chip size={"small"} label={renderProjectUserRole(myProjectRole)} />
                                </Stack>
                            </Card>
                        </Stack>
                    )}
                </Stack>
            )}
        </>
    ) : (
        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
            <Box pr={1}>
                <MobileMenuIcon />
            </Box>
            <CDSLogo
                imageAttributes={{
                    width: "200px",
                    height: "100%",
                }}
            />
        </Box>
    );

    return (
        <AppBar
            elevation={0}
            sx={{
                transition: theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                ...(isRightSideDrawerOpen && {
                    width: `calc(100% - ${rightSideDrawerWidth}px)`,
                    marginRight: `${rightSideDrawerWidth}px`,
                    transition: theme.transitions.create(["margin", "width"], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }),
                borderBottom: (t: Theme) => `1.5px solid ${t.palette.cirrus.light}`,
            }}
        >
            <Toolbar sx={getTopbarStyles()}>
                <Box flexGrow={1} alignItems={"center"} display={"flex"}>
                    {topBarMenu}
                </Box>
                <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
                    <CurrentUserInfo isDesktop={isDesktop} />
                </Stack>
            </Toolbar>
        </AppBar>
    );
});

// ======================
// CurrentTimeDisplay
// ======================
interface CurrentTimeDisplayProps {}

const CurrentTimeDisplay: React.FC<CurrentTimeDisplayProps> = observer((p) => {
    const [currentTime, setTime] = useState(new Date());

    useMountEffect(() => {
        let interval = window.setInterval(() => setTime(new Date()), 1000);
        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    });

    return (
        <Box>
            <Typography>{format(currentTime, "PPPPpppp")}</Typography>
        </Box>
    );
});

// ======================
// MobileMenuIcon
// ======================

interface MobileMenuIconProps {}

export const MobileMenuIcon: React.FC<MobileMenuIconProps> = observer((props) => {
    const { appGlobalService } = useAppServices();

    return (
        <IconButton onClick={() => appGlobalService.openSideBar()} aria-label={"mobileSideMenu"}>
            <SvgIcon>
                <MdMenu />
            </SvgIcon>
        </IconButton>
    );
});

// ======================
// SupportButton
// ======================

interface SupportButtonProps {}

export const SupportButton: React.FC<SupportButtonProps> = (p) => {
    const nav = useNavigateToExternalLink(HELP_CENTER_URL);

    return <TopBarIconButton onClick={nav} icon={HelpIcon} label={"Help"} tooltipLabel={"Help Center"} />;
};

// ======================
// TopBarIconButton
// ======================

interface TopBarIconButtonProps {
    onClick: () => void;
    icon: ComponentType<IconBaseProps>;
    label: string;
    tooltipLabel?: string;
    color?: string;
}

export const TopBarIconButton: React.FC<TopBarIconButtonProps> = (p) => {
    const { onClick, label, tooltipLabel, color } = p;

    return (
        <Tooltip title={tooltipLabel || label}>
            <Box>
                <ButtonBase
                    sx={{
                        borderRadius: 1,
                        "&:hover": {
                            backgroundColor: (t: Theme) => t.palette.action.hover,
                        },
                        minWidth: "60px",
                    }}
                    onClick={onClick}
                >
                    <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} p={1}>
                        <p.icon size={20} color={color} />
                        <Typography
                            variant={"caption"}
                            sx={{
                                color: color,
                            }}
                        >
                            {label}
                        </Typography>
                    </Stack>
                </ButtonBase>
            </Box>
        </Tooltip>
    );
};
