// ======================
// CmoMigrationSystemWarningsScreen
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Box, Card, ListSubheader } from "@mui/material";
import { useGetCmoSystemWarningsSummary, useListCmoSystemWarnings } from "./cmo_migration_hooks";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { CmoMigrationActivitesTimeline } from "./activities/CmoMigrationActivitiesTimeline";
import { CmoMigrationWarningsSummary } from "./activities/CmoMigrationWarningsSummary";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";

interface CmoMigrationSystemWarningsScreenProps {
    projectId: string;
    systemId?: string;
}

export const CmoMigrationSystemWarningsScreen: React.FC<CmoMigrationSystemWarningsScreenProps> = (p) => {
    const { projectId, systemId } = p;
    const warningsSummary = useGetCmoSystemWarningsSummary(projectId, systemId);

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"System Warnings"} />
            <CmoMigrationWarningsSummary projectId={projectId} />
            <Box pt={2}>
                <Card>
                    <ListSubheader>{"Recent System Warnings"}</ListSubheader>
                    <CmoMigrationActivitesTimeline type={"warnings"} projectId={projectId} pgSize={30} />
                </Card>
            </Box>
        </ScreenContainer>
    );
};
