import { useGrpcApiStore } from "../grpc/grpcApiStore";
import {
    ApproveOperationTask,
    CancelOperation,
    CreateOperations,
    GetOperationConfig,
    GetOperationDetails,
    ListOperationLogLines,
    ListProjectOperations,
    RetryOperation,
    UpdateOperationConfig,
} from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { MigrateOpsMutationKeys, MigrateOpsQueryKeys } from "../../common/QueryKeys";
import { OperationLogLine } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { JavaScriptValue } from "google-protobuf/google/protobuf/struct_pb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { create } from "zustand";
import { GetOperationTaskFact } from "gc-web-proto/galaxycompletepb/apipb/support_api_pb";
import { ArchiveOperation } from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";

export const useListMigrateOps = (projectId: string, includeArchived: boolean, page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const sortFilterState = useGlobalTableSortFilterState().tableStateMap.get(MigrateOpsQueryKeys.listProjectOperations);
    const req = new ListProjectOperations.Request()
        .setProjectId(projectId)
        .setIncludeArchived(includeArchived)
        .setPageParams(new PagerParams().setPage(page).setPerPage(perPage));
    const queryFn = async () => {
        if (sortFilterState.sortState) {
            req.setSortParams(sortFilterState.sortFilterConfig.sort.getSortParam(sortFilterState.sortState));
        }
        for (let filter of sortFilterState.filters || []) {
            filter.fieldConfig.addFilterToRequest(req, filter.param);
        }

        const res = await apis.operationService.listProjectOperations(req, null);
        return res?.toObject();
    };

    return useQuery({
        queryKey: [
            MigrateOpsQueryKeys.listProjectOperations,
            projectId,
            includeArchived,
            page,
            perPage,
            sortFilterState.sortState?.field.label,
            sortFilterState.sortState?.descending.label,
            sortFilterState.filters.map((f) => `${f.fieldConfig.label}: ${f.param.toObject().op} ${f.param.toObject().value}`),
        ],
        queryFn: queryFn,
        refetchInterval: 30 * 1000,
    });
};
export const useGetMigrateOpDetails = (opId: number, refetchInterval?: number) => {
    const apis = useGrpcApiStore();
    const req = new GetOperationDetails.Request().setOperationId(opId).setIncludeOperationConfig(true);
    const queryFn = async () => {
        const res = await apis.operationService.getOperationDetails(req, null);
        return res?.toObject();
    };
    return useQuery({
        queryKey: [MigrateOpsQueryKeys.getOperationDetails, opId],
        queryFn: queryFn,
        refetchInterval: refetchInterval || 60 * 1000,
    });
};
export const useGetMigrateOpConfig = (opId: number) => {
    const apis = useGrpcApiStore();
    const req = new GetOperationConfig.Request().setOperationId(opId).setReturnYamlString(true);
    const queryFn = async () => {
        const res = await apis.operationService.getOperationConfig(req, null);
        return res?.toObject();
    };
    return useQuery({
        queryKey: [MigrateOpsQueryKeys.getOperationConfig, opId],
        queryFn: queryFn,
    });
};
export type CustomOperationLogLine = Omit<OperationLogLine.AsObject, "data"> & { data: { [key: string]: JavaScriptValue } };
export type ListOperationLogLinesResponse = Omit<ListOperationLogLines.Response.AsObject, "itemsList"> & { itemsList: Array<CustomOperationLogLine> };

export const useListOperationLogLines = (opId: number, taskId?: number, queryInterval?: number) => {
    const apis = useGrpcApiStore();
    const req = new ListOperationLogLines.Request().setOperationId(opId).setPart(1);

    if (!!taskId) {
        req.setOperationTaskId(taskId);
    }
    const queryFn = async () => {
        const res = await apis.operationService.listOperationLogLines(req, null);
        const resObject: ListOperationLogLinesResponse = {
            ...res.toObject(),
            itemsList: res.getItemsList().map((item): CustomOperationLogLine => {
                return {
                    ...item.toObject(),
                    data: item?.getData()?.toJavaScript(),
                };
            }),
        };
        return resObject;
    };

    return useQuery({
        queryKey: [MigrateOpsQueryKeys.listOperationLogLines, opId, taskId],
        queryFn: queryFn,
        refetchInterval: queryInterval || 60 * 1000,
    });
};

export const useCreateNewOperationFromYaml = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const projectId = useCurrentProjectID();
    const mutationFn = async (yamlString: string) => {
        const req = new CreateOperations.Request().setYamlString(yamlString).setProjectId(projectId);
        const res = await apis.operationService.createOperations(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.createOperationFromYaml],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.listProjectOperations],
            });
        },
    });
};

export const useRetryOperation = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (opId: number) => {
        const req = new RetryOperation.Request().setOperationId(opId);
        const res = await apis.operationService.retryOperation(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.retryOperation],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.getOperationDetails],
            });
        },
    });
};

export const useApproveOperationTask = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (req: ApproveOperationTask.Request) => {
        const res = await apis.operationService.approveOperationTask(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.approveTask],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.getOperationDetails],
            });
        },
    });
};

export const useGetMigrateOpsDocs = () => {
    const queryFn = async () => {
        const res = await fetch("/apidocs/apidocs.swagger.json", {});
        return res.json();
    };

    return useQuery({
        queryKey: [MigrateOpsQueryKeys.getOperationDocs],
        queryFn: queryFn,
    });
};

export const useUpdateOperationConfig = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (req: UpdateOperationConfig.Request) => {
        const res = await apis.operationService.updateOperationConfig(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.updateOperationConfig],
        mutationFn: mutationFn,
        onSuccess: async (data, variables) => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.getOperationConfig, variables.getOperationId()],
            });
        },
    });
};

export const useCancelOperation = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (opId: number) => {
        const req = new CancelOperation.Request().setOperationId(opId);
        const res = await apis.operationService.cancelOperation(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.cancelOperation],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.getOperationDetails],
            });
        },
    });
};

export const useGetOperationTaskFacts = (taskId: number) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new GetOperationTaskFact.Request().setOperationTaskId(taskId);
        const res = await apis.supportService.getOperationTaskFact(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [MigrateOpsQueryKeys.getOperationTaskFacts, taskId],
        queryFn: queryFn,
    });
};

interface MigrateOpsDocsDrawerState {
    migrateOpsDocsDrawerOpen: boolean;
    setMigrateOpsDocsDrawerOpen: (open: boolean) => void;
}

export const useMigrateOpsDocsDrawerState = create<MigrateOpsDocsDrawerState>((set) => ({
    migrateOpsDocsDrawerOpen: false,
    setMigrateOpsDocsDrawerOpen: (open: boolean) =>
        set({
            migrateOpsDocsDrawerOpen: open,
        }),
}));

export const useArchiveOperation = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (opId: number) => {
        const req = new ArchiveOperation.Request().setOperationId(opId);
        const res = await apis.operationService.archiveOperation(req, null);
        return res?.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [MigrateOpsMutationKeys.archiveOperation],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.getOperationDetails],
            });
            await queryClient.refetchQueries({
                queryKey: [MigrateOpsQueryKeys.listProjectOperations],
            });
        },
    });
};
