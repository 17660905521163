import { IntegrationAlertConfig } from "./IntegrationsCommon";
import { create } from "zustand";

interface IntegrationRedirectState {
    redirectFunc: () => void;
    alertConfig: IntegrationAlertConfig;
    preselectedHost: string;
    resetIntegrationRedirectState: () => void;
    initIntegrationRedirectState: (params: {
        redirectFunc: IntegrationRedirectState["redirectFunc"];
        alertConfig: IntegrationRedirectState["alertConfig"];
        preselectedHost: IntegrationRedirectState["preselectedHost"];
    }) => void;
}

export const useIntegrationRedirectState = create<IntegrationRedirectState>((set) => ({
    redirectFunc: () => {},
    alertConfig: null,
    preselectedHost: "",
    resetIntegrationRedirectState: () => set({ alertConfig: null, preselectedHost: "", redirectFunc: () => {} }),
    initIntegrationRedirectState: (params: { redirectFunc: () => void; alertConfig: IntegrationAlertConfig; preselectedHost: string }) => set({ ...params }),
}));
