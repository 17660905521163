import { ServerData, ServerDataFetcherFunc } from "./ServerData";
import { computed, makeAutoObservable, makeObservable, observable } from "mobx";
import { FilterParams, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { FilterState, SortState, TableState } from "../../../common/table/DataTable";
import { DeploymentHostEnvironmentFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { MtdiOsTypeFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb";
import { Enum } from "google-protobuf/google/protobuf/type_pb";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
export type EnumFilter = any;

export type FilterParamType =
    | FilterParams.StringFilter
    | FilterParams.DurationFilter
    | FilterParams.DateFilter
    | FilterParams.NumberFilter
    | FilterParams.BoolFilter
    | FilterParams.SimpleStringFilter
    | EnumFilter;

export interface ColumnSortFilterConfig<RequestType, SortParamType, EnumFilterType, EnumType> {
    sort: SortConfig<SortParamType>;
    filter: ListFilterableField<RequestType, EnumFilterType, EnumType>[];
}

export interface SortConfig<SortParamType> {
    defaultSort: ListSortableField;
    defaultSortDesc?: boolean;
    sortFields: ListSortableField[];
    getSortParam: (s: SortState) => SortParamType;
}

export interface ListSortableField {
    fieldId: any; // enum sort field
    label: string;
}

export interface FilterableFieldBase {
    fieldId: any; // one of api's FieldCase
    label: string;
    formatDisplayValue?: (value: any) => string;
    helperText?: string;
}

export type FilterType = "string" | "bool" | "simpleString" | "date" | "duration" | "number" | "enum" | "simpleNumber";

export interface StringFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "string";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.StringFilter) => void;
}

export interface EnumFilterField<RequestType, EnumFilterType, EnumType> extends FilterableFieldBase {
    filterType: "enum";
    enumLabel?: string;
    enumValuesList?: Array<number | string>;
    createParamFromFormValue?: (value: EnumType) => EnumFilterType;
    getFormValueDisplayLabel?: (value: EnumType) => string;
    addFilterToRequest: (request: RequestType, filterParam: EnumFilterType) => void;
}

export interface SimpleStringFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "simpleString";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.SimpleStringFilter) => void;
}

export interface DateFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "date";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.DateFilter) => void;
}

export interface BoolFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "bool";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.BoolFilter) => void;
}

export interface DurationFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "duration";
    durationUnit?: string;
    createDurationFromFormValue?: (value: number) => Duration;
    createFormValueFromDuration?: (value: Duration) => number;
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.DurationFilter) => void;
}

export interface NumberFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "number";
    numType?: "capacity" | "throughput" | "percentage";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.NumberFilter) => void;
}

export interface SimpleNumberFilterField<RequestType = any> extends FilterableFieldBase {
    filterType: "simpleNumber";
    addFilterToRequest: (request: RequestType, filterParam: FilterParams.SimpleNumberFilter) => void;
}

export type ListFilterableField<RequestType, EnumFilterType, EnumType> =
    | StringFilterField<RequestType>
    | SimpleStringFilterField<RequestType>
    | DateFilterField<RequestType>
    | BoolFilterField<RequestType>
    | DurationFilterField<RequestType>
    | NumberFilterField<RequestType>
    | EnumFilterField<RequestType, EnumFilterType, EnumType>
    | SimpleNumberFilterField<RequestType>;

export class ServerListData<T, ItemType, RequestType = any> extends ServerData<T> {
    tableState: TableState<ItemType>;

    constructor(
        perPage = 30,
        sortFilterConfig?: ColumnSortFilterConfig<RequestType, any, any, any>,
        selectedGetter?: (r: ItemType) => string,
        selectDisabledGetter?: (r: ItemType) => boolean
    ) {
        super();
        const pagerParam = new PagerParams().setPage(1).setPerPage(perPage);
        this.tableState = new TableState<ItemType>(pagerParam, sortFilterConfig, selectedGetter, selectDisabledGetter);

        makeObservable(this, {
            tableState: observable,
            pagerParam: computed,
        });
    }

    get pagerParam() {
        return this.tableState.pager;
    }

    get sortParam() {
        return this.tableState.sortFilterConfig.sort.getSortParam(this.tableState.sortState);
    }

    addFilterParamsToRequest(request: RequestType) {
        for (let filter of this.tableState.filters) {
            filter.fieldConfig.addFilterToRequest(request, filter.param);
        }
    }
}
