// ======================
// CmoMigrationSystemDetailsActivitiesTab
// ======================

import { Box, Card, Stack } from "@mui/material";
import { CmoMigrationActivitesTimeline } from "../activities/CmoMigrationActivitiesTimeline";

interface CmoMigrationSystemDetailsActivitiesTabProps {
    systemId: string;
    connected: boolean;
}

export const CmoMigrationSystemDetailsActivitiesTab: React.FC<CmoMigrationSystemDetailsActivitiesTabProps> = (p) => {
    const { systemId, connected } = p;

    return connected ? (
        <Box>
            <CmoMigrationActivitesTimeline systemId={systemId} type={"activities"} />
        </Box>
    ) : (
        <Box pt={2}>
            <Card>
                <Stack direction={"row"} justifyContent={"center"} p={2}>
                    {"Activities unavailable when system is offline."}
                </Stack>
            </Card>
        </Box>
    );
};
