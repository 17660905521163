import {
    DiscardPendingPeLicenseRequest,
    GetPendingPeLicenseRequest,
    GetPrivateEditionLocalStatus,
    InitiatePeLicenseRequest,
    SubmitPeLicenseReceipt,
} from "gc-web-proto/galaxycompletepb/apipb/pelocal_api_pb";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ListPrivateEditionVirtualAppliances, SubmitPrivateEditionLicenseRequest } from "gc-web-proto/galaxycompletepb/apipb/pemanage_api_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";

import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useIsAuthenticated } from "../auth/auth_hooks";

export const PrivateEditionQueryKeys = {
    InitiatePeLicenseRequest: "initiatePeLicenseRequest",
    GetPrivateEditionLocalStatus: "getPrivateEditionLocalStatus",
    SubmitPeLicenseReceipt: "submitPeLicenseReceipt",
    DiscardPendingPeLicenseRequest: "discardPendingPeLicenseRequest",
    GetPendingPeLicenseRequest: "getPendingPeLicenseRequest",
    ListPeVirtualAppliances: "listPeVirtualAppliances",
    SubmitPeLicenseRequest: "submitPeLicenseRequest",
};

export const useGetPrivateEditionLocalStatus = () => {
    const apis = useGrpcApiStore();

    const authenticated = useIsAuthenticated();

    const req = new GetPrivateEditionLocalStatus.Request();
    const queryFn = async () => {
        const res = await apis.privateEditionLocalService.getPrivateEditionLocalStatus(req, null);
        return res?.toObject();
    };
    return useQuery({
        enabled: getIsPrivateEdition() && authenticated, // must be authenticated because this is also used in initialization

        queryKey: [PrivateEditionQueryKeys.GetPrivateEditionLocalStatus],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useInitiatePeLicenseRequest = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (args: InitiatePeLicenseRequest.Request) => {
        return await apis.privateEditionLocalService.initiatePeLicenseRequest(args, null);
    };

    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError(
        {
            mutationKey: [PrivateEditionQueryKeys.InitiatePeLicenseRequest],
            mutationFn: mutationFn,
            onSuccess: async () => {
                await queryClient.refetchQueries({
                    queryKey: [PrivateEditionQueryKeys.GetPendingPeLicenseRequest],
                });
            },
        },
        "Generating License Request..."
    );
};

export const useSubmitPeLicenseReceipt = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (args: SubmitPeLicenseReceipt.Request) => {
        return await apis.privateEditionLocalService.submitPeLicenseReceipt(args, null);
    };
    return useMutationTrackAndAlertError(
        {
            mutationKey: [PrivateEditionQueryKeys.SubmitPeLicenseReceipt],
            mutationFn: mutationFn,
        },
        "Submitting License Receipt..."
    );
};

export const useDiscardPendingPeLicenseRequest = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async () => {
        return await apis.privateEditionLocalService.discardPendingPeLicenseRequest(new DiscardPendingPeLicenseRequest.Request(), null);
    };
    return useMutationTrackAndAlertError(
        {
            mutationKey: [PrivateEditionQueryKeys.DiscardPendingPeLicenseRequest],
            mutationFn: mutationFn,
        },
        "Discarding License Request..."
    );
};

export const useGetPendingPeLicenseRequest = () => {
    const api = useGrpcApiStore();
    const queryFn = async () => {
        const res = await api.privateEditionLocalService.getPendingPeLicenseRequest(new GetPendingPeLicenseRequest.Request(), null);
        return res?.toObject();
    };

    return useQuery({
        queryKey: [PrivateEditionQueryKeys.GetPendingPeLicenseRequest],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useListPeVirtualAppliances = (projectId: string, page: number, perPage: number) => {
    const api = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListPrivateEditionVirtualAppliances.Request()
            .setPageParams(new PagerParams().setPage(page).setPerPage(perPage))
            .setProjectId(projectId);
        const res = await api.privateEditionManagementService.listPrivateEditionVirtualAppliances(req, null);
        return res?.toObject();
    };
    return useQuery({
        queryKey: [PrivateEditionQueryKeys.ListPeVirtualAppliances, projectId, page, perPage],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useSubmitPeLicenseRequest = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (args: SubmitPrivateEditionLicenseRequest.Request) => {
        const res = await apis.privateEditionManagementService.submitPrivateEditionLicenseRequest(args, null);
        return res?.toObject();
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [PrivateEditionQueryKeys.SubmitPeLicenseRequest],
            mutationFn: mutationFn,
            onSuccess: async (data, variables) => {
                if (!variables.getPreview()) {
                    await queryClient.refetchQueries({
                        queryKey: [PrivateEditionQueryKeys.ListPeVirtualAppliances],
                    });
                }
            },
        },
        "Submitting License Request..."
    );
};
