import { create } from "zustand";
import { AzureRegion, AzureStorageProductRedundancy } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { CreateAzureStorageConsumptionPlan } from "gc-web-proto/galaxycompletepb/apipb/planner_api_pb";

export interface AzureStoragePlannerDescriptionFormFields {
    planName: string;
    planDescription: string;
    analyzePeriodMinutes: number;
    storageGrowthPercent: number;
    workloadGrowthPercent: number;
    region: AzureRegion;
    storageRedundancy: AzureStorageProductRedundancy;
    noElasticSan: boolean;
}
interface AzureStoragePlannerState {
    projectId: string;
    descriptionFields: AzureStoragePlannerDescriptionFormFields;
    systemIds: string[];
    setDescriptionFields: (fieldValues: AzureStoragePlannerDescriptionFormFields) => void;
    descriptionFieldsSubmitted: boolean;
    setDescriptionFieldsSubmitted: (submitted: boolean) => void;
    addSystemIds: (systemIds: string[]) => void;
    removeSystemIds: (systemIds: string[]) => void;
    removeAllSystemIds: () => void;
    hostsHaveInsufficientHistory: boolean;
    setHostsHaveInsufficientHistory: (insufficient: boolean) => void;
    createRequest: () => void;
    consumptionPlanRequest: CreateAzureStorageConsumptionPlan.Request | null;
    setProjectId: (projectId: string) => void;
    resetState: () => void;
}

const initialDescriptionFields: AzureStoragePlannerDescriptionFormFields = {
    planName: "",
    planDescription: "",
    analyzePeriodMinutes: 1440,
    storageGrowthPercent: 0,
    workloadGrowthPercent: 0,
    region: null,
    storageRedundancy: AzureStorageProductRedundancy.AZURE_STORAGE_REDUNDANCY_LOCAL,
    noElasticSan: false,
};

export const useAzureStoragePlannerState = create<AzureStoragePlannerState>((set, get) => ({
    descriptionFields: initialDescriptionFields,
    projectId: "",
    descriptionFieldsSubmitted: false,
    systemIds: [],
    consumptionPlanRequest: null,
    hostsHaveInsufficientHistory: false,
    resetState: () => {
        set({
            descriptionFields: initialDescriptionFields,
            projectId: "",
            descriptionFieldsSubmitted: false,
            systemIds: [],
            consumptionPlanRequest: null,
            hostsHaveInsufficientHistory: false,
        });
    },
    setProjectId: (projectId: string) => {
        set({
            projectId: projectId,
        });
    },
    setHostsHaveInsufficientHistory: (insufficient: boolean) => {
        set({
            hostsHaveInsufficientHistory: insufficient,
        });
    },
    setDescriptionFields: (fieldValues: AzureStoragePlannerDescriptionFormFields) => {
        set({
            descriptionFields: fieldValues,
        });
    },
    setDescriptionFieldsSubmitted: (submitted: boolean) => {
        set({
            descriptionFieldsSubmitted: submitted,
        });
    },
    addSystemIds: (systemIds: string[]) => {
        const array = [...get().systemIds, ...systemIds];
        const arrayWithoutDuplicates = [...new Set(array)];
        set({
            systemIds: arrayWithoutDuplicates,
        });
    },
    removeSystemIds: (systemIds: string[]) => {
        set({
            systemIds: get().systemIds.filter((id) => !systemIds.includes(id)),
        });
    },
    removeAllSystemIds: () => {
        set({
            systemIds: [],
        });
    },
    createRequest: () => {
        const globalParams = new CreateAzureStorageConsumptionPlan.Request.GlobalParams()
            .setRegion(get().descriptionFields.region)
            .setStorageRedundancy(get().descriptionFields.storageRedundancy)
            .setNoElasticSan(get().descriptionFields.noElasticSan)
            .setAnalyzePeriodMinutes(get().descriptionFields.analyzePeriodMinutes)
            .setStorageGrowthPercent(get().descriptionFields.storageGrowthPercent)
            .setWorkloadGrowthPercent(get().descriptionFields.workloadGrowthPercent);

        const req = new CreateAzureStorageConsumptionPlan.Request()
            .setProjectId(get().projectId)
            .setPlanName(get().descriptionFields.planName)
            .setPlanDescription(get().descriptionFields.planDescription)
            .setSystemIdsList(get().systemIds)
            .setGlobalParams(globalParams);

        set({
            consumptionPlanRequest: req,
        });
    },
}));
