import { GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Alert, AlertTitle, Box, Button, Card, Chip, Dialog, DialogActions, DialogContent, Link, SvgIcon, Theme, Typography } from "@mui/material";
import { useAppServices } from "../../../app/services";
import { DialogState, useDialogState } from "../../../core/dialog/DialogService";
import { DialogTopBar } from "../../../core/dialog/DialogComponents";
import { MdCheckCircle } from "react-icons/md";
import { useIsFeatureEnabled } from "../../../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../../../app/AppGlobalService";
import { HELP_CENTER_URL } from "../../../help/HelpCommon";
import { OperatorView } from "../../../auth/AuthenticatedViews";
import { ChecklistInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/checklist_pb";
import { SelectableBox } from "../../../../common/card/SelectableCard";

// ======================
// StartMigrationCutoverChecklistBanner
// ======================

interface StartMigrationCutoverChecklistBannerProps {
    migrationSessionStatus: GalaxyMigrateMigrationSessionInfo.Status;
    hasExistingChecklist: boolean;
    goToChecklistTab: () => void;
}

export const StartMigrationCutoverChecklistBanner: React.FC<StartMigrationCutoverChecklistBannerProps> = observer((p) => {
    const isChecklistEnabled = useIsFeatureEnabled(FeatureFlag.CUTOVER_CHECKLIST);

    if (p.migrationSessionStatus === GalaxyMigrateMigrationSessionInfo.Status.TRACKING && !p.hasExistingChecklist && isChecklistEnabled) {
        return (
            <OperatorView>
                <Box pb={2} width={"100%"}>
                    <Alert severity={"info"} action={<StartCutoverChecklistButton goToChecklistTab={p.goToChecklistTab} />} variant={"standard"}>
                        <AlertTitle>{"Initial Synchronization Completed"}</AlertTitle>
                        To initiate cutover, start a guided cutover process or visit{" "}
                        <Link href={HELP_CENTER_URL} target={"_blank"}>
                            help center
                        </Link>{" "}
                        for more information.
                    </Alert>
                </Box>
            </OperatorView>
        );
    }

    return null;
});

// ======================
// StartCutoverChecklistButton
// ======================

interface StartCutoverChecklistButtonProps {
    goToChecklistTab: () => void;
}

const StartCutoverChecklistButton: React.FC<StartCutoverChecklistButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService, dialogService } = useAppServices();
    const eligibleChecklists = gmMigrationService.currentSessionEligibleChecklists;
    const cutoverChecklistTypeDialogState = useDialogState();
    const noChecklistTypesDialogState = useDialogState();

    const onStartChecklist = async (type: string) => {
        const res = await gmMigrationService.startCutOverChecklist(type);
        await gmMigrationService.currentSession.fetchData();
        checklistService.setCurrentChecklistId(res.getChecklist().getChecklistId());
        if (!!gmMigrationService.currentSession.data?.getCutoverChecklistId()) {
            p.goToChecklistTab();
        }
    };

    const onClick = async () => {
        await eligibleChecklists.fetchData();
        if (eligibleChecklists.data.length === 0) {
            await dialogService.addAlertDialog({
                title: "Guided Cutover Process is not available for this session",
                message: "Visit our Help Center to learn more about cutover processes.",
                renderAdditionalContent: () => (
                    <Box display={"flex"} justifyContent={"center"} pt={4}>
                        <Button variant={"contained"} href={HELP_CENTER_URL} target={"_blank"}>
                            Help Center
                        </Button>
                    </Box>
                ),
            });
            // } FIXME
            // else if (eligibleChecklists.data.length === 1) {
            //     await onStartChecklist(eligibleChecklists.data[0].type);
            // } else if (eligibleChecklists.data.length > 1) {
        } else if (eligibleChecklists.data.length > 0) {
            cutoverChecklistTypeDialogState.open();
        }
    };

    return (
        <>
            <Button color={"inherit"} onClick={onClick}>
                {"Start Guided Cutover Process"}
            </Button>
            {cutoverChecklistTypeDialogState.isOpen && (
                <StartCutoverChecklistDialog
                    dialogState={cutoverChecklistTypeDialogState}
                    eligibleChecklists={eligibleChecklists.data}
                    onStartChecklist={onStartChecklist}
                />
            )}
        </>
    );
});

// ======================
// StartCutoverChecklistDialog
// ======================

interface StartCutoverChecklistDialogProps {
    dialogState: DialogState;
    eligibleChecklists: ChecklistInfo.Descriptor.AsObject[];
    onStartChecklist: (type: string) => Promise<void>;
}

const StartCutoverChecklistDialog: React.FC<StartCutoverChecklistDialogProps> = observer((p) => {
    const [selectedChecklist, setSelectedChecklist] = useState(null);

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={"md"} fullWidth>
            <DialogTopBar dialogState={p.dialogState} title={"Start Guided Cutover Process"} />
            <DialogContent>
                <Typography variant={"body1"}>
                    {`The following cutover guides are applicable to this migration session. Select a cutover process that best describes your migration scenario to get
                        started.`}
                </Typography>
                <br />
                <Card sx={{ background: (t: Theme) => t.palette.cirrus.main }}>
                    {p.eligibleChecklists.map((checklistDescriptor, i) => {
                        const selected = selectedChecklist === checklistDescriptor.type;
                        return (
                            <SelectableBox
                                selected={selected}
                                key={i}
                                cardProps={{
                                    sx: { backgroundColor: "none" },
                                }}
                                onSelect={() => setSelectedChecklist(checklistDescriptor.type)}
                            >
                                <Box p={2} display={"flex"} alignItems={"center"}>
                                    <Box pr={2}>
                                        <SvgIcon
                                            sx={{
                                                color: selected ? (t: Theme) => t.palette.primary.main : "rgba(255, 255, 255, .2)",
                                            }}
                                        >
                                            <MdCheckCircle />
                                        </SvgIcon>
                                    </Box>
                                    <Box>
                                        <Box pb={2}>
                                            <Typography variant={"h6"}>{checklistDescriptor.title}</Typography>
                                            {checklistDescriptor.tagsList.map((tag) => {
                                                return <Chip label={tag} size={"small"} sx={{ marginRight: 1 }} />;
                                            })}
                                        </Box>

                                        <Typography variant={"body2"} color={"textSecondary"}>
                                            {checklistDescriptor.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </SelectableBox>
                        );
                    })}
                </Card>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"outlined"} color={"neutral"} onClick={p.dialogState.close}>
                    {`Cancel`}
                </Button>
                <Button
                    variant={"contained"}
                    disabled={selectedChecklist === null}
                    onClick={async () => {
                        await p.onStartChecklist(selectedChecklist);
                        p.dialogState.close();
                    }}
                >
                    {`Start Cutover Guide`}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

// ======================
// NoCutoverChecklistTypesDialog
// ======================

interface NoCutoverChecklistTypesDialogProps {}

export const NoCutoverChecklistTypesDialog: React.FC<NoCutoverChecklistTypesDialogProps> = observer((p) => {
    return null;
});
