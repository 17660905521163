import React from "react";
import { observer } from "mobx-react-lite";
import TableContainer from "@mui/material/TableContainer";
import { Paper, TableCell, TableRow } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { DataTable, DataTableProps } from "./DataTable";

// ======================
// SimpleTable
// ======================
type SimpleTableProps<RowType, RowIDType = any> = Omit<DataTableProps<RowType, RowIDType>, "state" | "onTableStateChange">;
export const SimpleTable = observer(<RowType,>(p: SimpleTableProps<RowType>) => {
    return <DataTable state={null} onTableStateChange={null} {...p} />;
});
