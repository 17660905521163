import { generatePath, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../app/AppRoutes";
import { AuthenticatedUserInfo } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";

export const useNavigateToUserSettingsPage = () => {
    const navigate = useNavigate();
    const p = generatePath(APP_ROUTES.USER);
    return () => navigate(p);
};

export const useNavigateToUserLicensePage = () => {
    const navigate = useNavigate();
    const p = generatePath(APP_ROUTES.USER_LICENSE);
    return () => navigate(p);
};

export const getAuthenticatedUserFullName = (u: AuthenticatedUserInfo): string => {
    if (!u) {
        return null;
    }
    return `${u.getFirstName()} ${u.getLastName()}`;
};

export const getAuthenticatedUserFullNameFromObject = (u: AuthenticatedUserInfo.AsObject): string => {
    if (!u) {
        return null;
    }
    return `${u.firstName} ${u.lastName}`;
};
