import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useAppServices } from "../../app/services";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { GalaxyMigrateDeploymentRelayUnactivatedScreen } from "./GalaxyMigrateDeploymentRelayUnactivated";
import { GalaxyMigrateDeploymentRelayActivatedScreen } from "./GalaxyMigrateDeploymentRelayActivated";
import { renderServerDataWithLoadingBox, useInitData } from "../../core/data/DataLoaderHooks";
import { renderIfConnectedDeployment } from "../../deployment/DeploymentCommon";
import { Box } from "@mui/material";

// ======================
// GalaxyMigrateDeploymentRelayScreen
// ======================

interface GalaxyMigrateDeploymentRelayScreenProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentRelayScreen: React.FC<GalaxyMigrateDeploymentRelayScreenProps> = observer((p) => {
    const { gmDeploymentService } = useAppServices();

    useInitData({
        poll: () => gmDeploymentService.cdcRelayServerStatus.fetchData(),
        pollInterval: 3,
        deinit: () => gmDeploymentService.cdcRelayServerStatus.resetData(),
    });

    return (
        <Box pt={2}>
            <>
                {renderIfConnectedDeployment(
                    p.deployment,
                    renderServerDataWithLoadingBox(gmDeploymentService.cdcRelayServerStatus, (data) => {
                        return !data.relayServerInfo.config.enable ? (
                            <GalaxyMigrateDeploymentRelayUnactivatedScreen deployment={p.deployment} />
                        ) : (
                            <GalaxyMigrateDeploymentRelayActivatedScreen relayServerInfo={data.relayServerInfo} deployment={p.deployment} />
                        );
                    })
                )}
            </>
        </Box>
    );
});
