import * as React from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import { Box, Button, Card, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { useMountEffect } from "../../../../common/hooks/hookslib";

// ======================
// CirrusDataCloudAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

export const DGSAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setDgs(new AutoAlloc.VolumeParams.DGS());
        }
    });

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <Card>
                <List>
                    {state.sourceDevices.map((device) => {
                        const blockDevice = device.source.getBlockDevice();
                        return (
                            <ListItem key={blockDevice.getDeviceName()}>
                                <ListItemIcon>
                                    <DiskIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                                    secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Card>
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});
