import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { ProjectService } from "../project/ProjectService";
import { makeAutoObservable } from "mobx";
import { ServerData } from "../core/data/ServerData";
import {
    LicenseStorePurchasableProduct,
    TransactionCounterInfo,
    TransactionDetails,
    VaultDetails,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { ServerListData } from "../core/data/ListData";
import {
    GetProjectLicenseVaultDetails,
    ListProjectLicenseTransactions,
    TransferLicenseFromProject,
    TransferLicenseToProject,
} from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { GetUserLicenseVaultDetails, ListUserLicenseTransactions, RedeemCode } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import {
    CreateBillingManagementSession,
    CreateLicensePurchaseSession,
    GetLicensePurchaseCatalog,
    PurchaseLicenseFromAwsMarketplaceSubscription,
    PurchaseLicenseFromAzureMarketplaceSubscription,
} from "gc-web-proto/galaxycompletepb/apipb/license_api_pb";
import { mockLicensePurchaseCatalog } from "../core/testutils/fixtures/MockLicenseService";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";

export class LicenseService {
    private api: GRPCServices;
    protected dialogService: DialogService;
    protected progressService: ProgressService;
    protected projectService: ProjectService;

    public projectLicenseDetails = new ServerData<VaultDetails.AsObject>().setDataFetcher(this.fetchProjectLicenseDetails.bind(this));
    public projectLicenseTransactions = new ServerListData<ListProjectLicenseTransactions.Response.AsObject, TransactionDetails.AsObject>(50).setDataFetcher(
        this.fetchProjectLicenseTransactions.bind(this)
    );
    public userLicenseVaultDetails = new ServerData<VaultDetails.AsObject>().setDataFetcher(this.fetchUserLicenseVaultDetails.bind(this));
    public userLicenseTransactions = new ServerListData<ListUserLicenseTransactions.Response.AsObject, TransactionDetails.AsObject>(50).setDataFetcher(
        this.fetchUserLicenseTransactions.bind(this)
    );
    public licensePurchaseCatalog = new ServerData<LicenseStorePurchasableProduct.AsObject[]>().setDataFetcher(this.getLicensePurchaseCatalog.bind(this));
    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService, projectService: ProjectService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.projectService = projectService;
        makeAutoObservable(this);
    }

    get currentLicenseSerial() {
        return this.projectLicenseDetails.data?.identity.vaultSerial || this.userLicenseVaultDetails.data?.identity.vaultSerial;
    }

    public async fetchProjectLicenseDetails(projectID?: string) {
        const id = projectID || this.projectService.currentProjectID;
        const req = new GetProjectLicenseVaultDetails.Request().setProjectId(id);
        const res = await this.api.projectService.getProjectLicenseVaultDetails(req, null);
        return res.getVaultDetails().toObject();
    }

    public async fetchProjectLicenseTransactions(projectID?: string, pageParams?: PagerParams) {
        projectID = projectID || this.projectService.currentProjectID;
        pageParams = pageParams || this.projectLicenseTransactions.pagerParam;
        const req = new ListProjectLicenseTransactions.Request().setProjectId(projectID).setPageParams(pageParams);

        const res = await this.api.projectService.listProjectLicenseTransactions(req, null);

        //return mockListLicenseTransactions.toObject()
        return res.toObject();
    }

    public async fetchUserLicenseVaultDetails() {
        const req = new GetUserLicenseVaultDetails.Request();
        //return mockVaultDetails.toObject()
        const res = await this.api.authService.getUserLicenseVaultDetails(req, null);
        return res.getVaultDetails().toObject();
    }

    public async fetchUserLicenseTransactions() {
        const req = new ListUserLicenseTransactions.Request();
        const res = await this.api.authService.listUserLicenseTransactions(req, null);
        return res.toObject();
        //return mockListLicenseTransactions.toObject()
    }

    public async exportLicenseToProject(projectId: string, description: string, countersList: Array<TransactionCounterInfo>) {
        const req = new TransferLicenseToProject.Request().setProjectId(projectId).setDescription(description).setCountersList(countersList);
        return await this.progressService.track(this.api.projectService.transferLicenseToProject(req, null));
    }

    public async exportLicenseToUserVault(projectId: string, description: string, countersList: Array<TransactionCounterInfo>) {
        const req = new TransferLicenseFromProject.Request()
            .setProjectId(this.projectService.currentProjectID || projectId)
            .setDescription(description)
            .setCountersList(countersList);
        return await this.progressService.track(this.api.projectService.transferLicenseFromProject(req, null));
    }

    public async startPurchaseSession(selectedItems: { [priceId: string]: number }) {
        const req = new CreateLicensePurchaseSession.Request();
        const map = req.getItemsSelectedMap();
        for (let item in selectedItems) {
            map.set(item, selectedItems[item]);
        }
        return await this.progressService.track(this.api.licenseBillingService.createLicensePurchaseSession(req, null));
    }
    public async startBillingManagementSession() {
        const req = new CreateBillingManagementSession.Request();
        return await this.progressService.track(this.api.licenseBillingService.createBillingManagementSession(req, null));
    }

    public async getLicensePurchaseCatalog() {
        const req = new GetLicensePurchaseCatalog.Request();
        const res = await this.api.licenseBillingService.getLicensePurchaseCatalog(req, null);
        return res.toObject().productsList;
    }

    public async addAwsMigrationCredit(amount: number) {
        const req = new PurchaseLicenseFromAwsMarketplaceSubscription.Request().setCounter(
            new TransactionCounterInfo()
                .setCounterType(new LicenseVaultCounterType().setValue(LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION))
                .setAmount(amount)
        );

        return await this.progressService.track(this.api.licenseBillingService.purchaseLicenseFromAwsMarketplaceSubscription(req, null));
    }
    public async addAzureMigrationCredit(amount: number, counterType: LicenseVaultCounterType.LicenseVaultCounterType) {
        const req = new PurchaseLicenseFromAzureMarketplaceSubscription.Request().setCounter(
            new TransactionCounterInfo().setCounterType(new LicenseVaultCounterType().setValue(counterType)).setAmount(amount)
        );

        return await this.progressService.track(this.api.licenseBillingService.purchaseLicenseFromAzureMarketplaceSubscription(req, null));
    }

    public async redeemCode(code: string) {
        const req = new RedeemCode.Request().setCode(code);

        return await this.progressService.track(this.api.authService.redeemCode(req, null));
    }
}
