// ======================
// GlobalProgressViews
// ======================

import { useGlobalProgressState } from "./GlobalProgressState";
import { FullScreenLoading } from "../data/DataLoaders";
import * as React from "react";

interface GlobalProgressViewsProps {}

export const GlobalProgressViews: React.FC<GlobalProgressViewsProps> = (p) => {
    const trackers = useGlobalProgressState((s) => s.trackers);
    const hasOutstandingTrackers = useGlobalProgressState((s) => !!s.trackers.length);
    return (
        <>
            {trackers.map((t) => (
                <FullScreenLoading key={t.id} loading={hasOutstandingTrackers} message={t.message} />
            ))}
        </>
    );
};
