// ======================
// AzureStoragePlanListView
// ======================

import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useListAzureStorageConsumptionPlans } from "./azure_storage_planner_hooks";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { AzureStorageConsumptionPlanListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/planner_pb";
import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import { QueryTable } from "../../../common/table/QueryTable";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { getUserFullNameFromObject } from "../../settings/ProjectUsers";
import { useNavigateToAzureStoragePlanDetails, useNavigateToCreateNewAzureStoragePlannerAssessment } from "./AzureStoragePlannerHelpers";

interface AzureStoragePlanListViewProps {}

export const AzureStoragePlanListView: React.FC<AzureStoragePlanListViewProps> = (p) => {
    const goToPlanner = useNavigateToCreateNewAzureStoragePlannerAssessment();

    const createNewPlanButton = <Button variant={"contained"} color={"secondary"} onClick={goToPlanner}>{`Create New Plan`}</Button>;
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`My Azure Storage Consumption Plans`} actions={createNewPlanButton} />
            <AzureStoragePlanTable />
        </ScreenContainer>
    );
};

// ======================
// AzureStoragePlanTable
// ======================

interface AzureStoragePlanTableProps {}

const AzureStoragePlanTable: React.FC<AzureStoragePlanTableProps> = (p) => {
    const projectId = useCurrentProjectID();
    const queryResult = useListAzureStorageConsumptionPlans(projectId);
    const goToPlanDetails = useNavigateToAzureStoragePlanDetails();
    const columnHelper = createColumnHelper<AzureStorageConsumptionPlanListItem.AsObject>();
    const cols: ColumnDef<AzureStorageConsumptionPlanListItem.AsObject, any>[] = [
        columnHelper.accessor((r) => r, {
            id: "name",
            header: "Storage Consumption Plan",
            cell: (props) => (
                <Box>
                    <Typography>
                        <Link onClick={() => goToPlanDetails(props.row.original.id)}>{props.row.original.name}</Link>
                    </Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {props.row.original.description}
                    </Typography>
                </Box>
            ),
        }),

        columnHelper.accessor((r) => r, {
            id: "volume",
            header: "Storage",
            cell: (props) => (
                <Box>
                    <Typography>
                        {props.row.original.totalVolumes} {`Volumes (${props.row.original.totalHosts} Hosts)`}
                    </Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {formatKnownDataType(props.row.original.totalCapacity, KnownDataType.CAPACITY)}
                    </Typography>
                </Box>
            ),
        }),
        columnHelper.accessor((r) => r.totalMonthlyCost, {
            id: "cost",
            header: "Estimated Cost",
            cell: (props) => (
                <Box>
                    <Typography>
                        {formatKnownDataType(props.getValue(), KnownDataType.CURRENCY_USD)} {` per month`}
                    </Typography>
                </Box>
            ),
        }),
        columnHelper.accessor((r) => r, {
            id: "created",
            header: "Created",
            cell: (props) => (
                <Box>
                    <Tooltip title={formatKnownDataType(convertTimestampObjectToDate(props.row.original.createdAt), KnownDataType.DATE)}>
                        <Typography>{formatKnownDataType(convertTimestampObjectToDate(props.row.original.createdAt), KnownDataType.DATE_RELATIVE)}</Typography>
                    </Tooltip>
                    <Typography variant={"caption"} color={"textSecondary"}>{`by ${getUserFullNameFromObject(props.row.original.createdByUser)}`}</Typography>
                </Box>
            ),
        }),
    ];
    return (
        <QueryTable
            columns={cols}
            isLoading={queryResult.isLoading}
            data={queryResult.data?.plansList || []}
            isError={queryResult.isError}
            error={queryResult.error}
        />
    );
};
