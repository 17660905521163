import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { ListDeploymentsGeolocationInfo } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useQuery } from "@tanstack/react-query";
import { DeploymentQueryKeys } from "../../common/QueryKeys";

export const useListDeploymentsGeolocationInfo = (projectId: string) => {
    const apis = useGrpcApiStore();
    const req = new ListDeploymentsGeolocationInfo.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(1).setPerPage(1000));

    const queryFn = async () => {
        const res = await apis.deploymentService.listDeploymentsGeolocationInfo(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.listDeploymentsGeolocationInfo],
        queryFn,
    });
};
