import { GmMigrationSessionParametersForm, MigrationParametersType } from "./GmMigrationSessionParametersForm";
import { WizardStepProp } from "./GmMigrationWizardCommon";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import { useAppServices } from "../../app/services";
import { FormikValues } from "formik";
import { generateMigrationSessionDetailsPath } from "../CmcMigrationCommon";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
// ======================
// ParametersStep
// ======================
export const GmMigrationWizardParametersStep: React.FC<WizardStepProp> = observer((p) => {
    const navigate = useNavigate();
    const initialValues = p.wizardState.migrationParameters;
    const hostTimezone = p.wizardState.deployment?.data?.getInfo().getDeployment().getSystemTimezone();
    const projectId = useCurrentProjectID();

    const { dialogService, gmMigrationService } = useAppServices();

    const _submit = async (values: FormikValues) => {
        p.wizardState.migrationParameters = values as MigrationParametersType;
        const req = p.wizardState.makeCreateSessionRequest();
        const res = await gmMigrationService.createSession(req);
        navigate(generateMigrationSessionDetailsPath(projectId, res?.getSessionUuid()));
    };

    const formActions = (
        <Grid container justifyContent={"center"}>
            <Box p={1} pt={2} display={"flex"}>
                <Box pr={2}>
                    <Button variant={"contained"} color={"neutral"} onClick={() => p.wizardState.stepperState.goBackOneStep()}>
                        {"Go Back"}
                    </Button>
                </Box>
                <Box>
                    <Button type={"submit"} variant={"contained"} color={"primary"}>{`Create Session`}</Button>
                </Box>
            </Box>
        </Grid>
    );

    return (
        <GmMigrationSessionParametersForm
            initialValues={initialValues}
            onSubmit={_submit}
            formActions={formActions}
            sourceDeployment={p.wizardState.deployment.data}
            isRemoteMigration={p.wizardState.isRemoteMigration}
            isComputeMigration={p.wizardState.isComputeMigration}
            isBootVolumeMigration={p.wizardState.bootVolumeSelected}
            actionsState={p.wizardState.postSyncActionsState}
            isMockMigration={p.wizardState.isMockMigration}
        />
    );
});
