import { observer } from "mobx-react-lite";
import React from "react";
import { ScreenContainer } from "../layout/ScreenCommon";
import { useAppServices } from "../app/services";
import { useLogOut } from "./auth_hooks";
import { useMountEffect } from "../../common/hooks/hookslib";

// ======================
// SignOutScreen
// ======================

interface SignOutScreenProps {}

export const SignOutScreen: React.FC<SignOutScreenProps> = observer((p) => {
    const logout = useLogOut();

    useMountEffect(() => {
        const requestLogOut = async () => {
            const confirmed = await logout.mutateAsync();
            if (!confirmed) {
                window.history.back();
            }
        };
        requestLogOut();
    });

    return <ScreenContainer />;
});
