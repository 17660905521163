import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { APP_ROUTES } from "../app/AppRoutes";
import { generatePath, useMatch, useNavigate } from "react-router-dom";
import { useCurrentUser } from "../auth/auth_hooks";

export const useIsSupportProject = () => {
    return !!useMatch(`${APP_ROUTES.SUPPORT_PROJECTS}/:projectId/*`);
};

export const useIsSupportUser = () => {
    const currentUser = useCurrentUser();
    return currentUser?.cdsiSupportUser;
};

export const useNavigateToSupportProjects = () => {
    const navigate = useNavigate();
    const p = generatePath(APP_ROUTES.SUPPORT_PROJECTS);
    return () => navigate(p);
};

// ======================
// CdsSupportStaffView
// ======================

interface CdsSupportStaffViewProps {
    children: React.ReactNode;
}

export const CdsSupportStaffView: React.FC<CdsSupportStaffViewProps> = observer((p) => {
    const isSupportUser = useIsSupportUser();
    if (isSupportUser) {
        return <>{p.children}</>;
    }
    return null;
});
