import { action, computed, makeAutoObservable, makeObservable, observable } from "mobx";

export type ServerDataFetcherFunc<T> = (...args: any[]) => Promise<T>;

export class ServerData<T> {
    dataLoaded = false; // will set to true after the first time the data is loaded
    data: T = null;
    error: Error = null;
    lastResetTime: number = null;

    // optionally a reload function
    dataFetcher?: ServerDataFetcherFunc<T> = null;
    fetching = false;

    constructor() {
        makeObservable(this, {
            dataLoaded: observable,
            data: observable,
            error: observable,
            lastResetTime: observable,
            dataFetcher: observable,
            fetching: observable,
            loading: computed,
            ready: computed,
            inError: computed,
            setData: action,
            resetData: action,
            setDataFetcher: action,
        });
    }

    get loading() {
        return !this.dataLoaded;
    }

    get ready() {
        return !this.loading && !this.inError;
    }

    get inError() {
        return !!this.error;
    }

    resetData() {
        this.lastResetTime = new Date().getTime();
        this.data = null;
        this.dataLoaded = false;
        this.error = null;
    }

    setData(data: T) {
        this.data = data;
        // mark data loaded
        this.dataLoaded = true;
        return this;
    }

    errorEncountered(err: Error) {
        if (this.error !== err) {
            this.error = err || null;
        }
    }

    async loadDataFromAPI(promise: Promise<T>) {
        // async loadDataFromAPI(promise: Promise<Class|AxiosResponse<Class>>) {
        if (promise) {
            const callTime = new Date().getTime();
            try {
                const res = await promise;

                // when data is returned, if the call time was before the reset time, don't set the data
                if (this.lastResetTime && callTime < this.lastResetTime) {
                    console.debug(
                        `Will not set data to ServerData. Last Reset Time: ${this.lastResetTime}, Call Time: ${callTime}. Diff: ${
                            this.lastResetTime - callTime
                        }`
                    );
                    return null;
                }

                let data = res ? res : null;
                // let data = isAxiosResponse(res) ? res.data : res;
                this.setData(data);
                this.errorEncountered(null);
                return res;
            } catch (err) {
                if (err) {
                    console.error("Error Encountered: ", err);
                    this.errorEncountered(err as Error);
                    this.setData(null);
                    throw err;
                }
            }
        } else {
            return promise;
        }
    }

    async fetchData(...args: any[]) {
        if (this.dataFetcher) {
            this.fetching = true;
            try {
                return await this.loadDataFromAPI(this.dataFetcher(...args));
            } finally {
                this.fetching = false;
            }
        } else {
            throw new Error("no refresh function provided. cannot refresh");
        }
    }

    setDataFetcher(refreshFn: ServerDataFetcherFunc<T>) {
        this.dataFetcher = refreshFn;
        return this;
    }
}

// const isAxiosResponse = (p: any): p is AxiosResponse => p.hasOwnProperty('status') && p.hasOwnProperty('data') && p.hasOwnProperty('statusText');
