import { StepConfig } from "./StepperHelpers";
import { createStore, useStore } from "zustand";
import { createContext, useContext } from "react";

export interface StepperProps {
    activeStep?: number;
    startingStep?: number;
    totalSteps: number;
    stepConfigs: StepConfig[];
}

export interface StepperState extends StepperProps {
    setStartingStepIndex: (index: number) => void;
    goToNextStep: () => void;
    goBackOneStep: () => void;
    setStepConfigs: (configs: StepConfig[]) => void;
    setTotalSteps: (steps: number) => void;
    resetStepper: () => void;
    getHasNextStep: () => boolean;
    getHasPreviousStep: () => boolean;
}

export type StepperStore = ReturnType<typeof createStepperStore>;

export const createStepperStore = (initProps?: Partial<StepperProps>) => {
    const DEFAULT_PROPS: StepperProps = {
        stepConfigs: [],
        activeStep: 0,
        startingStep: 0,
        totalSteps: 1,
    };
    return createStore<StepperState>()((set, get) => ({
        ...DEFAULT_PROPS,
        ...initProps,
        setStartingStepIndex: (index: number) => {
            set({
                startingStep: index,
                activeStep: index,
            });
        },
        goToNextStep: () => {
            set({
                activeStep: get().activeStep + 1,
            });
        },
        goBackOneStep: () => {
            set({
                activeStep: get().activeStep - 1,
            });
        },
        setStepConfigs: (configs: StepConfig[]) => {
            set({
                stepConfigs: configs,
                totalSteps: configs?.length || 0,
            });
        },
        setTotalSteps: (steps: number) => {
            set({
                totalSteps: steps,
            });
        },
        resetStepper: () => {
            set({
                activeStep: get().startingStep,
            });
        },
        getHasNextStep: () => get().activeStep !== get().totalSteps - 1,
        getHasPreviousStep: () => get().activeStep - 1 >= get().startingStep,
    }));
};
export const StepperContext = createContext<StepperStore | null>(null);

export const useStepperContext = <T,>(selector: (state: StepperState) => T, equalityFn?: (left: T, right: T) => boolean): T => {
    const store = useContext(StepperContext);
    if (!store) throw new Error("Missing StepperContext.Provider in the tree");
    return useStore(store, selector, equalityFn);
};
