// ======================
// CmoMigrationSessionDetailsQosScheduleTab
// ======================

import { CmoMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { CmoMigrationSessionDetailsQosCalendar } from "./qosCalendar/CmoMigrationSessionDetailsQosCalendar";

interface CmoMigrationSessionDetailsQosScheduleTabProps {
    data: CmoMigrationSessionInfo.QosSchedule.AsObject;
}

export const CmoMigrationSessionDetailsQosScheduleTab: React.FC<CmoMigrationSessionDetailsQosScheduleTabProps> = (p) => {
    const { data } = p;
    return <CmoMigrationSessionDetailsQosCalendar qosSchedule={data} />;
};
