import "./CodeEditor.css";
import hljs from "highlight.js/lib/core";
import Editor from "react-simple-code-editor";
import React from "react";

// ======================
// SimpleCodeEditor
// ======================

interface SimpleCodeEditorProps {
    value: string;
    setValue: (value: string) => void;
    placeHolder?: string;
}

export const SimpleCodeEditor: React.FC<SimpleCodeEditorProps> = (p) => {
    const { value, setValue, placeHolder } = p;
    return (
        <Editor
            value={value}
            onValueChange={(v) => setValue(v)}
            placeholder={placeHolder || "Start typing here..."}
            highlight={(code) => hljs.highlight(code, { language: "yaml" }).value}
            style={{
                fontFamily: '"Fira code", "Fira Mono","Consolas","Menlo", monospace',
                fontSize: 13,
                minHeight: "50vh",
            }}
            textareaClassName={"text-area"}
        />
    );
};
