import { GRPCServices } from "../grpc/grpcapi";
import { ProjectService } from "../project/ProjectService";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { DeploymentService } from "../deployment/DeploymentService";
import { makeAutoObservable } from "mobx";
import { ServerData } from "../core/data/ServerData";
import { GalaxyMigrateStorageConfig } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    CheckForOnlineUpgrade,
    ConfigureAzureHelper,
    ConfigureCdcRelayServer,
    ConfigureVmwareHelper,
    CreateGalaxyMigrateLink,
    GetAzureHelperResourceSelections,
    GetCdcRelayServerStatus,
    GetComputeMigrationSourceReadiness,
    GetGMStorageConfig,
    GetIOStats,
    GetLastOnlineUpgradeStatus,
    GetVmwareHelperResourceSelections,
    GetXRay,
    ListAzureHelpers,
    ListGalaxyMigrateLinks,
    ListVmwareHelpers,
    PerformOnlineUpgrade,
    PrepareSourceForComputeMigration,
    ReconnectGalaxyMigrateLink,
    RefreshGalaxyMigrateLink,
    RemoveGalaxyMigrateLink,
    RestartMTdiDaemon,
    RestartGalaxyMigrate,
    ViewLogs,
} from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import semverGte from "semver/functions/gte";
import { CdcRelayServerConfig, GetLastOnlineUpgradeStatusResponse, ViewLogsRequest } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { VmwareCompute } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { ServerListData } from "../core/data/ListData";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { GmHelperNodeInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/compute_pb";
import { HostEnvironment } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { IOStatsDataSet } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { IO_TYPE } from "./performance/GalaxyMigrateDeploymentPerformanceHelpers";

export class GalaxyMigrateDeploymentService {
    protected readonly api: GRPCServices;
    private readonly projectService: ProjectService;
    private readonly dialogService: DialogService;
    private readonly progressService: ProgressService;

    protected readonly deploymentService: DeploymentService;

    upgradeState: DeploymentUpgradeState;

    storageConfig = new ServerData<GalaxyMigrateStorageConfig>().setDataFetcher(this.fetchStorageConfig.bind(this));

    deploymentLogs = new ServerData<ViewLogs.Response>().setDataFetcher(this.fetchDeploymentLogs.bind(this));

    galaxyMigrateLinks = new ServerListData<ListGalaxyMigrateLinks.Response, GMLinkInfo>().setDataFetcher(this.fetchGalaxyMigrateLinks.bind(this));

    galaxyMigrateVmwareHelpers = new ServerListData<ListVmwareHelpers.Response, GmHelperNodeInfo.Vmware>().setDataFetcher(
        this.fetchGalaxyMigrateVmwareHelpers.bind(this)
    );

    galaxyMigrateComputeMigrationSourceReadiness = new ServerData<GetComputeMigrationSourceReadiness.Response>().setDataFetcher(
        this.getSourceVmReadiness.bind(this)
    );

    galaxyMigrateVmwareHelperResourceSelections = new ServerData<GetVmwareHelperResourceSelections.Response.AsObject>().setDataFetcher(
        this.getVmwareHelperResourceSelections.bind(this)
    );

    cdcRelayServerStatus = new ServerData<GetCdcRelayServerStatus.Response.AsObject>().setDataFetcher(this.getRelayServerStatus.bind(this));

    constructor(
        api: GRPCServices,
        projectService: ProjectService,
        dialogService: DialogService,
        progressService: ProgressService,
        deploymentService: DeploymentService
    ) {
        this.api = api;
        this.projectService = projectService;
        this.dialogService = dialogService;
        this.progressService = progressService;
        this.deploymentService = deploymentService;

        this.initUpgradeState();
        makeAutoObservable(this);
    }

    // represent current deployment

    async fetchStorageConfig(deploymentId?: string, includeLvm = false) {
        deploymentId = deploymentId || this.deploymentService.currentDeploymentID;
        const req = new GetGMStorageConfig.Request().setSystemId(deploymentId).setIncludeLvm(includeLvm);

        const res = await this.api.gmService.getStorageConfig(req, null);
        return res.getStorageConfig();
    }

    initUpgradeState() {
        this.upgradeState = new DeploymentUpgradeState(this.api, this.deploymentService);
        return this.upgradeState;
    }

    get currentDeploymentSupportsOnlineUpgrade() {
        const ver = this.deploymentService.currentDeploymentVersion;
        return ver && (semverGte(ver, "1.0.6") || ver === "0.0.1"); // or debug version
    }

    async assignUnlimitedLicense(systemId?: string) {
        const sysId = systemId || this.deploymentService.currentDeploymentID;
        //const req   = new AssignUnlimitedLicense.Request()
        //.setSystemId(sysId);
        //return await this.progressService.track(this.api.gmService.assignUnlimitedLicense(req, null));
    }

    async fetchDeploymentLogs(deploymentId = this.deploymentService.currentDeploymentID, source: ViewLogsRequest.LogSource, filter: string) {
        if (!deploymentId) {
            throw new Error("no current current deployment id context set");
        }

        const req = new ViewLogs.Request().setSystemId(deploymentId).setParams(new ViewLogsRequest().setSource(source).setLimit(1000).setFilter(filter));

        return await this.api.gmService.viewLogs(req, null);
    }

    async getMigrationXRay(systemId?: string, argumentsList?: string[]) {
        systemId = systemId || this.deploymentService.currentDeploymentID;
        const req = new GetXRay.Request().setSystemId(systemId);

        if (!!argumentsList) {
            req.setArgumentsList(argumentsList);
        }
        return await this.progressService.track(this.api.gmService.getXRay(req, null), "Generating X-Ray...");
    }

    async restartMTdiDaemon(systemId?: string) {
        systemId = systemId || this.deploymentService.currentDeploymentID;
        const req = new RestartMTdiDaemon.Request().setSystemId(systemId);
        return await this.progressService.track(this.api.gmService.restartMTdiDaemon(req, null), "Restarting mTDI Daemon...");
    }
    async restartGalaxyMigrate(systemId?: string) {
        systemId = systemId || this.deploymentService.currentDeploymentID;
        const req = new RestartGalaxyMigrate.Request().setSystemId(systemId);
        return await this.progressService.track(this.api.gmService.restartGalaxyMigrate(req, null), "Triggering Galaxy Migrate Restart...");
    }

    async createDeploymentLink(
        serverId: string,
        clientId: string,
        serverAddress: string,
        serverPort: number = null,
        description: string,
        allowProxy: boolean,
        compression: boolean
    ) {
        const req = new CreateGalaxyMigrateLink.Request()
            .setServersystemid(serverId)
            .setClientsystemid(clientId)
            .setServeraddress(serverAddress)
            .setServerport(serverPort)
            .setDescription(description)
            .setAllowProxy(allowProxy)
            .setCompression(compression);
        return await this.progressService.track(this.api.gmService.createGalaxyMigrateLink(req, null), "Creating Connection...");
    }

    async batchCreateDeploymentLinks(
        array: Array<{
            server: { serverId: string; serverName: string };
            client: { clientId: string; clientName: string };
            serverAddress: string;
            serverPort: number;
            description: string;
            compression: boolean;
            proxy: boolean;
        }>
    ) {
        for (let link of array) {
            await this.createDeploymentLink(
                link.server.serverId,
                link.client.clientId,
                link.serverAddress,
                link.serverPort,
                link.description,
                link.proxy,
                link.compression
            );
        }
    }

    async fetchGalaxyMigrateLinks(systemId: string = "", onlyConnected: boolean = false, pagerParams?: PagerParams) {
        const req = new ListGalaxyMigrateLinks.Request()
            .setProjectId(this.projectService.currentProjectID)
            .setOnlyConnected(onlyConnected)
            .setSystemIdFilter(systemId)
            .setPageParams(pagerParams || this.galaxyMigrateLinks.pagerParam);
        return await this.api.gmService.listGalaxyMigrateLinks(req, null);
    }

    async removeGalaxyMigrateLink(linkId: string) {
        const req = new RemoveGalaxyMigrateLink.Request().setLinkid(linkId);
        const confirmed = await this.dialogService.addConfirmDialog({
            message: `Are you sure you want to remove this connection?`,
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            return await this.progressService.track(this.api.gmService.removeGalaxyMigrateLink(req, null));
        }
    }

    async reconnectGalaxyMigrateLink(linkId: string, connectionAddress: string, serverPort: number = null) {
        const req = new ReconnectGalaxyMigrateLink.Request().setLinkid(linkId).setServeraddress(connectionAddress).setServerport(serverPort);

        return await this.progressService.track(this.api.gmService.reconnectGalaxyMigrateLink(req, null));
    }

    async refreshGalaxyMigrateLink(linkId: string) {
        const req = new RefreshGalaxyMigrateLink.Request().setLinkid(linkId);
        return await this.progressService.track(this.api.gmService.refreshGalaxyMigrateLink(req, null));
    }

    async configureRelayServer(address: string, enable: boolean) {
        const req = new ConfigureCdcRelayServer.Request()
            .setSystemId(this.deploymentService.currentDeploymentID)
            .setConfig(new CdcRelayServerConfig().setEnable(enable).setAddress(address));

        return await this.progressService.track(this.api.gmService.configureCdcRelayServer(req, null));
    }

    async fetchGalaxyMigrateVmwareHelpers(onlyIfValidMigrationTargetFrom?: string) {
        const req = new ListVmwareHelpers.Request()
            .setProjectId(this.projectService.currentProjectID)
            .setOnlyIfValidMigrationTargetFrom(onlyIfValidMigrationTargetFrom);
        return await this.api.gmService.listVmwareHelpers(req, null);
    }

    async getSourceVmReadiness(systemId: string, targetEnvironment: HostEnvironment) {
        const req = new GetComputeMigrationSourceReadiness.Request()
            .setSystemId(systemId || this.deploymentService.currentDeploymentID)
            .setTargetEnvironment(targetEnvironment);
        return await this.api.gmService.getComputeMigrationSourceReadiness(req, null);
    }

    async prepareSourceForComputeMigration(systemId: string, targetEnvironment: HostEnvironment) {
        const req = new PrepareSourceForComputeMigration.Request()
            .setSystemId(systemId || this.deploymentService.currentDeploymentID)
            .setTargetEnvironment(targetEnvironment);
        return await this.progressService.track(this.api.gmService.prepareSourceForComputeMigration(req, null), "Preparing Host...");
    }

    async getVmwareHelperResourceSelections(helperId: string) {
        const req = new GetVmwareHelperResourceSelections.Request().setHelperId(helperId);
        const response = await this.api.gmService.getVmwareHelperResourceSelections(req, null);
        return response.toObject();
    }

    async getRelayServerStatus() {
        const req = new GetCdcRelayServerStatus.Request().setSystemId(this.deploymentService.currentDeploymentID);

        const response = await this.api.gmService.getCdcRelayServerStatus(req, null);
        return response.toObject();
    }

    async getIoStats(numOfPoints: number, domainsList: Array<string[]>, ioType: IO_TYPE) {
        const includeRead = !(ioType === IO_TYPE.WRITE);
        const includeWrite = !(ioType === IO_TYPE.READ);
        const timeDomain = new IOStatsDataSet.TimeDomain().setPoints(numOfPoints).setLive(false);

        if (numOfPoints === 300) {
            timeDomain.setLive(true);
        }

        const domains =
            domainsList.length > 0
                ? domainsList.map((domain) => {
                      return new IOStatsDataSet.Domain().setDevicePathsList(domain).setIncludeWrites(includeWrite).setIncludeReads(includeRead);
                  })
                : [new IOStatsDataSet.Domain().setDevicePathsList([]).setIncludeReads(includeRead).setIncludeWrites(includeWrite)];

        const req = new GetIOStats.Request().setTimeDomain(timeDomain).setDomainsList(domains).setSystemId(this.deploymentService.currentDeploymentID);

        const res = await this.api.gmService.getIOStats(req, null);
        return res.toObject();

        //return new GetIOStatsResponse().toObject()
    }

    async configureAzureHelper(systemId: string, integrationId: number) {
        const req = new ConfigureAzureHelper.Request().setSystemId(systemId).setIntegrationId(integrationId);

        const res = await this.progressService.track(this.api.gmService.configureAzureHelper(req, null), "Configuring Azure Helper...");
        return res.toObject();
    }
}

export class DeploymentUpgradeState {
    private readonly api: GRPCServices;
    private readonly deploymentService: DeploymentService;

    deploymentId: string = null;
    isUpgrading: boolean = false;

    upgradeStatus = new ServerData<GetLastOnlineUpgradeStatusResponse>().setDataFetcher(this.getLastOnlineUpgradeStatus.bind(this));

    constructor(api: GRPCServices, deploymentService: DeploymentService) {
        this.api = api;
        this.deploymentService = deploymentService;
        makeAutoObservable(this);
    }

    initUpgradeState(deploymentId: string) {
        if (deploymentId !== this.deploymentId) {
            this.deploymentId = deploymentId;
            this.upgradeStatus.resetData();
        }
    }

    public async checkForUpgrade() {
        const req = new CheckForOnlineUpgrade.Request().setSystemId(this.deploymentId);
        const res = await this.api.gmService.checkForOnlineUpgrade(req, null);
        return res.getCheckResponse();
    }

    public async upgradeGalaxyMigrate() {
        this.isUpgrading = true;
        const req = new PerformOnlineUpgrade.Request().setSystemId(this.deploymentId);
        await this.api.gmService.performOnlineUpgrade(req, null);
    }

    public async getLastOnlineUpgradeStatus() {
        const req = new GetLastOnlineUpgradeStatus.Request().setSystemId(this.deploymentId);

        const res = await this.api.gmService.getLastOnlineUpgradeStatus(req, null);
        if (res.getLastOnlineUpgradeStatusResponse()?.getLastStatus()?.getCompleted()) {
            this.isUpgrading = false;
        }
        return res.getLastOnlineUpgradeStatusResponse();
    }
}
