import { UseMutationOptions } from "@tanstack/react-query/src/types";
import { useGlobalDialogState } from "../dialog/GlobalDialogState";
import { QueryObserverSuccessResult, useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useGlobalProgressState } from "../progress/GlobalProgressState";
import { QueryKey } from "@tanstack/query-core";
export const useMutationAlertError = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    options: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
    const globalDialogState = useGlobalDialogState();
    const mutationFn = async (variables: TVariables) => await options.mutationFn(variables);

    return useMutation({
        mutationFn,
        ...options,
        onError: async (error, variables, context) => {
            await globalDialogState.addErrorAlertDialog(error as unknown as Error, true);
            options.onError(error, variables, context);
        },
    });
};
export const useMutationTrackAndAlertError = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    options: UseMutationOptions<TData, TError, TVariables, TContext>,
    loadingMessage?: string
) => {
    const globalDialogState = useGlobalDialogState();
    const track = useGlobalProgressState((s) => s.track);

    const mutationFn = async (variables: TVariables) => await track(options.mutationFn(variables), loadingMessage);

    return useMutation({
        ...options,
        onError: async (error, variables, context) => {
            await globalDialogState.addErrorAlertDialog(error as unknown as Error, true);
            options.onError(error, variables, context);
        },
    });
};
