import { KnownDataType } from "./formatter";

export function getAxesMaxValue(dataType: KnownDataType, dataArray: number[]) {
    const bytesInKb = 1024;
    const kb = bytesInKb;
    const mb = bytesInKb * kb;
    const gb = bytesInKb * mb;
    const tb = bytesInKb * gb;

    const us = 1;
    const ms = 1000 * us;
    const s = 1000 * ms;

    const maxValue = Math.max.apply(Math, dataArray);
    let availableMaxValues: number[] = [];
    if (dataType === KnownDataType.THROUGHPUT || dataType === KnownDataType.CAPACITY) {
        availableMaxValues = [
            10 * kb,
            50 * kb,
            100 * kb,
            500 * kb,
            mb,
            10 * mb,
            100 * mb,
            500 * mb,
            gb,
            5 * gb,
            10 * gb,
            50 * gb,
            100 * gb,
            500 * gb,
            tb,
            10 * tb,
            100 * tb,
            500 * tb,
        ];
    } else if (dataType === KnownDataType.NUMBER) {
        availableMaxValues = [10, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000, 5000000];
    } else if (dataType === KnownDataType.DURATION_MICROSECONDS) {
        availableMaxValues = [100 * us, 500 * us, ms, 10 * ms, 100 * ms, 500 * ms, s, 10 * s];
    }
    const len = availableMaxValues.length;
    for (let i = 0; i < len; i++) {
        const availableMaxValue = availableMaxValues[i];
        if (maxValue <= availableMaxValue) {
            return availableMaxValue;
        }
    }
    /* if not returned yet*/
    return maxValue;
}

export const getYAxisTickValues = (min: number, max: number, dividend: number): number[] => {
    // const tickValues = [];
    // const multiple = max / dividend;
    // if (min < 0) {
    //     for (let i = -dividend; i <= dividend; i++) {
    //         tickValues.push(multiple * i);
    //     }
    //     return tickValues;
    // } else {
    //     for (let i = 0; i <= dividend; i++) {
    //         tickValues.push(multiple * i);
    //     }
    //     return tickValues;
    // }
    return [min, max];
};

export const numberWithDecimal = (num = 0, decimalPlace = 0, noTrimming = false, returnAsNumber = false) => {
    if (typeof decimalPlace === "undefined" || decimalPlace === null) {
        return returnAsNumber ? num : num.toString();
    } else {
        let _num = num.toFixed(decimalPlace);
        // _num = noTrimming ? _num : (_num * 1);
        return returnAsNumber ? parseFloat(_num) : _num.toString();
    }
};

export const formatMicrosecond = (microSecond: number) => {
    const microSecondUnit = "μs";
    if (typeof microSecond === "undefined") {
        return "0 μs";
    }
    if (microSecond < 1000) {
        if (microSecond !== 0) {
            return (numberWithDecimal(microSecond, null) + " μs").toString();
        } else {
            return "0 μs";
        }
    }
    let millisecond = microSecond / 1000.0;
    if (millisecond < 1000) {
        return (numberWithDecimal(millisecond, null) + " ms").toString();
    }
    let second = millisecond / 1000.0;
    return (numberWithDecimal(second, 2) + " s").toString();
};
