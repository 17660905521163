// ======================
// LicenseActivationScreen
// ======================

import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Alert, alpha, Box, Button, ButtonGroup, Card, IconButton, Link, SvgIcon, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import React, { useCallback, useState } from "react";
import { SelectableBox } from "../../common/card/SelectableCard";
import { getCounterTypeDisplayValue, useCurrentProjectLicenseModel } from "../license/LicenseCommon";
import { FiMinus } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { useNavigateToExternalLink } from "../help/HelpCommon";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
// ======================
// RequestLicenseButton
// ======================

import { useHasPendingPELicenseRequest, useIsPELicenseActivated } from "./PrivateEditionCommon";

import { useNavigateToPeLicensePendingRequestScreen, useNavigateToProjectLicenseDetailsScreen } from "../management/ProjectManagementCommon";
import { useAppServices } from "../app/services";
import { TransactionCounterInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { renderServerDataWithLoadingBox, useInitData } from "../core/data/DataLoaderHooks";
import { LicenseModel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_model_pb";
import { useGetPendingPeLicenseRequest, useInitiatePeLicenseRequest } from "./private_edition_hooks";
import { InitiatePeLicenseRequest, SubmitPeLicenseReceipt } from "gc-web-proto/galaxycompletepb/apipb/pelocal_api_pb";
import { PeLicenseInteractionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/pe_license_interaction_type_pb";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";

type LicenseInteractionType = "Activation" | "Renewal" | "Withdrawal";

interface LicenseRequestScreenProps {
    type: LicenseInteractionType;
}

export const PrivateEditionLocalRequestLicenseScreen: React.FC<LicenseRequestScreenProps> = observer((p) => {
    const { type } = p;

    const isLicenseActivated = useIsPELicenseActivated();

    const hasPendingLicense = useGetPendingPeLicenseRequest();
    const licenseModel = useCurrentProjectLicenseModel();
    const navigateToPendingLicense = useNavigateToPeLicensePendingRequestScreen();

    const initiateLicenseRequest = useInitiatePeLicenseRequest();

    const getInstructions = () => {
        if (type === "Activation") {
            return `Please select from the following licenses to create your activation request. Upon activation, your machine will store these licenses in the
            license key to be consumed upon migration.`;
        } else if (type === "Withdrawal") {
            return "Please select the licenses you would like to withdraw from Cirrus Data Cloud";
        } else if (type === "Renewal") {
            return `Please select any additional license you would like to add upon renewal of your Private Edition license.`;
        }
    };

    const [counterState, setCounterState] = React.useState(getInitialCounterState(licenseModel));

    const onChangeQuantity = useCallback(
        (licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => {
            const newCounterState = new Map(counterState.set(licenseType, newValue));
            setCounterState(newCounterState);
        },
        [setCounterState, counterState]
    );

    const onLicenseRequested = useCallback(async () => {
        const getInteractionType = () => {
            if (type === "Activation" || type === "Renewal") {
                return PeLicenseInteractionType.PeLicenseInteractionType.ACTIVATION;
            } else if (type === "Withdrawal") {
                return PeLicenseInteractionType.PeLicenseInteractionType.WITHDRAWAL;
            }
        };

        const counters = Array.from(counterState.keys())
            .filter((c) => counterState.get(c) > 0)
            .map((c) => {
                const counterAmount =
                    c === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION
                        ? counterState.get(c) * Math.pow(1024, 4)
                        : counterState.get(c);
                return new TransactionCounterInfo().setCounterType(new LicenseVaultCounterType().setValue(c)).setAmount(counterAmount);
            });

        const req = new InitiatePeLicenseRequest.Request().setLicenseCountersList(counters).setInteractionType(getInteractionType());
        await initiateLicenseRequest
            .mutateAsync(req)
            .then(() => navigateToPendingLicense())
            .catch((e) => {});
    }, [counterState, initiateLicenseRequest, navigateToPendingLicense, type]);

    return (
        <QueryResultWrapper queryResult={hasPendingLicense}>
            <ScreenContainer>
                <ScreenTitleBar title={`License ${type} Request`} />
                {type !== "Withdrawal" && (
                    <Box pt={2}>
                        <Typography variant={"h5"}>{`1. Request License ${type}`}</Typography>
                        <Box pt={1}>
                            <Alert severity={"info"} variant={"outlined"}>
                                {`1 Private Edition Virtual Appliance License (60d) will be consumed from your Cirrus Data Cloud project. Once consumed, your virtual appliance will be licensed for 60 days.`}
                            </Alert>
                        </Box>
                        <br />
                    </Box>
                )}
                <Typography variant={"h5"}>{type !== "Withdrawal" ? "2. Select Additional Licenses" : "Select Licenses"}</Typography>
                <Box pt={1}>
                    <Typography color={"textSecondary"}>{getInstructions()}</Typography>
                </Box>
                <SelectLicenseTypesSection
                    counterState={counterState}
                    licenseModel={licenseModel}
                    setCounterState={setCounterState}
                    onChangeQuantity={onChangeQuantity}
                />
                <Box pt={2}>
                    <Alert severity={"warning"}>
                        {
                            "Caution! Make sure that you have sufficient licenses in your Cirrus Data Cloud project. You can withdraw additional licenses to this virtual appliance after activation at any time."
                        }
                    </Alert>
                </Box>
                <Box pt={4} display={"flex"} justifyContent={"center"}>
                    <Button disabled={!Array.from(counterState.values()).find((v) => v !== 0)} variant={"contained"} onClick={onLicenseRequested}>
                        Generate Request
                    </Button>
                </Box>
            </ScreenContainer>
        </QueryResultWrapper>
    );
});

// ======================
// SelectLicenseTypesSection
// ======================

interface SelectLicenseTypesSectionProps {
    licenseModel: LicenseModel.LicenseModel;
    counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>;
    setCounterState: (counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>) => void;
    onChangeQuantity: (licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => void;
}

export const SelectLicenseTypesSection: React.FC<SelectLicenseTypesSectionProps> = observer((p) => {
    const { counterState, onChangeQuantity, licenseModel, setCounterState } = p;
    const [selectedLicenseModel, setSelectedLicenseModel] = React.useState(licenseModel);

    return (
        <Box pt={2}>
            {licenseModel === LicenseModel.LicenseModel.UNDECIDED && (
                <Box display={"flex"} alignItems={"center"}>
                    <ToggleButtonGroup
                        size={"small"}
                        value={selectedLicenseModel}
                        exclusive
                        onChange={(e, v) => {
                            setSelectedLicenseModel(v);
                            setCounterState(getInitialCounterState(v));
                        }}
                    >
                        <ToggleButton
                            sx={{ border: "1px solid" }}
                            value={LicenseModel.LicenseModel.HOST_BASED}
                            selected={selectedLicenseModel === LicenseModel.LicenseModel.HOST_BASED}
                        >
                            {"Host-Based Licensing"}
                        </ToggleButton>
                        <ToggleButton
                            sx={{ border: "1px solid" }}
                            value={LicenseModel.LicenseModel.CAPACITY_BASED}
                            selected={selectedLicenseModel === LicenseModel.LicenseModel.CAPACITY_BASED}
                        >
                            {"Capacity-Based Licensing"}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )}

            {selectedLicenseModel === LicenseModel.LicenseModel.HOST_BASED && (
                <Box pt={2}>
                    <Typography variant={"h6"}>Host Migration License</Typography>
                    <LicenseActivationCounterCard
                        licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE}
                        onChangeQuantity={onChangeQuantity}
                        counterState={counterState}
                    />
                    <br />
                    <Typography variant={"h6"}>Migration Capacity Quota Add-On</Typography>
                    <LicenseActivationCounterCard
                        licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION}
                        onChangeQuantity={onChangeQuantity}
                        counterState={counterState}
                    />
                    <br />
                    <Typography variant={"h6"}>Host Migration License Extension</Typography>
                    <LicenseActivationCounterCard
                        licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION}
                        onChangeQuantity={onChangeQuantity}
                        counterState={counterState}
                    />
                    <LicenseActivationCounterCard
                        licenseType={LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION}
                        onChangeQuantity={onChangeQuantity}
                        counterState={counterState}
                    />
                </Box>
            )}
            {selectedLicenseModel === LicenseModel.LicenseModel.CAPACITY_BASED && (
                <Box pt={2}>
                    <LicenseActivationCounterCard
                        licenseType={LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION}
                        onChangeQuantity={onChangeQuantity}
                        counterState={counterState}
                    />
                </Box>
            )}
        </Box>
    );
});

// ======================
// LicenseActivationCounterCard
// ======================

interface LicenseActivationCounterCardProps {
    licenseType: LicenseVaultCounterType.LicenseVaultCounterType;
    onChangeQuantity: (licenseType: LicenseVaultCounterType.LicenseVaultCounterType, newValue: number) => void;
    counterState: Map<LicenseVaultCounterType.LicenseVaultCounterType, number>;
}

const LicenseActivationCounterCard: React.FC<LicenseActivationCounterCardProps> = observer((p) => {
    const currentQuantity = p.counterState.get(p.licenseType);
    const decreaseQuantity = () => {
        p.onChangeQuantity(p.licenseType, currentQuantity - 1);
    };

    const increaseQuantity = () => {
        p.onChangeQuantity(p.licenseType, currentQuantity + 1);
    };

    return (
        <Box pt={1} pb={1}>
            <SelectableBox selected={currentQuantity > 0} disableHoverEffects>
                <Box p={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                        <Typography>{getCounterTypeDisplayValue(p.licenseType)}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}></Typography>
                    </Box>
                    {p.licenseType !== LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION ? (
                        <Box display={"flex"} alignItems={"center"}>
                            <Box width={"100%"} pr={1}>
                                <IconButton onClick={decreaseQuantity} disabled={currentQuantity === 0}>
                                    <SvgIcon>
                                        <FiMinus />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                            <Box width={"100%"}>
                                <TextField
                                    inputProps={{
                                        style: { textAlign: "center" },
                                        min: 0,
                                    }}
                                    variant={"filled"}
                                    sx={{ width: 50 }}
                                    value={currentQuantity}
                                    onChange={(e) => {
                                        if (!!e.target.value) {
                                            p.onChangeQuantity(p.licenseType, parseInt(e.target.value));
                                        } else {
                                            p.onChangeQuantity(p.licenseType, null);
                                        }
                                    }}
                                />
                            </Box>

                            <Box width={"100%"} pl={1}>
                                <IconButton onClick={increaseQuantity}>
                                    <SvgIcon>
                                        <MdAdd />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        </Box>
                    ) : (
                        <Box height={"100%"}>
                            <TextField
                                inputProps={{
                                    style: { textAlign: "center", paddingTop: "8px" },
                                    min: 0,
                                }}
                                InputProps={{
                                    endAdornment: "TiB",
                                }}
                                type={"number"}
                                variant={"filled"}
                                sx={{ width: 100 }}
                                value={currentQuantity}
                                onChange={(e) => {
                                    if (!!e.target.value) {
                                        p.onChangeQuantity(p.licenseType, parseInt(e.target.value));
                                    } else {
                                        p.onChangeQuantity(p.licenseType, null);
                                    }
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </SelectableBox>
        </Box>
    );
});

const getInitialCounterState = (licenseModel: LicenseModel.LicenseModel) => {
    const counterState = new Map();

    if (licenseModel === LicenseModel.LicenseModel.HOST_BASED) {
        counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE, 0);
        counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION, 0);
        counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION, 0);
        counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE60_DAYS_EXTENSION, 0);
    }

    if (licenseModel === LicenseModel.LicenseModel.CAPACITY_BASED) {
        counterState.set(LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION, 0);
    }

    return counterState;
};
