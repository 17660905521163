import { observer } from "mobx-react-lite";
import React from "react";

export const getIsPrivateEdition = () => {
    if (!!import.meta.env.VITE_PRIVATE_EDITION) {
        return import.meta.env.VITE_PRIVATE_EDITION !== "0";
    } else {
        return false;
    }
};

// ======================
// PrivateEditionView
// ======================

interface PrivateEditionViewProps {
    children: React.ReactNode;
}

export const PrivateEditionView: React.FC<PrivateEditionViewProps> = observer((p) => {
    const isPrivateEdition = getIsPrivateEdition();

    if (isPrivateEdition) {
        return <>{p.children}</>;
    }
    return null;
});

interface PrivateEditionViewProps {
    children: React.ReactNode;
}

export const HideInPrivateEdition: React.FC<PrivateEditionViewProps> = observer((p) => {
    const isPrivateEdition = getIsPrivateEdition();

    if (!isPrivateEdition) {
        return <>{p.children}</>;
    }
    return null;
});
