// ======================
// LicenseTimeRemainingDisplay
// ======================

import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import * as React from "react";
import { Box, Button, Card, CardContent, LinearProgress, Link, Typography, useTheme } from "@mui/material";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { intervalToDuration, isAfter } from "date-fns";
import { getLicenseDurationLeftString, getLicenseExpirationDateColor } from "../galaxymigrate/hostLicense/HostLicenseCommon";
import { observer } from "mobx-react-lite";
import {
    useNavigateToPeLicensePendingRequestScreen,
    useNavigateToPeLicenseRenewalScreen,
    useNavigateToPeLicenseWithdrawalScreen,
} from "../management/ProjectManagementCommon";
import { useGetPendingPeLicenseRequest, useGetPrivateEditionLocalStatus } from "./private_edition_hooks";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { getCdcSaaSUrlForThisPE, useHasPendingPELicenseRequest } from "./PrivateEditionCommon";
import { useNavigateToExternalLink } from "../help/HelpCommon";
import Grid from "@mui/material/Grid2";

// ======================
// PendingLicenseSection
// ======================

interface PendingLicenseSectionProps {}

export const PrivateEditionPendingLicenseSection: React.FC<PendingLicenseSectionProps> = observer((p) => {
    const navToRequestLicenseScreen = useNavigateToPeLicensePendingRequestScreen();

    const queryResult = useGetPendingPeLicenseRequest();

    return (
        <QueryResultWrapper queryResult={queryResult}>
            {!!queryResult.data?.licenseRequest?.requestTime ? (
                <Box>
                    <Typography variant={"h6"}>Pending License Request</Typography>
                    <Typography>View and continue previous license request</Typography>
                    <Box pt={2} pb={4}>
                        <Card variant={"outlined"}>
                            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography>License Requested</Typography>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {formatKnownDataType(convertTimestampObjectToDate(queryResult.data?.licenseRequest?.requestTime), KnownDataType.DATE)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button variant={"contained"} onClick={navToRequestLicenseScreen}>
                                        Continue
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            ) : null}
        </QueryResultWrapper>
    );
});

interface LicenseTimeRemainingDisplayProps {
    licenseExpirationTime: Timestamp.AsObject;
}

const PrivateEditionLicenseTimeRemainingDisplay: React.FC<LicenseTimeRemainingDisplayProps> = (p) => {
    const { licenseExpirationTime } = p;
    const t = useTheme();
    const expDate = convertTimestampObjectToDate(licenseExpirationTime);
    const durationBetweenDates = intervalToDuration({
        start: Date.now(),
        end: expDate,
    });

    const durationBetweenDatesAsDays =
        durationBetweenDates.months * 30 + durationBetweenDates.days + durationBetweenDates.hours / 24 + durationBetweenDates.minutes / (60 * 24);

    const timePercentage = isAfter(Date.now(), expDate) ? 0 : (durationBetweenDatesAsDays / 60) * 100;

    return (
        <Box>
            <Typography variant={"h6"} sx={{ color: getLicenseExpirationDateColor(expDate, t, true) }}>
                {getLicenseDurationLeftString(expDate)}
            </Typography>
            <Box sx={{ color: getLicenseExpirationDateColor(expDate, t, true) }}>
                <LinearProgress variant={"determinate"} value={timePercentage} color={"inherit"} />
            </Box>
            <Typography variant={"overline"} sx={{ color: getLicenseExpirationDateColor(expDate, t, true) }}>
                {"Exp: "}
                {formatKnownDataType(expDate, KnownDataType.DATE)}
            </Typography>
        </Box>
    );
};

// ======================
// PrivateEditionLicenseInfoSection
// ======================

interface PrivateEditionLicenseInfoSectionProps {}

export const PrivateEditionLicenseInfoSection: React.FC<PrivateEditionLicenseInfoSectionProps> = observer((p) => {
    const hasPendingLicenseRequest = useHasPendingPELicenseRequest();
    const queryResult = useGetPrivateEditionLocalStatus();
    const navigateToLicenseRenewalScreen = useNavigateToPeLicenseRenewalScreen();
    const navigateToLicenseWithdrawalScreen = useNavigateToPeLicenseWithdrawalScreen();
    const navigateToCDC = useNavigateToExternalLink(getCdcSaaSUrlForThisPE(queryResult?.data?.status));

    const getCanRequestExtension = (expDate: Date) => {
        const now = Date.now();
        const durationBetweenDates = intervalToDuration({
            start: now,
            end: expDate,
        });

        const withinOneWeek = durationBetweenDates.months === 0 && durationBetweenDates.days <= 6;

        return isAfter(now, expDate) || withinOneWeek;
    };

    return (
        <QueryResultWrapper queryResult={queryResult}>
            {queryResult.data?.status?.licenseActivated ? (
                <Box pb={2}>
                    <Typography variant={"h6"}>Private Edition License</Typography>
                    <Typography variant={"body2"}>
                        This virtual appliance is registered to Cirrus Data Cloud project{" "}
                        <Link onClick={navigateToCDC}>{queryResult?.data?.status?.cdcProjectName}</Link>
                    </Typography>
                    <br />
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 6,
                                    }}
                                >
                                    {queryResult.data?.status?.licenseExpirationTime ? (
                                        <PrivateEditionLicenseTimeRemainingDisplay licenseExpirationTime={queryResult.data?.status?.licenseExpirationTime} />
                                    ) : null}
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 6,
                                    }}
                                >
                                    <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} height={"100%"}>
                                        <Box>
                                            {getCanRequestExtension(convertTimestampObjectToDate(queryResult.data?.status?.licenseExpirationTime)) ? (
                                                <Button disabled={hasPendingLicenseRequest} variant={"contained"} onClick={navigateToLicenseRenewalScreen}>
                                                    {"Request License Extension"}
                                                </Button>
                                            ) : (
                                                <Button disabled={hasPendingLicenseRequest} variant={"contained"} onClick={navigateToLicenseWithdrawalScreen}>
                                                    {"Withdraw Additional License"}
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            ) : null}
        </QueryResultWrapper>
    );
});
