import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { GmMigrationAutoAllocationState } from "../../GmMigrationService";
import {
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import { AutoAlloc, BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { DiskIcon } from "../../../../common/CommonIcons";
import { formatKnownDataType, formatTitleCase, KnownDataType } from "../../../../common/utils/formatter";
import produce from "immer";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import { TruncatedText } from "../../../../common/text/TruncatedText";
import Grid from "@mui/material/Grid2";

// ======================
// VmwareDatastoreAllocateVolumesStep
// ======================
interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}

interface VmwareDiskAssignmentConfig {
    capacity: number;
    datastore: string;
    directory: string;
    provisioning: AutoAlloc.VolumeParams.VMware.DiskProvisioningMode;
    diskMode: AutoAlloc.VolumeParams.VMware.DiskMode;
    sharing: boolean;
}

export const VmwareDatastoreAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.vmware;

    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setVmware(
                new AutoAlloc.VolumeParams.VMware()
                    .setDatastore(defaultParams.datastore)
                    .setDirectory(defaultParams.directory)
                    .setSharing(defaultParams.sharing)
                    .setDiskMode(defaultParams.diskMode)
                    .setProvisioning(defaultParams.provisioning)
            );
        }
    });

    const getInitialDeviceAssignments = () => {
        const assignments: { [key: string]: VmwareDiskAssignmentConfig } = {};
        for (let device of state.sourceDevices) {
            const blockDevice = device.source.getBlockDevice();
            assignments[blockDevice.getDeviceName()] = {
                capacity: blockDevice.getCapacity() / Math.pow(1024, 3),
                datastore: defaultParams.datastore,
                directory: defaultParams.directory,
                provisioning: defaultParams.provisioning,
                diskMode: defaultParams.diskMode,
                sharing: defaultParams.sharing,
            };
        }
        return assignments;
    };

    const [deviceConfigs, setDeviceConfigs] = useState<{
        [key: string]: VmwareDiskAssignmentConfig;
    }>(getInitialDeviceAssignments());

    const setDeviceCapacity = (deviceName: string, capacity: number) => {
        setDeviceConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[deviceName].capacity = capacity;
                } else {
                    draft[deviceName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const setDeviceField = <VmwareDiskAssignmentConfigKey extends keyof VmwareDiskAssignmentConfig>(
        deviceName: string,
        field: VmwareDiskAssignmentConfigKey,
        value: VmwareDiskAssignmentConfig[VmwareDiskAssignmentConfigKey]
    ) => {
        setDeviceConfigs(
            produce((draft) => {
                draft[deviceName][field] = value;
            })
        );
    };
    const getHasError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        }
        for (let device in deviceConfigs) {
            const blockDevice = state.sourceDevices.find((d) => d.source.getBlockDevice().getDeviceName() === device);
            if (deviceConfigs[device].capacity < blockDevice?.source.getBlockDevice().getCapacity() / Math.pow(1024, 3)) {
                return true;
            }
        }
        return false;
    };

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Destination Volumes will be allocated from the connected storage to match the following source volumes`}
            </Typography>
            <br />
            <List>
                {state.sourceDevices.map((device) => {
                    const blockDevice = device.source.getBlockDevice();
                    return (
                        <VmwareDatastoreDiskCard
                            state={state}
                            blockDevice={blockDevice}
                            setDeviceField={setDeviceField}
                            currentDeviceFields={deviceConfigs[blockDevice.getDeviceName()]}
                            setDeviceCapacity={setDeviceCapacity}
                            currentDeviceCapacity={deviceConfigs[blockDevice.getDeviceName()].capacity}
                        />
                    );
                })}
            </List>
            <Box pt={2} pb={2}>
                <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => {
                        for (let deviceName in deviceConfigs) {
                            const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === deviceName);
                            const vmware = sourceDeviceInAutoAllocState.autoAllocParams.getVmware();
                            vmware.setDatastore(deviceConfigs[deviceName].datastore);
                            vmware.setDirectory(deviceConfigs[deviceName].directory);
                            vmware.setProvisioning(deviceConfigs[deviceName].provisioning);
                            vmware.setSharing(deviceConfigs[deviceName].sharing);
                            vmware.setDiskMode(deviceConfigs[deviceName].diskMode);
                        }
                        allocateNow();
                    }}
                    disabled={getHasError()}
                >
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});

// ======================
// VmwareDatastoreDiskCard
// ======================

interface VmwareDatastoreDiskCardProps {
    state: GmMigrationAutoAllocationState;
    blockDevice: BlockDeviceInfo;
    currentDeviceCapacity: number;
    setDeviceCapacity: (deviceName: string, capacity: number) => void;
    currentDeviceFields: VmwareDiskAssignmentConfig;
    setDeviceField: <VmwareDiskAssignmentConfigKey extends keyof VmwareDiskAssignmentConfig>(
        deviceName: string,
        field: VmwareDiskAssignmentConfigKey,
        value: VmwareDiskAssignmentConfig[VmwareDiskAssignmentConfigKey]
    ) => void;
}

const VmwareDatastoreDiskCard: React.FC<VmwareDatastoreDiskCardProps> = observer((p) => {
    const { state, blockDevice, currentDeviceCapacity, setDeviceCapacity, currentDeviceFields, setDeviceField } = p;

    const defaultHelperText = "Set a custom disk size.";

    const [capacityHelperText, setCapacityHelperText] = useState(defaultHelperText);

    const getCapacityError = () => {
        if (state.allowSmallerDestinations) {
            return false;
        } else {
            return currentDeviceCapacity < blockDevice.getCapacity() / Math.pow(1024, 3);
        }
    };

    return (
        <Card sx={{ mb: 2 }}>
            <Grid container spacing={2} width={"100%"} p={2}>
                <Grid size={9}>
                    <ListItem key={blockDevice.getDeviceName()}>
                        <ListItemIcon>
                            <DiskIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={`${blockDevice.getDeviceName()} (${blockDevice.getDeviceType()})`}
                            secondary={formatKnownDataType(blockDevice.getCapacity(), KnownDataType.CAPACITY)}
                        />
                    </ListItem>
                </Grid>

                <Grid size={3}>
                    <TextField
                        fullWidth
                        label={"Disk Size"}
                        variant={"outlined"}
                        error={getCapacityError()}
                        value={currentDeviceCapacity}
                        type={"number"}
                        onChange={(e) => {
                            setDeviceCapacity(blockDevice.getDeviceName(), Number(e.target.value));
                            if (!state.allowSmallerDestinations && Number(e.target.value) < blockDevice.getCapacity() / Math.pow(1024, 3)) {
                                setCapacityHelperText("Must be greater than or equal to source volume size.");
                            } else {
                                setCapacityHelperText(defaultHelperText);
                            }
                        }}
                        helperText={capacityHelperText}
                        InputProps={{ endAdornment: <Typography>{`GiB`}</Typography> }}
                    />
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} width={"100%"} p={2}>
                <Grid size={3}>
                    <TextField
                        fullWidth
                        label={"Datastore"}
                        variant={"filled"}
                        value={currentDeviceFields.datastore}
                        onChange={(e) => {
                            setDeviceField(blockDevice.getDeviceName(), "datastore", e.target.value);
                        }}
                        helperText={
                            <TruncatedText
                                characterLimit={20}
                                variant={"caption"}
                                text={`Name of datastore. If not specified, same datastore as virtual machine (vmx file) will be used.`}
                            />
                        }
                    />
                </Grid>

                <Grid size={3}>
                    <TextField
                        fullWidth
                        label={"Directory"}
                        variant={"filled"}
                        value={currentDeviceFields.directory}
                        onChange={(e) => {
                            setDeviceField(blockDevice.getDeviceName(), "directory", e.target.value);
                        }}
                        helperText={
                            <TruncatedText
                                characterLimit={31}
                                variant={"caption"}
                                text={`Directory inside the datastore to place the vmdk. If not specified, same folder as virtual machine will be used. Folder will be created automatically if not exists.`}
                            />
                        }
                    />
                </Grid>
                <Grid size={3}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"} id={"select-provisioning-label"}>
                            {"Provisioning"}
                        </InputLabel>
                        <Select
                            variant={"filled"}
                            labelId={"select-provisioning-label"}
                            id={"select-provisioning"}
                            value={currentDeviceFields.provisioning}
                            onChange={(e) => {
                                setDeviceField(blockDevice.getDeviceName(), "provisioning", Number(e.target.value));
                                if (Number(e.target.value) !== AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THICK_EAGER_ZEROED) {
                                    setDeviceField(blockDevice.getDeviceName(), "sharing", false);
                                }
                            }}
                        >
                            {Object.keys(AutoAlloc.VolumeParams.VMware.DiskProvisioningMode).map((key) => {
                                const provisioningMode =
                                    AutoAlloc.VolumeParams.VMware.DiskProvisioningMode[key as keyof typeof AutoAlloc.VolumeParams.VMware.DiskProvisioningMode];
                                const label = formatTitleCase(key.replaceAll("_", " "));

                                return (
                                    <MenuItem value={provisioningMode} key={key}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>
                            <TruncatedText variant={"caption"} characterLimit={30} text={"Provisioning mode. Defaults to thin provisioning"} />
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid size={3}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"} id={"select-diskmode-label"}>
                            {"Disk Mode"}
                        </InputLabel>
                        <Select
                            variant={"filled"}
                            labelId={"select-diskmode-label"}
                            id={"select-diskmode"}
                            value={currentDeviceFields.diskMode}
                            onChange={(e) => {
                                setDeviceField(blockDevice.getDeviceName(), "diskMode", Number(e.target.value));
                            }}
                        >
                            {Object.keys(AutoAlloc.VolumeParams.VMware.DiskMode).map((key) => {
                                const diskMode = AutoAlloc.VolumeParams.VMware.DiskMode[key as keyof typeof AutoAlloc.VolumeParams.VMware.DiskMode];
                                const label = formatTitleCase(key.replaceAll("_", " "));
                                return (
                                    <MenuItem value={diskMode} key={key}>
                                        {label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>
                            <TruncatedText variant={"caption"} characterLimit={30} text={"Disk mode for the new disk. Defaults to persistent"} />
                        </FormHelperText>
                    </FormControl>
                </Grid>
                {currentDeviceFields.provisioning === AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THICK_EAGER_ZEROED && (
                    <Grid>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={currentDeviceFields.sharing}
                                    onChange={(e) => {
                                        setDeviceField(blockDevice.getDeviceName(), "sharing", e.target.checked);
                                    }}
                                />
                            }
                            disabled={currentDeviceFields.provisioning !== AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THICK_EAGER_ZEROED}
                            label={
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Sharing mode for the new disk. If enabled, multi-writer mode will be used when allocating new volume"}
                                </Typography>
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    );
});
