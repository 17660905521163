// ======================
// TooltipTimeText
// ======================

import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Box, Tooltip, Typography, TypographyProps } from "@mui/material";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../utils/formatter";
import { format } from "date-fns";

interface TooltipTimeTextProps {
    time: Timestamp.AsObject;
    dateFormat?: string;
}

export const TooltipTimeText: React.FC<TooltipTimeTextProps & TypographyProps> = (p) => {
    const { time, dateFormat, ...typographyProps } = p;
    const timeAsDate = convertTimestampObjectToDate(time);
    const displayTime = dateFormat ? format(timeAsDate, dateFormat) : formatKnownDataType(timeAsDate, KnownDataType.DATE_RELATIVE);
    return (
        <Tooltip title={formatKnownDataType(timeAsDate, KnownDataType.DATE)}>
            <Box>
                <Typography {...typographyProps}>{displayTime}</Typography>
            </Box>
        </Tooltip>
    );
};
