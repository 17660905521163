import { ColumnSortFilterConfig, ListFilterableField } from "../core/data/ListData";
import { ListGalaxyMigrateDeployments } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { SortState } from "../../common/table/DataTable";
import { ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { DeploymentHostEnvironment, DeploymentHostEnvironmentFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { MtdiOsType, MtdiOsTypeFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb";
import { getHostEnvDisplayName } from "./GalaxyMigrateCommon";
import { getMtdiDeploymentOsDisplayName } from "../project/ProjectCommon";
import { convertNumToProtoDuration } from "../../common/utils/formatter";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
export const getDeploymentListFilterConfig: () => ColumnSortFilterConfig<
    ListGalaxyMigrateDeployments.Request,
    ListGalaxyMigrateDeployments.Request.Sort,
    any,
    any
> = () => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.REGISTERED_AT,
                label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.REGISTERED_AT),
            },
            defaultSortDesc: true,
            sortFields: [
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_NAME,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_NAME),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.LAST_CHECKIN,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.LAST_CHECKIN),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.LATENCY,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.LATENCY),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.REGISTERED_AT,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.REGISTERED_AT),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.CONNECTED,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.CONNECTED),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.OS_TYPE,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.OS_TYPE),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_ENVIRONMENT,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_ENVIRONMENT),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.KERNEL,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.KERNEL),
                },
                {
                    fieldId: ListGalaxyMigrateDeployments.Request.Sort.Field.MTDI_VERSION,
                    label: getListGmDeploymentsSortFieldLabel(ListGalaxyMigrateDeployments.Request.Sort.Field.MTDI_VERSION),
                },
            ],
            getSortParam: (s: SortState) => new ListGalaxyMigrateDeployments.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value),
        },
        filter: [
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.NAME,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.NAME),
                filterType: "string",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setName(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LABEL,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LABEL),
                filterType: "simpleString",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setLabel(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.VERSION,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.VERSION),
                filterType: "string",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setVersion(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_TYPE,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_TYPE),
                filterType: "enum",
                enumLabel: "OS Type",
                enumValuesList: Object.values(MtdiOsType.MtdiOsType),
                getFormValueDisplayLabel: (v: MtdiOsType.MtdiOsType) => getMtdiDeploymentOsDisplayName(v),
                createParamFromFormValue: (v: MtdiOsType.MtdiOsType) => new MtdiOsTypeFilter().setHas(new MtdiOsType().setValue(v)),
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: MtdiOsTypeFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setOsType(f));
                },
            } as ListFilterableField<ListGalaxyMigrateDeployments.Request, MtdiOsTypeFilter, MtdiOsType.MtdiOsType>,
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_CLASS,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_CLASS),
                filterType: "string",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setOsClass(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.MTDI_VERSION,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.MTDI_VERSION),
                filterType: "string",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setMtdiVersion(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.KERNEL,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.KERNEL),
                filterType: "string",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setKernel(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.HOST_ENVIRONMENT,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.HOST_ENVIRONMENT),
                filterType: "enum",
                enumLabel: "Host Environment",
                enumValuesList: Object.values(DeploymentHostEnvironment.DeploymentHostEnvironment),
                getFormValueDisplayLabel: (v: DeploymentHostEnvironment.DeploymentHostEnvironment) => getHostEnvDisplayName(v),
                createParamFromFormValue: (v: DeploymentHostEnvironment.DeploymentHostEnvironment) =>
                    new DeploymentHostEnvironmentFilter().setHas(new DeploymentHostEnvironment().setValue(v)),
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: DeploymentHostEnvironmentFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setHostEnvironment(f));
                },
            } as ListFilterableField<
                ListGalaxyMigrateDeployments.Request,
                DeploymentHostEnvironmentFilter,
                DeploymentHostEnvironment.DeploymentHostEnvironment
            >,
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LAST_CHECKIN,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LAST_CHECKIN),
                filterType: "date",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setLastCheckin(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.REGISTERED_AT,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.REGISTERED_AT),
                filterType: "date",
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setLastCheckin(f));
                },
            },
            {
                fieldId: ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LATENCY,
                label: getListFilterFieldLabel(ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LATENCY),
                filterType: "duration",
                durationUnit: "ms",
                formatDisplayValue: (value: Duration) => `${value.getSeconds() * 1000 + value.getNanos() / 1000000}ms`,
                createDurationFromFormValue: (v: number) => convertNumToProtoDuration(v, "ms"),
                createFormValueFromDuration: (f: Duration) => f.getSeconds() * 1000 + f.getNanos() / 1000000,
                addFilterToRequest: (r: ListGalaxyMigrateDeployments.Request, f: FilterParams.DurationFilter) => {
                    r.addFilterParams(new ListGalaxyMigrateDeployments.Request.Filter().setLatency(f));
                },
            },
        ],
    };
};

const getListGmDeploymentsSortFieldLabel = (field: ListGalaxyMigrateDeployments.Request.Sort.Field) => {
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_NAME) {
        return "Host Name";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.LATENCY) {
        return "Latency";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.LAST_CHECKIN) {
        return "Last Check-in";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.REGISTERED_AT) {
        return "Registered At";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.CONNECTED) {
        return "Connected";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.OS_TYPE) {
        return "OS Type";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.HOST_ENVIRONMENT) {
        return "Host Environment";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.KERNEL) {
        return "Kernel";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Sort.Field.MTDI_VERSION) {
        return "mTDI Version";
    }
    return "";
};

const getListFilterFieldLabel = (field: ListGalaxyMigrateDeployments.Request.Filter.FieldCase) => {
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.NAME) {
        return "Host Name";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_TYPE) {
        return "OS Type";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.OS_CLASS) {
        return "OS Class";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.HOST_ENVIRONMENT) {
        return "Host Environment";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LATENCY) {
        return "Latency";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LABEL) {
        return "Label";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.LAST_CHECKIN) {
        return "Last Check-in";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.REGISTERED_AT) {
        return "Registered At";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.VERSION) {
        return "Version";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.MTDI_VERSION) {
        return "mTDI Version";
    }
    if (field === ListGalaxyMigrateDeployments.Request.Filter.FieldCase.KERNEL) {
        return "Kernel";
    }
    return "";
};
