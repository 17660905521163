// ======================
// MigrateOpsMigrationSessionTab
// ======================

import { Box } from "@mui/material";
import { useAppServices } from "../../app/services";
import { useInitData } from "../../core/data/DataLoaderHooks";
import { MigrationSessionsTable } from "../../cmcMigration/ProjectCmcMigrationSessionsListScreen";

interface MigrateOpsMigrationSessionTabProps {
    opId: number;
}

export const MigrateOpsMigrationSessionTab: React.FC<MigrateOpsMigrationSessionTabProps> = (p) => {
    const { opId } = p;
    const { gmMigrationService, licenseService } = useAppServices();

    const poll = async () => {
        await gmMigrationService.sessions.fetchData({ operationId: Number(opId) });
        await licenseService.projectLicenseDetails.fetchData();
    };

    useInitData({
        poll: poll,
        pollInterval: 5,
        deinit: () => {
            gmMigrationService.sessions.resetData();
        },
    });
    return (
        <Box pt={2}>
            <MigrationSessionsTable
                serverData={gmMigrationService.sessions}
                emptyTableConfig={{
                    actionButton: <></>,
                    message: "No migration sessions associated with this operation found.",
                }}
            />
        </Box>
    );
};
