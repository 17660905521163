/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppRoutes
 */
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AZURE_STORAGE_PLANNER_ROUTE } from "../integrations/azureStoragePlanner/AzureStoragePlannerHelpers";

export const APP_ROUTES = {
    PROJECTS: "/projects",
    USER: "/profile",
    USER_LICENSE: "/user-license",
    CALLBACKS: "/callbacks", //for hooks, oid, saml, etc.
    SUPPORT_PROJECTS: "/support-projects",
    REPORTS: "/reports",
    CUSTOMER_CENTER: "/customer-center",
};

export const AUTH_ROUTES = {
    SET_PASSWORD: "/set-password",
    SIGN_UP: "/sign-up",
    CONNECT_AWS: "/aws-mp-signup",
    CONNECT_AZURE: "/azure-mp-signup",
};

export const UNAUTHENTICATED_ROUTES = {
    MIGRATEOPS_DOCS: "/migrate-ops-docs",
};

export enum LICENSE_SUBROUTE {
    PURCHASE = "purchase",
    REQUEST = "request",
    ACTIVATE = "activate",
    WITHDRAW = "withdraw",
    RENEW = "renew",
}

export enum PROJECT_SUBROUTE {
    DASHBOARD = "dashboard",
    DEPLOYMENTS = "deployments",
    CMC_MIGRATIONS = "cmc-migrations",
    CMO_MIGRATIONS = "cmo-migrations",
    MIGRATE_OPS = "migrateops",
    MIGRATE_OPS_DOCS = "migrateops-docs",
    MANAGEMENT = "management",
    REPORTS = "reports",
    SETTINGS = "project-settings",
    INTEGRATIONS = "integrations",
    GLOBAL_MAP_VIEW = "deployments-map",
}

export enum MANAGEMENT_SUBROUTE {
    LICENSE = "license",
}

export enum INTEGRATIONS_SUBROUTE {
    CREATE = "create",
    ENABLED = "enabled",
    AZURE_STORAGE_PLANNER = "azure-consumption-plans",
}

export enum CMC_MIGRATION_SUBROUTE {
    DEPLOYMENTS = "deployments",
    SESSIONS = "sessions",
    CONNECTIONS = "connections",
    WIZARD = "wizard",
    AUTO_ALLOCATION = "auto-allocation",
    VOLUMES = "volumes", //under session id,
    WORKFLOW_RUNS = "workflow-runs", //under session id
    QOS = "qos", // under volumes
    EDIT = "edit",
    EDIT_VM_CONFIG = "edit-vm-config",
}

export enum CMO_MIGRATION_SUBROUTE {
    DASHBOARD = "dashboard",
    CMO_SYSTEMS = "appliances",
    NEXUS = "nexus",
    MIGRATION_SESSIONS = "sessions",
    WARNINGS = "warnings",
}

export enum MIGRATE_OPS_SUBROUTE {
    CREATE = "create",
    BUILDER = "builder",
    WIZARD = "wizard",
}

export enum MIGRATE_OPS_DOCS_SUBROUTE {
    GETTING_STARTED = "getting-started",
}

export enum DEPLOYMENT_SUBROUTE {
    DEPLOYED = "deployed",
    CONNECTIONS = "connections",
}

export enum DEPLOYMENT_DETAILS_SUBROUTE {
    HOST_INFO = "host-info",
    SETTINGS = "deployment-settings",
    RELAY = "management-relay",
    LOG = "live-log",
    LICENSE = "license",
    XRAYS = "xrays",
    PERFORMANCE = "performance",
    LABELS = "labels",
}

export enum CALLBACK_SUBROUTE {
    LICENSE_PURCHASE_SUCCESS = "purchase-success",
    LICENSE_PURCHASE_CANCELLED = "purchase-cancelled",
}

export const getProjectSubRouteTemplate = (sub: string) => {
    return `${APP_ROUTES.PROJECTS}/:projectId/${sub}`;
};

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};
