import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Divider, ListSubheader, Theme, Typography } from "@mui/material";
import enUS from "date-fns/locale/en-US";
import { Calendar, Components, Culture, dateFnsLocalizer, DateLocalizer, DateRange, Event, Formats } from "react-big-calendar";
import "./qosCalendar.css";
import {
    format,
    getDay,
    nextFriday,
    nextMonday,
    nextSaturday,
    nextThursday,
    nextTuesday,
    nextWednesday,
    parse,
    previousFriday,
    previousMonday,
    previousSunday,
    previousThursday,
    previousTuesday,
    previousWednesday,
    startOfWeek,
} from "date-fns";

import { CmoMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { FormSelectableCardConfig } from "../../../../common/form/FormComponents";
import { CmoMigrationQosImpactLevel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_qos_impact_level_pb";
import MinimumMode from "../../../../assets/migrationSession/minimum.png";
import ModerateMode from "../../../../assets/migrationSession/moderate.png";
import AggressiveMode from "../../../../assets/migrationSession/aggressive.png";
import { getCmoMigrationQosImpactLevelLabel } from "../../CmoMigrationCommon";
import { BlackTooltip } from "../../../../common/tooltip/ColorTooltip";

const locales = {
    "en-US": enUS,
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

// ======================
// EventCard
// ======================

interface EventCardProps {
    event: Event;
}

export const EventCard: React.FC<EventCardProps> = (p) => {
    const { event } = p;
    return (
        <BlackTooltip
            title={
                <Box>
                    {`${format(event.start, "hh:mm aa")} - ${format(new Date(event.end).setMinutes(event.end.getMinutes() + 1), "hh:mm aa")} : ${
                        getImpactLevelConfig(event?.resource?.qosLevel)?.title
                    } iQOS Level`}
                </Box>
            }
            placement={"top"}
            arrow
        >
            <Box>{event.title}</Box>
        </BlackTooltip>
    );
};

// ======================
// GalaxyMigrateMigrationSessionDetailsQosCalendar
// ======================

interface CmoMigrationSessionDetailsQosCalendarProps {
    qosSchedule: CmoMigrationSessionInfo.QosSchedule.AsObject;
    timezone?: string;
}

export const CmoMigrationSessionDetailsQosCalendar: React.FC<CmoMigrationSessionDetailsQosCalendarProps> = observer((p) => {
    const { qosSchedule, timezone } = p;
    const components = useMemo(
        (): Components => ({
            toolbar: () => null,
            event: EventCard,
        }),
        []
    );

    const formats = useMemo(
        (): Formats => ({
            dayFormat: (date: Date, culture: string, localizer: DateLocalizer) => localizer.format(date, "EEEE", culture),
            eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) => {
                const endDisplayDate = format(new Date(range.end).setMinutes(range.end.getMinutes() + 1), "hh:mm aa");
                return `${format(range.start, "hh:mm aa")}-${endDisplayDate}`;
            },
        }),
        []
    );

    return (
        <Box pt={4}>
            <Card sx={{ color: (t: Theme) => t.palette.text.secondary }}>
                <ListSubheader>iQOS Schedule {timezone ? `(in ${timezone})` : ""}</ListSubheader>
                <Divider />
                <Calendar
                    formats={formats}
                    components={components}
                    selectable={false}
                    getNow={() => null}
                    events={getEventsFromQosSchedule(qosSchedule)}
                    views={["week"]}
                    defaultDate={new Date()}
                    defaultView={"week"}
                    style={{ height: 1250 }}
                    localizer={localizer}
                    startAccessor={"start"}
                    endAccessor={"end"}
                    tooltipAccessor={(event) => ""}
                />
            </Card>
        </Box>
    );
});

export const getEventFromTimeslot = (dayOfWeek: number, timeslot: CmoMigrationSessionInfo.QosSchedule.TimeSlot.AsObject): Event => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDate = today.getDate();
    const currentDay = today.getDay();

    let targetYear;
    let targetMonth;
    let targetDate;

    if (dayOfWeek === 0) {
        targetYear = currentDay === dayOfWeek ? currentYear : previousSunday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : previousSunday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : previousSunday(today).getDate();
    }
    if (dayOfWeek === 1) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextMonday(today).getFullYear() : previousMonday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextMonday(today).getMonth() : previousMonday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextMonday(today).getDate() : previousMonday(today).getDate();
    }
    if (dayOfWeek === 2) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextTuesday(today).getFullYear() : previousTuesday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextTuesday(today).getMonth() : previousTuesday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextTuesday(today).getDate() : previousTuesday(today).getDate();
    }
    if (dayOfWeek === 3) {
        targetYear =
            currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextWednesday(today).getFullYear() : previousWednesday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextWednesday(today).getMonth() : previousWednesday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextWednesday(today).getDate() : previousWednesday(today).getDate();
    }
    if (dayOfWeek === 4) {
        targetYear =
            currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextThursday(today).getFullYear() : previousThursday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextThursday(today).getMonth() : previousThursday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextThursday(today).getDate() : previousThursday(today).getDate();
    }
    if (dayOfWeek === 5) {
        targetYear = currentDay === dayOfWeek ? currentYear : currentDay < dayOfWeek ? nextFriday(today).getFullYear() : previousFriday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : currentDay < dayOfWeek ? nextFriday(today).getMonth() : previousFriday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : currentDay < dayOfWeek ? nextFriday(today).getDate() : previousFriday(today).getDate();
    }
    if (dayOfWeek === 6) {
        targetYear = currentDay === dayOfWeek ? currentYear : nextSaturday(today).getFullYear();
        targetMonth = currentDay === dayOfWeek ? currentMonth : nextSaturday(today).getMonth();
        targetDate = currentDay === dayOfWeek ? currentDate : nextSaturday(today).getDate();
    }

    const start = new Date(targetYear, targetMonth, targetDate, timeslot.startHour, timeslot.startMinute);
    const end = new Date(targetYear, targetMonth, targetDate, timeslot.endHour, timeslot.endMinute - 1);
    const endDisplayDate = format(new Date(end).setMinutes(end.getMinutes() + 1), "hh:mm aa");
    return {
        start: start,
        end: end,
        allDay: false,
        title: (
            <Box pt={1}>
                <Typography variant={"caption"}>
                    {format(start, "hh:mm aa")}-{endDisplayDate}
                </Typography>
                <Box height={10} />
                <Typography fontWeight={700}>{getImpactLevelConfig(timeslot.qosLevel).title}</Typography>
                <Typography variant={"caption"}>iQOS Level</Typography>
            </Box>
        ),
        resource: timeslot,
    };
};

const getEventsFromQosSchedule = (qosSchedule: CmoMigrationSessionInfo.QosSchedule.AsObject): Event[] => {
    const events: Event[] = [];

    if (!!qosSchedule.weekendsList) {
        for (let interval of qosSchedule.weekendsList) {
            const sundayEvents = getEventFromTimeslot(0, interval);
            events.push(sundayEvents);
            const saturdayEvents = getEventFromTimeslot(6, interval);
            events.push(saturdayEvents);
        }
    }
    if (!!qosSchedule.weekdaysList) {
        for (let interval of qosSchedule.weekdaysList) {
            const mondayEvents = getEventFromTimeslot(1, interval);
            events.push(mondayEvents);
            const tuesdayEvents = getEventFromTimeslot(2, interval);
            events.push(tuesdayEvents);
            const wednesdayEvents = getEventFromTimeslot(3, interval);
            events.push(wednesdayEvents);
            const thursdayEvents = getEventFromTimeslot(4, interval);
            events.push(thursdayEvents);
        }
    }
    if (!!qosSchedule.beforeWeekendsList) {
        for (let interval of qosSchedule.beforeWeekendsList) {
            const fridayEvents = getEventFromTimeslot(5, interval);
            events.push(fridayEvents);
        }
    }

    return events;
};

export const impactLevelOptions: FormSelectableCardConfig[] = [
    {
        title: getCmoMigrationQosImpactLevelLabel(CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.MINIMAL),
        description: "Application will have absolute priority",
        icon: <img src={MinimumMode} height={72} alt={"Minimum Mode"} />,
        value: CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.MINIMAL,
    },
    {
        title: getCmoMigrationQosImpactLevelLabel(CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.MODERATE),
        description: "Migration will compete with Application for up to 30%",
        icon: <img src={ModerateMode} height={72} alt={"Moderate Mode"} />,
        value: CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.MODERATE,
    },
    {
        title: getCmoMigrationQosImpactLevelLabel(CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.AGGRESSIVE),
        description: "Migration will compete with Application for up to 75%",
        icon: <img src={AggressiveMode} height={72} alt={"Aggressive Mode"} />,
        value: CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel.AGGRESSIVE,
    },
];
export const getImpactLevelConfig = (impactLevel: CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel): FormSelectableCardConfig => {
    return impactLevelOptions.find((o) => o.value === impactLevel);
};
