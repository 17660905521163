// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectCommon

import * as React from "react";
import { Box, SvgIcon, SvgIconProps, Theme } from "@mui/material";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES, getProjectSubRouteTemplate } from "../app/AppRoutes";
import { AiOutlineCloudServer, AiOutlineProject } from "react-icons/ai";
import { MdDashboard, MdOutlineCloudQueue, MdOutlineSpaceDashboard, MdOutlineSupportAgent, MdVpnKey } from "react-icons/md";
import { RiMap2Fill, RiSettings5Line } from "react-icons/ri";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GiMineTruck } from "react-icons/gi";
import { FaMapMarkerAlt, FaRegUser, FaUser } from "react-icons/fa";
import { FiServer } from "react-icons/fi";
import { IoIosLink, IoIosStats, IoMdCloudOutline, IoMdSettings } from "react-icons/io";
import { ColumnSortFilterConfig } from "../core/data/ListData";
import { ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { SortState } from "../../common/table/DataTable";
import { useAppServices } from "../app/services";
import { VscGraph, VscLink } from "react-icons/vsc";
import { BiServer, BiShieldQuarter, BiSubdirectoryRight, BiTransfer } from "react-icons/bi";
import { MtdiOsType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/mtdi_os_type_pb";
import { observer } from "mobx-react-lite";
import { SiLinux, SiWindows } from "react-icons/si";
import { UnknownIcons } from "../../common/CommonIcons";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { CgServer } from "react-icons/cg";
import { HiOutlineServerStack } from "react-icons/hi2";
import { IconBaseProps } from "react-icons";
import { IoCodeSlash, IoGitNetworkOutline } from "react-icons/io5";
import { useQueryClient } from "@tanstack/react-query";
import { ProjectQueryKeys } from "../../common/QueryKeys";
import { useListMyProjects } from "./project_hooks";

// ======================
// ProjectIcon
// ======================
interface ProjectIconProps {}

export const ProjectIcon: React.FC<ProjectIconProps & Partial<IconBaseProps>> = (p) => {
    return <AiOutlineProject {...p} />;
};

export const DashboardIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <MdOutlineSpaceDashboard {...p} />;
};
export const CmcHostsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <CgServer {...p} />;
};

export const H2HConnectionsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoGitNetworkOutline {...p} />;
};

export const CmcMigrationIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoMdCloudOutline {...p} />;
};

export const CmoSummaryIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoIosStats {...p} />;
};

export const CmoSystemsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <BiServer {...p} />;
};

export const CmoMigrationSessionsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <BiTransfer {...p} />;
};

export const ApiReferenceIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoCodeSlash {...p} />;
};

export const DeployedDeploymentsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FiServer />
        </SvgIcon>
    );
};
export const ProjectSettingsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <IoMdSettings {...p} />;
};
export const MigrationIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <GiMineTruck {...p} />;
};

export const IntegrationsIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <VscLink {...p} />;
};

export const UserCountIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <FaRegUser {...p} />;
};
export const UserSettingsIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <FaUser />
        </SvgIcon>
    );
};

export const SubDirectoryIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p} sx={{ color: `rgba(255,255,255,.3)` }}>
            <BiSubdirectoryRight />
        </SvgIcon>
    );
};

export const CmcIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdOutlineCloudQueue />
        </SvgIcon>
    );
};
export const CmoIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <BiServer />
        </SvgIcon>
    );
};

export const DataProtectionIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <BiShieldQuarter />
        </SvgIcon>
    );
};

export const GlobalMapViewIcon: React.FC<Partial<IconBaseProps>> = (p) => {
    return <FaMapMarkerAlt {...p} />;
};

export const LicenseKeyIcon: React.FC<Partial<SvgIconProps>> = (p) => {
    return (
        <SvgIcon {...p}>
            <MdVpnKey />
        </SvgIcon>
    );
};

export const useNavigateToProjectListScreen = () => {
    const navigate = useNavigate();
    const p = generatePath(APP_ROUTES.PROJECTS);
    return () => navigate(p);
};

export const useNavigateToProjectDetails = (projectId: string) => {
    const navigate = useNavigate();
    const p = generatePath(`${APP_ROUTES.PROJECTS}/${projectId}`);
    return () => navigate(p);
};

export const useNavigateToProjectSubRoute = (sub: string) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(sub), { projectId });
    return () => navigate(p);
};

const getListSortFieldLabel = (field: ListProjects.Request.Sort.Field) => {
    if (field === ListProjects.Request.Sort.Field.NAME) {
        return "Project Name";
    } else if (field === ListProjects.Request.Sort.Field.CREATION_TIME) {
        return "Creation Time";
    }
};

const getListFilterFieldLabel = (field: ListProjects.Request.Filter.FieldCase) => {
    if (field === ListProjects.Request.Filter.FieldCase.NAME) {
        return "Project Name";
    } else if (field === ListProjects.Request.Filter.FieldCase.CREATION_TIME) {
        return "Creation Time";
    } else if (field === ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER) {
        return "Project Member";
    } else if (field === ListProjects.Request.Filter.FieldCase.DESCRIPTION) {
        return "Description";
    } else if (field === ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME) {
        return "Host Name";
    }
};

export const getProjectListFilterConfig: () => ColumnSortFilterConfig<ListProjects.Request, ListProjects.Request.Sort, any, any> = () => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListProjects.Request.Sort.Field.NAME,
                label: getListSortFieldLabel(ListProjects.Request.Sort.Field.NAME),
            },
            sortFields: [
                {
                    fieldId: ListProjects.Request.Sort.Field.NAME,
                    label: getListSortFieldLabel(ListProjects.Request.Sort.Field.NAME),
                },
                {
                    fieldId: ListProjects.Request.Sort.Field.CREATION_TIME,
                    label: getListSortFieldLabel(ListProjects.Request.Sort.Field.CREATION_TIME),
                },
            ],
            getSortParam: (s: SortState) => new ListProjects.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value),
        },
        filter: [
            {
                fieldId: ListProjects.Request.Filter.FieldCase.NAME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.NAME),
                filterType: "string",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter().setName(f));
                },
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.DESCRIPTION,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.DESCRIPTION),
                filterType: "string",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter().setDescription(f));
                },
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.DEPLOYMENT_NAME),
                filterType: "string",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter().setDeploymentName(f));
                },
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.PROJECT_MEMBER),
                filterType: "simpleString",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter().setProjectMember(f));
                },
            },
            {
                fieldId: ListProjects.Request.Filter.FieldCase.CREATION_TIME,
                label: getListFilterFieldLabel(ListProjects.Request.Filter.FieldCase.CREATION_TIME),
                filterType: "date",
                addFilterToRequest: (r: ListProjects.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListProjects.Request.Filter().setCreationTime(f));
                },
            },
        ],
    };
};

export const getOsDisplayName = (os: MtdiOsType.MtdiOsType | MTDIDeploymentInfo.OSType) => {
    if (os === MtdiOsType.MtdiOsType.LINUX || os === MTDIDeploymentInfo.OSType.LINUX) {
        return "Linux";
    } else if (os === MtdiOsType.MtdiOsType.WINDOWS || os === MTDIDeploymentInfo.OSType.WINDOWS) {
        return "Windows";
    } else {
        return "Unknown";
    }
};

export const getMtdiDeploymentOsDisplayName = (os: MTDIDeploymentInfo.OSType | MtdiOsType.MtdiOsType) => {
    if (os === MtdiOsType.MtdiOsType.LINUX || os === MTDIDeploymentInfo.OSType.LINUX) {
        return "Linux";
    } else if (os === MtdiOsType.MtdiOsType.WINDOWS || os === MTDIDeploymentInfo.OSType.WINDOWS) {
        return "Windows";
    } else {
        return "Unknown";
    }
};

interface HostOSIconProps {
    os: MtdiOsType.MtdiOsType | MTDIDeploymentInfo.OSType;
}

export const HostOSIcon: React.FC<HostOSIconProps & Partial<SvgIconProps>> = observer((p) => {
    const { os, ...props } = p;
    const sizeStyle = {
        size: 20,
    };
    return (
        <Box pt={1}>
            {os === MtdiOsType.MtdiOsType.LINUX || os === MTDIDeploymentInfo.OSType.LINUX ? (
                <SiLinux {...sizeStyle} />
            ) : os === MtdiOsType.MtdiOsType.WINDOWS || os === MTDIDeploymentInfo.OSType.WINDOWS ? (
                <SiWindows {...sizeStyle} />
            ) : (
                <UnknownIcons {...sizeStyle} />
            )}
        </Box>
    );
});
