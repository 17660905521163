import { observer } from "mobx-react-lite";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import React, { ReactNode } from "react";
import {
    Badge,
    Box,
    Button,
    Card,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    SelectChangeEvent,
    SvgIcon,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { MdTimer } from "react-icons/md";
import { getModuleConfigByModule, ModuleIcon } from "../../integrations/IntegrationsCommon";
import { GmMigrationSessionPostSyncActionsState } from "../GmMigrationService";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { WorkflowStage } from "gc-web-proto/galaxycompletepb/apipb/domainpb/workflow_pb";
import { DynamicVerticalMobXStepper } from "../../../common/stepper/MobXStepperComponents";
import { IntegrationConfigInfo, IntegrationDefinition } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import { CgNotes } from "react-icons/cg";
import { GoTerminal } from "react-icons/go";
import { ActionConfiguredPropertiesTable, usePostSyncActionStyles } from "./PostSyncActionsCommon";
import { CollapsibleCard } from "../../../common/collapsible/CollapsibleCard";
import { StepConfig } from "../../../common/stepper/StepperHelpers";
import { useMountEffect } from "../../../common/hooks/hookslib";
import { useListIntegrationModules, useListProjectIntegrations } from "../../integrations/integration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";

// ======================
// PostSyncActionSection
// ======================

interface PostSyncActionSectionProps {
    actionsState: GmMigrationSessionPostSyncActionsState;
}

export const PostSyncActionSection: React.FC<PostSyncActionSectionProps> = observer((p) => {
    const addActionDialogState = useDialogState();

    return (
        <Box>
            <AddedActionsSection actionsState={p.actionsState} />
            <AddPostSyncActionButton dialogState={addActionDialogState} />
            {addActionDialogState.isOpen && <AddPostSyncActionDialog dialogState={addActionDialogState} actionsState={p.actionsState} type={"create"} />}
        </Box>
    );
});

// ======================
// AddedActionsSection
// ======================

interface AddedActionsSectionProps {
    actionsState: GmMigrationSessionPostSyncActionsState;
}

export const AddedActionsSection: React.FC<AddedActionsSectionProps> = observer((p) => {
    const actions = p.actionsState.actions;

    const editActionDialogState = useDialogState();
    const onClickEdit = (i: number) => {
        editActionDialogState.setDialogProps({ index: i });
        editActionDialogState.open();
    };

    return (
        <Box pb={actions.length ? 2 : 0}>
            {actions.map((a, i) => {
                return (
                    <>
                        <AddedActionCard action={a} actionsState={p.actionsState} onEdit={onClickEdit} index={i} />
                        {i !== actions.length - 1 && (
                            <svg width={"100%"} height={30}>
                                <line x1={"50%"} y1={5} x2={"50%"} y2={40} strokeWidth={3} stroke={"white"} />
                            </svg>
                        )}
                    </>
                );
            })}
            {editActionDialogState.isOpen && <AddPostSyncActionDialog dialogState={editActionDialogState} actionsState={p.actionsState} type={"edit"} />}
        </Box>
    );
});

// ======================
// AddedActionCard
// ======================

interface AddedActionCardProps {
    action: PostSyncAction;
    index: number;
    actionsState: GmMigrationSessionPostSyncActionsState;
    onEdit: (i: number) => void;
}

export const AddedActionCard: React.FC<AddedActionCardProps> = observer((p) => {
    const { index, action, actionsState, onEdit } = p;
    const actions = actionsState.actions;

    return (
        <Box>
            <CollapsibleCard
                arrowPlacement={"right-middle"}
                collapsibleElement={
                    <Box p={2}>
                        <Card sx={{ background: (t: Theme) => t.palette.cirrus.main }}>
                            <ListSubheader>Configured Properties</ListSubheader>
                            <ActionConfiguredPropertiesTable workflowStage={action.workflowStage} properties={action.properties} />
                        </Card>
                    </Box>
                }
            >
                <Box display={"flex"} alignItems={"center"} key={index} pl={2} flexGrow={1}>
                    <Box>
                        <Box>
                            <Tooltip title={"Move Up"}>
                                <IconButton disabled={index === 0} onClick={() => actionsState.moveActionUp(action, index)}>
                                    <SvgIcon>
                                        <IoIosArrowUp />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box pr={2}>
                            <Tooltip title={"Move Down"}>
                                <IconButton disabled={index === actions.length - 1} onClick={() => actionsState.moveActionDown(action, index)}>
                                    <SvgIcon>
                                        <IoIosArrowDown />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box pr={4} pl={2}>
                        <Badge
                            badgeContent={index + 1}
                            color={"primary"}
                            sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: (t: Theme) => t.palette.cirrus.main,
                                    height: (t: Theme) => t.spacing(3),
                                    width: (t: Theme) => t.spacing(3),
                                    borderRadius: (t: Theme) => t.spacing(3),
                                },
                            }}
                        />
                    </Box>
                    <Box flexGrow={1} pr={2}>
                        <ListItem key={index}>
                            <ListItemIcon>
                                <SvgIcon>{action.config.icon}</SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={action.config.label} secondary={action.config.description} />
                            <Box pr={1}>
                                <Button variant={"outlined"} color={"neutral"} onClick={() => onEdit(index)}>
                                    Edit
                                </Button>
                            </Box>
                            <Box>
                                <Button variant={"outlined"} color={"error"} onClick={() => actionsState.removeAction(index)}>
                                    Remove
                                </Button>
                            </Box>
                        </ListItem>
                    </Box>
                </Box>
            </CollapsibleCard>
        </Box>
    );
});

// ======================
// AddPostSyncActionButton
// ======================

interface AddPostSyncActionButtonProps {
    dialogState: DialogState;
}

const AddPostSyncActionButton: React.FC<AddPostSyncActionButtonProps> = observer((p) => {
    return (
        <>
            <Button variant={"outlined"} color={"primary"} onClick={p.dialogState.open}>
                Add Action
            </Button>
        </>
    );
});

// ======================
// AddPostSyncActionDialog
// ======================

interface AddPostSyncActionDialogProps {
    dialogState: DialogState;
    actionsState: GmMigrationSessionPostSyncActionsState;
    type: "edit" | "create";
}

const AddPostSyncActionDialog: React.FC<AddPostSyncActionDialogProps> = observer((p) => {
    useMountEffect(() => {
        if (p.type === "edit") {
            p.actionsState.selectAction(p.actionsState.actions[p.dialogState.dialogProps.index].config, p.dialogState.dialogProps.index);
            p.actionsState.stepperState.setStartingStepIndex(1);
        }
    });

    const fullScreen = useShouldDialogFullScreen();

    const styles = usePostSyncActionStyles();

    const onClose = () => {
        p.dialogState.close();
        p.actionsState.resetActionCreation();
    };

    const onActionSelected = (a: PostSyncActionConfig) => {
        p.actionsState.selectAction(a);
    };

    const onConfirm = () => {
        p.actionsState.confirmAction(p.dialogState.dialogProps?.index);
        p.dialogState.close();
    };

    return (
        <Dialog
            open={p.dialogState.isOpen}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth={"md"}
            fullWidth
            scroll={"paper"}
            PaperProps={{ sx: styles.dialog }}
        >
            <DialogTopBar dialogState={p.dialogState} title={p.type === "edit" ? "Edit Action" : "Add Action"} onDialogClosed={onClose} divider />
            <DialogContent sx={styles.dialogContent}>
                <Box>
                    {!p.actionsState.currentPostSyncActionConfig && p.type === "create" && (
                        <SelectActionStep onSelected={onActionSelected} actionsState={p.actionsState} />
                    )}
                    {p.actionsState.currentPostSyncActionConfig && (
                        <DynamicVerticalMobXStepper
                            wrapperBoxProps={{ p: 2 }}
                            stepConfigs={getStepsByAction(p.actionsState.currentPostSyncActionConfig.action, p.actionsState, p.dialogState.dialogProps?.index)}
                            stepperState={p.actionsState.stepperState}
                        />
                    )}
                </Box>
            </DialogContent>
            <>
                <Divider />

                <Box display={"flex"} justifyContent={"space-between"} p={2}>
                    <Button
                        variant={"outlined"}
                        disabled={!p.actionsState.stepperState.hasPreviousStep}
                        onClick={() => {
                            if (p.actionsState.stepperState.activeStep === 1) {
                                p.actionsState.resetActionCreation();
                            }
                            if (p.actionsState.stepperState.hasPreviousStep) {
                                p.actionsState.stepperState.goBackOneStep();
                            }
                        }}
                    >
                        Back
                    </Button>

                    <Button variant={"contained"} color={"primary"} disabled={p.actionsState.isConfirmDisabled} type={"submit"} onClick={onConfirm}>
                        Confirm
                    </Button>
                </Box>
            </>
        </Dialog>
    );
});

// ======================
// SelectActionStep
// ======================

interface SelectActionStepProps {
    onSelected: Function;
    actionsState: GmMigrationSessionPostSyncActionsState;
}

export const SelectActionStep: React.FC<SelectActionStepProps> = observer((p) => {
    const styles = usePostSyncActionStyles();

    return (
        <Box p={2}>
            <Typography variant={"h3"}>Select Action</Typography>
            <Typography variant={"body1"}>Select an action to be added into this migration session</Typography>
            <br />
            <Card sx={styles.action}>
                {PostSyncActions.filter((v) => !v.hidden).map((a, i) => {
                    return (
                        <Box key={i}>
                            <ListItemButton onClick={() => p.onSelected(a)} key={i} disabled={!!a.isAllowed ? !a.isAllowed(p.actionsState) : false}>
                                <ListItemIcon>
                                    <SvgIcon>{a.icon}</SvgIcon>
                                </ListItemIcon>
                                <ListItemText primary={a.label} secondary={a.description} />
                            </ListItemButton>
                            {i !== PostSyncActions.length - 1 && <Divider sx={styles.divider} />}
                        </Box>
                    );
                })}
            </Card>
        </Box>
    );
});

// ======================
// SelectIntegrationStep
// ======================

interface SelectIntegrationStepProps {
    getIntegrations: (integrations: IntegrationConfigInfo[]) => IntegrationConfigInfo[];
    actionsState: GmMigrationSessionPostSyncActionsState;
    onSelected: Function;
}

export const SelectIntegrationStep: React.FC<SelectIntegrationStepProps> = observer((p) => {
    const styles = usePostSyncActionStyles();

    const integrationsList = useListProjectIntegrations();
    const integrationDefs = useListIntegrationModules();
    const integrations =
        integrationsList.data?.itemsList.filter((i) => {
            const moduleConfig = getModuleConfigByModule(i.module, integrationDefs.data?.itemsList);
            return moduleConfig?.moduleDefinition?.capabilitiesList
                ?.map((c) => c.capability)
                .includes(IntegrationDefinition.CapabilityDefinition.Capability.SNAPSHOT);
        }) || [];

    return (
        <>
            <Typography variant={"h3"}>Select Integration</Typography>
            <Typography variant={"body1"}>Select an integration (only enabled integrations will be shown on the list below)</Typography>
            <br />
            <QueryResultWrapper queryResult={integrationsList}>
                <QueryResultWrapper queryResult={integrationDefs}>
                    <Card sx={styles.action}>
                        {integrations.map((i, index) => {
                            const moduleConfig = getModuleConfigByModule(i.module, integrationDefs.data?.itemsList);
                            return (
                                <Box key={index}>
                                    <ListItemButton onClick={() => p.onSelected(i.id)} selected={p.actionsState.currentIntegrationId === i.id}>
                                        <ListItemIcon>
                                            <ModuleIcon as={moduleConfig.icon} size={"small"} />
                                        </ListItemIcon>
                                        <ListItemText primary={i.friendlyName} secondary={moduleConfig.moduleDefinition?.description} />
                                    </ListItemButton>
                                    {index !== integrations.length - 1 && <Divider sx={styles.divider} />}
                                </Box>
                            );
                        })}
                    </Card>
                </QueryResultWrapper>
            </QueryResultWrapper>
        </>
    );
});

// ======================
// SnapshotPropertiesStep
// ======================

interface SnapshotPropertiesStepProps {
    state: GmMigrationSessionPostSyncActionsState;
    index?: number;
}

export const SnapshotPropertiesStep: React.FC<SnapshotPropertiesStepProps> = observer((p) => {
    useMountEffect(() => {
        if (p.index >= 0) {
            p.state.setSnapshotNamePrefix(
                p.state.actions[p.index].properties?.namePrefix ||
                    `${p.state.actions[p.index].workflowStage
                        .getProperties()
                        .getFieldsMap()
                        .get("volumes")
                        .getStructValue()
                        .getFieldsMap()
                        .get(p.state.migrationVolumeNames[0])
                        .getStringValue()}`
            );
        }
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        p.state.setSnapshotNamePrefix(e.target.value);
    };

    return (
        <>
            <Typography variant={"h3"}>Set Properties</Typography>
            <Typography variant={"body1"}>Set snapshot properties below.</Typography>
            <br />
            <TextField
                variant={"filled"}
                value={p.state.currentProperties.namePrefix}
                label={"Snapshot Name Prefix"}
                InputLabelProps={{ htmlFor: "namePrefix" }}
                helperText={"Set a custom name prefix for the snapshot volumes."}
                required
                fullWidth
                inputProps={{ id: "namePrefix" }}
                onChange={handleChange}
            />
        </>
    );
});

// ======================
// ExecuteCommandPropertiesStep
// ======================

interface ExecuteCommandPropertiesStepProps {
    state: GmMigrationSessionPostSyncActionsState;
    index?: number;
}

export const ExecuteCommandPropertiesStep: React.FC<ExecuteCommandPropertiesStepProps> = observer((p) => {
    useMountEffect(() => {
        if (p.index >= 0) {
            p.state.setExecuteSystemId(p.state.actions[p.index].workflowStage.getSystemId());
            p.state.setExecuteCommand(
                p.state.actions[p.index].properties?.command ||
                    p.state.actions[p.index].workflowStage.getProperties().getFieldsMap().get("command").getStringValue()
            );
            p.state.setActionTimeout(p.state.actions[p.index].workflowStage.getTimeout().getSeconds());
        }
    });

    const handleChangeCommand = (e: React.ChangeEvent<HTMLInputElement>) => {
        p.state.setExecuteCommand(e.target.value);
    };
    const handleChangeTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
        const timeout = parseInt(e.target.value);
        p.state.setActionTimeout(timeout);
    };

    return (
        <>
            <Typography variant={"h3"}>Set Properties</Typography>
            <Typography variant={"body1"}>Set command properties below.</Typography>
            {!!p.state.destinationSystemId && (
                <>
                    <br />
                    <FormControl fullWidth>
                        <InputLabel id={"commandDeploymentIdLabel"} variant={"filled"}>
                            Deployment
                        </InputLabel>
                        <Select
                            variant={"filled"}
                            labelId={"commandDeploymentIdLabel"}
                            id={"commandDeploymentId"}
                            value={p.state.currentSystemId}
                            onChange={(event: SelectChangeEvent<string>, child: ReactNode) => p.state.setExecuteSystemId(event.target.value as string)}
                        >
                            <MenuItem value={p.state.sourceSystemId}>Source ({p.state.sourceSystemName})</MenuItem>
                            <MenuItem value={p.state.destinationSystemId}>Destination ({p.state.destinationSystemName})</MenuItem>
                        </Select>
                        <FormHelperText>Deployment on which to run the command</FormHelperText>
                    </FormControl>
                    <br />
                </>
            )}
            <br />
            <TextField
                variant={"filled"}
                value={p.state.currentProperties.command}
                label={"Command"}
                InputLabelProps={{ htmlFor: "command" }}
                helperText={"Shell command to run on deployment"}
                required
                fullWidth
                inputProps={{ id: "command" }}
                onChange={handleChangeCommand}
            />
            <br />
            <br />

            <TextField
                variant={"filled"}
                value={p.state.timeout}
                label={"Timeout"}
                type={"number"}
                InputLabelProps={{ htmlFor: "timeout" }}
                helperText={"Set timeout for command"}
                required
                fullWidth
                InputProps={{ endAdornment: <Box pl={1}>Seconds</Box> }}
                inputProps={{ id: "timeout" }}
                onChange={handleChangeTimeout}
            />
        </>
    );
});

export interface PostSyncAction {
    config: PostSyncActionConfig;
    workflowStage: WorkflowStage;
    properties?: any;
}

export interface PostSyncActionConfig {
    id: string;
    action: WorkflowStage.Action;
    label: string;
    description: string;
    icon: React.ReactNode;
    getIntegrations?: (integrations: IntegrationConfigInfo[]) => IntegrationConfigInfo[];
    properties?: (state: GmMigrationSessionPostSyncActionsState, index?: number) => React.ReactNode;
    otherSteps?: Array<StepConfig>;
    isAllowed?: (state: GmMigrationSessionPostSyncActionsState) => boolean;
    hidden?: boolean;
}

const PostSyncActions: PostSyncActionConfig[] = [
    {
        id: "snapshot",
        action: WorkflowStage.Action.SNAPSHOT,
        label: "Take Snapshot",
        description: "Create snapshot per synchronization",
        icon: <MdTimer />,
        getIntegrations: (integrations: IntegrationConfigInfo[]) => integrations,
        properties: (state: GmMigrationSessionPostSyncActionsState, index?: number) => <SnapshotPropertiesStep state={state} index={index} />,
        isAllowed: (state: GmMigrationSessionPostSyncActionsState) =>
            state.actions.filter((v) => v.config.action === WorkflowStage.Action.SNAPSHOT).length === 0,
    },
    {
        id: "unknown",
        action: WorkflowStage.Action.UNKNOWN,
        label: "Unknown",
        description: "Unknown",
        icon: <AiOutlineQuestionCircle />,
        getIntegrations: (integrations: IntegrationConfigInfo[]) => integrations,
        hidden: true,
    },
    {
        id: "log",
        action: WorkflowStage.Action.LOG,
        label: "Log",
        description: "Debug workflow",
        icon: <CgNotes />,
        getIntegrations: (integrations: IntegrationConfigInfo[]) => integrations,
        hidden: true,
    },
    {
        id: "execute",
        action: WorkflowStage.Action.EXECUTE,
        label: "Execute Command",
        description: "Run shell command on deployment",
        icon: <GoTerminal />,
        properties: (state: GmMigrationSessionPostSyncActionsState, index?: number) => <ExecuteCommandPropertiesStep state={state} index={index} />,
        //hidden: true
    },
];

export const getPostSyncActionDefFromAction = (a: WorkflowStage.Action) => {
    return PostSyncActions.find((c) => c.action === a);
};

export const getStepsByAction = (a: WorkflowStage.Action, state: GmMigrationSessionPostSyncActionsState, index?: number) => {
    const actionDef = getPostSyncActionDefFromAction(a);

    const selectActionStep: StepConfig = {
        id: "action",
        label: "Select Action:",
        getDescription: () => actionDef.label,
        renderer: () => null,
    };

    const integrationStepConfig: StepConfig = {
        id: "integration",
        label: "Select Integration",
        renderer: (stepConfigs, stepperState) => {
            const onIntegrationSelected = (id: number) => {
                state.selectIntegration(id);
                if (!stepperState.isLastStep) {
                    stepperState.goToNextStep();
                }
            };
            return <SelectIntegrationStep onSelected={onIntegrationSelected} actionsState={state} getIntegrations={actionDef.getIntegrations} />;
        },
    };

    const propertiesStepConfig: StepConfig = {
        id: "properties",
        label: "Set Properties",
        renderer: (stepConfigs, stepperState) => {
            return <>{actionDef.properties(state, index)}</>;
        },
    };

    let stepConfigs = [selectActionStep];

    if (!!actionDef.getIntegrations) {
        stepConfigs.push(integrationStepConfig);
    }

    if (!!actionDef.properties) {
        stepConfigs.push(propertiesStepConfig);
    }

    if (!!actionDef.otherSteps) {
        stepConfigs = stepConfigs.concat(actionDef.otherSteps);
    }

    return stepConfigs;
};

export const getActionDisplayName = (a: WorkflowStage.Action) => {
    if (a === WorkflowStage.Action.SNAPSHOT) {
        return "Snapshot";
    } else if (a === WorkflowStage.Action.LOG) {
        return "Log";
    } else if (a === WorkflowStage.Action.EXECUTE) {
        return "Command";
    }
};
