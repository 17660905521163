// ======================
// ConnectedStatusChip
// ======================

import React from "react";
import { Chip, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
interface ConnectedStatusChipProps {
    connected: boolean;
}

export const ConnectedStatusChip: React.FC<ConnectedStatusChipProps> = (p) => {
    const t = useTheme();

    const getStyles = () => {
        if (p.connected) {
            return {
                backgroundColor: t.palette.success.dark,
                color: "white",
            };
        } else {
            return {
                backgroundColor: t.palette.error.main,
                color: "white",
            };
        }
    };
    return <Chip label={p.connected ? "Online" : "Offline"} sx={getStyles()} />;
};

export const renderChipInfo = (s: string) => {
    if (s) {
        return (
            <Grid>
                <Chip size={"small"} label={s} />
            </Grid>
        );
    }
};

export const renderBootVolumeChip = (boot: boolean) => {
    if (boot) {
        return (
            <Grid>
                <Chip size={"small"} label={"boot"} color={"secondary"} />
            </Grid>
        );
    }
};
