/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: ProgressService
 */
import { computed, makeAutoObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";
import { DialogService } from "../dialog/DialogService";
import create from "zustand";

export class ProgressService {
    private dialogService: DialogService;
    trackers: ProgressTrackerState[] = [];

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;

        makeAutoObservable(this);
    }

    private removeTracker(id: string) {
        const t = this.trackers.findIndex((t) => t.id === id);
        if (t >= 0) {
            this.trackers.splice(t, 1);
        }
    }

    async track<T>(promise: Promise<T>, message: string = null, alertError = true): Promise<T> {
        const id = uuid();

        this.trackers.push({
            id,
            message,
        });

        try {
            return await promise;
        } catch (e) {
            if (alertError) {
                this.removeTracker(id);
                await Promise.resolve(this.dialogService.addErrorAlertDialog(e as Error));
            }
            throw e;
        } finally {
            this.removeTracker(id);
        }
    }

    get hasOutstandingTrackers() {
        return this.trackers.length > 0;
    }
}

export interface ProgressTrackerState {
    id: string;
    message?: string;
}
