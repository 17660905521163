// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: LayoutCommon

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import { alpha, IconButton, lighten, SvgIcon, useTheme } from "@mui/material";
import SmallLogo from "../../assets/Cirrus_Data_Cloud_Logo_Small.png";
import LargeLogo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";

import { MdBrightness4, MdBrightnessHigh } from "react-icons/md";
import { CSSProperties, ImgHTMLAttributes } from "react";
import PrivateEditionLogo from "../../assets/Cirrus_Data_Cloud_Logo_Private_Edition.png";

export const useSideMenuListItemStyle = (subroute?: boolean) => {
    const theme = useTheme();

    return {
        marginLeft: 2,
        marginRight: 2,
        borderRadius: 2,
        padding: 0.5,
        paddingLeft: 3.5,
        fontSize: 14,
        color: theme.palette.text.secondary,
        "&.Mui-selected": {
            fontWeight: 600,
            color: theme.palette.primary.light,
            backgroundColor: subroute ? "transparent" : alpha(theme.palette.primary.main, 0.2),
            border: subroute ? "none" : `2px solid ${theme.palette.primary.main}`,
            //color: subroute ? theme.palette.primary.main : theme.palette.text.primary,
            "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.4),
                color: "white",
            },
        },
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
            color: "white",
            fontWeight: 600,
        },
    };
};

// ======================
// LightModeIcon
// ======================

interface LightModeIconProps {}

export const LightModeIcon: React.FC<LightModeIconProps> = observer((props) => {
    const { appGlobalService } = useAppServices();

    const getIcon = () => {
        if (appGlobalService.isLightMode) {
            return <MdBrightness4 />;
        } else {
            return <MdBrightnessHigh />;
        }
    };

    return (
        <IconButton onClick={() => appGlobalService.toggleLightMode()}>
            <SvgIcon>{getIcon()}</SvgIcon>
        </IconButton>
    );
});

// ======================
// CDSLogo
// ======================

interface CDSLogoProps {
    imageClass?: string;
    imageAttributes?: ImgHTMLAttributes<HTMLImageElement>;
    size?: "small" | "large";
    style?: CSSProperties;
}

export const CDSLogo: React.FC<CDSLogoProps> = observer((props) => {
    return (
        <img
            style={props.style}
            src={props.size === "large" ? LargeLogo : SmallLogo}
            alt={"Cirrus Data Cloud"}
            {...props.imageAttributes}
            className={props.imageClass}
        />
    );
});

export const CDSPrivateEditionLogo: React.FC<CDSLogoProps> = observer((props) => {
    return (
        <img src={PrivateEditionLogo} alt={"Cirrus Data Cloud Private Edition"} {...props.imageAttributes} className={props.imageClass} style={props.style} />
    );
});
