// ======================
// CmoMigrationsScreens
// ======================

import { Navigate, Route, Routes } from "react-router-dom";
import { CMO_MIGRATION_SUBROUTE } from "../app/AppRoutes";
import * as React from "react";
import { CmoMigrationDashboardScreen } from "./CmoMigrationDashboardScreen";
import { CmoMigrationSystemsListScreen } from "./CmoMigrationSystemsListScreen";
import { CmoMigrationSessionsListScreen } from "./CmoMigrationSessionsListScreen";
import { CmoMigrationSystemDetailsScreen } from "./systemDetails/CmoMigrationSystemDetailsScreen";
import { CmoMigrationSessionDetailsScreen } from "./sessions/CmoMigrationSessionDetailsScreen";
import { CmoMigrationNexusScreen } from "./CmoMigrationNexusScreen";
import { CmoMigrationSystemWarningsScreen } from "./CmoMigrationSystemWarningsScreen";

interface ProjectCmoMigrationsScreenProps {
    projectId: string;
}
export const CmoMigrationsScreens: React.FC<ProjectCmoMigrationsScreenProps> = (p) => {
    return (
        <Routes>
            <Route index element={<Navigate to={CMO_MIGRATION_SUBROUTE.DASHBOARD} replace />} />
            <Route path={CMO_MIGRATION_SUBROUTE.DASHBOARD} element={<CmoMigrationDashboardScreen projectId={p.projectId} />} />
            <Route path={CMO_MIGRATION_SUBROUTE.CMO_SYSTEMS}>
                <Route index element={<CmoMigrationSystemsListScreen projectId={p.projectId} />} />
                <Route path={":systemId"}>
                    <Route index element={<CmoMigrationSystemDetailsScreen />} />
                </Route>
            </Route>
            <Route path={CMO_MIGRATION_SUBROUTE.NEXUS}>
                <Route index element={<CmoMigrationNexusScreen projectId={p.projectId} />} />
            </Route>
            <Route path={CMO_MIGRATION_SUBROUTE.MIGRATION_SESSIONS}>
                <Route index element={<CmoMigrationSessionsListScreen projectId={p.projectId} />} />
                <Route path={":sessionId"}>
                    <Route index element={<CmoMigrationSessionDetailsScreen />} />
                </Route>
            </Route>
            <Route path={CMO_MIGRATION_SUBROUTE.WARNINGS}>
                <Route index element={<CmoMigrationSystemWarningsScreen projectId={p.projectId} />} />
            </Route>
        </Routes>
    );
};
