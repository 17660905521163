import { makeAutoObservable } from "mobx";
import { SnackbarProps } from "@mui/material";
import * as React from "react";
import { v4 as uuid } from "uuid";

export class NotificationService {
    constructor() {
        makeAutoObservable(this);
    }

    notifications: NotificationConfig[] = [];

    async addNotification(
        message: string = null,
        type: NotificationType = "info",
        action?: ((ret?: any) => Promise<any>) | ((ret?: any) => void),
        actionLabel?: string
    ) {
        const id = uuid();

        let config = null;
        const confirmed = await new Promise((resolve) => {
            config = {
                id,
                message,
                type,
                action,
                actionLabel,
                onClose: (confirmed: boolean) => resolve(confirmed),
            };
            this.notifications.push(config);
        });
        this.notifications.splice(
            this.notifications.findIndex((a) => a.id === id),
            1
        );
        return confirmed;
    }
}

export interface NotificationConfig {
    id: string;
    message: string;
    type: NotificationType;
    action?: ((ret?: any) => Promise<any>) | ((ret?: any) => void);
    actionLabel?: string;
    onClose?: (ret: any) => void;
}

export type NotificationType = "error" | "info" | "success" | "warning";
