// ======================
// KVContrastTable
// ======================

import { ReactNode } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

interface KVContrastTableProps {
    // each object in array represents a row, and each key-value pair in object represents a column group
    data: {
        [key: string]: {
            key: React.ReactNode | string;
            value: React.ReactNode | string;
        };
    }[];
    dense?: boolean;
}

export const KVContrastTable: React.FC<KVContrastTableProps> = (p) => {
    const { data, dense } = p;
    const theme = useTheme();
    const colGroup = (
        <>
            <col style={{}} />
            <col style={{}} />
        </>
    );

    const renderColGroups = () => {
        const colGroupArray = [];
        for (let i = 0; i < Object.keys(data[0]).length; i++) {
            colGroupArray.push(colGroup);
        }
        return colGroupArray;
    };

    return (
        <table
            style={{
                width: "100%",
                borderWidth: 1.5,
                borderStyle: "solid",
                borderColor: theme.palette.border.main,
                borderRadius: 10,
                borderSpacing: 0,
            }}
        >
            <colgroup>{renderColGroups()}</colgroup>
            <tbody>
                {data.length === 1 &&
                    Object.keys(data[0]).map((key, index) => {
                        const numOfRows = Object.keys(data[0]).length;
                        return (
                            <tr key={key}>
                                <td
                                    style={{
                                        borderRight: "1.5px solid #3B4252",
                                        borderTopLeftRadius: index === 0 ? 9 : 0,
                                        borderBottomLeftRadius: index === numOfRows - 1 ? 9 : 0,
                                        backgroundColor: theme.palette.cirrus.main,
                                        borderBottom: index === numOfRows - 1 ? "none" : "1.5px solid #3B4252",
                                    }}
                                >
                                    <Box p={dense ? 1 : 2}>{data[0][key].key}</Box>
                                </td>
                                <td
                                    style={{
                                        borderBottom: index === numOfRows - 1 ? "none" : "1.5px solid #3B4252",
                                    }}
                                >
                                    <Box p={dense ? 1 : 2}>{data[0][key].value}</Box>
                                </td>
                            </tr>
                        );
                    })}

                {data.length > 1 &&
                    data.map((item, rowIndex) => {
                        return (
                            <tr key={rowIndex}>
                                {Object.keys(item).map((key, itemIndex) => {
                                    const numOfRows = data.length;
                                    const numOfColumnGroups = Object.keys(item).length;
                                    return (
                                        <React.Fragment key={key}>
                                            <td
                                                style={{
                                                    borderRight: "1.5px solid #3B4252",
                                                    borderTopLeftRadius: rowIndex === 0 ? 9 : 0,
                                                    borderBottomLeftRadius: rowIndex === numOfRows - 1 ? 9 : 0,
                                                    backgroundColor: theme.palette.cirrus.main,
                                                    borderBottom: rowIndex === numOfRows - 1 ? "none" : "1.5px solid #3B4252",
                                                }}
                                            >
                                                <Box p={dense ? 1 : 2}>{item[key].key}</Box>
                                            </td>
                                            <td
                                                style={{
                                                    borderRight: itemIndex === numOfColumnGroups - 1 ? "none" : "1.5px solid #3B4252",
                                                    borderBottom: rowIndex === numOfRows - 1 ? "none" : "1.5px solid #3B4252",
                                                }}
                                            >
                                                <Box p={dense ? 1 : 2}>{item[key].value}</Box>
                                            </td>
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};
