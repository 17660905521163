import { formatDistanceToNowStrict, intervalToDuration, isAfter } from "date-fns";
import { Box, Theme, Typography, useTheme } from "@mui/material";
import xbytes from "xbytes";
import { useAppServices } from "../../app/services";
import * as React from "react";
import { DeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { useGrpcApiStore } from "../../grpc/grpcApiStore";

export const DEFAULT_LICENSE_TIME_LIMIT_IN_SECONDS = 60 * 24 * 60 * 60;

export const DEFAULT_LICENSE_CAPACITY = xbytes.parseSize("2 TiB");

export const getLicenseExpirationDateColor = (expirationDate: Date, t: Theme, text?: boolean) => {
    const isExpired = getIsHostLicenseExpired(expirationDate);

    if (isExpired) {
        return t.palette.error.main;
    } else {
        const durationBetweenDates = intervalToDuration({
            start: Date.now(),
            end: expirationDate,
        });

        if (durationBetweenDates.days >= 7 || durationBetweenDates.months > 0) {
            if (text) {
                return t.palette.text.primary;
            } else {
                return t.palette.primary.main;
            }
        } else {
            return t.palette.warning.main;
        }
    }
};

export const getLicenseCapacityColor = (capacity: number, t: Theme, text?: boolean) => {
    const warningLimit = xbytes.parseSize("1 TiB");

    if (capacity < warningLimit) {
        return t.palette.warning.main;
    } else {
        if (text) {
            return t.palette.text.primary;
        } else {
            return t.palette.primary.main;
        }
    }
};

export const getLicenseDurationLeftString = (expirationDate: Date) => {
    if (getIsHostLicenseExpired(expirationDate)) {
        return "Expired";
    } else {
        return `${formatDistanceToNowStrict(expirationDate)} left`;
    }
};

export const getIsHostLicenseExpired = (expirationDate: Date) => {
    return isAfter(Date.now(), expirationDate);
};
