import { CollapsibleHeader, CollapsibleSubheader, ReportFooter, ReportProps } from "../ReportCommon";
import { Box, CircularProgress, IconButton, Stack, Theme, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import CdsCloudLogo from "../../../assets/Cirrus_Data_Cloud_Logo_Large_Dark.png";
import * as React from "react";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MigrationSourceDataAssessmentReport } from "gc-web-proto/galaxycompletepb/apipb/domainpb/report_pb";
import { TiWarningOutline } from "react-icons/ti";
import { formatDistanceStrict } from "date-fns";

// ======================
// MigrationSourceDataAssessmentReport
// ======================

export const ThinDataReport: React.FC<ReportProps> = (p) => {
    const { reportData, reportInfo } = p;
    const theme = useTheme();

    return (
        <Box>
            <Box p={4} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h4"}>{`Cirrus Data Cloud`}</Typography>
                    <Typography variant={"h4"}>{`Source Data Assessment Report`}</Typography>
                </Box>
                <Box>
                    <Box>
                        <img src={CdsCloudLogo} width={"200"} height={"auto"} alt={"Cirrus Data Cloud"} />
                    </Box>
                </Box>
            </Box>
            <CollapsibleHeader title={"1. Overview"}>
                <Stack p={4} spacing={4}>
                    <CollapsibleSubheader title={"1.1 Analysis Overview"}>
                        <Box p={2}>
                            <Grid container>
                                <Grid size={4}>
                                    <Stack width={"100%"} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                        <Box sx={{ position: "relative", display: "inline-flex" }}>
                                            <CircularProgress
                                                sx={{
                                                    color: (t: Theme) => t.palette.grey[300],
                                                }}
                                                size={200}
                                                variant={"determinate"}
                                                value={100}
                                                color={"secondary"}
                                            />
                                            <CircularProgress
                                                sx={{
                                                    color: (t: Theme) => t.palette.grey[600],
                                                    top: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                    right: 0,
                                                    position: "absolute",
                                                }}
                                                size={200}
                                                variant={"determinate"}
                                                value={reportInfo.msdarMeta.thinDataRatio}
                                            />
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            >
                                                <Stack textAlign={"center"} width={120}>
                                                    <Typography variant={"h5"}>{`Thin Data`}</Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Grid>

                                <Grid size={8}>
                                    <Stack height={"100%"} justifyContent={"center"}>
                                        <Typography variant={"h4"}>{formatKnownDataType(reportInfo.msdarMeta.thinDataRatio, KnownDataType.PERCENT)}</Typography>
                                        <Typography variant={"h6"}>{"of your data is thin."}</Typography>
                                        <Box pt={2}>
                                            <Grid container>
                                                <Grid size={6}>
                                                    <Box width={"100%"}>
                                                        <Typography fontWeight={600}>{`Analyzed (Total)`}</Typography>
                                                        <Grid container columnSpacing={4}>
                                                            <Grid size={4}>{"Hosts:"}</Grid>
                                                            <Grid size={8}>{reportInfo.msdarMeta.hostCount}</Grid>
                                                            <Grid size={4}>{"Volumes:"}</Grid>
                                                            <Grid size={8}>{reportInfo.msdarMeta.volumeCount}</Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid size={6}>
                                                    <Box width={"100%"}>
                                                        <Typography fontWeight={600}>{`Results (Total)`}</Typography>
                                                        <Grid container columnSpacing={4}>
                                                            <Grid size={5}>{"Capacity:"}</Grid>
                                                            <Grid size={7}>
                                                                {formatKnownDataType(reportInfo.msdarMeta.totalCapacity, KnownDataType.CAPACITY)}
                                                            </Grid>
                                                            <Grid size={5}>{"Thin Data:"}</Grid>
                                                            <Grid size={7}>
                                                                {formatKnownDataType(reportInfo.msdarMeta.totalThinData, KnownDataType.CAPACITY)}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </CollapsibleSubheader>
                    <CollapsibleSubheader title={"1.2 Project Information"}>
                        <Box p={2}>
                            <Grid container>
                                <Grid size={4}>{"Project Name:"}</Grid>
                                <Grid size={8}>{reportInfo.msdarMeta.projectName || "N/A"}</Grid>
                                <Grid size={4}>{"Project ID:"}</Grid>
                                <Grid size={8}>{reportInfo.msdarMeta.projectId || "N/A"}</Grid>
                                <Grid size={4}>{"Project Description:"}</Grid>
                                <Grid size={8}>{reportInfo.msdarMeta.projectDescription || "N/A"}</Grid>
                                <Grid size={4}>{"Project Created At:"}</Grid>
                                <Grid size={8}>{formatKnownDataType(reportInfo.msdarMeta.projectCreatedAt, KnownDataType.DATE)}</Grid>
                            </Grid>
                        </Box>
                    </CollapsibleSubheader>
                    <CollapsibleHeader title={"2. Thin Data Analysis"}>
                        <Stack p={4} spacing={4}>
                            {reportData.msdar.hostsList.map((host, i) => {
                                const hostStatsFrom = formatDistanceStrict(convertTimestampObjectToDate(host.hostData.averageIoStats.from), new Date());
                                return (
                                    <CollapsibleSubheader key={i} title={`2.${i + 1} ${host.hostData.name}`}>
                                        <Stack spacing={2} p={2}>
                                            <Box>
                                                <Typography fontWeight={600}>{"Host Info"}</Typography>
                                                <Grid container mt={1}>
                                                    <Grid size={4}>{"Host ID:"}</Grid>
                                                    <Grid size={8}>{host.hostData.systemId}</Grid>
                                                    <Grid size={4}>{"Type:"}</Grid>
                                                    <Grid size={8}>{host.hostData.osClass}</Grid>
                                                    <Grid size={4}>{"Total Capacity Provisioned:"}</Grid>
                                                    <Grid size={8}>{formatKnownDataType(host.hostData.totalCapacity, KnownDataType.CAPACITY)}</Grid>
                                                    <Grid size={4}>{"Total Thin Data:"}</Grid>
                                                    <Grid size={8}>
                                                        {formatKnownDataType(host.hostData.totalThinData, KnownDataType.CAPACITY)} {" Reduced"}
                                                    </Grid>
                                                    <Grid size={4}>{"Thin Data Ratio:"}</Grid>
                                                    <Grid size={8}>{formatKnownDataType(host.hostData.thinDataRatio, KnownDataType.PERCENT)}</Grid>
                                                </Grid>
                                            </Box>
                                            <Box>
                                                <Typography fontWeight={600}>{`Average I/O Performance (Last ${hostStatsFrom})`}</Typography>
                                                {host.hostData.getStatsError ? (
                                                    <Stack alignItems={"center"} direction={"row"} spacing={1} pt={1}>
                                                        <TiWarningOutline />
                                                        <Typography>{`Error: ${host.hostData.getStatsError}`}</Typography>
                                                    </Stack>
                                                ) : (
                                                    <Grid container mt={1}>
                                                        <Grid size={4}>{""}</Grid>
                                                        <Grid size={8}>
                                                            <Grid container>
                                                                <Grid size={6}>
                                                                    <Typography variant={"subtitle2"} sx={{ color: (t: Theme) => t.palette.grey[500] }}>
                                                                        {"Read"}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={6}>
                                                                    <Typography variant={"subtitle2"} sx={{ color: (t: Theme) => t.palette.grey[500] }}>
                                                                        {"Write"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid size={4}>{"IOPS:"}</Grid>
                                                        <Grid size={8}>
                                                            <Grid container>
                                                                <Grid size={6}>
                                                                    {formatKnownDataType(
                                                                        host.hostData.averageIoStats?.readStats.iops || 0,
                                                                        KnownDataType.THROUGHPUT
                                                                    )}
                                                                </Grid>
                                                                <Grid size={6}>
                                                                    {formatKnownDataType(
                                                                        host.hostData.averageIoStats?.writeStats.iops || 0,
                                                                        KnownDataType.THROUGHPUT
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid size={4}>{"Throughput:"}</Grid>
                                                        <Grid size={8}>
                                                            <Grid container>
                                                                <Grid size={6}>{`${host.hostData.averageIoStats?.readStats.iops || 0} IOPS`}</Grid>
                                                                <Grid size={6}>{`${host.hostData.averageIoStats?.writeStats.iops || 0} IOPS`}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid size={4}>{"Latency:"}</Grid>
                                                        <Grid size={8}>
                                                            <Grid container>
                                                                <Grid size={6}>{`${host.hostData.averageIoStats?.readStats.latency || 0}μs`}</Grid>
                                                                <Grid size={6}>{`${host.hostData.averageIoStats?.writeStats.latency || 0}μs`}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Box>
                                            <Box>
                                                <Typography fontWeight={600}>{`Volumes (${host.volumeDataList.length})`}</Typography>
                                                <Box pt={2}>
                                                    <VolumeTable vols={host.volumeDataList} />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"} color={theme.palette.grey[700]}>
                                                    {`The above information is collected from session ${host.sessionInfo.sessionId}.`}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </CollapsibleSubheader>
                                );
                            })}
                        </Stack>
                    </CollapsibleHeader>
                </Stack>
            </CollapsibleHeader>
            <ReportFooter reportInfo={reportInfo} />
        </Box>
    );
};

// ======================
// VolumeTable
// ======================

interface VolumeTableProps {
    vols: MigrationSourceDataAssessmentReport.Data.VolumeData.AsObject[];
}

export const VolumeTable: React.FC<VolumeTableProps> = (p) => {
    const { vols } = p;
    const theme = useTheme();
    const [openRows, setOpenRows] = React.useState<number[]>([]);
    const toggleRow = (row: number) => {
        if (openRows.includes(row)) {
            setOpenRows(openRows.filter((r) => r !== row));
        } else {
            setOpenRows([...openRows, row]);
        }
    };
    const toggleAllRows = () => {
        if (openRows.length >= vols.length) {
            setOpenRows([]);
        } else {
            setOpenRows(vols.map((_, i) => i));
        }
    };

    return (
        <table
            style={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "left",
            }}
        >
            <thead>
                <tr>
                    <th
                        style={{
                            padding: "8px",
                        }}
                    >
                        {"Volume"}
                    </th>
                    <th
                        style={{
                            padding: "8px",
                        }}
                    >
                        {"Provisioned"}
                    </th>
                    <th
                        style={{
                            padding: "8px",
                        }}
                    >
                        {"Thin"}
                    </th>
                    <th
                        style={{
                            padding: "8px",
                        }}
                    >
                        {"Thin Ratio"}
                    </th>
                    <th
                        style={{
                            padding: "8px",
                        }}
                    >
                        <IconButton onClick={toggleAllRows} sx={{ color: "black" }} size={"small"}>
                            {openRows.length > 0 ? <FaAngleUp /> : <FaAngleDown />}
                        </IconButton>
                    </th>
                </tr>
            </thead>
            <tbody>
                {vols.map((vol, i) => {
                    const volStatsFrom = formatDistanceStrict(convertTimestampObjectToDate(vol.averageIoStats.from), new Date());
                    return (
                        <>
                            <tr
                                key={i}
                                style={{
                                    borderBottom: !openRows.includes(i) ? `1px solid ${theme.palette.grey[300]}` : "none",
                                }}
                            >
                                <td
                                    style={{
                                        padding: "8px",
                                    }}
                                >
                                    {vol.volumeConfig.blockDevice.deviceName}
                                </td>
                                <td
                                    style={{
                                        padding: "8px",
                                    }}
                                >
                                    {formatKnownDataType(vol.capacity, KnownDataType.CAPACITY)}
                                </td>
                                <td
                                    style={{
                                        padding: "8px",
                                    }}
                                >
                                    {formatKnownDataType(vol.thinData, KnownDataType.CAPACITY)}
                                </td>
                                <td
                                    style={{
                                        padding: "8px",
                                    }}
                                >
                                    {formatKnownDataType(vol.thinDataRatio, KnownDataType.PERCENT)}
                                </td>
                                <td
                                    style={{
                                        padding: "8px",
                                    }}
                                >
                                    <IconButton onClick={() => toggleRow(i)} sx={{ color: "black" }} size={"small"}>
                                        {openRows.includes(i) ? <FaAngleUp /> : <FaAngleDown />}
                                    </IconButton>
                                </td>
                            </tr>
                            {openRows.includes(i) && (
                                <tr
                                    style={{
                                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                                    }}
                                >
                                    <td colSpan={5}>
                                        <Box p={1}>
                                            <Typography fontWeight={600}>{`Average I/O Performance (Last ${volStatsFrom})`}</Typography>
                                            {vol.getStatsError ? (
                                                <Stack alignItems={"center"} direction={"row"} spacing={1} pt={1}>
                                                    <TiWarningOutline />
                                                    <Typography>{`Error: ${vol.getStatsError}`}</Typography>
                                                </Stack>
                                            ) : (
                                                <Grid container mt={1}>
                                                    <Grid size={4}>{""}</Grid>
                                                    <Grid size={8}>
                                                        <Grid container>
                                                            <Grid size={6}>
                                                                <Typography variant={"subtitle2"} sx={{ color: (t: Theme) => t.palette.grey[500] }}>
                                                                    {"Read"}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid size={6}>
                                                                <Typography variant={"subtitle2"} sx={{ color: (t: Theme) => t.palette.grey[500] }}>
                                                                    {"Write"}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={4}>{"IOPS:"}</Grid>
                                                    <Grid size={8}>
                                                        <Grid container>
                                                            <Grid size={6}>{`${vol.averageIoStats?.readStats.iops || 0} IOPS`}</Grid>
                                                            <Grid size={6}>{`${vol.averageIoStats?.writeStats.iops || 0} IOPS`}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={4}>{"Throughput:"}</Grid>
                                                    <Grid size={8}>
                                                        <Grid container>
                                                            <Grid size={6}>
                                                                {formatKnownDataType(vol.averageIoStats?.readStats.throughput || 0, KnownDataType.THROUGHPUT)}
                                                            </Grid>
                                                            <Grid size={6}>
                                                                {formatKnownDataType(vol.averageIoStats?.writeStats.throughput || 0, KnownDataType.THROUGHPUT)}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid size={4}>{"Latency:"}</Grid>
                                                    <Grid size={8}>
                                                        <Grid container>
                                                            <Grid size={6}>{`${vol.averageIoStats?.readStats.latency || 0}μs`}</Grid>
                                                            <Grid size={6}>{`${vol.averageIoStats?.writeStats.latency || 0}μs`}</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Box>
                                    </td>
                                </tr>
                            )}
                        </>
                    );
                })}
            </tbody>
        </table>
    );
};
