/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: MainLayout
 */
import * as React from "react";
import { useEffect } from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { MobileSideMenu, SideMenu } from "./SideMenu";
import { TopBar } from "./TopBar";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { useMatch } from "react-router-dom";
import { APP_ROUTES, MIGRATE_OPS_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { HelpActionButton, HelpDrawer } from "../help/HelpDrawer";
import { useHelpDrawerState } from "../help/hooks/help_hooks";
import { useMigrateOpsDocsDrawerState } from "../migrateops/migrate_ops_hooks";

import { CirrusFooter } from "./Footer";
import { useCurrentProjectID, useCurrentProjectState } from "../project/CurrentProjectState";

const DESKTOP_BREAKPOINT = "md"; // beyond this consider desktop

export const useIsDesktop = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(DESKTOP_BREAKPOINT));
};

interface MainFrameLayoutProps {
    isDesktop: boolean;
    children?: React.ReactNode;
}

export const useRightSideDrawerWidth = () => {
    const theme = useTheme();
    const greaterThanXL = useMediaQuery(theme.breakpoints.up("xl"));
    return greaterThanXL ? 700 : 500;
};
const SIDE_MENU_WIDTH = 256;

export const MainFrameLayout: React.FC<MainFrameLayoutProps> = observer((props) => {
    const theme = useTheme();
    const isPrivateEdition = getIsPrivateEdition();
    const { isDesktop } = props;
    const noMainFrame = useMatch(`${APP_ROUTES.REPORTS}/:reportId/*`);
    const helpDrawerOpen = useHelpDrawerState((s) => s.helpDrawerOpen);
    const migrateOpsDocsDrawerOpen = useMigrateOpsDocsDrawerState((s) => s.migrateOpsDocsDrawerOpen);
    const setMigrateOpsDocsDrawerOpen = useMigrateOpsDocsDrawerState((s) => s.setMigrateOpsDocsDrawerOpen);
    const rightSideDrawerOpen = helpDrawerOpen || migrateOpsDocsDrawerOpen;
    const rightSideDrawerWidth = useRightSideDrawerWidth();
    const currentProjectId = useCurrentProjectID();
    const matchMigrateOpsWizard = useMatch(`${APP_ROUTES.PROJECTS}/${currentProjectId}/${PROJECT_SUBROUTE.MIGRATE_OPS}/${MIGRATE_OPS_SUBROUTE.CREATE}`);
    const clearCurrentProject = useCurrentProjectState((s) => s.clearCurrentProject);
    const onProjectPage = useMatch(`${APP_ROUTES.PROJECTS}/:projectId/*`);

    useEffect(() => {
        if (!matchMigrateOpsWizard) {
            setMigrateOpsDocsDrawerOpen(false);
        }

        if (!onProjectPage) {
            clearCurrentProject();
        }
    }, [matchMigrateOpsWizard, setMigrateOpsDocsDrawerOpen, onProjectPage]);

    if (noMainFrame) {
        return <>{props.children}</>;
    }

    const width = !!currentProjectId ? (isDesktop ? `calc(100% - ${SIDE_MENU_WIDTH}px)` : "100%") : "100%";
    const left = !!currentProjectId ? (isDesktop ? SIDE_MENU_WIDTH : 0) : 0;
    return (
        <Box width={"100%"} height={"100vh"}>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    background: theme.palette.background.default,
                    margin: "0 auto",
                }}
            >
                <TopBar isDesktop={isDesktop} isPrivateEdition={isPrivateEdition} isRightSideDrawerOpen={rightSideDrawerOpen} />
                <Box height={"100%"} width={"100%"}>
                    {!!currentProjectId && <SideMenu isDesktop={isDesktop} isPrivateEdition={isPrivateEdition} />}
                    {!isDesktop && <MobileSideMenu />}
                    <main
                        style={{
                            height: "100vh",
                            flexGrow: 1,
                            paddingTop: theme.spacing(10),
                            transition: theme.transitions.create(["margin", "width"], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                            }),
                            width: width,
                            position: "absolute",
                            left: left,
                            ...(rightSideDrawerOpen && {
                                transition: theme.transitions.create(["margin", "width"], {
                                    easing: theme.transitions.easing.easeOut,
                                    duration: theme.transitions.duration.enteringScreen,
                                }),
                                width: !!currentProjectId
                                    ? isDesktop
                                        ? `calc(100% - ${SIDE_MENU_WIDTH + rightSideDrawerWidth}px)`
                                        : "100%"
                                    : `calc(100% - ${rightSideDrawerWidth}px)`,
                            }),
                        }}
                    >
                        <Stack sx={{ height: "100%" }} direction={"column"} justifyContent={"space-between"} alignItems={"flex-start"} spacing={0}>
                            <Box width={"100%"}>{props.children}</Box>
                            <CirrusFooter />
                        </Stack>
                    </main>
                    <HelpDrawer />
                </Box>
            </Box>
            <HelpActionButton />
        </Box>
    );
});
