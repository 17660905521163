/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AuthService
 */
import { computed, observable, extendObservable, makeAutoObservable, runInAction } from "mobx";
import { AuthenticatedUserInfo, EnableMfaTotp, GenerateMfaTotpSecret, GenerateRecoveryCodes } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { MobXStepperState } from "../../common/stepper/MobXStepperComponents";
import { generate2FaStepConfigs } from "./TwoFactorAuth";
import { ServerData } from "../core/data/ServerData";

export class AuthService {
    api: GRPCServices;
    dialogService: DialogService;
    twoFactorAuthState: TwoFactorAuthState;

    constructor(api: GRPCServices, dialogService: DialogService) {
        this.dialogService = dialogService;
        this.api = api;
        makeAutoObservable(this);
    }

    initTwoFactorAuthState() {
        this.twoFactorAuthState = new TwoFactorAuthState(this.api, this.dialogService);
    }
}

export class TwoFactorAuthState {
    private api: GRPCServices;
    dialogService: DialogService;

    enable2FAStepperState = new MobXStepperState(0, generate2FaStepConfigs(this));

    twoFACode = new ServerData<GenerateMfaTotpSecret.Response>().setDataFetcher(this.fetch2FACode.bind(this));
    recoveryCodes = new ServerData<GenerateRecoveryCodes.Response>().setDataFetcher(this.generateRecoveryCodes.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService) {
        this.api = api;
        this.dialogService = dialogService;
        makeAutoObservable(this);
    }

    verificationCode: string = null;

    setVerificationCode(token: string) {
        this.verificationCode = token;
    }

    async enable2Fa() {
        const req = new EnableMfaTotp.Request().setCode(this.verificationCode).setSecret(this.twoFACode.data?.getSecret());

        await this.dialogService.catchAndAlertError(this.api.authService.enableMfaTotp(req, null));
    }

    async fetch2FACode() {
        const req = new GenerateMfaTotpSecret.Request();
        return await this.api.authService.generateMfaTotpSecret(req, null);
    }

    async generateRecoveryCodes() {
        const req = new GenerateRecoveryCodes.Request();
        return await this.api.authService.generateRecoveryCodes(req, null);
    }
}
