import { observer } from "mobx-react-lite";
import { alpha, Box, BoxProps, Button, ButtonProps, useTheme } from "@mui/material";
import React from "react";
import { MdArrowBack } from "react-icons/md";

//TODO: turn this button into a link style (underline and change color text on hover

// ======================
// BackButton
// ======================

interface BackButtonProps {
    navFunction: () => void;
    label: string;
    boxProps?: Partial<BoxProps>;
}

export const BackButton: React.FC<BackButtonProps & Partial<ButtonProps>> = observer((p) => {
    const t = useTheme();
    return (
        <Box color={t.palette.text.secondary} {...p.boxProps}>
            <Button color={"inherit"} startIcon={<MdArrowBack />} onClick={p.navFunction}>
                {p.label}
            </Button>
        </Box>
    );
});
