import { observer } from "mobx-react-lite";
import { CardProps, Card, Theme, alpha, Box, SvgIcon, Typography, Button, Alert, useTheme } from "@mui/material";
import React, { createContext } from "react";
import { MdCheckCircle, MdOutlineStorage } from "react-icons/md";
import { ActionConfig } from "../actions/CommonActions";
import { QuickTipButton } from "../../modules/help/HelpCommon";
import { StepperStore } from "../stepper/StepperState";

// ======================
// SelectableBox
// ======================

interface SelectableBoxProps {
    selected: boolean;
    onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
    disableHoverEffects?: boolean;
    darkBorder?: boolean;
    checkmark?: boolean;
    cardProps?: Partial<CardProps>;
    children?: React.ReactNode;
    expandOnSelect?: boolean;
    onDeselect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SelectableBox: React.FC<SelectableBoxProps> = observer((p) => {
    const { selected, onSelect, onDeselect, disabled, darkBorder, checkmark, disableHoverEffects, cardProps, children, expandOnSelect } = p;

    return (
        <SelectableBoxSelectedContext.Provider value={selected}>
            <Card
                {...cardProps}
                sx={{
                    backgroundColor: "rgba(0,0,0,0)",
                    overflow: "visible",
                    border: "2px solid",
                    borderColor: (t: Theme) => (selected ? t.palette.primary.main : darkBorder ? "rgba(255, 255, 255, .2)" : alpha("#fff", 0.2)),
                    "&:hover":
                        disabled || p.disableHoverEffects || selected
                            ? {}
                            : {
                                  backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.1),
                                  borderColor: (t: Theme) => t.palette.primary.main,
                                  cursor: "pointer",
                              },
                    "&:active":
                        disabled || disableHoverEffects || selected
                            ? {}
                            : {
                                  backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.2),
                                  borderColor: (t: Theme) => t.palette.primary.main,
                                  cursor: "pointer",
                              },
                    ...cardProps?.sx,
                }}
                elevation={0}
                onClick={disabled || selected ? () => {} : onSelect}
            >
                <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                    <Box
                        onClick={(e) => {
                            if (selected && !!onDeselect) {
                                onDeselect(e);
                            }
                        }}
                        sx={{
                            cursor: !!onDeselect ? "pointer" : "default",
                        }}
                    >
                        {children}
                    </Box>
                    {!!checkmark && (
                        <Box display={"flex"} justifyContent={"center"} pb={4}>
                            <SvgIcon
                                sx={{
                                    color: selected ? (t: Theme) => t.palette.primary.main : "rgba(255, 255, 255, .2)",
                                }}
                            >
                                <MdCheckCircle />
                            </SvgIcon>
                        </Box>
                    )}
                </Box>
            </Card>
        </SelectableBoxSelectedContext.Provider>
    );
});

// ======================
// SelectableCard
// ======================

export interface SelectableCardProps extends SelectableBoxProps {
    icon?: React.ReactNode;
    title?: string | React.ReactNode;
    description?: string | React.ReactNode;
    tip?: React.ReactNode;
    actions?: ActionConfig[];
    warning?: string;
    chipLabel?: React.ReactNode;
    alert?: string;
    checkmark?: boolean;
    darkBorder?: boolean;
    header?: string | React.ReactNode;
    mainContentPadding?: number;
}

export const SelectableCard: React.FC<SelectableCardProps> = observer((p) => {
    const {
        cardProps,
        mainContentPadding,
        selected,
        onSelect,
        actions,
        disabled,
        tip,
        warning,
        description,
        title,
        icon,
        chipLabel,
        alert,
        checkmark,
        darkBorder,
        header,
        disableHoverEffects,
        ...props
    } = p;

    const theme = useTheme();

    return (
        <SelectableBox
            onSelect={onSelect}
            selected={selected}
            disabled={disabled}
            darkBorder={darkBorder}
            checkmark={checkmark}
            cardProps={cardProps}
            disableHoverEffects={disableHoverEffects}
        >
            <Box display={"flex"} flexDirection={"column"} justifyContent={header ? "flex-start" : "center"}>
                {chipLabel && (
                    <Box p={2} sx={{ opacity: disabled ? 0.2 : 1 }}>
                        {chipLabel}
                    </Box>
                )}
                {header && (
                    <Box
                        sx={
                            selected
                                ? {
                                      backgroundColor: theme.palette.primary.main,
                                      borderTopLeftRadius: 5,
                                      borderTopRightRadius: 5,
                                      border: `1px solid ${theme.palette.primary.main}`,
                                  }
                                : {
                                      backgroundColor: theme.palette.cirrus.light,
                                      borderTopLeftRadius: 5,
                                      borderTopRightRadius: 5,
                                      borderBottom: `2px solid ${alpha("#fff", 0.1)}`,
                                  }
                        }
                        p={1}
                    >
                        <Typography textAlign={"center"}>{header}</Typography>
                    </Box>
                )}

                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    p={mainContentPadding !== undefined ? mainContentPadding : 4}
                    flexGrow={1}
                >
                    <Box textAlign={"center"}>
                        <Box sx={{ opacity: disabled ? 0.1 : 1 }}>{icon}</Box>
                        <Box p={2}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <Typography
                                    variant={"h6"}
                                    sx={{
                                        color: (t: Theme) => (disabled ? "rgba(255, 255, 255, .2)" : "white"),
                                    }}
                                >
                                    {p.title}
                                </Typography>
                                {tip && (
                                    <>
                                        <Typography>&nbsp; &nbsp;</Typography>
                                        <QuickTipButton title={tip} sx={{ opacity: disabled ? 0.2 : 1 }} disabled={disabled} />
                                    </>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    color: (t: Theme) => (disabled ? "rgba(255, 255, 255, .2)" : t.palette.text.secondary),
                                }}
                            >
                                {description}
                            </Box>
                        </Box>
                        {actions && (
                            <Box sx={{ opacity: disabled ? 0.2 : 1 }}>
                                {actions.map((a) => (
                                    <Button
                                        {...a.buttonProps}
                                        key={a.id}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            a.action();
                                        }}
                                        disabled={disabled || a.disabled}
                                    >
                                        {a.name}
                                    </Button>
                                ))}
                            </Box>
                        )}
                        {warning && (
                            <Box pt={2}>
                                <Typography sx={{ color: (t: Theme) => (disabled ? "rgba(255, 255, 255, .2)" : t.palette.warning.main) }}>{warning}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                {alert && (
                    <Box p={2}>
                        <Alert severity={"info"}>{alert}</Alert>
                    </Box>
                )}
            </Box>
        </SelectableBox>
    );
});

// ======================
// SelectableCardExpansionContent
// ======================

interface SelectableBoxExpansionContentProps {
    children: React.ReactNode;
}

export const SelectableBoxExpansionContent: React.FC<SelectableBoxExpansionContentProps> = (p) => {
    const { children } = p;
    return (
        <SelectableBoxSelectedContext.Consumer>
            {(selected) => {
                if (selected) {
                    return (
                        <Box
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {children}
                        </Box>
                    );
                }
                return null;
            }}
        </SelectableBoxSelectedContext.Consumer>
    );
};

export const SelectableBoxSelectedContext = createContext<boolean>(false);
