import { observer } from "mobx-react-lite";
import React from "react";
import { useAppServices } from "../app/services";
import { ProjectMemberInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { useGetMyProjectRole } from "../project/project_hooks";
import { useCurrentProjectState } from "../project/CurrentProjectState";

// ======================
// OperatorView
// ======================

export const useIsOperatorView = () => {
    const myRole = useCurrentProjectState((s) => s.myRole);
    if (myRole !== null) {
        return myRole === ProjectMemberInfo.Role.OPERATOR || myRole === ProjectMemberInfo.Role.ADMIN;
    }
    return false;
};

interface RestrictedViewProps {
    children: React.ReactNode;
}

export const OperatorView: React.FC<RestrictedViewProps> = observer((props) => {
    const isOperator = useIsOperatorView();
    return <>{isOperator && props.children}</>;
});

// ======================
// AdminView
// ======================

export const useIsAdminView = () => {
    const myRole = useCurrentProjectState((s) => s.myRole);
    if (myRole !== null) {
        return myRole === ProjectMemberInfo.Role.ADMIN;
    }
    return false;
};

export const AdminView: React.FC<RestrictedViewProps> = observer((props) => {
    const isAdmin = useIsAdminView();
    return <>{isAdmin && props.children}</>;
});

// ======================
// MonitorView
// ======================

export const useIsMonitorView = () => {
    const myRole = useCurrentProjectState((s) => s.myRole);
    if (myRole !== null) {
        return myRole === ProjectMemberInfo.Role.MONITOR;
    }
    return false;
};

export const MonitorView: React.FC<RestrictedViewProps> = observer((p) => {
    const isMonitor = useIsMonitorView();
    return <>{isMonitor && p.children}</>;
});

export const useIsSupportView = () => {
    const { appGlobalService } = useAppServices();
    return appGlobalService.isInternalUser;
};
