import { ProjectService } from "../project/ProjectService";
import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { makeAutoObservable, observable } from "mobx";
import { ServerData } from "../core/data/ServerData";
import { GalaxyMigrateDeploymentDetails, GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    DeleteHostLicense,
    GetGalaxyMigrateDeploymentDetails,
    ListGalaxyMigrateDeployments,
    ListHostLicenseTransactions,
    RemoveDeployment,
    RemoveOfflineDeployments,
} from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { ServerListData } from "../core/data/ListData";
import { PagerMeta, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { HostLicenseTxInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { getDeploymentListFilterConfig } from "../galaxymigrate/GmDeploymentCommon";
import { ProductType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/product_type_pb";

export class DeploymentService {
    private readonly api: GRPCServices;
    protected readonly projectService: ProjectService;
    protected readonly dialogService: DialogService;
    protected readonly progressService: ProgressService;

    galaxyMigrateHostLicenseTransactions = new ServerListData<ListHostLicenseTransactions.Response.AsObject, HostLicenseTxInfo.AsObject>().setDataFetcher(
        this.listHostLicenseTransactions.bind(this)
    );

    // todo this is current fixed to this type, need adjustment when introduce more
    currentDeploymentID: string;
    currentDeployment = new ServerData<GalaxyMigrateDeploymentDetails>().setDataFetcher(this.fetchDeploymentDetails.bind(this)); // will make it union other types

    constructor(api: GRPCServices, projectService: ProjectService, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.projectService = projectService;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    get currentDeploymentVersion() {
        return this.currentDeployment.ready ? this.currentDeployment.data.getInfo().getDeployment().getVersion() : null;
    }

    get currentDeploymentOS() {
        return this.currentDeployment.ready ? this.currentDeployment.data.getInfo()?.getOsType() : null;
    }
    async fetchDeploymentDetails(deploymentId = this.currentDeploymentID) {
        if (!deploymentId) {
            throw new Error("no current current deployment id context set");
        }

        // currently assume to be galaxy migrate, will need smarter logic here in future
        const req = new GetGalaxyMigrateDeploymentDetails.Request().setSystemId(deploymentId);
        const response = await this.api.deploymentService.getGalaxyMigrateDeploymentDetails(req, null);
        return response.getDeployment();
    }

    setCurrentDeploymentID(id: string) {
        console.debug(`current deployment id = ${id}`);
        const changed = id !== this.currentDeploymentID;
        this.currentDeploymentID = id;
        if (changed) {
            this.currentDeployment.resetData();
        }
    }

    async removeDeployment(deploymentId: string) {
        const req = new RemoveDeployment.Request().setSystemId(deploymentId);
        const confirmed = await this.dialogService.addConfirmDialog({
            message: `Are you sure you want to remove this host from this project?`,
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            return await this.progressService.track(this.api.deploymentService.removeDeployment(req, null));
        }
    }

    async listHostLicenseTransactions(systemId: string) {
        const req = new ListHostLicenseTransactions.Request().setSystemId(systemId || this.currentDeploymentID);

        const response = await this.api.deploymentService.listHostLicenseTransactions(req, null);
        return response.toObject();
    }
}
