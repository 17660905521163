import { DialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { getReportTypeLabel, useNavigateToReports } from "./ReportHelpers";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Typography } from "@mui/material";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";

interface CreateSessionReportDialogProps {
    reportType: ReportType.ReportType;
    reportArg: string;
    dialogState: DialogState;
    createReportFn: (args: { reportArg: string; reportNotes: string }) => void | Promise<any>;
}

export const CreateReportDialog: React.FC<CreateSessionReportDialogProps> = observer((p) => {
    const { reportType, reportArg, dialogState, createReportFn } = p;
    const fullScreen = useShouldDialogFullScreen();
    const [reportNotes, setReportNotes] = useState<string>("");
    const [error, setError] = useState<string>("");
    const navigateToReportsScreen = useNavigateToReports();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} fullWidth maxWidth={"md"}>
            <DialogTitle>{`Generate Report`}</DialogTitle>
            <DialogContent>
                <Typography>{`A new ${getReportTypeLabel(reportType)} will be generated. Report notes can be added to the project for references.`}</Typography>
                <br />
                <TextField
                    variant={"filled"}
                    label={"Report Notes"}
                    multiline
                    minRows={5}
                    error={reportNotes.length > 5000}
                    helperText={error}
                    value={reportNotes}
                    onChange={(e) => {
                        setReportNotes(e.target.value);
                        if (e.target.value.length > 5000) {
                            setError("Max 5000 characters");
                        } else {
                            setError("");
                        }
                    }}
                    fullWidth
                />
                <Box pt={1}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {`Generated Reports will be available from the project`}
                        &nbsp;
                        <Link onClick={navigateToReportsScreen}>{`Reports Page`}</Link>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                    {`Cancel`}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={async () => {
                        const res = await createReportFn({ reportArg, reportNotes });
                        if (res) {
                            dialogState.close();
                        }
                    }}
                >
                    {`Generate Report`}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
