import { observer } from "mobx-react-lite";
import { Box, Button, Card, CardHeader, Dialog, SvgIcon, Theme, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppServices } from "../../app/services";
import { useInitData, useServerDataWithLoadingList } from "../../core/data/DataLoaderHooks";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { CheckForOnlineUpgradeResponse } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { useIsDesktop } from "../../layout/MainLayout";
import { MdBuild } from "react-icons/md";

const useGmDeploymentDetailsSettingsStyles = () => {
    const t = useTheme();
    return {
        upgradeOutput: {
            backgroundColor: t.palette.cirrus.main,
            maxHeight: "75vh",
            overflowX: "auto",
        },
    };
};

// ======================
// OnlineUpgrade
// ======================

interface OnlineUpgradeProps {}

export const GmDeploymentOnlineUpgradeSettingArea: React.FC<OnlineUpgradeProps> = observer((props) => {
    const { gmDeploymentService, deploymentService } = useAppServices();
    const isDesktop = useIsDesktop();

    const upgradeDialogState = useDialogState();
    const [alertNoData, setAlertNoData] = useState(false);

    const openUpgradeStatusDialog = (alertIfNoPrevStatus = true) => {
        setAlertNoData(alertIfNoPrevStatus);
        upgradeDialogState.open();
    };

    useInitData({
        init: () => gmDeploymentService.upgradeState.initUpgradeState(deploymentService.currentDeploymentID),
    });

    let subheader = `You can upgrade cirrus migrate cloud software online without interrupting migration`;
    const supported = gmDeploymentService.currentDeploymentSupportsOnlineUpgrade;
    if (!supported) {
        subheader = "Online software upgrade is not supported by this currently installed version. Please see help for manual procedure";
    }

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    avatar={
                        <SvgIcon>
                            <MdBuild />
                        </SvgIcon>
                    }
                    title={"Cirrus Migrate Cloud Software Upgrade"}
                    subheader={subheader}
                    action={
                        isDesktop ? (
                            <Box display={"flex"} flexWrap={"wrap"}>
                                {supported && <CheckForUpgradeButton openUpgradeStatusDialog={openUpgradeStatusDialog} />}
                                {supported && <LastUpgradeStatusButton openUpgradeStatusDialog={openUpgradeStatusDialog} />}
                            </Box>
                        ) : (
                            []
                        )
                    }
                />
                {!isDesktop && (
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        {supported && <CheckForUpgradeButton openUpgradeStatusDialog={openUpgradeStatusDialog} />}
                        {supported && <LastUpgradeStatusButton openUpgradeStatusDialog={openUpgradeStatusDialog} />}
                    </Box>
                )}

                <UpgradeStatusDialog dialogState={upgradeDialogState} alertNoData={alertNoData} />
            </Card>
        </Box>
    );
});

// ======================
// LastUpgradeStatusButton
// ======================

interface LastUpgradeStatusButtonProps {
    openUpgradeStatusDialog: (alertIfNoPrevData: boolean) => void;
}

export const LastUpgradeStatusButton: React.FC<LastUpgradeStatusButtonProps> = observer((props) => {
    return (
        <Box p={2}>
            <Button variant={"outlined"} onClick={() => props.openUpgradeStatusDialog(true)}>
                {"View Last Upgrade Status"}
            </Button>
        </Box>
    );
});

// ======================
// UpgradeStatusDialog
// ======================

interface UpgradeStatusDialogProps {
    dialogState: DialogState;
    alertNoData: boolean;
}

const UpgradeStatusDialog: React.FC<UpgradeStatusDialogProps> = observer((props) => {
    const { dialogState, alertNoData } = props;
    const fullScreen = useShouldDialogFullScreen();
    const { isOpen, close } = dialogState;
    return (
        <Dialog open={isOpen} onClose={close} fullScreen={fullScreen} maxWidth={"md"} fullWidth={true}>
            {isOpen && <UpgradeDialogBody dialogState={dialogState} alertNoData={alertNoData} />}
        </Dialog>
    );
});

// ======================
// UpgradeDialogBody
// ======================

interface UpgradeDialogBodyProps {
    dialogState: DialogState;
    alertNoData: boolean;
}

const UpgradeDialogBody: React.FC<UpgradeDialogBodyProps> = observer((props) => {
    const { gmDeploymentService, dialogService } = useAppServices();
    const { open, isOpen, close } = props.dialogState;
    const { alertNoData } = props;
    const fullScreen = useShouldDialogFullScreen();

    const upgradeStatusData = gmDeploymentService.upgradeState.upgradeStatus;
    useInitData({
        init: () => upgradeStatusData.resetData(),
        poll: () => upgradeStatusData.fetchData(),
        pollInterval: 1,
    });

    useEffect(() => {
        if (upgradeStatusData.ready && !upgradeStatusData.data.getLastStatus()?.getLastUpdateTime()?.getSeconds() && alertNoData) {
            close();
            dialogService.addAlertDialog({ title: "No Upgrade Info", message: "No previous upgrade record found." });
        }
    });

    return useServerDataWithLoadingList(upgradeStatusData, (data) => {
        const statusText = !data.getLastStatus().hasLastUpdateTime() ? "N/A" : data.getLastStatus().getCompleted() ? "Completed" : "Upgrading...";
        const getSuccessText = () => {
            if (!data.getLastStatus().hasLastUpdateTime()) {
                return "N/A";
            }
            if (!data.getLastStatus().getCompleted()) {
                return "Pending...";
            } else if (data.getLastStatus().getSuccess()) {
                return "Success";
            } else {
                return "Failed";
            }
        };
        return (
            <>
                <CardHeader title={"Cirrus Migrate Cloud Software Upgrade"} subheader={`Status: ${statusText} | Result: ${getSuccessText()}`} />
                {gmDeploymentService.upgradeState.isUpgrading && (
                    <Box p={2}>
                        <Typography paragraph={true}>{"Upgrade in progress. While upgrading, please do not close or refresh this screen."}</Typography>
                    </Box>
                )}

                <Box p={2} width={"100%"} display={"flex"} justifyContent={"center"}>
                    <StatusLog data={data.getLastStatus().getScriptOutputLinesList()} />
                </Box>
            </>
        );
    });
});

// ======================
// StatusLog
// ======================

interface StatusLogProps {
    data: Array<string>;
}

export const StatusLog: React.FC<StatusLogProps> = observer((props) => {
    const styles = useGmDeploymentDetailsSettingsStyles();
    const { data } = props;

    return (
        <Box p={2} width={"100%"} sx={styles.upgradeOutput}>
            {data.map((line, index) => {
                return <pre key={index}>{line}</pre>;
            })}
        </Box>
    );
});

// ======================
// CheckForUpgradeButton
// ======================

interface CheckForUpgradeButtonProps {
    openUpgradeStatusDialog: (alertIfNoPrevData: boolean) => void;
}

const CheckForUpgradeButton: React.FC<CheckForUpgradeButtonProps> = observer((props) => {
    const { gmDeploymentService, dialogService, progressService } = useAppServices();

    const checkForUpgrade = async () => {
        let availableUpgrade: CheckForOnlineUpgradeResponse;
        try {
            availableUpgrade = await progressService.track(gmDeploymentService.upgradeState.checkForUpgrade(), null, false);
        } catch (e) {
            if (((e as Error)?.message || "").includes("no supported installation package found")) {
                availableUpgrade = null;
            } else {
                await dialogService.addErrorAlertDialog(e as Error);
            }
        }

        if (!availableUpgrade) {
            // alert no upgrade
            await dialogService.addAlertDialog({
                title: "You're up to date!",
                message: `No available software upgrade available for this system at this moment`,
            });
        } else {
            let confirmed = false;
            if ((availableUpgrade?.getNewVersion() || "") !== "") {
                confirmed = await dialogService.addConfirmDialog({
                    message: `New Cirrus Migrate Cloud Version ${availableUpgrade.getNewVersion()} is available for upgrade. Do you want to upgrade now?`,
                    autoConfirmationQuestionLine: false,
                });
                if (confirmed) {
                    confirmed = await dialogService.addConfirmDialog({
                        message:
                            "By continuing, galaxy-migrate services will be brought offline for upgrade. " +
                            "While Production and Migration I/O will not be interrupted, management functionalities will temporarily be restricted until upgrade is completed.  " +
                            "It is recommended that you have direct host access in order to monitor the upgrade process ",
                    });

                    if (confirmed) {
                        props.openUpgradeStatusDialog(false);
                        await gmDeploymentService.upgradeState.upgradeGalaxyMigrate();
                    }
                }
            }
        }
    };

    return (
        <Box p={2}>
            <Button variant={"outlined"} color={"primary"} onClick={checkForUpgrade}>
                {"Check For Upgrade"}
            </Button>
        </Box>
    );
});
