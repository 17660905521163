import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { ListProjectActivities } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { useQuery } from "@tanstack/react-query";
import { ProjectSettingsQueryKeys } from "../../common/QueryKeys";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";

export const useListProjectActivities = (projectId: string, pageIndex: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListProjectActivities.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(pageIndex).setPerPage(perPage));
        const res = await apis.projectService.listProjectActivities(req, null);
        return res;
    };
    return useQuery({
        queryKey: [ProjectSettingsQueryKeys.listProjectActivities, projectId, pageIndex, perPage],
        queryFn,
        refetchInterval: 30 * 1000,
    });
};
