import * as React from "react";
import { observer } from "mobx-react-lite";
import {
    alpha,
    Box,
    Divider,
    Drawer,
    IconButton,
    Link as MuiLink,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    SvgIcon,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { ProjectIcon, useNavigateToProjectListScreen } from "../project/ProjectCommon";
import { CurrentProjectSideMenu } from "./CurrentProjectSideMenu";
import { useAppServices } from "../app/services";
import { HelpIcon } from "../../common/CommonIcons";
import { ApiLink, EULALink, HELP_CENTER_URL, PrivacyPolicyLink, TermsOfServiceLink, useNavigateToExternalLink } from "../../modules/help/HelpCommon";
import { MdClose, MdSupportAgent } from "react-icons/md";
import { HideInPrivateEdition } from "../auth/PrivateEditionView";
import { CdsSupportStaffView, useNavigateToSupportProjects } from "../support/SupportCommon";
import { useCurrentProject } from "../project/CurrentProjectState";

// ======================
// SideMenu
// ======================

export const getSideMenuWidth = (t: Theme) => t.spacing(32);

const SIDE_MENU_ROOT_STYLE = {
    width: getSideMenuWidth,
    flexShrink: 0,
    scrollbarWidth: 0,
};

interface SideMenuProps {
    isDesktop: boolean;
    isPrivateEdition: boolean;
}

export const SideMenu: React.FC<SideMenuProps> = observer(({ ...p }) => {
    const { isDesktop } = p;

    if (!isDesktop) {
        return <MobileSideMenu />;
    }

    const theme = useTheme();

    return (
        <Drawer
            variant={"permanent"}
            sx={{
                ...SIDE_MENU_ROOT_STYLE,
                "& .MuiDrawer-paper": {
                    width: getSideMenuWidth,
                    zIndex: (t: Theme) => t.zIndex.appBar - 1,
                    background: (t: Theme) => t.palette.cirrus.medium,
                    borderRightWidth: 2,
                    borderRightColor: (t: Theme) => t.palette.cirrus.light,
                    borderRightStyle: "solid",
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "10px",
                        border: "2px solid transparent",
                        backgroundColor: "rgba(255,255,255,.1)",
                        backgroundClip: `content-box`,
                    },
                },
            }}
            elevation={0}
            anchor="left"
        >
            <Stack pt={theme.spacing(10)}>
                <CurrentProjectSideMenu />
            </Stack>
        </Drawer>
    );
});

// ======================
// MobileSideMenu
// ======================

interface MobileSideMenuProps {}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = observer((props) => {
    const { appGlobalService } = useAppServices();
    const theme = useTheme();
    const goBackToProjectPage = useNavigateToProjectListScreen();
    const goToSupportProjects = useNavigateToSupportProjects();
    const inProject = !!useCurrentProject();
    const goToHelpCenter = useNavigateToExternalLink(HELP_CENTER_URL);

    const style = {
        padding: 0.5,
        fontSize: 14,
        "&.Mui-selected": {
            fontWeight: 600,
            color: theme.palette.primary.light,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            border: `2px solid ${theme.palette.primary.main}`,
            "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.4),
                color: "white",
            },
        },
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
            color: "white",
            fontWeight: 600,
        },
    };

    return (
        <Drawer
            variant={"temporary"}
            open={appGlobalService.sideBarOpen}
            PaperProps={{
                sx: {
                    width: (t: Theme) => t.spacing(36),
                    zIndex: (t: Theme) => t.zIndex.appBar + 1,
                    background: (t: Theme) => t.palette.cirrus.medium,
                },
            }}
            sx={{
                width: (t: Theme) => t.spacing(36),
            }}
            ModalProps={{ onBackdropClick: () => appGlobalService.closeSideBar() }}
            elevation={0}
            anchor="left"
        >
            <Box display={"flex"} justifyContent={"flex-end"} p={1} width={"100%"}>
                <IconButton onClick={() => appGlobalService.closeSideBar()}>
                    <SvgIcon>
                        <MdClose />
                    </SvgIcon>
                </IconButton>
            </Box>
            <List>
                <ListItemButton sx={style} onClick={goBackToProjectPage}>
                    <ListItemIcon
                        sx={{
                            minWidth: "40px",
                            color: "inherit",
                            pl: 1.5,
                        }}
                    >
                        <ProjectIcon size={18} />
                    </ListItemIcon>
                    <Box pl={0} display={"flex"} alignItems={"center"}>
                        <ListItemText
                            primary={
                                <Typography fontSize={14} fontWeight={"inherit"}>
                                    {"My Projects"}
                                </Typography>
                            }
                        />
                    </Box>
                </ListItemButton>
                <CdsSupportStaffView>
                    <ListItemButton sx={style} onClick={goToSupportProjects}>
                        <ListItemIcon
                            sx={{
                                minWidth: "40px",
                                color: "red",
                                pl: 1.5,
                            }}
                        >
                            <MdSupportAgent size={18} />
                        </ListItemIcon>
                        <Box pl={0} display={"flex"} alignItems={"center"}>
                            <ListItemText
                                primary={
                                    <Typography color={"error"} fontSize={14} fontWeight={"inherit"}>
                                        {"Support Projects"}
                                    </Typography>
                                }
                            />
                        </Box>
                    </ListItemButton>
                </CdsSupportStaffView>
            </List>

            <Box pt={1} pb={1}>
                <Divider sx={{ borderColor: (t: Theme) => t.palette.cirrus.light }} />
            </Box>
            {inProject && (
                <>
                    <CurrentProjectSideMenu />
                    <Box pt={1} pb={1}>
                        <Divider sx={{ borderColor: (t: Theme) => t.palette.cirrus.light }} />
                    </Box>
                </>
            )}
            <List>
                <ListItemButton sx={style} onClick={goToHelpCenter}>
                    <ListItemIcon
                        sx={{
                            minWidth: "40px",
                            color: "white",
                            pl: 1.5,
                        }}
                    >
                        <HelpIcon size={18} />
                    </ListItemIcon>
                    <Box pl={0} display={"flex"} alignItems={"center"}>
                        <ListItemText
                            primary={
                                <Typography fontSize={14} fontWeight={"inherit"}>
                                    {"Help Center"}
                                </Typography>
                            }
                        />
                    </Box>
                </ListItemButton>
            </List>
        </Drawer>
    );
});

// ======================
// LegalLinks
// ======================

interface LegalLinksProps {}

export const LegalLinks: React.FC<LegalLinksProps> = observer((props) => {
    const currentYear = new Date(Date.now()).getFullYear();
    const navigateToCdsWebsite = useNavigateToExternalLink("https://www.cirrusdata.com/");

    return (
        <Box pt={2} pl={2}>
            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                <Box>
                    <TermsOfServiceLink variant={"caption"} color={"textSecondary"} label={"ToS"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <PrivacyPolicyLink variant={"caption"} color={"textSecondary"} />
                </Box>
                &nbsp; | &nbsp;
                <Box>
                    <EULALink variant={"caption"} color={"textSecondary"} />
                </Box>
                <HideInPrivateEdition>
                    <>
                        &nbsp; | &nbsp;
                        <Box>
                            <ApiLink variant={"caption"} color={"textSecondary"} />
                        </Box>
                    </>
                </HideInPrivateEdition>
            </Box>
            <Box display={"flex"} justifyContent={"flex-start"}>
                <MuiLink onClick={navigateToCdsWebsite} color={"textSecondary"}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        © {currentYear} Cirrus Data Solutions Inc.
                    </Typography>
                </MuiLink>
            </Box>
        </Box>
    );
});

// ======================
// SupportLink
// ======================
interface SupportLinkProps {}

export const SupportLink: React.FC<SupportLinkProps> = observer((props) => {
    const nav = useNavigateToExternalLink(HELP_CENTER_URL);
    const theme = useTheme();
    const style = {
        marginLeft: 2,
        marginRight: 2,
        borderRadius: 2,
        //padding: .5,
        //paddingLeft: 3.5,
        fontSize: 14,
        color: theme.palette.text.secondary,
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
            color: "white",
            fontWeight: 600,
        },
    };
    return (
        <List>
            <ListItemButton onClick={nav} sx={style}>
                <ListItemIcon
                    sx={{
                        minWidth: "30px",
                    }}
                >
                    <HelpIcon size={20} />
                </ListItemIcon>
                <ListItemText primary={<Typography fontSize={14} fontWeight={"inherit"}>{`Help Center`}</Typography>} />
            </ListItemButton>
        </List>
    );
});
