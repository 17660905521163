import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { UserMutationKeys, UserQueryKeys } from "../../common/QueryKeys";
import {
    GenerateAPIToken,
    GetMyUserInfo,
    ListMyUserSessions,
    ReplaceMyAvatar,
    RequestMyAvatarUploadUrl,
    RevokeAPIToken,
    RevokeMyUserSession,
    UpdateMyUserInfo,
} from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useUpdateCurrentAuthUser } from "../auth/auth_hooks";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { HubUser } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";

export const useListUserSessions = () => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const res = await apis.authService.listMyUserSessions(new ListMyUserSessions.Request(), null);
        return res?.toObject();
    };

    return useQuery({
        queryKey: [UserQueryKeys.listMyUserSessions],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useRevokeMyUserSession = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (sessionId: number) => {
        const req = new RevokeMyUserSession.Request().setSessionId(sessionId);
        return await apis.authService.revokeMyUserSession(req, null);
    };
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.revokeUserSession],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({ queryKey: [UserQueryKeys.listMyUserSessions] });
        },
    });
};

export const useGetMyUserInfo = () => {
    const authService = useGrpcApiStore((s) => s.authService);
    const queryFn = async () => {
        const res = await authService.getMyUserInfo(new GetMyUserInfo.Request(), null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [UserQueryKeys.getMyUserInfo],
        queryFn: queryFn,
        retry: false,
    });
};

export const useFetchNewAvatarLink = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (file: File) => {
        const req = new RequestMyAvatarUploadUrl.Request().setFileType(file.type);
        const res = await apis.authService.requestMyAvatarUploadUrl(req, null);
        return res.toObject();
    };
    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.fetchNewAvatarLink],
        mutationFn,
    });
};

export const useReplaceAvatar = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async () => {
        const req = new ReplaceMyAvatar.Request().setRemove(false);
        return await apis.authService.replaceMyAvatar(req, null);
    };
    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.replaceAvatar],
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [UserQueryKeys.getMyUserInfo],
            });
        },
    });
};

export const useRemoveAvatar = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async () => {
        const req = new ReplaceMyAvatar.Request().setRemove(true);
        return await apis.authService.replaceMyAvatar(req, null);
    };
    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.removeAvatar],
        mutationFn,
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: [UserQueryKeys.getMyUserInfo],
            }),
    });
};

export const useUploadAvatar = () => {
    const mutationFn = async (args: { uri: string; file: File }) => {
        const request = new Request(args.uri, {
            method: "PUT",
            body: args.file,
            headers: {
                "Content-Type": args.file.type,
                "x-amz-acl": "public-read",
            },
        });
        return await fetch(request);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.uploadAvatar],
        mutationFn,
    });
};

export const useUpdateAvatar = () => {
    const fetchNewAvatarLink = useFetchNewAvatarLink();
    const uploadAvatar = useUploadAvatar();
    const replaceAvatar = useReplaceAvatar();

    return async (file: File) => {
        const fetchedLink = await fetchNewAvatarLink.mutateAsync(file);
        if (fetchedLink) {
            const uploadedAvatar = await uploadAvatar.mutateAsync({ uri: fetchedLink.uploadToThisUri, file });
            if (uploadedAvatar) {
                return await replaceAvatar.mutateAsync();
            }
            return null;
        }
        return null;
    };
};

export const useUpdateMyUserInfo = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (userInfo: HubUser) => {
        const req = new UpdateMyUserInfo.Request()
            .setCompanyName(userInfo.getCompanyName())
            .setFirstName(userInfo.getFirstName())
            .setJobTitle(userInfo.getJobTitle())
            .setLastName(userInfo.getLastName())
            .setLocation(userInfo.getLocation())
            .setPhoneNumber(userInfo.getPhoneNumber());

        return await apis.authService.updateMyUserInfo(req, null);
    };
    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.updateMyUserInfo],
        mutationFn,
    });
};

export const useGenerateApiToken = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async () => {
        const req = new GenerateAPIToken.Request();
        const res = await apis.authService.generateAPIToken(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.generateApiToken],
        mutationFn,
    });
};

export const useRevokeApiToken = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async () => {
        const req = new RevokeAPIToken.Request();
        return await apis.authService.revokeAPIToken(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [UserMutationKeys.revokeApiToken],
        mutationFn,
    });
};
