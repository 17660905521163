/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppStates
 */

import { AuthService } from "../auth/AuthService";
import { observer, useLocalObservable, useLocalStore } from "mobx-react-lite";
import React, { createContext, useContext } from "react";
// import {ServerAPI} from '../core/ServerAPI';
import { AppGlobalService } from "./AppGlobalService";
import { ProgressService } from "../core/progress/ProgressService";
import { DialogService } from "../core/dialog/DialogService";
import { GRPCServices } from "../grpc/grpcapi";
import { ProjectService } from "../project/ProjectService";
import { DeploymentService } from "../deployment/DeploymentService";
import { GalaxyMigrateDeploymentService } from "../galaxymigrate/GmDeploymentService";
import { GalaxyMigrateMigrationService } from "../cmcMigration/GmMigrationService";
import { configure } from "mobx";
import { AppUpdateCheckerService } from "../core/updateChecker/AppUpdateCheckerService";
import { NotificationService } from "../../common/notifications/NotificationService";
import { HelpService } from "../help/HelpService";
import { ChecklistService } from "../cmcMigration/sessionDetails/checklist/ChecklistService";
import { LicenseService } from "../license/LicenseService";
import { SupportService } from "../support/SupportService";

export const configureMobx = () => {
    configure({
        enforceActions: "never", // currently set to never (not recommended) because of async function support. believe that it is okay to not have to wrap state changes as long as it is from the same class
    });
};

const initServices = () => {
    // const api                         = new ServerAPI();
    const api = new GRPCServices();
    const appGlobalService = new AppGlobalService(api);
    const dialogService = new DialogService();
    const notificationService = new NotificationService();
    const progressService = new ProgressService(dialogService);
    const appUpdateCheckerService = new AppUpdateCheckerService(appGlobalService, dialogService, notificationService);
    const authService = new AuthService(api, dialogService);
    const projectService = new ProjectService(api, dialogService, progressService);
    const deploymentService = new DeploymentService(api, projectService, dialogService, progressService);
    const gmDeploymentService = new GalaxyMigrateDeploymentService(api, projectService, dialogService, progressService, deploymentService);
    const gmMigrationService = new GalaxyMigrateMigrationService(api, dialogService, progressService, gmDeploymentService, deploymentService, projectService);
    const helpService = new HelpService(api, dialogService, progressService);
    const checklistService = new ChecklistService(api, dialogService, progressService);
    const licenseService = new LicenseService(api, dialogService, progressService, projectService);
    const supportService = new SupportService(api, dialogService, progressService);

    return {
        appGlobalService,
        progressService,
        dialogService,
        notificationService,
        api,
        appUpdateCheckerService,
        authService,
        projectService,
        deploymentService,
        gmDeploymentService,
        gmMigrationService,
        helpService,
        checklistService,
        licenseService,
        supportService,
        // migrationSourceService, migrationDestinationService,migrationService,systemService,
    };
};

export type AppServicesType = ReturnType<typeof initServices>;
// use partial because of mocking
export const appStateContext = createContext<Partial<AppServicesType> | null>(null);

export const useAppServices = () => {
    const services = useContext(appStateContext);
    if (!services) {
        throw new Error("not inside app services provider");
    }
    return services;
};

// ======================
// AppServicesProvider
// ======================
interface AppServicesProviderProps {
    children: React.ReactNode;
}

export const AppServicesProvider: React.FC<AppServicesProviderProps> = observer(({ ...p }) => {
    const store = useLocalObservable(initServices);
    return <appStateContext.Provider value={store}>{p.children}</appStateContext.Provider>;
});
