// ======================
// AppDaemons
// ======================

import { observer } from "mobx-react-lite";
import { useAppServices } from "./services";
import { useEffect } from "react";
import { startPolling } from "../../common/poller/Poller";
import { isDevelopment } from "../../common/utils/util";

import { useAuthState } from "../auth/AuthState";
import { useCurrentUser } from "../auth/auth_hooks";
import { useMountEffect } from "../../common/hooks/hookslib";

interface AppDaemonsProps {}

export const AppDaemons: React.FC<AppDaemonsProps> = observer((props) => {
    const { appUpdateCheckerService, appGlobalService } = useAppServices();
    const currentUser = useCurrentUser();

    useMountEffect(() => {
        startPolling(
            () => appGlobalService.reloadEnabledFeatureFlags(),
            60 * 5,
            () => !currentUser
        );
        startPolling(
            () => appUpdateCheckerService.reloadIfHashUpdated(),
            60,
            () => isDevelopment()
        );
    });

    return null;
});
