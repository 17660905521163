// ======================
// SessionVolumesInfo
// ======================
import { GalaxyMigrateMigrationSessionDetails, GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Divider, IconButton, LinearProgress, SvgIcon, Theme, Tooltip, Typography } from "@mui/material";
import { SyncQos } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import {
    getChangeDataMapDisabledForSession,
    getMigrationDestinationVolumeLabel,
    getMigrationSourceVolumeLabel,
    renderGMSessionStatus,
    useNavigateToQosScreen,
} from "../CmcMigrationCommon";
import Grid from "@mui/material/Grid2";
import { useDialogState } from "../../core/dialog/DialogService";
import { useIsDesktop } from "../../layout/MainLayout";
import { useAppServices } from "../../app/services";
import { isDeploymentGteVersion, isMinMtdiVersion } from "../../deployment/DeploymentCommon";
import { renderServerDataWithLoadingBox, useInitData } from "../../core/data/DataLoaderHooks";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { ActionConfig } from "../../../common/actions/CommonActions";
import { IQosIcon } from "./qosCharts/GmSessionVolumeQosScreen";
import { BitmapIcon, GmVolumeBitmapDialog } from "./GmMigrationSessionVolumeBitmap";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { FaAngleDoubleDown, FaAngleDoubleRight } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LightDivider } from "../../../common/misc";

interface SessionVolumesProgressInfoProps {
    data: GalaxyMigrateMigrationSessionDetails;
}

export const GmMigrationSessionVolumesProgressInfo: React.FC<SessionVolumesProgressInfoProps> = observer((p) => {
    return (
        <Box pt={2}>
            {p.data
                .getSessionInfo()
                .getSessionVolumesList()
                .map((v) => (
                    <SessionVolumeProgressInfo
                        stats={p.data.getSessionInfo().getSessionStats()}
                        qosLevel={p.data.getSessionInfo().getQosLevel()}
                        deploymentId={p.data.getSessionInfo().getDeployment().getSystemId()}
                        vol={v}
                        key={v.getSource().getUuid()}
                    />
                ))}
        </Box>
    );
});

// ======================
// SessionVolumeInfo
// ======================

interface SessionVolumeProgressInfoProps {
    vol: GalaxyMigrateMigrationSessionInfo.Volume;
    qosLevel: SyncQos.ImpactLevel;
    deploymentId: string;
    stats: GalaxyMigrateMigrationSessionInfo.Statistics;
}

const SessionVolumeProgressInfo: React.FC<SessionVolumeProgressInfoProps> = observer((p) => {
    const vol = p.vol;
    const sourceVolume = vol.getSource();
    const destinationVolume = vol.getDestination();
    const statistics = vol.getVolumeStats();
    const navigateToQosScreen = useNavigateToQosScreen(sourceVolume.getUuid());
    const bitmapDialogState = useDialogState();
    const isDesktop = useIsDesktop();
    const { deploymentService, gmMigrationService } = useAppServices();
    const stats = p.stats;
    const newStatsMinVersion = "5.1.0";
    const isNewStatsMinVersion = isDeploymentGteVersion(deploymentService.currentDeployment.data, newStatsMinVersion);

    const init = async () => {
        deploymentService.setCurrentDeploymentID(p.deploymentId);
        await deploymentService.currentDeployment.fetchData();
    };

    useInitData({
        init: init,
    });

    const isDeploymentWindows = deploymentService.currentDeploymentOS === MTDIDeploymentInfo.OSType.WINDOWS;

    const bitMapTooltipText =
        sourceVolume.getCapacity() > 8 * Math.pow(1024, 4) ? "Change map distribution is not available for volumes larger than 8TiB" : "View Changed Data Map";

    const volumeActions: ActionConfig[] = [
        {
            id: "iqosDetails",
            name: "View iQos Details",
            action: async () => {
                navigateToQosScreen();
            },
            disabled: p.qosLevel === SyncQos.ImpactLevel.RELENTLESS,
            icon: <IQosIcon />,
        },
        {
            id: "bitmap",
            name: bitMapTooltipText,
            action: () => {
                bitmapDialogState.open();
            },
            disabled: getChangeDataMapDisabledForSession(vol.getVolumeStatus(), deploymentService.currentDeployment.data, sourceVolume.getCapacity()),
            icon: <BitmapIcon />,
            hidden: !isMinMtdiVersion(deploymentService.currentDeployment.data, "8.5.0"),
        },
    ];

    const actionIcons = (
        <Box p={2} display={"flex"} justifyContent={"flex-end"}>
            {volumeActions
                .filter((action) => !action.hidden)
                .map((action, index) => {
                    return (
                        <Box id={index.toString()} key={index}>
                            <Tooltip title={action.name}>
                                <div>
                                    <IconButton onClick={action.action} disabled={action.disabled} hidden={action.hidden} aria-label={action.id}>
                                        {action.icon}
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Box>
                    );
                })}
        </Box>
    );

    return renderServerDataWithLoadingBox(gmMigrationService.storageConfig, (_) => {
        return (
            <Box pt={2}>
                <Card sx={{ marginBottom: (t: Theme) => t.spacing(2) }}>
                    <LinearProgress variant="determinate" value={statistics.getProgressPercentage()} />
                    <Box pt={1}>
                        <Grid container alignItems={"center"} justifyContent={"space-around"}>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 4,
                                }}
                            >
                                {getMigrationSourceVolumeLabel(sourceVolume, gmMigrationService.storageConfig.data, isDeploymentWindows)}
                                <Typography variant={"caption"} align={"center"} paragraph>
                                    {formatKnownDataType(sourceVolume.getCapacity(), KnownDataType.CAPACITY)} | {sourceVolume.getDevicePath()}
                                </Typography>
                            </Grid>
                            <Grid
                                justifyContent={"center"}
                                size={{
                                    xs: 12,
                                    sm: 1,
                                }}
                            >
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pb={1}>
                                    <SvgIcon>
                                        {isDesktop && <FaAngleDoubleRight />}
                                        {!isDesktop && <FaAngleDoubleDown />}
                                    </SvgIcon>
                                </Box>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 4,
                                }}
                            >
                                <Typography variant={"h6"} align={"center"}>
                                    {getMigrationDestinationVolumeLabel(
                                        destinationVolume,
                                        gmMigrationService.storageConfig.data,
                                        isDeploymentWindows,
                                        gmMigrationService.currentSession.data.getSessionInfo().getDestinationDeployment(),
                                        gmMigrationService.remoteStorageConfig.data
                                    )}
                                </Typography>
                                <Typography variant={"caption"} align={"center"} paragraph>
                                    {formatKnownDataType(destinationVolume.getCapacity(), KnownDataType.CAPACITY)} | {destinationVolume.getDevicePath()}
                                </Typography>
                            </Grid>
                            {isDesktop && <Grid size={2}>{actionIcons}</Grid>}
                        </Grid>
                    </Box>

                    <Divider />
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid size={3}>
                            <Typography variant={"h5"} align={"center"}>
                                {renderGMSessionStatus(vol.getVolumeStatus())}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Box p={2}>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"} component={"div"} sx={{ paddingTop: "3px" }}>
                                        {"Sync Progress"} - {statistics.getProgressPercentage().toFixed(2)}% &nbsp;
                                    </Typography>
                                    {isNewStatsMinVersion && (
                                        <Tooltip
                                            title={
                                                <Box>
                                                    <Box>
                                                        Total Transferred: &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getTotalTransferred(), KnownDataType.CAPACITY)}
                                                    </Box>
                                                    <Box>Total Sync Time: &nbsp; {formatDurationFromSeconds(statistics?.getTotalSyncTime()?.getSeconds())}</Box>
                                                    <Box>
                                                        Total Data Reduced: &nbsp; {formatKnownDataType(statistics.getTotalThinData(), KnownDataType.CAPACITY)}
                                                    </Box>
                                                </Box>
                                            }
                                            arrow={true}
                                        >
                                            <SvgIcon color={"primary"}>
                                                <AiOutlineInfoCircle />
                                            </SvgIcon>
                                        </Tooltip>
                                    )}
                                </Box>

                                <Typography variant={"subtitle1"}>
                                    {formatKnownDataType(statistics.getTotalSynced(), KnownDataType.CAPACITY)} /{" "}
                                    {formatKnownDataType(statistics.getTotalData(), KnownDataType.CAPACITY)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            <Box p={2}>
                                <Typography variant={"caption"} color={"textSecondary"} component={"div"}>
                                    {"Total Changes (New)"}
                                </Typography>
                                <Typography variant={"subtitle1"}>
                                    {formatKnownDataType(statistics.getTotalChanged(), KnownDataType.CAPACITY)} (
                                    {formatKnownDataType(statistics.getCurrentChanged(), KnownDataType.CAPACITY)})
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            <Box p={2}>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Typography variant={"caption"} color={"textSecondary"} component={"div"} sx={{ paddingTop: "3px" }}>
                                        {"Migration Rate"} &nbsp;
                                    </Typography>
                                    {isNewStatsMinVersion && (
                                        <Tooltip
                                            title={
                                                <Box>
                                                    <Box>
                                                        Current Migration Rate: &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getCurrentThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                    <Box>
                                                        Current Transfer Rate: &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getCurrentTransferThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                    <Box>
                                                        Average Migration Rate: &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getAverageThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                    <Box>
                                                        Average Transfer Rate: &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getAverageTransferThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                    <Box>
                                                        Recent Migration Rate (60s): &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getRecentThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                    <Box>
                                                        Recent Transfer Rate (60s): &nbsp;{" "}
                                                        {formatKnownDataType(statistics.getRecentTransferThroughput(), KnownDataType.THROUGHPUT)}
                                                    </Box>
                                                </Box>
                                            }
                                            arrow={true}
                                        >
                                            <SvgIcon color={"primary"}>
                                                <AiOutlineInfoCircle />
                                            </SvgIcon>
                                        </Tooltip>
                                    )}
                                </Box>

                                <Typography variant={"subtitle1"}>
                                    {formatKnownDataType(statistics.getCurrentThroughput(), KnownDataType.THROUGHPUT)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid>
                            <Box p={2}>
                                <Typography variant={"caption"} color={"textSecondary"} component={"div"}>
                                    {"Remaining"}
                                </Typography>
                                <Typography variant={"subtitle1"}>{formatKnownDataType(statistics.getTotalRemaining(), KnownDataType.CAPACITY)}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {vol.getVolumeErrorText() && vol.getVolumeStatus() !== GalaxyMigrateMigrationSessionInfo.Status.COMPLETED && (
                        <>
                            <br />
                            <Box p={2}>
                                <Typography variant={"subtitle1"} color={"error"}>
                                    {vol.getVolumeErrorText()}
                                </Typography>
                            </Box>
                        </>
                    )}
                    {!isDesktop && (
                        <>
                            <LightDivider />
                            {actionIcons}
                        </>
                    )}
                </Card>
                <GmVolumeBitmapDialog dialogState={bitmapDialogState} stats={stats} sourceVol={sourceVolume} status={vol.getVolumeStatus()} />
            </Box>
        );
    });
});
