import { observer } from "mobx-react-lite";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { Box, Button, Card, ListSubheader, Theme, Typography, useTheme } from "@mui/material";
import { GalaxyMigrateDeploymentHostLicenseInfoCard } from "./GalaxyMigrateDeploymentHostLicenseInfoCard";
import { FormattedInfoBox } from "../../../common/card/FormattedInfoBox";
import { MdVpnKey } from "react-icons/md";
import * as React from "react";
import { useAppServices } from "../../app/services";
import { AppHintID } from "../../help/HelpCommon";
import { HostLicenseTransactionSection } from "./GalaxyMigrateDeploymentHostLicenseTransaction";
import { useOpenHelpSearch } from "../../help/hooks/help_hooks";

// ======================
// HostLicenseScreen
// ======================

interface GalaxyMigrateDeploymentHostLicenseScreenProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentHostLicenseScreen: React.FC<GalaxyMigrateDeploymentHostLicenseScreenProps> = observer((p) => {
    const licenseInfo = p.deployment.getInfo().getDeployment().getLicense();
    return (
        <Box pt={2}>
            {!licenseInfo && <NoLicenseCard />}
            <GalaxyMigrateDeploymentHostLicenseInfoCard data={licenseInfo} />
            <br />
            <HostLicenseTransactionSection />
        </Box>
    );
});

// ======================
// NoLicenseCard
// ======================

interface NoLicenseCardProps {}

const NoLicenseCard: React.FC<NoLicenseCardProps> = observer((p) => {
    const openHelpSearch = useOpenHelpSearch();

    return (
        <Card>
            <ListSubheader>Host License Status</ListSubheader>
            <FormattedInfoBox
                boxWrapperProps={{ pb: 4 }}
                icon={<MdVpnKey size={"4em"} />}
                title={`This host does not have an activated license`}
                message={`License will be activated during migration session creation`}
                action={
                    <Button variant={"outlined"} color={"neutral"} onClick={() => openHelpSearch(AppHintID.LICENSE_KEY)}>
                        Learn More
                    </Button>
                }
            />
        </Card>
    );
});
