import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Alert, Box, Button, Link, Typography } from "@mui/material";
import { CirrusGradient } from "../app/AppTheme";
import Logo from "../../assets/Cirrus_Data_Cloud_Logo_Large.png";
import { CirrusFooter } from "../layout/Footer";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormTextField } from "../../common/form/FormComponents";
import { PrivacyPolicyLink, TermsOfServiceLink } from "../help/HelpCommon";
import { BsCheck2All } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { AWS_MARKETPLACE_TOKEN, useNavigateToPreLogin } from "./AuthCommon";
import { BackButton } from "../../common/CommonButtons";
import { useQueryParams } from "../app/AppRoutes";
import { useSignUp } from "./auth_hooks";
import { Signup } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import Grid from "@mui/material/Grid2";

// ======================
// UserSignUpScreen
// ======================

interface UserSignUpScreenProps {
    loginRedirectFunction?: () => void;
    awsToken?: string;
    azureToken?: string;
    navigateBackFunction?: () => void;
    prefilledEmail?: string;
}

export const UserSignUpScreen: React.FC<UserSignUpScreenProps> = observer((p) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [approvalRequired, setApprovalRequired] = useState(true);
    const [signupEmail, setSignupEmail] = useState("");
    const query = useQueryParams();
    const promo = query.get("promo");
    return (
        <Box
            sx={{
                background: CirrusGradient,
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                sx={{
                    height: "100%",
                }}
            >
                <Grid
                    pl={5}
                    pr={5}
                    pb={10}
                    size={{
                        xs: 12,
                        md: 6,
                        xl: 4,
                    }}
                >
                    <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                        <Box maxWidth={500}>
                            {!!p.navigateBackFunction && !formSubmitted && (
                                <Box>
                                    <BackButton navFunction={p.navigateBackFunction} label={"Back"} />
                                </Box>
                            )}
                            <Box p={3} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                                    <img src={Logo} style={{ maxWidth: "330px", width: "auto" }} alt={"logo"} />
                                </Box>
                            </Box>
                            {!formSubmitted && (
                                <UserSignUpForm
                                    loginRedirectFunction={p.loginRedirectFunction}
                                    awsToken={p.awsToken}
                                    azureToken={p.azureToken}
                                    prefilledEmail={p.prefilledEmail}
                                    onSubmit={(email: string, approvalRequired: boolean) => {
                                        setFormSubmitted(true);
                                        setSignupEmail(email);
                                        setApprovalRequired(approvalRequired);
                                    }}
                                />
                            )}

                            {formSubmitted && <PostSignUpScreen approvalRequired={approvalRequired} email={signupEmail} />}
                        </Box>
                    </Box>
                </Grid>
                {!!promo && (
                    <Grid
                        item
                        sx={{
                            height: `100%`,
                            width: "100%",
                        }}
                        pb={"64px"}
                        size={{
                            xs: 12,
                            md: 6,
                            xl: 8,
                        }}
                    >
                        <iframe title={"Promotion"} src={`https://action.cirrusdata.com/${promo}`} height={"100%"} width={"100%"} style={{ border: "none" }} />
                    </Grid>
                )}
            </Grid>
            <CirrusFooter />
        </Box>
    );
});

// ======================
// UserSignUpForm
// ======================

interface UserSignUpFormProps {
    onSubmit: (email: string, approvalRequired: boolean) => void;
    loginRedirectFunction?: () => void;
    awsToken?: string;
    azureToken?: string;
    prefilledEmail?: string;
}

const UserSignUpForm: React.FC<UserSignUpFormProps> = observer((p) => {
    const goToLogin = useNavigateToPreLogin(window.location.search);
    const signUp = useSignUp();

    const initialValues = {
        firstName: "",
        lastName: "",
        email: p.prefilledEmail || "",
        companyName: "",
        jobTitle: "",
        awsToken: p.awsToken,
        azureToken: p.azureToken,
    };

    const validationSchema = yup.object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().required(),
        companyName: yup.string(),
        jobTitle: yup.string(),
        awsToken: yup.string(),
        azureToken: yup.string(),
    });

    return (
        <Box>
            <Box textAlign={"center"} pb={4}>
                <Typography variant={"h5"}>New Account Sign Up</Typography>
            </Box>
            {!!p.awsToken && (
                <Box pb={4}>
                    <Alert severity={"info"}>Once connected, all usage will be billed through AWS Marketplace.</Alert>
                </Box>
            )}
            {!!p.azureToken && (
                <Box pb={4}>
                    <Alert severity={"info"}>
                        All usage from this account will be billed through Azure Marketplace. Depending on Microsoft Azure's processing time, this subscription
                        activation time may take up to a few minutes.
                    </Alert>
                </Box>
            )}
            <Formik
                onSubmit={async (values) => {
                    const req = new Signup.Request()
                        .setEmail(values.email)
                        .setCompanyName(values.companyName)
                        .setJobTitle(values.jobTitle)
                        .setFirstName(values.firstName)
                        .setLastName(values.lastName)
                        .setAzureMarketplacePairToken(values.azureToken)
                        .setAwsMarketplacePairToken(values.awsToken);
                    const response = await signUp.mutateAsync(req);
                    p.onSubmit(values.email, response.getApprovalRequired());
                }}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(props) => {
                    return (
                        <Form>
                            <Box display={"flex"} pb={2}>
                                <Box pr={1} width={"100%"}>
                                    <FormTextField label={"First Name"} name={"firstName"} required fullWidth />
                                </Box>
                                <Box pl={1} width={"100%"}>
                                    <FormTextField label={"Last Name"} name={"lastName"} required fullWidth />
                                </Box>
                            </Box>
                            <Box pb={2}>
                                <FormTextField label={"Work Email"} name={"email"} required disabled={!!p.prefilledEmail} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField label={"Company Name"} name={"companyName"} />
                            </Box>
                            <Box pb={2}>
                                <FormTextField label={"Job Title"} name={"jobTitle"} />
                            </Box>
                            <Box textAlign={"center"}>
                                <Typography variant={"body2"}>By continuing, you agree to the platform's</Typography>
                                <Typography variant={"body2"}>
                                    <TermsOfServiceLink variant={"body2"} />
                                    &nbsp;and&nbsp;
                                    <PrivacyPolicyLink variant={"body2"} />
                                </Typography>
                            </Box>
                            <br />
                            <Box width={"100%"}>
                                <Button type={"submit"} variant={"contained"} fullWidth>
                                    Sign Up
                                </Button>
                            </Box>
                            <input name={AWS_MARKETPLACE_TOKEN} hidden readOnly value={props.values.awsToken} type={"text"} />
                            <br />
                            {!p.azureToken && !p.awsToken && (
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Typography variant={"body2"}>
                                        Already have an account?&nbsp;
                                        <Link onClick={p.loginRedirectFunction || goToLogin}>Log In</Link>
                                    </Typography>
                                </Box>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
});

// ======================
// PostSignUpScreen
// ======================

interface PostSignUpScreenProps {
    approvalRequired: boolean;
    email: string;
}

export const PostSignUpScreen: React.FC<PostSignUpScreenProps> = observer((p) => {
    if (p.approvalRequired) {
        return (
            <Box>
                <Typography variant={"h5"} textAlign={"center"}>
                    Thank you for Signing Up
                </Typography>
                <Box display={"flex"} justifyContent={"center"} p={4}>
                    <BsCheck2All size={"4em"} />
                </Box>
                <Typography textAlign={"center"}>Once your account is ready, an account setup email will be sent to {p.email}.</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant={"h5"} textAlign={"center"}>
                Account Setup Email Sent
            </Typography>
            <Box display={"flex"} justifyContent={"center"} p={4}>
                <MdOutlineEmail size={"4em"} />
            </Box>
            <Typography textAlign={"center"}>
                Thank you for signing up! We've sent an email to {p.email}.
                <br />
                <br />
                To continue and get started with your account setup, please check your email inbox.
            </Typography>
        </Box>
    );
});
