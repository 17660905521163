// ======================
// OperationTasksTab
// ======================

import { useApproveOperationTask, useGetOperationTaskFacts } from "../migrate_ops_hooks";
import {
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    FormControlLabel,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { SelectableBox, SelectableBoxExpansionContent } from "../../../common/card/SelectableCard";
import { getMigrateOpStatusColor, getMigrateOpStatusIcon, getMigrateOpStatusLabel, MigrateOpsStatusIcon } from "../MigrateOpsCommon";
import { differenceInSeconds, isAfter } from "date-fns";
import {
    convertDateObjectToTimestamp,
    convertTimestampObjectToDate,
    formatKnownDataType,
    formatProtoDurationObject,
    KnownDataType,
} from "../../../common/utils/formatter";
import { MigrateOpsTaskLog } from "./MigrateOpsTaskLog";
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { OperationDetails, OperationRecipeDescriptor, OperationTaskInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { ApproveOperationTask } from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { IoMdListBox } from "react-icons/io";
import { useIsSupportUser } from "../../support/SupportCommon";
import { CodeCard } from "../../../common/card/DarkCard";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { OperatorView, useIsOperatorView } from "../../auth/AuthenticatedViews";
import Grid from "@mui/material/Grid2";

interface MigrateOpsTasksTabProps {
    data: OperationDetails.AsObject;
    setQueryInterval: (interval: number) => void;
    loggingQueryInterval: number;
}

export const MigrateOpsTasksTab: React.FC<MigrateOpsTasksTabProps> = (p) => {
    const { data, setQueryInterval, loggingQueryInterval } = p;
    const groupedTasksList = useMemo(() => groupTasksByKind(data.allTasksList), [data.allTasksList]);
    const phases = groupTasksIntoPhases(groupedTasksList, data.info.recipe);
    const [selectedTask, setSelectedTask] = React.useState<number>(data.info.currentStep);
    const currentSelectedTaskRef = useRef(null);
    useEffect(() => {
        const includedStatus = [
            OperationStatus.OperationStatus.RUNNING,
            OperationStatus.OperationStatus.WAITING,
            OperationStatus.OperationStatus.PENDING,
            OperationStatus.OperationStatus.QUEUED,
        ];
        if (includedStatus.includes(data.info.status)) {
            setQueryInterval(3 * 1000);
        } else {
            setQueryInterval(60 * 1000);
        }
    }, [data.info.status, setQueryInterval]);

    useEffect(() => {
        if (!!currentSelectedTaskRef.current) {
            const { top } = currentSelectedTaskRef.current.getBoundingClientRect();
            window.scrollTo({ top: currentSelectedTaskRef.current.offsetTop - 200, behavior: "smooth" });
        }
    }, [data.info.currentStep, data.allTasksList, setSelectedTask]);

    return (
        <Box>
            <Box pt={2}>
                <Grid container>
                    {Object.keys(groupedTasksList).map((kind, i) => {
                        const latestAttempt = groupedTasksList[kind].find((t) => t.isLatestAttempt);
                        const stepRecipe = data.info.recipe.tasksList.find((t) => t.kind === latestAttempt.kind);
                        return (
                            <Grid key={latestAttempt.kind}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <BlackTooltip
                                        arrow
                                        title={
                                            <Box>
                                                <Typography fontWeight={600}>{stepRecipe?.name}</Typography>
                                                <Typography>
                                                    {"Status: "}
                                                    {getMigrateOpStatusLabel(latestAttempt.status)}
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setSelectedTask(latestAttempt.stepNumber);
                                            }}
                                            sx={{
                                                width: "50px",
                                                height: "50px",
                                            }}
                                        >
                                            <Box pt={"3px"}>
                                                <MigrateOpsStatusIcon status={latestAttempt.status} size={40} />
                                            </Box>
                                        </IconButton>
                                    </BlackTooltip>
                                    {i !== data.allTasksList.length - 1 && <Box width={15} height={"1px"} sx={{ background: "rgba(255,255,255,.3)" }} />}
                                </Stack>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            <OperationProgressTimeline
                taskPhases={phases}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                currentSelectedTaskRef={currentSelectedTaskRef}
                operationRecipe={data.info.recipe}
                opId={data.info.id}
                loggingQueryInterval={loggingQueryInterval}
            />
        </Box>
    );
};

// ======================
// OperationProgressTimeline
// ======================

interface OperationProgressTimelineProps {
    taskPhases: TasksListGroupedByPhases;
    selectedTask: number;
    setSelectedTask: (taskNumber: number) => void;
    currentSelectedTaskRef: MutableRefObject<any>;
    operationRecipe: OperationRecipeDescriptor.AsObject;
    opId: number;
    loggingQueryInterval: number;
}

const OperationProgressTimeline: React.FC<OperationProgressTimelineProps> = (p) => {
    const { operationRecipe, currentSelectedTaskRef, selectedTask, setSelectedTask, opId, loggingQueryInterval, taskPhases } = p;
    const boxRef = useRef(null);
    const [bottomOffset, setBottomOffset] = useState(0);
    const [windowSize, setWindowSize] = useState(0);
    const [bottomOfRect, setBottomOfRect] = useState(null);
    const timelineRef = useRef(null);
    const [firstPageLoaded, setFirstPageLoaded] = useState(false);

    const scrollToTopOfSelectedTimelineItem = useCallback(() => {
        const { top } = currentSelectedTaskRef.current.getBoundingClientRect();
        const scrollTop = top > windowSize ? top + bottomOfRect : top;

        timelineRef.current.scrollTo({
            top: top,
        });
    }, [currentSelectedTaskRef, windowSize, bottomOfRect, timelineRef]);

    // useEffect(() => {
    //     if (boxRef.current) {
    //         const { bottom } = boxRef.current.getBoundingClientRect();
    //         setBottomOfRect(bottom);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (currentSelectedTaskRef.current && timelineRef.current && !!bottomOfRect && !!selectedTask && !!bottomOffset && !firstPageLoaded) {
    //         const { top } = currentSelectedTaskRef.current.getBoundingClientRect();
    //         const scrollTop = top > windowSize ? top + bottomOfRect : top;
    //
    //         timelineRef.current.scrollTo({
    //             top: scrollTop,
    //         });
    //
    //         if (bottomOffset !== windowSize) {
    //             setFirstPageLoaded(true);
    //         }
    //     }
    // }, [currentSelectedTaskRef, firstPageLoaded, bottomOffset, timelineRef, selectedTask, bottomOfRect, windowSize]);

    // useEffect(() => {
    //     const calculateBottomOffset = () => {
    //         if (boxRef.current) {
    //             const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    //             const windowHeight = window.innerHeight;
    //             const bottomOffset = scrollPosition < bottomOfRect ? windowHeight - Math.abs(bottomOfRect - scrollPosition) : windowHeight + bottomOfRect;
    //             setBottomOffset(bottomOffset);
    //             setWindowSize(windowHeight);
    //         }
    //     };
    //
    //     // Call the function initially
    //     calculateBottomOffset();
    //
    //     // Attach a resize event listener to update the position on window resize
    //     window.addEventListener("resize", calculateBottomOffset);
    //     window.addEventListener("scroll", calculateBottomOffset);
    //
    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //         window.removeEventListener("resize", calculateBottomOffset);
    //         window.removeEventListener("scroll", calculateBottomOffset);
    //     };
    // }, [bottomOfRect]);

    return (
        <Box>
            <div ref={boxRef} style={{ width: "1px", height: "1px", backgroundColor: "none" }}></div>
            <Box
                pt={1}
                //ref={timelineRef}
                // sx={{
                //     height: bottomOffset,
                //     overflowY: "scroll",
                //     "&::-webkit-scrollbar": {
                //         display: "none",
                //     },
                // }}
            >
                <Stack direction={"column"} pt={1} pr={2} pb={4}>
                    {taskPhases.map((phase) => {
                        return (
                            <Stack key={phase.phaseName}>
                                <Box display={"flex"} pb={2}>
                                    <Typography color={"textSecondary"} variant={"h5"} fontWeight={600}>
                                        {"Phase: "}&nbsp;
                                    </Typography>
                                    <Typography variant={"h5"} fontWeight={600}>
                                        {phase.phaseName}
                                    </Typography>
                                </Box>
                                <Box pb={3}>
                                    {Object.keys(phase.tasks).map((kind, index) => {
                                        return (
                                            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} key={index}>
                                                <TaskTimelineItem
                                                    currentSelectedTaskRef={currentSelectedTaskRef}
                                                    setSelectedTask={setSelectedTask}
                                                    selectedTaskNumber={selectedTask}
                                                    operationRecipe={operationRecipe}
                                                    opId={opId}
                                                    scrollToTop={scrollToTopOfSelectedTimelineItem}
                                                    loggingQueryInterval={loggingQueryInterval}
                                                    taskAttempts={phase.tasks[kind]}
                                                    taskInfo={phase.tasks[kind]?.find((task) => task.isLatestAttempt)}
                                                />
                                                {Object.keys(phase.tasks).length - 1 === index ? null : (
                                                    <Box height={20} width={"100%"} display={"flex"} justifyContent={"center"}>
                                                        <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} orientation={"vertical"} />
                                                    </Box>
                                                )}
                                            </Stack>
                                        );
                                    })}
                                </Box>
                            </Stack>
                        );
                    })}
                </Stack>
            </Box>
        </Box>
    );
};

// ======================
// TaskTimelineItem
// ======================

interface TaskTimelineItemProps {
    setSelectedTask: (task: number) => void;
    selectedTaskNumber: number;
    taskInfo: OperationTaskInfo.AsObject;
    currentSelectedTaskRef: MutableRefObject<any>;
    operationRecipe: OperationRecipeDescriptor.AsObject;
    opId: number;
    taskAttempts: OperationTaskInfo.AsObject[];
    loggingQueryInterval: number;
    scrollToTop: () => void;
}

const TaskTimelineItem: React.FC<TaskTimelineItemProps> = (p) => {
    const theme = useTheme();
    const { currentSelectedTaskRef, operationRecipe, selectedTaskNumber, setSelectedTask, taskInfo, taskAttempts, opId, loggingQueryInterval, scrollToTop } = p;
    const taskRecipe = operationRecipe.tasksList.find((t) => t.kind === taskInfo.kind);
    const selected = selectedTaskNumber === taskInfo.stepNumber;
    const isSupportUser = useIsSupportUser();
    const taskFactsDialogState = useDialogState();

    return (
        <Box width={"100%"} ref={selectedTaskNumber === taskInfo.stepNumber ? currentSelectedTaskRef : null}>
            <SelectableBox
                cardProps={{ sx: { backgroundColor: theme.palette.cirrus.light, width: "100%" } }}
                selected={selected}
                onSelect={() => {
                    setSelectedTask(taskInfo.stepNumber);
                }}
                onDeselect={() => {
                    setSelectedTask(null);
                }}
            >
                <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"} pt={2} pr={2} pl={2} pb={selected ? 0 : 2}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"} pt={2} pr={2} pl={2} pb={selected ? 0 : 2}>
                        <Typography color={"textSecondary"}>{taskInfo.stepNumber}</Typography>
                        <MigrateOpsStatusIcon status={taskInfo.status} size={32} tooltip />
                        <Box>
                            <Stack direction={"row"} alignItems={"center"} spacing={1} pb={1}>
                                <Typography fontWeight={600}>{taskRecipe?.name}</Typography>
                                {taskInfo.approvalRequired && !taskInfo.approved ? <Chip size={"small"} label={"Approval Required"} color={"warning"} /> : null}
                            </Stack>
                            <Typography>{taskRecipe?.description}</Typography>
                            {!!taskInfo.approvedAt ? (
                                <Typography variant={"caption"} fontStyle={"italic"} color={"textSecondary"}>
                                    Approved {formatKnownDataType(convertTimestampObjectToDate(taskInfo.approvedAt), KnownDataType.DATE_RELATIVE)}
                                </Typography>
                            ) : null}
                        </Box>
                    </Stack>
                    {isSupportUser && (
                        <Tooltip title={"View Task Facts"}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    taskFactsDialogState.open();
                                }}
                            >
                                <IoMdListBox />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                <SelectableBoxExpansionContent>
                    <TaskDetailsSection
                        selectedTaskInfo={taskInfo}
                        opId={opId}
                        taskAttempts={taskAttempts}
                        operationRecipe={operationRecipe}
                        loggingQueryInterval={loggingQueryInterval}
                    />
                </SelectableBoxExpansionContent>
            </SelectableBox>
            {taskFactsDialogState.isOpen && <ViewTaskFactsDialog dialogState={taskFactsDialogState} taskId={taskInfo.taskId} />}
        </Box>
    );
};

// ======================
// TaskDetailsCard
// ======================
interface TaskDetailsSectionProps {
    selectedTaskInfo: OperationTaskInfo.AsObject;
    opId: number;
    taskAttempts: OperationTaskInfo.AsObject[];
    operationRecipe: OperationRecipeDescriptor.AsObject;
    loggingQueryInterval: number;
}

const TaskDetailsSection: React.FC<TaskDetailsSectionProps> = (p) => {
    const { selectedTaskInfo, loggingQueryInterval, operationRecipe, opId, taskAttempts } = p;
    const [currentViewedPastAttempt, setCurrentViewedPastAttempt] = useState<OperationTaskInfo.AsObject>(null);

    const otherAttemptsDialogState = useDialogState();

    return (
        <Box width={"100%"} pt={2}>
            <Divider sx={{ borderColor: "rgba(255, 255, 255, .3)" }} />
            <SelectedTaskInfo
                selectedTaskInfo={currentViewedPastAttempt ?? selectedTaskInfo}
                otherAttemptsDialogState={otherAttemptsDialogState}
                opId={opId}
                loggingQueryInterval={loggingQueryInterval}
                taskAttempts={taskAttempts}
            />
            {otherAttemptsDialogState.isOpen && (
                <SelectTaskAttemptDialog
                    taskAttempts={taskAttempts}
                    setSelectedTaskAttempt={setCurrentViewedPastAttempt}
                    currentAttempt={currentViewedPastAttempt ?? selectedTaskInfo}
                    dialogState={otherAttemptsDialogState}
                />
            )}
        </Box>
    );
};

// ======================
// TaskDetailsInfo
// ======================

interface SelectedTaskInfoProps {
    selectedTaskInfo: OperationTaskInfo.AsObject;
    otherAttemptsDialogState: DialogState;
    opId: number;
    loggingQueryInterval: number;
    taskAttempts: OperationTaskInfo.AsObject[];
}

export const SelectedTaskInfo: React.FC<SelectedTaskInfoProps> = (p) => {
    const { selectedTaskInfo, otherAttemptsDialogState, opId, loggingQueryInterval, taskAttempts } = p;

    const taskDetailInfo = (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography variant={"subtitle2"} color={"textSecondary"}>
                {`#${selectedTaskInfo?.taskId}`}
            </Typography>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                    {`Time Elapsed: ${
                        !selectedTaskInfo.startedAt
                            ? "N/A"
                            : selectedTaskInfo?.endedAt
                            ? formatProtoDurationObject({
                                  seconds: differenceInSeconds(
                                      convertTimestampObjectToDate(selectedTaskInfo?.endedAt),
                                      convertTimestampObjectToDate(selectedTaskInfo?.startedAt)
                                  ),
                                  nanos: 0,
                              })
                            : formatProtoDurationObject({
                                  seconds: differenceInSeconds(new Date(), convertTimestampObjectToDate(selectedTaskInfo?.startedAt)),
                                  nanos: 0,
                              })
                    }`}
                </Typography>
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                    {` | `}
                </Typography>
                <Typography variant={"subtitle2"} color={"textSecondary"}>
                    {!selectedTaskInfo.startedAt
                        ? "Pending Start"
                        : `Started ${formatKnownDataType(convertTimestampObjectToDate(selectedTaskInfo?.startedAt), KnownDataType.DATE)}`}
                </Typography>
                {selectedTaskInfo?.endedAt && (
                    <>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {` | `}
                        </Typography>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {`Ended ${formatKnownDataType(convertTimestampObjectToDate(selectedTaskInfo?.endedAt), KnownDataType.DATE)}`}
                        </Typography>
                    </>
                )}
            </Stack>
        </Stack>
    );
    const notLatestAttemptAlert = !selectedTaskInfo.isLatestAttempt ? (
        <Alert
            severity={"warning"}
            sx={{ mb: 2 }}
            action={
                <Button color={"warning"} onClick={() => otherAttemptsDialogState.open()}>
                    {`View Other Attempts`}
                </Button>
            }
        >
            {`You are currently viewing a previous attempt at ${formatKnownDataType(
                convertTimestampObjectToDate(selectedTaskInfo.startedAt),
                KnownDataType.DATE
            )}`}
        </Alert>
    ) : null;

    return (
        <>
            <Box pt={2} pr={2} pl={2}>
                {notLatestAttemptAlert}
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box>{taskDetailInfo}</Box>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <SelectTaskAttemptDialogButton dialogState={otherAttemptsDialogState} taskAttempts={taskAttempts} currentAttempt={selectedTaskInfo} />
                    </Stack>
                </Stack>
            </Box>
            <Box p={2}>
                {selectedTaskInfo.approvalRequired && !selectedTaskInfo.approved ? (
                    <UserTaskApprovalForm taskId={selectedTaskInfo.taskId} approvalMessage={selectedTaskInfo.approvalRequestMessage} />
                ) : (
                    <MigrateOpsTaskLog opId={opId} taskId={selectedTaskInfo?.taskId} queryInterval={loggingQueryInterval} />
                )}
            </Box>
        </>
    );
};

// ======================
// UserTaskApprovalForm
// ======================

interface UserTaskApprovalFormProps {
    taskId: number;
    approvalMessage: string;
}

const UserTaskApprovalForm: React.FC<UserTaskApprovalFormProps> = (p) => {
    const { taskId, approvalMessage } = p;
    const approveTask = useApproveOperationTask();
    const [approvalNotes, setApprovalNotes] = useState<string>("");
    const [useApprovalWindow, setUseApprovalWindow] = useState(false);
    const [approvalStartTime, setApprovalStartTime] = useState<Date>(null);
    const [approvalEndTime, setApprovalEndTime] = useState<Date>(null);
    const isOperator = useIsOperatorView();
    const addConfirmDialog = useGlobalDialogState((s) => s.addConfirmDialog);
    const handleSubmit = async () => {
        const req = new ApproveOperationTask.Request().setNotes(approvalNotes).setOperationTaskId(taskId);
        if (!!approvalStartTime) {
            req.setApprovalStart(convertDateObjectToTimestamp(approvalStartTime));
        }
        if (!!approvalEndTime) {
            req.setApprovalEnd(convertDateObjectToTimestamp(approvalEndTime));
        }
        const confirmed = await addConfirmDialog({
            title: "Confirm Approve Task",
            message: "Operation will resume once you approve this task. Are you sure you want to continue?",
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            await approveTask.mutateAsync(req);
        }
    };

    const getApprovalWindowError = () => {
        if (!!approvalEndTime && !!approvalStartTime) {
            return !isAfter(approvalEndTime, approvalStartTime);
        }
        return false;
    };

    if (!isOperator) {
        return <Alert severity={"warning"}>{"This task needs to be approved by a team member with approval privileges."}</Alert>;
    }

    return (
        <>
            <OperatorView>
                <Box>
                    <Typography variant={"h5"}>{"Approval is Required"}</Typography>
                    <br />
                    <Typography variant={"body1"}>{approvalMessage}</Typography>
                    <Box pt={2} pb={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useApprovalWindow}
                                    onChange={(e) => {
                                        setUseApprovalWindow(e.target.checked);
                                        setApprovalStartTime(new Date());
                                    }}
                                    color={"secondary"}
                                />
                            }
                            label={"Set up an approval window"}
                        />
                    </Box>
                    {useApprovalWindow && (
                        <Box pt={2} pb={2}>
                            <Grid container spacing={2}>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 6,
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            slotProps={{
                                                textField: {
                                                    error: getApprovalWindowError(),
                                                    helperText: getApprovalWindowError() ? "End time must be after start time" : "",
                                                    fullWidth: true,
                                                    variant: "filled",
                                                },
                                            }}
                                            label={"Approval Start Time"}
                                            value={approvalStartTime}
                                            onChange={(e) => setApprovalStartTime(e)}
                                            disablePast={true}
                                            // onError={(reason, value) => {
                                            //     let errorMessage: string = reason;
                                            //
                                            //     setDateTimePickerError(errorMessage);
                                            // }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 6,
                                    }}
                                >
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <MobileDateTimePicker
                                            slotProps={{
                                                textField: {
                                                    error: getApprovalWindowError(),
                                                    helperText: getApprovalWindowError() ? "End time must be after start time" : "",
                                                    fullWidth: true,
                                                    variant: "filled",
                                                },
                                            }}
                                            label={"Aproval End Time"}
                                            value={approvalEndTime}
                                            onChange={(e) => setApprovalEndTime(e)}
                                            disablePast={true}
                                            // onError={(reason, value) => {
                                            //     let errorMessage: string = reason;
                                            //
                                            //     setDateTimePickerError(errorMessage);
                                            // }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box pt={2} pb={2}>
                        <TextField
                            variant={"filled"}
                            label={"Add your approval notes here for future reference"}
                            fullWidth
                            multiline
                            rows={4}
                            value={approvalNotes}
                            onChange={(e) => setApprovalNotes(e.target.value)}
                        />
                    </Box>
                    <Box pb={2}>
                        <Button variant={"contained"} color={"success"} onClick={handleSubmit} sx={{ color: "white" }}>
                            {"Approve"}
                        </Button>
                    </Box>
                </Box>
            </OperatorView>
        </>
    );
};

// ======================
// SelectTaskAttemptDialogButton
// ======================

interface SelectTaskAttemptDialogButtonProps {
    taskAttempts: OperationTaskInfo.AsObject[];
    dialogState: DialogState;
    currentAttempt: OperationTaskInfo.AsObject;
}

const SelectTaskAttemptDialogButton: React.FC<SelectTaskAttemptDialogButtonProps> = (p) => {
    const { taskAttempts, dialogState, currentAttempt } = p;
    const hasMoreThanOneAttempt = taskAttempts?.length > 1;
    const isLatestAttempt = currentAttempt.isLatestAttempt;
    return (
        <>
            {hasMoreThanOneAttempt && isLatestAttempt && (
                <Tooltip title={"View Other Attempts"}>
                    <IconButton onClick={() => dialogState.open()}>
                        <RxCounterClockwiseClock size={24} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

// ======================
// SelectTaskAttemptDialog
// ======================

interface SelectTaskAttemptDialogProps {
    taskAttempts: OperationTaskInfo.AsObject[];
    dialogState: DialogState;
    currentAttempt: OperationTaskInfo.AsObject;
    setSelectedTaskAttempt: (taskAttempt: OperationTaskInfo.AsObject) => void;
}

const SelectTaskAttemptDialog: React.FC<SelectTaskAttemptDialogProps> = (p) => {
    const { taskAttempts, dialogState, currentAttempt, setSelectedTaskAttempt } = p;
    const isDialogFullScreen = useShouldDialogFullScreen();
    const theme = useTheme();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"sm"} fullScreen={isDialogFullScreen}>
            <ListSubheader>{"Select to view other attempts."}</ListSubheader>
            <List>
                {taskAttempts.map((taskAttempt) => {
                    return (
                        <ListItem
                            key={taskAttempt.taskId}
                            onClick={() => {
                                setSelectedTaskAttempt(taskAttempt);
                                dialogState.close();
                            }}
                            button
                        >
                            <ListItemIcon>
                                {getMigrateOpStatusIcon(taskAttempt.status, {
                                    size: 24,
                                    color: getMigrateOpStatusColor(taskAttempt.status)(theme),
                                })}
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                        <Typography>{formatKnownDataType(convertTimestampObjectToDate(taskAttempt.startedAt), KnownDataType.DATE)}</Typography>
                                        {taskAttempt.taskId === currentAttempt.taskId && <Chip label={"Currently Viewing"} size={"small"} />}
                                    </Stack>
                                }
                                secondary={taskAttempt.isLatestAttempt ? "Latest Attempt" : null}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Dialog>
    );
};

// ======================
// ViewTaskFactsDialog
// ======================

interface ViewTaskFactsDialogProps {
    dialogState: DialogState;
    taskId: number;
}

export const ViewTaskFactsDialog: React.FC<ViewTaskFactsDialogProps> = (p) => {
    const { dialogState, taskId } = p;
    const queryResult = useGetOperationTaskFacts(taskId);
    const isDialogFullScreen = useShouldDialogFullScreen();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"md"} fullScreen={isDialogFullScreen}>
            <DialogTopBar divider={true} dialogState={dialogState} title={"Task Facts"} />
            <DialogContent>
                <CodeCard>
                    <Box
                        p={2}
                        width={"100%"}
                        sx={{
                            wordBreak: "break-word",
                            overflowX: "scroll",
                            "&::-webkit-scrollbar": {
                                width: "10px",
                                backgroundColor: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                borderRadius: "10px",
                                border: "2px solid transparent",
                                backgroundColor: "rgba(255,255,255,.1)",
                                backgroundClip: `content-box`,
                            },
                        }}
                    >
                        <QueryResultWrapper queryResult={queryResult}>
                            <Box width={"100%"}>
                                <pre>{JSON.stringify(JSON.parse(queryResult.data?.taskFactJson || "{}"), null, 2)}</pre>
                            </Box>
                        </QueryResultWrapper>
                    </Box>
                </CodeCard>
            </DialogContent>
        </Dialog>
    );
};

interface TasksListGroupedByAttempts {
    [key: string]: OperationTaskInfo.AsObject[];
}

const groupTasksByKind = (tasksList: OperationTaskInfo.AsObject[]) => {
    const groupedTasksList: TasksListGroupedByAttempts = {};
    tasksList.forEach((task) => {
        if (!groupedTasksList[task.kind]) {
            groupedTasksList[task.kind] = [];
        }
        groupedTasksList[task.kind].push(task);
    });
    return groupedTasksList;
};

type TasksListGroupedByPhases = Array<{ phaseName: string; tasks: TasksListGroupedByAttempts }>;
const groupTasksIntoPhases = (tasksGroupedByKind: TasksListGroupedByAttempts, recipe: OperationRecipeDescriptor.AsObject) => {
    const tasksGroupedByPhase: TasksListGroupedByPhases = [];

    let isFirstTask = true;
    recipe.tasksList.forEach((taskDescriptor, index) => {
        const previousStep = index - 1;

        const tasksForThisKind = tasksGroupedByKind[taskDescriptor.kind];
        if (!tasksForThisKind?.length) {
            return;
        }

        // if it is a first task, create a new phase in the array so the subsequent one can add to the "previous phase"
        if (isFirstTask) {
            tasksGroupedByPhase.push({
                phaseName: taskDescriptor.phase,
                tasks: {
                    [taskDescriptor.kind]: tasksForThisKind,
                },
            });
            isFirstTask = false;
            return;
        }

        // if the next step has no phase or if the phase is the same as the previous step, add that step's tasks to the previous phase
        if (!taskDescriptor.phase || recipe.tasksList[previousStep]?.phase === taskDescriptor.phase) {
            tasksGroupedByPhase[tasksGroupedByPhase.length - 1].tasks[taskDescriptor.kind] = tasksForThisKind;
            return;
        }

        //if the task phase differs, create a new phase in the array
        if (recipe.tasksList[previousStep]?.phase !== taskDescriptor.phase) {
            tasksGroupedByPhase.push({
                phaseName: taskDescriptor.phase,
                tasks: {
                    [taskDescriptor.kind]: tasksForThisKind,
                },
            });
            return;
        }
    });

    return tasksGroupedByPhase;
};
