import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { LoginView } from "../auth/LoginScreen";
import { AUTH_ROUTES, useQueryParams } from "../app/AppRoutes";
import { SetNewPasswordScreen } from "../auth/SetNewPasswordScreen";
import { UserSignUpScreen } from "../auth/UserSignUpScreen";
import { useEffect } from "react";
import { useAppServices } from "../app/services";
import { FullScreenLoading } from "../core/data/DataLoaders";

interface LoginCustomerCenterViewProps {}

export const LoginCustomerCenter: React.FC<LoginCustomerCenterViewProps> = (p) => {
    const { helpService } = useAppServices();
    const query = useQueryParams();
    useEffect(() => {
        const redirect = query.get("redirect");
        window.location.href = helpService.getCustomerCenterAuthCallbackUrl(redirect);
    });
    return <FullScreenLoading />;
};
