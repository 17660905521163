import React from "react";
import "./index.css";
import App from "./modules/app/App";
import { configureMobx } from "./modules/app/services";
import { initLoggers } from "./common/logger";
import "@fontsource/nunito-sans/";
import "@fontsource/inter/variable.css";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "leaflet/dist/leaflet.css";

// init
configureMobx();
initLoggers();

if (!!import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_PRIVATE_EDITION !== "1") {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        environment: import.meta.env.VITE_ENVIRONMENT,
        release: import.meta.env.VITE_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
