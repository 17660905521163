// ======================
// LabelForm
// ======================

import { Box, Button, Chip, darken, DialogActions, DialogContent, Stack, TextField, Typography, useTheme } from "@mui/material";
import { LabelListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { DialogState } from "../core/dialog/DialogService";
import { blue, cyan, green, grey, lime, orange, purple, red, yellow } from "@mui/material/colors";
import { useCreateNewLabel, useUpdateLabel } from "./label_hooks";
import { CreateLabel, UpdateLabel } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { MdCheck } from "react-icons/md";
import { LabelChip } from "./labelCommon";
import React, { useState } from "react";
import * as yup from "yup";

interface CreateLabelFormProps {
    labelInfo?: LabelListItem.AsObject;
    dialogState: DialogState;
    projectId: string;
    currentLabel?: string;
}

export const CreateLabelForm: React.FC<CreateLabelFormProps> = (p) => {
    const { labelInfo, projectId, dialogState } = p;
    const labelRegex = /^[a-z0-9-/]{1,100}$/;
    const scopeRegex = /^[a-z0-9-]{0,100}$/;
    const [scope, setScope] = React.useState<string>(labelInfo ? (labelInfo.label.name.includes("//") ? labelInfo.label.name.split("//")[0] : "") : "");
    const [scopeError, setScopeError] = useState("");
    const [label, setLabel] = React.useState<string>(
        labelInfo ? (labelInfo.label.name.includes("//") ? labelInfo.label.name.split("//")[1] : labelInfo.label.name) : ""
    );
    const [labelError, setLabelError] = useState("");
    const [description, setDescription] = React.useState<string>(labelInfo ? labelInfo.label.description : "");
    const [color, setColor] = React.useState<string>(labelInfo ? labelInfo.label.color : "");
    const theme = useTheme();
    const chipColors = [red[500], purple[500], blue[500], cyan[200], green[500], lime[500], yellow[500], orange[500], grey[100], "#4A5268"];

    const createLabel = useCreateNewLabel();
    const updateLabel = useUpdateLabel();
    const getSubmitDisabled = () => {
        if (!label || !color || !!scopeError || !!labelError) {
            return true;
        }
        return false;
    };

    const onAddNewLabel = async () => {
        const fullLabel = scope ? `${scope}//${label}` : label;
        const req = new CreateLabel.Request().setColor(color).setDescription(description).setLabel(fullLabel).setProjectId(projectId);
        await createLabel.mutateAsync(req);
        dialogState.close();
    };

    const onUpdateLabel = async () => {
        const fullLabel = scope ? `${scope}//${label}` : label;
        const req = new UpdateLabel.Request()
            .setColor(color)
            .setDescription(description)
            .setExistingLabel(labelInfo?.label.name)
            .setNewLabel(fullLabel)
            .setProjectId(projectId);
        await updateLabel.mutateAsync(req);
        dialogState.close();
    };

    return (
        <>
            <DialogTopBar dialogState={dialogState} title={!!labelInfo ? "Update Label" : "Add New Label"} />
            <DialogContent>
                <Box pb={2}>
                    <Typography variant={"h6"}>{"1. Label Name"}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"Label names are case-insensitive. Only letters (a-z), numbers (0-9) and dashes (-) are allowed."}
                    </Typography>
                    <Stack direction={"row"} pt={1} spacing={2} alignItems={"center"}>
                        <TextField
                            value={scope}
                            onChange={(e) => {
                                setScope(e.target.value);
                                if (!e.target.value.match(scopeRegex)) {
                                    setScopeError("Scope must be 100 characters or less and only contain letters, numbers, and dashes.");
                                } else {
                                    setScopeError("");
                                }
                            }}
                            variant={"filled"}
                            fullWidth={true}
                            label={"Scope (optional)"}
                            error={!!scopeError}
                            helperText={scopeError}
                        />
                        <Typography>{"//"}</Typography>
                        <TextField
                            value={label}
                            onChange={(e) => {
                                setLabel(e.target.value);
                                if (!e.target.value.match(labelRegex)) {
                                    setLabelError("Label must be 100 characters or less and only contain letters, numbers, and dashes.");
                                } else {
                                    setLabelError("");
                                }
                            }}
                            variant={"filled"}
                            fullWidth={true}
                            label={"Label"}
                            required
                            error={!!labelError}
                            helperText={labelError}
                        />
                    </Stack>
                </Box>
                <Box pb={2}>
                    <Typography variant={"h6"}>{"2. Label Description"}</Typography>
                    <Box pt={1}>
                        <TextField
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            variant={"filled"}
                            multiline={true}
                            minRows={2}
                            fullWidth={true}
                            label={"Description"}
                        />
                    </Box>
                </Box>
                <Box pb={2}>
                    <Typography variant={"h6"}>{"3. Label Color"}</Typography>
                    <Stack direction={"row"} pt={1} spacing={2} alignItems={"center"}>
                        {chipColors.map((c) => {
                            return (
                                <Chip
                                    sx={{
                                        textOverflow: "clip",
                                        overflow: "visible",
                                        height: "30px",
                                        width: "30px",
                                        backgroundColor: c,
                                        "&:hover": {
                                            backgroundColor: darken(c, 0.2),
                                        },
                                        "& .MuiChip-label": {
                                            padding: 0,
                                        },
                                        borderRadius: 2,
                                    }}
                                    key={c}
                                    onClick={() => setColor(c)}
                                    label={
                                        c === color ? (
                                            <MdCheck size={20} style={{ color: color ? theme.palette.getContrastText(color) : "white" }} />
                                        ) : (
                                            <Typography>&nbsp; &nbsp;</Typography>
                                        )
                                    }
                                />
                            );
                        })}
                    </Stack>
                    <Stack pt={2} direction={"row"} spacing={2} alignItems={"center"}>
                        <Typography color={"textSecondary"}>{"Preview:"}</Typography>
                        <LabelChip label={scope ? `${scope} // ${label}` : label} color={color} />
                    </Stack>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={getSubmitDisabled()} onClick={!!labelInfo ? onUpdateLabel : onAddNewLabel} variant={"contained"} color={"primary"}>
                    {!!labelInfo ? "Update Label" : "Add Label"}
                </Button>
            </DialogActions>
        </>
    );
};
