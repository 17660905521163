// ======================
// CmoMigrationIoStatChart
// ======================

import { CmoIOStatData, CmoSystemIoStats } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import { alpha, Box, Card, CardProps, FormControl, ListSubheader, MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import {
    CmoIoStatChartType,
    getChartDataFromIoStatsObject,
    getDataKeyFromCmoIoStatType,
    getKeyOfCmoSystemIoStatsFromStatType,
    getKnownDataTypeOfCmoIoStatType,
    getYAxisFormatterForCmoIoStatType,
} from "./CmoMigrationIoStatChartHelpers";
import { pink } from "@mui/material/colors";
import { getAxesMaxValue, getYAxisTickValues } from "../../../common/utils/statsUtil";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { GoDot, GoDotFill } from "react-icons/go";

interface CmoMigrationIoStatWidgetProps {
    ioStats: CmoIOStatData.AsObject;
    title?: string;
    cardProps?: CardProps;
    defaultStat?: CmoIoStatChartType;
    chartHeight?: number;
}

export const CmoMigrationIoStatChart: React.FC<CmoMigrationIoStatWidgetProps> = (p) => {
    const { ioStats, title, cardProps, defaultStat, chartHeight } = p;
    const theme = useTheme();
    const [statType, setStatType] = useState<CmoIoStatChartType>(defaultStat || CmoIoStatChartType.THROUGHPUT);

    const chartData = getChartDataFromIoStatsObject(ioStats);
    const allY = chartData
        .map((point) => {
            const array = [];
            if (statType === CmoIoStatChartType.THROUGHPUT) {
                array.push(point.readThroughput);
                array.push(point.writeThroughput);
            }
            if (statType === CmoIoStatChartType.IOPS) {
                array.push(point.writeIops);
                array.push(point.readIops);
            }
            if (statType === CmoIoStatChartType.LATENCY) {
                array.push(point.averageReadResponseTime);
                array.push(point.averageWriteResponseTime);
            }
            if (statType === CmoIoStatChartType.QUEUE_DEPTH) {
                array.push(point.readPendingIo);
                array.push(point.writePendingIo);
            }
            return array;
        })
        .flat();

    const yMax = getAxesMaxValue(getKnownDataTypeOfCmoIoStatType(statType), allY);
    const yAxisFormatter = getYAxisFormatterForCmoIoStatType(statType);

    return (
        <Card {...cardProps}>
            <ListSubheader>{title || ""}</ListSubheader>
            <Box p={2}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} spacing={1}>
                    <Box>
                        <FormControl>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={statType}
                                color={"primary"}
                                onChange={(e) => setStatType(e.target.value as CmoIoStatChartType)}
                            >
                                {Object.keys(CmoIoStatChartType).map((key) => {
                                    const val = CmoIoStatChartType[key as keyof typeof CmoIoStatChartType];
                                    return <MenuItem value={val}>{val}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Stack direction={"row"} spacing={6}>
                            <Box textAlign={"right"}>
                                <Typography variant={"h5"} fontWeight={600}>
                                    {yAxisFormatter(
                                        ioStats.pointsList[ioStats.pointsList.length - 1].stats[
                                            getKeyOfCmoSystemIoStatsFromStatType(statType, "read") as keyof CmoSystemIoStats.AsObject
                                        ] as number
                                    )}
                                </Typography>
                                <Typography color={"textSecondary"} variant={"subtitle2"}>
                                    {`Read`}
                                </Typography>
                            </Box>
                            <Box textAlign={"right"}>
                                <Typography variant={"h5"} fontWeight={600}>
                                    {yAxisFormatter(
                                        ioStats.pointsList[ioStats.pointsList.length - 1].stats[
                                            getKeyOfCmoSystemIoStatsFromStatType(statType, "write") as keyof CmoSystemIoStats.AsObject
                                        ] as number
                                    )}
                                </Typography>
                                <Typography color={"textSecondary"} variant={"subtitle2"}>
                                    {`Write`}
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box p={2} pt={0}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        {yAxisFormatter(yMax)}
                    </Typography>
                </Box>
                <ResponsiveContainer width={"100%"} height={chartHeight || 120}>
                    <LineChart data={chartData}>
                        <Tooltip
                            content={<TooltipContent yAxisFormatter={yAxisFormatter} />}
                            cursor={{ strokeWidth: 0 }}
                            wrapperStyle={{ fontFamily: theme.typography.fontFamily, border: "1px solid transparent" }}
                        />
                        <Line
                            type="monotone"
                            dataKey={getDataKeyFromCmoIoStatType(statType, "read")}
                            key={getDataKeyFromCmoIoStatType(statType, "read")}
                            activeDot={{ r: 4 }}
                            stroke={pink[500]}
                            dot={false}
                            isAnimationActive={false}
                            strokeWidth={3}
                        />
                        <Line
                            type="monotone"
                            dataKey={getDataKeyFromCmoIoStatType(statType, "write")}
                            key={getDataKeyFromCmoIoStatType(statType, "write")}
                            activeDot={{ r: 4 }}
                            stroke={pink[200]}
                            dot={false}
                            isAnimationActive={false}
                            strokeWidth={3}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Card>
    );
};

// ======================
// TooltipContent
// ======================

interface TooltipContentProps {
    active?: any;
    payload?: any;
    label?: any;
    yAxisFormatter?: (value: number) => string;
}

const TooltipContent: React.FC<TooltipContentProps> = (p) => {
    const { active, payload, label, yAxisFormatter } = p;
    if (active && payload && payload.length) {
        const time = payload[0].payload.time;
        return (
            <Box sx={{ backgroundColor: alpha("#000000", 0.8), borderRadius: 2 }}>
                <Box p={1}>
                    {payload.map((p: any) => {
                        return (
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <GoDotFill color={p.color} />
                                <Typography variant={"subtitle2"}>{`${p.name.toLowerCase().includes("read") ? "Read" : "Write"}: `}</Typography>
                                <Typography variant={"subtitle2"}>{yAxisFormatter(p.value)}</Typography>
                            </Stack>
                        );
                    })}
                    <Box pt={0.5}>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {formatKnownDataType(time, KnownDataType.DATE_RELATIVE)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }

    return null;
};
