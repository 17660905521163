import { CirrusGradient } from "../app/AppTheme";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES, AUTH_ROUTES } from "../app/AppRoutes";
import { useAppServices } from "../app/services";
import Box from "@mui/material/Box";
import { FcCheckmark } from "react-icons/fc";
import { Typography } from "@mui/material";
import React from "react";

export const useLoginStyles = () => ({
    root: {
        background: CirrusGradient,
        height: "100vh",
        width: "100vw",
        display: "flex",
    },
    card: {
        minHeight: 500,
    },
    login: {
        textAlign: "center",
    },
    center: {
        display: "flex",
        justifyContent: "center",
    },
    logo: {
        maxWidth: "100%",
        width: "auto",
    },
    savedEmail: {
        border: "1px solid #777777",
        backgroundColor: `rgb(255, 255, 255, 0.05)`,
    },
    lowerCaseButton: {
        textTransform: "lowercase",
    },
    hiddenInput: {
        visibility: "hidden",
    },
});

export const useNavigateToPreLogin = (queryString?: string) => {
    const navigate = useNavigate();
    const p = generatePath(`/${queryString || ""}`);
    return () => navigate(p);
};

export const useNavigateToSignUpScreen = (queryString?: string) => {
    const navigate = useNavigate();
    const p = generatePath(`${AUTH_ROUTES.SIGN_UP}${queryString ? `/${queryString}` : ""}`);
    return () => navigate(p);
};
//
// export const useLogIn = () => {
//     const { authService, dialogService, progressService } = useAppServices();
//     const navigate = useNavigate();
//     const location = useLocation();
//     return async (username: string, password: string, mfaToken: string, awsToken?: string, azureToken?: string) => {
//         const awsConfirmationMessage = `THIS ACTION IS IRREVERSIBLE. By continuing, your existing account ${username} will be connected to your AWS Marketplace Subscription.`;
//         const azureConfirmationMessage = `By continuing, your Azure Marketplace Subscription will be activated and connected to your existing account ${username}. Depending on Microsoft Azure Marketplace's processing time, this activation process may take up to a few minutes.`;
//
//         const getPostLoginAwsSuccessMessage = async () => {
//             const getMessageBody = () => (
//                 <Box display={"flex"} alignItems={"center"}>
//                     <Box pr={2}>
//                         <FcCheckmark size={"1.5em"} />
//                     </Box>
//                     <Typography>Congratulations! {username} is successfully connected with your AWS Marketplace Subscription.</Typography>
//                 </Box>
//             );
//             return await dialogService.addAlertDialog({
//                 title: "Account is connected to AWS Marketplace Subscription",
//                 renderAdditionalContent: getMessageBody,
//                 okButtonLabel: "Done",
//             });
//         };
//         const getPostLoginAzureSuccessMessage = async () => {
//             const getMessageBody = () => (
//                 <Box display={"flex"} alignItems={"center"}>
//                     <Box pr={2}>
//                         <FcCheckmark size={"1.5em"} />
//                     </Box>
//                     <Typography>
//                         Congratulations! your Azure Marketplace Subscription has been activated successfully and connected to your Cirrus Data Cloud account{" "}
//                         {username}.
//                     </Typography>
//                 </Box>
//             );
//             return await dialogService.addAlertDialog({
//                 title: "Azure Marketplace Subscription Activated",
//                 renderAdditionalContent: getMessageBody,
//                 okButtonLabel: "Done",
//             });
//         };
//         let confirmed;
//         if (!!awsToken) {
//             confirmed = await dialogService.addConfirmDialog({
//                 title: "Connect to AWS Marketplace Subscription Confirmation",
//                 message: awsConfirmationMessage,
//             });
//         } else if (!!azureToken) {
//             confirmed = await dialogService.addConfirmDialog({
//                 title: "Azure Marketplace Subscription Activation",
//                 message: azureConfirmationMessage,
//             });
//         } else {
//             confirmed = true;
//         }
//
//         if (confirmed) {
//             const result = await progressService.track(authService.login(username, password, mfaToken, awsToken, azureToken));
//             if (!!result) {
//                 if (!!awsToken) {
//                     authService.setAwsAuthenticated(true);
//                 } else if (!!azureToken) {
//                     authService.setAzureAuthenticated(true);
//                 }
//                 if (location.pathname === "/") {
//                     navigate(APP_ROUTES.PROJECTS);
//                 }
//                 if (!!awsToken) {
//                     const alerted = await getPostLoginAwsSuccessMessage();
//                     if (alerted) {
//                         navigate(APP_ROUTES.PROJECTS);
//                     }
//                 } else if (!!azureToken) {
//                     const alerted = await getPostLoginAzureSuccessMessage();
//                     if (alerted) {
//                         navigate(APP_ROUTES.PROJECTS);
//                     }
//                 }
//                 return result;
//             }
//         }
//     };
// };

export const AWS_MARKETPLACE_TOKEN = "x-amzn-marketplace-token";
