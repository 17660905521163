import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import {
    ChangeProjectMemberRole,
    InviteNewProjectMembers,
    LeaveProject,
    ListProjectMembers,
    RemoveProjectMember,
    SelectProjectProducts,
    UpdateProject,
} from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { ProjectQueryKeys, ProjectSettingsMutationKeys, ProjectSettingsQueryKeys } from "../../common/QueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ProjectInfo, ProjectMemberInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { useCurrentProject } from "../project/CurrentProjectState";
import NotificationSettings = ProjectInfo.NotificationSettings;
import { PROJECT_PRODUCT_TYPE } from "../project/project_hooks";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";

export const useListProjectMembers = (projectId: string, pageIndex: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListProjectMembers.Request().setProjectId(projectId).setPageParams(new PagerParams().setPerPage(perPage).setPage(pageIndex));
        const res = await apis.projectService.listProjectMembers(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [ProjectSettingsQueryKeys.listProjectMembers, projectId],
        queryFn,
    });
};
export const useInviteNewProjectMembers = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (args: { emails: string[]; projectId: string; role: ProjectMemberInfo.Role }) => {
        const req = new InviteNewProjectMembers.Request().setProjectId(args.projectId).setEmailsList(args.emails).setRole(args.role);

        return await apis.projectService.inviteNewProjectMembers(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.inviteNewProjectMembers],
        mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectSettingsQueryKeys.listProjectMembers],
            });
        },
    });
};
export const useLeaveProject = () => {
    const apis = useGrpcApiStore();
    const globalDialogState = useGlobalDialogState();
    const mutationFn = async (projectId: string) => {
        const req = new LeaveProject.Request().setProjectId(projectId);
        try {
            return await apis.projectService.leaveProject(req, null);
        } catch (e) {
            if ((e as Error).message === "can not remove the last admin from the project") {
                await globalDialogState.addAlertDialog({
                    title: "Cannot Leave Project",
                    message: `Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`,
                });
            } else {
                await globalDialogState.addErrorAlertDialog(e as Error);
            }
            //throw e;
        }
    };
    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.leaveProject],
        mutationFn,
    });
};
export const useChangeProjectMemberRole = () => {
    const apis = useGrpcApiStore();
    const globalDialogState = useGlobalDialogState();
    const queryClient = useQueryClient();
    const mutationFn = async (args: { projectId: string; userId: number; role: ProjectMemberInfo.Role }) => {
        const req = new ChangeProjectMemberRole.Request().setProjectId(args.projectId).setUserId(args.userId).setNewRole(args.role);

        const confirmationMessage = () => {
            if (args.role === ProjectMemberInfo.Role.ADMIN) {
                return `Changing to Admin status will grant this user privileges to add and delete project members. Are you sure you want to continue?`;
            } else if (args.role === ProjectMemberInfo.Role.MONITOR) {
                return `Changing to Monitor status will remove operational privileges from this user. Are you sure you want to continue?`;
            } else if (args.role === ProjectMemberInfo.Role.OPERATOR) {
                return `Are you sure you want to change this user's role to Operator?`;
            }
        };
        const confirmed = await globalDialogState.addConfirmDialog({
            message: confirmationMessage(),
            autoConfirmationQuestionLine: false,
        });
        if (confirmed) {
            try {
                return await apis.projectService.changeProjectMemberRole(req, null);
            } catch (e) {
                if ((e as Error).message === "can not remove the last admin from the project") {
                    await globalDialogState.addAlertDialog({
                        title: "Cannot Change Role",
                        message: `Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`,
                    });
                } else {
                    await globalDialogState.addErrorAlertDialog(e as Error);
                }
                throw e;
            }
        }
    };
    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.changeProjectMemberRole],
        mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectSettingsQueryKeys.listProjectMembers],
            });
        },
    });
};
export const useRemoveProjectMember = () => {
    const apis = useGrpcApiStore();
    const globalDialogState = useGlobalDialogState();
    const queryClient = useQueryClient();
    const mutationFn = async (args: { projectId: string; userId: number }) => {
        const req = new RemoveProjectMember.Request().setProjectId(args.projectId).setUserId(args.userId);

        try {
            return await apis.projectService.removeProjectMember(req, null);
        } catch (e) {
            if ((e as Error).message === "can not remove the last admin from the project") {
                await globalDialogState.addAlertDialog({
                    title: "Cannot Remove Member",
                    message: `Each project must have at least one admin. Please transfer admin privilege to another project member and try again.`,
                });
            } else {
                await globalDialogState.addErrorAlertDialog(e as Error);
            }
            //throw e;
        }
    };
    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.removeProjectMember],
        mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectSettingsQueryKeys.listProjectMembers],
            });
        },
    });
};

export const useUpdateProject = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.updateProject],
        mutationFn: async (args: {
            projectId: string;
            name: string;
            description: string;
            emergencyEmail: string;
            emergencyPhone: string;
            deleteLock: boolean;
        }) => {
            const req = new UpdateProject.Request()
                .setProjectId(args.projectId)
                .setName(args.name)
                .setDescription(args.description)
                .setEmergencyEmail(args.emergencyEmail)
                .setEmergencyPhone(args.emergencyPhone)
                .setDeleteLock(args.deleteLock);
            return await apis.projectService.updateProject(req, null);
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.getProjectDetails],
            });
        },
    });
};

export const useSetProjectEmailNotifications = () => {
    const apis = useGrpcApiStore();
    const currentProject = useCurrentProject();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.setProjectEmailNotifications],
        mutationFn: async (args: { disableLicenseTransactionEmail: boolean; disableNewHostEmail: boolean; disableMigrateOpsEmail: boolean }) => {
            const req = new UpdateProject.Request()
                .setProjectId(currentProject?.getInfo().getProjectId())
                .setDeleteLock(currentProject?.getInfo().getDeleteLock())
                .setEmergencyEmail(currentProject?.getInfo().getEmergencyEmail())
                .setEmergencyPhone(currentProject?.getInfo().getEmergencyPhone())
                .setDescription(currentProject?.getInfo().getDescription())
                .setName(currentProject?.getInfo().getName())
                .setNotificationSettings(
                    new NotificationSettings()
                        .setDisabledLicenseTransaction(args.disableLicenseTransactionEmail)
                        .setDisabledNewHostAdded(args.disableNewHostEmail)
                        .setDisabledMigrateOps(args.disableMigrateOpsEmail)
                );
            return await apis.projectService.updateProject(req, null);
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.getProjectDetails],
            });
        },
    });
};

export const useSetProjectSupportPermissions = () => {
    const apis = useGrpcApiStore();
    const currentProject = useCurrentProject();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.setProjectSupportPermissions],
        mutationFn: async (args: { allowSupport: boolean }) => {
            const req = new UpdateProject.Request()
                .setProjectId(currentProject?.getInfo().getProjectId())
                .setDeleteLock(currentProject?.getInfo().getDeleteLock())
                .setEmergencyEmail(currentProject?.getInfo().getEmergencyEmail())
                .setEmergencyPhone(currentProject?.getInfo().getEmergencyPhone())
                .setDescription(currentProject?.getInfo().getDescription())
                .setName(currentProject?.getInfo().getName())
                .setAllowSupportStaff(args.allowSupport);
            return await apis.projectService.updateProject(req, null);
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.getProjectDetails],
            });
        },
    });
};

export const useSelectProjectProducts = () => {
    const apis = useGrpcApiStore();
    const currentProject = useCurrentProject();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectSettingsMutationKeys.selectProjectProducts],
        mutationFn: async (products: Array<PROJECT_PRODUCT_TYPE>) => {
            const req = new SelectProjectProducts.Request()
                .setProjectId(currentProject?.getInfo().getProjectId())
                .setCmo(products.includes(PROJECT_PRODUCT_TYPE.CMO))
                .setCmc(products.includes(PROJECT_PRODUCT_TYPE.CMC));
            return await apis.projectService.selectProjectProducts(req, null);
        },
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.getProjectDetails],
            });
        },
    });
};
