import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getProjectSubRouteTemplate, MIGRATE_OPS_SUBROUTE, PROJECT_SUBROUTE } from "../../app/AppRoutes";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import { useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { sanitizeObjectOfEmptyFields } from "../MigrateOpsNewOperationBuilderState";
import { Box, Button, CardContent, Dialog, DialogContent, Link, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { CodeCard } from "../../../common/card/DarkCard";
import * as YAML from "yaml";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import React, { useEffect, useState } from "react";
import { useStepperContext } from "../../../common/stepper/StepperState";
import { useNavigateToOpDetails, useNavigatetoRecipeReference } from "../MigrateOpsCommon";
import { useCreateNewOperationFromYaml } from "../migrate_ops_hooks";
import { SlideAndFadeInStep, StepperNavButtons } from "../../../common/stepper/StepperComponents";
import { SimpleCodeEditor } from "../../../common/editor/SimpleCodeEditor";
import { ClipboardButton, ClipboardText } from "../../../common/clipboard/ClipboardComponents";
import Grid from "@mui/material/Grid2";
import { MdOutlineDownload } from "react-icons/md";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";

export interface OperationConfig {
    system_id: string;
    integration_id?: number;
    scheduled_start_time?: string;
}
export interface OperationJson {
    name: string;
    notes: string;
    recipe: string;
}

export type OperationRecipeRoute = "azure" | "aws" | "local-data-migration" | "hyperv" | "cluster" | "nutanix" | "remote-migration" | "source-data-assessment";

export const useNavigateToMigrateOpsRecipeWizard = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (recipeId: OperationRecipeID) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS) + `/${MIGRATE_OPS_SUBROUTE.WIZARD}/${recipeId}`, {
            projectId,
        });
        navigate(p);
    };
};

// ======================
// ViewCurrentYaml
// ======================

interface ViewCurrentYamlButtonProps {
    operationJson: object;
    recipeId: OperationRecipeID;
}

export const ViewCurrentYamlButton: React.FC<ViewCurrentYamlButtonProps> = (p) => {
    const { operationJson, recipeId } = p;
    const projectId = useCurrentProjectID();
    const dialogState = useDialogState();
    const clonedJson = JSON.parse(JSON.stringify(operationJson));
    const sanitizedJson = sanitizeObjectOfEmptyFields(clonedJson, true);
    const fullScreen = useShouldDialogFullScreen();
    const recipePath = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS_DOCS) + `/${recipeId}`, { projectId });

    return (
        <>
            <Grid container spacing={2}>
                <Grid>
                    <Link sx={{ color: alpha("rgb(255,255,255)", 0.3) }} variant={"subtitle2"} onClick={dialogState.open}>
                        {"View MigrateOps Config Draft"}
                    </Link>
                </Grid>
                <Grid>
                    <Link
                        sx={{ color: alpha("rgb(255,255,255)", 0.3) }}
                        variant={"subtitle2"}
                        onClick={() => {
                            window.open(recipePath, "_blank");
                        }}
                    >
                        {"View Recipe Reference"}
                    </Link>
                </Grid>
            </Grid>
            {dialogState.isOpen && (
                <Dialog fullScreen={fullScreen} maxWidth={"md"} fullWidth open={dialogState.isOpen} onClose={dialogState.close}>
                    <DialogTopBar dialogState={dialogState} title={"MigrateOps Configuration"} />
                    <DialogContent>
                        <CodeCard sx={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}>
                            <CardContent sx={{ height: "100%", overflow: "auto" }}>
                                <Box>
                                    <pre>
                                        {YAML.stringify(sanitizedJson, {
                                            indent: 2,
                                            defaultKeyType: "PLAIN",
                                            defaultStringType: "QUOTE_DOUBLE",
                                        })}
                                    </pre>
                                </Box>
                            </CardContent>
                        </CodeCard>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

// ======================
// FinishUpStep
// ======================

interface ViewGeneratedConfigStep {
    onGoBack: () => void;
    resetState: () => void;
    id: string;
    submitButtonLabel: string;
    wizardState: any;
}

export const ViewGeneratedConfigStep: React.FC<ViewGeneratedConfigStep> = (p) => {
    const { onGoBack, resetState, id, submitButtonLabel } = p;
    const projectId = useCurrentProjectID();
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    const wizardState = p.wizardState();
    const clonedJson = {
        operations: [JSON.parse(JSON.stringify(wizardState.operationJson))],
    };
    const sanitizedJson = sanitizeObjectOfEmptyFields(clonedJson, true);
    const generatedYamlId = "generated-yaml-id";
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editorValue, setEditorValue] = useState<string>("");
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };
    const goToMigrateOpsDetailPage = useNavigateToOpDetails();
    const globalDialogState = useGlobalDialogState();
    const createOperation = useCreateNewOperationFromYaml();
    const handleSubmit = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            title: "Execute Operation",
            message: "A new MigrateOps operation will be created.",
        });

        if (confirmed) {
            const res = await createOperation.mutateAsync(editorValue);
            if (res) {
                goToMigrateOpsDetailPage(res.operationIdsList[0]);
                resetState();
            }
        }
    };

    useEffect(() => {
        if (!editMode) {
            console.debug("hi");
            setEditorValue(
                YAML.stringify(sanitizedJson, {
                    indent: 2,
                    defaultKeyType: "PLAIN",
                    defaultStringType: "QUOTE_DOUBLE",
                })
            );
        }
    }, [sanitizedJson, editMode]);

    const openMigrateOpsReference = () => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MIGRATE_OPS_DOCS), { projectId });
        window.open(p, "_blank");
    };

    const downloadYaml = () => {
        const link = document.createElement("a");
        const content = editorValue;
        const file = new Blob([content]);
        link.href = URL.createObjectURL(file);
        link.download = "Generated-YAML.yaml";
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={2}>
                <Stack textAlign={"center"} direction={"column"} spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Typography variant={"h4"}>{"Your configuration has been generated."}</Typography>
                    <Typography variant={"body1"}>
                        {`Below is the generated configuration. You can now choose to make any edits to the configuration or go ahead and create the assessment.`}
                    </Typography>
                </Stack>
                {editMode ? (
                    <>
                        <Box pt={2}>
                            <CodeCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                                <Stack direction={"row"} justifyContent={"flex-end"}>
                                    <Button variant={"outlined"} color={"neutral"} onClick={openMigrateOpsReference}>
                                        {"Open MigrateOps Reference"}
                                    </Button>
                                </Stack>
                                <SimpleCodeEditor value={editorValue} setValue={setEditorValue} />
                            </CodeCard>
                        </Box>
                    </>
                ) : (
                    <CodeCard sx={{ maxHeight: "calc(100vh - 250px)", overflow: "auto" }}>
                        <CardContent sx={{ height: "100%", overflow: "auto" }}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Typography variant={"h6"}>{"Configuration"}</Typography>
                                <Button variant={"outlined"} color={"neutral"} onClick={toggleEditMode}>
                                    {"Edit Config"}
                                </Button>
                            </Box>
                            <Box>
                                <ClipboardText clipboardId={generatedYamlId}>
                                    <pre>
                                        {YAML.stringify(sanitizedJson, {
                                            indent: 2,
                                            defaultKeyType: "PLAIN",
                                            defaultStringType: "QUOTE_DOUBLE",
                                        })}
                                    </pre>
                                </ClipboardText>
                                <Stack direction={"row"} spacing={1}>
                                    <ClipboardButton color={"neutral"} clipboardId={generatedYamlId} label={"Copy Config"} />
                                </Stack>
                            </Box>
                        </CardContent>
                    </CodeCard>
                )}
                <Stack direction={"row"} spacing={1} justifyContent={"center"}>
                    <Button
                        disabled={editMode}
                        variant={"outlined"}
                        color={"neutral"}
                        onClick={() => {
                            setSlideDirection("up");
                            onGoBack();
                        }}
                    >
                        {"Back"}
                    </Button>
                    <Button startIcon={<MdOutlineDownload />} variant={"outlined"} color={"neutral"} onClick={downloadYaml}>
                        {"Download YAML File"}
                    </Button>
                    <Button variant={"contained"} color={"primary"} onClick={handleSubmit}>
                        {"Execute Operation"}
                    </Button>
                </Stack>
            </Stack>
        </SlideAndFadeInStep>
    );
};
