import { useGrpcApiStore } from "../../grpc/grpcApiStore";
import { useMutationAlertError, useMutationTrackAndAlertError } from "../../core/data/useHooksWithErrorHandling";
import { ConsumptionPlanningMutationKeys, ConsumptionPlanningQueryKeys } from "../../../common/QueryKeys";
import { AzureStorageConsumptionPlanListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/planner_pb";
import { mockProjectIdentityInfo1 } from "../../core/testutils/fixtures/MockProjectService";
import { AzureRegion } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { convertDateObjectToTimestamp } from "../../../common/utils/formatter";
import { mockUser1 } from "../../core/testutils/fixtures/FixturesCommon";
import { useAzureStoragePlannerState } from "./AzureStoragePlannerState";
import { useNavigateToAzureStoragePlanDetails, useNavigateToAzureStoragePlanList } from "./AzureStoragePlannerHelpers";
import { useStepperContext } from "../../../common/stepper/StepperState";
import {
    CustomizeAzureStorageConsumptionPlan,
    DeleteAzureStorageConsumptionPlan,
    GetAzureStorageConsumptionPlan,
    ListAzureStorageConsumptionPlan,
} from "gc-web-proto/galaxycompletepb/apipb/planner_api_pb";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { useQuery } from "@tanstack/react-query";

const mockConsumptionPlan = new AzureStorageConsumptionPlanListItem()
    .setName("Mock Plan 1")
    .setDescription("Mock Plan 1 Description")
    .setId("1")
    .setProject(mockProjectIdentityInfo1)
    .setRegion(AzureRegion.CENTRAL_US)
    .setCreatedAt(convertDateObjectToTimestamp(new Date()))
    .setCreatedByUser(mockUser1)
    .setTotalCapacity(1000000000)
    .setTotalHosts(2)
    .setTotalVolumes(15)
    .setTotalMonthlyCost(10000);

export const useListAzureStorageConsumptionPlans = (projectId: string) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListAzureStorageConsumptionPlan.Request().setProjectId(projectId);
        const res = await apis.consumptionPlanningService.listAzureStorageConsumptionPlan(req, null);
        return res.toObject();
        //return new ListAzureStorageConsumptionPlan.Response().setPlansList([mockConsumptionPlan]).toObject();
    };

    return useQuery({
        queryKey: [ConsumptionPlanningQueryKeys.listConsumptionPlans, projectId],
        queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetAzureStorageConsumptionPlan = (consumptionPlanId?: string) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new GetAzureStorageConsumptionPlan.Request().setPlanId(consumptionPlanId);
        const res = await apis.consumptionPlanningService.getAzureStorageConsumptionPlan(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [ConsumptionPlanningQueryKeys.getConsumptionPlan, consumptionPlanId],
        queryFn,
        enabled: !!consumptionPlanId,
    });
};

export const useCreateAzureStorageConsumptionPlan = () => {
    const apis = useGrpcApiStore();
    const req = useAzureStoragePlannerState((s) => s.consumptionPlanRequest);
    const resetState = useAzureStoragePlannerState((s) => s.resetState);
    const mutationFn = async () => {
        const res = await apis.consumptionPlanningService.createAzureStorageConsumptionPlan(req, null);
        return res?.toObject();
    };
    const goBackOneStepInForm = useStepperContext((s) => s.goBackOneStep);

    const goToConsumptionPlanDetails = useNavigateToAzureStoragePlanDetails();

    return useMutationAlertError({
        mutationKey: [ConsumptionPlanningMutationKeys.createConsumptionPlan],
        mutationFn: mutationFn,
        onSuccess: (res) => {
            resetState();
            goToConsumptionPlanDetails(res.planId);
        },
        onError: (err) => {
            goBackOneStepInForm();
        },
    });
};
export const useCustomizeAzureStorageConsumptionPlan = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async () => {
        const req = new CustomizeAzureStorageConsumptionPlan.Request();

        const res = await apis.consumptionPlanningService.customizeAzureStorageConsumptionPlan(req, null);
        return res?.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [ConsumptionPlanningMutationKeys.customizeConsumptionPlan],
        mutationFn: mutationFn,
    });
};

export const useDeleteAzureStorageConsumptionPlan = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (planId: string) => {
        const req = new DeleteAzureStorageConsumptionPlan.Request().setPlanId(planId);
        const res = await apis.consumptionPlanningService.deleteAzureStorageConsumptionPlan(req, null);
        return res?.toObject();
    };

    const goToPlanList = useNavigateToAzureStoragePlanList();

    return useMutationTrackAndAlertError({
        mutationKey: [ConsumptionPlanningMutationKeys.createConsumptionPlan],
        mutationFn: mutationFn,
        onSuccess: (res) => {
            goToPlanList();
        },
    });
};
