// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: EnhancedTable

import * as React from "react";
import { ComponentType, ElementType } from "react";
import { observer } from "mobx-react-lite";
import { Box, Card, Checkbox, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PagerMeta, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { makeAutoObservable } from "mobx";
import { LoadingListItems } from "../progress/LoadingIndicators";
import { KnownDataType } from "../utils/formatter";
import { ActionConfig, ActionMenuButton } from "../actions/CommonActions";
import { FormattedDisplay } from "../FormattedDisplay";
import { TableFilter } from "./TableFilter";
import { ColumnSortFilterConfig, ListFilterableField, ListSortableField } from "../../modules/core/data/ListData";
import { getSortOrderLabel, SORT_ORDER, TableSort } from "./TableSort";
import NoResult from "../../assets/image_no_result.png";
import EmptyTable from "../../assets/image_empty_folder.png";
import { useNavigateToHelpCenterKb } from "../../modules/help/HelpCommon";
import { PaginationControl } from "./PaginationControl";
import { EmptyTableConfig, EmptyTableContent } from "./TableCommon";

// export const getPagerParamsFromTableInstance = (t: TableInstance<any>) => {
//     return new PagerParams().setPerPage(t.state.pageSize).setPage(t.state.pageIndex + 1);
// };

export const getInitialPagerState = (pagerParam: PagerParams) => {
    return {
        pageSize: pagerParam.getPerPage(),
        pageIndex: pagerParam.getPage() - 1,
    };
};

export interface FilterState<RequestType = any, FilterType = any> {
    fieldConfig: ListFilterableField<RequestType, any, any>;
    param: FilterType;
}

export interface SortState {
    field: {
        label: string;
        fieldId: any;
    };
    descending: {
        label: string;
        value: boolean;
    };
}

export class TableState<RowData> {
    pager = new PagerParams();
    sortState: SortState = null;
    filters: FilterState[] = [];
    sortFilterConfig: ColumnSortFilterConfig<any, any, any, any>;
    selectedRows: { [key: string]: RowData } = {};
    selectedGetter: (r: RowData) => string;
    selectDisabledGetter: (r: RowData) => boolean;

    constructor(
        pager: PagerParams,
        columnSortFilterConfig?: ColumnSortFilterConfig<any, any, any, any>,
        selectedGetter?: (r: RowData) => string,
        selectDisabledGetter?: (r: RowData) => boolean
    ) {
        this.pager = pager;
        this.sortFilterConfig = columnSortFilterConfig;
        this.selectedGetter = selectedGetter;
        this.selectDisabledGetter = selectDisabledGetter;
        if (columnSortFilterConfig) {
            this.setSort({
                field: columnSortFilterConfig.sort.defaultSort,
                descending: {
                    label: columnSortFilterConfig.sort.defaultSortDesc ? getSortOrderLabel(SORT_ORDER.DESCENDING) : getSortOrderLabel(SORT_ORDER.ASCENDING),
                    value: columnSortFilterConfig.sort.defaultSortDesc,
                },
            });
        }
        makeAutoObservable(this);
    }

    get selectable() {
        return !!this.selectedGetter;
    }

    get hasSelectedRows() {
        return Object.keys(this.selectedRows).length > 0;
    }

    get numOfSelectedRows() {
        return Object.keys(this.selectedRows).length;
    }

    isSelectDisabled(r: RowData) {
        if (!!this.selectDisabledGetter) {
            return this.selectDisabledGetter(r);
        } else {
            return false;
        }
    }

    isRowSelected(r: RowData) {
        const key = this.selectedGetter(r);
        return !!this.selectedRows[key];
    }

    allRowsSelected(r: RowData[]) {
        if (Object.keys(this.selectedRows).length > 0) {
            for (let row of r) {
                const key = this.selectedGetter(row);
                if (!this.selectedRows[key] && !this.isSelectDisabled(row)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    selectAllRows(r: RowData[]) {
        r.forEach((r) => {
            if (!this.isSelectDisabled(r)) {
                this.selectRow(r);
            }
        });
    }

    isAllRowsDisabled(r: RowData[]) {
        for (let row of r) {
            if (!this.isSelectDisabled(row)) {
                return false;
            }
        }
        return true;
    }

    deselectAllRows() {
        this.selectedRows = {};
    }

    deselectAllRowsOnPage(r: RowData[]) {
        r.forEach((r) => {
            this.deselectRow(r);
        });
    }

    setSelectedGetter(f: (r: RowData) => string) {
        this.selectedGetter = f;
    }

    selectRow(r: RowData) {
        const key = this.selectedGetter(r);
        if (!this.selectedRows[key]) {
            this.selectedRows[key] = r;
        }
    }

    deselectRow(r: RowData) {
        const key = this.selectedGetter(r);
        delete this.selectedRows[key];
    }

    setSort(config: SortState) {
        this.sortState = config;
    }

    toggleSortDirection() {
        this.sortState.descending.value = !this.sortState.descending.value;
        if (this.sortState.descending.label === getSortOrderLabel(SORT_ORDER.ASCENDING)) {
            this.sortState.descending.label = getSortOrderLabel(SORT_ORDER.DESCENDING);
        } else {
            this.sortState.descending.label = getSortOrderLabel(SORT_ORDER.ASCENDING);
        }
    }

    clearSort() {
        this.setSort({
            field: this.sortFilterConfig.sort.defaultSort,
            descending: {
                label: this.sortFilterConfig.sort.defaultSortDesc ? getSortOrderLabel(SORT_ORDER.DESCENDING) : getSortOrderLabel(SORT_ORDER.ASCENDING),
                value: this.sortFilterConfig.sort.defaultSortDesc,
            },
        });
    }

    setFilter(config: FilterState) {
        this.filters.push(config);
    }

    removeFilter(i: number) {
        this.filters.splice(i, 1);
    }

    clearFilters() {
        this.filters = [];
    }

    get isFilterActive() {
        return this.filters.length > 0;
    }
}

export interface ColumnDef<RowData, ColValueType = any> {
    id: any;
    label: string | React.ReactNode;
    getter: (d: RowData) => ColValueType;
    renderer?: (v: ColValueType, r: RowData) => React.ReactNode | string;
    dataType?: KnownDataType;
    maxWidth?: number;
    sort?: any;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    hidden?: boolean;
}

// ======================
// DataTable
// ======================
export interface DataTableProps<RowData, RowIDType = any> {
    state: TableState<RowData>;
    rows: RowData[];
    cols: ColumnDef<RowData>[];
    onTableStateChange?: (state: TableState<RowData>) => void;
    loading?: boolean;
    rowIdGetter?: (r: RowData) => RowIDType;
    rowActions?: (r: RowData) => ActionConfig[];
    pagerMeta?: PagerMeta.AsObject;
    emptyTableConfig?: EmptyTableConfig;
    emptyTableIcon?: React.ReactNode;
    emptyTableTitle?: React.ReactNode;
    emptyTableMessage?: React.ReactNode;
    emptyTableActionButton?: React.ReactNode;
    selectedGetter?: (r: RowData) => string;
    selectedActions?: React.ReactNode;
    rowItemLabel?: string;
    tableComponent?: ComponentType<any> | ElementType;
}

export const DataTable = observer(<RowData, RowIDType>(p: DataTableProps<RowData, RowIDType>) => {
    const goToHelpCenter = useNavigateToHelpCenterKb();

    const pagerOn = p.state && p.pagerMeta;

    const cols = p.cols.filter((c) => !c.hidden);
    if (p.rowActions) {
        cols.push(_makeActionsColumnDef(p));
    }

    return (
        <div>
            <Grid container justifyContent={"space-between"} mb={2}>
                <Grid>
                    <TableFilter state={p.state} onTableStateChange={p.onTableStateChange} />
                </Grid>
                <Grid>
                    <TableSort state={p.state} onTableStateChange={p.onTableStateChange} />
                </Grid>
            </Grid>
            <TableContainer component={p.tableComponent ?? Card}>
                {p.state?.selectable && p.state?.hasSelectedRows && (
                    <Box pt={2} pr={2} pl={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                        <Box>
                            <Typography>
                                {p.state.numOfSelectedRows} {p.rowItemLabel} Selected
                            </Typography>
                        </Box>
                        {p.selectedActions}
                    </Box>
                )}
                <Table aria-label="table">
                    <TableHead>
                        <TableRow>
                            {p.state?.selectable && (
                                <TableCell>
                                    <Checkbox
                                        disabled={p.state.isAllRowsDisabled(p.rows)}
                                        checked={p.state.allRowsSelected(p.rows)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                p.state.selectAllRows(p.rows);
                                            } else {
                                                p.state.deselectAllRowsOnPage(p.rows);
                                            }
                                        }}
                                    />
                                </TableCell>
                            )}
                            {cols.map((c) => _renderTableHeaderCell(c, p.state, p.onTableStateChange))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {p.loading && <TableLoadingIndicator />}
                        {!p.loading && !p.rows?.length && (
                            <EmptyTableContent emptyTableConfig={p.emptyTableConfig} cols={cols.length} isFilterActive={p.state?.isFilterActive} />
                        )}
                        {!p.loading &&
                            (p.rows || [])
                                .filter((a) => !!a)
                                .map((r, i) => {
                                    return _renderTableRow(r, cols, getRowID(r, p.rowIdGetter) || i, p.state);
                                })}
                    </TableBody>
                </Table>
                {pagerOn && <PaginationControl onTableStateChange={p.onTableStateChange} pagerMeta={p.pagerMeta} state={p.state} />}
            </TableContainer>
        </div>
    );
});

const _renderEmptyTableMessage = (
    cols: number,
    isFilterActive: boolean,
    actionButton: React.ReactNode,
    customIcon: React.ReactNode,
    customTitle: React.ReactNode,
    customMessage: React.ReactNode,
    navToHelpCenter: () => void
) => {
    if (isFilterActive) {
        return (
            <TableRow>
                <TableCell colSpan={cols} align={"center"}>
                    <Box pt={6} pb={6}>
                        <Box pb={2}>
                            <img src={NoResult} alt={"No Results Found"} height={"50px"} />
                        </Box>
                        <Box pb={1}>
                            <Typography variant={"h6"}>No Results Found</Typography>
                        </Box>
                        <Box>
                            <Typography variant={"body1"}>Try adjusting or clearing your filter(s) to find what you're looking for.</Typography>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        );
    }
    return (
        <TableRow>
            <TableCell colSpan={cols} align={"center"}>
                <Box pt={6} pb={6}>
                    <Box pb={2}>{customIcon ? customIcon : <img src={EmptyTable} alt={"No Data Yet"} height={"50px"} />}</Box>
                    <Box pb={1}>
                        <Typography variant={"h6"}>{customTitle ? customTitle : <>No Data Found</>}</Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography variant={"body1"}>
                            {customMessage ? (
                                customMessage
                            ) : (
                                <>
                                    Get started now or visit our <Link onClick={navToHelpCenter}>Help Center</Link> for additional information.
                                </>
                            )}
                        </Typography>
                    </Box>
                    <Box p={2}>{actionButton}</Box>
                </Box>
            </TableCell>
        </TableRow>
    );
};

const _getAlignment = <RowData,>(col: ColumnDef<RowData>) => {
    if (!!col.align) {
        return col.align;
    }
    return _getAlignByDataType(col.dataType);
};

const _getAlignByDataType = (t: KnownDataType) => {
    if (t === KnownDataType.NUMBER || t === KnownDataType.BOOL) {
        return "right";
    }
    return "left";
};

const _makeActionsColumnDef = <RowData,>(p: DataTableProps<RowData>): ColumnDef<RowData> => {
    return {
        id: "__table_action",
        label: "Actions",
        align: "right",
        getter: (r) => r,
        renderer: (v, r) => {
            return (
                <>
                    <ActionMenuButton actions={p.rowActions(r)} />
                </>
            );
        },
    };
};
const _renderTableHeaderCell = <RowData, ColValue>(
    col: ColumnDef<RowData, ColValue>,
    state: TableState<RowData>,
    onTableStateChange: (state: TableState<RowData>) => void
) => {
    const sortConfig = state?.sortFilterConfig?.sort.sortFields.find((s: ListSortableField) => s.fieldId === col.sort);

    if (!!sortConfig) {
        const defaultSort = state.sortFilterConfig.sort.defaultSort;
        const defaultDesc = state.sortFilterConfig.sort.defaultSortDesc;
        const active = state.sortState ? state.sortState?.field.fieldId === sortConfig.fieldId : defaultSort.fieldId === col.sort;

        const _getDirection = () => {
            if (!!state.sortState) {
                if (state.sortState.descending.value) {
                    return "desc";
                } else {
                    return "asc";
                }
            }
            if (defaultDesc) {
                return "desc";
            } else {
                return "asc";
            }
        };
        const onClick = () => {
            if (state.sortState.field.fieldId !== sortConfig.fieldId) {
                state.setSort({
                    descending: {
                        label: getSortOrderLabel(SORT_ORDER.ASCENDING),
                        value: false,
                    },
                    field: {
                        fieldId: sortConfig.fieldId,
                        label: sortConfig.label,
                    },
                });
                onTableStateChange(state);
            } else {
                state.toggleSortDirection();
                onTableStateChange(state);
            }
        };

        return (
            <TableCell key={col.id} variant={"head"} align={_getAlignment(col)}>
                <TableSortLabel active={active} direction={_getDirection()} onClick={onClick}>
                    {col.label}
                </TableSortLabel>
            </TableCell>
        );
    }
    return (
        <TableCell key={col.id} variant={"head"} align={_getAlignment(col)} width={col.maxWidth}>
            {col.label}
        </TableCell>
    );
};

const _renderTableRow = <RowData, RowIDType>(row: RowData, cols: ColumnDef<RowData>[], id: RowIDType, tableState: TableState<RowData>) => {
    return (
        <TableRow hover key={`${id}`}>
            {!!tableState?.selectedGetter && (
                <TableCell key={"select"}>
                    <Checkbox
                        disabled={tableState.isSelectDisabled(row)}
                        checked={tableState.isRowSelected(row)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                tableState.selectRow(row);
                            } else {
                                tableState.deselectRow(row);
                            }
                        }}
                    />
                </TableCell>
            )}
            {cols.map((c) => {
                return _renderTableCell(row, c);
            })}
        </TableRow>
    );
};

const _renderTableCell = <RowData, ColValue>(row: RowData, col: ColumnDef<RowData, ColValue>) => {
    const colValue = col.getter(row);
    let v: ColValue | React.ReactNode = colValue;
    if (!!col.renderer) {
        v = col.renderer(colValue, row);
    }

    // apply type formatter if it is raw value
    const isRawValue = !React.isValidElement(v) || typeof v !== "function";
    if (isRawValue) {
        v = _applyTableDataTypeFormatter(v, col.dataType);
    }

    return (
        <TableCell key={col.id} variant={"body"} align={_getAlignment(col)} width={col.maxWidth}>
            {v as React.ReactNode}
        </TableCell>
    );
};

const getRowID = <RowData, RowIDType>(row: RowData, rowIdGetter?: (r: RowData) => RowIDType) => {
    if (typeof rowIdGetter === "function") {
        return rowIdGetter(row);
    }
    return null;
};

const _applyTableDataTypeFormatter = (v: any, type: KnownDataType): React.ReactNode => {
    return <FormattedDisplay dataType={type} value={v} />;
};

// ======================
// TableLoadingIndicator
// ======================
interface TableLoadingIndicatorProps {}

export const TableLoadingIndicator: React.FC<TableLoadingIndicatorProps> = observer((p) => {
    return (
        <TableRow>
            <TableCell colSpan={30000}>
                <LoadingListItems count={2} />
            </TableCell>
        </TableRow>
    );
});
