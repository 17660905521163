// ======================
// MigrateOpsAwsWizard
// ======================

import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import React from "react";
import { StepConfig } from "../../../../common/stepper/StepperHelpers";
import { DynamicStepper } from "../../../../common/stepper/StepperComponents";

export enum AwsWizardStepId {
    GET_STARTED = "get-started",
    SYSTEM_AND_INTEGRATION = "system-and-integration",
    FINISH_UP = "finish-up",
}
interface MigrateOpsAwsWizardProps {}

export const MigrateOpsAwsWizard: React.FC<MigrateOpsAwsWizardProps> = (p) => {
    const [currentStep, setCurrentStep] = React.useState(0);
    const getStepConfigs = (): StepConfig[] => {
        const getStartedStep: StepConfig = {
            id: AwsWizardStepId.GET_STARTED,
            label: "Get Started",
            renderer: () => <></>,
        };

        return [getStartedStep];
    };

    const [stepConfigs, setStepConfigs] = React.useState<StepConfig[]>(getStepConfigs());

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Migrate to AWS"} />
            <DynamicStepper
                hideStepper
                stepperProps={{
                    activeStep: currentStep,
                    stepConfigs: stepConfigs,
                    startingStep: 0,
                    totalSteps: stepConfigs.length,
                }}
                orientation={"horizontal"}
                useTransitions
            />
        </ScreenContainer>
    );
};
