import React from "react";
import { observer } from "mobx-react-lite";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { Box, Button, ButtonProps, Card, CardHeader, Dialog } from "@mui/material";
import { ProjectInfoForm } from "../project/ProjectInfoForm";

// ======================
// EditProject
// ======================

interface EditProjectProps {}

export const EditProject: React.FC<EditProjectProps> = observer((props) => {
    const editProjectDialogState = useDialogState();

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    title={"Edit Project"}
                    subheader={"Here you can edit project details including name, description, and associated contact information."}
                    action={
                        <Box p={2}>
                            <EditProjectButton dialogState={editProjectDialogState} />
                        </Box>
                    }
                />
            </Card>
            <EditProjectDialog dialogState={editProjectDialogState} />
        </Box>
    );
});

// ======================
// EditProjectButton
// ======================

interface EditProjectButtonProps {
    dialogState: DialogState;
}

const EditProjectButton: React.FC<EditProjectButtonProps & Partial<ButtonProps>> = observer((props) => {
    const { dialogState, ...rest } = props;

    return (
        <Button variant={"outlined"} onClick={dialogState.open} {...rest}>
            Edit Info
        </Button>
    );
});

// ======================
// EditProjectDialog
// ======================

interface EditProjectDialogProps {
    dialogState: DialogState;
}

const EditProjectDialog: React.FC<EditProjectDialogProps> = observer((props) => {
    const { dialogState } = props;
    const fullScreen = useShouldDialogFullScreen();

    return (
        <Dialog open={dialogState.isOpen} fullScreen={fullScreen} maxWidth={"sm"} fullWidth onClose={dialogState.close}>
            {dialogState.isOpen && <ProjectInfoForm dialogState={dialogState} type={"edit"} />}
        </Dialog>
    );
});
