import { observer } from "mobx-react-lite";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import { DialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { useAppServices } from "../../app/services";
import * as React from "react";
import { useState } from "react";

// ======================
// GalaxyMigrateDeploymentCreateXrayParamsDialog
// ======================

interface GalaxyMigrateDeploymentCreateXrayParamsDialogProps {
    dialogState: DialogState;
    systemId: string;
    supportOnly?: boolean;
}

export const GalaxyMigrateDeploymentCreateXrayParamsDialog: React.FC<GalaxyMigrateDeploymentCreateXrayParamsDialogProps> = observer((p) => {
    const { dialogState, systemId, supportOnly } = p;
    const { dialogService, gmDeploymentService, supportService } = useAppServices();
    const fullScreen = useShouldDialogFullScreen();
    const [argumentList, setArgumentList] = useState("");

    const createXray = async () => {
        const argsList = argumentList.split("\n");
        const confirmed = await dialogService.addConfirmDialog({
            title: "Create X-Ray",
            autoConfirmationQuestionLine: true,
        });
        if (confirmed) {
            await gmDeploymentService.getMigrationXRay(systemId, argsList);
            await supportService.deploymentXrays.fetchData(systemId);
            dialogState.close();
        }
    };

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} maxWidth={"md"} fullWidth>
            <DialogTopBar dialogState={dialogState} title={"Create X-Ray"} />
            <DialogContent>
                <Typography>List additional arguments here on separate lines (optional):</Typography>
                <br />
                <TextField
                    label={"Arguments"}
                    multiline
                    fullWidth
                    variant={"filled"}
                    rows={6}
                    value={argumentList}
                    onChange={(e) => setArgumentList(e.target.value)}
                />
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"outlined"} color={"neutral"}>
                    Cancel
                </Button>
                <Button variant={"contained"} onClick={createXray}>
                    Create X-Ray
                </Button>
            </DialogActions>
        </Dialog>
    );
});
