import { isDevelopment } from "../../common/utils/util";

export const getApiHost = () => GRPC_API_HOST;
export const getApiEndpoint = () => {
    // for localhost development, we have switched to use a local middleware proxy that proxies to VITE_WEB_API_HOST
    // this is needed because of the cookie auth change
    if (isDevelopment()) {
        return "/SPECIAL-LOCALHOST-WEBAPI-PROXY";
    }
    if (GRPC_API_HOST) {
        return `https://${GRPC_API_HOST}`;
    }
    // return /webapi for private edition or in the future if we use cookie
    return `/webapi`;
};
export const GRPC_API_HOST = import.meta.env.VITE_WEB_API_HOST;
