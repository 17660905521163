import { create } from "zustand";
import { v4 as uuid } from "uuid";
import { formatErrorMessage } from "../../../common/utils/errorUtil";
import { AlertDialogConfig, ConfirmDialogConfig } from "./DialogService";

export interface GlobalDialogState {
    alertDialogs: AlertDialogConfig[];
    confirmDialogs: ConfirmDialogConfig[];
    closeAllAlertDialogs: () => void;
    addConfirmDialog: (config: ConfirmDialogConfig) => Promise<boolean>;
    addAlertDialog: (config: AlertDialogConfig) => Promise<any>;
    addErrorAlertDialog: (e: Error, waitUntilClose?: boolean) => Promise<any>;
    catchAndAlertError: <T>(promise: Promise<T>, waitUntilClose: boolean) => Promise<T>;
}

export const useGlobalDialogState = create<GlobalDialogState>((set, get) => ({
    alertDialogs: [],
    confirmDialogs: [],
    closeAllAlertDialogs: () => {
        set({
            alertDialogs: [],
        });
    },
    addConfirmDialog: async (config: ConfirmDialogConfig) => {
        const id = uuid();

        let dialogConfig: ConfirmDialogConfig = null;
        const confirmed: boolean = await new Promise((resolve) => {
            dialogConfig = {
                id,
                autoConfirmationQuestionLine: true,
                ...config,
                onClose: (confirmed: boolean) => {
                    resolve(confirmed);
                    if (!!config.onClose) {
                        config.onClose(confirmed);
                    }
                },
            };
            set((state) => ({
                confirmDialogs: [...state.confirmDialogs, dialogConfig],
            }));
        });
        set((state) => {
            const newDialogs = [...state.confirmDialogs];
            newDialogs.splice(
                state.confirmDialogs.findIndex((a) => a.id === id),
                1
            );
            return {
                confirmDialogs: newDialogs,
            };
        });
        return confirmed;
    },
    addAlertDialog: async (config: AlertDialogConfig) => {
        const id = uuid();

        let dialogConfig: AlertDialogConfig = null;
        const ret = await new Promise((resolve) => {
            dialogConfig = {
                id,
                ...config,
                onClose: (a: any) => resolve(a),
            };
            set((state) => ({
                alertDialogs: [...state.alertDialogs, dialogConfig],
            }));
        });
        set((state) => {
            const newDialogs = [...state.alertDialogs];
            newDialogs.splice(
                state.alertDialogs.findIndex((a) => a.id === id),
                1
            );
            return {
                alertDialogs: newDialogs,
            };
        });
        return ret;
    },
    addErrorAlertDialog: async (e: Error, waitUntilClose = true, options?: Partial<AlertDialogConfig>) => {
        let errorMsg = e.toString();
        if (e.message) {
            errorMsg = e.message;
        }
        const promise = get().addAlertDialog({ title: "Error", message: formatErrorMessage(errorMsg), ...options });
        if (waitUntilClose) {
            return await promise;
        }
    },
    catchAndAlertError: async <T>(promise: Promise<T>, waitUntilClose = false): Promise<T> => {
        try {
            return await promise;
        } catch (e) {
            await get().addErrorAlertDialog(e as Error, waitUntilClose);
            throw e;
        }
    },
}));
