// ======================
// FormattedInfoBox
// ======================

import { Box, BoxProps, Typography } from "@mui/material";
import * as React from "react";

interface FormattedInfoBoxProps {
    icon: React.ReactNode;
    title: string;
    message: string;
    action: React.ReactNode;
    boxWrapperProps?: Partial<BoxProps>;
}

export const FormattedInfoBox: React.FC<FormattedInfoBoxProps> = (p) => {
    return (
        <Box textAlign={"center"} {...p.boxWrapperProps}>
            <Box pb={2}>{p.icon}</Box>
            <Box pb={1}>
                <Typography variant={"h6"}>{p.title}</Typography>
            </Box>
            <Box pb={2}>
                <Typography variant={"body1"}>{p.message}</Typography>
            </Box>
            <Box p={2}>{p.action}</Box>
        </Box>
    );
};
