import { Box, Card, Link, TableCell, TableRow, Typography } from "@mui/material";
import { ServerListData } from "../../modules/core/data/ListData";
import { HELP_CENTER_URL } from "../../modules/help/HelpCommon";
import * as React from "react";
import EmptyTable from "../../assets/empty_states/empty-generic.svg";
import { useIsDesktop } from "../../modules/layout/MainLayout";

export interface EmptyTableConfig {
    icon?: React.ReactNode;
    iconPath?: string;
    title?: React.ReactNode;
    message?: React.ReactNode;
    actionButton?: React.ReactNode;
    hideHelpLink?: boolean;
}

export const useIsTableEmpty = (d: ServerListData<any, any>) => {
    return d.ready && d.data?.getItemsList()?.length > 0;
};

// ======================
// EmptyTableContent
// ======================

interface EmptyTableContentProps {
    emptyTableConfig?: EmptyTableConfig;
    isFilterActive?: boolean;
    cols?: number;
}

export const EmptyTableContent: React.FC<EmptyTableContentProps> = (p) => {
    const { emptyTableConfig, isFilterActive, cols } = p;
    const title = isFilterActive ? "No Results Found" : emptyTableConfig?.title || "No Data Found";
    const message = isFilterActive ? "Try adjusting or clearing your filter(s) to find what you're looking for." : emptyTableConfig?.message || null;
    const actionButton = isFilterActive ? null : emptyTableConfig?.actionButton;
    const iconSrc = isFilterActive ? EmptyTable : emptyTableConfig?.iconPath || EmptyTable;
    const isDesktop = useIsDesktop();

    const content = (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box pt={6} pb={6} textAlign={"center"}>
                <Box pb={2}>{emptyTableConfig?.icon || <img src={iconSrc} alt={"No Results Found"} height={"100px"} />}</Box>
                <Box pb={1}>
                    <Typography variant={"h6"}>{title}</Typography>
                </Box>
                <Box pb={2} pr={isDesktop ? 10 : 2} pl={isDesktop ? 10 : 2}>
                    <Typography variant={"subtitle2"}>{message}</Typography>
                </Box>
                {isFilterActive ? null : (
                    <>
                        {actionButton && (
                            <Box pb={2} pr={2} pl={2}>
                                {actionButton}
                            </Box>
                        )}
                        {!emptyTableConfig?.hideHelpLink && (
                            <Box pr={isDesktop ? 10 : 2} pl={isDesktop ? 10 : 2}>
                                <Typography color={"textSecondary"} variant={"caption"}>
                                    <>
                                        {`To learn more about Cirrus Data Cloud and gain access to our support guides, please visit our 
                                            `}
                                        <Link href={HELP_CENTER_URL} target={"_blank"}>
                                            {"Customer Center"}
                                        </Link>
                                        {"."}
                                    </>
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );

    return cols ? (
        <TableRow>
            <TableCell colSpan={cols}>{content}</TableCell>
        </TableRow>
    ) : (
        <Card>{content}</Card>
    );
};
