// ======================
// MigrateOpsListView
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { MigrateOpsNewOperationButton } from "./MigrateOpsNewOperationPage";
import { useArchiveOperation, useListMigrateOps } from "./migrate_ops_hooks";
import * as React from "react";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryListTable } from "../../common/table/QueryListTable";
import { OperationListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { Box, Button, Card, CardContent, Chip, FormControlLabel, IconButton, Link, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { MdLabelOutline, MdListAlt, MdOutlineTimer } from "react-icons/md";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getMigrateOpStatusLabel, getOperationRecipeLabel, MigrateOpsStatusIcon, MigrateOpStatusChip, useNavigateToOpDetails } from "./MigrateOpsCommon";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { useNavigateToDeploymentDetails } from "../galaxymigrate/GalaxyMigrateCommon";
import { HiOutlineServer } from "react-icons/hi";
import { IoArchive, IoDocumentText } from "react-icons/io5";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import EmptyTableIcon from "../../assets/empty_states/empty-migrateops.svg";
import { IosSwitch } from "../../common/switch/IosSwitch";
import { grey } from "@mui/material/colors";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { HubUser } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { MigrateOpsQueryKeys } from "../../common/QueryKeys";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { LabelChip } from "../labels/labelCommon";

interface MigrateOpsListViewProps {}

export const MigrateOpsListView: React.FC<MigrateOpsListViewProps> = (p) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const projectId = useCurrentProjectID();

    const [includeArchived, setIncludeArchived] = useState(false);

    const queryResult = useListMigrateOps(projectId, includeArchived, pageIndex, pageSize);
    const openHelpArticle = useOpenHelpArticle();

    const actions =
        queryResult.data?.pagerMeta?.totalItems > 0 ? (
            <Stack direction={"row"} spacing={1}>
                <Button onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS)} variant={"outlined"} color={"neutral"}>
                    {"Learn More"}
                </Button>
                <MigrateOpsNewOperationButton />
            </Stack>
        ) : null;

    return (
        <>
            <ScreenContainer>
                <ScreenTitleBar title={"Migration Operations"} actions={actions} />
                <Box pb={1} display={"flex"} justifyContent={"end"}>
                    <FormControlLabel
                        sx={{}}
                        control={
                            <IosSwitch
                                sx={{ marginLeft: 1, marginRight: 1 }}
                                value={includeArchived}
                                onChange={(e) => {
                                    setIncludeArchived(e.target.checked);
                                }}
                            />
                        }
                        label={<Typography fontWeight={600}>{"Show Archived"}</Typography>}
                    />
                </Box>
                <QueryListTable
                    listComponent={MigrateOpsListCard}
                    data={queryResult.data?.itemsList}
                    pagination={{ pageIndex, pageSize }}
                    pageCount={queryResult.data?.pagerMeta.totalPages}
                    setPagination={setPagination}
                    error={queryResult.error}
                    isError={queryResult.isError}
                    isLoading={queryResult.isLoading}
                    refetch={queryResult.refetch}
                    queryKey={MigrateOpsQueryKeys.listProjectOperations}
                    emptyTableConfig={{
                        iconPath: EmptyTableIcon,
                        title: "Enterprise-Scale Automation with MigrateOps",
                        message:
                            "Automate your enterprise-scale data mobility workflows today with MigrateOps, Cirrus Data Cloud's Data Mobility as Code automation platform.",
                        actionButton: (
                            <Stack direction={"column"} spacing={2} width={"100%"} justifyContent={"center"} alignItems={"center"}>
                                <MigrateOpsNewOperationButton />
                            </Stack>
                        ),
                    }}
                />
            </ScreenContainer>
        </>
    );
};

// ======================
// MigrateOpsListCard
// ======================

interface MigrateOpsListCardProps {
    data: OperationListItem.AsObject;
}

export const MigrateOpsListCard: React.FC<MigrateOpsListCardProps> = (p) => {
    const { data } = p;
    const opInfo = data.info;
    const steps = data.stepsList;
    const currentStepKind = steps.find((s) => s.stepNumber === opInfo.currentStep)?.kind;
    const currentStepDescriptor = opInfo.recipe.tasksList.find((t) => t.kind === currentStepKind);
    const goToOpDetails = useNavigateToOpDetails();
    const goToDeploymentDetails = useNavigateToDeploymentDetails();
    const labels = opInfo.labelsList;

    return (
        <Card sx={{ marginBottom: 2 }}>
            <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        lg: 3,
                    }}
                >
                    <CardContent>
                        <Stack spacing={2}>
                            <Stack spacing={1}>
                                <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                    <MigrateOpStatusChip status={opInfo.status} />
                                    <OperationLabelsDisplay operationLabels={labels} />
                                    <ArchiveOperationDisplay
                                        opStatus={opInfo.status}
                                        opId={opInfo.id}
                                        archivedAt={opInfo.archivedAt}
                                        archivedBy={opInfo.archivedBy}
                                    />
                                </Stack>
                                <Link onClick={() => goToOpDetails(opInfo.id)} variant={"h5"} fontWeight={600}>
                                    {opInfo.name || `Operation #${opInfo.id}`}
                                </Link>
                            </Stack>
                            <Stack>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <IoDocumentText />
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {"#"}
                                        {opInfo.id}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                    <MdListAlt size={18} />
                                    <Typography variant={"subtitle2"} color={"textSecondary"}>
                                        {`${getOperationRecipeLabel(opInfo.recipe.id)}`}
                                    </Typography>
                                </Stack>
                                <Tooltip title={formatKnownDataType(convertTimestampObjectToDate(opInfo.createdAt), KnownDataType.DATE)}>
                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                        <MdOutlineTimer size={18} />
                                        <Box>
                                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                                {opInfo.createdAt
                                                    ? `Created ${formatKnownDataType(
                                                          convertTimestampObjectToDate(opInfo.createdAt),
                                                          KnownDataType.DATE_RELATIVE
                                                      )}${opInfo.createdByUser ? ` by ${getUserFullNameFromObject(opInfo.createdByUser)}` : ""}`
                                                    : ""}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Tooltip>
                                {!!opInfo.deployment && (
                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                        <HiOutlineServer size={18} />
                                        <Box>
                                            <Link onClick={() => goToDeploymentDetails(opInfo.deployment?.systemId)}>{opInfo.deployment?.systemName}</Link>
                                        </Box>
                                    </Stack>
                                )}
                            </Stack>
                        </Stack>
                    </CardContent>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        lg: 9,
                    }}
                >
                    <CardContent>
                        <Box pt={1}>
                            <DarkFlatOutlinedCard>
                                <CardContent>
                                    <Box>
                                        <Grid container mb={2}>
                                            {steps.map((s, i) => {
                                                const stepRecipe = opInfo.recipe.tasksList.find((t) => t.kind === s.kind);
                                                return (
                                                    <Grid key={s.kind}>
                                                        <Stack direction={"row"} alignItems={"center"}>
                                                            <Tooltip
                                                                arrow
                                                                key={s.kind}
                                                                title={
                                                                    <Box>
                                                                        <Typography fontWeight={600}>{stepRecipe?.name}</Typography>
                                                                        <Typography>
                                                                            {"Status: "}
                                                                            {getMigrateOpStatusLabel(s.status)}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {"Run #"}
                                                                            {s.attempt}
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            >
                                                                <Box>
                                                                    <MigrateOpsStatusIcon status={s.status} size={40} />
                                                                </Box>
                                                            </Tooltip>
                                                            {i !== steps.length - 1 && (
                                                                <Box width={15} height={"1px"} sx={{ background: "rgba(255,255,255,.3)" }} />
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography color={"textSecondary"}>{`(${opInfo.currentStep}/${steps.length})`}</Typography>
                                            <Typography>{currentStepDescriptor?.description}</Typography>
                                        </Stack>
                                        <Box display={"flex"}>
                                            <Typography color={"textSecondary"}>{`Last Updated:`}&nbsp;</Typography>
                                            <Typography>
                                                {formatKnownDataType(
                                                    convertTimestampObjectToDate(opInfo.lastPingAt ?? opInfo.endedAt ?? opInfo.startedAt ?? opInfo.createdAt),
                                                    KnownDataType.DATE_RELATIVE
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </DarkFlatOutlinedCard>
                        </Box>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
};

// ======================
// ArchiveOperationButton
// ======================

interface ArchiveOperationDisplayProps {
    opStatus: OperationStatus.OperationStatus;
    opId: number;
    archivedBy: HubUser.AsObject;
    archivedAt: Timestamp.AsObject;
}

export const ArchiveOperationDisplay: React.FC<ArchiveOperationDisplayProps> = (p) => {
    const { opStatus, opId, archivedBy, archivedAt } = p;
    const archiveOperation = useArchiveOperation();
    const globalDialogState = useGlobalDialogState();

    const validOpStatuses = [OperationStatus.OperationStatus.COMPLETED, OperationStatus.OperationStatus.FAILED, OperationStatus.OperationStatus.CANCELLED];

    const handleArchiveOperation = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            title: "Archive Operation",
            message: "Are you sure you want to archive this operation? Operation data will be deleted after 30 days.",
            okButtonLabel: "Archive Operation",
            autoConfirmationQuestionLine: false,
        });

        if (confirmed) {
            archiveOperation.mutate(opId);
        }
    };

    return validOpStatuses.includes(opStatus) && !archivedBy ? (
        <Tooltip arrow title={"Archive Operation"}>
            <IconButton size={"small"} onClick={handleArchiveOperation}>
                <IoArchive color={grey[400]} size={18} />
            </IconButton>
        </Tooltip>
    ) : !!archivedBy ? (
        <Tooltip
            arrow
            title={
                <Box>
                    <Typography variant={"caption"}>
                        {`Archived by ${getUserFullNameFromObject(archivedBy)} at ${formatKnownDataType(archivedAt, KnownDataType.DATE)}`}
                    </Typography>
                </Box>
            }
        >
            <Chip size={"small"} label={"Archived"} />
        </Tooltip>
    ) : null;
};

interface OperationLabelsProps {
    operationLabels: Array<Label.AsObject>;
}

export const OperationLabelsDisplay: React.FC<OperationLabelsProps> = (p) => {
    const theme = useTheme();
    const labels = p.operationLabels;

    return (
        labels.length > 0 && (
            <Box pt={0.5}>
                <BlackTooltip
                    title={
                        <Box p={1}>
                            <Stack spacing={1}>
                                {labels.map((l) => (
                                    <LabelChip key={l.name} label={l.name} description={l.description} color={l.color} />
                                ))}
                            </Stack>
                        </Box>
                    }
                    arrow
                >
                    <Box>
                        <MdLabelOutline size={20} color={theme.palette.warning.main} />
                    </Box>
                </BlackTooltip>
            </Box>
        )
    );
};
