// Project: GalaxyComplete
// Created: 9/27/20 by sammy
// File: GmMigrationWizard

import * as React from "react";
import { observer } from "mobx-react-lite";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useAppServices } from "../../app/services";
import { GmMigrationType, GmMigrationWizardState, GmMigrationWizardStep } from "../GmMigrationService";
import { GmMigrationWizardComputeMigrationSourcePreparationStep, GmMigrationWizardVmwareVmConfigStep } from "./computeMigration/GmMigrationWizardVmware";
import { getIsComputeMigration, renderWizardStepText } from "./GmMigrationWizardUtils";
import { GmMigrationWizardDeploymentSelectionStep } from "./GmMigrationWizardDeploymentSelectionStep";
import { GmMigrationWizardMigrationTypeStep } from "./GmMigrationWizardMigrationTypeSelectionStep";
import { GmMigrationWizardVolumesSelectionStep } from "./GmMigrationWizardVolumeSelectionStep";
import { GmMigrationWizardParametersStep } from "./GmMigrationWizardParametersStep";
import { DynamicHorizontalMobXStepper } from "../../../common/stepper/MobXStepperComponents";
import { StepConfig } from "../../../common/stepper/StepperHelpers";

// ======================
// GalaxyMigrateMigrationWizardScreen
// ======================
interface GalaxyMigrateMigrationWizardScreenProps {}

export const GalaxyMigrateMigrationWizardScreen: React.FC<GalaxyMigrateMigrationWizardScreenProps> = observer((p) => {
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`New Migration Session`} />
            <GalaxyMigrateMigrationWizard />
        </ScreenContainer>
    );
});

// ======================
// GalaxyMigrateMigrationWizard
// ======================
interface GalaxyMigrateMigrationWizardProps {}

const GalaxyMigrateMigrationWizard: React.FC<GalaxyMigrateMigrationWizardProps> = observer((p) => {
    const { gmMigrationService } = useAppServices();
    const wizardState = gmMigrationService.wizardState;

    return <DynamicHorizontalMobXStepper stepConfigs={getMigrationWizardStepConfigs(wizardState)} stepperState={wizardState.stepperState} />;
});

export const getMigrationWizardStepConfigs = (wizardState: GmMigrationWizardState): StepConfig[] => {
    const deploymentSelectionStepConfig: StepConfig = {
        id: "deploymentSelection",
        label: renderWizardStepText(GmMigrationWizardStep.SOURCE_DEPLOYMENT_SELECTION, wizardState),
        renderer: () => <GmMigrationWizardDeploymentSelectionStep wizardState={wizardState} />,
    };
    const migrationTypeSelectionStepConfig: StepConfig = {
        id: "migrationTypeSelection",
        label: renderWizardStepText(GmMigrationWizardStep.MIGRATION_TYPE, wizardState),
        renderer: () => <GmMigrationWizardMigrationTypeStep wizardState={wizardState} />,
    };

    let stepConfigs = [deploymentSelectionStepConfig, migrationTypeSelectionStepConfig];

    if (getIsComputeMigration(wizardState.selectedMigrationType)) {
        const computeMigrationSourcePrepStepConfig: StepConfig = {
            id: "computeMigrationSourcePrep",
            label: renderWizardStepText(GmMigrationWizardStep.COMPUTE_MIGRATION_SOURCE_PREP, wizardState),
            renderer: () => <GmMigrationWizardComputeMigrationSourcePreparationStep wizardState={wizardState} />,
        };
        stepConfigs.push(computeMigrationSourcePrepStepConfig);
    }

    if (wizardState.selectedMigrationType === GmMigrationType.COMPUTE_VMWARE) {
        const vmwareVmConfig: StepConfig = {
            id: "vmConfig",
            label: renderWizardStepText(GmMigrationWizardStep.COMPUTE_VMWARE_VM_CONFIG, wizardState),
            renderer: () => <GmMigrationWizardVmwareVmConfigStep wizardState={wizardState} />,
        };
        stepConfigs.push(vmwareVmConfig);
    }

    const volumesSelectionStepConfig: StepConfig = {
        id: "volumesSelection",
        label: renderWizardStepText(GmMigrationWizardStep.VOLUMES_SELECTION, wizardState),
        renderer: () => <GmMigrationWizardVolumesSelectionStep wizardState={wizardState} />,
    };

    const parametersStepConfig: StepConfig = {
        id: "migrationTypeSelection",
        label: renderWizardStepText(GmMigrationWizardStep.MIGRATION_PARAMETERS, wizardState),
        renderer: () => <GmMigrationWizardParametersStep wizardState={wizardState} />,
    };

    stepConfigs.push(volumesSelectionStepConfig, parametersStepConfig);

    return stepConfigs;
};
