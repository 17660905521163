import { useGrpcApiStore } from "../grpc/grpcApiStore";
import {
    CreateHostConfigurationReport,
    CreateMigrationSessionReport,
    CreateMigrationSourceDataAssessmentReport,
    CreateProjectLicenseReport,
    GetReportData,
    ListProjectReports,
} from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { ReportMutationKeys, ReportQueryKeys } from "../../common/QueryKeys";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import * as React from "react";
import { UseMutationOptions, useQuery } from "@tanstack/react-query";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";
import { MigrationSourceDataAssessmentReport, ReportInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/report_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { mockListPagerMeta, mockUser1 } from "../core/testutils/fixtures/FixturesCommon";

export const useCreateMigrationSessionReport = (options?: UseMutationOptions<any, any, any, any>) => {
    const apis = useGrpcApiStore();

    const mutationFn = async (req: CreateMigrationSessionReport.Request) => {
        const res = await apis.reportService.createMigrationSessionReport(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [ReportMutationKeys.createMigrationSessionReport],
            mutationFn: mutationFn,
            ...options,
        },
        "Generating Report..."
    );
};

export const useCreateProjectLicenseReport = (options?: UseMutationOptions<any, any, any, any>) => {
    const apis = useGrpcApiStore();

    const mutationFn = async (req: CreateProjectLicenseReport.Request) => {
        const res = await apis.reportService.createProjectLicenseReport(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [ReportMutationKeys.createMigrationSessionReport],
            mutationFn: mutationFn,
            ...options,
        },
        "Generating Report..."
    );
};

export const useCreateHostConfigurationReport = (options?: UseMutationOptions<any, any, any, any>) => {
    const apis = useGrpcApiStore();

    const mutationFn = async (req: CreateHostConfigurationReport.Request) => {
        const res = await apis.reportService.createHostConfigurationReport(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [ReportMutationKeys.createHostConfigurationReport],
            mutationFn: mutationFn,
            ...options,
        },
        "Generating Report..."
    );
};

export const useCreateMigrationSourceDataAssessmentReport = (options?: UseMutationOptions<any, any, any, any>) => {
    const apis = useGrpcApiStore();

    const mutationFn = async (req: CreateMigrationSourceDataAssessmentReport.Request) => {
        const res = await apis.reportService.createMigrationSourceDataAssessmentReport(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [ReportMutationKeys.createMigrationSourceDataAssessmentReport],
            mutationFn: mutationFn,
            ...options,
        },
        "Generating Report..."
    );
};

export const useListProjectReports = (projectId: string, pagerParam?: PagerParams, reportTypeFilter?: ReportType.ReportType) => {
    const apis = useGrpcApiStore();
    const sortFilterState = useGlobalTableSortFilterState().tableStateMap.get(ReportQueryKeys.listProjectReports);
    const queryFn = async () => {
        const req = new ListProjectReports.Request()
            .setProjectId(projectId)
            .setReportTypeFilter(new ReportType().setValue(reportTypeFilter))
            .setPageParams(pagerParam);

        if (sortFilterState.sortState) {
            req.setSortParam(sortFilterState.sortFilterConfig.sort.getSortParam(sortFilterState.sortState));
        }

        for (let filter of sortFilterState.filters || []) {
            filter.fieldConfig.addFilterToRequest(req, filter.param);
        }

        const res = await apis.reportService.listProjectReports(req, null);
        // return new ListProjectReports.Response()
        //     .setPagerMeta(mockListPagerMeta)
        //     .setReportsList([
        //         new ReportInfo().setReportId(1).setReportType(ReportType.ReportType.MIGRATION_SOURCE_DATA_ASSESSMENT_REPORT)
        //             .setCreatedAt(new Timestamp())
        //             .setCreatedBy(mockUser1)
        //             .setUserNotes('Notes')
        //             .setMsdarMeta(new MigrationSourceDataAssessmentReport.Metadata()
        //                 .setHostCount(2)
        //                 .setSessionIdsList(['sadfas', 'sadfasdf'])
        //                 .setThinDataRatio(50)
        //                 .setTotalCapacity(234243242423423)
        //                 .setTotalThinData(23423423)
        //                 .setVolumeCount(2)
        //             )
        //     ]).toObject()
        return res.toObject();
    };

    return useQuery({
        queryKey: [
            ReportQueryKeys.listProjectReports,
            projectId,
            pagerParam.toObject(),
            reportTypeFilter,
            sortFilterState.sortState?.field.label,
            sortFilterState.sortState?.descending.label,
            sortFilterState.filters.map((f) => `${f.fieldConfig.label}: ${f.param.toObject().op} ${f.param.toObject().value}`),
        ],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetReportData = (reportId: number) => {
    const apis = useGrpcApiStore();

    const queryFn = async () => {
        const req = new GetReportData.Request().setReportId(reportId);

        const res = await apis.reportService.getReportData(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [ReportQueryKeys.getReportData, reportId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};
