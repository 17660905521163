import { useGetPendingPeLicenseRequest, useGetPrivateEditionLocalStatus } from "./private_edition_hooks";
import { PeLicenseInteractionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/pe_license_interaction_type_pb";
import { PrivateEditionLocalStatus } from "gc-web-proto/galaxycompletepb/apipb/pelocal_api_pb";

export const useIsPELicenseActivated = () => {
    const { data } = useGetPrivateEditionLocalStatus();
    return !!data?.status?.licenseActivated;
};
export const useIsPELicenseNeedAuthentication = () => {
    const activated = useIsPELicenseActivated();
    const { data } = useGetPrivateEditionLocalStatus();
    return activated && !data?.status?.licenseAuthenticated;
};

export const useHasPendingPELicenseRequest = () => {
    const { data } = useGetPendingPeLicenseRequest();
    return !!data?.licenseRequest;
};

export const getPeLicenseInteractionTypeDisplayValue = (type: PeLicenseInteractionType.PeLicenseInteractionType, alreadyActivated?: boolean) => {
    if (type === PeLicenseInteractionType.PeLicenseInteractionType.ACTIVATION) {
        return alreadyActivated ? "Renewal" : "Activation";
    } else if (type === PeLicenseInteractionType.PeLicenseInteractionType.CHECK_IN) {
        return "Check In";
    } else if (type === PeLicenseInteractionType.PeLicenseInteractionType.WITHDRAWAL) {
        return "Withdrawal";
    } else {
        return "Unknown";
    }
};
export const getCdcSaaSUrlForThisPE = (peLocalStatus: PrivateEditionLocalStatus.AsObject) => {
    return !!peLocalStatus?.cdcProjectId ? `https://cloud.cirrusdata.com/projects/${peLocalStatus?.cdcProjectId}` : "https://cloud.cirrusdata.com/";
};
