import { observer } from "mobx-react-lite";
import { Box, Button, Chip, Dialog, DialogTitle, Divider, ListSubheader, Popover, SvgIcon, Theme } from "@mui/material";
import React from "react";
import { SortState, TableState } from "./DataTable";
import { ListSortableField } from "../../modules/core/data/ListData";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { FormAutocompleteField } from "../form/FormComponents";
import { PopoverState, usePopoverState } from "../popover/PopoverService";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";

const useSortStyles = () => ({
    popover: {
        width: "300",
    },
});

export enum SORT_ORDER {
    ASCENDING,
    DESCENDING,
}

interface TableSortProps<RowData> {
    state: TableState<RowData>;
    onTableStateChange?: (state: TableState<RowData>) => void;
}

export const TableSort = observer(<RowData,>(props: TableSortProps<RowData>) => {
    const { state, onTableStateChange } = props;
    const sortPopoverState = usePopoverState();

    if (!state?.sortFilterConfig) {
        return null;
    }

    const _renderSortButton = () => {
        const icon = state.sortState.descending.value ? (
            <SvgIcon>
                <FaSortAmountDown />
            </SvgIcon>
        ) : (
            <SvgIcon>
                <FaSortAmountUp />
            </SvgIcon>
        );
        return (
            <Button color={"inherit"} startIcon={icon} onClick={sortPopoverState.open}>
                {`Sort By: ${state.sortState.field.label}`}
            </Button>
        );
    };

    return (
        <>
            <Box display={"flex"} justifyContent={"flex-end"}>
                {_renderSortButton()}
            </Box>
            <TableSortPopover state={state} popoverState={sortPopoverState} onTableStateChange={onTableStateChange} />
        </>
    );
});

// ======================
// TableSortPopover
// ======================

interface TableSortPopoverProps<RowData> {
    state: TableState<RowData>;
    popoverState: PopoverState;
    onTableStateChange?: (state: TableState<RowData>) => void;
}

const TableSortPopover = observer(<RowData,>(props: TableSortPopoverProps<RowData>) => {
    const { state, popoverState, onTableStateChange } = props;

    const styles = useSortStyles();

    return (
        <Popover
            open={popoverState.isOpen}
            onClose={popoverState.close}
            anchorEl={popoverState.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            sx={styles.popover}
        >
            <Box width={300}>
                <ListSubheader>Sort By</ListSubheader>
                <TableSortForm state={state} closeForm={popoverState.close} onTableStateChange={onTableStateChange} />
            </Box>
        </Popover>
    );
});

// ======================
// TableSortForm
// ======================

interface TableSortFormProps<RowData> {
    state: TableState<RowData>;
    closeForm: () => void;
    onTableStateChange?: (state: TableState<RowData>) => void;
}

export const TableSortForm = observer(<RowData,>(props: TableSortFormProps<RowData>) => {
    const { state, closeForm, onTableStateChange } = props;

    const schema = yup.object({
        field: yup.object({
            label: yup.string().required("Select criteria."),
            fieldId: yup.mixed(),
        }),
        descending: yup.object({
            label: yup.string().required("Select sort order."),
            value: yup.boolean(),
        }),
    });

    const _getInitialValues = (): SortState => {
        const defaultValue = state.sortFilterConfig.sort.defaultSort;
        if (!!state.sortState) {
            return state.sortState;
        } else {
            return {
                field: {
                    label: defaultValue.label,
                    fieldId: defaultValue.fieldId,
                },
                descending: {
                    label: getSortOrderLabel(SORT_ORDER.ASCENDING),
                    value: state.sortFilterConfig.sort.defaultSortDesc,
                },
            };
        }
    };

    const sortOptions = state.sortFilterConfig.sort.sortFields;

    return (
        <Formik
            initialValues={_getInitialValues()}
            validationSchema={schema}
            enableReinitialize
            onSubmit={async (values) => {
                state.setSort(values);
                closeForm();
                onTableStateChange(state);
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <Box p={2}>
                            <FormAutocompleteField<ListSortableField, false, true, false>
                                label={"Criteria"}
                                name={"field"}
                                options={sortOptions || []}
                                errorGetter={(v: ListSortableField) => v.label}
                                getOptionLabel={(o: ListSortableField) => o.label}
                                disableClearable
                                blurOnSelect
                            />
                            <br />
                            <FormAutocompleteField<SortSelectionType, false, true, false>
                                label={"Sort Order"}
                                name={"descending"}
                                options={sortOrderList}
                                errorGetter={(v: SortSelectionType) => v.label}
                                getOptionLabel={(o: SortSelectionType) => o.label}
                                disableClearable
                                blurOnSelect
                            />
                        </Box>
                        <Divider />
                        <Box display={"flex"} justifyContent={"space-between"} p={2}>
                            <Button
                                variant={"outlined"}
                                onClick={() => {
                                    state.clearSort();
                                    props.handleReset();
                                }}
                            >
                                Reset
                            </Button>
                            <Button variant={"contained"} color={"primary"} type={"submit"}>
                                Apply
                            </Button>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
});

export const getSortOrderLabel = (sortOrder: SORT_ORDER) => {
    if (sortOrder === SORT_ORDER.ASCENDING) {
        return "Ascending (A to Z)";
    } else if (sortOrder === SORT_ORDER.DESCENDING) {
        return "Descending (Z to A)";
    }
};

interface SortSelectionType {
    label: string;
    value: boolean;
}

const sortOrderList: SortSelectionType[] = [
    {
        label: getSortOrderLabel(SORT_ORDER.ASCENDING),
        value: false,
    },
    {
        label: getSortOrderLabel(SORT_ORDER.DESCENDING),
        value: true,
    },
];
