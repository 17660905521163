import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import {
    alpha,
    Box,
    Card,
    Dialog,
    DialogContent,
    ListSubheader,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { useGetCmoIoStats, useGetCmoNexusDetails, useGetCmoNexusDiscoveredPorts, useGetCmoSystemDetails, useListCmoNexus } from "./cmo_migration_hooks";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryListTable } from "../../common/table/QueryListTable";
import { CmoNexus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { DarkFlatCard, DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { KeyValuePair } from "../../common/text/CommonTypeFormats";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { OutlinedCard } from "../../common/card/OutlinedCard";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { LightDivider } from "../../common/misc";
import { OnlineStatusIcon } from "../../common/CommonIcons";
import { GetCmoIOStats } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { CmoMigrationIoStatChart } from "./ioStats/CmoMigrationIoStatChart";
import { CmoIoStatChartType } from "./ioStats/CmoMigrationIoStatChartHelpers";
import Grid from "@mui/material/Grid2";

// ======================
// CmoMigrationNexusScreen
// ======================

interface CmoMigrationNexusScreenProps {
    projectId: string;
}

export const CmoMigrationNexusScreen: React.FC<CmoMigrationNexusScreenProps> = (p) => {
    const { projectId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 0,
    });
    const queryResult = useListCmoNexus(projectId, pageIndex, pageSize);
    const getArrayOfNexusesGroupedBySystem = () => {
        const nexuses = queryResult.data?.itemsList || [];
        const grouped = nexuses.reduce((acc, nexus) => {
            const systemId = nexus.deployment.systemId || "";
            if (acc[systemId]) {
                acc[systemId].push(nexus);
            } else {
                acc[systemId] = [nexus];
            }
            return acc;
        }, {} as { [key: string]: CmoNexus.AsObject[] });

        return Object.entries(grouped).map(([systemId, nexuses]) => {
            return {
                nexuses,
            };
        });
    };

    const getSystemsGroupedByNumberOfNexuses = () => {
        const nexusesGroupedBySystem = getArrayOfNexusesGroupedBySystem();
        const systemsGroupedByNexusCount: { [key: number]: { nexuses: Array<CmoNexus.AsObject> }[] } = {};

        for (let system of nexusesGroupedBySystem) {
            const nexusCount = system.nexuses.length;
            if (systemsGroupedByNexusCount[nexusCount]) {
                systemsGroupedByNexusCount[nexusCount].push(system);
            } else {
                systemsGroupedByNexusCount[nexusCount] = [system];
            }
        }
        return systemsGroupedByNexusCount;
    };

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Cirrus Migrate On-Premises Nexus"} />
            <Box pb={2}>
                <Typography variant={"body2"} color={"textSecondary"}>
                    {"Click on each Nexus card to access Upstream/Downstream information, I/O Statistics, and all inserted initiators/targets."}
                </Typography>
            </Box>
            {Object.values(getSystemsGroupedByNumberOfNexuses())
                .reverse()
                .map((systemList) => {
                    return (
                        <Box pb={2}>
                            <QueryListTable
                                listComponent={CmoNexusCard}
                                data={systemList || []}
                                error={queryResult.error}
                                isLoading={queryResult.isLoading}
                                grid
                            />
                        </Box>
                    );
                })}
        </ScreenContainer>
    );
};

// ======================
// CmoNexusCard
// ======================

interface CmoNexusCardProps {
    data: {
        nexuses: Array<CmoNexus.AsObject>;
    };
}

const CmoNexusCard: React.FC<CmoNexusCardProps> = (p) => {
    const { data } = p;
    const systemName = data.nexuses[0].deployment.systemName;
    const systemId = data.nexuses[0].deployment.systemId;
    const insertionMethod = data.nexuses[0].insertionMethod;
    const primaryApplianceNexuses = data.nexuses.filter((nexus) => nexus.fromPrimaryAppliance).sort((a, b) => a.nexusId - b.nexusId);
    const memberApplianceNexuses = data.nexuses.filter((nexus) => !nexus.fromPrimaryAppliance).sort((a, b) => a.nexusId - b.nexusId);
    const nexusDetailsDialogState = useDialogState();

    return (
        <Grid>
            <Card>
                <Box p={2}>
                    <Stack direction={"row"} spacing={2} pb={2}>
                        <NexusGroupDisplay nexusList={primaryApplianceNexuses} nexusDetailsDialogState={nexusDetailsDialogState} />
                        <NexusGroupDisplay nexusList={memberApplianceNexuses} nexusDetailsDialogState={nexusDetailsDialogState} />
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <Typography fontWeight={600}>{systemName}</Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {`· ${insertionMethod}`}
                        </Typography>
                    </Stack>
                </Box>
            </Card>
            {nexusDetailsDialogState.isOpen && <NexusInfoDialog dialogState={nexusDetailsDialogState} systemId={systemId} />}
        </Grid>
    );
};

// ======================
// NexusInfoDialog
// ======================

interface NexusInfoDialogProps {
    dialogState: DialogState;
    systemId: string;
}

export const NexusInfoDialog: React.FC<NexusInfoDialogProps> = (p) => {
    const { dialogState, systemId } = p;
    const fullScreen = useShouldDialogFullScreen();
    const theme = useTheme();
    const cmoNexusDetails = useGetCmoNexusDetails(dialogState.dialogProps.systemId, dialogState.dialogProps.nexusName);
    const cmoSystemDetails = useGetCmoSystemDetails(systemId);
    const renderHbaInfo = (hbaInfo: CmoNexus.HBAInfo.AsObject, stream: "UpStream" | "DownStream", initiators: number, targets: number) => {
        return (
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Box>
                        <Typography fontWeight={600}>{`${stream} - ${hbaInfo?.portId}`}</Typography>
                        <Typography color={hbaInfo?.isOnline ? "success.main" : "error.main"}>{hbaInfo?.isOnline ? "Connected" : "Disconnected"}</Typography>
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Box>
                        <KeyValuePair label={"WWPN"} value={hbaInfo?.wwpn} />
                        <KeyValuePair label={"FCID"} value={hbaInfo?.fcid} />
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <Box>
                        <KeyValuePair label={"Topology"} value={hbaInfo?.topology} />
                        <KeyValuePair label={"Speed"} value={`${hbaInfo?.datarate}Gb`} />
                    </Box>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 3,
                    }}
                >
                    <OutlinedCard
                        sx={{
                            maxWidth: "200px",
                            borderColor: hbaInfo?.isOnline ? theme.palette.success.main : theme.palette.error.main,
                        }}
                    >
                        <Stack direction={"row"} alignItems={"center"} spacing={1} p={1} justifyContent={"center"}>
                            <Typography variant={"h6"} color={hbaInfo?.isOnline ? "success.main" : "error.main"}>
                                {stream === "UpStream" ? initiators : targets}
                            </Typography>
                            <Typography variant={"body2"} color={hbaInfo?.isOnline ? "success.main" : "error.main"}>
                                {`Inserted ${stream === "UpStream" ? "Initiators" : "Targets"}`}
                            </Typography>
                        </Stack>
                    </OutlinedCard>
                </Grid>
            </Grid>
        );
    };

    const discoveredPorts = useGetCmoNexusDiscoveredPorts(dialogState.dialogProps.systemId, dialogState.dialogProps.nexusName);

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"lg"} fullScreen={fullScreen}>
            <DialogTopBar dialogState={dialogState} title={dialogState.dialogProps.nexusName} />
            <DialogContent>
                <QueryResultWrapper queryResult={cmoNexusDetails}>
                    <OutlinedCard
                        sx={{
                            borderColor: theme.palette.primary.main,
                        }}
                    >
                        <Box p={2}>
                            {renderHbaInfo(
                                cmoNexusDetails.data?.nexus.nexus.upstreamHba,
                                "UpStream",
                                cmoNexusDetails.data?.nexus.nexus.initiatorCount,
                                cmoNexusDetails.data?.nexus.nexus.targetCount
                            )}
                        </Box>
                        <LightDivider />
                        <Box p={2}>
                            {renderHbaInfo(
                                cmoNexusDetails.data?.nexus.nexus.downstreamHba,
                                "DownStream",
                                cmoNexusDetails.data?.nexus.nexus.initiatorCount,
                                cmoNexusDetails.data?.nexus.nexus.targetCount
                            )}
                        </Box>
                    </OutlinedCard>
                </QueryResultWrapper>
                <QueryResultWrapper queryResult={cmoNexusDetails}>
                    <QueryResultWrapper queryResult={cmoSystemDetails}>
                        {cmoSystemDetails.data?.system.info.deployment.connected ? (
                            <Grid container spacing={2} pt={2}>
                                <Grid
                                    size={{
                                        xs: 12,
                                        lg: 6,
                                    }}
                                >
                                    <NexusPerformanceCard
                                        systemId={systemId}
                                        nexusId={cmoNexusDetails.data?.nexus.nexus.nexusId}
                                        applianceId={cmoNexusDetails.data?.nexus.nexus.applianceId}
                                    />
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        lg: 6,
                                    }}
                                >
                                    <NexusPerformanceCard
                                        systemId={systemId}
                                        nexusId={cmoNexusDetails.data?.nexus.nexus.nexusId}
                                        applianceId={cmoNexusDetails.data?.nexus.nexus.applianceId}
                                        defaultStat={CmoIoStatChartType.LATENCY}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <DarkFlatCard sx={{ marginTop: 2 }}>
                                <Stack direction={"row"} justifyContent={"center"} p={2}>
                                    <Typography variant={"body1"}>{"IO stats unavailable when system is offline."}</Typography>
                                </Stack>
                            </DarkFlatCard>
                        )}
                    </QueryResultWrapper>
                </QueryResultWrapper>
                <QueryResultWrapper queryResult={discoveredPorts}>
                    <Stack direction={"column"} spacing={2} pt={2}>
                        {discoveredPorts.data?.ports.targetsList.length > 0 && (
                            <OutlinedCard>
                                <ListSubheader>{`Inserted Targets (${discoveredPorts.data?.ports.targetsList.length})`}</ListSubheader>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`WWPN`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`FCID`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`Vendor`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`Storage`}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discoveredPorts.data?.ports.targetsList.map((target, index) => {
                                            const isLast = index === discoveredPorts.data?.ports.targetsList.length - 1;
                                            const sx = {
                                                borderBottomColor: isLast ? "transparent" : theme.palette.border.main,
                                            };
                                            return (
                                                <TableRow>
                                                    <TableCell sx={sx}>{target.wwpn}</TableCell>
                                                    <TableCell sx={sx}>{target.fcid}</TableCell>
                                                    <TableCell sx={sx}>{target.portVendor}</TableCell>
                                                    <TableCell sx={sx}>{"--"}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </OutlinedCard>
                        )}
                        {discoveredPorts.data?.ports.initiatorsList.length > 0 && (
                            <OutlinedCard>
                                <ListSubheader>{`Inserted Initiators (${discoveredPorts.data?.ports.initiatorsList.length})`}</ListSubheader>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`WWPN`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`FCID`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`Vendor`}</TableCell>
                                            <TableCell sx={{ borderBottomColor: theme.palette.border.main }}>{`Host`}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discoveredPorts.data?.ports.initiatorsList.map((target, index) => {
                                            const isLast = index === discoveredPorts.data?.ports.initiatorsList.length - 1;
                                            const sx = {
                                                borderBottomColor: isLast ? "transparent" : theme.palette.border.main,
                                            };

                                            return (
                                                <TableRow>
                                                    <TableCell sx={sx}>{target.wwpn}</TableCell>
                                                    <TableCell sx={sx}>{target.fcid}</TableCell>
                                                    <TableCell sx={sx}>{target.portVendor}</TableCell>
                                                    <TableCell sx={sx}>{"--"}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </OutlinedCard>
                        )}
                    </Stack>
                </QueryResultWrapper>
            </DialogContent>
        </Dialog>
    );
};

// ======================
// NexusPerformanceCard
// ======================

interface NexusPerformanceCardProps {
    systemId: string;
    nexusId: number;
    applianceId: string;
    defaultStat?: CmoIoStatChartType;
}

export const NexusPerformanceCard: React.FC<NexusPerformanceCardProps> = (p) => {
    const { systemId, nexusId, applianceId, defaultStat } = p;
    const ioStats = useGetCmoIoStats(
        new GetCmoIOStats.Request().setNexus(new GetCmoIOStats.Request.NexusStatsParams().setNexusId(nexusId).setApplianceId(applianceId)).setSystemId(systemId)
    );

    return (
        <QueryResultWrapper queryResult={ioStats}>
            <CmoMigrationIoStatChart
                ioStats={ioStats.data?.stats}
                defaultStat={defaultStat}
                //title={"IO Stats"}
                cardProps={{
                    elevation: 0,
                    sx: {
                        border: (t: Theme) => `2px solid ${t.palette.border.main}`,
                    },
                }}
            />
        </QueryResultWrapper>
    );
};

// ======================
// NexusGroupDisplay
// ======================

interface NexusGroupDisplayProps {
    nexusList: CmoNexus.AsObject[];
    nexusDetailsDialogState?: DialogState;
}

export const NexusGroupDisplay: React.FC<NexusGroupDisplayProps> = (p) => {
    const { nexusList, nexusDetailsDialogState } = p;
    const theme = useTheme();
    return (
        <Stack direction={"column"} spacing={1} width={"fit-content"}>
            <Stack direction={"row"} spacing={0.5}>
                {nexusList.map((nexus, index) => {
                    return (
                        <Stack direction={"column"} spacing={0.5} key={nexus.nexusId} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`N${index + 1}`}
                            </Typography>
                            <Tooltip
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: theme.palette.cirrus.light,
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            borderRadius: 3,
                                        },
                                    },
                                }}
                                title={
                                    <Stack direction={"column"} p={2} spacing={2}>
                                        <Typography fontWeight={600}>{nexus.nexusName}</Typography>
                                        <DarkFlatOutlinedCard cardProps={{}}>
                                            <Box p={1}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                    <OnlineStatusIcon online={nexus.upstreamHba.isOnline} size={12} />
                                                    <Typography variant={"body2"} fontWeight={600}>
                                                        {`UpStream - ${nexus.upstreamHba.portId}`}
                                                    </Typography>
                                                </Stack>
                                                <KeyValuePair typographyVariant={"caption"} label={"Initiators"} value={nexus.initiatorCount} />
                                                <KeyValuePair typographyVariant={"caption"} label={"FCID"} value={nexus.upstreamHba.fcid} />
                                                <KeyValuePair typographyVariant={"caption"} label={"Topology"} value={nexus.upstreamHba.topology} />
                                                <KeyValuePair typographyVariant={"caption"} label={"Speed"} value={`${nexus.upstreamHba.datarate}Gb`} />
                                            </Box>
                                        </DarkFlatOutlinedCard>
                                        <DarkFlatOutlinedCard>
                                            <Box p={1}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                    <OnlineStatusIcon online={nexus.downstreamHba.isOnline} size={12} />
                                                    <Typography variant={"body2"} fontWeight={600}>
                                                        {`DownStream - ${nexus.downstreamHba.portId}`}
                                                    </Typography>
                                                </Stack>
                                                <KeyValuePair typographyVariant={"caption"} label={"Targets"} value={nexus.targetCount} />
                                                <KeyValuePair typographyVariant={"caption"} label={"FCID"} value={nexus.downstreamHba.fcid} />
                                                <KeyValuePair typographyVariant={"caption"} label={"Topology"} value={nexus.downstreamHba.topology} />
                                                <KeyValuePair typographyVariant={"caption"} label={"Speed"} value={`${nexus.downstreamHba.datarate}Gb`} />
                                            </Box>
                                        </DarkFlatOutlinedCard>
                                    </Stack>
                                }
                            >
                                <Box>
                                    <DarkFlatOutlinedCard
                                        cardProps={{
                                            onClick: () => {
                                                nexusDetailsDialogState.setDialogProps({
                                                    systemId: nexus.deployment.systemId,
                                                    nexusName: nexus.nexusName,
                                                });
                                                nexusDetailsDialogState.open();
                                            },
                                        }}
                                        sx={{
                                            "&:hover": {
                                                cursor: "pointer",
                                                borderColor: theme.palette.primary.main,
                                                backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.1),
                                            },
                                            "&:active": {
                                                backgroundColor: (t: Theme) => alpha(t.palette.primary.main, 0.2),
                                                borderColor: (t: Theme) => t.palette.primary.main,
                                                cursor: "pointer",
                                            },
                                        }}
                                    >
                                        <Stack direction={"column"} p={0.5} alignItems={"center"}>
                                            <Typography
                                                color={
                                                    nexus.initiatorCount === 0
                                                        ? theme.palette.border.main
                                                        : nexus.upstreamHba.isOnline
                                                        ? "success.main"
                                                        : "error.main"
                                                }
                                            >
                                                {nexus.initiatorCount}
                                            </Typography>
                                            <IoMdArrowDropup
                                                color={
                                                    nexus.initiatorCount === 0
                                                        ? theme.palette.border.main
                                                        : nexus.upstreamHba.isOnline
                                                        ? theme.palette.success.main
                                                        : theme.palette.error.main
                                                }
                                            />
                                            <IoMdArrowDropdown
                                                color={
                                                    nexus.targetCount === 0
                                                        ? theme.palette.border.main
                                                        : nexus.upstreamHba.isOnline
                                                        ? theme.palette.success.main
                                                        : theme.palette.error.main
                                                }
                                            />
                                            <Typography
                                                color={
                                                    nexus.targetCount === 0
                                                        ? theme.palette.border.main
                                                        : nexus.downstreamHba.isOnline
                                                        ? "success.main"
                                                        : "error.main"
                                                }
                                            >
                                                {nexus.targetCount}
                                            </Typography>
                                        </Stack>
                                    </DarkFlatOutlinedCard>
                                </Box>
                            </Tooltip>
                        </Stack>
                    );
                })}
            </Stack>
            <Box borderRadius={5} width={"100%"} height={5} bgcolor={theme.palette.border.main} />
        </Stack>
    );
};
