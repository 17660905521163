// ======================
// CmoSystemHealthIcon
// ======================

import { CmoSystemHealth } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import {
    Alert,
    AlertTitle,
    Box,
    Dialog,
    DialogContent,
    IconButton,
    linearProgressClasses,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Theme,
    useTheme,
} from "@mui/material";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { MdOutlineWarningAmber } from "react-icons/md";
import { PiWarningOctagonBold } from "react-icons/pi";
import { BsQuestionCircleFill } from "react-icons/bs";
import React from "react";
import { formatTitleCase } from "../../common/utils/formatter";
import { CmoHealthStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_health_status_pb";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CMC_MIGRATION_SUBROUTE, CMO_MIGRATION_SUBROUTE, getProjectSubRouteTemplate, PROJECT_SUBROUTE } from "../app/AppRoutes";
import {
    CmoMigrationSessionStatus,
    CmoMigrationSessionStatusFilter,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_status_pb";
import { SyncQos } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { CmoMigrationQosImpactLevel } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_qos_impact_level_pb";
import { CmoMigrationSessionVerificationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_verification_status_pb";
import { CmoMigrationVolumeStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_volume_status_pb";
import { CmoMigrationSessionVolumeStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_volume_status_pb";
import { IconBaseProps } from "react-icons/lib/cjs/iconBase";
import { HelpIcon } from "../../common/CommonIcons";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { DarkFlatCard } from "../../common/card/DarkCard";
import { useGetCmoSystemHealthDetails } from "./cmo_migration_hooks";
import { useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { CmoMigrationSessionType, CmoMigrationSessionTypeFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_type_pb";
import { ColumnSortFilterConfig, ListFilterableField } from "../core/data/ListData";
import { ListCmoMigrationSessions } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { SortState } from "../../common/table/DataTable";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";

export const getCmoSystemHealthStatusLabel = (status: CmoHealthStatus.CmoHealthStatus) => {
    return formatTitleCase(
        Object.keys(CmoHealthStatus.CmoHealthStatus).find(
            (k) => CmoHealthStatus.CmoHealthStatus[k as keyof typeof CmoHealthStatus.CmoHealthStatus] === status
        ) || ""
    );
};

interface CmoSystemHealthIconProps {
    healthStatus: CmoHealthStatus.CmoHealthStatus;
    iconProps?: IconBaseProps;
}

export const CmoSystemHealthIcon: React.FC<CmoSystemHealthIconProps> = (p) => {
    const { healthStatus, iconProps } = p;
    const theme = useTheme();
    const statusLabel = getCmoSystemHealthStatusLabel(healthStatus);

    return (
        <>
            {healthStatus === CmoHealthStatus.CmoHealthStatus.HEALTHY && <IoShieldCheckmarkSharp {...iconProps} color={theme.palette.success.main} />}
            {healthStatus === CmoHealthStatus.CmoHealthStatus.WARNING && <MdOutlineWarningAmber {...iconProps} color={theme.palette.warning.main} />}
            {healthStatus === CmoHealthStatus.CmoHealthStatus.FAILURE && <PiWarningOctagonBold {...iconProps} color={theme.palette.error.main} />}
            {(healthStatus === CmoHealthStatus.CmoHealthStatus.UNKNOWN || healthStatus === CmoHealthStatus.CmoHealthStatus.UNAVAILABLE) && (
                <BsQuestionCircleFill {...iconProps} color={theme.palette.grey["700"]} />
            )}
        </>
    );
};

// ======================
// CmoSystemHealthIcon
// ======================

interface CmoSystemHealthIconButtonProps {
    healthStatus: CmoHealthStatus.CmoHealthStatus;
    systemId: string;
    iconProps?: IconBaseProps;
}

export const CmoSystemHealthIconButton: React.FC<CmoSystemHealthIconButtonProps> = (p) => {
    const { healthStatus, systemId, iconProps } = p;
    const queryResult = useGetCmoSystemHealthDetails(systemId);
    const dialogState = useDialogState();
    const fullScreen = useShouldDialogFullScreen();

    return (
        <>
            <BlackTooltip title={"View System Health Details"}>
                <IconButton onClick={dialogState.open}>
                    <SvgIcon>
                        <CmoSystemHealthIcon healthStatus={healthStatus} iconProps={iconProps} />
                    </SvgIcon>
                </IconButton>
            </BlackTooltip>

            <Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={"md"} fullWidth fullScreen={fullScreen}>
                <DialogTopBar dialogState={dialogState} title={"System Health Status"} />
                <DialogContent>
                    <QueryResultWrapper queryResult={queryResult}>
                        {!queryResult.data?.details ? (
                            <Box>{"No data"}</Box>
                        ) : (
                            <Box>
                                {queryResult.data?.details.executionError && (
                                    <Alert severity={"error"}>
                                        <AlertTitle>{"Execution Error"}</AlertTitle>
                                        {queryResult.data?.details.executionError}
                                    </Alert>
                                )}
                                <Box pt={2}>
                                    <DarkFlatCard>
                                        <List>
                                            {queryResult.data?.details.checksList.map((check) => {
                                                return (
                                                    <ListItem key={check.key}>
                                                        <ListItemIcon>
                                                            <CmoSystemHealthIcon healthStatus={check.status} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Box>
                                                                    {check.message}
                                                                    <br />
                                                                    {check.message2}
                                                                </Box>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </DarkFlatCard>
                                </Box>
                            </Box>
                        )}
                    </QueryResultWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const getCmoMigrationSessionVerificationStatusLabel = (status: CmoMigrationSessionVerificationStatus.CmoMigrationSessionVerificationStatus) => {
    return formatTitleCase(
        Object.keys(CmoMigrationSessionVerificationStatus.CmoMigrationSessionVerificationStatus)
            .find(
                (key) =>
                    CmoMigrationSessionVerificationStatus.CmoMigrationSessionVerificationStatus[
                        key as keyof typeof CmoMigrationSessionVerificationStatus.CmoMigrationSessionVerificationStatus
                    ] === status
            )
            .replace("VERIFICATION_", "") || ""
    );
};

export const useLinearProgressStyle = (darkBackground?: boolean) => {
    const theme = useTheme();
    return {
        borderRadius: 5,
        backgroundColor: darkBackground ? theme.palette.cirrus.main : "",

        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
        },
    };
};

export const useNavigateToCmoSystemsList = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.CMO_MIGRATIONS) + `/${CMO_MIGRATION_SUBROUTE.CMO_SYSTEMS}`, { projectId });
    return () => navigate(p);
};

export const useNavigateToCmoSystemDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (systemId: string) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.CMO_MIGRATIONS) + `/${CMO_MIGRATION_SUBROUTE.CMO_SYSTEMS}/${systemId}`, {
            projectId,
        });
        navigate(p);
    };
};

export const useNavigateToCmoMigrationSessionsList = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.CMO_MIGRATIONS) + `/${CMO_MIGRATION_SUBROUTE.MIGRATION_SESSIONS}`, { projectId });
    return () => navigate(p);
};

export const useNavigateToCmoMigrationSessionDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    return (sessionId: string) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.CMO_MIGRATIONS) + `/${CMO_MIGRATION_SUBROUTE.MIGRATION_SESSIONS}/${sessionId}`, {
            projectId,
        });
        navigate(p);
    };
};

export const getCmoMigrationSessionStatusLabel = (status: CmoMigrationSessionStatus.CmoMigrationSessionStatus) => {
    return formatTitleCase(
        Object.keys(CmoMigrationSessionStatus.CmoMigrationSessionStatus)
            .find(
                (key) => CmoMigrationSessionStatus.CmoMigrationSessionStatus[key as keyof typeof CmoMigrationSessionStatus.CmoMigrationSessionStatus] === status
            )
            .replace("_", " ") || ""
    );
};

export const getCmoMigrationSessionVolumeStatusLabel = (status: CmoMigrationSessionVolumeStatus.CmoMigrationSessionVolumeStatus) => {
    return formatTitleCase(
        Object.keys(CmoMigrationSessionVolumeStatus.CmoMigrationSessionVolumeStatus)
            .find(
                (key) =>
                    CmoMigrationSessionVolumeStatus.CmoMigrationSessionVolumeStatus[
                        key as keyof typeof CmoMigrationSessionVolumeStatus.CmoMigrationSessionVolumeStatus
                    ] === status
            )
            ?.replace("_", " ") || ""
    );
};

export const getCmoMigrationSessionStatusColor = (status: CmoMigrationSessionStatus.CmoMigrationSessionStatus, theme: Theme) => {
    if (status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.NEW) {
        return "white";
    }
    if (status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.IN_PROGRESS) {
        return theme.palette.primary.main;
    }
    if (status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.PENDING_COMPLETE) {
        return theme.palette.warning.light;
    }
    if (status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.COMPLETED) {
        return theme.palette.success.main;
    }
    if (status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.FAILED || status === CmoMigrationSessionStatus.CmoMigrationSessionStatus.IN_ERROR) {
        return theme.palette.error.main;
    }
    return theme.palette.grey[500];
};

export const getCmoMigrationSessionTypeLabel = (sessionType: CmoMigrationSessionType.CmoMigrationSessionType) => {
    return formatTitleCase(
        Object.keys(CmoMigrationSessionType.CmoMigrationSessionType)
            .find((key) => CmoMigrationSessionType.CmoMigrationSessionType[key as keyof typeof CmoMigrationSessionType.CmoMigrationSessionType] === sessionType)
            .replace("_", " ") || ""
    );
};

export const getCmoMigrationQosImpactLevelLabel = (impactLevel: string | CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel) => {
    return formatTitleCase(
        Object.keys(CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel)
            .find(
                (key) =>
                    CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel[key as keyof typeof CmoMigrationQosImpactLevel.CmoMigrationQosImpactLevel] ===
                    impactLevel
            )
            .replace("_", " ") || ""
    );
};

export const getCmoMigrationSessionsListSortFilterConfig = (): ColumnSortFilterConfig<
    ListCmoMigrationSessions.Request,
    ListCmoMigrationSessions.Request.Sort,
    any,
    any
> => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListCmoMigrationSessions.Request.Sort.Field.CREATED_AT,
                label: "Creation Time",
            },
            defaultSortDesc: true,
            sortFields: [
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.CREATED_AT, label: "Creation Time" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.STARTED_AT, label: "Start Time" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.AVERAGE_THROUGHPUT, label: "Average Migration Rate" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.CURRENT_THROUGHPUT, label: "Current Migration Rate" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.MIGRATION_STATUS, label: "Status" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.AVERAGE_TRANSFER_THROUGHPUT, label: "Average Transfer Rate" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.PROGRESS_PERCENTAGE, label: "Progress" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.THIN_PERCENTAGE, label: "Thin Data Ratio" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.TOTAL_CAPACITY, label: "Capacity" },
                { fieldId: ListCmoMigrationSessions.Request.Sort.Field.TOTAL_VOLUMES, label: "Volumes" },
            ],
            getSortParam: (s: SortState) => new ListCmoMigrationSessions.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value),
        },
        filter: [
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.GLOBAL_SESSION_ID,
                label: "Global Session ID",
                filterType: "string",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setGlobalSessionId(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.LOCAL_SESSION_ID,
                label: "Local Session ID",
                filterType: "simpleNumber",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.SimpleNumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setLocalSessionId(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.DESCRIPTION,
                label: "Description",
                filterType: "string",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setDescription(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.STATUS,
                label: "Status",
                filterType: "enum",
                enumLabel: "Migration Type",
                enumValuesList: Object.values(CmoMigrationSessionStatus.CmoMigrationSessionStatus),
                getFormValueDisplayLabel: (v: CmoMigrationSessionStatus.CmoMigrationSessionStatus) => getCmoMigrationSessionStatusLabel(v),
                createParamFromFormValue: (v: CmoMigrationSessionStatus.CmoMigrationSessionStatus) =>
                    new CmoMigrationSessionStatusFilter().setHas(new CmoMigrationSessionStatus().setValue(v)),
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: CmoMigrationSessionStatusFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setStatus(f));
                },
            } as ListFilterableField<ListCmoMigrationSessions.Request, CmoMigrationSessionStatusFilter, CmoMigrationSessionStatus.CmoMigrationSessionStatus>,
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.TYPE,
                label: "Type",
                filterType: "enum",
                enumLabel: "Migration Type",
                enumValuesList: Object.values(CmoMigrationSessionType.CmoMigrationSessionType),
                getFormValueDisplayLabel: (v: CmoMigrationSessionType.CmoMigrationSessionType) => getCmoMigrationSessionTypeLabel(v),
                createParamFromFormValue: (v: CmoMigrationSessionType.CmoMigrationSessionType) =>
                    new CmoMigrationSessionTypeFilter().setHas(new CmoMigrationSessionType().setValue(v)),
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: CmoMigrationSessionTypeFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setType(f));
                },
            } as ListFilterableField<ListCmoMigrationSessions.Request, CmoMigrationSessionTypeFilter, CmoMigrationSessionType.CmoMigrationSessionType>,
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.TOTAL_VOLUMES,
                label: "Volumes",
                filterType: "number",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setTotalVolumes(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.TOTAL_CAPACITY,
                label: "Capacity",
                filterType: "number",
                numType: "capacity",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setTotalCapacity(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.CREATED_AT,
                label: "Created",
                filterType: "date",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setCreatedAt(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.STARTED_AT,
                label: "Started",
                filterType: "date",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.DateFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setStartedAt(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.LABEL,
                label: "Label",
                filterType: "string",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setLabel(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.ACTIVE_MIGRATION_TIME_PERCENTAGE,
                label: "Duty Cycle",
                filterType: "number",
                numType: "percentage",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setActiveMigrationTimePercentage(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.THIN_PERCENTAGE,
                label: "Thin Data Ratio",
                filterType: "number",
                numType: "percentage",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setThinPercentage(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.PROGRESS_PERCENTAGE,
                label: "Progress",
                filterType: "number",
                numType: "percentage",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setProgressPercentage(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.AVERAGE_THROUGHPUT,
                label: "Average Migration Rate",
                filterType: "number",
                numType: "throughput",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setAverageThroughput(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.AVERAGE_TRANSFER_THROUGHPUT,
                label: "Average Transfer Rate",
                filterType: "number",
                numType: "throughput",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.NumberFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setAverageTransferThroughput(f));
                },
            },
            {
                fieldId: ListCmoMigrationSessions.Request.Filter.FieldCase.SYSTEM_NAME,
                label: "System Name",
                filterType: "string",
                addFilterToRequest: (r: ListCmoMigrationSessions.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCmoMigrationSessions.Request.Filter().setSystemName(f));
                },
            },
        ],
    };
};
