// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: ProjectReportsScreen

import * as React from "react";
import { useState } from "react";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { observer } from "mobx-react-lite";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { HostConfigurationReport, ReportInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/report_pb";
import { Avatar, Box, Card, Chip, FormControl, InputLabel, Link, MenuItem, Select, Tooltip, Typography, useTheme } from "@mui/material";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import { getReportTypeLabel, viewReport } from "./ReportHelpers";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import { cirrusColors } from "../app/AppTheme";
import { HostEnvironmentIcon, ReportIcon } from "../../common/CommonIcons";
import { alpha } from "@mui/material/styles";
import { getGmSessionIDSlug, renderGMSessionStatus, useNavigateToMigrationSessionDetails } from "../cmcMigration/CmcMigrationCommon";
import { KeyValuePairSubtitle } from "../../common/text/CommonTypeFormats";
import { Route, Routes } from "react-router-dom";
import { ViewReportScreen } from "./reportScreen/ViewReportScreen";
import { getHostEnvDisplayName, useNavigateToDeploymentDetails } from "../galaxymigrate/GalaxyMigrateCommon";
import { useListProjectReports } from "./report_hooks";
import { QueryListTable } from "../../common/table/QueryListTable";
import { PaginationState } from "@tanstack/react-table";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { getOsDisplayName, HostOSIcon } from "../project/ProjectCommon";
import EmptyTableIcon from "../../assets/empty_states/empty-report.svg";
import { ReportQueryKeys } from "../../common/QueryKeys";
import OSCounters = HostConfigurationReport.Metadata.OSCounters;
import EnvironmentCounters = HostConfigurationReport.Metadata.EnvironmentCounters;

// ======================
// ProjectReportsScreens
// ======================

interface ProjectReportsScreensProps {}

export const ProjectReportsScreens: React.FC<ProjectReportsScreensProps> = observer((p) => {
    return (
        <Routes>
            <Route index element={<ProjectReportsListScreen />} />
            <Route path={":reportId"} element={<ViewReportScreen />} />
        </Routes>
    );
});

// ======================
// ProjectReportsScreen
// ======================
interface ProjectReportsScreenProps {}

export const ProjectReportsListScreen: React.FC<ProjectReportsScreenProps> = observer((p) => {
    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Project Reports`} />
            <ProjectReportsList />
        </ScreenContainer>
    );
});

// ======================
// ProjectReportsList
// ======================

interface ProjectReportsListProps {}

const ProjectReportsList: React.FC<ProjectReportsListProps> = observer((p) => {
    const projectId = useCurrentProjectID();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 15,
    });

    const [reportType, setReportType] = useState<ReportType.ReportType>(ReportType.ReportType.MIGRATION_SESSION_REPORT);

    const queryResult = useListProjectReports(projectId, new PagerParams().setPage(pageIndex).setPerPage(pageSize), reportType);
    const currentValidReportTypes = [
        ReportType.ReportType.PROJECT_LICENSE_REPORT,
        ReportType.ReportType.MIGRATION_SESSION_REPORT,
        ReportType.ReportType.HOST_CONFIGURATIONS_REPORT,
        ReportType.ReportType.MIGRATION_SOURCE_DATA_ASSESSMENT_REPORT,
    ];
    return (
        <>
            <FormControl fullWidth>
                <InputLabel id={"report-type-select-label"} variant={"outlined"}>
                    {`Report Type`}
                </InputLabel>
                <Select
                    labelId={"report-type-select-label"}
                    id={"report-type-select"}
                    value={reportType}
                    label={"Report Type"}
                    onChange={(e) => {
                        setReportType(e.target.value as ReportType.ReportType);
                    }}
                >
                    {currentValidReportTypes.map((reportType) => {
                        return (
                            <MenuItem key={reportType} value={reportType}>
                                {getReportTypeLabel(reportType)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Box pt={2}>
                <QueryListTable
                    listComponent={getReportTypeListCard(reportType)}
                    data={queryResult.data?.reportsList || []}
                    isLoading={queryResult.isLoading}
                    isError={queryResult.isError}
                    error={queryResult.error}
                    refetch={async () => await queryResult.refetch()}
                    pageCount={queryResult.data?.pagerMeta?.totalPages}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    queryKey={ReportQueryKeys.listProjectReports}
                    emptyTableConfig={{
                        iconPath: EmptyTableIcon,
                        title: "No Reports Found",
                    }}
                />
            </Box>
        </>
    );
});

// ======================
// MigrationSessionReportListCard
// ======================

interface MigrationSessionReportListCardProps {
    data: ReportInfo.AsObject;
}

const MigrationSessionReportListCard: React.FC<MigrationSessionReportListCardProps> = observer((p) => {
    const { data } = p;
    const theme = useTheme();
    const goToSessionDetails = useNavigateToMigrationSessionDetails();
    const goToDeploymentDetails = useNavigateToDeploymentDetails();

    return (
        <Box pt={2}>
            <Card>
                <Box p={2} display={"flex"} height={"100%"} alignItems={"center"}>
                    <Box pr={1}>
                        <Avatar sx={{ bgcolor: theme.palette.cirrus.main }}>
                            <ReportIcon color={alpha("#fff", 0.5)} />
                        </Avatar>
                    </Box>
                    <Box sx={{ paddingRight: 2, borderRight: `2px solid ${cirrusColors.primary}` }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box pr={1}>
                                <Typography variant={"h6"}>
                                    {`Report ID:`} &nbsp;
                                    <Link onClick={() => viewReport(data.reportId)}>{data.reportId}</Link>
                                </Typography>
                            </Box>
                            <Chip label={getReportTypeLabel(data.reportType)} size={"small"} />
                        </Box>
                        <Box>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`Created ${formatKnownDataType(
                                    convertTimestampObjectToDate(data.createdAt),
                                    KnownDataType.DATE_RELATIVE
                                )} by ${getUserFullNameFromObject(data.createdBy)}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box pl={2} display={"flex"}>
                            <KeyValuePairSubtitle
                                label={"Migration Session"}
                                value={<Link onClick={() => goToSessionDetails(data.msrMeta.sessionId)}>{getGmSessionIDSlug(data.msrMeta.sessionId)}</Link>}
                            />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle
                                label={"Host"}
                                value={
                                    <>
                                        <Link onClick={() => goToDeploymentDetails(data.msrMeta.sourceSystemId)}>{data.msrMeta.sourceSystemName}</Link>
                                        &nbsp;
                                        {">"}
                                        &nbsp;
                                        <Link onClick={() => goToDeploymentDetails(data.msrMeta.destSystemId)}>{data.msrMeta.destSystemName}</Link>
                                    </>
                                }
                            />
                        </Box>
                        <Box pl={2} display={"flex"}>
                            <KeyValuePairSubtitle label={"Status"} value={renderGMSessionStatus(data.msrMeta.sessionStatus)} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Volumes"} value={data.msrMeta.sessionVolumes} />
                        </Box>
                        <Box pl={2} display={"flex"}>
                            <KeyValuePairSubtitle label={"Session Description"} value={data.msrMeta.sessionDesc || "N/A"} />
                        </Box>
                    </Box>
                </Box>
                <Box pb={2} pr={2} pl={2} pt={0}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {data.systemNotes}
                    </Typography>
                    {!!data.userNotes && (
                        <Box display={"flex"} justifyContent={"flex-start"} pt={1}>
                            <Typography variant={"body2"} fontWeight={500}>
                                {`User Report Notes:`} &nbsp;
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {data.userNotes}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
});

// ======================
// ProjectLicenseReportListCard
// ======================

interface ProjectLicenseReportListCardProps {
    data: ReportInfo.AsObject;
}

const ProjectLicenseReportListCard: React.FC<ProjectLicenseReportListCardProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    return (
        <Box pt={2}>
            <Card>
                <Box p={2} display={"flex"} height={"100%"} alignItems={"center"}>
                    <Box pr={1}>
                        <Avatar sx={{ bgcolor: theme.palette.cirrus.main }}>
                            <ReportIcon color={alpha("#fff", 0.5)} />
                        </Avatar>
                    </Box>
                    <Box sx={{ paddingRight: 2, borderRight: `2px solid ${cirrusColors.primary}` }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box pr={1}>
                                <Typography variant={"h6"}>
                                    {`Report ID:`} &nbsp;
                                    <Link onClick={() => viewReport(data.reportId)}>{data.reportId}</Link>
                                </Typography>
                            </Box>
                            <Chip label={getReportTypeLabel(data.reportType)} size={"small"} />
                        </Box>
                        <Box>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`Created ${formatKnownDataType(
                                    convertTimestampObjectToDate(data.createdAt),
                                    KnownDataType.DATE_RELATIVE
                                )} by ${getUserFullNameFromObject(data.createdBy)}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box pl={2} display={"flex"}>
                            <KeyValuePairSubtitle label={"Total Hosts"} value={data.plrMeta.totalHostsInProject} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Active Licenses"} value={data.plrMeta.totalActiveLicenses} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Expired Licenses"} value={data.plrMeta.totalExpiredLicenses} />
                        </Box>
                    </Box>
                </Box>
                <Box pb={2} pr={2} pl={2} pt={0}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {data.systemNotes}
                    </Typography>
                    {!!data.userNotes && (
                        <Box display={"flex"} justifyContent={"flex-start"} pt={1}>
                            <Typography variant={"body2"} fontWeight={500}>
                                {`User Report Notes:`} &nbsp;
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {data.userNotes}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

// ======================
// HostConfigurationReportListCard
// ======================

interface HostConfigurationReportListCardProps {
    data: ReportInfo.AsObject;
}

const HostConfigurationReportListCard: React.FC<HostConfigurationReportListCardProps> = (p) => {
    const { data } = p;
    const theme = useTheme();

    const renderOSCounters = (osCounters: OSCounters.AsObject[]) => {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                {osCounters.map((osCounter: OSCounters.AsObject) => {
                    return (
                        <Tooltip title={getOsDisplayName(osCounter.osType)}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pr={1}>
                                <HostOSIcon os={osCounter.osType} />
                                <Box pl={1}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {`${osCounter.count}`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                    );
                })}
            </Box>
        );
    };

    const renderEnvCounters = (envCounters: EnvironmentCounters.AsObject[]) => {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                {envCounters.map((envCounter: EnvironmentCounters.AsObject) => {
                    return (
                        <Tooltip title={getHostEnvDisplayName(envCounter.environment.value)}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pr={1}>
                                <HostEnvironmentIcon env={envCounter.environment.value} />
                                <Box pl={1}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {envCounter.count}
                                    </Typography>
                                </Box>
                            </Box>
                        </Tooltip>
                    );
                })}
            </Box>
        );
    };

    return (
        <Box pt={2}>
            <Card>
                <Box p={2} display={"flex"} height={"100%"} alignItems={"center"}>
                    <Box pr={1}>
                        <Avatar sx={{ bgcolor: theme.palette.cirrus.main }}>
                            <ReportIcon color={alpha("#fff", 0.5)} />
                        </Avatar>
                    </Box>
                    <Box sx={{ paddingRight: 2, borderRight: `2px solid ${cirrusColors.primary}` }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box pr={1}>
                                <Typography variant={"h6"}>
                                    {`Report ID:`} &nbsp;
                                    <Link onClick={() => viewReport(data.reportId)}>{data.reportId}</Link>
                                </Typography>
                            </Box>
                            <Chip label={getReportTypeLabel(data.reportType)} size={"small"} />
                        </Box>
                        <Box>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`Created ${formatKnownDataType(
                                    convertTimestampObjectToDate(data.createdAt),
                                    KnownDataType.DATE_RELATIVE
                                )} by ${getUserFullNameFromObject(data.createdBy)}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box pl={2} display={"flex"} alignItems={"center"}>
                            <KeyValuePairSubtitle label={"Total Online Hosts"} value={data.hcrMeta.totalOnlineHosts} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"OS"} value={renderOSCounters(data.hcrMeta.osCountersList)} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Env"} value={renderEnvCounters(data.hcrMeta.envCountersList)} />
                        </Box>
                    </Box>
                </Box>
                <Box pb={2} pr={2} pl={2} pt={0}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {data.systemNotes}
                    </Typography>
                    {!!data.userNotes && (
                        <Box display={"flex"} justifyContent={"flex-start"} pt={1}>
                            <Typography variant={"body2"} fontWeight={500}>
                                {`User Report Notes:`} &nbsp;
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {data.userNotes}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

// ======================
// MigrationSourceDataAssessmentReportListCard
// ======================

interface MigrationSourceDataAssessmentReportListCardProps {
    data: ReportInfo.AsObject;
}

const MigrationSourceDataAssessmentReportListCard: React.FC<MigrationSourceDataAssessmentReportListCardProps> = (p) => {
    const { data } = p;
    const theme = useTheme();

    return (
        <Box pt={2}>
            <Card>
                <Box p={2} display={"flex"} height={"100%"} alignItems={"center"}>
                    <Box pr={1}>
                        <Avatar sx={{ bgcolor: theme.palette.cirrus.main }}>
                            <ReportIcon color={alpha("#fff", 0.5)} />
                        </Avatar>
                    </Box>
                    <Box sx={{ paddingRight: 2, borderRight: `2px solid ${cirrusColors.primary}` }}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Box pr={1}>
                                <Typography variant={"h6"}>
                                    {`Report ID:`} &nbsp;
                                    <Link onClick={() => viewReport(data.reportId)}>{data.reportId}</Link>
                                </Typography>
                            </Box>
                            <Chip label={getReportTypeLabel(data.reportType)} size={"small"} />
                        </Box>
                        <Box>
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`Created ${formatKnownDataType(
                                    convertTimestampObjectToDate(data.createdAt),
                                    KnownDataType.DATE_RELATIVE
                                )} by ${getUserFullNameFromObject(data.createdBy)}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Box pl={2} display={"flex"} alignItems={"center"}>
                            <KeyValuePairSubtitle label={"Total Hosts"} value={data.msdarMeta.hostCount} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Total Volumes"} value={data.msdarMeta.volumeCount} />
                        </Box>
                        <Box pl={2} display={"flex"} alignItems={"center"}>
                            <KeyValuePairSubtitle label={"Total Capacity"} value={formatKnownDataType(data.msdarMeta.totalCapacity, KnownDataType.CAPACITY)} />
                        </Box>
                        <Box pl={2} display={"flex"} alignItems={"center"}>
                            <KeyValuePairSubtitle label={"Total Thin Data"} value={formatKnownDataType(data.msdarMeta.totalThinData, KnownDataType.CAPACITY)} />
                            <Typography variant={"body2"} sx={{ paddingRight: 2, paddingLeft: 2 }}>
                                {`|`}
                            </Typography>
                            <KeyValuePairSubtitle label={"Thin Data Ratio"} value={formatKnownDataType(data.msdarMeta.thinDataRatio, KnownDataType.PERCENT)} />
                        </Box>
                    </Box>
                </Box>

                <Box pb={2} pr={2} pl={2} pt={0}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {data.systemNotes}
                    </Typography>
                    {!!data.userNotes && (
                        <Box display={"flex"} justifyContent={"flex-start"} pt={1}>
                            <Typography variant={"body2"} fontWeight={500}>
                                {`User Report Notes:`} &nbsp;
                            </Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {data.userNotes}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};

export const getReportTypeListCard = (reportType: ReportType.ReportType) => {
    if (reportType === ReportType.ReportType.HOST_CONFIGURATIONS_REPORT) {
        return HostConfigurationReportListCard;
    } else if (reportType === ReportType.ReportType.PROJECT_MIGRATION_REPORT) {
        return null;
    } else if (reportType === ReportType.ReportType.MIGRATION_SESSION_REPORT) {
        return MigrationSessionReportListCard;
    } else if (reportType === ReportType.ReportType.HOST_PERFORMANCE_REPORT) {
        return null;
    } else if (reportType === ReportType.ReportType.PROJECT_LICENSE_REPORT) {
        return ProjectLicenseReportListCard;
    } else if (reportType === ReportType.ReportType.PERFORMANCE_SUMMARY_REPORT) {
        return null;
    } else if (reportType === ReportType.ReportType.PROJECT_SUMMARY_REPORT) {
        return null;
    } else if (reportType === ReportType.ReportType.MIGRATION_SOURCE_DATA_ASSESSMENT_REPORT) {
        return MigrationSourceDataAssessmentReportListCard;
    }
    return null;
};
