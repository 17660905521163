import { getProjectSubRouteTemplate, LICENSE_SUBROUTE, MANAGEMENT_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { generatePath, useNavigate, useParams } from "react-router-dom";

export const generateManagementSubPathRoute = (projectId: string, managementSubroute: string) => {
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MANAGEMENT) + `/${managementSubroute}`, { projectId });
    return p;
};

export const useNavigateToProjectLicenseDetailsScreen = (projId?: string) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateManagementSubPathRoute(projId || projectId, MANAGEMENT_SUBROUTE.LICENSE);
    return () => navigate(p);
};

export const useNavigateToPeLicensePendingRequestScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateManagementSubPathRoute(projectId, `${MANAGEMENT_SUBROUTE.LICENSE}/${LICENSE_SUBROUTE.REQUEST}`);
    return () => navigate(p);
};

export const useNavigateToPeLicenseActivationScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateManagementSubPathRoute(projectId, `${MANAGEMENT_SUBROUTE.LICENSE}/${LICENSE_SUBROUTE.ACTIVATE}`);
    return () => navigate(p);
};

export const useNavigateToPeLicenseRenewalScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateManagementSubPathRoute(projectId, `${MANAGEMENT_SUBROUTE.LICENSE}/${LICENSE_SUBROUTE.RENEW}`);
    return () => navigate(p);
};

export const useNavigateToPeLicenseWithdrawalScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateManagementSubPathRoute(projectId, `${MANAGEMENT_SUBROUTE.LICENSE}/${LICENSE_SUBROUTE.WITHDRAW}`);
    return () => navigate(p);
};

export const useNavigateToProjectManagementMainScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.MANAGEMENT), { projectId });
    return () => navigate(p);
};
