import { useParams } from "react-router-dom";
import { MigrateOpsAwsWizard } from "./aws/MigrateOpsAwsWizard";
import { MigrateOpsThinDataReportWizard } from "./thinDataReport/MigrateOpsThinDataReportWizard";
import { MigrateOpsLocalDataMigrationWizard } from "./localDataMigration/MigrateOpsLocalDataMigrationWizard";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
import { ScreenContainer } from "../../layout/ScreenCommon";
import { Button, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { useOpenHelpArticle } from "../../help/hooks/help_hooks";
import { KnownArticle } from "../../help/HelpCommon";
import { getOperationRecipeLabel, useNavigatetoRecipeReference } from "../MigrateOpsCommon";

// ======================
// MigrateOpsWizardView
// ======================

interface MigrateOpsWizardViewProps {}

export const MigrateOpsWizardView: React.FC<MigrateOpsWizardViewProps> = (p) => {
    const { recipe } = useParams();

    const recipeId = Number(recipe) as OperationRecipeID;

    if (recipeId === OperationRecipeID.MIGRATEOPS_AWS_COMPUTE) {
        return <MigrateOpsAwsWizard />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_MIGRATION_SOURCE_ASSESSMENT_REPORT) {
        return <MigrateOpsThinDataReportWizard />;
    }
    if (recipeId === OperationRecipeID.MIGRATEOPS_LOCAL_DATA_MIGRATION) {
        return <MigrateOpsLocalDataMigrationWizard />;
    } else {
        return <MigrateOpsWizardEmptyState recipeId={recipeId} />;
    }
};

// ======================
// MigrateOpsWizardEmptyState
// ======================

interface MigrateOpsWizardEmptyStateProps {
    recipeId: OperationRecipeID;
}

const MigrateOpsWizardEmptyState: React.FC<MigrateOpsWizardEmptyStateProps> = (p) => {
    const { recipeId } = p;
    const openHelpArticle = useOpenHelpArticle();
    const goToRecipeReference = useNavigatetoRecipeReference();
    const label = getOperationRecipeLabel(recipeId);
    return (
        <ScreenContainer>
            <Card>
                <Stack spacing={4} p={6} direction={"row"} justifyContent={"center"} alignItems={"center"}>
                    <Stack spacing={2} alignItems={"center"} textAlign={"center"}>
                        <Typography variant={"h4"}>{label}</Typography>
                        <Typography>
                            {`MigrateOps Configuration Guide is not currently available. To learn more about this recipe, click the following button to review the recipe reference.`}
                        </Typography>
                        <Stack direction={"row"} spacing={2}>
                            <Button
                                variant={"outlined"}
                                color={"neutral"}
                                onClick={() => {
                                    goToRecipeReference(Number(recipeId) as OperationRecipeID);
                                }}
                            >
                                {"View Recipe Reference"}
                            </Button>
                            <Button variant={"contained"} color={"secondary"} onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS_CONFIG)}>
                                {"Get Help Migrating"}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </ScreenContainer>
    );
};
