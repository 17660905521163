// ======================
// MigrateOpsWizardGetStartedStep
// ======================

import { Alert, Box, Slide, Stack, TextField, Typography } from "@mui/material";
import { useStepperContext } from "../../../common/stepper/StepperState";
import React from "react";
import { SlideAndFadeInStep, StepperNavButtons } from "../../../common/stepper/StepperComponents";
import { LocalDataMigrationWizardStepId } from "./localDataMigration/MigrateOpsLocalDataMigrationWizard";

interface MigrateOpsWizardGetStartedStepProps {
    goToNextStepFn: () => void;
    id: string;
}

export const MigrateOpsWizardGetStartedStep: React.FC<MigrateOpsWizardGetStartedStepProps> = (p) => {
    const { goToNextStepFn, id } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const [slideDirection, setSlideDirection] = React.useState<"up" | "down">("up");
    return (
        <SlideAndFadeInStep direction={slideDirection} transitionIn={stepConfigs[activeStep].id === id}>
            <Stack direction={"column"} spacing={6}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={2} pr={6} pl={6}>
                    <Typography variant={"h2"}>{"Let's get started."}</Typography>
                    <Typography variant={"body1"} textAlign={"center"}>
                        {"In just a few questions, you'll be able to set up create a basic MigrateOps configuration for this recipe. " +
                            "You can further customize the generated MigrateOps Configuration before submitting for execution."}
                    </Typography>
                </Stack>
                <Box pr={6} pl={6}>
                    <Alert severity={"info"}>
                        {`The configuration generated from this builder only covers basic use cases and does not cover every possible configuration. You will likely need to further customize the configuration before execution to cover all your particular use cases.`}
                    </Alert>
                </Box>
                <Box>
                    <StepperNavButtons
                        nextButtonProps={{
                            onClick: () => {
                                setSlideDirection("down");
                                goToNextStepFn();
                            },
                        }}
                    />
                </Box>
            </Stack>
        </SlideAndFadeInStep>
    );
};
