import { create } from "zustand";
import ProtoDoc from "../../assets/migrateOps/migrateops-proto.json";
import { immer } from "zustand/middleware/immer";
import _ from "lodash-es";
import { ConfigFieldMap } from "./MigrateOpsGeneratedProtobufJsonHelpers";
import { OperationRecipeID } from "gc-web-proto/galaxycompletepb/operationpb/operation_pb";
interface OperationObject {
    recipe: string;
    config: any;
    name: string;
    notes: string;
}

interface OperationExpansionState {
    [key: string]: null | boolean | OperationExpansionState | Array<OperationExpansionState>;
}

interface OperationSelectedOneOfState {
    [key: string]: null | string | OperationSelectedOneOfState | Array<OperationSelectedOneOfState>;
}

interface MigrateOpsNewOperationBuilderState {
    operations: OperationObject[];
    operationsExpansionState: OperationExpansionState[];
    operationsSelectedOneOfState: OperationSelectedOneOfState[];
    addOperation: () => void;
    removeOperation: (opIndex: number) => void;
    createFinalJsObject: () => void;
    setOperationField: (opIndex: number, field: keyof OperationObject, value: any) => void;
    setConfigField: (opIndex: number, nestedFields: string | Array<string | number>, value: any) => void;
    addItemToArrayField: (opIndex: number, nestedFields: string | Array<string | number>, value: any) => void;
    removeItemFromArrayField: (opIndex: number, nestedFields: string | Array<string | number>, arrayIndex: number) => void;
    getConfigField: (opIndex: number, nestedFields: string | Array<string | number>) => any;
    generatedJsonObject: { [key: string]: any };
    setOperationConfigFieldMapFromRecipe: (configFieldMap: ConfigFieldMap, opIndex: number) => void;
    setSelectedOneOfValue: (opIndex: number, nestedFields: string | Array<string | number>, value: string) => void;
    getSelectedOneOfValue: (opIndex: number, nestedFields: string | Array<string | number>) => string | null;
    operationConfigFieldMaps: ConfigFieldMap[];
    getOperationConfigFieldMap: (opIndex: number) => ConfigFieldMap;
    setObjectFieldExpanded: (opIndex: number, nestedFields: string | Array<string | number>, value: boolean) => void;
    getObjectFieldExpanded: (opIndex: number, nestedFields: string | Array<string | number>) => boolean | null;
    resetBuilderState: () => void;
    isGeneratedJsonAppliedToEditor: boolean;
    setJsonAppliedToEditor: (applied: boolean) => void;
}

export const getInitialMigrateOperationObject = (): OperationObject => {
    return {
        recipe: "",
        config: {},
        name: "",
        notes: "",
    };
};

export const getInitialMigrateOperationExpansionState = (): OperationExpansionState => {
    return {
        recipe: null,
        config: {
            currentlyExpanded: true,
        },
        name: null,
        notes: null,
    };
};

export const getInitialMigrationOperationSelectedOneOfState = (): OperationSelectedOneOfState => {
    return {
        recipe: null,
        config: null,
        name: null,
        notes: null,
    };
};

export const useMigrateOpsNewOperationBuilderState = create<MigrateOpsNewOperationBuilderState>()(
    immer((set, get) => ({
        isGeneratedJsonAppliedToEditor: false,
        setJsonAppliedToEditor: (applied: boolean) => {
            set((state) => {
                state.isGeneratedJsonAppliedToEditor = applied;
            });
        },
        operations: [getInitialMigrateOperationObject()],
        operationsExpansionState: [getInitialMigrateOperationExpansionState()],
        operationsSelectedOneOfState: [getInitialMigrationOperationSelectedOneOfState()],
        operationConfigFieldMaps: [],
        addOperation: () => {
            set((state) => {
                state.operations.push(getInitialMigrateOperationObject());
                state.operationsExpansionState.push(getInitialMigrateOperationExpansionState());
                state.operationsSelectedOneOfState.push(getInitialMigrationOperationSelectedOneOfState());
            });
        },
        setOperationConfigFieldMapFromRecipe: (configFieldMap: ConfigFieldMap, opIndex: number) => {
            set((state) => {
                state.operationConfigFieldMaps[opIndex] = configFieldMap;
                state.operationsSelectedOneOfState[opIndex].config = generateSelectedOneOfStateFromConfigFieldMap(configFieldMap);
                state.operationsExpansionState[opIndex].config = generateExpandedStateFromConfigFieldMap(configFieldMap);
            });
            get().createFinalJsObject();
        },
        getOperationConfigFieldMap: (opIndex: number) => {
            return get().operationConfigFieldMaps[opIndex];
        },
        getSelectedOneOfValue: (opIndex: number, nestedFields: string | Array<string | number>) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                return _.get(get().operationsSelectedOneOfState[opIndex].config, `${key}.currentlySelected`);
            }
        },
        setSelectedOneOfValue: (opIndex: number, nestedFields: string | Array<string | number>, value: string) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                set((state) => {
                    if (!!get().getSelectedOneOfValue(opIndex, nestedFields)) {
                        const currentSelectedFieldName = get().getSelectedOneOfValue(opIndex, nestedFields);
                        const blankSelectedOneOfState = recursivelyClearSelectedOneOfState(
                            JSON.parse(JSON.stringify(_.get(state.operationsSelectedOneOfState[opIndex].config, key)))
                        );
                        const blankExpandedState = recursivelyClearExpandedState(
                            JSON.parse(JSON.stringify(_.get(state.operationsExpansionState[opIndex].config, key)))
                        );
                        _.set(state.operationsExpansionState[opIndex].config as object, `${key}`, blankExpandedState);
                        _.set(state.operationsSelectedOneOfState[opIndex].config as object, `${key}`, blankSelectedOneOfState);
                        _.set(state.operations[opIndex].config as object, `${key}.${currentSelectedFieldName}`, null);
                    }

                    _.set(state.operationsSelectedOneOfState[opIndex].config as object, `${key}.currentlySelected`, value);
                });
            }
            get().createFinalJsObject();
        },
        setObjectFieldExpanded: (opIndex: number, nestedFields: string | Array<string | number>, value: boolean) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                set((state) => {
                    _.set(state.operationsExpansionState[opIndex].config as object, `${key}.currentlyExpanded`, value);
                });

                //if closing section, close all sections inside
                if (value === false) {
                    set((state) => {
                        const blankExpandedState = recursivelyClearExpandedState(
                            JSON.parse(JSON.stringify(_.get(state.operationsExpansionState[opIndex].config, key)))
                        );
                        _.set(state.operationsExpansionState[opIndex].config as object, `${key}`, blankExpandedState);
                    });
                }
            }
        },
        getObjectFieldExpanded: (opIndex: number, nestedFields: string | Array<string | number>) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                return _.get(get().operationsExpansionState[opIndex].config, `${key}.currentlyExpanded`);
            }
        },
        removeOperation: (opIndex: number) => {
            set((state) => {
                state.operations.splice(opIndex, 1);
            });
            get().createFinalJsObject();
        },
        setOperationField: (opIndex: number, field: keyof OperationObject, value: any) => {
            set((state) => {
                if (field === "recipe" && !!state.operations[opIndex][field]) {
                    const allOtherRecipes = Object.keys(OperationRecipeID)
                        .map((recipe) => recipe.toLowerCase())
                        .filter((recipe) => recipe !== value);
                    for (let recipe of allOtherRecipes) {
                        delete state.operations[opIndex].config[recipe];
                    }
                }
                state.operations[opIndex][field] = value;
            });

            get().createFinalJsObject();
        },
        setConfigField: (opIndex: number, nestedFields: string | Array<string | number>, value: any) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                set((state) => {
                    _.set(state.operations[opIndex].config, key, value);
                });
            } else {
                set((state) => {
                    state.operations[opIndex].config[nestedFields as string] = value;
                });
            }
            get().createFinalJsObject();
        },
        getConfigField: (opIndex: number, nestedFields: string | Array<string | number>) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                return _.get(get().operations[opIndex].config, key);
            } else {
                return get().operations[opIndex].config[nestedFields as string];
            }
        },
        addItemToArrayField: (opIndex: number, nestedFields: string | Array<string | number>, value: any) => {
            if (Array.isArray(nestedFields)) {
                const key = nestedFields.join(".");
                set((state) => {
                    if (!get().getConfigField(opIndex, nestedFields)) {
                        _.set(state.operations[opIndex].config, key, []);
                    }
                    _.set(state.operations[opIndex].config, `${key}[${get().getConfigField(opIndex, nestedFields)?.length || 0}]`, value);

                    if (_.get(state.operationsSelectedOneOfState[opIndex].config, key)) {
                        const blankSelectedOneOfState = recursivelyClearSelectedOneOfState(
                            JSON.parse(JSON.stringify(_.get(state.operationsSelectedOneOfState[opIndex].config, key)[0]))
                        );
                        if (get().getConfigField(opIndex, nestedFields)?.length >= 1) {
                            _.set(
                                state.operationsSelectedOneOfState[opIndex].config as object,
                                `${key}[${_.get(state.operationsSelectedOneOfState[opIndex].config, key).length}]`,
                                blankSelectedOneOfState
                            );
                        }
                    }
                    if (_.get(state.operationsExpansionState[opIndex].config, key)) {
                        const blankExpandedState = recursivelyClearExpandedState(
                            JSON.parse(JSON.stringify(_.get(state.operationsExpansionState[opIndex].config, key)[0]))
                        );
                        if (get().getConfigField(opIndex, nestedFields)?.length >= 1) {
                            _.set(
                                state.operationsExpansionState[opIndex].config as object,
                                `${key}[${_.get(state.operationsExpansionState[opIndex].config, key).length}]`,
                                blankExpandedState
                            );
                        }
                    }
                });
            } else {
                set((state) => {
                    state.operations[opIndex].config[nestedFields as string].push(value);
                });
            }
        },
        removeItemFromArrayField: (opIndex: number, nestedFields: string | Array<string | number>, arrayIndex: number) => {
            if (Array.isArray(nestedFields)) {
                set((state) => {
                    const currentArray = [...state.getConfigField(opIndex, nestedFields)];
                    _.pullAt(currentArray, arrayIndex);
                    _.set(state.operations[opIndex].config, nestedFields, currentArray.length === 0 ? null : currentArray);
                    if (_.get(state.operationsSelectedOneOfState[opIndex].config, nestedFields)) {
                        const currentSelectedOneOfArray = [..._.get(state.operationsSelectedOneOfState[opIndex].config, nestedFields)];
                        if (arrayIndex !== 0) {
                            _.pullAt(currentSelectedOneOfArray, arrayIndex);
                            _.set(state.operationsSelectedOneOfState[opIndex].config as object, nestedFields, currentSelectedOneOfArray);
                        } else {
                            currentSelectedOneOfArray[arrayIndex] = recursivelyClearSelectedOneOfState(currentSelectedOneOfArray[arrayIndex]);
                        }
                    }
                    if (_.get(state.operationsExpansionState[opIndex].config, nestedFields)) {
                        const currentExpandedArray = [..._.get(state.operationsExpansionState[opIndex].config, nestedFields)];
                        if (arrayIndex !== 0) {
                            _.pullAt(currentExpandedArray, arrayIndex);
                            _.set(state.operationsExpansionState[opIndex].config as object, nestedFields, currentExpandedArray);
                        } else {
                            currentExpandedArray[arrayIndex] = recursivelyClearExpandedState(currentExpandedArray[arrayIndex]);
                        }
                    }
                });
            } else {
                set((state) => {
                    _.pullAt(state.operations[opIndex].config[nestedFields as string], arrayIndex);
                });
            }
            get().createFinalJsObject();
        },
        createFinalJsObject: () => {
            const operations = JSON.parse(JSON.stringify(get().operations));
            const jsObject = {
                operations,
            };
            const oneOfSelectedState = JSON.parse(JSON.stringify(get().operationsSelectedOneOfState));
            const onOfSelectedObj = {
                operations: oneOfSelectedState,
            };
            let sanitizedObject = sanitizeObjectOfEmptyFields(jsObject);
            const reformattedObject = reformatObjectForOneOfs(sanitizedObject, onOfSelectedObj);
            set((state) => {
                state.generatedJsonObject = reformattedObject;
            });
        },
        generatedJsonObject: {},
        resetBuilderState: () => {
            set((state) => {
                state.generatedJsonObject = {};
                state.operationsExpansionState = [getInitialMigrateOperationExpansionState()];
                state.operationsSelectedOneOfState = [getInitialMigrationOperationSelectedOneOfState()];
                state.operations = [getInitialMigrateOperationObject()];
                state.operationConfigFieldMaps = [];
            });
        },
    }))
);

export const generateJsonObjectFromConfigFieldMap = (configFieldMap: ConfigFieldMap) => {
    const jsonObject: { [key: string]: any } = {};
    Object.keys(configFieldMap).forEach((key) => {
        if (configFieldMap[key].dataType === "array") {
            if (configFieldMap[key].arrayItemType) {
                jsonObject[key] = [generateJsonObjectFromConfigFieldMap(configFieldMap[key].arrayItemType)];
            } else {
                jsonObject[key] = [];
            }
        } else if (configFieldMap[key].dataType === "object") {
            jsonObject[key] = generateJsonObjectFromConfigFieldMap(configFieldMap[key].properties);
        } else if (configFieldMap[key].dataType === "oneOf") {
            jsonObject[key] = {};
        } else {
            jsonObject[key] = null;
        }
    });
    return jsonObject;
};

export const generateSelectedOneOfStateFromConfigFieldMap = (configFieldMap: ConfigFieldMap) => {
    const selectedOneOfState: OperationSelectedOneOfState = {};
    Object.keys(configFieldMap).forEach((key) => {
        if (configFieldMap[key].dataType === "array") {
            if (configFieldMap[key].arrayItemType) {
                selectedOneOfState[key] = [generateSelectedOneOfStateFromConfigFieldMap(configFieldMap[key].arrayItemType)];
            } else {
                selectedOneOfState[key] = null;
            }
        } else if (configFieldMap[key].dataType === "object") {
            selectedOneOfState[key] = generateSelectedOneOfStateFromConfigFieldMap(configFieldMap[key].properties);
        } else if (configFieldMap[key].dataType === "oneOf") {
            selectedOneOfState[key] = {
                ...generateSelectedOneOfStateFromConfigFieldMap(configFieldMap[key].oneOfFields),
                currentlySelected: null,
            };
        } else {
            selectedOneOfState[key] = null;
        }
    });
    return selectedOneOfState;
};

export const generateExpandedStateFromConfigFieldMap = (configFieldMap: ConfigFieldMap) => {
    const expandedState: OperationExpansionState = {};
    Object.keys(configFieldMap).forEach((key) => {
        if (configFieldMap[key].dataType === "array") {
            if (configFieldMap[key].arrayItemType) {
                expandedState[key] = [generateExpandedStateFromConfigFieldMap(configFieldMap[key].arrayItemType)];
            } else {
                expandedState[key] = null;
            }
        } else if (configFieldMap[key].dataType === "object") {
            expandedState[key] = {
                ...generateExpandedStateFromConfigFieldMap(configFieldMap[key].properties),
                currentlyExpanded: false,
            };
        } else if (configFieldMap[key].dataType === "oneOf") {
            expandedState[key] = generateExpandedStateFromConfigFieldMap(configFieldMap[key].oneOfFields);
        } else {
            expandedState[key] = null;
        }
    });
    return expandedState;
};

const recursivelyClearExpandedState = (expandedState: OperationExpansionState) => {
    Object.entries(expandedState).forEach(([k, v]) => {
        if (v && typeof v === "object") {
            recursivelyClearExpandedState(v as OperationExpansionState);
        }
        if (k === "currentlyExpanded") {
            expandedState[k] = false;
        }
        if (Array.isArray(v)) {
            v.forEach((item) => {
                recursivelyClearExpandedState(item);
            });
        }
    });
    return expandedState;
};

const recursivelyClearSelectedOneOfState = (selectedOneOfState: OperationSelectedOneOfState) => {
    Object.entries(selectedOneOfState).forEach(([k, v]) => {
        if (v && typeof v === "object") {
            recursivelyClearSelectedOneOfState(v as OperationSelectedOneOfState);
        }
        if (k === "currentlySelected") {
            selectedOneOfState[k] = null;
        }
        if (Array.isArray(v)) {
            v.forEach((item) => {
                recursivelyClearSelectedOneOfState(item);
            });
        }
    });
    return selectedOneOfState;
};

export const sanitizeObjectOfEmptyFields = (object: { [key: string]: any }, deleteEmptyArray?: boolean) => {
    Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === "object") {
            sanitizeObjectOfEmptyFields(v, deleteEmptyArray);
        }
        if (v === null || v === undefined) {
            if (Array.isArray(object)) {
                object.splice(Number(k), 1);
            } else {
                delete object[k];
            }
        }

        if (deleteEmptyArray && Array.isArray(v) && v.length === 0) {
            object[k] = undefined;
            delete object[k];
        }
    });
    return object;
};

const reformatObjectForOneOfs = (object: { [key: string]: any }, oneOfState: OperationSelectedOneOfState) => {
    Object.keys(object).forEach((key) => {
        if (typeof object[key] === "object") {
            reformatObjectForOneOfs(object[key], oneOfState[key] as OperationSelectedOneOfState);
            if (!!oneOfState[key]) {
                if (Object.keys(oneOfState[key]).includes("currentlySelected")) {
                    let innerObj = { ...object[key] };
                    for (let field in innerObj) {
                        object[field] = innerObj[field];
                    }
                    delete object[key];
                }
            }
        }
    });
    return object;
};
