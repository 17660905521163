import { DialogState } from "./DialogService";
import * as React from "react";
import { Box, DialogTitle, Divider, IconButton, IconButtonProps, ListSubheader, Theme } from "@mui/material";
import { CloseIcon } from "../../../common/CommonIcons";
import { observer } from "mobx-react-lite";

// ======================
// DialogTopBar
// ======================

interface DialogTopBarProps {
    dialogState: DialogState;
    title: string;
    actions?: React.ReactNode[];
    list?: boolean;
    onDialogClosed?: Function;
    divider?: boolean;
}

export const DialogTopBar: React.FC<DialogTopBarProps> = observer((p) => {
    const _renderTitle = () => {
        if (p.list) {
            return <ListSubheader>{p.title}</ListSubheader>;
        }
        return <DialogTitle>{p.title}</DialogTitle>;
    };
    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                {_renderTitle()}
                <Box display={"flex"} alignItems={"center"}>
                    {p.actions?.map((a, i) => (
                        <div key={i}>{a}</div>
                    ))}
                    <CloseDialogButton dialogState={p.dialogState} onClosed={p.onDialogClosed} />
                </Box>
            </Box>
            {p.divider && <Divider />}
        </>
    );
});

// ======================
// CloseDialogButton
// ======================

interface CloseDialogButtonProps {
    dialogState: DialogState;
    onClosed?: Function;
}

export const CloseDialogButton: React.FC<CloseDialogButtonProps & Partial<IconButtonProps>> = (props) => {
    const { dialogState, onClosed, ...rest } = props;

    const onClose = () => {
        dialogState.close();
        if (!!props.onClosed) {
            props.onClosed();
        }
    };

    return (
        <Box p={1}>
            <IconButton onClick={onClose} {...rest}>
                <CloseIcon />
            </IconButton>
        </Box>
    );
};
