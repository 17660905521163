import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { useNavigateToUserLicensePage } from "../user/UserCommon";
import { BackButton } from "../../common/CommonButtons";
import { alpha, Box, Button, IconButton, Link, SvgIcon, TextField, Theme, Typography } from "@mui/material";
import { renderServerDataWithLoadingList, useInitData } from "../core/data/DataLoaderHooks";
import { useAppServices } from "../app/services";
import { LicenseStorePurchasableProduct } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { getCounterTypeDisplayValue } from "./LicenseCommon";
import { FiMinus, FiPlus } from "react-icons/fi";
import { SelectableBox } from "../../common/card/SelectableCard";
import { HELP_CENTER_URL } from "../help/HelpCommon";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { IoBagCheckOutline } from "react-icons/io5";
import Grid from "@mui/material/Grid2";

// ======================
// PurchaseLicenseScreen
// ======================

interface PurchaseLicenseScreenProps {}

export const PurchaseLicenseScreen: React.FC<PurchaseLicenseScreenProps> = observer((p) => {
    const goBackToUserLicense = useNavigateToUserLicensePage();
    const { licenseService } = useAppServices();

    useInitData({
        poll: () => licenseService.licensePurchaseCatalog.fetchData(),
        pollInterval: 300,
    });

    return (
        <ScreenContainer>
            <BackButton navFunction={goBackToUserLicense} label={"Back To License Key"} />
            <ScreenTitleBar title={"Cirrus Data Cloud License Marketplace"} />
            <Typography variant={"body2"} color={"textSecondary"}>
                For enterprise license and other purchase / subscription related inquiries, please <Link href={HELP_CENTER_URL}>contact sales</Link>.
            </Typography>
            {renderServerDataWithLoadingList(licenseService.licensePurchaseCatalog, (data) => {
                return <LicenseProductForm data={data} />;
            })}
        </ScreenContainer>
    );
});

// ======================
// LicenseProductForm
// ======================

interface LicenseProductFormProps {
    data: LicenseStorePurchasableProduct.AsObject[];
}

export const LicenseProductForm: React.FC<LicenseProductFormProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const [selected, setSelected] = useState<{ [priceId: string]: number }>({});

    const setItemQuantity = useCallback(
        (priceId: string, quantity: number) => {
            if (quantity <= 999 && quantity >= 0) {
                setSelected({ ...selected, [priceId]: quantity });
            }
            if (!quantity) {
                setSelected({ ...selected, [priceId]: 0 });
            }
        },
        [setSelected, selected]
    );
    const incrementItemQuantity = useCallback(
        (priceId: string) => {
            const currentValue = selected[priceId] || 0;
            setItemQuantity(priceId, currentValue + 1);
        },
        [selected, setItemQuantity]
    );
    const decrementItemQuantity = useCallback(
        (priceId: string) => {
            const currentValue = selected[priceId] || 0;
            setItemQuantity(priceId, currentValue - 1);
        },
        [selected, setItemQuantity]
    );

    const totalItemsSelected = Object.values(selected || {}).reduce((a, b) => a + b, 0);

    const onCheckout = useCallback(async () => {
        const res = await licenseService.startPurchaseSession(selected);
        const url = res.getUrl();
        window.location.replace(url);
    }, [licenseService, selected]);

    const productsWithoutLicense = p.data.filter((p) => p.licenseCounterType === LicenseVaultCounterType.LicenseVaultCounterType.UNKNOWN);
    return (
        <div>
            {Object.values(LicenseVaultCounterType.LicenseVaultCounterType)
                .filter((x) => typeof x === "number" && x !== LicenseVaultCounterType.LicenseVaultCounterType.UNKNOWN)
                .map((v) => v as LicenseVaultCounterType.LicenseVaultCounterType)
                .map((licenseType) => {
                    const products = p.data.filter((p) => p.licenseCounterType === licenseType);
                    return !products?.length ? null : (
                        <LicenseProductGroupSection
                            groupName={getCounterTypeDisplayValue(licenseType)}
                            groupDescription={null}
                            selectedQuantities={selected}
                            setItemQuantity={setItemQuantity}
                            incrementItemQuantity={incrementItemQuantity}
                            decrementItemQuantity={decrementItemQuantity}
                            products={products}
                        />
                    );
                })}
            {productsWithoutLicense.map((product) => {
                return (
                    <LicenseProductGroupSection
                        groupName={product.name}
                        groupDescription={product.description}
                        selectedQuantities={selected}
                        setItemQuantity={setItemQuantity}
                        incrementItemQuantity={incrementItemQuantity}
                        decrementItemQuantity={decrementItemQuantity}
                        products={[product]}
                    />
                );
            })}

            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pt={5}>
                <Button
                    startIcon={<IoBagCheckOutline />}
                    onClick={onCheckout}
                    disabled={!totalItemsSelected}
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                >
                    {"Checkout"}
                </Button>
            </Box>
            <Box pt={2}>
                <Typography variant={"body2"} color={"textSecondary"}>
                    All payment processing including payment methods and invoice management are provided by Stripe, our payment platform partner which is PCI
                    compliant. Cirrus Data does not have access to your credit card or bank information.
                </Typography>
            </Box>
        </div>
    );
});

// ======================
// LicenseProductGroupSection
// ======================

interface LicenseProductGroupSectionProps {
    groupName: string;
    groupDescription: string;
    products: LicenseStorePurchasableProduct.AsObject[];

    selectedQuantities: { [priceId: string]: number };
    setItemQuantity: (priceId: string, quantity: number) => void;
    incrementItemQuantity: (priceId: string) => void;
    decrementItemQuantity: (priceId: string) => void;
}

const LicenseProductGroupSection: React.FC<LicenseProductGroupSectionProps> = observer((p) => {
    return (
        <Box pt={2}>
            <Typography variant={"h5"}>{p.groupName}</Typography>
            {/*<Typography variant={'h5'}>{getCounterTypeDisplayValue(p.product.licenseCounterType)}</Typography>*/}
            {p.groupDescription && <Typography variant={"body2"}>{p.groupDescription}</Typography>}
            <br />
            <Grid container spacing={3}>
                {p.products.map((product, i) => {
                    return (
                        <LicenseProductCard
                            product={product}
                            key={product.priceId}
                            incrementItemQuantity={() => p.incrementItemQuantity(product.priceId)}
                            decrementItemQuantity={() => p.decrementItemQuantity(product.priceId)}
                            setItemQuantity={(q) => p.setItemQuantity(product.priceId, q)}
                            selectedQuantity={p.selectedQuantities[product.priceId] || 0}
                        />
                    );
                })}
            </Grid>
        </Box>
    );
});

// ======================
// LicenseProductPriceCard
// ======================

interface LicenseProductCardProps {
    product: LicenseStorePurchasableProduct.AsObject;

    selectedQuantity: number;

    setItemQuantity: (quantity: number) => void;
    incrementItemQuantity: () => void;
    decrementItemQuantity: () => void;
}

export const LicenseProductCard: React.FC<LicenseProductCardProps> = observer((p) => {
    return (
        <Grid
            size={{
                xs: 12,
                sm: 6,
                md: 4,
            }}
        >
            <SelectableBox selected={!!p.selectedQuantity} disableHoverEffects>
                <Box
                    sx={{
                        backgroundColor: (t: Theme) => alpha(t.palette.cirrus.light, 0.7),
                        borderBottom: "1px solid",
                        borderBottomColor: (t: Theme) => t.palette.cirrus.light,
                    }}
                >
                    <Box textAlign={"center"} p={1}>
                        <Typography variant={"body2"}>{p.product.name}</Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            {p.product.description}
                        </Typography>
                    </Box>
                </Box>
                <Box p={4} textAlign={"center"}>
                    <Typography variant={"h3"}>
                        {getCurrencySymbolFromCurrencyString(p.product.priceCurrency)}
                        {p.product.priceCents / 100}
                    </Typography>
                    <Typography variant={"caption"} color={"textSecondary"}>
                        per {p.product.unit}
                    </Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid", borderTopColor: (t: Theme) => t.palette.cirrus.light }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid size={5}>
                            <Box pl={1} display={"flex"} justifyContent={"flex-end"}>
                                <IconButton onClick={p.decrementItemQuantity}>
                                    <SvgIcon>
                                        <FiMinus />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid justifyContent={"center"} size={2}>
                            <Box display={"flex"} justifyContent={"center"} pt={2} pb={2}>
                                <TextField
                                    InputProps={{ sx: { height: "36px" } }}
                                    value={`${p.selectedQuantity}`}
                                    variant={"filled"}
                                    inputProps={{ style: { textAlign: "center", padding: "10px" } }}
                                    onChange={(e) => {
                                        try {
                                            const value = parseInt(e.target.value);
                                            p.setItemQuantity(value);
                                        } catch (e) {}
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid size={5}>
                            <Box display={"flex"} justifyContent={"flex-start"} pr={1}>
                                <IconButton onClick={p.incrementItemQuantity}>
                                    <SvgIcon>
                                        <FiPlus />
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SelectableBox>
        </Grid>
    );
});

const getCurrencySymbolFromCurrencyString = (currency: string) => {
    if (currency === "usd") {
        return "$";
    }
};
