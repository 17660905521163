import { AzureManagedDiskProduct, AzureStorageConsumptionRecommendation } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import xbytes from "xbytes";
import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";

export interface DiskAssignmentConfig {
    managedDiskStorageType?: AutoAlloc.VolumeParams.Azure.StorageType;
    elasticSan?: boolean;
    iops: number;
    throughput: number;
    capacity: number;
}

export interface AzureCalculatedDiskAssignmentConfig {
    product: AzureManagedDiskProduct.AsObject;
    iops: number;
    throughput: number;
    capacity: number;
    price: number;
}

export interface AzureConsumptionPlanDiskAssignmentConfig {
    volumeType: AzureStorageConsumptionRecommendation.VolumeType.AsObject;
    iops: number;
    throughput: number;
    capacity: number;
}

export const getDiskSizeInGiB = (capacity: number) => {
    return Number(new xbytes.ByteUnitObject(capacity).convertTo("GiB").split(" ")[0]);
};

export const DiskClassesWithEnabledParams = [AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2, AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD];

export const getDiskParamsEnabled = (diskClass: AutoAlloc.VolumeParams.Azure.StorageType) => {
    return DiskClassesWithEnabledParams.includes(diskClass);
};

export const getAzureStorageTypeDisplayName = (storageType: AutoAlloc.VolumeParams.Azure.StorageType) => {
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.STANDARD) {
        return `Standard HDD`;
    }
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.STANDARD_SSD) {
        return `Standard SSD`;
    }
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM) {
        return `Premium SSD`;
    }
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2) {
        return `Premium SSD v2`;
    }
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD) {
        return `Ultra SSD`;
    }
    if (storageType === AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD) {
        return `Ultra Disk Storage`;
    }
    return `Unknown`;
};
