// ======================
// CmoMigrationSessionDetailsScreen
// ======================

import { useParams } from "react-router-dom";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { useGetCmoMigrationSessionDetails } from "../cmo_migration_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { CmoMigrationSessionDetailsInfoTab } from "./CmoMigrationSessionDetailsInfoTab";
import { CmoMigrationSessionDetailsVolumeTab } from "./CmoMigrationSessionDetailsVolumesTab";
import { BackButton } from "../../../common/CommonButtons";
import { useNavigateToCmoMigrationSessionsList } from "../CmoMigrationCommon";
import { CmoMigrationSessionDetailsQosScheduleTab } from "./CmoMigrationSessionDetailsQosScheduleTab";
import { Box } from "@mui/material";

interface CmoMigrationSessionDetailsScreenProps {}

export const CmoMigrationSessionDetailsScreen: React.FC<CmoMigrationSessionDetailsScreenProps> = (p) => {
    const { projectId, sessionId } = useParams();
    const goBacktoMigrationSessionsList = useNavigateToCmoMigrationSessionsList();
    const queryResult = useGetCmoMigrationSessionDetails(sessionId);

    const tabConfig: TabConfig[] = [
        {
            label: "Volumes",
            renderer: () => <CmoMigrationSessionDetailsVolumeTab sessionId={sessionId} />,
        },
        {
            label: "Migration Schedule",
            renderer: () => <CmoMigrationSessionDetailsQosScheduleTab data={queryResult.data?.session.sessionInfo.qosSchedule} />,
        },
    ];

    return (
        <ScreenContainer>
            <BackButton navFunction={goBacktoMigrationSessionsList} label={"Back To Migration Sessions"} />
            <ScreenTitleBar title={`Cirrus Migrate On Premises Session`} />
            <QueryResultWrapper queryResult={queryResult}>
                <CmoMigrationSessionDetailsInfoTab data={queryResult.data?.session} />
                <Box pt={2}>
                    <TabGroup configs={tabConfig} />
                </Box>
            </QueryResultWrapper>
        </ScreenContainer>
    );
};
