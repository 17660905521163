// ======================
// MigrateOpsConfigurationsTab
// ======================

import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { OperationDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/operation_pb";
import { useGetMigrateOpConfig, useUpdateOperationConfig } from "../migrate_ops_hooks";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import hljs from "highlight.js/lib/core";
import { DarkFlatOutlinedCard } from "../../../common/card/DarkCard";
import Editor from "react-simple-code-editor";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { OperationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/operation_status_pb";
import { UpdateOperationConfig } from "gc-web-proto/galaxycompletepb/apipb/operation_api_pb";
import { OperatorView } from "../../auth/AuthenticatedViews";

interface MigrateOpsConfigurationsTabProps {
    data: OperationDetails.AsObject;
}

export const MigrateOpsConfigurationsTab: React.FC<MigrateOpsConfigurationsTabProps> = (p) => {
    const { data } = p;
    const configRes = useGetMigrateOpConfig(data.info.id);
    if (!data?.info?.id) {
        return null;
    }
    return (
        <QueryResultWrapper queryResult={configRes}>
            <ConfigYamlDisplay yaml={configRes.data?.yamlString} opStatus={data?.info.status} opId={data?.info.id} />
        </QueryResultWrapper>
    );
};

interface ConfigYamlDisplayProps {
    yaml: string;
    opStatus: OperationStatus.OperationStatus;
    opId: number;
}

function ConfigYamlDisplay(p: ConfigYamlDisplayProps) {
    const [editOn, setEditOn] = useState(false);
    const [editValue, setEditValue] = useState("");
    const html = hljs.highlight(p.yaml, { language: "yaml" }).value;
    const updateConfig = useUpdateOperationConfig();
    const globalDialogState = useGlobalDialogState();
    const onSave = async () => {
        if (p.opStatus !== OperationStatus.OperationStatus.PENDING) {
            const confirmed = await globalDialogState.addConfirmDialog({
                title: "Confirm Configuration Update",
                message: (
                    <Box>
                        <Typography>{"WARNING! Operation has already been started."}</Typography>
                        <br />
                        <Typography>
                            {`This operation has already been partially executed.
                            While you can still edit the operation configuration,
                            updating the configuration now may have`}{" "}
                            <b>{`conflicts`}</b>{" "}
                            {`with the operation tasks that have already been executed.
                        This could result in unwanted consequences or failure.`}
                        </Typography>
                        <br />
                        <Typography>
                            {`Depending on the operation recipe and the fields that are changed, 
                        some changes may be ignored if they have already been executed.`}
                        </Typography>
                        <br />
                        <Typography>{"If you require additional assistance, please contact Cirrus Data Support."}</Typography>
                    </Box>
                ),
                typeToConfirm: "UPDATE",
            });
            if (confirmed) {
                const req = new UpdateOperationConfig.Request().setYamlString(editValue).setOperationId(p.opId);
                await updateConfig.mutateAsync(req);
                setEditOn(false);
            }
        }
    };

    const configActions = (
        <Stack direction={"row"} spacing={1}>
            <OperatorView>
                <Button
                    variant={"contained"}
                    disabled={editOn || p.opStatus === OperationStatus.OperationStatus.COMPLETED}
                    onClick={() => {
                        setEditValue(p.yaml);
                        setEditOn(true);
                    }}
                >
                    {"Edit Configuration"}
                </Button>
            </OperatorView>
        </Stack>
    );

    return (
        <Box pt={2}>
            <Card>
                <CardHeader
                    title={"MigrateOps Configuration"}
                    subheader={`The following configuration is currently active for this operation.`}
                    action={configActions}
                />
                <CardContent>
                    {editOn ? (
                        <>
                            <DarkFlatOutlinedCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                                <Editor
                                    value={editValue}
                                    onValueChange={(v) => setEditValue(v)}
                                    placeholder={"Enter your MigrateOps Configuration YAML here..."}
                                    highlight={(code) => hljs.highlight(code, { language: "yaml" }).value}
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono","Consolas","Menlo", monospace',
                                        fontSize: 13,
                                        minHeight: "50vh",
                                    }}
                                />
                            </DarkFlatOutlinedCard>
                            <Stack direction={"row"} spacing={1} pt={2}>
                                <Button variant={"contained"} onClick={onSave}>
                                    {"Save"}
                                </Button>
                                <Button variant={"outlined"} color={"neutral"} onClick={() => setEditOn(false)}>
                                    {"Cancel"}
                                </Button>
                            </Stack>
                        </>
                    ) : (
                        <pre>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </pre>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}
