import { Button, Stack, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigateToAzureStoragePlanList, useNavigateToCreateNewAzureStoragePlannerAssessment } from "./AzureStoragePlannerHelpers";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { StepConfig } from "../../../common/stepper/StepperHelpers";
import { DynamicStepper } from "../../../common/stepper/StepperComponents";
import { AzureStoragePlannerDescriptionStep } from "./AzureStoragePlannerDescriptionStep";
import { AzureStoragePlannerSelectHostStep } from "./AzureStoragePlannerSelectHostStep";
import { AzureStoragePlannerGeneratePlanStep } from "./AzureStoragePlannerGeneratePlanStep";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import { useAzureStoragePlannerState } from "./AzureStoragePlannerState";

import { OperatorView } from "../../auth/AuthenticatedViews";
import { useMountEffect } from "../../../common/hooks/hookslib";

interface AzureStoragePlannerActionsProps {}
export const AzureStoragePlannerActions: React.FC<AzureStoragePlannerActionsProps> = (p) => {
    const goToPlanner = useNavigateToCreateNewAzureStoragePlannerAssessment();
    const goToPlanList = useNavigateToAzureStoragePlanList();

    return (
        <Stack spacing={2}>
            <OperatorView>
                <Button variant={"contained"} color={"primary"} onClick={goToPlanner}>{`Create Assessment`}</Button>
            </OperatorView>
            <Button variant={"outlined"} color={"neutral"} onClick={goToPlanList}>{`View Assessments`}</Button>
        </Stack>
    );
};

// ======================
// AzureStoragePlannerWizard
// ======================

interface AzureStoragePlannerWizardProps {}

export const AzureStoragePlannerWizard: React.FC<AzureStoragePlannerWizardProps> = (p) => {
    const projectId = useCurrentProjectID();
    const setProjectId = useAzureStoragePlannerState((state) => state.setProjectId);

    useMountEffect(() => {
        setProjectId(projectId);
    });

    const getStoragePlannerStepConfigs = (): StepConfig[] => {
        const descriptionStepConfig: StepConfig = {
            id: "description",
            label: `Description & Preferences`,
            renderer: () => <AzureStoragePlannerDescriptionStep />,
        };

        const sourceHostSelectionStepConfig: StepConfig = {
            id: "sourceHost",
            label: `Select Source Host`,
            renderer: () => <AzureStoragePlannerSelectHostStep />,
        };

        const generateConsumptionPlannerStepConfig: StepConfig = {
            id: "generatePlanner",
            label: `Generate Consumption Planner`,
            renderer: () => <AzureStoragePlannerGeneratePlanStep />,
        };

        return [descriptionStepConfig, sourceHostSelectionStepConfig, generateConsumptionPlannerStepConfig];
    };

    return (
        <ScreenContainer>
            <ScreenTitleBar title={`Azure Consumption Planner`} />
            <DynamicStepper
                stepperProps={{
                    activeStep: 0,
                    stepConfigs: getStoragePlannerStepConfigs(),
                    startingStep: 0,
                    totalSteps: getStoragePlannerStepConfigs().length,
                }}
                orientation={"horizontal"}
            />
        </ScreenContainer>
    );
};
