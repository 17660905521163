/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppBackgroundViews
 */

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "./services";
import { FullScreenLoading } from "../core/data/DataLoaders";
import { GlobalDialogViews } from "../core/dialog/GlobalDialogViews";
import { NotificationViews } from "../../common/notifications/NotificationViews";
import { BasicNotification } from "../../common/notifications/NotificationComponents";
import { GlobalProgressViews } from "../core/progress/GlobalProgressViews";

// ======================
// AppBackgroundViews
// ======================
interface AppBackgroundViewsProps {}

export const AppBackgroundViews: React.FC<AppBackgroundViewsProps> = observer(({ ...p }) => {
    const { progressService, dialogService } = useAppServices();
    return (
        <>
            {progressService.trackers.map((t) => {
                return <FullScreenLoading key={t.id} loading={progressService.hasOutstandingTrackers} message={t.message} />;
            })}
            <GlobalProgressViews />
            <GlobalDialogViews />
            <NotificationViews />
        </>
    );
});
