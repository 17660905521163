import { Label, LabelListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";

export const getScopeFromLabel = (label: string) => {
    return label.includes("//") ? label.split("//")[0] : "";
};

export const getUnscopedLabelsFromList = (labels: Array<LabelListItem.AsObject>) => {
    const list: Array<Label.AsObject> = [];

    labels.forEach((label) => {
        const labelName = label.label.name;
        const scope = getScopeFromLabel(labelName);
        if (!scope) {
            list.push(label.label);
        }
    });

    return list;
};
export const getScopeListFromLabels = (labels: Array<LabelListItem.AsObject>) => {
    const list: Array<string> = [];

    labels.forEach((label) => {
        const scope = getScopeFromLabel(label.label.name);
        if (!list.includes(scope) && scope !== "") {
            list.push(scope);
        }
    });

    return list;
};
