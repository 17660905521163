import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Alert, Box, Button, Card, CardContent, Link, TextField, Typography } from "@mui/material";
import { useListPeVirtualAppliances, useSubmitPeLicenseRequest } from "../privateEdition/private_edition_hooks";
import { SubmitPrivateEditionLicenseRequest } from "gc-web-proto/galaxycompletepb/apipb/pemanage_api_pb";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";

import { getCounterTypeDisplayValue, getLicenseValueFormat } from "../license/LicenseCommon";

import { getPeLicenseInteractionTypeDisplayValue } from "../privateEdition/PrivateEditionCommon";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../common/table/QueryTable";
import { PrivateEditionVirtualApplianceInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/pe_pb";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import { getLicenseDurationLeftString } from "../galaxymigrate/hostLicense/HostLicenseCommon";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import xbytes from "xbytes";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";

import { PeLicenseInteractionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/pe_license_interaction_type_pb";

// ======================
// ProjectSettingsPrivateEditionSetupScreen
// ======================

interface ProjectSettingsPrivateEditionSetupScreenProps {}

export const ProjectSettingsPrivateEditionSetupScreen: React.FC<ProjectSettingsPrivateEditionSetupScreenProps> = observer((p) => {
    return (
        <Box pt={2}>
            <UploadRequestCard />
            <br />
            <PrivateEditionInstallationsTable />
        </Box>
    );
});

// ======================
// UploadRequestCard
// ======================

interface UploadRequestCardProps {}

const UploadRequestCard: React.FC<UploadRequestCardProps> = observer((p) => {
    const [licenseRequest, setLicenseRequest] = useState("");

    const [licenseSubmissionRes, setLicenseSubmissionRes] = useState<SubmitPrivateEditionLicenseRequest.Response.AsObject>(null);

    const projectId = useCurrentProjectID();
    const submitPeLicenseRequest = useSubmitPeLicenseRequest();
    const globalDialogState = useGlobalDialogState();

    const openHelpArticle = useOpenHelpArticle();

    const licenseReceiptCopyKey = "licenseReceipt";
    const handleSubmitRequest = async () => {
        const req = new SubmitPrivateEditionLicenseRequest.Request().setProjectId(projectId).setPreview(true).setSignedLicenseRequest(licenseRequest);

        const res = await submitPeLicenseRequest.mutateAsync(req).catch((e) => {});

        if (!!res) {
            const licenseCountersToBeDeductedList = res.licenseCountersToBeDeductedList?.filter((c) => c.amount > 0);

            const confirmed = await globalDialogState.addConfirmDialog({
                title: "Private Edition License Transaction Confirmation",
                message: (
                    <Box pb={2}>
                        <Box pb={2}>
                            <Typography>{`Virtual Appliance: ${res.virtualApplianceInfo.systemName}`}</Typography>
                            <Typography>{`Transaction: ${getPeLicenseInteractionTypeDisplayValue(res.interactionType)}`}</Typography>
                        </Box>

                        {licenseCountersToBeDeductedList?.length > 0 && (
                            <>
                                <Typography>{`The following license will be deducted from this project's license key:`}</Typography>
                                <Box pt={2} pb={2}>
                                    <Card variant={"outlined"}>
                                        <CardContent>
                                            {licenseCountersToBeDeductedList?.map((counter, i) => {
                                                return (
                                                    <Box key={i}>
                                                        <Typography variant={"overline"}>
                                                            {`${getCounterTypeDisplayValue(counter.counterType.value)} - ${getLicenseValueFormat(
                                                                counter.amount,
                                                                counter.counterType.value
                                                            )}`}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </>
                        )}

                        <Alert severity={"warning"}>
                            {`DO NOT proceed if you cannot immediately import the transaction receipt back to your virtual appliance, or if your virtual appliance has been restarted since the license request was generated. If you're not sure, you can safely discard the pending request from your virtual appliance and re-create the transaction.`}
                        </Alert>
                    </Box>
                ),
            });
            if (confirmed) {
                req.setPreview(false);
                const res = await submitPeLicenseRequest.mutateAsync(req).catch((e) => {});
                if (!!res) {
                    setLicenseSubmissionRes(res);
                    // await globalDialogState.addConfirmDialog({
                    //     title: "Private Edition License Transaction Receipt",
                    //     message: (
                    //         <Box>
                    //             <Typography variant={"h6"}>{"Upload License Receipt"}</Typography>
                    //             <Typography>
                    //                 {`Please copy the following receipt and upload it to your Private Edition Virtual Appliance (${res.virtualApplianceInfo.systemName}) to complete the license transaction.`}
                    //             </Typography>
                    //             <Box pt={2} pb={2}>
                    //                 <Card sx={{ backgroundColor: "#000", width: "100%", wordBreak: "break-word" }}>
                    //                     <Box display={"flex"} justifyContent={"space-between"}>
                    //                         <Box p={2}>
                    //                             <ClipboardText clipboardId={licenseReceiptCopyKey}>{res.signedLicenseReceipt}</ClipboardText>
                    //                         </Box>
                    //                         <Box p={2}>
                    //                             <ClipboardButton clipboardId={licenseReceiptCopyKey} iconButton />
                    //                         </Box>
                    //                     </Box>
                    //                 </Card>
                    //             </Box>
                    //         </Box>
                    //     ),
                    //     autoConfirmationQuestionLine: false,
                    //     okButtonLabel: "Close",
                    //     hideCancelButton: true,
                    // });
                    if (res.interactionType !== PeLicenseInteractionType.PeLicenseInteractionType.CHECK_IN) {
                        await globalDialogState.addAlertDialog({
                            title: "Private Edition License Transaction Completed",
                            message:
                                "All requested licenses have been deducted from your project license key. Please import your license receipt to your Private Edition Virtual Appliance to complete the transaction immediately. You can only see the license receipt once.",
                        });
                    }

                    setLicenseRequest("");
                }
            }
        }
    };

    return (
        <Card>
            <CardContent>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                        <Typography variant={"h5"}>{"Upload License Request"}</Typography>
                        <Typography color={"textSecondary"}>
                            {"Submit license request from your Cirrus Data Cloud Private Edition virtual appliances. "}
                            <Link onClick={() => openHelpArticle(KnownArticle.PRIVATE_EDITION_LICENSING)}>Click here to learn more.</Link>
                        </Typography>
                    </Box>
                    <Box>
                        <Button variant={"outlined"} color={"neutral"} onClick={() => openHelpArticle(KnownArticle.ABOUT_PRIVATE_EDITION)}>
                            {"What is Private Edition?"}
                        </Button>
                    </Box>
                </Box>

                <Box pt={2} pb={2}>
                    <TextField
                        variant={"filled"}
                        label={"License Request"}
                        multiline
                        rows={10}
                        value={licenseRequest}
                        fullWidth
                        onChange={(e) => {
                            setLicenseRequest(e.target.value);
                        }}
                    />
                </Box>

                <Button variant={"contained"} color={"secondary"} onClick={handleSubmitRequest}>
                    {"Upload Request"}
                </Button>
                <br />

                {!!licenseSubmissionRes && !!licenseSubmissionRes.signedLicenseReceipt && (
                    <Box pt={2}>
                        <Typography variant={"h6"}>{"Upload License Receipt"}</Typography>
                        <Typography>
                            {`Please copy the following receipt and upload it to your Private Edition Virtual Appliance (${licenseSubmissionRes?.virtualApplianceInfo?.systemName}) to complete the license transaction.`}
                        </Typography>
                        <br />
                        <Card sx={{ backgroundColor: "#000000", width: "100%", wordBreak: "break-word" }}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                                <Box p={2}>
                                    <ClipboardText clipboardId={licenseReceiptCopyKey}>{licenseSubmissionRes.signedLicenseReceipt}</ClipboardText>
                                </Box>
                                <Box p={2}>
                                    <ClipboardButton clipboardId={licenseReceiptCopyKey} iconButton />
                                </Box>
                            </Box>
                        </Card>
                        <br />
                        <Alert severity={"warning"}>
                            {`DO NOT leave this page until you have copied the receipt to your virtual appliance. The receipt is not retrievable after you leave the page, and you will lose the license if the virtual appliance is restarted before the transaction is completed.`}
                        </Alert>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
});

// ======================
// PrivateEditionInstallationsTable
// ======================

interface PrivateEditionInstallationsTableProps {}

const PrivateEditionInstallationsTable: React.FC<PrivateEditionInstallationsTableProps> = observer((p) => {
    const projectId = useCurrentProjectID();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const openHelpArticle = useOpenHelpArticle();

    const queryResult = useListPeVirtualAppliances(projectId, pageIndex, pageSize);

    const columnHelper = createColumnHelper<PrivateEditionVirtualApplianceInfo.AsObject>();

    const cols: ColumnDef<PrivateEditionVirtualApplianceInfo.AsObject, any>[] = [
        columnHelper.accessor((r) => r.systemName, {
            id: "name",
            header: "Appliance",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.systemVersion, {
            id: "version",
            header: "Version",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.systemUrl, {
            id: "url",
            header: "URL",
            cell: (props) => <Link onClick={() => window.open(props.getValue(), "_blank")}>{props.getValue()}</Link>,
        }),
        columnHelper.accessor((r) => r.activatedAt, {
            id: "activatedAt",
            header: "Activated",
            cell: (props) => formatKnownDataType(convertTimestampObjectToDate(props.getValue()), KnownDataType.DATE),
        }),
        columnHelper.accessor((r) => r.expireAt, {
            id: "expireAt",
            header: "Expires",
            cell: (props) => {
                return (
                    <Typography>
                        {`${formatKnownDataType(convertTimestampObjectToDate(props.getValue()), KnownDataType.DATE)} (${getLicenseDurationLeftString(
                            convertTimestampObjectToDate(props.getValue())
                        )})`}
                    </Typography>
                );
            },
        }),
    ];

    return (
        <Card>
            <Box p={2} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h5"}>Private Edition Virtual Appliances</Typography>

                    <Typography>{`These virtual appliances are registered to this project`}</Typography>
                </Box>
                <Box>
                    <Button variant={"outlined"} color={"primary"} onClick={() => openHelpArticle(KnownArticle.INSTALLING_PRIVATE_EDITION)}>
                        {"Deploy New Virtual Appliance"}
                    </Button>
                </Box>
            </Box>
            <QueryTable
                tableComponent={Box}
                data={queryResult.data?.virtualApplianceInfosList}
                columns={cols}
                isError={queryResult.isError}
                isLoading={queryResult.isLoading}
                pageCount={queryResult.data?.pagerMeta?.totalPages}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
            />
        </Card>
    );
});
