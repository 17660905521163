// ======================
// UnactivatedRelayScreen
// ======================

import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Autocomplete, Box, Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { AppHintID, HintButton } from "../../help/HelpCommon";
import { useAppServices } from "../../app/services";
import { PortNumbers } from "gc-web-proto/galaxymigratepb/galaxy_migrate_pb";
import { MonitorView, OperatorView } from "../../auth/AuthenticatedViews";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";

interface UnactivatedRelayScreenProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentRelayUnactivatedScreen: React.FC<UnactivatedRelayScreenProps> = observer((p) => {
    return (
        <Box>
            <Card>
                <CardContent>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <Typography variant={"h4"}>Activate CDC Management Relay</Typography>
                            <Typography variant={"body1"}>
                                CDC Management Relay allows other Cirrus Migrate Cloud hosts to be deployed and connect to Cirrus Data Cloud via this host
                            </Typography>
                        </Box>
                        <Box>
                            <HintButton hintID={AppHintID.CMC_RELAY} />
                        </Box>
                    </Box>
                    <ActivateRelayForm deployment={p.deployment} />
                </CardContent>
            </Card>
        </Box>
    );
});

// ======================
// ActivateRelayForm
// ======================

interface ActivateRelayFormProps {
    deployment: GalaxyMigrateDeploymentDetails;
}

interface ServerAddressOptionType {
    address: string;
    network: string;
}

export const ActivateRelayForm: React.FC<ActivateRelayFormProps> = observer((p) => {
    const { dialogService, gmDeploymentService } = useAppServices();

    const networkInterfacesList = p.deployment.getNetworkInterfacesList()?.map((n) => {
        return {
            address: n.getAddr(),
            network: n.getName(),
        };
    });

    const [serverAddress, setServerAddress] = useState(networkInterfacesList[0]);
    const [inputValue, setInputValue] = useState(networkInterfacesList[0]?.address);
    const [error, setError] = useState("");

    const portNumber = p.deployment.getInfo().getOsType() === MTDIDeploymentInfo.OSType.WINDOWS ? PortNumbers.CDC_RELAY_HTTP : PortNumbers.CDC_RELAY;

    const activateConfirmationMessage = `Once CDC Management Relay is activated, 
    other Cirrus Migrate Cloud hosts will be able to be deploy and connect to Cirrus Data Cloud 
    via this host over the specified server address and port (${!!serverAddress ? serverAddress.address.split("/")[0] : inputValue}:${portNumber}).`;

    const onActivate = async () => {
        const confirmed = await dialogService.addConfirmDialog({
            message: activateConfirmationMessage,
            title: "Activate CDC Management Relay Confirmation",
        });
        if (confirmed) {
            if (!!serverAddress) {
                await gmDeploymentService.configureRelayServer(serverAddress.address, true);
            } else {
                await gmDeploymentService.configureRelayServer(inputValue, true);
            }
            await gmDeploymentService.cdcRelayServerStatus.fetchData();
        }
    };

    return (
        <>
            <OperatorView>
                <Box pt={2}>
                    <Typography variant={"h5"}>1. Server Address</Typography>
                    <br />
                    <Autocomplete<ServerAddressOptionType, false, false, true>
                        renderInput={(params) => (
                            <TextField
                                helperText={!!error ? error : "This address will appear in commands used by other hosts during installation"}
                                {...params}
                                error={!!error}
                                label={"Server Address"}
                                variant={"filled"}
                            />
                        )}
                        options={networkInterfacesList as ServerAddressOptionType[]}
                        freeSolo
                        getOptionLabel={(option) => (option as ServerAddressOptionType).address}
                        value={serverAddress}
                        inputValue={inputValue}
                        onChange={(e, v, reason) => {
                            setServerAddress(v as ServerAddressOptionType);
                            if (reason === "clear") {
                                setError("Enter a valid IP address");
                            } else {
                                setError("");
                            }
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            setInputValue(newInputValue);
                            if (inputValue.includes(":")) {
                                setError("Server address is not a valid IP address");
                            } else if (reason === "clear" || !newInputValue) {
                                setError("Enter a valid IP address");
                            } else {
                                setError("");
                            }
                        }}
                        renderOption={(props, option) => {
                            return (
                                <Box component={"li"} p={2} {...props}>
                                    <Box>
                                        <Typography variant={"subtitle2"}>{option.network}</Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {option.address}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        }}
                    />{" "}
                </Box>
                <Box pt={4}>
                    <Button variant={"contained"} onClick={onActivate} disabled={!!error}>
                        Activate CDC-Relay
                    </Button>
                </Box>
            </OperatorView>
            <MonitorView>
                <Box pt={4}>
                    <Button variant={"contained"} disabled>
                        Not Activated
                    </Button>
                </Box>
            </MonitorView>
        </>
    );
});
