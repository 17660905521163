import { useGlobalProgressState } from "../progress/GlobalProgressState";
import { useGlobalDialogState } from "../dialog/GlobalDialogState";

export const useTrackAndAlertError = <TRes, TArgs>(fn: (args: TArgs) => Promise<TRes>, message?: string) => {
    const track = useGlobalProgressState((s) => s.track);
    const addErrorDialog = useGlobalDialogState((s) => s.addErrorAlertDialog);

    return async (args: TArgs) => {
        try {
            return await track(fn(args), message);
        } catch (e) {
            await addErrorDialog(e as Error, true);
        }
    };
};
