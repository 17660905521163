// ======================
// AzureStoragePlannerSelectHostStep
// ======================

import { useAzureStoragePlannerState } from "./AzureStoragePlannerState";
import { AzureStoragePlannerStepProps, getHostHistoryInsufficient } from "./AzureStoragePlannerHelpers";
import { Alert, AlertTitle, Box, Button, Checkbox, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { StepperNavButtons } from "../../../common/stepper/StepperComponents";
import { useListGalaxyMigrateDeployments } from "../../deployment/deployment_hooks";
import { useCurrentProjectID } from "../../project/CurrentProjectState";
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table";
import { GalaxyMigrateDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { getHostEnvDisplayName } from "../../galaxymigrate/GalaxyMigrateCommon";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { QueryTable } from "../../../common/table/QueryTable";
import { useStepperContext } from "../../../common/stepper/StepperState";

export const AzureStoragePlannerSelectHostStep: React.FC<AzureStoragePlannerStepProps> = (p) => {
    const azurePlannerState = useAzureStoragePlannerState();

    return (
        <>
            <Typography variant={"h5"}>{`Select Source Host`}</Typography>
            <Typography>{`Please select host(s) to be included in this consumption planner:`}</Typography>
            <HostInsufficientHistoryWarning />
            <Box pt={2} pb={2}>
                <HostSelectionTable />
            </Box>
            <StepperNavButtons
                nextButtonProps={{
                    disabled: azurePlannerState.systemIds.length === 0 || azurePlannerState.hostsHaveInsufficientHistory,
                    label: `Create Plan`,
                    onClick: () => {
                        azurePlannerState.createRequest();
                    },
                }}
            />
        </>
    );
};

// ======================
// HostSelectionTable
// ======================

interface HostSelectionTableProps {}

const HostSelectionTable: React.FC<HostSelectionTableProps> = (p) => {
    const azurePlannerState = useAzureStoragePlannerState();

    const projectId = useCurrentProjectID();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const queryResult = useListGalaxyMigrateDeployments(projectId, true, pageIndex, pageSize, true);

    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();

    const cols: ColumnDef<GalaxyMigrateDeploymentInfo.AsObject, any>[] = [
        columnHelper.display({
            id: "select",
            header: ({ table }) => {
                const areAllRowsAlreadySelected = queryResult.data?.itemsList
                    .map((i) => i.deployment.systemId)
                    .every((item) => azurePlannerState.systemIds.includes(item));
                return (
                    <Checkbox
                        color={"secondary"}
                        checked={areAllRowsAlreadySelected || table.getIsAllRowsSelected()}
                        onChange={(e) => {
                            table.getToggleAllRowsSelectedHandler()(e);
                            if (e.target.checked) {
                                azurePlannerState.addSystemIds(queryResult.data?.itemsList.map((i) => i.deployment.systemId) || []);
                            } else {
                                azurePlannerState.removeAllSystemIds();
                            }
                        }}
                    />
                );
            },
            cell: (props) => {
                console.log(azurePlannerState.systemIds.includes(props.row.original.deployment.systemId));
                //props.row.toggleSelected(azurePlannerState.systemIds.includes(props.row.original.deployment.systemId));
                return (
                    <Checkbox
                        color={"secondary"}
                        checked={azurePlannerState.systemIds.includes(props.row.original.deployment.systemId) || props.row.getIsSelected()}
                        onChange={(e) => {
                            props.row.toggleSelected(e.target.checked);
                            if (e.target.checked) {
                                azurePlannerState.addSystemIds([props.row.original.deployment.systemId]);
                            } else {
                                azurePlannerState.removeSystemIds([props.row.original.deployment.systemId]);
                            }
                        }}
                    />
                );
            },
        }),
        columnHelper.accessor((r) => r.deployment.systemName, {
            id: "hostName",
            header: "Host Name",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.deployment.hostEnvironment.value, {
            id: "hostEnv",
            header: "Host Environment",
            cell: (props) => getHostEnvDisplayName(props.getValue()),
        }),
        columnHelper.accessor((r) => r, {
            id: "os",
            header: "OS",
            cell: (props) => `${props.row.original.osClass} ${props.row.original.kernel}`,
        }),
        columnHelper.accessor((r) => r.deployment.registeredAt, {
            id: "registeredAt",
            header: "Registered",
            cell: (props) => formatKnownDataType(convertTimestampObjectToDate(props.getValue()), KnownDataType.DATE),
        }),
    ];

    return (
        <QueryTable
            data={queryResult.data?.itemsList}
            columns={cols}
            pagination={{ pageIndex, pageSize }}
            pageCount={queryResult.data?.pagerMeta.totalPages}
            setPagination={setPagination}
            error={queryResult.error}
            isError={queryResult.isError}
            isLoading={queryResult.isLoading}
        />
    );
};

// ======================
// HostInsufficientHistoryWarning
// ======================

interface HostInsufficientHistoryWarningProps {}

const HostInsufficientHistoryWarning: React.FC<HostInsufficientHistoryWarningProps> = (p) => {
    const analyzePeriodMinutes = useAzureStoragePlannerState((s) => s.descriptionFields.analyzePeriodMinutes);
    const selectedHosts = useAzureStoragePlannerState((s) => s.systemIds);
    const setHostsHaveInsufficientHistory = useAzureStoragePlannerState((s) => s.setHostsHaveInsufficientHistory);
    const goBack = useStepperContext((s) => s.goBackOneStep);
    const projectId = useCurrentProjectID();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 30,
    });
    const queryResult = useListGalaxyMigrateDeployments(projectId, true, pageIndex, pageSize, true);

    const columnHelper = createColumnHelper<GalaxyMigrateDeploymentInfo.AsObject>();

    const cols: ColumnDef<GalaxyMigrateDeploymentInfo.AsObject, any>[] = [
        columnHelper.accessor((r) => r.deployment.systemName, {
            id: "hostName",
            header: "Host Name",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.deployment.hostEnvironment.value, {
            id: "hostEnv",
            header: "Host Environment",
            cell: (props) => getHostEnvDisplayName(props.getValue()),
        }),
        columnHelper.accessor((r) => r, {
            id: "os",
            header: "OS",
            cell: (props) => `${props.row.original.osClass} ${props.row.original.kernel}`,
        }),
        columnHelper.accessor((r) => r.deployment.registeredAt, {
            id: "registeredAt",
            header: "Registered",
            cell: (props) => formatKnownDataType(convertTimestampObjectToDate(props.getValue()), KnownDataType.DATE),
        }),
    ];

    const data = useMemo(() => {
        return queryResult.data?.itemsList.filter((i) => {
            if (selectedHosts.includes(i.deployment.systemId)) {
                return getHostHistoryInsufficient(i.deployment.registeredAt, analyzePeriodMinutes);
            }
            return false;
        });
    }, [queryResult.data, selectedHosts, analyzePeriodMinutes]);

    useEffect(() => {
        if (data && data?.length > 0) {
            setHostsHaveInsufficientHistory(true);
        } else {
            setHostsHaveInsufficientHistory(false);
        }
    }, [data, data?.length, setHostsHaveInsufficientHistory]);

    if (!data || data?.length === 0) {
        return null;
    }

    return (
        <Box pt={2} pb={2}>
            <Alert
                severity={"warning"}
                variant={"outlined"}
                sx={{
                    "& .MuiAlert-message": {
                        width: `100%`,
                    },
                }}
            >
                <AlertTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>
                            <Typography variant={"h6"}>Insufficient History For Analysis</Typography>

                            <Typography variant={"caption"}>
                                {`The following hosts do not have enough statistical data for analysis. Please wait for more data to be collected or choose a different analysis period.`}
                            </Typography>
                        </Box>
                        <Button
                            variant={"outlined"}
                            color={"secondary"}
                            onClick={() => {
                                goBack();
                            }}
                        >
                            {`Go Back To Adjust`}
                        </Button>
                    </Box>
                </AlertTitle>

                <Box width={"100%"}>
                    <QueryTable
                        tableComponent={Box}
                        data={data}
                        columns={cols}
                        pagination={{ pageIndex, pageSize }}
                        pageCount={queryResult.data?.pagerMeta.totalPages}
                        setPagination={setPagination}
                        error={queryResult.error}
                        isError={queryResult.isError}
                        isLoading={queryResult.isLoading}
                    />
                </Box>
            </Alert>
        </Box>
    );
};
