// ======================
// DarkCard
// ======================

import { Card, CardProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

interface DarkCardProps {
    children: React.ReactNode;
    cardProps?: Partial<CardProps>;
    sx?: SxProps<Theme>;
}

export const DarkFlatCard: React.FC<DarkCardProps> = (p) => {
    const { children, cardProps, sx } = p;
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                ...sx,
            }}
            {...cardProps}
        >
            {children}
        </Card>
    );
};

export const DarkFlatOutlinedCard: React.FC<DarkCardProps> = (p) => {
    const { children, cardProps, sx } = p;
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: `#3B4252`,
                ...sx,
            }}
            {...cardProps}
        >
            {children}
        </Card>
    );
};

// ======================
// CodeCard
// ======================
export const CodeCard: React.FC<DarkCardProps> = (p) => {
    const { children, cardProps, sx } = p;
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: "#000000",
                ...sx,
            }}
            {...cardProps}
        >
            {children}
        </Card>
    );
};
