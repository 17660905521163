import xbytes from "xbytes";
import { formatDistance, formatDistanceStrict, formatDuration, formatISO9075, formatRelative, isAfter } from "date-fns";
import { Duration as ProtoDuration } from "google-protobuf/google/protobuf/duration_pb";
import { Duration } from "date-fns";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export const formatBool = (b: boolean) => (!!b ? "Yes" : "No");

function numberWithDecimal(num = 0, decimalPlace = 0) {
    if (typeof decimalPlace === "undefined" || decimalPlace === null) {
        return num;
    } else {
        let _num = num.toFixed(decimalPlace);
        return parseFloat(_num);
    }
}

function numberWithDecimalS(num = 0, decimalPlace = 0) {
    const n = numberWithDecimal(num, decimalPlace);
    return n ? n.toString() : n;
}

export function formatMicroseconds(us: number) {
    let microSecond = us;
    const microSecondUnit = "μs";
    if (typeof microSecond === "undefined") {
        return 0;
    }
    if (microSecond < 1000) {
        if (microSecond !== 0) {
            return numberWithDecimalS(microSecond, null) + " μs";
        } else {
            return "0 μs";
        }
    }
    let millisecond = microSecond / 1000.0;
    if (millisecond < 1000) {
        return numberWithDecimalS(millisecond, 3) + " ms";
    }
    let second = millisecond / 1000.0;
    return numberWithDecimalS(second, 3) + " s";
}

export enum KnownDataType {
    GENERIC,
    NUMBER,
    BOOL,
    CAPACITY,
    THROUGHPUT,
    DATE,
    DATE_RELATIVE,
    PERCENT,
    DURATION,
    SIMPLE_STRING,
    ENUM,
    DURATION_MILLISECONDS,
    DURATION_MICROSECONDS,
    CURRENCY_USD,
}

export const convertTimestampObjectToDate = (timestamp: Timestamp.AsObject) => {
    const t = new Timestamp().setSeconds(timestamp.seconds).setNanos(timestamp.nanos);
    return t.toDate();
};

export const convertDateObjectToTimestamp = (date: Date): Timestamp => {
    return new Timestamp().setSeconds(Math.floor(date.getTime() / 1000));
};

export const formatKnownDataType = (v: any, type: KnownDataType) => {
    if (v === undefined) {
        return "N/A";
    }
    if (type === KnownDataType.BOOL) {
        return formatBool(v);
    }
    if (type === KnownDataType.CAPACITY) {
        return xbytes(v, { iec: true });
    }
    if (type === KnownDataType.THROUGHPUT) {
        return `${xbytes(v, { iec: true })}/s`;
    }
    if (type === KnownDataType.DATE) {
        let date = v;
        if (!!v.seconds) {
            date = convertTimestampObjectToDate(v);
        }
        return formatISO9075(date);
    }
    if (type === KnownDataType.DATE_RELATIVE) {
        let date = v;
        if (!!v.seconds) {
            date = convertTimestampObjectToDate(v);
        }
        return formatDistanceStrict(date, new Date(), {
            addSuffix: true,
        });
    }
    if (type === KnownDataType.PERCENT) {
        if (v.toString().indexOf(".") === -1) {
            return `${v}%`;
        }

        return `${parseFloat(`${v || 0}`).toFixed(2)}%`;
    }
    if (type === KnownDataType.DURATION_MILLISECONDS) {
        const seconds = v.seconds ?? v.getSeconds();
        const nanos = v.nanos ?? v.getNanos();
        return `${(seconds * 1000 + nanos / 1000 / 1000).toFixed(2)}ms`;
    }
    if (type === KnownDataType.CURRENCY_USD) {
        const USDollar = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
        return USDollar.format(v);
    }
    return v;
};

export const formatProtoDuration = (v: ProtoDuration): string => {
    return formatDuration(convertProtoDurationToDuration(v));
};

export const formatProtoDurationObject = (v: ProtoDuration.AsObject) => {
    if (v === undefined) {
        return "N/A";
    }
    const durationSecs = v.seconds;
    const durationNanos = v.nanos;
    const fullDurationInSeconds = durationSecs + durationNanos / 1000 / 1000 / 1000;
    if (fullDurationInSeconds < 1) {
        return `${fullDurationInSeconds.toFixed(2)}s`;
    }
    const dateObj = new Date(fullDurationInSeconds * 1000);
    let hours: number | string = dateObj.getUTCHours();
    let minutes: number | string = dateObj.getUTCMinutes();
    let seconds: number | string = dateObj.getSeconds();

    if (hours < 10) {
        hours = `0${hours}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
};
export const formatDurationFromSeconds = (
    v: number,
    options?: {
        format?: string[];
        zero?: boolean;
        delimiter?: string;
        locale?: Locale;
    }
): string => {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    const week = day * 7;
    const month = day * 30;
    const year = day * 365;

    const years = Math.floor(v / year);
    const months = Math.floor((v % year) / month);
    const weeks = Math.floor(((v % year) % month) / week);
    const days = Math.floor(v / day);
    const hours = Math.floor((v % day) / hr);
    const minutes = Math.floor(((v % day) % hr) / min);
    const seconds = ((v % day) % hr) % min;
    const duration = {
        years,
        months,
        weeks,
        days,
        hours,
        minutes,
        seconds,
    };

    if (!!years) {
        return formatDuration(duration, { format: ["years"] });
    }

    if (!!months) {
        return formatDuration(duration, { format: ["months"] });
    }

    if (!!weeks) {
        return formatDuration(duration, { format: ["weeks"] });
    }

    if (!!days) {
        return formatDuration(duration, { format: ["days"] });
    }
    return formatDuration(duration, { format: ["hours", "minutes", "seconds"] });
};

const convertProtoDurationToDuration = (v: ProtoDuration): Duration => {
    const duration = {
        seconds: v.getSeconds(),
    };

    return duration;
};

export const formatTitleCase = (string: string): string => {
    const array = string.split(" ");
    const newArray = array.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return newArray.join(" ");
};

export const convertNumToProtoDuration = (value: number, unit: "s" | "ms") => {
    if (unit === "s") {
        const seconds = Math.floor(value);
        const nanos = Math.floor((value - seconds) * 1000 * 1000 * 1000);
        return new ProtoDuration().setSeconds(seconds).setNanos(nanos);
    } else {
        const seconds = Math.floor(value / 1000);
        const nanos = Math.floor((value - seconds * 1000) * 1000 * 1000);
        return new ProtoDuration().setSeconds(seconds).setNanos(nanos);
    }
};

export const formatSnakeCaseToPascalCase = (s: string, withSpace: boolean = false) => {
    // convert snake_case to  PascalCase or spaced title case
    // if each chunk is less than 3 characters, it would be all upper case

    if (s.length === 0) {
        return "";
    }

    const chunks = s.split("_");
    const result = chunks.map((chunk) => {
        if (chunk.length <= 2) {
            return chunk.toUpperCase();
        }
        return formatTitleCase(chunk);
    });
    if (withSpace) {
        return result.join(" ").trim();
    }
    return result.join("").trim();
};

export const formatPlural = (label: string, count: number) => {
    return count === 1 ? label : `${label}s`;
};
