// ======================
// CmoMigrationSessionsListScreen
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Theme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { MdOutlineList } from "react-icons/md";
import { BiGridHorizontal } from "react-icons/bi";
import { BlackTooltip } from "../../common/tooltip/ColorTooltip";
import { CmoMigrationSessionsListTable } from "./sessions/CmoMigrationSessionsListTable";
import { CmoMigrationSessionsListGrid } from "./sessions/CmoMigrationSessionsListGrid";
import { CmoMigrationSessionsToggleButton, CmoMigrationSessionsToggleView } from "./sessions/CmoMigrationSessionsToggleView";

interface CmoMigrationSessionsListScreenProps {
    projectId: string;
}

export const CmoMigrationSessionsListScreen: React.FC<CmoMigrationSessionsListScreenProps> = (p) => {
    const { projectId } = p;

    const [view, setView] = useState<"list" | "grid">("grid");

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"CMO Migration Sessions"} actions={<CmoMigrationSessionsToggleButton view={view} setView={setView} />} />
            <CmoMigrationSessionsToggleView view={view} projectId={projectId} />
        </ScreenContainer>
    );
};
