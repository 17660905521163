import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography,
} from "@mui/material";
import { useNavigateToProjectListScreen } from "../project/ProjectCommon";
import { AdminView } from "../auth/AuthenticatedViews";
import { EditProject } from "./EditProject";
import { ConfirmDialogConfig, useDialogState } from "../core/dialog/DialogService";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { useIsDesktop } from "../layout/MainLayout";
import { useCurrentProject, useCurrentProjectID } from "../project/CurrentProjectState";
import { HideInPrivateEdition } from "../auth/PrivateEditionView";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { useLeaveProject, useSetProjectEmailNotifications, useSetProjectSupportPermissions } from "./project_settings_hooks";
import { useIsFeatureEnabled } from "../core/featureflag/FeatureFlags";
import { FeatureFlag } from "../app/AppGlobalService";

// ======================
// AdvancedSettings
// ======================

interface AdvancedSettingsProps {}

export const AdvancedSettings: React.FC<AdvancedSettingsProps> = observer((props) => {
    return (
        <Box pb={6}>
            <Card>
                <CardHeader title={"Advanced"} subheader={"Adjust advanced settings"} />
                <AdminView>
                    <EditProject />
                    <EmailNotificationPreferences />
                    <CdsSupportPermission />
                </AdminView>
                <LeaveProject />
            </Card>
        </Box>
    );
});

// ======================
// LeaveProject
// ======================

interface LeaveProjectProps {}

export const LeaveProject: React.FC<LeaveProjectProps> = observer((p) => {
    const leaveProject = useLeaveProject();
    const projectId = useCurrentProjectID();
    const globalDialogState = useGlobalDialogState();
    const goBackToProjectListScreen = useNavigateToProjectListScreen();

    const handleLeaveProject = async () => {
        const config: ConfirmDialogConfig = {
            title: "Leave Project Confirmation",
            message: "By continuing, you will no longer have access to the project.",
            autoConfirmationQuestionLine: true,
        };
        const confirmed = await globalDialogState.addConfirmDialog(config);
        if (confirmed) {
            await leaveProject.mutateAsync(projectId);
            goBackToProjectListScreen();
        }
    };

    return (
        <HideInPrivateEdition>
            <Box p={2}>
                <Card variant={"outlined"}>
                    <CardHeader
                        title={"Leave Project"}
                        subheader={"You will no longer have access to this project"}
                        action={
                            <Box p={2}>
                                <Button color={"error"} variant={"outlined"} onClick={handleLeaveProject}>
                                    {"Leave Project"}
                                </Button>
                            </Box>
                        }
                    />
                </Card>
            </Box>
        </HideInPrivateEdition>
    );
});

// ======================
// EmailNotificationPreferences
// ======================

interface EmailNotificationPreferencesProps {}

export const EmailNotificationPreferences: React.FC<EmailNotificationPreferencesProps> = observer((p) => {
    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader
                    title={"Email Notification Preferences"}
                    subheader={"Here you can edit your email notification settings for this project"}
                    action={
                        <Box p={2}>
                            <EmailNotificationPreferencesButton />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
});

// ======================
// EmailNotificationPreferencesButton
// ======================

interface EmailNotificationPreferencesButtonProps {}

export const EmailNotificationPreferencesButton: React.FC<EmailNotificationPreferencesButtonProps> = observer((p) => {
    const dialogState = useDialogState();
    const setProjectEmailNotifications = useSetProjectEmailNotifications();
    const currentProject = useCurrentProject();

    const [state, setState] = useState({
        disableLicenseTransactionEmail: currentProject?.getInfo().getNotificationSettings()?.getDisabledLicenseTransaction() ?? false,
        disableNewHostEmail: currentProject?.getInfo().getNotificationSettings()?.getDisabledNewHostAdded() ?? false,
        disableMigrateOpsEmail: currentProject?.getInfo().getNotificationSettings()?.getDisabledMigrateOps() ?? false,
    });
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({
            ...state,
            [event.target.name]: !checked,
        });
    };

    const savePreferences = async () => {
        await setProjectEmailNotifications.mutateAsync({
            disableLicenseTransactionEmail: state.disableLicenseTransactionEmail,
            disableNewHostEmail: state.disableNewHostEmail,
            disableMigrateOpsEmail: state.disableMigrateOpsEmail,
        });
        dialogState.close();
    };

    const dialogFullScreen = !useIsDesktop();

    return (
        <>
            <Button variant={"outlined"} onClick={dialogState.open}>
                Edit
            </Button>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={dialogFullScreen} fullWidth maxWidth={"md"}>
                    <DialogTopBar dialogState={dialogState} title={"Email Notification Preferences"} divider />
                    <DialogContent>
                        <Typography>Email Notification Type</Typography>
                        <Typography variant={"caption"} color={"textSecondary"}>
                            Choose whether or not to receive these email notifications from this project
                        </Typography>
                        <br />
                        <FormControl variant={"standard"}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!state.disableLicenseTransactionEmail}
                                            onChange={handleChange}
                                            name={"disableLicenseTransactionEmail"}
                                        />
                                    }
                                    label={"License Transaction Notification"}
                                />
                                <FormControlLabel
                                    control={<Switch checked={!state.disableNewHostEmail} onChange={handleChange} name={"disableNewHostEmail"} />}
                                    label={"New Host Added Notification"}
                                />
                                <FormControlLabel
                                    control={<Switch checked={!state.disableMigrateOpsEmail} onChange={handleChange} name={"disableMigrateOpsEmail"} />}
                                    label={"MigrateOps Notification"}
                                />
                            </FormGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Box display={"flex"} justifyContent={"flex-end"} pr={1} pb={1}>
                            <Button variant={"contained"} onClick={savePreferences}>
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
});

// ======================
// CdsSupportPermission
// ======================

interface CdsSupportPermissionProps {}

export const CdsSupportPermission: React.FC<CdsSupportPermissionProps> = observer((p) => {
    const isSupportEnabled = useCurrentProject()?.getInfo().getAllowSupportStaff();
    const globalDialogState = useGlobalDialogState();
    const setSupportPermissions = useSetProjectSupportPermissions();
    const subheader = isSupportEnabled
        ? "Authorized Support Representatives can join this project for support purposes. \n" +
          "Once they join the project, they will show up in the project member list."
        : "Remote Assistance is disabled. Our support team does not have access to this project. ";

    const getAction = useCallback(() => {
        if (isSupportEnabled) {
            const onClick = async () => {
                const confirmed = await globalDialogState.addConfirmDialog({
                    title: "Disable Remote Assistance Permission",
                    message: (
                        <>
                            <Box pb={2}>By continuing, Support Representatives will no longer be able to join the project.</Box>
                            <Box pb={2}>NOTE: All existing Support Representatives will be removed from the project.</Box>
                        </>
                    ),
                    autoConfirmationQuestionLine: true,
                });
                if (confirmed) {
                    await setSupportPermissions.mutateAsync({ allowSupport: false });
                }
            };
            return (
                <Button onClick={onClick} variant={"outlined"} color={"error"}>
                    Disable
                </Button>
            );
        } else {
            const onClick = async () => {
                await setSupportPermissions.mutateAsync({ allowSupport: true });
            };
            return (
                <Button onClick={onClick} variant={"outlined"}>
                    Enable
                </Button>
            );
        }
    }, [isSupportEnabled, globalDialogState, setSupportPermissions]);

    return (
        <Box p={2}>
            <Card variant={"outlined"}>
                <CardHeader title={"Remote Assistance Permission"} subheader={subheader} action={<Box p={2}>{getAction()}</Box>} />
            </Card>
        </Box>
    );
});
