import { UseInfiniteQueryResult, UseQueryResult } from "@tanstack/react-query";
import { LoadingBox } from "../../../common/progress/LoadingIndicators";
import * as React from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { RefreshButton } from "../../../common/CommonIcons";
import { observer } from "mobx-react-lite";

interface DataRendererProps<TData, TError, TComponentProps> {
    queryResult: UseQueryResult<TData, TError> | UseInfiniteQueryResult<TData, TError>;
    children: React.ReactNode;
    loadingElement?: React.ReactNode;
}

// ======================
//
// ======================

interface Props {}

export const QueryResultWrapper = observer(<TData, TError, TComponentProps>(p: DataRendererProps<TData, TError, TComponentProps>) => {
    const { isLoading, isError, error, isFetched, refetch, data } = p.queryResult;

    const refreshButton = (
        <Box pr={2}>
            <RefreshButton onClick={() => refetch()} variant={"outlined"} color={"inherit"} />
        </Box>
    );

    if (isLoading) {
        return !!p.loadingElement ? <>{p.loadingElement}</> : <LoadingBox />;
    }

    if (isError) {
        return (
            <Box width={"100%"} p={2}>
                <Alert severity={"error"} action={refreshButton}>
                    <AlertTitle>Error Encountered</AlertTitle>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box>{(error as unknown as Error).message}</Box>
                    </Box>
                </Alert>
            </Box>
        );
    }

    if (isFetched) {
        return <>{p.children}</>;
    }

    return null;
});
