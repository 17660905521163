// ======================
// CreateSessionReportDialog
// ======================

import * as React from "react";
import { observer } from "mobx-react-lite";
import { DialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigateToReports, viewReport } from "../../reports/ReportHelpers";
import { useMutation } from "@tanstack/react-query";
import { useAppServices } from "../../app/services";
import { CreateMigrationSessionReport } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { APP_ROUTES } from "../../app/AppRoutes";
import { useCurrentProjectID } from "../../project/CurrentProjectState";

interface CreateSessionReportDialogProps {
    sessionId: string;
    dialogState: DialogState;
    createReportFn: (args: { sessionId: string; reportNotes: string }) => void | Promise<any>;
}

export const GmMigrationSessionCreateReportDialog: React.FC<CreateSessionReportDialogProps> = observer((p) => {
    const { sessionId, dialogState, createReportFn } = p;
    const fullScreen = useShouldDialogFullScreen();
    const [reportNotes, setReportNotes] = useState<string>("");
    const [error, setError] = useState<string>("");
    const navigateToReportsScreen = useNavigateToReports();

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} fullWidth maxWidth={"md"}>
            <DialogTitle>{`Generate Report`}</DialogTitle>
            <DialogContent>
                <Typography>{`A new migration report on this session will be generated. Report notes can be added to the project for references.`}</Typography>
                <br />
                <TextField
                    variant={"filled"}
                    label={"Report Notes"}
                    multiline
                    minRows={5}
                    error={reportNotes.length > 5000}
                    helperText={error}
                    value={reportNotes}
                    onChange={(e) => {
                        setReportNotes(e.target.value);
                        if (e.target.value.length > 5000) {
                            setError("Max 5000 characters");
                        } else {
                            setError("");
                        }
                    }}
                    fullWidth
                />
                <Box pt={1}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {`Generated Reports will be available from the project`}
                        &nbsp;
                        <Link onClick={navigateToReportsScreen}>{`Reports Page`}</Link>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
                <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                    {`Cancel`}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={async () => {
                        const res = await createReportFn({ sessionId, reportNotes });
                        if (res) {
                            dialogState.close();
                        }
                    }}
                >
                    {`Generate Report`}
                </Button>
            </DialogActions>
        </Dialog>
    );
});
