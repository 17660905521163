import { makeAutoObservable } from "mobx";
import md5 from "blueimp-md5";
import { DialogService } from "../dialog/DialogService";
import { AppGlobalService } from "../../app/AppGlobalService";
import { NotificationService } from "../../../common/notifications/NotificationService";

export class AppUpdateCheckerService {
    dialogService: DialogService;
    appGlobalService: AppGlobalService;
    notificationService: NotificationService;
    indexPageHash: string = null;

    constructor(appGlobalService: AppGlobalService, dialogService: DialogService, notificationService: NotificationService) {
        this.dialogService = dialogService;
        this.appGlobalService = appGlobalService;
        this.notificationService = notificationService;
        makeAutoObservable(this);
    }

    async fetchHash() {
        return await fetch("/");
    }

    async refreshCurrentHash() {
        try {
            const result = await this.fetchHash();
            const data = await result.text();
            if (data) {
                this.indexPageHash = md5(data);
                console.debug(`Current Hash: ${md5(data)}`);
            }
        } catch (e) {
            console.debug(`Failed to update hash ${e}`);
            this.indexPageHash = null;
        }
    }

    async reloadIfHashUpdated(warnFirst = true) {
        const currentHash = this.indexPageHash;
        await this.refreshCurrentHash();

        const message = "Outdated Application Detected. Please refresh your browser.";
        const refresh = () => window.location.reload();

        if (currentHash && this.indexPageHash && currentHash !== this.indexPageHash) {
            console.debug(`Page reload needed. Hash updated from ${currentHash} to ${this.indexPageHash}`);
            if (warnFirst) {
                await this.notificationService.addNotification(message, "info", refresh, "Refresh");
            } else {
                refresh();
            }
        } else {
            console.debug("checking for system updates");
        }
    }
}
