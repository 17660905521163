// Project: GalaxyComplete
// Created: 9/29/20 by sammy
// File: ProjectMembersList

import * as React from "react";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { ProjectMemberInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { Box, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, SvgIcon, Theme, Typography } from "@mui/material";
import { getUserFullNameFromObject, renderProjectUserRole, UserAvatar } from "./ProjectUsers";
import { useAppServices } from "../app/services";
import { ConfirmDialogConfig, DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { FormRadioGroup, FormTextField } from "../../common/form/FormComponents";
import { AiOutlineSend } from "react-icons/ai";
import { DeleteIcon } from "../../common/CommonIcons";
import { UserSettingsIcon } from "../project/ProjectCommon";
import { AdminView, useIsAdminView } from "../auth/AuthenticatedViews";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import { getIsPrivateEdition } from "../auth/PrivateEditionView";
import { HintButton, KnownArticle } from "../help/HelpCommon";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { ActionMenuButton } from "../../common/actions/CommonActions";
import { QueryTable } from "../../common/table/QueryTable";
import { useChangeProjectMemberRole, useInviteNewProjectMembers, useListProjectMembers, useRemoveProjectMember } from "./project_settings_hooks";

// ======================
// ProjectMembersArea
// ======================
interface ProjectMembersAreaProps {}

export const ProjectMembersArea: React.FC<ProjectMembersAreaProps> = observer((p) => {
    const { dialogService } = useAppServices();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const currentProjectId = useCurrentProjectID();
    const queryResult = useListProjectMembers(currentProjectId, pageIndex, pageSize);
    const removeProjectMember = useRemoveProjectMember();
    const changeUserRoleDialogState = useDialogState();
    const isAdmin = useIsAdminView();
    const isPrivateEdition = getIsPrivateEdition();

    const renderUserStyle = (supportUser?: boolean) => {
        if (supportUser) {
            return { color: (t: Theme) => t.palette.success.light };
        }
        return {};
    };

    const columnHelper = createColumnHelper<ProjectMemberInfo.AsObject>();

    const columns = [
        columnHelper.accessor((r) => r.user, {
            header: "",
            id: "avatar",
            cell: (props) => <UserAvatar user={props.getValue()} />,
        }),
        columnHelper.accessor((r) => r.user.email, {
            header: "Email",
            id: "email",
            cell: (props) => <Typography sx={renderUserStyle(props.row.original.asSupportStaff)}>{props.getValue()}</Typography>,
        }),
        columnHelper.accessor((r) => r.user, {
            header: "Name",
            id: "name",
            cell: (props) => (
                <Typography sx={renderUserStyle(props.row.original.asSupportStaff)}>{`${props.getValue().firstName} ${props.getValue().lastName}`}</Typography>
            ),
        }),
        columnHelper.accessor((r) => r.role, {
            header: "Role",
            id: "role",
            cell: (props) => {
                if (props.row.original.asSupportStaff) {
                    return <Typography sx={{ color: (t: Theme) => t.palette.success.light }}>Support</Typography>;
                } else {
                    return renderProjectUserRole(props.getValue());
                }
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Actions",
            id: "actions",
            cell: (props) => {
                return <ActionMenuButton actions={getRowActions(props.row.original)} />;
            },
        }),
    ];

    const getRowActions = (r: ProjectMemberInfo.AsObject) => [
        {
            id: "remove",
            name: isPrivateEdition ? "Remove User Account" : `Remove ${getUserFullNameFromObject(r.user)} From Project`,
            action: async () => {
                const userDisplayName = r.user.firstName ? getUserFullNameFromObject(r.user) : "this user";
                const confirmConfig: ConfirmDialogConfig = isPrivateEdition
                    ? {
                          title: "Permanently Delete User Account Confirmation",
                          message: `By continuing, ${userDisplayName} will lose access, and member user account will be permanently deleted.`,
                          autoConfirmationQuestionLine: false,
                          typeToConfirm: "DELETE",
                      }
                    : {
                          title: "Remove User Confirmation",
                          message: `Are you sure you want to remove ${userDisplayName} from this project?`,
                          autoConfirmationQuestionLine: false,
                      };
                const confirmed = await dialogService.addConfirmDialog(confirmConfig);
                if (confirmed) {
                    await removeProjectMember.mutateAsync({
                        projectId: currentProjectId,
                        userId: r.user.userId,
                    });
                }
            },
            icon: <DeleteIcon />,
            hidden: !isAdmin,
        },
        {
            id: "changeUserRole",
            name: `Change User Role`,
            action: async () => {
                changeUserRoleDialogState.setDialogProps({
                    userRole: r.role,
                    userID: r.user.userId,
                    projectID: currentProjectId,
                    userName: r.user.firstName,
                });
                changeUserRoleDialogState.open();
            },
            icon: <UserSettingsIcon />,
            hidden: !isAdmin || r.asSupportStaff,
        },
    ];

    return (
        <>
            <Card>
                <CardHeader title={`Project Members`} subheader={`Access is granted to the following users`} action={<InviteNewMemberButton />} />
                <QueryTable
                    data={queryResult.data?.itemsList}
                    columns={columns}
                    pagination={{ pageIndex, pageSize }}
                    setPagination={setPagination}
                    pageCount={queryResult.data?.pagerMeta?.totalPages}
                    error={queryResult.error}
                    isError={queryResult.isError}
                    isLoading={queryResult.isLoading}
                />
            </Card>

            {changeUserRoleDialogState.isOpen && <ChangeUserRoleDialog dialogState={changeUserRoleDialogState} />}
        </>
    );
});

// ======================
// InviteNewMemberButton
// ======================
interface InviteNewMemberButtonProps {}

const InviteNewMemberButton: React.FC<InviteNewMemberButtonProps> = observer((p) => {
    const dialogState = useDialogState();

    return (
        <AdminView>
            <Box p={2}>
                <Button variant={"outlined"} size={"large"} onClick={dialogState.open}>{`Invite New Members`}</Button>
                <InviteNewMembersDialog dialogState={dialogState} />
            </Box>
        </AdminView>
    );
});

interface InviteNewMembersDialogProps {
    dialogState: DialogState;
}

const InviteNewMembersDialog: React.FC<InviteNewMembersDialogProps> = observer((p) => {
    const initialValues = {
        emails: [] as string[],
        role: ProjectMemberInfo.Role.OPERATOR,
    };
    const inviteNewMembers = useInviteNewProjectMembers();
    const projectId = useCurrentProjectID();

    const schema = yup
        .object({
            emails: yup
                .array()
                .required("at least one email address is required")
                .transform(function (value, originalValue) {
                    if (this.isType(value) && value !== null) {
                        return value;
                    }
                    return originalValue ? originalValue.split(/[\s,]+/) : [];
                })
                .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
            role: yup.number(),
        })
        .strict(false);

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} maxWidth={"sm"} fullWidth fullScreen={useShouldDialogFullScreen()}>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    const data = await schema.validate(values);
                    const args = {
                        emails: data.emails.filter((e) => !!e).map((e) => e.toLowerCase()),
                        role: data.role,
                        projectId: projectId,
                    };
                    await inviteNewMembers.mutateAsync(args);
                    p.dialogState.close();
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <DialogTopBar dialogState={p.dialogState} title={`Invite New Members to Project`} />
                            <DialogContent>
                                <Typography variant={"h6"}>1. Invitee Email Addresses</Typography>
                                <DialogContentText>
                                    {
                                        "Enter one or more email addresses (comma-separated) of the invitees and we will send the account creation and access instructions"
                                    }
                                </DialogContentText>
                                <br />
                                <FormTextField name={"emails"} label={"Type or copy and paste email addresses here"} multiline rows={4} />
                                <br />
                                <br />
                                <Box display={"flex"} justifyContent={"space-between"}>
                                    <Typography variant={"h6"}>2. User Role</Typography>
                                    <HintButton articleID={KnownArticle.PROJECT_MEMBER_ROLES} />
                                </Box>

                                <DialogContentText>{"Choose a user role for the invitee(s) above."}</DialogContentText>
                                <br />
                                <FormRadioGroup
                                    label={""}
                                    name={"role"}
                                    options={[
                                        {
                                            label: "Monitor",
                                            value: ProjectMemberInfo.Role.MONITOR,
                                        },
                                        {
                                            label: "Operator",
                                            value: ProjectMemberInfo.Role.OPERATOR,
                                        },
                                        {
                                            label: "Admin",
                                            value: ProjectMemberInfo.Role.ADMIN,
                                        },
                                    ]}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Box p={2}>
                                    <Button
                                        type={"submit"}
                                        variant={"outlined"}
                                        startIcon={
                                            <SvgIcon>
                                                <AiOutlineSend />
                                            </SvgIcon>
                                        }
                                        size={"large"}
                                    >
                                        {"Send Invitations"}
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
});

// ======================
// ChangeUserRoleDialog
// ======================

interface ChangeUserRoleDialogProps {
    dialogState: DialogState;
}

export const ChangeUserRoleDialog: React.FC<ChangeUserRoleDialogProps> = observer((props) => {
    const { dialogState } = props;
    const changeUserRole = useChangeProjectMemberRole();

    const initialValues = {
        userRole: dialogState.dialogProps?.userRole.toString(),
    };

    const schema = yup.object({
        userRole: yup.string().required(),
    });

    const options = [
        { label: "Monitor", value: ProjectMemberInfo.Role.MONITOR },
        { label: "Operator", value: ProjectMemberInfo.Role.OPERATOR },
        { label: "Admin", value: ProjectMemberInfo.Role.ADMIN },
    ];

    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={"sm"} fullWidth fullScreen={useShouldDialogFullScreen()}>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async (values) => {
                    const validatedValues = await schema.validate(values);
                    const newUserRole = parseInt(validatedValues.userRole);
                    const userID = dialogState.dialogProps?.userID;
                    const projectID = dialogState.dialogProps?.projectID;
                    const args = {
                        projectId: projectID,
                        userId: userID,
                        role: newUserRole,
                    };
                    const changed = await changeUserRole.mutateAsync(args);
                    if (changed) {
                        dialogState.close();
                    }
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <DialogTopBar dialogState={dialogState} title={"Change User Role"} />
                            <DialogContent>
                                <DialogContentText>{`Choose A New User Role for ${dialogState.dialogProps?.userName}:`}</DialogContentText>
                                <FormRadioGroup name={"userRole"} label={""} options={options} />
                            </DialogContent>
                            <DialogActions>
                                <Box p={2}>
                                    <Button type={"submit"} variant={"outlined"} size={"large"}>
                                        {"Change User Role"}
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
});
