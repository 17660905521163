import { DialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import * as React from "react";
import { observer } from "mobx-react-lite";
import Dialog from "@mui/material/Dialog";
import { useAppServices } from "../../app/services";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Box, Button, DialogActions, DialogTitle, Divider } from "@mui/material";
import { CloseDialogButton } from "../../core/dialog/DialogComponents";
import { FormTextField } from "../../../common/form/FormComponents";
import { formatServerAddressWithoutDefaultPort } from "../GalaxyMigrateCommon";
import Grid from "@mui/material/Grid2";

// ======================
// EditConnectionDialog
// ======================

interface EditConnectionDialogProps {
    dialogState: DialogState;
    linkInfo: GMLinkInfo.AsObject;
}

export const EditConnectionDialog: React.FC<EditConnectionDialogProps> = observer((props) => {
    const { dialogState, linkInfo } = props;

    return (
        <Dialog open={dialogState.isOpen} fullScreen={useShouldDialogFullScreen()} maxWidth={"sm"} fullWidth onClose={dialogState.close}>
            {dialogState.isOpen && linkInfo && <EditConnectionForm dialogState={dialogState} linkInfo={linkInfo} />}
        </Dialog>
    );
});

// ======================
// EditConnectionForm
// ======================

interface EditConnectionFormProps {
    dialogState: DialogState;
    linkInfo: GMLinkInfo.AsObject;
}

const EditConnectionForm: React.FC<EditConnectionFormProps> = observer((props) => {
    const { dialogState, linkInfo } = props;
    const { deploymentService, gmDeploymentService } = useAppServices();
    const validationSchema = yup.object({
        serverAddress: yup.string().required("Specify Connection Address."),
    });
    const initialValues = {
        serverAddress: formatServerAddressWithoutDefaultPort(linkInfo.lastserveraddress),
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
                const serverPort = !!linkInfo.lastserveraddress.split(":")[1] ? parseInt(linkInfo.lastserveraddress.split(":")[1]) : 0;

                await gmDeploymentService.reconnectGalaxyMigrateLink(linkInfo.linkid, values.serverAddress, serverPort);
                await gmDeploymentService.galaxyMigrateLinks.fetchData();
                dialogState.close();
            }}
        >
            {(props) => {
                return (
                    <Form>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <DialogTitle>Edit Connection Information</DialogTitle>
                            <CloseDialogButton dialogState={dialogState} />
                        </Box>
                        <Divider />
                        <Box p={2}>
                            <Grid container>
                                <Grid size={6}>
                                    <FormTextField
                                        label={"Connection Address"}
                                        name={"serverAddress"}
                                        helperText={`${linkInfo.client.systemName} will connect to
                                                               ${linkInfo.server.systemName} via this address.`}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <DialogActions>
                            <Box p={1} display={"flex"}>
                                <Box pr={2}>
                                    <Button size={"large"} onClick={dialogState.close}>
                                        Cancel
                                    </Button>
                                </Box>
                                <Box>
                                    <Button type={"submit"} variant={"contained"} color={"primary"} size={"large"}>
                                        Confirm
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </Form>
                );
            }}
        </Formik>
    );
});
