import { useAppServices } from "../app/services";
import { useQuery } from "@tanstack/react-query";
import { CmcMigrationMutationKeys, MigrationQueryKeys } from "../../common/QueryKeys";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { GetComputeMigrationSourceReadiness, SyncMigrationSession } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { HostEnvironment } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";

export const useListAzureHelpers = (projectId: string, targetFrom?: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.listAzureHelpers(projectId, targetFrom);

    return useQuery({
        queryKey: [MigrationQueryKeys.ListAzureHelpers, projectId, targetFrom],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetAzureHelperResourceSelections = (helperId: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.getAzureHelperResourceSelections(helperId);
    return useQuery({
        queryKey: [MigrationQueryKeys.GetAzureHelperResourceSelections, helperId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetVmwareHelperResourceSelections = (helperId: string) => {
    const { gmMigrationService } = useAppServices();
    const queryFn = async () => await gmMigrationService.getVmwareHelperResourceSelections(helperId);
    return useQuery({
        queryKey: [MigrationQueryKeys.GetVmwareHelperResourceSelections, helperId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetComputeMigrationSourceReadiness = (deploymentId: string, hostEnvironment: HostEnvironment) => {
    const apis = useGrpcApiStore();

    const req = new GetComputeMigrationSourceReadiness.Request().setSystemId(deploymentId).setTargetEnvironment(hostEnvironment);

    const queryFn = async () => await apis.gmService.getComputeMigrationSourceReadiness(req, null);

    return useQuery({
        queryKey: [MigrationQueryKeys.getComputeMigrationSourceReadiness, deploymentId, hostEnvironment],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
        gcTime: 0,
    });
};

export const useSyncMigrationSession = () => {
    const apis = useGrpcApiStore();

    const mutationFn = async (args: { sessionId: string; consistencyGroup?: boolean }) => {
        const req = new SyncMigrationSession.Request().setSessionId(args.sessionId).setConsistencyGroup(args.consistencyGroup || false);
        await apis.gmService.syncMigrationSession(req, null);
    };

    return useMutationTrackAndAlertError(
        {
            mutationKey: [CmcMigrationMutationKeys.syncMigrationSession],
            mutationFn: mutationFn,
        },
        "Syncing Migration Session..."
    );
};
