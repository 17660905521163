import { APP_ROUTES, CALLBACK_SUBROUTE } from "../app/AppRoutes";
import React from "react";

export interface CallbackConfig {
    route: CALLBACK_SUBROUTE;
    redirectRoute: string;
    dialogTitle: string;
    dialogMessage: () => React.ReactNode;
    buttonText: string;
}

export const getCallbackConfig = (route: CALLBACK_SUBROUTE) => {
    if (route === CALLBACK_SUBROUTE.LICENSE_PURCHASE_SUCCESS) {
        return {
            route: CALLBACK_SUBROUTE.LICENSE_PURCHASE_SUCCESS,
            redirectRoute: APP_ROUTES.USER_LICENSE,
            dialogTitle: "Purchase License – Success",
            dialogMessage: () => "License purchased successfully!",
            buttonText: "Back To My License Page",
        };
    } else if (route === CALLBACK_SUBROUTE.LICENSE_PURCHASE_CANCELLED) {
        return {
            route: CALLBACK_SUBROUTE.LICENSE_PURCHASE_CANCELLED,
            redirectRoute: APP_ROUTES.USER_LICENSE,
            dialogTitle: "Purchase License – Canceled",
            dialogMessage: () => {
                return <>Purchase was canceled. No transactions were made.</>;
            },
            buttonText: "Back To My License Page",
        };
    }
};
