import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import { useAppServices } from "../../../app/services";
import { GalaxyMigrateMigrationSessionInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import {
    ActionTriggeredState,
    useCompleteMigrationSession,
    useCutoverBootSession,
    useCutoverMigrationSession,
    useDeleteMigrationSession,
    useFinalizeCutoverMigrationSession,
    useSyncMigrationSession,
} from "../../CmcMigrationCommon";
import { ChecklistTaskUIAction } from "gc-web-proto/galaxycompletepb/apipb/domainpb/checklist_pb";
import { GmMigrationSessionCreateReportDialog } from "../GmMigrationSessionCreateReportDialog";
import { useDialogState } from "../../../core/dialog/DialogService";
import { useMutation } from "@tanstack/react-query";
import { useCreateMigrationSessionReport } from "../../../reports/report_hooks";
import { CreateMigrationSessionReport } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";

interface ChecklistTaskSpecialUIActionButtonProps extends ChecklistAsyncActionButtonProps {
    action: ChecklistTaskUIAction;
}

export const ChecklistTaskSpecialUIActionButton: React.FC<ChecklistTaskSpecialUIActionButtonProps> = observer((p) => {
    if (p.action === ChecklistTaskUIAction.GM_TRIGGER_SYNC) {
        return <TriggerSynchronizationButton taskId={p.taskId} actionTriggeredState={p.actionTriggeredState} />;
    } else if (p.action === ChecklistTaskUIAction.GM_TRIGGER_CMOTION) {
        return <TriggerCmotionButton taskId={p.taskId} actionTriggeredState={p.actionTriggeredState} />;
    } else if (p.action === ChecklistTaskUIAction.GM_FINALIZE_CUTOVER) {
        return <FinalizeCutoverButton taskId={p.taskId} actionTriggeredState={p.actionTriggeredState} />;
    } else if (p.action === ChecklistTaskUIAction.GM_COMPLETE_SESSION) {
        return <CompleteSessionButton taskId={p.taskId} actionTriggeredState={p.actionTriggeredState} />;
    } else if (p.action === ChecklistTaskUIAction.GM_GENERATE_MIGRATION_SESSION_REPORT) {
        return <GenerateSessionReportButton taskId={p.taskId} actionTriggeredState={p.actionTriggeredState} />;
    }
    return null;
});

// ======================
// AsyncActionButton
// ======================

interface AsyncActionButtonProps {
    actionTriggeredState?: ActionTriggeredState;
    asyncAction: () => Promise<boolean>;
    label: string;
}

const AsyncActionButton: React.FC<AsyncActionButtonProps> = observer((p) => {
    const onClick = useCallback(async () => {
        const confirmed = await p.asyncAction();
        if (confirmed && !!p.actionTriggeredState) {
            p.actionTriggeredState.setActionTriggered(true);
        }
    }, [p]);
    return (
        <Button variant={"outlined"} onClick={onClick} disabled={p.actionTriggeredState?.actionTriggered}>
            {p.label}
        </Button>
    );
});

interface ChecklistAsyncActionButtonProps {
    taskId: number;
    actionTriggeredState?: ActionTriggeredState;
}

// ======================
// CmotionButton
// ======================

const TriggerCmotionButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService } = useAppServices();
    const sessionStatus = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStatus();
    const triggerCmotion = useCutoverMigrationSession();

    const setActionTriggered = p.actionTriggeredState.setActionTriggered;
    const markTaskCompleted = useCallback(async () => {
        await checklistService.markTaskCompleted(p.taskId);
        setActionTriggered(false);
    }, [checklistService, p.taskId, setActionTriggered]);

    React.useEffect(() => {
        if (sessionStatus === GalaxyMigrateMigrationSessionInfo.Status.STANDING_IN) {
            markTaskCompleted();
        }
    }, [markTaskCompleted, sessionStatus]);

    return <AsyncActionButton asyncAction={triggerCmotion} actionTriggeredState={p.actionTriggeredState} label={"Trigger cMotion™"} />;
});

// ======================
// FinalizeCutoverButton
// ======================

const FinalizeCutoverButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService } = useAppServices();
    const sessionStatus = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStatus();
    const finalizeCutover = useFinalizeCutoverMigrationSession();

    const setActionTriggered = p.actionTriggeredState.setActionTriggered;
    const markTaskCompleted = useCallback(async () => {
        await checklistService.markTaskCompleted(p.taskId);
        setActionTriggered(false);
    }, [checklistService, p.taskId, setActionTriggered]);

    React.useEffect(() => {
        if (sessionStatus === GalaxyMigrateMigrationSessionInfo.Status.CUTOVER) {
            markTaskCompleted();
        }
    }, [markTaskCompleted, sessionStatus]);

    return <AsyncActionButton asyncAction={finalizeCutover} actionTriggeredState={p.actionTriggeredState} label={"Finalize Cutover"} />;
});

// ======================
// TriggerSyncButton
// ======================

const TriggerSynchronizationButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService } = useAppServices();
    const sessionStatus = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStatus();
    const syncSession = useSyncMigrationSession();

    const setActionTriggered = p.actionTriggeredState.setActionTriggered;
    const markTaskCompleted = useCallback(async () => {
        await checklistService.markTaskCompleted(p.taskId);
        setActionTriggered(false);
    }, [checklistService, p.taskId, setActionTriggered]);

    React.useEffect(() => {
        if (sessionStatus === GalaxyMigrateMigrationSessionInfo.Status.TRACKING) {
            markTaskCompleted();
        }
    }, [markTaskCompleted, sessionStatus]);

    return <AsyncActionButton asyncAction={syncSession} actionTriggeredState={p.actionTriggeredState} label={"Trigger Synchronization"} />;
});

// ======================
// CutoverBootButton
// ======================

const CutoverBootButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService } = useAppServices();
    const sessionStatus = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStatus();
    const cutoverBootSession = useCutoverBootSession();

    const setActionTriggered = p.actionTriggeredState.setActionTriggered;
    const markTaskCompleted = useCallback(async () => {
        await checklistService.markTaskCompleted(p.taskId);
        setActionTriggered(false);
    }, [checklistService, p.taskId, setActionTriggered]);

    React.useEffect(() => {
        if (sessionStatus === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED) {
            markTaskCompleted();
        }
    }, [markTaskCompleted, sessionStatus]);

    return <AsyncActionButton asyncAction={cutoverBootSession} actionTriggeredState={p.actionTriggeredState} label={"Trigger Cutover"} />;
});

// ======================
// DeleteSessionButton
// ======================

const DeleteSessionButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const deleteSession = useDeleteMigrationSession();
    return <AsyncActionButton asyncAction={deleteSession} label={"Delete Session"} />;
});

const CompleteSessionButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { gmMigrationService, checklistService } = useAppServices();
    const sessionStatus = gmMigrationService.currentSession.data?.getSessionInfo().getSessionStatus();
    const action = useCompleteMigrationSession();

    const setActionTriggered = p.actionTriggeredState.setActionTriggered;
    const markTaskCompleted = useCallback(async () => {
        await checklistService.markTaskCompleted(p.taskId);
        setActionTriggered(false);
    }, [checklistService, p.taskId, setActionTriggered]);

    React.useEffect(() => {
        if (sessionStatus === GalaxyMigrateMigrationSessionInfo.Status.COMPLETED) {
            markTaskCompleted();
        }
    }, [markTaskCompleted, sessionStatus]);

    return <AsyncActionButton asyncAction={action} actionTriggeredState={p.actionTriggeredState} label={"Complete Session"} />;
});

// ======================
// GenerateSessionReportButton
// ======================

const GenerateSessionReportButton: React.FC<ChecklistAsyncActionButtonProps> = observer((p) => {
    const { checklistService, gmMigrationService } = useAppServices();
    const createReportDialogState = useDialogState();

    const createReport = useCreateMigrationSessionReport({
        onMutate: () => p.actionTriggeredState.setActionTriggered(true),
        onSuccess: async () => {
            await checklistService.markTaskCompleted(p.taskId);
            p.actionTriggeredState.setActionTriggered(false);
        },
    });

    return (
        <>
            <Button variant={"outlined"} onClick={createReportDialogState.open}>
                {`Generate Session Report`}
            </Button>
            {createReportDialogState.isOpen && (
                <GmMigrationSessionCreateReportDialog
                    sessionId={gmMigrationService.currentSessionID}
                    dialogState={createReportDialogState}
                    createReportFn={(args: { sessionId: string; reportNotes: string }) =>
                        createReport.mutate(new CreateMigrationSessionReport.Request().setSessionId(args.sessionId).setUserNotes(args.reportNotes))
                    }
                />
            )}
        </>
    );
});
