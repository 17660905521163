import * as React from "react";

import { createTheme, responsiveFontSizes, ThemeProvider, Theme, alpha } from "@mui/material/styles";
import primary from "@mui/material/colors/blue";
import secondary from "@mui/material/colors/pink";
import { grey } from "@mui/material/colors";
import { observer } from "mobx-react-lite";
import { useAppServices } from "./services";

export const cirrusColors = {
    primary: "#15192A",
    medium: "#1b1e2f",
    light: "#252f43",
};

export const border = {
    primary: "#3B4252",
};
export const cirrusGray = {
    primary: "#F5F7FB",
    dark: "#E1E2E6",
};

export const CirrusColor = "#15192A";

export const CirrusGradient = `linear-gradient(117deg, rgba(21,25,42,1) 6%, rgba(21,26,42,1) 35%, rgba(21,26,43,1) 66%, rgba(21,25,42,1) 100%)`;
// const CirrusGradient = `linear-gradient(117deg, rgba(21,28,101,1) 0%, rgba(0,28,56,1) 58%)`

export const makeAppTheme = (light = true) => {
    let theme;

    if (light) {
        theme = createTheme({
            palette: {
                background: {
                    default: grey["100"],
                },
                primary: { ...primary, main: primary["800"] },
                divider: border.primary,
                secondary: {
                    ...secondary,
                    main: secondary["A400"],
                    light: secondary["200"],
                },
                mode: light ? "light" : "dark",
            },
        });
        theme = responsiveFontSizes(theme);
    } else {
        theme = createTheme({
            palette: {
                background: {
                    default: cirrusColors.primary,
                    paper: cirrusColors.light,
                },
                divider: cirrusColors.primary,
                primary: {
                    ...primary,
                    main: primary["600"],
                },
                secondary: {
                    ...secondary,
                    main: secondary["500"],
                    light: secondary["200"],
                },
                cirrus: {
                    main: cirrusColors.primary,
                    light: cirrusColors.light,
                    medium: cirrusColors.medium,
                },
                cirrusGray: {
                    main: cirrusGray.primary,
                    dark: cirrusGray.dark,
                },
                border: {
                    main: border.primary,
                },
                neutral: {
                    main: grey["200"],
                    dark: grey["400"],
                    contrastText: grey["900"],
                },
                mode: light ? "light" : "dark",
            },
            shape: {},
            typography: {
                fontFamily: "InterVariable",
                h3: {
                    fontWeight: 700,
                    fontFamily: "Nunito Sans",
                },
                h4: {
                    fontFamily: "Nunito Sans",
                },
                h5: {
                    fontFamily: "Nunito Sans",
                },
                h6: {
                    fontFamily: "Nunito Sans",
                    fontWeight: 700,
                },
                button: {
                    fontWeight: 700,
                },
                body1: {
                    //fontSize: '1.1rem'
                },
                overline: {
                    letterSpacing: ".05rem",
                    color: "rgba(255, 255, 255, 0.7)",
                },
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        contained: {
                            borderRadius: 50,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 24,
                            paddingRight: 24,
                        },
                        outlined: {
                            borderRadius: 50,
                            paddingTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 24,
                            paddingRight: 24,
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderRadius: 10,
                            background: cirrusColors.light,
                            "&.MuiPaper-outlined": {
                                background: cirrusColors.primary,
                                border: "2px solid",
                                borderColor: cirrusColors.light,
                            },
                        },
                    },
                },

                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            borderRadius: 10,
                        },
                    },
                },
                MuiListSubheader: {
                    styleOverrides: {
                        root: {
                            background: "inherit",
                        },
                    },
                },
                MuiLink: {
                    defaultProps: {
                        color: "primary.light",
                    },
                    styleOverrides: {
                        root: {
                            textDecoration: "none",
                            //color: primary['400'],
                            "&:hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            overflow: "auto",
                            backgroundImage: "none",
                            "&::-webkit-scrollbar": {
                                width: "10px",
                                backgroundColor: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                borderRadius: "10px",
                                border: "2px solid transparent",
                                backgroundColor: "rgba(255,255,255,.1)",
                                backgroundClip: `content-box`,
                            },
                        },
                    },
                },
                MuiStepIcon: {
                    styleOverrides: {
                        root: {
                            "&.Mui-completed": {
                                color: alpha(primary["800"], 0.5),
                            },
                        },
                    },
                },
                MuiAlert: {
                    styleOverrides: {
                        root: {
                            "&.MuiAlert-outlinedInfo": {
                                borderColor: primary["800"],
                                color: primary["400"],
                                "& .MuiAlert-icon": {
                                    color: primary["600"],
                                },
                            },
                            "&.MuiAlert-standardInfo": {
                                backgroundColor: "#000712",
                                color: primary["400"],
                                "& .MuiAlert-icon": {
                                    color: primary["400"],
                                },
                            },
                        },
                    },
                },
                MuiDialogContent: {
                    styleOverrides: {
                        root: {
                            "&::-webkit-scrollbar": {
                                //display        : "none",
                                width: "10px",
                                backgroundColor: "transparent",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                borderRadius: "10px",
                                border: "2px solid transparent",
                                backgroundColor: "rgba(255,255,255,.1)",
                                backgroundClip: `content-box`,
                            },
                        },
                    },
                },
            },
        });
        theme = responsiveFontSizes(theme);
    }
    return theme;
};

// ======================
// LightModeTheme
// ======================
interface ThemeProps {
    children: React.ReactNode;
}

export const LightModeTheme: React.FC<ThemeProps> = observer(({ ...p }) => {
    return <ThemeProvider theme={makeAppTheme(true)}>{p.children}</ThemeProvider>;
});

// ======================
// DarkModeTheme
// ======================

export const DarkModeTheme: React.FC<ThemeProps> = observer(({ ...p }) => {
    return <ThemeProvider theme={makeAppTheme(false)}>{p.children}</ThemeProvider>;
});

// ======================
// AppTheme
// ======================

interface AppThemeProps {
    children: React.ReactNode;
}

export const AppTheme: React.FC<AppThemeProps> = observer(({ ...p }) => {
    const { appGlobalService } = useAppServices();
    if (appGlobalService.isLightMode) {
        return <LightModeTheme {...p}> {p.children}</LightModeTheme>;
    } else {
        return <DarkModeTheme {...p}> {p.children}</DarkModeTheme>;
    }
});
