// ======================
// CmoMigrationSystemDetailsVolumesTab
// ======================

import { useListCmoSourceVolumes } from "../cmo_migration_hooks";
import React, { useState } from "react";
import { ColumnDef, createColumnHelper, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../../common/table/QueryTable";
import { CmoVolume } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { Box, Link, Stack, Typography } from "@mui/material";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { getLicenseDurationLeftString } from "../../galaxymigrate/hostLicense/HostLicenseCommon";
import { OnlineStatusIcon } from "../../../common/CommonIcons";

interface CmoMigrationSystemDetailsVolumesTabProps {
    systemId: string;
}

export const CmoMigrationSystemDetailsVolumesTab: React.FC<CmoMigrationSystemDetailsVolumesTabProps> = (p) => {
    const { systemId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const queryResult = useListCmoSourceVolumes(systemId, pageIndex, pageSize);

    const columnHelper = createColumnHelper<CmoVolume.AsObject>();

    const cols: ColumnDef<CmoVolume.AsObject, any>[] = [
        columnHelper.accessor((r) => r.deviceDisplayName, {
            id: "Device",
            header: "Device",
            cell: (props) => (
                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <OnlineStatusIcon online={props.row.original.online} />
                    <Box>{props.getValue()}</Box>
                </Stack>
            ),
        }),
        columnHelper.accessor((r) => r.capacity, {
            id: "capacity",
            header: "Capacity",
            cell: (props) => formatKnownDataType(props.getValue(), KnownDataType.CAPACITY),
        }),
        columnHelper.accessor((r) => r.hostName, {
            id: "host",
            header: "Host",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.storageArrayName, {
            id: "storage",
            header: "Storage",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.vendor, {
            id: "vendor",
            header: "Vendor",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.firstPathLun, {
            id: "lun",
            header: "LUN",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.pathCount, {
            id: "paths",
            header: "Paths",
            cell: (props) => props.getValue(),
        }),
    ];

    return (
        <Box pt={2}>
            <QueryTable
                isLoading={queryResult.isLoading}
                data={queryResult.data?.itemsList || []}
                isError={queryResult.isError}
                error={queryResult.error}
                columns={cols}
                // pagination={{ pageIndex, pageSize }}
                // setPagination={setPagination}
                // pageCount={queryResult.data?.pagerMeta?.totalPages}
            />
        </Box>
    );
};
