import { GetCmoSystemHealthDetails, ListDeploymentsGeolocationInfo, ListGalaxyMigrateDeployments } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { mockDeploymentInfo1, mockGmDeployment1, mockGmDeployment2, mockGmDeployment3, mockGmLinkInfo, mockListPagerMeta } from "./FixturesCommon";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { ListGalaxyMigrateLinks } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { DeploymentNetworkInterfaceInfo, DeploymentsGeolocationListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import {
    CmoAppliance,
    CmoApplianceResourcesUsageStat,
    CmoDeploymentsSummary,
    CmoDestinationSummary,
    CmoInsertionSummary,
    CmoMigrationSummary,
    CmoSystemHealth,
    CmoSystemInfo,
    CmoSystemIoStats,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { CmoHealthStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_health_status_pb";
import { convertDateObjectToTimestamp } from "../../../../common/utils/formatter";
// ======================
// Deployments List
// ======================

export const mockDeploymentsList = new ListGalaxyMigrateDeployments.Response()
    .setItemsList([mockGmDeployment1, mockGmDeployment2, mockGmDeployment3])
    .setPagerMeta(mockListPagerMeta);

// ======================
// Connections List
// ======================

export const mockConnectionsList = new ListGalaxyMigrateLinks.Response().setItemsList([mockGmLinkInfo]).setPagerMeta(mockListPagerMeta);

// ======================
// Deployment Details
// ======================

const mockNetworkInterface1 = new DeploymentNetworkInterfaceInfo().setAddr("192.168.1.107").setName("Network 1").setConnected(true);

const mockNetworkInterface2 = new DeploymentNetworkInterfaceInfo().setAddr("192.168.1.108").setName("Network 2").setConnected(true);

export const mockDeploymentDetails = new GalaxyMigrateDeploymentDetails()
    .setNetworkInterfacesList([mockNetworkInterface1, mockNetworkInterface2])
    .setInfo(mockGmDeployment1);

export const mockCmoInsertionInfo = new CmoInsertionSummary()
    .setHosts(2)
    .setPaths(2)
    .setVolumes(2)
    .setHostPorts(2)
    .setFabricVendorsList(["Cisco", "Juniper"])
    .setHostPortVendorsList(["Cisco", "Juniper"])
    .setNexusActive(2)
    .setNexusTotal(4)
    .setStorageCapacity(100000000000)
    .setStorageEntities(2)
    .setStoragePorts(4)
    .setStorageVendorsList(["Cisco", "Juniper"]);

export const mockCmoMigrationInfo = new CmoMigrationSummary()
    .setCurrentThroughput(23423)
    .setAverageThroughput(23234)
    .setTotalMigrated(23423423)
    .setTotalData(234234234)
    .setTotalCurrentChangedData(234234234)
    .setTotalRemaining(234234234)
    .setTotalThinData(234234234)
    .setTotalBase(23423234)
    .setTotalChanged(2342423)
    .setTotalMigrated(232234)
    .setTotalTransferred(234234234)
    .setAverageTransferThroughput(23423)
    .setCurrentTransferThroughput(23423)
    .setProgressPercentage(33)
    .setSessionsActive(2)
    .setSessionsTotal(3)
    .setThinPercentage(23)
    .setVolumesTotal(23);
export const mockCmoDestinationInfo = new CmoDestinationSummary()
    .setInitiators(2)
    .setConnectedPaths(2)
    .setActiveInitiators(1)
    .setConnectedPorts(2)
    .setConnectedVolumes(2)
    .setConnectedCapacity(2323423223423)
    .setConnectedStorageVendorsList(["Cisco", "Juniper"]);
export const mockCmoStatsInfo = new CmoSystemIoStats()
    .setIops(234233)
    .setPendingIo(234223)
    .setReadIops(2323422)
    .setAverageReadSize(234232)
    .setWriteIops(2342423)
    .setAverageWriteSize(234234)
    .setReadThroughput(2000000)
    .setWriteThroughput(2000000)
    .setTotalThroughput(4000000);
export const mockCmoDeploymentSummary = new CmoDeploymentsSummary()
    .setCmoSystemsOnline(2)
    .setTotalCmoSystems(2)
    .setInsertion(mockCmoInsertionInfo)
    .setDestination(mockCmoDestinationInfo)
    .setMigration(mockCmoMigrationInfo)
    .setIoStats(mockCmoStatsInfo)
    .setInsertionMethodsList(["Path Insertion", "iSCSI Insertion"])
    .setNodeCount(4);

export const mockCmoHealthStatus = new CmoHealthStatus().setValue(CmoHealthStatus.CmoHealthStatus.HEALTHY);

export const mockCmoAppliance = new CmoAppliance()
    .setApplianceId("23432432")
    .setApplianceName("Appliance 1")
    .setPrimaryAppliance(true)
    .setModel("Model 1")
    .setActiveNexus(2)
    .setInsertedInitiators(2)
    .setInsertedPaths(2)
    .setInsertedTargets(2)
    .setInsertedVolumes(2)
    .setPrimaryIp("192.168.1.184")
    .setInsertionMethod("Path Insertion")
    .setIntercommIp("0.0.0.0")
    .setIscsiInitiatorIqn("23234")
    .setTotalNexus(4)
    .setKernelVersion("1.0.0")
    .setSoftwareVersion("DMS8000")
    .setSerialNumber("234234")
    .setSystemId("23423423234")
    .setResourcesUsage(new CmoApplianceResourcesUsageStat().setMemoryUsagePercent(85).setCpuUsagePercent(25));

export const mockCmoSystemInfo = new CmoSystemInfo()
    .setDestinationSummary(mockCmoDestinationInfo)
    .setInsertionSummary(mockCmoInsertionInfo)
    .setMigrationSummary(mockCmoMigrationInfo)
    .setIoStats(mockCmoStatsInfo)
    .setPrimaryAppliance(mockCmoAppliance)
    .setDeployment(mockDeploymentInfo1)
    .setHealthStatus(CmoHealthStatus.CmoHealthStatus.WARNING)
    .setMemberAppliance(mockCmoAppliance);

export const mockGetCmoSystemHealthDetails = new GetCmoSystemHealthDetails.Response().setDetails(
    new CmoSystemHealth()
        .setOverallStatus(CmoHealthStatus.CmoHealthStatus.WARNING)
        .setTime(convertDateObjectToTimestamp(new Date()))
        .setOverallStatus(CmoHealthStatus.CmoHealthStatus.HEALTHY)
        .setChecksList([
            new CmoSystemHealth.Check()
                .setName("Appliance System Storage")
                .setKey("1")
                .setTime(convertDateObjectToTimestamp(new Date()))
                .setMessage("Filesystem / has high usage (81.01% used with 3.5 GiB free space)")
                .setMessage2("Appliance member message")
                .setStatus(CmoHealthStatus.CmoHealthStatus.WARNING),
            new CmoSystemHealth.Check()
                .setName("CPU Resource")
                .setKey("2")
                .setTime(convertDateObjectToTimestamp(new Date()))
                .setMessage("Appliance cpu usage is at an acceptable level (15.00%)")
                .setMessage2("Appliance member message")
                .setStatus(CmoHealthStatus.CmoHealthStatus.HEALTHY),
            new CmoSystemHealth.Check()
                .setName("FC Nexus Bandwidth Utilization")
                .setKey("3")
                .setTime(convertDateObjectToTimestamp(new Date()))
                .setMessage("All 3 online nexus's io level is within normal utilization level")
                .setMessage2("Appliance member message")
                .setStatus(CmoHealthStatus.CmoHealthStatus.HEALTHY),
        ])
        .setExecutionError(
            'failed to execute health check on member appliance: rpc error: code = Unavailable desc = connection error: desc = "transport: Error while dialing dial tcp 192.68.1.174:11604: connect: connection refused"'
        )
);

export const mockListDeploymentsGeolocationInfo = new ListDeploymentsGeolocationInfo.Response().setPagerMeta(mockListPagerMeta).setItemsList([
    //NYC
    new DeploymentsGeolocationListItem().setCmoDeployments(1).setCmcDeployments(1).setGeoGroupLat(40.7128).setGeoGroupLong(-74.006),
    //Austin
    new DeploymentsGeolocationListItem().setCmcDeployments(2).setCmoDeployments(4).setGeoGroupLat(30.2672).setGeoGroupLong(-97.7431),
    //Shanghai
    new DeploymentsGeolocationListItem().setCmcDeployments(2).setCmoDeployments(4).setGeoGroupLat(31.2304).setGeoGroupLong(121.4737),
    //San Francisco
    new DeploymentsGeolocationListItem().setCmcDeployments(2).setCmoDeployments(4).setGeoGroupLat(37.7749).setGeoGroupLong(-122.4194),
    //Los Angeles
    new DeploymentsGeolocationListItem().setCmcDeployments(2).setCmoDeployments(4).setGeoGroupLat(34.0549).setGeoGroupLong(-118.2426),
]);
