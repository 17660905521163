import { observer } from "mobx-react-lite";
import { Box, Typography, TypographyOwnProps, TypographyVariants } from "@mui/material";
import * as React from "react";
// ======================
// KeyValuePair
// ======================

interface KeyValuePairProps {
    label: string;
    value: string | React.ReactNode;
    typographyVariant?: TypographyOwnProps["variant"];
}

export const KeyValuePair: React.FC<KeyValuePairProps> = observer((p) => {
    return (
        <Box display={"flex"}>
            <Typography variant={p.typographyVariant || "body1"} color={"textSecondary"}>
                {p.label}:&nbsp;&nbsp;&nbsp;
            </Typography>
            <Typography variant={p.typographyVariant || "body1"}>{p.value}</Typography>
        </Box>
    );
});

// ======================
// KeyValuePairSubtitle
// ======================

interface KeyValuePairSubtitleProps {}

export const KeyValuePairSubtitle: React.FC<KeyValuePairProps> = observer((p) => {
    return (
        <Box display={"flex"} alignItems={"center"}>
            <Typography fontWeight={500} variant={"body2"}>
                {p.label}:&nbsp;&nbsp;&nbsp;
            </Typography>
            <Typography color={"textSecondary"} variant={"body2"}>
                {p.value}
            </Typography>
        </Box>
    );
});
