import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CmoQueryKeys, DeploymentMutationKeys, DeploymentQueryKeys } from "../../common/QueryKeys";
import {
    GetCmoDeploymentsSummary,
    GetCmoSystemDetails,
    GetCmoSystemHealthDetails,
    ListCmoSystems,
    RemoveDeployment,
    RemoveOfflineDeployments,
} from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { FilterParams, PagerMeta, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import {
    GetCmoIOStats,
    GetCmoMigrationSessionDetails,
    GetCmoNexusDetails,
    GetCmoNexusDiscoveredPorts,
    GetCmoSessionVolumeDetails,
    GetCmoSystemWarningsSummary,
    ListCmoMigrationSessions,
    ListCmoNexuses,
    ListCmoSessionVolumes,
    ListCmoSourceVolumes,
    ListCmoSystemEvents,
    ListCmoSystemWarnings,
} from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { mockCmoMigrationSessionDetails, mockGetCmoMigrationSessionDetailsResponse } from "../core/testutils/fixtures/MockCmoService";
import { CmoSystemEvent } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { convertDateObjectToTimestamp } from "../../common/utils/formatter";
import { ProductType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/product_type_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";
import { ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";

export const useGetCmoDeploymentsSummary = (projectId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoDeploymentsSummary.Request().setProjectId(projectId);
    const queryFn = async () => {
        const res = await apis.deploymentService.getCmoDeploymentsSummary(req, null);
        //const res = new GetCmoDeploymentsSummary.Response().setSummary(mockCmoDeploymentSummary);

        return res.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.getCmoDeploymentsSummary, projectId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoSystems = (projectId: string, page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const req = new ListCmoSystems.Request().setProjectId(projectId).setPageParams(new PagerParams().setPerPage(perPage).setPage(page));
    const queryFn = async () => {
        const res = await apis.deploymentService.listCmoSystems(req, null);
        //const res = new ListCmoSystems.Response().setItemsList([mockCmoSystemInfo, mockCmoSystemInfo, mockCmoSystemInfo]).setPagerMeta(mockListPagerMeta);
        return res.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.listCmoSystems, projectId, page, perPage],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoSystemHealthDetails = (systemId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoSystemHealthDetails.Request().setSystemId(systemId);

    const queryFn = async () => {
        const res = await apis.deploymentService.getCmoSystemHealthDetails(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [DeploymentQueryKeys.getCmoSystemHealthStatus, systemId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoMigrationSessions = (projectId: string, page: number, perPage: number, systemId?: string) => {
    const apis = useGrpcApiStore();
    const sortFilterState = useGlobalTableSortFilterState().tableStateMap.get(CmoQueryKeys.listCmoMigrationSessions);

    const queryFn = async () => {
        const req = new ListCmoMigrationSessions.Request()
            .setProjectId(projectId)
            .setPageParams(new PagerParams().setPerPage(perPage).setPage(page))
            .setSystemId(systemId);
        if (sortFilterState.sortState) {
            req.setSortParam(sortFilterState.sortFilterConfig.sort.getSortParam(sortFilterState.sortState));
        }

        for (let filter of sortFilterState.filters || []) {
            filter.fieldConfig.addFilterToRequest(req, filter.param);
        }

        const res = await apis.cmoService.listCmoMigrationSessions(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [
            CmoQueryKeys.listCmoMigrationSessions,
            projectId,
            page,
            perPage,
            systemId,
            sortFilterState.sortState?.field.label,
            sortFilterState.sortState?.descending.label,
            sortFilterState.filters.map((f) => `${f.fieldConfig.label}: ${f.param.toObject().op} ${f.param.toObject().value}`),
        ],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoMigrationSessionDetails = (sessionId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoMigrationSessionDetails.Request().setSessionId(sessionId);

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoMigrationSessionDetails(req, null);
        //const res = mockGetCmoMigrationSessionDetailsResponse;
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.getCmoMigrationSessionDetails, sessionId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoSessionVolumes = (sessionId: string, page: number, perPage: number, includePaths: boolean = false) => {
    const apis = useGrpcApiStore();
    const req = new ListCmoSessionVolumes.Request()
        .setSessionId(sessionId)
        .setPageParams(new PagerParams().setPerPage(perPage).setPage(page))
        .setIncludepaths(includePaths);

    const queryFn = async () => {
        const res = await apis.cmoService.listCmoSessionVolumes(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.listCmoSessionVolumes, sessionId, page, perPage, includePaths],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoSessionVolumeDetails = (sessionId: string, volumeId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoSessionVolumeDetails.Request().setSessionId(sessionId).setSourceVolumeId(volumeId);

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoSessionVolumeDetails(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [CmoQueryKeys.getCmoSessionVolumeDetails, sessionId, volumeId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoSystemDetails = (systemId: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoSystemDetails.Request().setSystemId(systemId);

    const queryFn = async () => {
        const res = await apis.deploymentService.getCmoSystemDetails(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.getCmoSystemDetails, systemId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoSourceVolumes = (systemId: string, page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const req = new ListCmoSourceVolumes.Request().setSystemId(systemId).setPageParams(new PagerParams().setPerPage(perPage).setPage(page));

    const queryFn = async () => {
        const res = await apis.cmoService.listCmoSourceVolumes(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.listCmoSourceVolumes, systemId, page, perPage],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoNexus = (projectId: string, page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const req = new ListCmoNexuses.Request().setProjectId(projectId).setPageParams(new PagerParams().setPerPage(perPage).setPage(page));

    const queryFn = async () => {
        const res = await apis.cmoService.listCmoNexuses(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [CmoQueryKeys.listCmoNexuses, projectId, page, perPage],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoNexusDetails = (systemId: string, nexusName: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoNexusDetails.Request().setSystemId(systemId).setNexusName(nexusName);

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoNexusDetails(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.getCmoNexusDetails, systemId, nexusName],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoNexusDiscoveredPorts = (systemId: string, nexusName: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoNexusDiscoveredPorts.Request().setSystemId(systemId).setNexusName(nexusName);

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoNexusDiscoveredPorts(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.getCmoNexusDiscoveredPorts, systemId, nexusName],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export type CmoSystemEventsType = "activities" | "warnings";
export const useListCmoSystemEvents = (systemId: string, type: CmoSystemEventsType, page: number, perPage: number, projectId?: string) => {
    const apis = useGrpcApiStore();
    const activitiesReq = new ListCmoSystemEvents.Request().setSystemId(systemId).setPageParams(new PagerParams().setPerPage(perPage).setPage(page));
    const warningsReq = new ListCmoSystemWarnings.Request()
        .setProjectId(projectId)
        .setSystemId(systemId)
        .setPageParams(new PagerParams().setPerPage(perPage).setPage(page));

    const activitiesQueryFn = async () => {
        const res = await apis.cmoService.listCmoSystemEvents(activitiesReq, null);
        return res.toObject();
    };

    const warningsQueryFn = async () => {
        const res = await apis.cmoService.listCmoSystemWarnings(warningsReq, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.listCmoSystemEvents, type, systemId, page, perPage, projectId],
        queryFn: type === "activities" ? activitiesQueryFn : warningsQueryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useListCmoSystemWarnings = (projectId: string, systemId: string, page: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const req = new ListCmoSystemWarnings.Request()
        .setSystemId(systemId)
        .setProjectId(projectId)
        .setPageParams(new PagerParams().setPerPage(perPage).setPage(page));

    const queryFn = async () => {
        const res = await apis.cmoService.listCmoSystemWarnings(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.listCmoSystemWarnings, projectId, systemId, page, perPage],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useGetCmoIoStats = (req: GetCmoIOStats.Request) => {
    const apis = useGrpcApiStore();

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoIOStats(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.getCmoIoStats, req.getSystemId(), req.getStatsSelectionCase()],
        queryFn,
        refetchInterval: 1000,
    });
};

export const useGetCmoSystemWarningsSummary = (projectId?: string, systemId?: string) => {
    const apis = useGrpcApiStore();
    const req = new GetCmoSystemWarningsSummary.Request().setSystemId(systemId).setProjectId(projectId).setDaysReturnedCount(30);

    const queryFn = async () => {
        const res = await apis.cmoService.getCmoSystemWarningsSummary(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CmoQueryKeys.getCmoSystemWarningsSummary, projectId, systemId],
        queryFn,
        refetchInterval: 1000 * 60,
    });
};

export const useRemoveAllOfflineDeployments = (projectId: string) => {
    const apis = useGrpcApiStore();
    const mutationFn = async () => {
        const req = new RemoveOfflineDeployments.Request()
            .setProjectId(projectId)
            .setProductTypesList([new ProductType().setValue(ProductType.ProductType.DMS)]);
        await apis.deploymentService.removeOfflineDeployments(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [DeploymentMutationKeys.removeAllOfflineDeployments, projectId],
        mutationFn,
    });
};

export const useRemoveCmoDeployment = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (systemId: string) => {
        const req = new RemoveDeployment.Request().setSystemId(systemId);

        await apis.deploymentService.removeDeployment(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [DeploymentMutationKeys.removeDeployment],
        mutationFn,
        onSuccess: () => {
            queryClient.refetchQueries({
                queryKey: [DeploymentQueryKeys.listCmoSystems],
            });
        },
    });
};
