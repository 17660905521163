import * as React from "react";
import { useState } from "react";
import { VendorAllocateVolumesStepProps } from "../GmAutoAllocationCommon";
import { observer } from "mobx-react-lite";

import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { getDiskSizeInGiB } from "./azure/AzureIntegrationHelpers";
import produce from "immer";
import { ColumnDef } from "../../../../common/table/DataTable";
import { GmMigrationWizardVolumeState } from "../../GmMigrationService";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { SimpleTable } from "../../../../common/table/SimpleTable";
import { useMountEffect } from "../../../../common/hooks/hookslib";

interface DiskAssignmentConfig {
    capacity: number;
}

export const AzureElasticSanAllocateVolumesStep: React.FC<VendorAllocateVolumesStepProps> = observer((p) => {
    const state = p.state;
    const defaultParams = state.selectedIntegration.defaultVolumeParams.azuresan;

    const allocateNow = p.allocateFunc;

    useMountEffect(() => {
        for (let device of state.sourceDevices) {
            device.autoAllocParams.setAzuresan(new AutoAlloc.VolumeParams.AzureElasticSAN().setIqn(defaultParams.iqn).setAddress(defaultParams.address));
        }
    });

    const getInitalDiskAssignments = () => {
        const assignments: { [key: string]: DiskAssignmentConfig } = {};
        state.sourceDevices.forEach((device) => {
            assignments[device.source.getBlockDevice().getDeviceName()] = {
                capacity: getDiskSizeInGiB(device.source.getBlockDevice().getCapacity()),
            };
        });
        return assignments;
    };

    const [diskConfigs, setDiskConfigs] = useState<{ [key: string]: DiskAssignmentConfig }>(getInitalDiskAssignments());

    const setDiskCapacity = (diskName: string, capacity: number) => {
        setDiskConfigs(
            produce((draft) => {
                if (capacity > 0) {
                    draft[diskName].capacity = capacity;
                } else {
                    draft[diskName].capacity = null;
                }
            })
        );
        const sourceDeviceInAutoAllocState = state.sourceDevices.find((s) => s.source.getBlockDevice().getDeviceName() === diskName);
        sourceDeviceInAutoAllocState.autoAllocVolumeCapacity = capacity;
    };

    const cols: ColumnDef<GmMigrationWizardVolumeState>[] = [
        {
            id: "source",
            label: "Source Volume",
            getter: (d) => `${d.source.getBlockDevice().getDeviceName()} (${d.source.getBlockDevice().getDeviceType()})`,
        },
        {
            id: "capacity",
            label: "Capacity",
            getter: (d) => d.source.getBlockDevice().getCapacity(),
            renderer: (v, r) => {
                const diskConfig = diskConfigs[r.source.getBlockDevice().getDeviceName()];
                const error = diskConfig.capacity < getDiskSizeInGiB(r.source.getBlockDevice().getCapacity());
                return (
                    <TextField
                        value={diskConfig.capacity}
                        type={"number"}
                        variant={"outlined"}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">GiB</InputAdornment>,
                        }}
                        error={error}
                        helperText={error ? `Must be at least ${formatKnownDataType(r.source.getBlockDevice().getCapacity(), KnownDataType.CAPACITY)}` : ""}
                        onChange={(e) => {
                            setDiskCapacity(r.source.getBlockDevice().getDeviceName(), Number(e.target.value));
                        }}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Typography color={"textSecondary"}>
                {`Azure Elastic SAN Volumes matching the following source volumes will be created using the specified parameters and attached to the destination host`}
            </Typography>
            <br />
            <SimpleTable rows={state.sourceDevices} rowIdGetter={(r) => r.source.getBlockDevice().getDeviceName()} cols={cols} />
            <Box pt={2} pb={2}>
                <Button color={"primary"} variant={"contained"} onClick={allocateNow}>
                    {`Allocate Volumes (${state.sourceDevices.length})`}
                </Button>
            </Box>
        </>
    );
});
