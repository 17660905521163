import { observer } from "mobx-react-lite";
import { Link, Theme, Tooltip, Typography, TypographyProps, useTheme } from "@mui/material";
import React from "react";
import { TooltipText } from "./TooltipText";

// ======================
// TruncatedText
// ======================

interface TruncatedTextProps {
    characterLimit: number;
    text: string;
    truncateStart?: boolean;
}

export const TruncatedText: React.FC<TruncatedTextProps & Partial<TypographyProps>> = observer((p) => {
    const { characterLimit, text, truncateStart, ...typographyProps } = p;
    const t = useTheme();

    if (text.length <= characterLimit) {
        return <Typography {...typographyProps}>{text}</Typography>;
    }

    let truncatedText = text.slice(0, characterLimit - 1);

    if (truncateStart) {
        truncatedText = text.slice(text.length - characterLimit, text.length);
        return (
            <Typography {...typographyProps}>
                <TooltipText label={"..."} tooltipText={text} style={{ color: t.palette.primary.main }} />
                {truncatedText}
            </Typography>
        );
    }

    return (
        <Typography {...typographyProps}>
            {truncatedText}
            <TooltipText label={"..."} tooltipText={text} style={{ color: t.palette.primary.main }} />
        </Typography>
    );
});

// ======================
// TruncatedTextArray
// ======================

interface TruncatedTextArrayProps {
    arrayLimit: number;
    text: string[];
    emptyString?: string;
}

export const TruncatedTextArray: React.FC<TruncatedTextArrayProps & Partial<TypographyProps>> = (p) => {
    const { arrayLimit, text, ...typographyProps } = p;
    const t = useTheme();

    if (text.length === 0) {
        return <Typography {...typographyProps}>{p.emptyString || ""}</Typography>;
    }

    if (text.length <= arrayLimit) {
        return <Typography {...typographyProps}>{text.join(", ")}</Typography>;
    }

    const truncatedText = text.slice(0, arrayLimit);

    return (
        <Typography {...typographyProps}>
            {truncatedText.join(", ")}
            <TooltipText label={"..."} tooltipText={text.join(", ")} style={{ color: t.palette.primary.main }} />
        </Typography>
    );
};
