// ======================
// MigrateOpsNewOperationWizard
// ======================

import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { Box, Button, Card, Link, Stack, Theme, Typography } from "@mui/material";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import React, { useCallback, useEffect, useState } from "react";
import { useCreateNewOperationFromYaml } from "./migrate_ops_hooks";
import Editor from "react-simple-code-editor";
import hljs from "highlight.js/lib/core";
import yaml from "highlight.js/lib/languages/yaml";
import { useNavigateToNewOpScreen, useNavigateToOpsList, useNavigateToReference } from "./MigrateOpsCommon";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { KnownArticle } from "../help/HelpCommon";
import "highlight.js/styles/tokyo-night-dark.css";
import { OperatorView } from "../auth/AuthenticatedViews";
import { useMigrateOpsNewOperationBuilderState } from "./MigrateOpsNewOperationBuilderState";
import * as YAML from "yaml";
import { useDropzone } from "react-dropzone";
import { MdUpload } from "react-icons/md";
import { useIsDesktop } from "../layout/MainLayout";

hljs.registerLanguage("yaml", yaml);

interface MigrateOpsNewOperationWizardProps {}

export const MigrateOpsNewOperationPage: React.FC<MigrateOpsNewOperationWizardProps> = (p) => {
    const createOperation = useCreateNewOperationFromYaml();
    const generatedJsonFromBuilder = useMigrateOpsNewOperationBuilderState((s) => s.generatedJsonObject);
    const isGeneratedJsonAppliedToEditor = useMigrateOpsNewOperationBuilderState((s) => s.isGeneratedJsonAppliedToEditor);
    const setJsonAppliedToEditor = useMigrateOpsNewOperationBuilderState((s) => s.setJsonAppliedToEditor);
    const resetBuilder = useMigrateOpsNewOperationBuilderState((s) => s.resetBuilderState);

    const [value, setValue] = useState(
        Object.keys(generatedJsonFromBuilder).length > 0
            ? YAML.stringify(generatedJsonFromBuilder, {
                  indent: 2,
                  defaultKeyType: "PLAIN",
                  defaultStringType: "QUOTE_DOUBLE",
              })
            : ""
    );

    const [showEditor, setShowEditor] = useState(false);

    const goToOperationsListPage = useNavigateToOpsList();
    const openHelpArticle = useOpenHelpArticle();

    const handleSubmit = async () => {
        const res = await createOperation.mutateAsync(value);
        if (res) {
            goToOperationsListPage();
        }
    };

    useEffect(() => {
        if (isGeneratedJsonAppliedToEditor) {
            resetBuilder();
            setJsonAppliedToEditor(false);
        }
    }, [resetBuilder, isGeneratedJsonAppliedToEditor]);

    useEffect(() => {
        if (!!value) {
            setShowEditor(true);
        }
    }, [value, setShowEditor]);

    const goToReference = useNavigateToReference();

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    setValue(reader.result as string);
                },
                false
            );

            if (file) {
                reader.readAsText(file);
            }
        });
    }, []);
    const { getRootProps, getInputProps, fileRejections } = useDropzone({ onDrop, accept: [".yml", ".yaml"] });
    const isDesktop = useIsDesktop();
    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Start New Migration Operations"} />
            <Box pb={2}>
                <Typography>
                    {"If you need help getting started, go to"}
                    <Link onClick={goToReference}>{` MigrateOps Recipes `}</Link>
                    {"and click the Get Started button for your recipe."}
                </Typography>
            </Box>
            <Box>
                <Box {...getRootProps()} width={"100%"} pt={2}>
                    <Card
                        sx={{
                            backgroundColor: "rgba(255, 255, 255, .06)",
                            border: "1px dashed",
                            borderColor: "rgba(255, 255, 255, .7)",
                            transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                            cursor: "pointer",
                            "&:hover": {
                                backgroundColor: "rgba(255,255,255,.1)",
                            },
                        }}
                    >
                        <Stack p={2} alignItems={"center"}>
                            <Stack justifyContent={isDesktop ? "left" : "center"} direction={"row"} spacing={1} alignItems={"center"} pb={1}>
                                <MdUpload size={24} />
                                <Typography variant={"h5"}>{"Upload Your YAML Configuration"}</Typography>
                            </Stack>
                            <input {...getInputProps()} />
                            <Typography sx={{ color: "rgba(255,255,255,.7)" }}>{`Drag and drop or click to select file to load into editor below.`}</Typography>
                            <Typography variant={"body2"} sx={{ color: "rgba(255,255,255,.7)" }}>
                                {`Only YAML files (.yml or .yaml) will be accepted`}
                            </Typography>
                        </Stack>
                    </Card>
                    {fileRejections.length > 0 && (
                        <Box>
                            {fileRejections.map((f, i) => {
                                return (
                                    <Box key={i}>
                                        <Typography variant={"caption"} sx={{ color: (t: Theme) => t.palette.error.main }}>
                                            {f.file.name} cannot be uploaded. &nbsp;
                                            {f.errors.map((e) => e.message).join(". ")}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Box>
                <Box pt={2} textAlign={"center"}>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        {"Alternatively, you can copy and paste your YAML config directly by clicking the button below."}
                    </Typography>
                </Box>
                {!!showEditor ? (
                    <Box pt={2}>
                        <DarkFlatOutlinedCard sx={{ padding: 2, maxHeight: "55vh", overflow: "auto" }}>
                            <Editor
                                value={value}
                                onValueChange={(v) => setValue(v)}
                                placeholder={"Paste your MigrateOps Configuration YAML here..."}
                                highlight={(code) => hljs.highlight(code, { language: "yaml" }).value}
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono","Consolas","Menlo", monospace',
                                    fontSize: 13,
                                    minHeight: "50vh",
                                }}
                            />
                        </DarkFlatOutlinedCard>
                    </Box>
                ) : (
                    <Box textAlign={"center"} pt={2}>
                        <Button variant={"contained"} color={"primary"} onClick={() => setShowEditor(true)}>
                            {"Enter Config Manually"}
                        </Button>
                    </Box>
                )}
            </Box>
            {!value ? null : (
                <Box pt={2} textAlign={isDesktop ? "left" : "center"}>
                    <Button variant={"contained"} onClick={handleSubmit} color={"primary"} disabled={value === ""}>{`Create Operations`}</Button>
                </Box>
            )}

            <Stack spacing={2} pb={2} pt={4}>
                <Typography>{"For more information, see:"}</Typography>
                <ul>
                    <li>
                        <Link onClick={goToReference}>{"MigrateOps Recipes"}</Link>
                        <Box>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {"Operation recipe references and guides to getting started."}
                            </Typography>
                        </Box>
                    </li>
                    <li>
                        <Link onClick={() => openHelpArticle(KnownArticle.MIGRATEOPS_CONFIG)}>{"About Operation Configurations"}</Link>
                        <Box>
                            <Typography variant={"subtitle2"} color={"textSecondary"}>
                                {"Knowledge Base article with in-depth introduction to configurations."}
                            </Typography>
                        </Box>
                    </li>
                </ul>
            </Stack>
        </ScreenContainer>
    );
};
interface MigrateOpsNewOperationButtonProps {}

export const MigrateOpsNewOperationButton: React.FC<MigrateOpsNewOperationButtonProps> = (p) => {
    const navigateToNewOpWizard = useNavigateToNewOpScreen();
    return (
        <OperatorView>
            <Button sx={{ width: "fit-content" }} variant={"contained"} color={"secondary"} onClick={navigateToNewOpWizard}>
                {"Start New Operation"}
            </Button>
        </OperatorView>
    );
};
