import { Alert, Box, BoxProps, Button, Card, CardProps, Divider, Link, Typography } from "@mui/material";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { getProjectSubRouteTemplate, INTEGRATIONS_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import Email from "../../assets/integration_logos/email_icon.png";
import Placeholder from "../../assets/integration-placeholder.png";
import React, { ComponentType, ElementType } from "react";
import { observer } from "mobx-react-lite";
import { IntegrationConfigInfo, IntegrationDefinition, IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { ActionConfig, ActionMenuButton } from "../../common/actions/CommonActions";
import DGS from "../../assets/integration_logos/dgs.png";
import Azure from "../../assets/integration_logos/azure.png";
import AWS from "../../assets/integration_logos/aws.png";
import Pure from "../../assets/integration_logos/pure.png";
import NetApp from "../../assets/integration_logos/netapp.png";
import DigitalOcean from "../../assets/integration_logos/digitalocean.png";
import GCP from "../../assets/integration_logos/gcp.png";
import Oracle from "../../assets/integration_logos/oracle.png";
import VMware from "../../assets/integration_logos/vmware.png";
import AwsFsxNetapp from "../../assets/integration_logos/aws_fsx_netapp.png";
import Silk from "../../assets/integration_logos/silk.png";
import Ovirt from "../../assets/integration_logos/ovirt.png";
import { MdArrowBack } from "react-icons/md";
import { OperatorView } from "../auth/AuthenticatedViews";
import DellTechnologies from "../../assets/integration_logos/dell_technologies.png";
import { AzureStoragePlannerActions } from "./azureStoragePlanner/AzureStoragePlannerWizard";
import { useIntegrationRedirectState } from "./IntegrationRedirectState";
import { SiNutanix } from "react-icons/si";
import { TbReportAnalytics } from "react-icons/tb";
import { CreateThinDataReportButton } from "../migrateops/wizard/thinDataReport/MigrateOpsThinDataReportWizard";

export enum INTEGRATIONS_TYPE_SUBROUTE {
    AWS = "aws",
    AZURE = "azure",
    DGS = "dgs",
    PURE_CBS = "pure-cbs",
    EMAIL = "email",
    NETAPP = "netapp",
    GOOGLE = "google-cloud-platform",
    DIGITAL_OCEAN = "digital-ocean",
    ORACLE = "oracle",
    PURE_FLASH = "pure-flash-array",
    POWERSTORE = "dell-emc-powerstore",
    FC_POWERSTORE = "dell-emc-fc-powerstore",
    POWERMAX = "dell-emc-powermax",
    AWS_FSX_NETAPP = "aws-fsx-netapp",
    SILK = "silk",
    POWERFLEX = "dell-emc-powerflex",
    ELASTIC_SAN = "azure-elastic-san",
    VMWARE_DATASTORE = "vmware-datastore",
    NUTANIX = "nutanix",
    OVIRT = "ovirt",
}

const smallIconStyles = {
    width: 30,
    height: 30,
};

const normalIconStyles = {
    width: 100,
    height: 100,
};

interface IntegrationIconProps {
    size?: "small" | "large";
}

export const EmailIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Email} alt={"Email Integration"} {...attr} />;
};

export const PlaceholderIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Placeholder} alt={"Integration"} {...attr} />;
};

export const AzureIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Azure} alt={"Microsoft Azure Integration"} {...attr} />;
};

export const DgsIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={DGS} alt={"Cirrus Data Cloud Integration"} {...attr} />;
};

export const AwsIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"AWS Integration"} src={AWS} {...attr} />;
};

export const PureIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Pure Storage Integration"} src={Pure} {...attr} />;
};

export const NetAppIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"NetApp Integration"} src={NetApp} {...attr} />;
};

export const GCPIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Google Cloud Platform Integration"} src={GCP} {...attr} />;
};

export const DigitalOceanIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"DigitalOcean Integration"} src={DigitalOcean} {...attr} />;
};

export const OracleIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"DigitalOcean Integration"} src={Oracle} {...attr} />;
};

export const DellEmcIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Dell EMC Integration"} src={DellTechnologies} {...attr} />;
};

export const VmwareIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"VMware Integration"} src={VMware} {...attr} />;
};

export const AwsFsxNetappIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;

    return <img alt={"Amazon FSx for NetApp Integration"} src={AwsFsxNetapp} {...attr} />;
};

export const SilkIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img alt={"Silk Integration"} src={Silk} {...attr} />;
};

export const AzureElasticSanIntegrationIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Azure} alt={"Microsoft Azure Elastic SAN Integration"} {...attr} />;
};

export const AzureStoragePlannerIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Azure} alt={"Microsoft Azure Storage Planner"} {...attr} />;
};

export const NutanixIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <SiNutanix size={p.size === "small" ? 30 : 100} />;
};

export const ThinDataReportIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <TbReportAnalytics size={p.size === "small" ? 30 : 100} />;
};

export const OvirtIcon: React.FC<IntegrationIconProps> = (p) => {
    const attr = p.size === "small" ? smallIconStyles : normalIconStyles;
    return <img src={Ovirt} alt={"Ovirt"} {...attr} />;
};

type ModuleIconProps<P = {}> = {
    as?: ComponentType<P> | ElementType;
} & P;

export const ModuleIcon = <P,>({ as: Component = "div", ...props }: ModuleIconProps<P>) => {
    return <Component {...props} />;
};

export enum IntegrationCategory {
    ALERT,
    PUBLIC_CLOUD,
    ISCSI_STORAGE_ARRAY,
    FC_STORAGE_ARRAY,
    COMPUTE_INTEGRATION,
    HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE,
    PUBLIC_CLOUD_STORAGE_PLANNER,
}

export const getIntegrationCategoryDisplayName = (i: IntegrationCategory) => {
    if (i === IntegrationCategory.ALERT) {
        return "Alert";
    } else if (i === IntegrationCategory.ISCSI_STORAGE_ARRAY) {
        return "iSCSI Storage Array";
    } else if (i === IntegrationCategory.PUBLIC_CLOUD) {
        return "Public Cloud";
    } else if (i === IntegrationCategory.FC_STORAGE_ARRAY) {
        return "FC Storage Array";
    } else if (i === IntegrationCategory.COMPUTE_INTEGRATION) {
        return "Compute Integration";
    } else if (i === IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE) {
        return "Hypervisors / HCI / Software-defined Storage";
    } else if (i === IntegrationCategory.PUBLIC_CLOUD_STORAGE_PLANNER) {
        return "Storage Consumption Planner";
    }
};

export enum IntegrationNonModuleId {
    AZURE_STORAGE_PLANNER = "azure-storage-planner",
    SOURCE_DATA_ASSESSMENT = "source-data-assessment",
}

export interface IntegrationModuleConfig {
    module?: IntegrationModule;
    moduleDefinition?: IntegrationDefinition.AsObject;
    label?: string;
    route?: INTEGRATIONS_TYPE_SUBROUTE;
    category: IntegrationCategory;
    icon: ComponentType<any> | ElementType;
    defaultEnabled?: boolean;
    disabled?: boolean;
    externalLink?: string;
    mainActionButtonLabel?: string;
    mainActionButton?: React.ReactNode;
    description?: string;
    nonModuleId?: IntegrationNonModuleId;
}

export const getModuleConfigByModule = (module: IntegrationModule, integrationDefs?: IntegrationDefinition.AsObject[]) => {
    const defs = getIntegrationModuleConfigsWithDefinitions(integrationDefs);
    return defs.find((d) => d.module === module);
};

export const getIntegrationModuleConfigsWithDefinitions = (integrationDefs?: IntegrationDefinition.AsObject[]): IntegrationModuleConfig[] => {
    const moduleConfigs = getIntegrationModuleConfigs();
    return moduleConfigs.map((moduleConfig) => {
        const integrationDef = integrationDefs?.find((i) => moduleConfig.module === i.module);
        return {
            ...moduleConfig,
            moduleDefinition: integrationDef,
        };
    });
};

export const getIntegrationModuleConfigs = (): IntegrationModuleConfig[] => [
    {
        module: IntegrationModule.AWS,
        route: INTEGRATIONS_TYPE_SUBROUTE.AWS,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: AwsIntegrationIcon,
    },
    {
        module: IntegrationModule.AZURE,
        route: INTEGRATIONS_TYPE_SUBROUTE.AZURE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: AzureIntegrationIcon,
    },
    {
        label: "Microsoft Azure",
        icon: AzureStoragePlannerIcon,
        description: "Optimized cost analysis and recommendations on Azure products that fit your environment perfectly",
        category: IntegrationCategory.PUBLIC_CLOUD_STORAGE_PLANNER,
        mainActionButton: <AzureStoragePlannerActions />,
        nonModuleId: IntegrationNonModuleId.AZURE_STORAGE_PLANNER,
    },
    {
        module: IntegrationModule.AZURESAN,
        route: INTEGRATIONS_TYPE_SUBROUTE.ELASTIC_SAN,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: AzureElasticSanIntegrationIcon,
    },
    {
        module: IntegrationModule.DGS,
        route: INTEGRATIONS_TYPE_SUBROUTE.DGS,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: DgsIntegrationIcon,
    },
    {
        module: IntegrationModule.PURE,
        route: INTEGRATIONS_TYPE_SUBROUTE.PURE_CBS,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: PureIntegrationIcon,
    },
    {
        module: IntegrationModule.NETAPP,
        route: INTEGRATIONS_TYPE_SUBROUTE.NETAPP,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: NetAppIntegrationIcon,
    },
    {
        module: IntegrationModule.GCP,
        route: INTEGRATIONS_TYPE_SUBROUTE.GOOGLE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: GCPIntegrationIcon,
    },
    {
        module: IntegrationModule.ORACLE,
        route: INTEGRATIONS_TYPE_SUBROUTE.ORACLE,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: OracleIntegrationIcon,
    },
    {
        route: INTEGRATIONS_TYPE_SUBROUTE.DIGITAL_OCEAN,
        category: IntegrationCategory.PUBLIC_CLOUD,
        icon: DigitalOceanIntegrationIcon,
        module: IntegrationModule.DIGITALOCEAN,
    },
    {
        label: "Email",
        route: INTEGRATIONS_TYPE_SUBROUTE.EMAIL,
        category: IntegrationCategory.ALERT,
        icon: EmailIcon,
        description: "Email notifications for major project events.",
        defaultEnabled: true,
    },
    {
        module: IntegrationModule.FC_PURE,
        route: INTEGRATIONS_TYPE_SUBROUTE.PURE_FLASH,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: PureIntegrationIcon,
    },
    {
        module: IntegrationModule.POWERSTORE,
        route: INTEGRATIONS_TYPE_SUBROUTE.POWERSTORE,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
    },
    {
        module: IntegrationModule.FC_POWERSTORE,
        route: INTEGRATIONS_TYPE_SUBROUTE.FC_POWERSTORE,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
    },
    {
        module: IntegrationModule.FC_POWERMAX,
        route: INTEGRATIONS_TYPE_SUBROUTE.POWERMAX,
        category: IntegrationCategory.FC_STORAGE_ARRAY,
        icon: DellEmcIntegrationIcon,
    },
    {
        module: IntegrationModule.AWS_FSX_NETAPP,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: AwsFsxNetappIntegrationIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.AWS_FSX_NETAPP,
    },
    // {
    //     label: "VMware vCenter",
    //     category: IntegrationCategory.COMPUTE_INTEGRATION,
    //     icon: VmwareIntegrationIcon,
    //     description: "VMware, Inc.",
    //     externalLink: KnownArticle.CMC_HELPER_VMWARE,
    //     mainActionButtonLabel: "Deploy CMC Helper",
    // },
    {
        module: IntegrationModule.SILKSDP,
        category: IntegrationCategory.ISCSI_STORAGE_ARRAY,
        icon: SilkIntegrationIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.SILK,
    },
    {
        label: "Dell PowerFlex",
        module: IntegrationModule.POWERFLEX,
        category: IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE,
        icon: DellEmcIntegrationIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.POWERFLEX,
    },
    {
        module: IntegrationModule.VMWAREDATASTORE,
        category: IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE,
        icon: VmwareIntegrationIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.VMWARE_DATASTORE,
    },
    {
        module: IntegrationModule.NUTANIX,
        category: IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE,
        icon: NutanixIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.NUTANIX,
    },
    {
        module: IntegrationModule.OVIRT,
        category: IntegrationCategory.HYPERVISOR_HCI_SOFTWARE_DEFINED_STORAGE,
        icon: OvirtIcon,
        route: INTEGRATIONS_TYPE_SUBROUTE.OVIRT,
    },
    {
        label: "Source Data Assessment",
        icon: ThinDataReportIcon,
        description: "Generate a source data assessment to evaluate registered hosts' IO performance and potential thin data savings.",
        category: IntegrationCategory.PUBLIC_CLOUD_STORAGE_PLANNER,
        mainActionButton: <CreateThinDataReportButton />,
        nonModuleId: IntegrationNonModuleId.SOURCE_DATA_ASSESSMENT,
    },
];

// ======================
// IntegrationCard
// ======================

interface IntegrationCardProps {
    module: IntegrationModuleConfig;
    instance?: IntegrationConfigInfo.AsObject;
    mainActionButton?: React.ReactNode;
    cardProps?: Partial<CardProps>;
    cardActionsGetter?: (i: IntegrationConfigInfo.AsObject) => ActionConfig[];
    dividerColor?: string;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = observer((p) => {
    const goToDetails = useNavigateToIntegrationDetails(p.instance?.id);

    return (
        <Card {...p.cardProps}>
            <Grid container direction={"column"} alignItems={"stretch"}>
                {!!p.cardActionsGetter && (
                    <OperatorView>
                        <Grid>
                            <Box display={"flex"} justifyContent={"flex-end"} pr={1} pt={1}>
                                <ActionMenuButton actions={p.cardActionsGetter(p.instance)} />
                            </Box>
                        </Grid>
                    </OperatorView>
                )}
                <Grid>
                    <Box display={"flex"} justifyContent={"center"} pb={4} pt={!!p.cardActionsGetter ? 0 : 4}>
                        <ModuleIcon as={p.module.icon} size={"large"} />
                    </Box>
                </Grid>
                <Grid>
                    <Divider sx={{ borderColor: p.dividerColor ?? "rgba(255, 255, 255, .2)" }} />
                </Grid>
                <Grid>
                    <Box p={2}>
                        <Box>
                            {!!p.instance && (
                                <Box pb={1}>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {`id: #${p.instance.id}`}
                                    </Typography>
                                    <Typography variant={"body1"}>
                                        <Link onClick={goToDetails}>{p.instance.friendlyName}</Link>
                                    </Typography>
                                </Box>
                            )}
                            <Box minHeight={100}>
                                <Typography variant={"body1"}>{p.module.label || p.module.moduleDefinition?.name}</Typography>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {p.module.description || p.module.moduleDefinition?.description}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {!!p.mainActionButton && (
                    <Grid>
                        <Box p={2} display={"flex"} justifyContent={"center"}>
                            {p.mainActionButton}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
});

// ======================
// AddIntegrationButton
// ======================

interface AddIntegrationButtonProps {
    route: INTEGRATIONS_TYPE_SUBROUTE;
    defaultEnabled: boolean;
    disabled?: boolean;
    label?: string;
}

export const AddIntegrationButton: React.FC<AddIntegrationButtonProps> = observer((p) => {
    const goToCreateForm = useNavigateToCreateIntegrationForm(p.route);

    return (
        <Button variant={"contained"} color={"primary"} onClick={goToCreateForm} disabled={p.disabled || p.defaultEnabled}>
            {p.defaultEnabled ? "Enabled" : p.label || "Add Integration"}
        </Button>
    );
});

export interface IntegrationAlertConfig {
    alertMessage: string;
    navigateBack: () => void;
    navigateBackLabel: string;
}

// ======================
// IntegrationRedirectAlert
// ======================

interface IntegrationRedirectAlertProps {
    alertConfig: IntegrationAlertConfig;
    boxProps: Partial<BoxProps>;
}

export const IntegrationRedirectAlert: React.FC<IntegrationRedirectAlertProps> = observer((p) => {
    const resetIntegrationRedirectState = useIntegrationRedirectState((s) => s.resetIntegrationRedirectState);
    const resetIntegrationRedirect = () => {
        p.alertConfig.navigateBack();
        resetIntegrationRedirectState();
    };

    if (!p.alertConfig) {
        return null;
    }

    return (
        <>
            <Box {...p.boxProps}>
                <Alert
                    severity={"info"}
                    action={
                        <Button onClick={resetIntegrationRedirect} color={"inherit"} startIcon={<MdArrowBack />} size={"small"}>
                            {p.alertConfig?.navigateBackLabel}
                        </Button>
                    }
                >
                    {p.alertConfig.alertMessage}
                </Alert>
            </Box>
        </>
    );
});

export const getIntegrationModuleFromRoute = (route: INTEGRATIONS_TYPE_SUBROUTE) => {
    return getIntegrationModuleConfigs().find((m) => m.route === route).module;
};

export const getIntegrationRouteFromModule = (module: IntegrationModule) => {
    return getIntegrationModuleConfigs().find((m) => m.module === module).route;
};

export const INTEGRATIONS_REDIRECTED_QUERY_PARAM = "redirected";

export const generateIntegrationsSubRoutePath = (subroute: string, projectId: string, query?: boolean) => {
    if (query) {
        return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}?${subroute}`), { projectId });
    }
    return generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}/${subroute}`), { projectId });
};

export const useRedirectToIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_REDIRECTED_QUERY_PARAM}=true`, projectId, true);
    return () => navigate(p);
};

export const useNavigateToIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(`${PROJECT_SUBROUTE.INTEGRATIONS}`), { projectId });
    return () => navigate(p);
};

export const useNavigateToEnabledIntegrations = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(INTEGRATIONS_SUBROUTE.ENABLED, projectId);
    return () => navigate(p);
};

export const useNavigateToCreateIntegrationForm = (route: INTEGRATIONS_TYPE_SUBROUTE) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const subroute = `${INTEGRATIONS_SUBROUTE.CREATE}/${route}`;

    const p = generateIntegrationsSubRoutePath(subroute, projectId);
    return () => {
        navigate(p);
    };
};

export const useNavigateToEditIntegrationForm = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (id: string) => {
        const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_SUBROUTE.ENABLED}/${id}/edit`, projectId);
        navigate(p);
    };
};

export const useNavigateToIntegrationDetails = (id: number) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generateIntegrationsSubRoutePath(`${INTEGRATIONS_SUBROUTE.ENABLED}/${id}`, projectId);
    return () => {
        navigate(p);
    };
};
