import { useParams } from "react-router-dom";
import { Box, Button, Chip, darken, Dialog, DialogActions, DialogContent, IconButton, Stack, SvgIcon, TextField, Typography, useTheme } from "@mui/material";
import { QueryTable } from "../../common/table/QueryTable";
import { useCreateNewLabel, useDeleteLabel, useListProjectLabels } from "./label_hooks";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { CreateLabel, DeleteLabel, ListAllLabels } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { LabelDetails, LabelListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { DeleteIcon, EditIcon } from "../../common/CommonIcons";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { OperatorView, useIsOperatorView } from "../auth/AuthenticatedViews";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import React from "react";
import { red, purple, blue, cyan, green, lime, yellow, orange } from "@mui/material/colors";
import { MdCheck } from "react-icons/md";
import { LabelChip } from "./labelCommon";

import { ImCheckmark } from "react-icons/im";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { CreateLabelForm } from "./CreateLabelForm";

// ======================
// ProjectLabelManagementScreen
// ======================
interface ProjectLabelManagementScreenProps {}

export const ProjectLabelManagementScreen: React.FC<ProjectLabelManagementScreenProps> = (p) => {
    const { projectId } = useParams();
    const addNewLabelDialogState = useDialogState();
    return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h5"}>{"Label Management"}</Typography>
                <OperatorView>
                    <Button variant={"contained"} color={"secondary"} onClick={addNewLabelDialogState.open}>
                        {"Add New Label"}
                    </Button>
                </OperatorView>
            </Stack>
            <Box pt={2}>
                <ProjectLabelsTable projectId={projectId} />
            </Box>
            {addNewLabelDialogState.isOpen && <AddNewLabelDialog projectId={projectId} dialogState={addNewLabelDialogState} />}
        </Box>
    );
};

// ======================
// ProjectLabelsTable
// ======================

interface ProjectLabelsTableProps {
    projectId: string;
}

export const ProjectLabelsTable: React.FC<ProjectLabelsTableProps> = (p) => {
    const { projectId } = p;
    const queryResult = useListProjectLabels(projectId);
    const columnHelper = createColumnHelper<LabelListItem.AsObject>();
    const deleteLabel = useDeleteLabel();
    const globalDialogState = useGlobalDialogState();
    const isOperator = useIsOperatorView();
    const handleDelete = async (label: string) => {
        const confirmed = globalDialogState.addConfirmDialog({
            title: "Delete Label",
            message: "Are you sure you want to delete this label?",
            okButtonLabel: "Delete",
        });
        if (confirmed) {
            const req = new DeleteLabel.Request().setLabel(label).setProjectId(projectId);
            await deleteLabel.mutateAsync(req);
        }
    };

    const getHiddenColumns = () => {
        if (!isOperator) {
            return ["edit", "delete"];
        }
        return [];
    };

    const cols: ColumnDef<LabelListItem.AsObject, any>[] = [
        columnHelper.display({
            id: "edit",
            header: "",
            cell: (props) => {
                return <UpdateLabelButton projectId={projectId} labelInfo={props.row.original} />;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Label",
            id: "label",
            cell: (props) => (
                <LabelChip
                    label={props.row.original.label.name}
                    color={props.row.original.label.color}
                    description={props.row.original.label.description}
                    hostCount={props.row.original.hostCount}
                    migrationSessionCount={props.row.original.migrationSessionCount}
                    migrateOpsCount={props.row.original.migrateopsCount}
                />
            ),
        }),
        columnHelper.accessor((r) => r.label, {
            header: "Description",
            id: "description",
            cell: (props) => <Box>{props.getValue().description || "--"}</Box>,
        }),
        columnHelper.accessor((r) => r, {
            header: "Used By",
            id: "usedBy",
            cell: (props) => (
                <Stack direction={"row"} spacing={4}>
                    <Typography>{`${props.row.original.hostCount} Host(s)`}</Typography>

                    <Typography>{`${props.row.original.migrationSessionCount} Migration Session(s)`}</Typography>

                    <Typography>{`${props.row.original.migrateopsCount} MigrateOp(s)`}</Typography>
                </Stack>
            ),
        }),
        columnHelper.display({
            id: "delete",
            header: "",
            cell: (props) => {
                return (
                    <IconButton onClick={async () => handleDelete(props.row.original.label.name)}>
                        <DeleteIcon />
                    </IconButton>
                );
            },
        }),
    ];

    return (
        <QueryTable
            isLoading={queryResult.isLoading}
            data={queryResult.data?.itemsList || []}
            isError={queryResult.isError}
            error={queryResult.error}
            columns={cols}
            hiddenColumns={getHiddenColumns()}
        />
    );
};

// ======================
// AddNewLabelDialog
// ======================

interface AddNewLabelDialogProps {
    dialogState: DialogState;
    projectId: string;
}

export const AddNewLabelDialog: React.FC<AddNewLabelDialogProps> = (p) => {
    const { dialogState, projectId } = p;
    const isDialogFullScreen = useShouldDialogFullScreen();

    return (
        <Dialog open={dialogState.isOpen} fullWidth={true} fullScreen={isDialogFullScreen} maxWidth={"md"} onClose={dialogState.close}>
            <CreateLabelForm projectId={projectId} dialogState={dialogState} />
        </Dialog>
    );
};

// ======================
// UpdateLabelDialog
// ======================

interface UpdateLabelButtonProps {
    projectId: string;
    labelInfo: LabelListItem.AsObject;
}

export const UpdateLabelButton: React.FC<UpdateLabelButtonProps> = (p) => {
    const { projectId, labelInfo } = p;
    const isDialogFullScreen = useShouldDialogFullScreen();
    const dialogState = useDialogState();

    return (
        <>
            <IconButton onClick={dialogState.open}>
                <EditIcon />
            </IconButton>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} fullWidth={true} fullScreen={isDialogFullScreen} maxWidth={"md"} onClose={dialogState.close}>
                    <CreateLabelForm projectId={projectId} dialogState={dialogState} labelInfo={labelInfo} />
                </Dialog>
            )}
        </>
    );
};
