import { PagerMeta } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { HubUser } from "gc-web-proto/galaxycompletepb/apipb/domainpb/user_pb";
import { DeploymentIdentityInfo, DeploymentInfo, DeploymentNetworkInterfaceInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { ProjectIdentityInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import {
    GalaxyMigrateDeploymentDetails,
    GalaxyMigrateDeploymentInfo,
    GalaxyMigrateStorageConfig,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { BlockDeviceInfo } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { CheckForOnlineUpgradeResponse, GetLastOnlineUpgradeStatusResponse, OnlineUpgradeStatus } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { AuthenticatedUserInfo } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { GMLinkInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigratelink_pb";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
import { DeploymentHostEnvironment } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/deployment_host_environment_pb";
import { DialogState } from "../../dialog/DialogService";
import { HostLicenseInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";

export const mockOpenDialogState: DialogState = {
    isOpen: true,
    open: () => {},
    close: () => {},
    dialogProps: {},
    setDialogProps: () => {},
};

// ======================
// Authenticated User
// ======================

export const mockAuthUserNotActivated = new AuthenticatedUserInfo().setUserId(1).setActivated(false);

export const mockAuthUserActivated = new AuthenticatedUserInfo()
    .setCompanyName("Cirrus Data")
    .setActivated(true)
    .setEmail("lindsey.lam@cdsi.us.com")
    .setFirstName("Lindsey")
    .setLastName("Lam")
    .setUserId(1);

export const mockAuthSupportUserActivated = new AuthenticatedUserInfo()
    .setCompanyName("Cirrus Data")
    .setActivated(true)
    .setEmail("lindsey.lam@cdsi.us.com")
    .setFirstName("Lindsey")
    .setLastName("Lam")
    .setUserId(1)
    .setCdsiSupportUser(true);

// ======================
// PagerMeta
// ======================

export const mockListPagerMeta = new PagerMeta().setHasMore(true).setNextPage(2).setPage(1).setPerPage(30).setPrevPage(0).setTotalItems(2).setTotalPages(2);

// ======================
// Hub Users
// ======================

export const mockUser1 = new HubUser()
    .setBsiUserId(0)
    .setCompanyName("Cirrus Data")
    .setFirstName("Lindsey")
    .setLastName("Lam")
    .setEmail("lindsey.lam@cdsi.us.com")
    .setLocation("New York")
    .setJobTitle("Developer")
    .setPhoneNumber("xxx-xxx-xxxx");

export const mockUser2 = new HubUser().setEmail("sammy.tam@cdsi.us.com").setFirstName("Sammy").setLastName("Tam").setCompanyName("Cirrus Data");

// ======================
// Deployment
// ======================

export const mockHostLicenseInfo1 = new HostLicenseInfo()
    .setMigrationCapacityRemaining(234234242332)
    .setMigrationCapacityConsumed(324234232233)
    .setActivatedAt(new Timestamp().setSeconds(new Date("October 12, 2021").getTime() / 1000))
    .setExpireAt(new Timestamp().setSeconds(new Date("October 12, 2030").getTime() / 1000))
    .setTimeRemaining(new Duration().setSeconds(new Date("October 12, 2030").getTime() / 1000 - new Date().getTime() / 1000));

export const mockHostLicenseInfo2 = new HostLicenseInfo()
    .setMigrationCapacityRemaining(23423424233223)
    .setMigrationCapacityConsumed(324234232233)
    .setActivatedAt(new Timestamp().setSeconds(new Date("October 12, 2021").getTime() / 1000))
    .setExpireAt(new Timestamp().setSeconds(new Date("June 12, 2022").getTime() / 1000))
    .setTimeRemaining(new Duration().setSeconds(new Date("June 12, 2022").getTime() / 1000 - new Date().getTime() / 1000));

export const mockHostLicenseInfo3 = new HostLicenseInfo()
    .setMigrationCapacityRemaining(234234242332)
    .setMigrationCapacityConsumed(324234232233)
    .setActivatedAt(new Timestamp().setSeconds(new Date("October 12, 2021").getTime() / 1000))
    .setExpireAt(new Timestamp().setSeconds(new Date("June 3, 2022").getTime() / 1000))
    .setTimeRemaining(new Duration().setSeconds(0));

export const mockDeploymentInfo1 = new DeploymentInfo()
    .setConnected(true)
    .setConnectionLatency(new Duration().setNanos(23423432343))
    .setLastCheckin(new Timestamp().setSeconds(1604648837))
    .setProductType(2)
    .setLicense(mockHostLicenseInfo1)
    .setProject(new ProjectIdentityInfo().setName("Unicorn").setProjectId("884a4178-099d-40dd-b7f9-8cdc550f0277"))
    .setRegisteredAt(new Timestamp().setSeconds(1604350291))
    .setSystemId("51e03fe4-4da7-4414-80ee-dab0971f599e")
    .setSystemName("ip-172-31-40-51")
    .setSystemTimezone("UTC")
    .setHostEnvironment(new DeploymentHostEnvironment().setValue(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE))
    .setVersion("0.0.1");

export const mockDeploymentInfo2 = new DeploymentInfo()
    .setConnected(true)
    .setConnectionLatency(new Duration().setNanos(2342334322))
    .setLastCheckin(new Timestamp().setSeconds(1604648837))
    .setProductType(2)
    .setLicense(mockHostLicenseInfo2)
    .setProject(new ProjectIdentityInfo().setName("Pegasus").setProjectId("5254624543252345346262345345435435446433"))
    .setRegisteredAt(new Timestamp().setSeconds(1604350291))
    .setSystemId("8b0f459b-8876-4ece-bcfb-0ef7f981299c")
    .setSystemName("mock deployment 2")
    .setSystemTimezone("UTC")
    .setHostEnvironment(new DeploymentHostEnvironment().setValue(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE))
    .setVersion("0.0.1");

export const mockDeploymentInfo3 = new DeploymentInfo()
    .setConnected(true)
    .setConnectionLatency(new Duration().setNanos(344354645343))
    .setLastCheckin(new Timestamp().setSeconds(1604648837))
    .setProductType(2)
    .setLicense(mockHostLicenseInfo3)
    .setProject(new ProjectIdentityInfo().setName("Griffin").setProjectId("56778768764567367678768486576576457645756767"))
    .setRegisteredAt(new Timestamp().setSeconds(1604350291))
    .setSystemId("51e03fe4-4da7-4414-80ee-dsfasdfasdaefg")
    .setSystemName("mock deployment 3")
    .setSystemTimezone("UTC")
    .setHostEnvironment(new DeploymentHostEnvironment().setValue(DeploymentHostEnvironment.DeploymentHostEnvironment.VMWARE))
    .setVersion("0.0.1");

export const mockGmDeployment1 = new GalaxyMigrateDeploymentInfo()
    .setDeployment(mockDeploymentInfo1)
    .setKernel("5.4.0-1024-aws")
    .setMemory(0)
    .setMemoryUsed(0)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType(0)
    .setMtdiVersion("9.0.0");

export const mockGmDeployment2 = new GalaxyMigrateDeploymentInfo()
    .setDeployment(mockDeploymentInfo2)
    .setKernel("5.4.0-1024-aws")
    .setMemory(0)
    .setMemoryUsed(0)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType(0)
    .setMtdiVersion("9.0.0");

export const mockGmDeployment3 = new GalaxyMigrateDeploymentInfo()
    .setDeployment(mockDeploymentInfo3)
    .setKernel("5.4.0-1024-aws")
    .setMemory(0)
    .setMemoryUsed(0)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType(0)
    .setMtdiVersion("9.0.0");

export const mockNetworkInterface1 = new DeploymentNetworkInterfaceInfo().setName("network1").setConnected(true).setAddr("12312412").setMac("2342335r33432");

export const mockGmDeploymentDetails = new GalaxyMigrateDeploymentDetails()
    .setInfo(mockGmDeployment1)
    .setNetworkInterfacesList([mockNetworkInterface1, mockNetworkInterface1]);

export const mockDeploymentIdentityInfo1 = new DeploymentIdentityInfo()
    .setSystemId("51e03fe4-4da7-4414-80ee-dab0971f599e")
    .setSystemName("ip-172-31-40-51")
    .setProductType(2);

export const mockDeploymentIdentityInfo2 = new DeploymentIdentityInfo()
    .setSystemId("8b0f459b-8876-4ece-bcfb-0ef7f981299c")
    .setSystemName("mock deployment 2")
    .setProductType(2);

// ======================
// Galaxy Migrate Links
// ======================

export const mockGmLinkInfo = new GMLinkInfo()
    .setClient(mockDeploymentIdentityInfo1)
    .setServer(mockDeploymentIdentityInfo2)
    .setConnected(true)
    .setLinkid("mock")
    .setLastserveraddress("192.168.1.161")
    .setLatency(new Duration().setNanos(19694270));

// ======================
// Storage Config
// ======================

const blockDevice1 = new BlockDeviceInfo()
    .setBusAddress("")
    .setCapacity(8589934592)
    .setDeviceLinksList([
        "/dev/disk/by-id/nvme-nvme.1d0f-766f6c3030396164303164323266313233383535-416d617a6f6e20456c617374696320426c6f636b2053746f7265-00000001",
        "/dev/disk/by-id/nvme-Amazon_Elastic_Block_Store_vol009ad01d22f123855",
        "/dev/disk/by-path/pci-0000:00:1f.0-nvme-1",
    ])
    .setDeviceName("nvme2n1")
    .setDeviceType("disk")
    .setInUse(false)
    .setSerialId("Amazon Elastic Block Store_vol009ad01d22f123855")
    .setWwn("nvme.1d0f-766f6c3030396164303164323266313233383535-416d617a6f6e20456c617374696320426c6f636b2053746f7265-00000001")
    .setFsType("")
    .setFsUuid("")
    .setLabel("")
    .setHardwareId("")
    .setMountPoint("")
    .setPersistentIdentity("");

const blockDevice2 = new BlockDeviceInfo()
    .setBusAddress("")
    .setCapacity(8589934592)
    .setDeviceLinksList([
        "/dev/disk/by-id/nvme-nvme.1d0f-766f6c3030396164303164323266313233383535-416d617a6f6e20456c617374696320426c6f636b2053746f7265-00000001",
        "/dev/disk/by-id/nvme-Amazon_Elastic_Block_Store_vol009ad01d22f123855",
        "/dev/disk/by-path/pci-0000:00:1f.0-nvme-1",
        "/dev/disk/by-id/dm-uuid-mpath-3624a9370f04983dd1a4c443400012089",
        "/dev/disk/by-id/dm-uuid-mpath-3624a937051fc0e56125241eb0001be7d",
    ])
    .setDeviceName("nvme2n2")
    .setDeviceType("disk")
    .setInUse(false)
    .setSerialId("Amazon Elastic Block Store_vol009ad01d22f123855")
    .setWwn("nvme.1d0f-766f6c3030396164303164323266313233383535-416d617a6f6e20456c617374696320426c6f636b2053746f7265-00000001")
    .setFsType("FS Type")
    .setFsUuid("")
    .setLabel("FS Label")
    .setHardwareId("")
    .setMountPoint("Mount Point")
    .setPersistentIdentity("");

const role = new GalaxyMigrateStorageConfig.Device.RoleInfo()
    .setDevicePathSelected("/dev/disk/by-id/nvme-Amazon_Elastic_Block_Store_vol009ad01d22f123855")
    .setDeviceUuid("d0669241-9728-85eb-35c3-ae0eb7a84454")
    .setMigrationSessionUuid("f53f10b3-0688-4841-ad1f-64f43814ea4f")
    .setRole(2);

const role2 = new GalaxyMigrateStorageConfig.Device.RoleInfo()
    .setDevicePathSelected("/dev/disk/by-id/nvme-Amazon_Elastic_Block_Store_vol009ad01d22f123855")
    .setDeviceUuid("d0669241-9728-85eb-35c3-ae0eb7a84453")
    .setMigrationSessionUuid("f53f10b3-0688-4841-ad1f-64f43814ea4f")
    .setRole(2);

export const mockStorageConfigDevice1 = new GalaxyMigrateStorageConfig.Device()
    .setBlockDevice(blockDevice1)
    .setCanEnlistAsDestination(false)
    .setCanInsertAsSource(true)
    .setRole(role);

export const mockStorageConfigDevice2 = new GalaxyMigrateStorageConfig.Device()
    .setBlockDevice(blockDevice2)
    .setCanEnlistAsDestination(false)
    .setCanInsertAsSource(true)
    .setRole(role2);

export const mockGmStorageConfig = new GalaxyMigrateStorageConfig().setDevicesList([mockStorageConfigDevice1]).setLvmConfig(null);

// ======================
// Online Upgrade
// ======================

export const mockUpgradeVersion = new CheckForOnlineUpgradeResponse().setNewVersion("2.0.0");

export const mockSuccessUpgradeStatus = new GetLastOnlineUpgradeStatusResponse().setLastStatus(
    new OnlineUpgradeStatus()
        .setLastUpdateTime(new Timestamp().setSeconds(1604648837))
        .setCompleted(true)
        .setScriptOutputLinesList(["apple", "banana", "strawberry", "kiwi"])
        .setSuccess(true)
);

export const mockFailureUpgradeStatus = new GetLastOnlineUpgradeStatusResponse().setLastStatus(
    new OnlineUpgradeStatus()
        .setLastUpdateTime(new Timestamp().setSeconds(1604648837))
        .setCompleted(true)
        .setScriptOutputLinesList(["apple", "banana", "strawberry", "kiwi"])
        .setSuccess(false)
);

export const mockUpgradingUpgradeStatus = new GetLastOnlineUpgradeStatusResponse().setLastStatus(
    new OnlineUpgradeStatus()
        .setLastUpdateTime(new Timestamp().setSeconds(1604648837))
        .setCompleted(false)
        .setScriptOutputLinesList(["apple", "banana", "strawberry"])
        .setSuccess(false)
);

export const mockNoUpgradeStatus = new GetLastOnlineUpgradeStatusResponse().setLastStatus(
    new OnlineUpgradeStatus().setCompleted(false).setScriptOutputLinesList([]).setSuccess(false)
);

export const createTestBitmapArray = () => {
    let array = [];
    for (let i = 0; i < 5000; i++) {
        array.push(i);
    }
    return array;
};
