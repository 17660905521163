// ======================
// ProjectMigrateOpsScreens
// ======================

import { Route, Routes } from "react-router-dom";
import { MIGRATE_OPS_SUBROUTE, CMC_MIGRATION_SUBROUTE, PROJECT_SUBROUTE } from "../app/AppRoutes";
import { MigrateOpsListView } from "./MigrateOpsListView";
import { MigrateOpsDetailsView } from "./details/MigrateOpsDetailsView";
import { MigrateOpsNewOperationPage } from "./MigrateOpsNewOperationPage";
import { MigrateOpsNewOperationBuilderPage } from "./MigrateOpsNewOperationBuilder";
import * as React from "react";
import { MigrateOpsWizardView } from "./wizard/MigrateOpsWizardView";
import { MigrateOpsGettingStartedInstructionsView } from "./MigrateOpsGettingStartedInstructionsView";

interface ProjectMigrateOpsScreensProps {}

export const ProjectMigrateOpsScreens: React.FC<ProjectMigrateOpsScreensProps> = (p) => {
    return (
        <Routes>
            <Route index element={<MigrateOpsListView />} />
            <Route path={`:opId`} element={<MigrateOpsDetailsView />} />
            <Route path={MIGRATE_OPS_SUBROUTE.CREATE} element={<MigrateOpsNewOperationPage />} />
            <Route path={MIGRATE_OPS_SUBROUTE.BUILDER} element={<MigrateOpsNewOperationBuilderPage />} />
            <Route path={`${MIGRATE_OPS_SUBROUTE.WIZARD}/:recipe`} element={<MigrateOpsWizardView />} />
        </Routes>
    );
};
