import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { OperationConfig, OperationJson } from "../MigrateOpsWizardCommon";
import _ from "lodash-es";
import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";

export interface AutoAllocVolumeParams {
    purecbs?: {};
    azure?: {
        storage_type: string;
        iops: number;
        throughput: number;
    };
    dgs?: {};
    aws?: {
        volume_type: string;
        iops: number;
        ebs_encryption: boolean;
        kms_key: string;
    };
    netapp?: {
        qos_policy_name: string;
        volume_guarantee: boolean;
        fractional_reserve: number;
        snapshot_reserve: number;
        additional_capacity_percent: number;
    };
    powerstore?: {
        protection_policy_name: string;
        performance_policy_name: string;
    };
    powermax?: {
        service_level: string;
        storage_pool: string;
    };
    gcp?: {
        disk_type: string;
        iops: number;
    };
    oracle?: {
        vpus: number;
    };
    silksdp?: {
        volume_group_name: string;
    };
    digitalocean?: {
        size_gib: number;
    };
    powerflex?: {
        storage_pool_name: string;
        protection_domain_name: string;
        volume_type: string;
    };
    azuresan?: {
        iqn: string;
        address: string;
    };
    vmware?: {
        datastore: string;
        directory: string;
        provisioning: string;
        disk_mode: string;
        sharing: boolean;
    };
    nutanix?: {
        storage_container_name: string;
        native_vdisk: boolean;
        volume_group_name: string;
    };
    ovirt?: {
        storage_domain: string;
        sparse: boolean;
        interface: string;
    };
}

export enum HostActionType {
    OS_SHELL,
    OS_STOP_SERVICE,
    OS_START_SERVICE,
    STORAGE_RESCAN,
    STORAGE_UNMOUNT,
    STORAGE_MOUNT_ALL,
    WINDOWS_MOVE_CLUSTER_GROUP,
    WINDOWS_MOVE_CLUSTER_SHARED_VOLUME,
    WINDOWS_CSV_SET_REDIRECTED_ACCESS,
    SLEEP,
}

export interface HostAction {
    os_shell?: {
        script: Array<string>;
        timeout: string;
        user: string;
    };
    os_stop_service?: {
        service: string;
    };
    os_start_service?: {
        service: string;
    };
    storage_rescan?: {};
    storage_unmount?: {
        mountpoint: string;
    };
    storage_mount_all?: {};
    windows_move_cluster_group?: {
        resource_group: string;
        target_node_name: string;
    };
    windows_move_cluster_shared_volume?: {
        volume_name: string;
        target_node_name: string;
    };
    windows_csv_set_redirected_access?: {
        volume_name: string;
        redirected_access: boolean;
    };
    sleep?: {
        seconds: number;
    };
}

interface LocalDataMigrationConfig extends OperationConfig {
    migrateops_local_data_migration: {
        source: {
            migrate_all_volumes: boolean;
            source_volume_names: Array<string>;
        };
        destination: {
            auto_allocate_volumes: boolean;
            auto_prepare_host: boolean;
            default_auto_allocation_volume_params: AutoAllocVolumeParams;
        };
        migration: {
            session_description: string;
            auto_resync_interval: string;
            qos_level: "RELENTLESS" | "MINIMUM" | "MODERATE" | "AGGRESSIVE";
        };
        cutover: {
            manual_cutover: boolean;
            reboot_cutover: boolean;
            before_cutover: Array<HostAction>;
            after_cutover: Array<HostAction>;
        };
        hooks: {
            before_destination_auto_allocation: Array<HostAction>;
            after_destination_auto_allocation: Array<HostAction>;
            before_migration_creation: Array<HostAction>;
            after_migration_creation: Array<HostAction>;
        };
        generate_report: boolean;
    };
}

interface LocalDataMigrationJson extends OperationJson {
    config: LocalDataMigrationConfig;
}

export interface MigrateOpsLocalDataMigrationWizardState {
    operationJson: LocalDataMigrationJson;
    setOperationJsonField: (fields: string | Array<string>, value: any) => void;
    getOperationJsonField: (fields: Array<string>) => any;
    resetState: () => void;
}

const getInitialJson = (): LocalDataMigrationJson => {
    return {
        name: "Local Data Migration Operation",
        notes: "",
        recipe: "MIGRATEOPS_AWS_COMPUTE",
        config: {
            system_id: "",
            integration_id: null,
            scheduled_start_time: null,
            migrateops_local_data_migration: {
                source: {
                    migrate_all_volumes: undefined,
                    source_volume_names: [],
                },
                destination: {
                    auto_allocate_volumes: undefined,
                    auto_prepare_host: undefined,
                    default_auto_allocation_volume_params: undefined,
                },
                migration: {
                    session_description: "",
                    auto_resync_interval: "1440s",
                    qos_level: undefined,
                },
                cutover: {
                    manual_cutover: undefined,
                    reboot_cutover: undefined,
                    before_cutover: [],
                    after_cutover: [],
                },
                hooks: {
                    before_destination_auto_allocation: [],
                    after_destination_auto_allocation: [],
                    before_migration_creation: [],
                    after_migration_creation: [],
                },
                generate_report: undefined,
            },
        },
    };
};

export const useMigrateOpsLocalDataMigrationWizardState = create<MigrateOpsLocalDataMigrationWizardState>()(
    immer((set, get) => ({
        operationJson: getInitialJson(),
        setOperationJsonField: (fields: string | Array<string>, value: any) => {
            if (Array.isArray(fields)) {
                const key = fields.join(".");
                set((state) => {
                    _.set(state.operationJson, key, value);
                });
            } else {
                set((state) => {
                    state.operationJson[fields as keyof LocalDataMigrationJson] = value;
                });
            }
        },
        getOperationJsonField: (fields: Array<string>) => {
            const key = fields.join(".");
            return _.get(get().operationJson, key);
        },
        resetState: () => {
            set((state) => {
                state.operationJson = getInitialJson();
            });
        },
    }))
);
