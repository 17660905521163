// ======================
// AddHostActions
// ======================

import { HostAction, HostActionType, useMigrateOpsLocalDataMigrationWizardState } from "./MigrateOpsLocalDataMigrationWizardState";
import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { CloseIcon } from "../../../../common/CommonIcons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDialogState, useShouldDialogFullScreen } from "../../../core/dialog/DialogService";
import { DialogTopBar } from "../../../core/dialog/DialogComponents";
import { DarkFlatCard, DarkFlatOutlinedCard } from "../../../../common/card/DarkCard";
import { BiTerminal } from "react-icons/bi";
import { MdOutlineEject, MdOutlinePlayCircle, MdOutlineSaveAlt, MdOutlineStopCircle } from "react-icons/md";
import { RiQrScanFill, RiZzzFill } from "react-icons/ri";
import { AiOutlineCluster } from "react-icons/ai";
import { GrServerCluster } from "react-icons/gr";
import { BsFiletypeCsv } from "react-icons/bs";
import { HiOutlineServerStack } from "react-icons/hi2";

interface AddHostActionsProps {
    hostActionField: Array<string>;
}

export const AddHostActions: React.FC<AddHostActionsProps> = (p) => {
    const { hostActionField } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { operationJson, setOperationJsonField, getOperationJsonField } = wizardState;
    const [newSelectedHostAction, setNewSelectedHostAction] = useState<keyof HostAction | null>(null);

    const currentActions = getOperationJsonField(hostActionField);
    const addHostAction = (hostAction: keyof HostAction) => {
        const currentHostActions = getOperationJsonField(hostActionField);
        setOperationJsonField(hostActionField, [
            ...currentHostActions,
            {
                [hostAction]: {},
            },
        ]);
    };

    const deleteHostAction = (hostActionIndex: number) => {
        const currentHostActions = getOperationJsonField(hostActionField);
        setOperationJsonField(
            hostActionField,
            currentHostActions.filter((_: any, index: number) => index !== hostActionIndex)
        );
    };

    const moveHostActionUp = (hostActionIndex: number) => {
        const currentHostActions = getOperationJsonField(hostActionField);
        const newHostActions = [...currentHostActions];
        const temp = newHostActions[hostActionIndex];
        newHostActions[hostActionIndex] = newHostActions[hostActionIndex - 1];
        newHostActions[hostActionIndex - 1] = temp;
        setOperationJsonField(hostActionField, newHostActions);
    };

    const moveHostActionDown = (hostActionIndex: number) => {
        const currentHostActions = getOperationJsonField(hostActionField);
        const newHostActions = [...currentHostActions];
        const temp = newHostActions[hostActionIndex];
        newHostActions[hostActionIndex] = newHostActions[hostActionIndex + 1];
        newHostActions[hostActionIndex + 1] = temp;
        setOperationJsonField(hostActionField, newHostActions);
    };

    const theme = useTheme();

    return (
        <Stack spacing={2}>
            {currentActions.map((a: HostAction, i: number) => {
                const config = getHostActionConfigFromKey(Object.keys(a)[0] as keyof HostAction);
                return (
                    <Card elevation={0} key={i}>
                        <Box p={2}>
                            <Stack direction={"row"} spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    <Stack spacing={0} alignItems={"center"}>
                                        <Tooltip title={"Move Action Up"}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => {
                                                    moveHostActionUp(i);
                                                }}
                                                disabled={i === 0}
                                            >
                                                <SvgIcon>
                                                    <IoIosArrowUp />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Avatar
                                            sx={{
                                                width: 24,
                                                height: 24,
                                                bgcolor: theme.palette.primary.main,
                                            }}
                                        >
                                            <Typography color={"white"} fontWeight={600}>
                                                {i + 1}
                                            </Typography>
                                        </Avatar>
                                        <Tooltip title={"Move Action Down"}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => {
                                                    moveHostActionDown(i);
                                                }}
                                                disabled={i === currentActions.length - 1}
                                            >
                                                <SvgIcon>
                                                    <IoIosArrowDown />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                    <Box>
                                        <Typography variant={"h6"}>{config.label}</Typography>
                                        <Typography>{config.description}</Typography>
                                    </Box>
                                </Stack>
                                <Stack direction={"row"}>
                                    <Tooltip title={"Remove Action"}>
                                        <IconButton
                                            size={"small"}
                                            onClick={() => {
                                                deleteHostAction(i);
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                            <Box pt={2}>{renderActionFields(config.type, hostActionField, i)}</Box>
                        </Box>
                    </Card>
                );
            })}
            <Box>
                <AddNewHostActionMenuButton hostActionField={hostActionField} />
            </Box>
        </Stack>
    );
};

// ======================
// AddNewHostActionMenuButton
// ======================

interface AddNewHostActionMenuButtonProps {
    hostActionField: Array<string>;
}

export const AddNewHostActionMenuButton: React.FC<AddNewHostActionMenuButtonProps> = (p) => {
    const dialogState = useDialogState();
    const { hostActionField } = p;
    const fullScreen = useShouldDialogFullScreen();
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    const addHostAction = (hostAction: keyof HostAction) => {
        const currentHostActions = getOperationJsonField(hostActionField);
        setOperationJsonField(hostActionField, [
            ...currentHostActions,
            {
                [hostAction]: {},
            },
        ]);
    };

    return (
        <>
            <Button variant={"contained"} disableElevation onClick={dialogState.open}>
                {"Add New User-Defined Action"}
            </Button>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} onClose={dialogState.close} maxWidth={"md"} fullWidth fullScreen={fullScreen}>
                    <DialogTopBar dialogState={dialogState} title={"Add New User-Defined Action"} />
                    <DialogContent>
                        <Typography>{"Select an action from the list below."}</Typography>
                        <List>
                            {hostActionOptions.map((a, i) => {
                                return (
                                    <DarkFlatCard sx={{ marginBottom: 1 }} key={i}>
                                        <ListItemButton
                                            onClick={() => {
                                                addHostAction(a.value as keyof HostAction);
                                                dialogState.close();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <a.icon size={24} />
                                            </ListItemIcon>
                                            <ListItemText primary={a.label} secondary={a.description} />
                                        </ListItemButton>
                                    </DarkFlatCard>
                                );
                            })}
                        </List>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

// ======================
// OsShellOptions
// ======================

interface HostActionOptionsProps {
    hostActionField: Array<string>;
    index: number;
}

export const OsShellOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    multiline={true}
                    fullWidth
                    variant={"filled"}
                    label={"Script"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "os_shell", "script"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "os_shell", "script"])}
                    minRows={5}
                />
                <FormHelperText>
                    {`These commands will be executed as a script. For windows, these will be run in PowerShell. For linux, these will be run in your OS's default shell. If no default shell is defined, /bin/sh will be used.`}
                </FormHelperText>
            </Box>
            <Box>
                <TextField
                    variant={"filled"}
                    label={"Timeout"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "os_shell", "timeout"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "os_shell", "timeout"])}
                    fullWidth
                />
                <FormHelperText>
                    {`Command timeout (e.g. "30s", "30ms", "30us"). Note that this timeout is applied to each command above, not overall.`}
                </FormHelperText>
            </Box>
            <Box>
                <TextField
                    variant={"filled"}
                    label={"User"}
                    fullWidth
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "os_shell", "user"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "os_shell", "user"])}
                />
                <FormHelperText>{`User to run the command as on linux.`}</FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// OsStopServiceOptions
// ======================

export const OsStopServiceOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Service"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "os_stop_service", "service"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "os_stop_service", "service"])}
                />
                <FormHelperText>{`The name of the service to stop.`}</FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// OsStartServiceOptions
// ======================

export const OsStartServiceOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Service"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "os_start_service", "service"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "os_start_service", "service"])}
                />
                <FormHelperText>{`The name of the service to start.`}</FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// StorageUnmountOption
// ======================

interface StorageUnmountOptionProps {}

export const StorageUnmountOption: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Mountpoint"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "storage_unmount", "mountpoint"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "storage_unmount", "mountpoint"])}
                />
                <FormHelperText>
                    {`Mountpoint of the volume. For Windows, the mountpoint must be a drive letter (e.g. "D:"). For Linux, the mountpoint must be an absolute path (e.g. "/mnt/data").`}
                </FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// WindowsMoveClusterGroupOptions
// ======================

export const WindowsMoveClusterGroupOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Resource Group"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_group", "resource_group"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_group", "resource_group"])}
                />
                <FormHelperText>{"The name of the resource group to move."}</FormHelperText>
            </Box>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Target Node Name"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_group", "target_node_name"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_group", "target_node_name"])}
                />
                <FormHelperText>
                    {`The name of the node to move the resource group to. If not specified, the resource group will be moved to another node in the cluster.`}
                </FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// WindowsMoveClusterSharedVolumeOptions
// ======================

export const WindowsMoveClusterSharedVolumeOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;

    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Volume Name"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_shared_volume", "volume_name"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_shared_volume", "volume_name"])}
                />
                <FormHelperText>{"The name of the cluster shared volume."}</FormHelperText>
            </Box>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Target Node Name"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_shared_volume", "target_node_name"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "windows_move_cluster_shared_volume", "target_node_name"])}
                />
                <FormHelperText>
                    {`The name of the node to move the volume to. If not specified, the volume will be moved to another node in the cluster.`}
                </FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// WindowsCsvSetRedirectedAccessOptions
// ======================

export const WindowsCsvSetRedirectedAccessOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;
    return (
        <Stack spacing={2}>
            <Box>
                <TextField
                    fullWidth
                    variant={"filled"}
                    label={"Volume Name"}
                    onChange={(e) => {
                        setOperationJsonField([...hostActionField, `${index}`, "windows_csv_set_redirected_access", "volume_name"], e.target.value);
                    }}
                    value={getOperationJsonField([...hostActionField, `${index}`, "windows_csv_set_redirected_access", "volume_name"])}
                />
                <FormHelperText>{"The name of the resource group to move."}</FormHelperText>
            </Box>
            <Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={getOperationJsonField([...hostActionField, `${index}`, "windows_csv_set_redirected_access", "redirected_access"])}
                            onChange={(e) => {
                                setOperationJsonField(
                                    [...hostActionField, `${index}`, "windows_csv_set_redirected_access", "redirected_access"],
                                    e.target.checked
                                );
                            }}
                        />
                    }
                    label={"Redirected Access"}
                />
                <FormHelperText>{"if turned on, the csv will be set to redirected access mode."}</FormHelperText>
            </Box>
        </Stack>
    );
};

// ======================
// SleepOptions
// ======================

export const SleepOptions: React.FC<HostActionOptionsProps> = (p) => {
    const { hostActionField, index } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const { setOperationJsonField, getOperationJsonField } = wizardState;
    return (
        <Box>
            <TextField
                fullWidth
                variant={"filled"}
                label={"Seconds"}
                onChange={(e) => {
                    setOperationJsonField([...hostActionField, `${index}`, "sleep", "seconds"], Number(e.target.value));
                }}
                type={"number"}
                value={getOperationJsonField([...hostActionField, `${index}`, "sleep", "seconds"])}
            />
        </Box>
    );
};

const renderActionFields = (hostAction: HostActionType, field: Array<string>, index: number) => {
    if (hostAction === HostActionType.OS_SHELL) {
        return <OsShellOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.OS_STOP_SERVICE) {
        return <OsStopServiceOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.OS_START_SERVICE) {
        return <OsStartServiceOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.STORAGE_RESCAN || hostAction === HostActionType.STORAGE_MOUNT_ALL) {
        return null;
    }
    if (hostAction === HostActionType.STORAGE_UNMOUNT) {
        return <StorageUnmountOption hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.WINDOWS_MOVE_CLUSTER_GROUP) {
        return <WindowsMoveClusterGroupOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.WINDOWS_MOVE_CLUSTER_SHARED_VOLUME) {
        return <WindowsMoveClusterSharedVolumeOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.WINDOWS_CSV_SET_REDIRECTED_ACCESS) {
        return <WindowsCsvSetRedirectedAccessOptions hostActionField={field} index={index} />;
    }
    if (hostAction === HostActionType.SLEEP) {
        return <SleepOptions hostActionField={field} index={index} />;
    }
};

const getHostActionConfigFromKey = (key: keyof HostAction) => {
    return hostActionOptions.find((o) => o.value === key);
};

const hostActionOptions = [
    {
        type: HostActionType.OS_SHELL,
        icon: BiTerminal,
        label: "OS Shell",
        value: "os_shell",
        description: "Run custom system scripts from operating system.",
    },
    {
        type: HostActionType.OS_START_SERVICE,
        icon: MdOutlinePlayCircle,
        label: "OS Start Service",
        value: "os_start_service",
        description: "Start OS System Service.",
    },
    {
        type: HostActionType.OS_STOP_SERVICE,
        icon: MdOutlineStopCircle,
        label: "OS Stop Service",
        value: "os_stop_service",
        description: "Stop OS System Service.",
    },
    {
        type: HostActionType.STORAGE_RESCAN,
        icon: RiQrScanFill,
        label: "Storage Rescan",
        value: "storage_rescan",
        description: "Rescan storage configuration.",
    },
    {
        type: HostActionType.STORAGE_UNMOUNT,
        icon: MdOutlineEject,
        label: "Storage Unmount",
        value: "storage_unmount",
        description: "Unmount Storage Volume. Mount point of the volume must be provided.",
    },
    {
        type: HostActionType.STORAGE_MOUNT_ALL,
        icon: MdOutlineSaveAlt,
        label: "Storage Mount All",
        value: "storage_mount_all",
        description:
            'Mount all volumes (Windows). Mount all disks with valid partitions discovered by the OS. After online disk will be mounted in Read-Write mode. (Linux) Mount all mount points defined in /etc/fstab. Equivalent to running "mount -a".',
    },
    {
        type: HostActionType.WINDOWS_MOVE_CLUSTER_GROUP,
        icon: AiOutlineCluster,
        label: "Windows Move Cluster Group",
        value: "windows_move_cluster_group",
        description: `Move Windows Cluster Group to another node within a Failover Cluster. This operation is only supported on Windows. It moves a clustered role (a resource group) from one node to another in a failover cluster.`,
    },
    {
        type: HostActionType.WINDOWS_MOVE_CLUSTER_SHARED_VOLUME,
        icon: HiOutlineServerStack,
        label: "Windows Move Cluster Shared Volume",
        value: "windows_move_cluster_shared_volume",
        description: `Move Windows Cluster Shared Volume to another node within a Failover Cluster. This operation is only supported on Windows. It moves a clustered shared volume from one node to another in a failover cluster.`,
    },
    {
        type: HostActionType.WINDOWS_CSV_SET_REDIRECTED_ACCESS,
        icon: BsFiletypeCsv,
        label: "Windows CSV Set Redirected Access",
        value: "windows_csv_set_redirected_access",
        description: `Set Windows Cluster Shared Volume to redirected access mode This operation is only supported on Windows. It sets a clustered shared volume (CSV) to redirected access mode.`,
    },
    {
        type: HostActionType.SLEEP,
        icon: RiZzzFill,
        label: "Sleep",
        value: "sleep",
        description: "Sleep for a specified amount of time.",
    },
];
