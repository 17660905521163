// Project: GalaxyComplete
// Created: 9/24/20 by sammy
// File: CurrentUserInfo

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../app/services";
import {
    Avatar,
    Box,
    ButtonBase,
    Chip,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    Stack,
    SvgIcon,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { PopoverState, usePopoverState } from "../../common/popover/PopoverService";
import { LicenseKeyIcon, UserSettingsIcon } from "../project/ProjectCommon";
import { LogOutIcon } from "../../common/CommonIcons";
import {
    getAuthenticatedUserFullName,
    getAuthenticatedUserFullNameFromObject,
    useNavigateToUserLicensePage,
    useNavigateToUserSettingsPage,
} from "../user/UserCommon";
import { useIsDesktop } from "./MainLayout";
import { AuthenticatedUserInfo } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineStar } from "react-icons/md";
import { HideInPrivateEdition } from "../auth/PrivateEditionView";
import { SiMicrosoftazure } from "react-icons/si";
import { useCurrentUser, useLogOut } from "../auth/auth_hooks";

// ======================
// CurrentUserInfo
// ======================

const useCurrentUserInfoStyles = () => {
    const t = useTheme();
    return {
        avatar: {
            backgroundColor: t.palette.secondary.main,
        },
    };
};

interface CurrentUserInfoProps {
    isDesktop: boolean;
}

export const CurrentUserInfo: React.FC<CurrentUserInfoProps> = observer((p) => {
    const styles = useCurrentUserInfoStyles();
    const userMenuState = usePopoverState();
    const theme = useTheme();

    const currentUser = useCurrentUser();
    if (!currentUser) {
        return null;
    }

    const avatarString = `${currentUser.firstName[0]}${currentUser.lastName[0]}`;

    const name = (
        <Box display={"flex"} alignItems={"center"}>
            {getAuthenticatedUserFullNameFromObject(currentUser)}
            &nbsp;
            {currentUser.activeAwsMpSub && <MdOutlineStar size={"1.15em"} color={"#ffc107"} />}
            {currentUser.activeAzureMpSub && <SiMicrosoftazure size={"1em"} color={theme.palette.primary.main} />}
        </Box>
    );

    return (
        <Box>
            <ButtonBase
                sx={{
                    borderRadius: 1,
                    padding: 1,
                    "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                    },
                }}
            >
                <Stack direction={"row"} spacing={2} alignItems={"center"} onClick={userMenuState.open}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Avatar color={"secondary"} sx={styles.avatar} src={`${currentUser.avatar}`}>
                            {avatarString}
                        </Avatar>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} justifyContent={"center"} ml={1}>
                            <Typography variant={"body2"}>{name}</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {currentUser.companyName}
                            </Typography>
                        </Box>
                    </Stack>

                    <IoIosArrowDown size={14} />
                </Stack>
            </ButtonBase>
            <UserPopoverMenu popoverState={userMenuState} currentUser={currentUser} />
        </Box>
    );
});

// ======================
// UserPopoverMenu
// ======================

interface UserPopoverMenuProps {
    popoverState: PopoverState;
    currentUser: AuthenticatedUserInfo.AsObject;
}

export const UserPopoverMenu: React.FC<UserPopoverMenuProps> = observer((props) => {
    const { popoverState, currentUser } = props;
    const styles = useCurrentUserInfoStyles();
    const navigateToUserSettingsPage = useNavigateToUserSettingsPage();
    const navigateToUserLicensePage = useNavigateToUserLicensePage();
    const isDesktop = useIsDesktop();
    const logOut = useLogOut();

    const goToUserSettings = () => {
        navigateToUserSettingsPage();
        popoverState.close();
    };

    const goToUserLicense = () => {
        navigateToUserLicensePage();
        popoverState.close();
    };

    return (
        <Popover
            anchorEl={popoverState.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={popoverState.isOpen}
            onClose={popoverState.close}
        >
            <Box>
                <List>
                    {!isDesktop && (
                        <>
                            <ListItem>
                                <ListItemText primary={getAuthenticatedUserFullNameFromObject(currentUser)} secondary={currentUser.companyName} />
                            </ListItem>
                            <Divider />
                        </>
                    )}
                    {currentUser.activeAwsMpSub && (
                        <>
                            <ListItem>
                                <ListItemText primary={"AWS Marketplace Subscription"} secondary={"Active Subscription"} />
                            </ListItem>
                            <Divider />
                        </>
                    )}

                    <ListItemButton onClick={goToUserSettings}>
                        <ListItemIcon>
                            <UserSettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"My Profile"} />
                    </ListItemButton>
                    <Divider />
                    <HideInPrivateEdition>
                        <ListItemButton onClick={goToUserLicense}>
                            <ListItemIcon>
                                <LicenseKeyIcon />
                            </ListItemIcon>
                            <ListItemText primary={"My License Key"} />
                        </ListItemButton>
                        <Divider />
                    </HideInPrivateEdition>
                    <ListItemButton onClick={() => logOut.mutate()}>
                        <ListItemIcon>
                            <LogOutIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Log Out"} />
                    </ListItemButton>
                </List>
            </Box>
        </Popover>
    );
});
