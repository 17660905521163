import * as React from "react";
import { ComponentType, ElementType } from "react";
import { ColumnDef, OnChangeFn, PaginationState } from "@tanstack/react-table";
import { QueryObserverResult } from "@tanstack/react-query";
import { EmptyTableConfig } from "./TableCommon";
import { ActionConfig } from "../actions/CommonActions";
export interface QueryTableProps<TData, TError> {
    tableComponent?: ComponentType<any> | ElementType;
    pagination?: PaginationState;
    setPagination?: OnChangeFn<PaginationState>;
    pageCount?: number;
    data: Array<TData>;
    isLoading?: boolean;
    isError?: boolean;
    columns: ColumnDef<TData, any>[];
    title?: string;
    emptyTableConfig?: EmptyTableConfig;
    error?: TError;
    refetch?: () => Promise<QueryObserverResult<any, TError>>;
    hiddenColumns?: string[];
    queryKey?: string;
}

export const getPageSelectionList = (totalPages: number) => {
    const pageList = [];
    if (totalPages === 0) {
        return [1];
    }
    for (let i = 1; i <= totalPages; i++) {
        pageList.push(i);
    }
    return pageList;
};
