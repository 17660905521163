// ======================
// CmoMigrationSessionsToggleButton
// ======================

import React, { useState } from "react";
import { Theme, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { MdOutlineList } from "react-icons/md";
import { BiGridHorizontal } from "react-icons/bi";
import { CmoMigrationSessionsListTable } from "./CmoMigrationSessionsListTable";
import { CmoMigrationSessionsListGrid } from "./CmoMigrationSessionsListGrid";

interface CmoMigrationSessionsToggleButtonProps {
    view: "list" | "grid";
    setView: (view: "list" | "grid") => void;
}

export const CmoMigrationSessionsToggleButton: React.FC<CmoMigrationSessionsToggleButtonProps> = (p) => {
    const { view, setView } = p;

    return (
        <ToggleButtonGroup
            exclusive
            value={view}
            onChange={(e, value) => {
                setView(value);
            }}
        >
            <BlackTooltip arrow title={"Grid View"}>
                <ToggleButton value={"grid"} sx={{ borderColor: (t: Theme) => t.palette.border.main }}>
                    <BiGridHorizontal size={20} />
                </ToggleButton>
            </BlackTooltip>
            <BlackTooltip arrow title={"List View"}>
                <ToggleButton value={"list"} sx={{ borderColor: (t: Theme) => t.palette.border.main }}>
                    <MdOutlineList size={20} />
                </ToggleButton>
            </BlackTooltip>
        </ToggleButtonGroup>
    );
};

// ======================
// CmoMigrationSessionsToggleView
// ======================

interface CmoMigrationSessionsToggleViewProps {
    view: "list" | "grid";
    projectId: string;
    systemId?: string;
}

export const CmoMigrationSessionsToggleView: React.FC<CmoMigrationSessionsToggleViewProps> = (p) => {
    const { projectId, view, systemId } = p;
    return view === "list" ? (
        <CmoMigrationSessionsListTable systemId={systemId} projectId={projectId} />
    ) : (
        <CmoMigrationSessionsListGrid systemId={systemId} projectId={projectId} />
    );
};
