// ======================
// TransactionHistoryTable
// ======================

import { ListProjectLicenseTransactions } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { ListUserLicenseTransactions } from "gc-web-proto/galaxycompletepb/apipb/auth_api_pb";
import { ColumnDef, DataTable, TableState } from "../../common/table/DataTable";
import { TransactionCounterInfo, TransactionDetails, VaultCounterInfo, VaultDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import { observer } from "mobx-react-lite";
import { LicenseVaultTransactionStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_status_pb";
import { LicenseVaultTransactionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_type_pb";
import { convertTimestampObjectToDate, formatKnownDataType, formatTitleCase, KnownDataType } from "../../common/utils/formatter";
import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Radio,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import { getUserFullNameFromObject } from "../settings/ProjectUsers";
import { TruncatedText } from "../../common/text/TruncatedText";
import { TooltipText } from "../../common/text/TooltipText";
import { AppHintID, HintButton, KnownArticle } from "../help/HelpCommon";
import { ExportLicenseButton } from "./LicenseExport";
import { useAppServices } from "../app/services";
import { renderServerDataWithLoadingBox } from "../core/data/DataLoaderHooks";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { BackButton } from "../../common/CommonButtons";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { MdFileCopy, MdOutlineCardGiftcard, MdRefresh } from "react-icons/md";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiCreditCard } from "react-icons/fi";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../core/dialog/DialogService";
import { DialogTopBar } from "../core/dialog/DialogComponents";
import xbytes from "xbytes";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";
import { FcCheckmark } from "react-icons/fc";
import { useIsDesktop } from "../layout/MainLayout";
import { getCounterTypeDisplayValue, getLicenseTransactionTypeDisplayValue, getLicenseValueFormat, useNavigateToPurchaseLicenseScreen } from "./LicenseCommon";
import { HideInPrivateEdition, PrivateEditionView } from "../auth/PrivateEditionView";
import { TabConfig, TabGroup } from "../../common/tabs/TabComponents";
import { useOpenHelpArticle } from "../help/hooks/help_hooks";
import { PrivateEditionLicenseInfoSection, PrivateEditionPendingLicenseSection } from "../privateEdition/PrivateEditionLocalLicenseManagementComponents";
import { useIsSupportUser } from "../support/SupportCommon";
import { useCreateProjectLicenseReport } from "../reports/report_hooks";
import { CreateProjectLicenseReport } from "gc-web-proto/galaxycompletepb/apipb/report_api_pb";
import { useCurrentProjectID } from "../project/CurrentProjectState";
import { viewReport } from "../reports/ReportHelpers";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { useCurrentUser } from "../auth/auth_hooks";
import Grid from "@mui/material/Grid2";

const getLicenseScreenActions = (data: VaultDetails.AsObject, type: "user" | "project", isSupportUser: boolean, isDesktop: boolean) => {
    return (
        <Box display={"flex"}>
            <HideInPrivateEdition>
                {isDesktop && (
                    <Box pr={2}>
                        <HintButton hintID={AppHintID.LICENSE_KEY} />
                    </Box>
                )}
                {type === "project" ? (
                    <Box display={"flex"}>
                        {isSupportUser && (
                            <Box pr={2}>
                                <ExportLicenseButton vaultDetails={data} />
                            </Box>
                        )}
                        <Box>
                            <CreateLicenseReportButton />
                        </Box>
                    </Box>
                ) : (
                    <ExportLicenseButton vaultDetails={data} />
                )}
            </HideInPrivateEdition>
        </Box>
    );
};

// ======================
// CreateLicenseReportButton
// ======================

interface CreateLicenseReportButtonProps {}

export const CreateLicenseReportButton: React.FC<CreateLicenseReportButtonProps> = (p) => {
    const projectId = useCurrentProjectID();
    const globalDialogState = useGlobalDialogState();

    const createLicenseReport = useCreateProjectLicenseReport({
        onSuccess: async (data: CreateProjectLicenseReport.Response.AsObject) => {
            await globalDialogState.addConfirmDialog({
                title: "New Report Generated",
                message: <>{"Project License Report has been generated successfully."}</>,
                onClose: (confirmed) => {
                    if (confirmed) {
                        viewReport(data.reportId);
                    }
                },
                okButtonLabel: "View Report",
                cancelButtonLabel: "Close",
                autoConfirmationQuestionLine: false,
            });
        },
    });
    const handleClick = async () => {
        const req = new CreateProjectLicenseReport.Request().setProjectId(projectId);
        await createLicenseReport.mutateAsync(req);
    };
    return (
        <Button variant={"contained"} color={"secondary"} onClick={handleClick}>
            Create License Report
        </Button>
    );
};

// ======================
// LicenseKeyMainScreen
// ======================

interface LicenseKeyMainScreenProps {
    type: "user" | "project";
    backButtonNavFunction: () => void;
    refresh: () => Promise<any>;
    isPrivateEdition?: boolean;
}

export const LicenseKeyMainScreen: React.FC<LicenseKeyMainScreenProps> = observer((p) => {
    const isDesktop = useIsDesktop();
    const vaultSerialId = "vault-serial";
    const { licenseService } = useAppServices();
    const vaultDetailsServerData = p.type === "user" ? licenseService.userLicenseVaultDetails : licenseService.projectLicenseDetails;
    const isSupportUser = useIsSupportUser();

    return renderServerDataWithLoadingBox(vaultDetailsServerData, (data) => {
        const tabConfigs: TabConfig[] = [
            {
                label: "License Key Balance",
                renderer: () => <LicenseKeyBalanceSection itemList={data.itemsList} />,
            },
            {
                label: "Transaction History",
                renderer: () => <TransactionHistorySection type={p.type} />,
            },
        ];
        return (
            <ScreenContainer>
                <BackButton navFunction={p.backButtonNavFunction} label={p.type === "user" ? "Exit User License" : "Back To Project Details"} />
                {isDesktop ? (
                    <ScreenTitleBar
                        title={
                            <ClipboardText variant={"h3"} clipboardId={vaultSerialId}>
                                {data.identity.vaultSerial}
                            </ClipboardText>
                        }
                        actions={getLicenseScreenActions(data, p.type, isSupportUser, isDesktop)}
                    />
                ) : (
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pb={2}>
                        <Box>
                            <ClipboardText variant={"h4"} clipboardId={vaultSerialId}>
                                {data.identity.vaultSerial}
                            </ClipboardText>
                        </Box>

                        <Box>
                            <HintButton hintID={AppHintID.LICENSE_KEY} />
                        </Box>
                    </Box>
                )}

                <Box pb={2}>
                    <ButtonGroup>
                        <Button startIcon={<MdRefresh />} onClick={p.refresh}>
                            Refresh
                        </Button>
                        <ClipboardButton startIcon={<MdFileCopy />} clipboardId={vaultSerialId} label={"Copy License Key"} />
                    </ButtonGroup>
                </Box>
                {!isDesktop && (
                    <Box display={"flex"} justifyContent={"center"}>
                        {getLicenseScreenActions(data, p.type, isSupportUser, isDesktop)}
                    </Box>
                )}
                {p.type === "user" && <PurchaseLicenseSection />}
                <br />
                <PrivateEditionView>
                    <PrivateEditionPendingLicenseSection />
                    <PrivateEditionLicenseInfoSection />
                </PrivateEditionView>
                <TabGroup configs={tabConfigs} />
            </ScreenContainer>
        );
    });
});

// ======================
// LicenseKeyBalanceSection
// ======================

interface LicenseKeyBalanceSectionProps {
    itemList: VaultCounterInfo.AsObject[];
}

const LicenseKeyBalanceSection: React.FC<LicenseKeyBalanceSectionProps> = observer((p) => {
    const renderEmptyState = () => {
        if (p.itemList.length === 0) {
            return (
                <Card>
                    <Box p={4} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        This license key is currently empty.
                    </Box>
                </Card>
            );
        }
    };
    const renderBalance = (i: VaultCounterInfo.AsObject) => {
        return (
            <Box pb={2}>
                <Card key={i.counterId}>
                    <Box p={2}>
                        <Grid container spacing={2} alignItems={"center"}>
                            <Grid size={6}>{getCounterTypeDisplayValue(i.counterType.value)}</Grid>
                            <Grid size={3}>
                                <Typography variant={"h6"}>{getLicenseValueFormat(i.currentBalance, i.counterType.value)}</Typography>
                                <Typography variant={"overline"}>Amount Remaining</Typography>
                            </Grid>
                            <Grid size={3}>
                                <Typography variant={"h6"}>{getLicenseValueFormat(i.totalConsumed, i.counterType.value)}</Typography>
                                <Typography variant={"overline"}>Total Consumed</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
        );
    };
    return (
        <Box pb={2}>
            <Typography variant={"h6"}>License Key Balance</Typography>
            <Typography variant={"body2"}>Contains all licenses currently owned by this license key</Typography>
            <br />
            {renderEmptyState()}
            {p.itemList.map((i, index) => (
                <Box key={index}>{renderBalance(i)}</Box>
            ))}
        </Box>
    );
});

interface TransactionHistorySectionProps {
    type: "user" | "project";
}

const TransactionHistorySection: React.FC<TransactionHistorySectionProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const transactionServerData = p.type === "user" ? licenseService.userLicenseTransactions : licenseService.projectLicenseTransactions;

    return (
        <Box>
            <Typography variant={"h6"}>Transaction History</Typography>
            <Typography variant={"body2"}>Contains all transaction information involving this license key</Typography>
            <br />
            <TransactionHistoryTable
                currentLicense={licenseService.currentLicenseSerial}
                tableState={transactionServerData.tableState}
                loading={transactionServerData.loading}
                onTableStateChange={async () => await transactionServerData.fetchData()}
                data={transactionServerData.data}
            />
        </Box>
    );
});

interface TransactionHistoryTableProps {
    onTableStateChange: (state: TableState<any>) => void;
    currentLicense: string;
    loading: boolean;
    data: ListProjectLicenseTransactions.Response.AsObject | ListUserLicenseTransactions.Response.AsObject;
    tableState: TableState<TransactionDetails.AsObject>;
}

const TransactionHistoryTable: React.FC<TransactionHistoryTableProps> = observer((p) => {
    const theme = useTheme();
    const getStatusFromValue = (value: any) => {
        for (let key in LicenseVaultTransactionStatus.LicenseVaultTransactionStatus) {
            if (LicenseVaultTransactionStatus.LicenseVaultTransactionStatus[key] === value) {
                return formatTitleCase(key);
            }
        }
    };

    const cols: ColumnDef<TransactionDetails.AsObject>[] = [
        {
            id: "from",
            label: "From/To",
            getter: (r) => r,
            renderer: (r, v) => {
                if (v.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.TRANSFER) {
                    return (
                        <Box>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {v.toVault?.vaultSerial !== p.currentLicense ? "To:" : "From:"}
                            </Typography>
                            <Typography
                                variant={"body1"}
                                sx={{ color: v.toVault?.vaultSerial !== p.currentLicense ? theme.palette.error.main : theme.palette.success.main }}
                            >
                                {v.toVault?.vaultSerial !== p.currentLicense ? v.toVault?.vaultSerial : v.fromVault?.vaultSerial}
                            </Typography>
                        </Box>
                    );
                } else {
                    return (
                        <Box>
                            <Typography variant={"body2"} color={"textSecondary"}>
                                {v.toVault?.vaultSerial ? "To:" : "From:"}
                            </Typography>
                            <Typography variant={"body1"}>{v.toVault?.vaultSerial || v.fromVault?.vaultSerial}</Typography>
                        </Box>
                    );
                }
            },
        },
        {
            id: "description",
            label: "Description",
            getter: (r) => r.description,
            renderer: (r, v) => {
                return r ? <TruncatedText characterLimit={40} text={r} /> : "N/A";
            },
        },
        {
            id: "amount",
            label: "Amount",
            getter: (r) => r.itemsList,
            renderer: (r: Array<TransactionCounterInfo.AsObject>, v) => {
                return r.map((counter, i) => {
                    return getTransactionDisplayAmountByType(counter, v, p.currentLicense, theme);
                });
            },
        },
        {
            id: "status",
            label: "Status",
            getter: (r) => r,
            renderer: (_, v) => {
                return (
                    <>
                        <Typography>{getStatusFromValue(v.status.value)}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {getLicenseTransactionTypeDisplayValue(v.type.value)}
                        </Typography>
                    </>
                );
            },
        },
        {
            id: "created",
            label: "Created",
            getter: (r) => r,
            renderer: (r: TransactionDetails.AsObject) => {
                return (
                    <>
                        <Typography>{formatKnownDataType(convertTimestampObjectToDate(r.createdAt), KnownDataType.DATE)}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {r.createdByUser ? `by ${getUserFullNameFromObject(r.createdByUser)}` : null}
                        </Typography>
                    </>
                );
            },
        },
    ];
    return (
        <Card>
            <DataTable
                state={p.tableState}
                pagerMeta={p.data?.pagerMeta}
                rows={p.data?.itemsList}
                loading={p.loading}
                onTableStateChange={p.onTableStateChange}
                cols={cols}
            />
        </Card>
    );
});

interface PurchaseLicenseSectionProps {}

const PurchaseLicenseSection: React.FC<PurchaseLicenseSectionProps> = observer((p) => {
    const { licenseService } = useAppServices();
    const goToPurchaseScreen = useNavigateToPurchaseLicenseScreen();
    const addCreditFromSubscriptionDialogState = useDialogState();
    const isDesktop = useIsDesktop();
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return null;
    }

    if (currentUser.activeAwsMpSub) {
        return (
            <Box>
                <Typography variant={"h6"}>Billing</Typography>
                <Typography variant={"body2"}>Active Billing Method & additional license top-up</Typography>
                <br />
                <Card
                    sx={{
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "2px solid",
                        borderColor: (t: Theme) => t.palette.cirrus.light,
                    }}
                >
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 9,
                                }}
                            >
                                <Typography variant={"h5"} sx={{ color: (t: Theme) => t.palette.primary.light }}>
                                    AWS Marketplace Subscription
                                </Typography>
                                <Typography variant={"overline"}>Current Billing Method</Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 3,
                                }}
                            >
                                <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"}>
                                    <Button variant={"contained"} onClick={addCreditFromSubscriptionDialogState.open}>
                                        Purchase Migration Credit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {addCreditFromSubscriptionDialogState.isOpen && <AddCreditFromAwsMarketplaceDialog dialogState={addCreditFromSubscriptionDialogState} />}
            </Box>
        );
    }

    if (currentUser.activeAzureMpSub) {
        return (
            <Box>
                <Typography variant={"h6"}>Billing</Typography>
                <Typography variant={"body2"}>Active Billing Method & additional license top-up</Typography>
                <br />
                <Card
                    sx={{
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "2px solid",
                        borderColor: (t: Theme) => t.palette.cirrus.light,
                    }}
                >
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 9,
                                }}
                            >
                                <Typography variant={"h5"} sx={{ color: (t: Theme) => t.palette.primary.light }}>
                                    Microsoft Azure Marketplace Subscription
                                </Typography>
                                <Typography variant={"subtitle1"}>
                                    Your user account is currently connected to a Microsoft Azure Marketplace Subscription. Select "Purchase Migration Credit"
                                    to purchase migration license via your Azure subscription.
                                </Typography>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 3,
                                }}
                            >
                                <Box display={"flex"} justifyContent={isDesktop ? "flex-end" : "center"} pt={isDesktop ? 0 : 2}>
                                    <Button variant={"contained"} onClick={addCreditFromSubscriptionDialogState.open}>
                                        Add Migration Credit
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {addCreditFromSubscriptionDialogState.isOpen && <AddCreditFromAzureMarketplaceDialog dialogState={addCreditFromSubscriptionDialogState} />}
            </Box>
        );
    }
    const onManage = async () => {
        const res = await licenseService.startBillingManagementSession();
        const url = res.getUrl();
        window.location.replace(url);
    };
    return (
        <Box>
            <Typography variant={"h6"}>Billing</Typography>
            <Typography variant={"body2"}>Purchase additional licenses/subscriptions or manage your billing settings</Typography>
            <br />
            <Grid container spacing={2} justifyContent={isDesktop ? "flex-start" : "center"}>
                <Grid>
                    <Button startIcon={<AiOutlineShoppingCart />} variant={"contained"} onClick={goToPurchaseScreen}>
                        {"Purchase License"}
                    </Button>
                </Grid>
                <Grid>
                    <LicenseRedemptionButton />
                </Grid>
                <Grid>
                    <Button startIcon={<FiCreditCard />} variant={"outlined"} onClick={onManage}>
                        {"Billing Management"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
});

// ======================
// LicenseRedemptionButton
// ======================

interface LicenseRedemptionButtonProps {}

export const LicenseRedemptionButton: React.FC<LicenseRedemptionButtonProps> = observer((p) => {
    const dialogState = useDialogState();
    const { licenseService, dialogService, helpService } = useAppServices();
    const [redemptionCode, setRedemptionCode] = useState("");
    const openHelpArticle = useOpenHelpArticle();

    const redeemCode = async () => {
        const res = await licenseService.redeemCode(redemptionCode);
        if (!!res) {
            await dialogService.addAlertDialog({
                title: "Successfully Redeemed Code",
                message: (
                    <Box>
                        <Box display={"flex"} pb={2} alignItems={"center"}>
                            <FcCheckmark />
                            <Box pl={2}>{res.getMessage()}</Box>
                        </Box>
                        <Card
                            elevation={0}
                            sx={{
                                backgroundColor: "rgba(0,0,0,0)",
                                border: "2px solid",
                                borderColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                        >
                            <CardContent>
                                Learn how to transfer license credits to projects.
                                <br />
                                <Box pt={2}>
                                    <Button variant={"outlined"} color={"neutral"} onClick={() => openHelpArticle(KnownArticle.TRANSFER_LICENSE_CREDITS)}>
                                        Learn More
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                ),
                okButtonLabel: "Close",
            });
            await licenseService.userLicenseVaultDetails.fetchData();
            await licenseService.userLicenseTransactions.fetchData();
            dialogState.close();
        }
    };

    return (
        <>
            <Button variant={"outlined"} startIcon={<MdOutlineCardGiftcard />} onClick={dialogState.open}>
                Redeem License
            </Button>
            {dialogState.isOpen && (
                <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullWidth maxWidth={"sm"}>
                    <DialogTopBar dialogState={dialogState} title={"Redeem License"} actions={[<HintButton articleID={KnownArticle.REDEEM_LICENSE} />]} />
                    <DialogContent>
                        <Box pb={2}>Enter your license redemption code below.</Box>
                        <TextField variant={"outlined"} label={"Redemption Code"} value={redemptionCode} onChange={(e) => setRedemptionCode(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Box pb={2} pr={1}>
                            <Button color={"neutral"} variant={"outlined"} onClick={dialogState.close}>
                                Cancel
                            </Button>
                        </Box>
                        <Box pb={2} pr={2}>
                            <Button variant={"contained"} onClick={redeemCode}>
                                Redeem
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
});

// ======================
// AddCreditFromAzureMarketplaceDialog
// ======================

interface AddCreditFromAzureMarketplaceDialogProps {
    dialogState: DialogState;
}

const AddCreditFromAzureMarketplaceDialog: React.FC<AddCreditFromAzureMarketplaceDialogProps> = observer((p) => {
    const [capacityQuotaAddOnAmount, setCapacityQuotaAddOnAmount] = useState(0);
    const [hostMigrationLicenseAmount, setHostMigrationLicenseAmount] = useState(0);
    const [hostMigrationLicenseExtensionAmount, setHostMigrationLicenseExtensionAmount] = useState(0);
    const theme = useTheme();
    const [selectedCounterType, setSelectedCounterType] = useState<LicenseVaultCounterType.LicenseVaultCounterType>(null);
    const openHelpArticle = useOpenHelpArticle();

    const { dialogService, licenseService } = useAppServices();

    const marketplaceName = `Azure Marketplace`;

    const getAmountError = (amount: number, counterType: LicenseVaultCounterType.LicenseVaultCounterType) => {
        if (selectedCounterType === counterType) {
            if (isNaN(amount) || !Number.isInteger(amount) || amount <= 0) {
                return "Amount must be a positive integer.";
            } else if (amount > 100) {
                return "Maximum of 100 licenses allowed.";
            } else {
                return null;
            }
        }
        return null;
    };

    const onConfirm = async () => {
        const message = `By continuing,  migration license will be added to your license key from your ${marketplaceName} Subscription. All billing & usage information will be available in ${marketplaceName}.`;
        const confirmed = await dialogService.addConfirmDialog({
            message: message,
            autoConfirmationQuestionLine: false,
            title: "Purchase Confirmation",
        });
        if (confirmed) {
            p.dialogState.close();
            const amount = [hostMigrationLicenseAmount, hostMigrationLicenseExtensionAmount, capacityQuotaAddOnAmount].find((a) => a > 0);
            const success = await licenseService.addAzureMigrationCredit(amount, selectedCounterType);
            if (success) {
                await licenseService.userLicenseVaultDetails.fetchData();
                await licenseService.userLicenseTransactions.fetchData();
                await dialogService.addAlertDialog({
                    title: "Purchase Migration Credit – Success",
                    renderAdditionalContent: () => {
                        return (
                            <Box>
                                <Box display={"flex"} pb={2} alignItems={"center"}>
                                    <FcCheckmark />
                                    <Box pl={2}>
                                        Migration credit has been added to your Cirrus Data Cloud user license key from your {marketplaceName} subscription
                                        successfully!
                                    </Box>
                                </Box>
                                <Card
                                    elevation={0}
                                    sx={{
                                        backgroundColor: "rgba(0,0,0,0)",
                                        border: "2px solid",
                                        borderColor: (t: Theme) => t.palette.cirrus.main,
                                    }}
                                >
                                    <CardContent>
                                        To start migrating, you can transfer your migration credits to any of your projects. Click below to see how.
                                        <br />
                                        <Box pt={2}>
                                            <Button
                                                variant={"outlined"}
                                                color={"neutral"}
                                                onClick={() => openHelpArticle(KnownArticle.TRANSFER_LICENSE_CREDITS)}
                                            >
                                                Learn More
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        );
                    },
                    okButtonLabel: "Back to My License Page",
                });
            }
        }
    };

    const getConfirmDisabled = () => {
        const allValues = [hostMigrationLicenseAmount, hostMigrationLicenseExtensionAmount, capacityQuotaAddOnAmount].reduce(
            (accumulator, currentValue) => accumulator + currentValue
        );
        return allValues === 0 || allValues > 100;
    };

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={"md"} fullScreen={useShouldDialogFullScreen()}>
            <DialogTopBar dialogState={p.dialogState} title={`Purchase Migration Credit from ${marketplaceName} Subscription`} divider />
            <DialogContent>
                <Card sx={{ backgroundColor: theme.palette.cirrus.main }}>
                    <CardContent>
                        <Box display={"flex"} alignItems={"center"} width={"100%"}>
                            <Radio
                                color={"secondary"}
                                checked={selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE}
                                onClick={() => {
                                    setSelectedCounterType(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE);
                                    setHostMigrationLicenseAmount(1);
                                    setCapacityQuotaAddOnAmount(0);
                                    setHostMigrationLicenseExtensionAmount(0);
                                }}
                            />
                            <Box pl={2} width={"100%"}>
                                <Typography variant={"h6"}>Host Migration License</Typography>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    A Host Migration License is consumed from the project and added to the host. This license remains active for 60 days, and
                                    comes with 2TiB of Migration Capacity Quota.
                                </Typography>
                                {selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE && (
                                    <Box display={"flex"} pt={3}>
                                        <TextField
                                            label={"Host Migration License"}
                                            value={hostMigrationLicenseAmount}
                                            type={"number"}
                                            error={
                                                !!getAmountError(
                                                    hostMigrationLicenseAmount,
                                                    LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE
                                                )
                                            }
                                            helperText={getAmountError(
                                                hostMigrationLicenseAmount,
                                                LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE
                                            )}
                                            fullWidth
                                            disabled={selectedCounterType !== LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE}
                                            onChange={(e) => setHostMigrationLicenseAmount(Number(e.target.value))}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <br />
                <Card sx={{ backgroundColor: theme.palette.cirrus.main }}>
                    <CardContent>
                        <Box display={"flex"} alignItems={"center"} width={"100%"}>
                            <Radio
                                color={"secondary"}
                                checked={selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION}
                                onClick={() => {
                                    setSelectedCounterType(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION);
                                    setHostMigrationLicenseExtensionAmount(1);
                                    setHostMigrationLicenseAmount(0);
                                    setCapacityQuotaAddOnAmount(0);
                                }}
                            />
                            <Box pl={2} width={"100%"}>
                                <Typography variant={"h6"}>Host Migration License Extension (30d)</Typography>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    Extend the existing host's license for 30 days from the previous expiration date.
                                </Typography>
                                {selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION && (
                                    <Box display={"flex"} pt={3}>
                                        <TextField
                                            label={"Host Migration License Extension (30d)"}
                                            value={hostMigrationLicenseExtensionAmount}
                                            type={"number"}
                                            error={
                                                !!getAmountError(
                                                    hostMigrationLicenseExtensionAmount,
                                                    LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION
                                                )
                                            }
                                            helperText={getAmountError(
                                                hostMigrationLicenseExtensionAmount,
                                                LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION
                                            )}
                                            fullWidth
                                            disabled={
                                                selectedCounterType !== LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE30_DAYS_EXTENSION
                                            }
                                            onChange={(e) => setHostMigrationLicenseExtensionAmount(Number(e.target.value))}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
                <br />
                <Card sx={{ backgroundColor: theme.palette.cirrus.main }}>
                    <CardContent>
                        <Box display={"flex"} alignItems={"center"} width={"100%"}>
                            <Radio
                                color={"secondary"}
                                checked={selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION}
                                onClick={() => {
                                    setSelectedCounterType(LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION);
                                    setCapacityQuotaAddOnAmount(1);
                                    setHostMigrationLicenseExtensionAmount(0);
                                    setHostMigrationLicenseAmount(0);
                                }}
                            />
                            <Box pl={2} width={"100%"}>
                                <Typography variant={"h6"}>Migration Capacity Quota Add-On (1T)</Typography>
                                <Typography variant={"body2"} color={"textSecondary"}>
                                    {`Increase Migration Capacity Quota of an activated Host Migration License by 1 TiB`}
                                </Typography>
                                {selectedCounterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION && (
                                    <Box display={"flex"} pt={3}>
                                        <TextField
                                            label={"Migration Capacity Quota Add-On (1T)"}
                                            value={capacityQuotaAddOnAmount}
                                            type={"number"}
                                            error={
                                                !!getAmountError(
                                                    capacityQuotaAddOnAmount,
                                                    LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION
                                                )
                                            }
                                            helperText={getAmountError(
                                                capacityQuotaAddOnAmount,
                                                LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION
                                            )}
                                            fullWidth
                                            disabled={
                                                selectedCounterType !==
                                                LicenseVaultCounterType.LicenseVaultCounterType.HOST_MIGRATION_LICENSE1_TB_CAPACITY_EXTENSION
                                            }
                                            onChange={(e) => setCapacityQuotaAddOnAmount(Number(e.target.value))}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>

                <Box pt={2}>
                    <Alert severity={"info"}>
                        By continuing, the above amount of migration license will be added to your license key from your {marketplaceName} Subscription. All
                        billing & usage information will be available in {marketplaceName}.
                    </Alert>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box pb={2} pr={2} display={"flex"}>
                    <Box pr={2}>
                        <Button variant={"outlined"} color={"neutral"} onClick={p.dialogState.close}>
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button variant={"contained"} onClick={onConfirm} disabled={getConfirmDisabled()}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
});

// ======================
// AddCreditFromMarketplaceDialog
// ======================

interface AddCreditFromAwsMarketplaceDialogProps {
    dialogState: DialogState;
}

const AddCreditFromAwsMarketplaceDialog: React.FC<AddCreditFromAwsMarketplaceDialogProps> = observer((p) => {
    const unit = "TiB";
    const [currentDisplayAmount, setCurrentDisplayAmount] = useState(1);
    const [currentAmountInBytes, setCurrentAmountInBytes] = useState(xbytes.parseSize(`1 TiB`));
    const openHelpArticle = useOpenHelpArticle();

    const { dialogService, licenseService } = useAppServices();

    const marketplaceName = `AWS Marketplace`;

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let displayAmount = parseFloat(e.target.value);
        let convertedAmount = xbytes.parseSize(`${displayAmount}${unit}`);

        setCurrentDisplayAmount(displayAmount);
        setCurrentAmountInBytes(convertedAmount);
    };

    const getAmountError = () => {
        if (isNaN(currentDisplayAmount) || !Number.isInteger(currentDisplayAmount) || currentDisplayAmount <= 0) {
            return "Amount must be a positive integer.";
        } else {
            return null;
        }
    };

    const onConfirm = async () => {
        const message = `By continuing, ${currentDisplayAmount} ${unit} of migration license will be added to your license key from your ${marketplaceName} Subscription. All billing & usage information will be available in ${marketplaceName}.`;
        const confirmed = await dialogService.addConfirmDialog({
            message: message,
            autoConfirmationQuestionLine: false,
            title: "Purchase Confirmation",
        });
        if (confirmed) {
            p.dialogState.close();
            const success = await licenseService.addAwsMigrationCredit(currentAmountInBytes);

            if (success) {
                await licenseService.userLicenseVaultDetails.fetchData();
                await licenseService.userLicenseTransactions.fetchData();
                await dialogService.addAlertDialog({
                    title: "Purchase Migration Credit – Success",
                    renderAdditionalContent: () => {
                        return (
                            <Box>
                                <Box display={"flex"} pb={2} alignItems={"center"}>
                                    <FcCheckmark />
                                    <Box pl={2}>
                                        Migration credit has been added to your Cirrus Data Cloud user license key from your {marketplaceName} subscription
                                        successfully!
                                    </Box>
                                </Box>
                                <Card
                                    elevation={0}
                                    sx={{
                                        backgroundColor: "rgba(0,0,0,0)",
                                        border: "2px solid",
                                        borderColor: (t: Theme) => t.palette.cirrus.main,
                                    }}
                                >
                                    <CardContent>
                                        To start migrating, you can transfer your migration credits to any of your projects. Click below to see how.
                                        <br />
                                        <Box pt={2}>
                                            <Button
                                                variant={"outlined"}
                                                color={"neutral"}
                                                onClick={() => openHelpArticle(KnownArticle.TRANSFER_LICENSE_CREDITS)}
                                            >
                                                Learn More
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        );
                    },
                    okButtonLabel: "Back to My License Page",
                });
            }
        }
    };

    const recommendationOptions = [1, 25, 50, 100, 250, 500, 1000];

    return (
        <Dialog open={p.dialogState.isOpen} onClose={p.dialogState.close} fullWidth maxWidth={"md"}>
            <DialogTopBar dialogState={p.dialogState} title={`Purchase Migration Credit from ${marketplaceName} Subscription`} divider />
            <DialogContent>
                <Typography variant={"h6"}>Cirrus Migrate Cloud – Local Migration</Typography>
                <Box display={"flex"} pt={2}>
                    <TextField
                        label={"Local Migration Capacity"}
                        InputProps={{ endAdornment: "TiB" }}
                        value={currentDisplayAmount}
                        type={"number"}
                        error={!!getAmountError()}
                        helperText={getAmountError()}
                        fullWidth
                        onChange={handleChangeAmount}
                    />
                </Box>
                <Box pt={2}>
                    <Typography variant={"body2"}>Instant Recommendation:</Typography>
                    <Box display={"flex"} pt={1}>
                        {recommendationOptions.map((o) => {
                            const byteString = `${o} TiB`;
                            const onSelect = () => {
                                setCurrentAmountInBytes(xbytes.parseSize(byteString));
                                setCurrentDisplayAmount(o);
                            };
                            return (
                                <Box pr={1}>
                                    <Chip
                                        size={"small"}
                                        onClick={onSelect}
                                        label={byteString}
                                        color={xbytes.parseSize(byteString) === currentAmountInBytes ? "primary" : "default"}
                                    />
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
                <Box pt={2}>
                    <Alert severity={"info"}>
                        By continuing, the above amount of migration license will be added to your license key from your {marketplaceName} Subscription. All
                        billing & usage information will be available in {marketplaceName}.
                    </Alert>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box pb={2} pr={2} display={"flex"}>
                    <Box pr={2}>
                        <Button variant={"outlined"} color={"neutral"} onClick={p.dialogState.close}>
                            Cancel
                        </Button>
                    </Box>
                    <Box>
                        <Button variant={"contained"} onClick={onConfirm}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
});

export const getTransactionDisplayAmountByType = (
    counterInfo: TransactionCounterInfo.AsObject,
    transactionDetails: TransactionDetails.AsObject,
    currentVaultSerial: string,
    theme: Theme
) => {
    const displayAmount = getLicenseValueFormat(counterInfo.amount, counterInfo.counterType.value);
    const negativeAmount = (
        <Typography color={"error"} variant={"body2"}>
            ({displayAmount}) {getTooltipTextByCounterType(counterInfo.counterType.value, theme)}
        </Typography>
    );

    const positiveAmount = (
        <Typography sx={{ color: (theme) => theme.palette.success.main }} variant={"body2"}>
            {displayAmount} {getTooltipTextByCounterType(counterInfo.counterType.value, theme)}
        </Typography>
    );

    if (transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.TRANSFER) {
        if (transactionDetails.fromVault.vaultSerial === currentVaultSerial) {
            return negativeAmount;
        } else {
            return positiveAmount;
        }
    } else if (transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.CONSUMPTION) {
        return negativeAmount;
    } else if (
        transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.CREDIT ||
        transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.DEPOSIT ||
        transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.PURCHASE ||
        transactionDetails.type.value === LicenseVaultTransactionType.LicenseVaultTransactionType.REFUND
    ) {
        return positiveAmount;
    } else {
        return <Typography>{displayAmount}</Typography>;
    }
};

const getTooltipTextByCounterType = (counterType: LicenseVaultCounterType.LicenseVaultCounterType, theme: Theme) => {
    if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.GALAXY_MIGRATE_LOCAL_MIGRATION) {
        return <TooltipText tooltipText={"CMC - Local Migration"} label={"(LM)"} style={{ color: theme.palette.grey["500"] }} />;
    } else if (counterType === LicenseVaultCounterType.LicenseVaultCounterType.HOST_UNLIMITED_MIGRATION) {
        return <TooltipText tooltipText={"Host Migration License"} label={"(HM)"} style={{ color: theme.palette.grey["500"] }} />;
    }
};
