// ======================
// GalaxyMigrateDeploymentRelayActivatedScreen
// ======================

import { observer } from "mobx-react-lite";
import { Box, Button, Card, Link, Theme, Tooltip, Typography } from "@mui/material";
import { AppHintID, HintButton } from "../../help/HelpCommon";
import React, { useState } from "react";
import { CdcRelayServerClientInfo, GetCdcRelayServerInfoResponse } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { PortNumbers } from "gc-web-proto/galaxymigratepb/galaxy_migrate_pb";
import { useAppServices } from "../../app/services";
import { useIsDesktop } from "../../layout/MainLayout";
import { GalaxyMigrateDeploymentDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/galaxymigrate_pb";
import { MTDIDeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/mtdi_pb";
import { GalaxyMigrationDeploymentOSDisplay } from "../GalaxyMigrateDeploymentsList";
import { convertTimestampObjectToDate, KnownDataType } from "../../../common/utils/formatter";
import { FormattedDisplay } from "../../../common/FormattedDisplay";
import { generateDeploymentDetailsPath } from "../GalaxyMigrateCommon";
import { useNavigate, useParams } from "react-router-dom";
import { NewDeploymentInstructions } from "../../deployment/NewDeploymentInstructions";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { useListGalaxyMigrateDeployments } from "../../deployment/deployment_hooks";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { QueryTable } from "../../../common/table/QueryTable";
import Grid from "@mui/material/Grid2";

interface GalaxyMigrateDeploymentRelayActivatedScreenProps {
    relayServerInfo: GetCdcRelayServerInfoResponse.AsObject;
    deployment: GalaxyMigrateDeploymentDetails;
}

export const GalaxyMigrateDeploymentRelayActivatedScreen: React.FC<GalaxyMigrateDeploymentRelayActivatedScreenProps> = observer((p) => {
    const { gmDeploymentService, dialogService } = useAppServices();
    const isDesktop = useIsDesktop();

    const onDeactivate = async () => {
        const message = `Once CDC Management Relay is deactivated, all hosts connected to this 
        relay server will be disconnected from Cirrus Data Cloud until CDC Management Relay is re-activated.`;

        const confirmed = await dialogService.addConfirmDialog({
            message,
            title: "Deactivate CDC Management Relay Confirmation",
        });
        if (confirmed) {
            await gmDeploymentService.configureRelayServer(null, false);
            await gmDeploymentService.cdcRelayServerStatus.fetchData();
        }
    };

    const serverAddress = p.relayServerInfo.config.address.split("/")[0];

    const isDeploymentWindows = p.deployment?.getInfo()?.getOsType() === MTDIDeploymentInfo.OSType.WINDOWS;

    return (
        <Box>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h5"}>CDC Management Relay Information</Typography>
                    <Typography variant={"body1"}>
                        Information for other Cirrus Migrate Cloud hosts to be deployed and connect to Cirrus Data Cloud via this host
                    </Typography>
                </Box>
                <Box>
                    <HintButton hintID={AppHintID.CMC_RELAY} />
                </Box>
            </Box>
            <Box pt={2}>
                <Card variant={"outlined"}>
                    <Grid container spacing={3}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 4,
                            }}
                        >
                            <Grid container spacing={2} p={2}>
                                <Grid size={6}>
                                    <Box>
                                        <Box>
                                            <Typography variant={"overline"}>Server Address</Typography>
                                        </Box>
                                        <Typography variant={"caption"}>{serverAddress}</Typography>
                                    </Box>
                                </Grid>
                                <Grid size={6}>
                                    <Box>
                                        <Box>
                                            <Typography variant={"overline"}>Port</Typography>
                                        </Box>
                                        <Typography variant={"caption"}>{isDeploymentWindows ? PortNumbers.CDC_RELAY_HTTP : PortNumbers.CDC_RELAY}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <OperatorView>
                            <Grid
                                borderLeft={isDesktop ? "2px solid" : "none"}
                                borderColor={(t: Theme) => t.palette.cirrus.light}
                                size={{
                                    xs: 12,
                                    md: 8,
                                }}
                            >
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={2}>
                                    <Box>
                                        <Typography variant={"h5"}>Deactivate CDC Management Relay</Typography>
                                        <Typography variant={"body1"}>Stop all new and existing connections to Cirrus Data Cloud via this host</Typography>
                                    </Box>
                                    <Box>
                                        <Button variant={"outlined"} color={"error"} onClick={onDeactivate}>
                                            Deactivate
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </OperatorView>
                    </Grid>
                </Card>
            </Box>
            <Box pt={4}>
                <Box pb={2}>
                    <Typography variant={"h5"}>Cirrus Migrate Cloud Deployment</Typography>
                    <Typography>Use the following commands to install / uninstall Cirrus Migrate Cloud via Management Relay</Typography>
                </Box>
                <Card>
                    <NewDeploymentInstructions relayAddress={serverAddress} />
                </Card>
            </Box>
            <Box pt={2}>
                <ConnectedClientsTable clientsList={p.relayServerInfo.clientsList} systemName={p.deployment.getInfo().getDeployment().getSystemName()} />
            </Box>
        </Box>
    );
});

// ======================
// ConnectedClientsTable
// ======================

interface ConnectedClientsTableProps {
    clientsList: Array<CdcRelayServerClientInfo.AsObject>;
    systemName: string;
}

const ConnectedClientsTable: React.FC<ConnectedClientsTableProps> = observer((p) => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const deploymentsList = useListGalaxyMigrateDeployments(
        projectId,
        false,
        pageIndex,
        pageSize,
        true,
        p.clientsList.map((c) => c.systemId)
    );

    const columnHelper = createColumnHelper<CdcRelayServerClientInfo.AsObject>();
    const columns = [
        columnHelper.accessor((r) => r.ip, {
            id: "ip",
            header: "IP",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.systemId, {
            id: "host",
            header: "Host Name",
            cell: (props) => {
                const deployment = deploymentsList.data?.itemsList.find((d) => d.deployment.systemId === props.getValue());
                return deployment ? (
                    <Link onClick={() => navigate(generateDeploymentDetailsPath(props.getValue(), projectId))}>{deployment.deployment.systemName}</Link>
                ) : (
                    "--"
                );
            },
        }),
        columnHelper.accessor((r) => r.systemId, {
            id: "os",
            header: "OS",
            cell: (props) => {
                const deployment = deploymentsList.data?.itemsList.find((d) => d.deployment.systemId === props.getValue());
                return deployment ? <GalaxyMigrationDeploymentOSDisplay deploymentInfo={deployment} hideKernel /> : "--";
            },
        }),
        columnHelper.accessor((r) => r.totalInstallationApiRequests, {
            id: "installer-req-relayed",
            header: "Installer Req. Relayed",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r, {
            id: "outbound-relayed",
            header: "Total Outbound Req. Relayed",
            cell: (props) => {
                return (
                    <Box textAlign={"right"}>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <Tooltip title={"OK Response"} arrow>
                                <Typography sx={{ color: (t: Theme) => t.palette.success.main }}>{props.getValue().totalConnRequestsOk}</Typography>
                            </Tooltip>
                            <Typography>&nbsp; | &nbsp;</Typography>
                            <Tooltip title={"Error Response"}>
                                <Typography sx={{ color: (t: Theme) => t.palette.error.main }}>{props.getValue().totalConnRequestsError}</Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                );
            },
        }),
        columnHelper.accessor((r) => r, {
            id: "last-req-time",
            header: "Last Req. Time",
            cell: (props) => <FormattedDisplay dataType={KnownDataType.DATE_RELATIVE} value={convertTimestampObjectToDate(props.getValue().lastRequest)} />,
        }),
        columnHelper.accessor((r) => r.systemId, {
            id: "latency",
            header: "Total Latency",
            cell: (props) => {
                const deployment = deploymentsList.data?.itemsList.find((d) => d.deployment.systemId === props.getValue());
                return deployment ? <FormattedDisplay dataType={KnownDataType.DURATION_MILLISECONDS} value={deployment.deployment.connectionLatency} /> : "--";
            },
        }),
    ];

    return (
        <Box pt={2}>
            <Box>
                <Typography variant={"h5"}>{`Connected Clients`}</Typography>
                <Typography variant={"body1"}>{`The following list of hosts are connected to Cirrus Data Cloud via ${p.systemName}.`}</Typography>
            </Box>
            <br />
            <QueryTable
                columns={columns}
                data={p.clientsList}
                emptyTableConfig={{
                    title: "No Connected Clients Found",
                }}
            />
        </Box>
    );
});
