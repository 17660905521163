import { CmoSystemEvent } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineDotProps,
    TimelineItem,
    timelineItemClasses,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator,
} from "@mui/lab";
import { formatKnownDataType, formatTitleCase, KnownDataType } from "../../../common/utils/formatter";
import { Box, Card, Link, MenuItem, Pagination, Select, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { CmoSystemEventMessageType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_system_event_message_type_pb";
import { CmoSystemEventsType, useListCmoSystemEvents } from "../cmo_migration_hooks";
import { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import * as React from "react";
import { ListCmoSystemWarnings } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { useNavigateToCmoSystemDetails } from "../CmoMigrationCommon";

// ======================
// CmoMigrationActivitesTimeline
// ======================

interface CmoMigrationActivitesTimelineProps {
    systemId?: string;
    projectId?: string;
    type: CmoSystemEventsType;
    pgSize?: number;
}

export const CmoMigrationActivitesTimeline: React.FC<CmoMigrationActivitesTimelineProps> = (p) => {
    const { systemId, type, projectId, pgSize } = p;
    const theme = useTheme();
    const gotoSystemDetails = useNavigateToCmoSystemDetails();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: pgSize || 10,
    });

    const systemEvents = useListCmoSystemEvents(systemId, type, pageIndex, pageSize, projectId);
    const itemsList = systemEvents.data?.itemsList || [];
    const getPageSelectionList = (totalPages: number) => {
        const pageList = [];
        if (totalPages === 0) {
            return [1];
        }
        for (let i = 1; i <= totalPages; i++) {
            pageList.push(i);
        }
        return pageList;
    };

    const activitiesPagination = (
        <Stack direction={"row"} spacing={1} justifyContent={"center"} p={1} alignItems={"center"}>
            <Pagination
                count={pageIndex + 1}
                page={pageIndex}
                onChange={(event, page) => {
                    setPagination({ pageIndex: page, pageSize });
                }}
                hideNextButton={itemsList.length < pageSize}
            />
        </Stack>
    );

    return (
        <QueryResultWrapper queryResult={systemEvents}>
            {itemsList?.length === 0 ? (
                <Box>
                    <Box p={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Box width={"100%"}>
                            <Card
                                elevation={type === "activities" ? 1 : 0}
                                sx={{
                                    width: "100%",
                                    backgroundColor: type === "activities" ? theme.palette.background.paper : theme.palette.cirrus.main,
                                }}
                            >
                                <Box p={2} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    <Typography variant={"body2"} color={"textSecondary"}>{`No Recent ${
                                        type === "activities" ? "Activities" : "Alerts"
                                    }.`}</Typography>
                                </Box>
                            </Card>
                        </Box>
                    </Box>
                    <Box>{type === "activities" && activitiesPagination}</Box>
                </Box>
            ) : (
                <>
                    <Timeline
                        sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.1,
                                textAlign: "left",
                                paddingRight: 1,
                                paddingLeft: 0,
                                paddingTop: 2.5,
                            },
                        }}
                    >
                        {itemsList.map((e, index) => {
                            const isLastItem = index === itemsList.length - 1;
                            return (
                                <TimelineItem>
                                    <TimelineOppositeContent>
                                        <Stack direction={"column"}>
                                            <Typography variant={"caption"}>{formatKnownDataType(e.time, KnownDataType.DATE_RELATIVE)}</Typography>
                                            {!systemId && (
                                                <Link
                                                    variant={"caption"}
                                                    onClick={() => {
                                                        gotoSystemDetails(e.deployment.systemId);
                                                    }}
                                                >
                                                    {e.deployment.systemName}
                                                </Link>
                                            )}
                                        </Stack>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator sx={{ paddingTop: 1 }}>
                                        <BlackTooltip title={getTimelineEventTypeLabel(e.type)} arrow>
                                            <TimelineDot
                                                color={getTimelineColorByEventType(e.type)}
                                                variant={"outlined"}
                                                sx={{ height: 30, width: 30, borderWidth: 3 }}
                                            />
                                        </BlackTooltip>
                                        {!isLastItem && <TimelineConnector sx={{ height: 15, backgroundColor: theme.palette.border.main }} />}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Card elevation={type === "warnings" ? 0 : 1}>
                                            <Box p={2}>
                                                <Typography>{e.message}</Typography>
                                            </Box>
                                        </Card>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                    {type === "warnings" ? (
                        <Stack direction={"row"} spacing={1} justifyContent={"center"} p={1} alignItems={"center"}>
                            <Pagination
                                count={(systemEvents.data as ListCmoSystemWarnings.Response.AsObject)?.pagerMeta.totalPages}
                                page={pageIndex}
                                onChange={(event, page) => {
                                    setPagination({ pageIndex: page, pageSize });
                                }}
                                showFirstButton={true}
                                showLastButton={true}
                            />
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {"Go To Page: "}
                            </Typography>
                            <Box>
                                <Select
                                    value={pageIndex}
                                    variant={"outlined"}
                                    MenuProps={{
                                        slotProps: {
                                            paper: {
                                                sx: {
                                                    height: "200px",
                                                    "&::-webkit-scrollbar": {
                                                        width: "10px",
                                                        backgroundColor: "transparent",
                                                    },
                                                    "&::-webkit-scrollbar-thumb": {
                                                        borderRadius: "10px",
                                                        border: "2px solid transparent",
                                                        backgroundColor: "rgba(255,255,255,.1)",
                                                        backgroundClip: `content-box`,
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                    slotProps={{}}
                                    onChange={(e) => {
                                        setPagination({ pageIndex: Number(e.target.value), pageSize });
                                    }}
                                    sx={{ height: "35px", lineHeight: 0, width: "70px" }}
                                    renderValue={(value: unknown) => <Typography variant={"caption"}>{value as React.ReactNode}</Typography>}
                                >
                                    {getPageSelectionList((systemEvents.data as ListCmoSystemWarnings.Response.AsObject)?.pagerMeta.totalPages).map((p, i) => {
                                        return (
                                            <MenuItem key={i} value={p}>
                                                {p}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        </Stack>
                    ) : (
                        activitiesPagination
                    )}
                </>
            )}
        </QueryResultWrapper>
    );
};

export const getTimelineColorByEventType = (eventType: CmoSystemEventMessageType.CmoSystemEventMessageType): TimelineDotProps["color"] => {
    if (eventType === CmoSystemEventMessageType.CmoSystemEventMessageType.INFORMATION) {
        return "primary";
    }
    if (eventType === CmoSystemEventMessageType.CmoSystemEventMessageType.WARNING) {
        return "warning";
    }
    if (eventType === CmoSystemEventMessageType.CmoSystemEventMessageType.ERROR) {
        return "error";
    }
    if (eventType === CmoSystemEventMessageType.CmoSystemEventMessageType.CRITICAL) {
        return "secondary";
    }
    return "grey";
};

export const getTimelineEventTypeLabel = (eventType: CmoSystemEventMessageType.CmoSystemEventMessageType): string => {
    return formatTitleCase(
        Object.keys(CmoSystemEventMessageType.CmoSystemEventMessageType).find(
            (key) => CmoSystemEventMessageType.CmoSystemEventMessageType[key as keyof typeof CmoSystemEventMessageType.CmoSystemEventMessageType] === eventType
        ) || ""
    );
};
