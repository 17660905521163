import visibilityjs from "visibilityjs";
import { emptyFn, sleepMS } from "../utils/util";

export type PollerFunction = () => Promise<any>;

// const emptyFn = ()=>{}
/**
 * simple autoPoll function. return the stop function
 */
export function startPolling(fn: PollerFunction, secondInterval = 5, skipPollingCheck = () => false, intervalAfterSkip = secondInterval, onlyIfVisible = true) {
    const poller = new Poller(fn, secondInterval * 1000, skipPollingCheck, false, intervalAfterSkip, onlyIfVisible);
    poller.start();

    return poller;
}

export function stopPolling(poller: Poller) {
    return poller ? poller.stop() : null;
}

export class Poller {
    id: string;
    _deferredFunction: Function;
    _skipPollingCheck: Function;
    _interval: number | (() => number);
    _intervalAfterSkip?: number;
    _onlyIfVisible: boolean;
    _stopped: boolean;
    _lastTickSkipped: boolean;

    /**
     *
     * @param {Function} deferredFunction
     * @param {Number} interval in ms
     * @param {=Function} skipPollingCheck
     * @param {Boolean=} autoStart
     * @param {Number=} intervalAfterSkip
     */
    constructor(
        deferredFunction: PollerFunction,
        interval = 0,
        skipPollingCheck = emptyFn,
        autoStart = false,
        intervalAfterSkip = interval,
        onlyIfVisible = true
    ) {
        this.id = `Poller${"_" + Math.random().toString(36).substr(2, 9)}`;

        this._skipPollingCheck = skipPollingCheck;
        this._onlyIfVisible = onlyIfVisible;
        this._deferredFunction = deferredFunction;

        this._interval = interval;
        this._stopped = true;

        // use to prevent start until everything is finished

        this._intervalAfterSkip = intervalAfterSkip;
        // this value used to determine how long to wait until next tick, after use it will set back to false
        this._lastTickSkipped = false;

        if (autoStart) {
            this.start();
        }
    }

    async start(firstTick = true): Promise<null> {
        if (this._stopped) {
            this._stopped = false;
            console.debug(["Started Poller " + this.id, this]);
            // tick once then schedule
            if (firstTick) {
                await this.tick();
            }

            while (!this._stopped) {
                await sleepMS(this._getInterval());
                if (!this._stopped) {
                    await this.tick();
                }
            }
        } else {
            console.error(["Poller already started", this]);
        }
        return null;
    }

    stop() {
        if (!this._stopped) {
            this._stopped = true;
            console.debug(["Stopped Poller " + this.id, this]);
        }
    }

    _getInterval() {
        let interval = this._interval;

        if (this._lastTickSkipped) {
            interval = this._intervalAfterSkip;
            this._lastTickSkipped = false;
        }

        return typeof interval === "function" ? interval() : interval;
    }

    /**
     *
     * @returns {Promise}
     */
    async tick() {
        if ((typeof this._skipPollingCheck !== "function" || !this._skipPollingCheck()) && (!this._onlyIfVisible || !visibilityjs.hidden())) {
            const deferFnReturn = this._deferredFunction();
            if (deferFnReturn) {
                try {
                    await deferFnReturn;
                } catch (e) {
                    console.error(`Error Encountered in Poller ${this.id}`);
                    console.error(e);
                }
            }
        } else {
            this._lastTickSkipped = true;
        }
    }
}
