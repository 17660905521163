// ======================
// GalaxyMigrateDeploymentLabels
// ======================

import { SelectLabelsForm } from "../../labels/SelectLabelsForm";
import React from "react";
import { Box } from "@mui/material";
import { useAddDeploymentLabel, useRemoveDeploymentLabel } from "../../labels/label_hooks";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { AddDeploymentLabel, RemoveDeployment, RemoveDeploymentLabel } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { useAppServices } from "../../app/services";

interface GalaxyMigrateDeploymentLabelsProps {
    projectId: string;
    deploymentLabels: Array<Label.AsObject>;
    deploymentId: string;
}

export const GalaxyMigrateDeploymentLabels: React.FC<GalaxyMigrateDeploymentLabelsProps> = (p) => {
    const { projectId, deploymentLabels, deploymentId } = p;
    const addDeploymentLabel = useAddDeploymentLabel();
    const removeDeploymentLabel = useRemoveDeploymentLabel();
    const { deploymentService } = useAppServices();
    const handleLabelSelection = async (label: Label.AsObject) => {
        if (!!deploymentLabels.find((l) => l.name === label.name)) {
            const req = new RemoveDeploymentLabel.Request().setLabel(label.name).setSystemId(deploymentId);
            await removeDeploymentLabel.mutateAsync(req);
            await deploymentService.currentDeployment.fetchData();
        } else {
            const req = new AddDeploymentLabel.Request().setLabel(label.name).setSystemId(deploymentId);
            await addDeploymentLabel.mutateAsync(req);
            await deploymentService.currentDeployment.fetchData();
        }
    };

    return (
        <Box pt={2}>
            <SelectLabelsForm currentlySelectedLabels={deploymentLabels} onClickLabel={handleLabelSelection} projectId={projectId} />
        </Box>
    );
};
