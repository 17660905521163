// ======================
// ProjectLicenseReport
// ======================

import { CollapsibleHeader, CollapsibleSubheader, ReportFooter, ReportProps } from "../ReportCommon";
import { Box, Stack, Typography } from "@mui/material";
import { formatISO } from "date-fns";
import { getCounterTypeDisplayValue, getLicenseModelLabel, getLicenseTransactionTypeDisplayValue, getLicenseValueFormat } from "../../license/LicenseCommon";
import { getTransactionDisplayAmountByType } from "../../license/LicenseKeyMainScreen";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { renderGMSessionStatus } from "../../cmcMigration/CmcMigrationCommon";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";

export const ProjectLicenseReport: React.FC<ReportProps> = (p) => {
    const { reportData, reportInfo } = p;
    const licenseModelNum = reportInfo.plrMeta.licenseModel;
    const theme = useTheme();
    return (
        <Box>
            <Box p={4} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <Typography variant={"h4"}>{`Cirrus Data Cloud`}</Typography>
                    <Typography variant={"h4"}>{`Project License Report`}</Typography>
                </Box>
            </Box>
            <Box>
                <CollapsibleHeader title={"1. Project License Key"}>
                    <Stack p={4} spacing={4}>
                        <CollapsibleSubheader title={"1.1 Information"}>
                            <Box pl={2} pt={1} pb={2}>
                                <Grid container>
                                    <Grid size={4}>{`Project Name:`}</Grid>
                                    <Grid size={8}>{reportInfo.plrMeta.projectName}</Grid>
                                    <Grid size={4}>{`License Key:`}</Grid>
                                    <Grid size={8}>{reportData.plr.projectLicenseData.projectVaultDetails.identity.vaultSerial}</Grid>
                                    <Grid size={4}>{`License Model:`}</Grid>
                                    <Grid size={8}>{getLicenseModelLabel(licenseModelNum)}</Grid>
                                    <Grid size={4}>{`Project ID:`}</Grid>
                                    <Grid size={8}>{reportInfo.plrMeta.projectId}</Grid>
                                    <Grid size={4}>{`Project Description:`}</Grid>
                                    <Grid size={8}>
                                        {/* Correct description? */}
                                        {reportInfo.systemNotes}
                                    </Grid>
                                    <Grid size={4}>{`Project Created At:`}</Grid>
                                    <Grid size={8}>{formatISO(convertTimestampObjectToDate(reportInfo.createdAt))}</Grid>
                                    <Grid size={4}>{`Total Hosts In Project:`}</Grid>
                                    <Grid size={8}>{reportInfo.plrMeta.totalHostsInProject}</Grid>
                                    {licenseModelNum === 1 && (
                                        <Grid container>
                                            <Grid size={4}>{`Total Active Licenses:`}</Grid>
                                            <Grid size={8}>{reportInfo.plrMeta.totalActiveLicenses}</Grid>
                                            <Grid size={4}>{`Total Expired Licenses:`}</Grid>
                                            <Grid size={8}>{reportInfo.plrMeta.totalExpiredLicenses}</Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </CollapsibleSubheader>
                        <CollapsibleSubheader title={"1.2 Current Balance"}>
                            <Stack pt={2} pr={2} pl={2} spacing={2}>
                                {reportData.plr.projectLicenseData.projectVaultDetails.itemsList.map((balance, index) => {
                                    return (
                                        <Box>
                                            <Typography fontWeight={600}>{getCounterTypeDisplayValue(balance.counterType?.value)}</Typography>
                                            <Grid container>
                                                <Grid size={4}>{"Amount Remaining:"}</Grid>
                                                <Grid size={8}>{getLicenseValueFormat(balance.currentBalance, balance.counterType.value)}</Grid>
                                                <Grid size={4}>{"Total Consumed:"}</Grid>
                                                <Grid size={8}>{getLicenseValueFormat(balance.totalConsumed, balance.counterType.value)}</Grid>
                                            </Grid>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </CollapsibleSubheader>
                    </Stack>
                </CollapsibleHeader>
                <CollapsibleHeader title={"2. License Transactions"}>
                    <Stack p={4} spacing={4}>
                        {reportData.plr.projectLicenseData.projectLicenseTransactionsList.map((transaction, index) => {
                            const vaultSerial = reportData.plr.projectLicenseData.projectVaultDetails.identity.vaultSerial;
                            return (
                                <Box pl={2} pt={1} pb={2}>
                                    <Grid container>
                                        <Grid size={4}>
                                            <Grid>
                                                <Typography fontWeight={600}>{formatISO(convertTimestampObjectToDate(transaction.createdAt))}</Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography fontWeight={600} sx={{ opacity: 0.25 }}>
                                                    {getLicenseTransactionTypeDisplayValue(transaction.type.value)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid size={8}>
                                            <Grid container>
                                                <Grid fontWeight={500} size={4}>
                                                    {`From:`}
                                                </Grid>
                                                <Grid size={8}>{transaction.fromVault?.vaultSerial || "N/A"}</Grid>
                                                <Grid fontWeight={500} size={4}>
                                                    {`Description:`}
                                                </Grid>
                                                <Grid size={8}>{transaction.description || "N/A"}</Grid>
                                                <Grid fontWeight={500} size={4}>
                                                    {`License Type:`}
                                                </Grid>
                                                <Grid size={8}>{getCounterTypeDisplayValue(transaction.type.value || 1000)}</Grid>
                                                <Grid fontWeight={500} size={4}>
                                                    {`Amount:`}
                                                </Grid>
                                                <Grid size={8}>
                                                    {getTransactionDisplayAmountByType(transaction.itemsList[0], transaction, vaultSerial, theme)}
                                                </Grid>
                                                <Grid fontWeight={500} size={4}>
                                                    {`Status:`}
                                                </Grid>
                                                <Grid size={8}>{renderGMSessionStatus(transaction.status.value)}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            );
                        })}
                    </Stack>
                </CollapsibleHeader>
                {licenseModelNum === 1 &&
                    reportData.plr.hostLicenseDataList.map((hostLicenseData, index) => {
                        return (
                            <CollapsibleHeader title={`${index + 1}. Host Migration License: ${hostLicenseData.systemName}`} key={index}>
                                <Stack p={4} spacing={4}>
                                    <CollapsibleSubheader title={`${index + 1}.1 License Info`}>
                                        <Box pl={2} pt={1} pb={2}>
                                            <Grid container>
                                                <Grid size={4}>{`Remaining Capacity:`}</Grid>
                                                <Grid size={8}>
                                                    {/* convert to a knowndatatype */}
                                                    <Typography>
                                                        {hostLicenseData.hostLicense
                                                            ? formatKnownDataType(
                                                                  hostLicenseData.hostLicense.migrationCapacityRemaining,
                                                                  KnownDataType.CAPACITY
                                                              )
                                                            : "N/A"}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={4}>{`Total Capacity Consumed:`}</Grid>
                                                <Grid size={8}>
                                                    <Typography>
                                                        {hostLicenseData.hostLicense
                                                            ? formatKnownDataType(hostLicenseData.hostLicense.migrationCapacityConsumed, KnownDataType.CAPACITY)
                                                            : "N/A"}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={4}>{`Activation Time:`}</Grid>
                                                <Grid size={8}>
                                                    <Typography>
                                                        {hostLicenseData.hostLicense
                                                            ? formatISO(convertTimestampObjectToDate(hostLicenseData.hostLicense.activatedAt))
                                                            : "N/A"}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={4}>{`Expiration Time:`}</Grid>
                                                <Grid size={8}>
                                                    <Typography>
                                                        {hostLicenseData.hostLicense
                                                            ? formatISO(convertTimestampObjectToDate(hostLicenseData.hostLicense.expireAt))
                                                            : "N/A"}
                                                    </Typography>
                                                </Grid>
                                                <Grid size={4}>{`Status:`}</Grid>
                                                <Grid size={8}>
                                                    {hostLicenseData.hostLicense
                                                        ? !!hostLicenseData.hostLicense.expired
                                                            ? "License Active"
                                                            : "License Expired"
                                                        : "N/A"}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </CollapsibleSubheader>
                                    <CollapsibleSubheader title={`${index + 1}.2 Transaction History`}>
                                        <Box pl={2} pt={1} pb={2}>
                                            {hostLicenseData.hostLicenseTransactionsList.length === 0 ? (
                                                <Typography>None</Typography>
                                            ) : (
                                                hostLicenseData.hostLicenseTransactionsList.map((licenseTransaction, index) => {
                                                    return (
                                                        <Grid container key={index} sx={{ typography: { fontSize: 13 } }}>
                                                            <Grid container size={4}>
                                                                <Grid size={12}>
                                                                    <Typography fontSize={13} fontWeight={600}>
                                                                        {formatISO(convertTimestampObjectToDate(licenseTransaction.timestamp))}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid size={12}>
                                                                    <Typography fontSize={13} fontWeight={600} sx={{ opacity: 0.25 }}>
                                                                        {getLicenseTransactionTypeDisplayValue(licenseTransaction.type)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container size={8}>
                                                                <Grid fontWeight={500} size={4}>
                                                                    {`Description:`}
                                                                </Grid>
                                                                <Grid size={8}>{licenseTransaction.description || "N/A"}</Grid>
                                                                <Grid fontWeight={500} size={4}>
                                                                    {`Migration Capacity:`}
                                                                </Grid>
                                                                <Grid size={8}>
                                                                    {formatKnownDataType(licenseTransaction.migrationCapacityAfter, KnownDataType.CAPACITY)}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })
                                            )}
                                        </Box>
                                    </CollapsibleSubheader>
                                </Stack>
                            </CollapsibleHeader>
                        );
                    })}
                <ReportFooter reportInfo={reportInfo} />
            </Box>
        </Box>
    );
};
