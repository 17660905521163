// ======================
// SelectLabelForm
// ======================

import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { LabelChip } from "./labelCommon";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { useListProjectLabels } from "./label_hooks";
import { useMemo } from "react";
import { getScopeFromLabel, getScopeListFromLabels, getUnscopedLabelsFromList } from "./labelHelpers";
import { Box, Card, CardContent, Divider, Link, ListSubheader, Stack, Typography } from "@mui/material";
import { DarkFlatOutlinedCard } from "../../common/card/DarkCard";
import EmptyTable from "../../assets/image_empty_folder.png";
import * as React from "react";

interface SelectLabelsFormProps {
    projectId: string;
    onClickLabel?: (label: Label.AsObject) => Promise<any> | any;
    currentlySelectedLabels?: Array<Label.AsObject>;
}

export const SelectLabelsForm: React.FC<SelectLabelsFormProps> = (p) => {
    const { projectId, onClickLabel, currentlySelectedLabels } = p;
    const projectLabels = useListProjectLabels(projectId);
    const scopeList = useMemo(() => {
        return getScopeListFromLabels(projectLabels.data?.itemsList || []);
    }, [projectLabels.data?.itemsList]);

    const unscopedLabels = useMemo(() => {
        return getUnscopedLabelsFromList(projectLabels.data?.itemsList || []);
    }, [projectLabels.data?.itemsList]);

    if (projectLabels.data?.itemsList?.length === 0) {
        return (
            <Card>
                <Stack justifyContent={"center"} alignItems={"center"} p={4}>
                    <Box pb={2}>
                        <img src={EmptyTable} alt={"No Data Yet"} height={"50px"} />
                    </Box>
                    <Box pb={1}>
                        <Typography variant={"h6"}>{"This project has No Labels"}</Typography>
                    </Box>
                    <Box pb={2}>
                        <Typography variant={"body1"}>{"To get started, go to Settings -> Label Management to add new labels for this project."}</Typography>
                    </Box>
                </Stack>
            </Card>
        );
    }

    return (
        <Card>
            <CardContent>
                <Typography color={"textSecondary"}>{`Select or Un-select the applicable labels:`}</Typography>
                <QueryResultWrapper queryResult={projectLabels}>
                    <Stack pt={2} spacing={2}>
                        {scopeList.map((scope) => {
                            return (
                                <DarkFlatOutlinedCard key={scope}>
                                    <CardContent>
                                        <Typography variant={"h6"}>{scope}</Typography>
                                        <Stack direction={"row"} spacing={2} pt={2}>
                                            {projectLabels.data?.itemsList
                                                .filter((l) => getScopeFromLabel(l.label.name) === scope)
                                                .map((l) => {
                                                    const handleClick = async () => {
                                                        await onClickLabel(l.label);
                                                    };
                                                    const isSelected = currentlySelectedLabels?.find((cl) => cl.name === l.label.name);
                                                    return <LabelChip onClick={handleClick} color={isSelected ? l.label.color : null} label={l.label.name} />;
                                                })}
                                        </Stack>
                                    </CardContent>
                                </DarkFlatOutlinedCard>
                            );
                        })}
                        <DarkFlatOutlinedCard>
                            <CardContent>
                                <Typography variant={"h6"}>{"Other Labels (Unscoped)"}</Typography>
                                <Stack direction={"row"} spacing={2} pt={2}>
                                    {unscopedLabels.map((l) => {
                                        const handleClick = async () => {
                                            await onClickLabel(l);
                                        };
                                        const isSelected = currentlySelectedLabels?.find((cl) => cl.name === l.name);
                                        return <LabelChip onClick={handleClick} color={isSelected ? l.color : null} label={l.name} />;
                                    })}
                                </Stack>
                            </CardContent>
                        </DarkFlatOutlinedCard>
                    </Stack>
                </QueryResultWrapper>
            </CardContent>
        </Card>
    );
};
