import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Box, Button, SvgIcon, useTheme } from "@mui/material";

import * as React from "react";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { MdPrint } from "react-icons/md";
import { useGetReportData } from "../report_hooks";
import { ReportType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/report_type_pb";
import { HostConfigurationReport } from "./HostConfigurationReport";
import { MigrationSessionReport } from "./MigrationSessionReport";
import { ProjectLicenseReport } from "./ProjectLicenseReport";
import { ThinDataReport } from "./MigrationSourceDataAssessmentReport";

// ======================
// ViewReportScreen
// ======================

interface ViewReportScreenProps {}

export const ViewReportScreen: React.FC<ViewReportScreenProps> = observer((p) => {
    const { reportId } = useParams();
    const theme = useTheme();

    const queryResult = useGetReportData(Number(reportId));
    const renderReportByType = (reportType: ReportType.ReportType) => {
        if (reportType === ReportType.ReportType.HOST_CONFIGURATIONS_REPORT) {
            return <HostConfigurationReport reportData={queryResult.data?.reportData} reportInfo={queryResult.data?.reportInfo} />;
        }
        if (reportType === ReportType.ReportType.MIGRATION_SESSION_REPORT) {
            return <MigrationSessionReport reportData={queryResult.data?.reportData} reportInfo={queryResult.data?.reportInfo} />;
        }
        if (reportType === ReportType.ReportType.PROJECT_LICENSE_REPORT) {
            return <ProjectLicenseReport reportData={queryResult.data?.reportData} reportInfo={queryResult.data?.reportInfo} />;
        }
        if (reportType === ReportType.ReportType.MIGRATION_SOURCE_DATA_ASSESSMENT_REPORT) {
            return <ThinDataReport reportData={queryResult.data?.reportData} reportInfo={queryResult.data?.reportInfo} />;
        }
        return null;
    };
    return (
        <QueryResultWrapper queryResult={queryResult}>
            <Box
                sx={{
                    "@media screen": {
                        width: "100vw",
                        height: "100%",
                        backgroundColor: theme.palette.cirrusGray.main,
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "center",
                    },
                    "@media print": {
                        width: "auto",
                        border: 0,
                        margin: "5%",
                        padding: 0,
                        float: "none",
                        position: "static",
                        overflow: "visible",
                    },
                }}
            >
                <Box
                    pt={4}
                    sx={{
                        color: "black",
                        "@media screen": {
                            width: "900px",
                            height: "100%",
                            backgroundColor: "white",
                            borderLeft: `1px solid ${theme.palette.cirrusGray.dark}`,
                            borderRight: `1px solid ${theme.palette.cirrusGray.dark}`,
                        },
                    }}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        pr={6}
                        sx={{
                            "@media print": {
                                display: "none",
                            },
                        }}
                    >
                        <Button
                            variant={"outlined"}
                            onClick={() => window.print()}
                            startIcon={
                                <SvgIcon>
                                    <MdPrint />
                                </SvgIcon>
                            }
                        >
                            {`Print Report`}
                        </Button>
                    </Box>
                    {renderReportByType(queryResult.data?.reportInfo?.reportType as ReportType.ReportType)}
                </Box>
            </Box>
        </QueryResultWrapper>
    );
});
