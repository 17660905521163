// Project: GalaxyComplete
// Created: 9/28/20 by sammy
// File: KVTable

import * as React from "react";
import { observer } from "mobx-react-lite";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../utils/formatter";

// ======================
// KVTable
// ======================
export interface KVTableData {
    key: string;
    value: React.ReactNode | any;

    // for raw value
    dataType?: KnownDataType;
}

interface KVTableProps {
    data: KVTableData[];
    dense?: boolean;
}

export const KVTable: React.FC<KVTableProps> = observer((p) => {
    return (
        <Table size={p.dense ? "small" : "medium"}>
            <TableBody>
                {p.data.map((d, i) => {
                    const islastItem = p.data.length - 1 === i;

                    return (
                        <TableRow key={i}>
                            <TableCell
                                variant={"head"}
                                sx={{
                                    borderBottom: islastItem ? "none" : "1px solid black",
                                }}
                            >
                                {d.key}
                            </TableCell>
                            <TableCell
                                align={"right"}
                                sx={{
                                    borderBottom: islastItem ? "none" : "1px solid black",
                                }}
                            >
                                {_renderValue(d)}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
});

const _renderValue = (d: KVTableData) => {
    const v = d.value;
    if (v === null || typeof v === "undefined") {
        return "N/A";
    }
    const isRawValue = !React.isValidElement(v) || typeof v !== "function";
    if (isRawValue) {
        return formatKnownDataType(v, d.dataType || KnownDataType.GENERIC);
    }
    return v;
};
