import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { CmoIOStatData, CmoSystemIoStats } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { formatMicrosecond } from "../../../common/utils/statsUtil";
import { IOStatsDataSet } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";

export const getKnownDataTypeOfCmoIoStatType = (statType: CmoIoStatChartType) => {
    const throughputTypes: Array<CmoIoStatChartType> = [CmoIoStatChartType.THROUGHPUT];
    const numberTypes: Array<CmoIoStatChartType> = [CmoIoStatChartType.IOPS, CmoIoStatChartType.QUEUE_DEPTH];
    const latencyTypes: Array<CmoIoStatChartType> = [CmoIoStatChartType.LATENCY];

    if (throughputTypes.includes(statType)) {
        return KnownDataType.THROUGHPUT;
    } else if (numberTypes.includes(statType)) {
        return KnownDataType.NUMBER;
    } else if (latencyTypes.includes(statType)) {
        return KnownDataType.DURATION_MICROSECONDS;
    }
};

export enum CmoIoStatChartType {
    THROUGHPUT = "Throughput",
    IOPS = "IOPS",
    LATENCY = "Latency",
    QUEUE_DEPTH = "Queue Depth",
}

interface PerformanceChartConfig {
    title: string;
    statType: CmoIoStatChartType;
    yAxisFormatter: (value: number) => string;
}
export const getCmoIoChartConfigs = (): PerformanceChartConfig[] => {
    return [
        {
            title: "IOPS",
            statType: CmoIoStatChartType.IOPS,
            yAxisFormatter: (value: number) => `${value} IOPS`,
        },
        {
            title: "Throughput",
            statType: CmoIoStatChartType.THROUGHPUT,
            yAxisFormatter: (value: number) => formatKnownDataType(value, KnownDataType.THROUGHPUT),
        },
        {
            title: "Latency",
            statType: CmoIoStatChartType.LATENCY,
            yAxisFormatter: (value: number) => formatMicrosecond(value),
        },
        {
            title: "Queue Depth",
            statType: CmoIoStatChartType.QUEUE_DEPTH,
            yAxisFormatter: (value: number) => `${value}`,
        },
        // {
        //     title: "IO Size",
        //     statType: "avgIoSize",
        //     yAxisFormatter: (value: number) => formatKnownDataType(value, KnownDataType.CAPACITY),
        // },
    ];
};

export const getDataKeyFromCmoIoStatType = (statType: CmoIoStatChartType, type: "read" | "write"): keyof CmoIoStatDataPoint => {
    if (statType === CmoIoStatChartType.THROUGHPUT) {
        return `${type}Throughput` as keyof CmoIoStatDataPoint;
    }
    if (statType === CmoIoStatChartType.IOPS) {
        return `${type}Iops` as keyof CmoIoStatDataPoint;
    }
    if (statType === CmoIoStatChartType.LATENCY) {
        return `average${type.charAt(0).toUpperCase()}${type.slice(1)}ResponseTime` as keyof CmoIoStatDataPoint;
    }
    if (statType === CmoIoStatChartType.QUEUE_DEPTH) {
        return `${type}PendingIo` as keyof CmoIoStatDataPoint;
    }
};

export const getKeyOfCmoSystemIoStatsFromStatType = (statType: CmoIoStatChartType, type: "read" | "write"): keyof CmoSystemIoStats.AsObject => {
    if (statType === CmoIoStatChartType.THROUGHPUT) {
        return `${type}Throughput` as keyof CmoSystemIoStats.AsObject;
    }
    if (statType === CmoIoStatChartType.IOPS) {
        return `${type}Iops` as keyof CmoSystemIoStats.AsObject;
    }
    if (statType === CmoIoStatChartType.LATENCY) {
        return `average${type.charAt(0).toUpperCase()}${type.slice(1)}ResponseTime` as keyof CmoSystemIoStats.AsObject;
    }
    if (statType === CmoIoStatChartType.QUEUE_DEPTH) {
        return `${type}PendingIo` as keyof CmoSystemIoStats.AsObject;
    }
};

export const getYAxisFormatterForCmoIoStatType = (statType: CmoIoStatChartType): ((value: number) => string) => {
    return getCmoIoChartConfigs().find((config) => config.statType === statType)?.yAxisFormatter || ((value: number) => `${value}`);
};

export interface CmoIoStatDataPoint {
    time: Date;
    readThroughput: number;
    writeThroughput: number;
    totalThroughput: number;
    maxReadThroughput: number;
    maxWriteThroughput: number;
    iops: number;
    writeIops: number;
    readIops: number;
    readPendingIo: number;
    writePendingIo: number;
    pendingIo: number;
    averageReadResponseTime: number;
    averageWriteResponseTime: number;
    maxReadResponseTime: number;
    maxWriteResponseTime: number;
    averageDownstreamWriteResponseTime: number;
    averageDownstreamReadResponseTime: number;
    averageResponseTime: number;
}
export const getChartDataFromIoStatsObject = (ioStats: CmoIOStatData.AsObject): Array<CmoIoStatDataPoint> => {
    return ioStats.pointsList
        .map((point) => {
            return {
                time: convertTimestampObjectToDate(point.time),
                readThroughput: point.stats.readThroughput,
                writeThroughput: point.stats.writeThroughput,
                totalThroughput: point.stats.totalThroughput,
                maxReadThroughput: point.stats.maxStats.maxReadThroughput,
                maxWriteThroughput: point.stats.maxStats.maxWriteThroughput,
                iops: point.stats.iops,
                writeIops: point.stats.writeIops,
                readIops: point.stats.readIops,
                readPendingIo: point.stats.readPendingIo,
                writePendingIo: point.stats.writePendingIo,
                pendingIo: point.stats.pendingIo,
                averageReadResponseTime: point.stats.averageReadResponseTime,
                averageWriteResponseTime: point.stats.averageWriteResponseTime,
                maxReadResponseTime: point.stats.maxStats.maxReadResponseTime,
                maxWriteResponseTime: point.stats.maxStats.maxWriteResponseTime,
                averageDownstreamWriteResponseTime: point.stats.averageDownstreamWriteResponseTime,
                averageDownstreamReadResponseTime: point.stats.averageDownstreamReadResponseTime,
                averageResponseTime: point.stats.averageResponseTime,
            };
        })
        .reverse();
};

export const getChartDataByKey = (chartData: Array<CmoIoStatDataPoint>, key: keyof CmoIoStatDataPoint): Array<{ time: Date; value: any }> => {
    return chartData.map((point) => {
        return {
            time: point.time,
            value: point[key],
        };
    });
};
