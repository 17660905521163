import { isDevelopment } from "./utils/util";

const originalFunctions = {
    debug: null as Function,
    info: null as Function,
    log: null as Function,
    warn: null as Function,
    error: null as Function,
    table: null as Function,
};

const _noop = () => {};
export const initLoggers = () => {
    originalFunctions.debug = console.debug;
    originalFunctions.info = console.info;
    originalFunctions.table = console.table;

    if (!isDevelopment()) {
        console.debug = _noop;
        console.info = _noop;
        console.table = _noop;
    }
};
