// ======================
// CmoMigrationDashboardScreen
// ======================

import { useGetCmoDeploymentsSummary, useGetCmoSystemWarningsSummary } from "./cmo_migration_hooks";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import {
    alpha,
    Box,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    LinearProgress,
    linearProgressClasses,
    ListSubheader,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { DarkFlatCard } from "../../common/card/DarkCard";
import { CmoDeploymentsSummary, CmoInsertionSummary, CmoMigrationSummary } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import { formatKnownDataType, KnownDataType } from "../../common/utils/formatter";
import CmoHost from "../../assets/cmo/Dashboard_Host.svg";
import CmoSystem from "../../assets/cmo/Dashboard_CMO.svg";
import CmoStorage from "../../assets/cmo/Dashboard_Storage.svg";
import { getAxesMaxValue } from "../../common/utils/statsUtil";
import { useLinearProgressStyle } from "./CmoMigrationCommon";
import { OnlineStatusIcon } from "../../common/CommonIcons";
import { CmoMigrationWarningsSummary } from "./activities/CmoMigrationWarningsSummary";
import Grid from "@mui/material/Grid2";

interface CmoMigrationDashboardScreenProps {
    projectId: string;
}

export const CmoMigrationDashboardScreen: React.FC<CmoMigrationDashboardScreenProps> = (p) => {
    const { projectId } = p;
    const deploymentsSummary = useGetCmoDeploymentsSummary(projectId);
    const warningsSummary = useGetCmoSystemWarningsSummary(projectId);

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Cirrus Migrate On-Premises Summary"} />
            <QueryResultWrapper queryResult={deploymentsSummary}>
                <Grid container spacing={2}>
                    <Grid
                        size={{
                            xs: 12,
                            lg: 4,
                        }}
                    >
                        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                            <CmoInsertionInfoCard data={deploymentsSummary.data?.summary.insertion} />
                            <CmoMigrationInfoCard data={deploymentsSummary.data?.summary.migration} />
                        </Box>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            lg: 8,
                        }}
                    >
                        <PerformanceCard data={deploymentsSummary.data?.summary} />
                        <CmoSystemOverviewDiagram data={deploymentsSummary.data?.summary} />
                    </Grid>
                </Grid>
            </QueryResultWrapper>
            <Box pt={2}>
                <CmoMigrationWarningsSummary projectId={projectId} />
            </Box>
        </ScreenContainer>
    );
};

// ======================
// CmoInsertionInfoCard
// ======================

interface CmoInsertionInfoCardProps {
    data: CmoInsertionSummary.AsObject;
}

const CmoInsertionInfoCard: React.FC<CmoInsertionInfoCardProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    return (
        <Card>
            <ListSubheader>{"CMO Insertion"}</ListSubheader>
            <CardContent sx={{ paddingTop: 0 }}>
                <Grid container spacing={1.5} p={0}>
                    <Grid size={4}>
                        <DarkFlatCard>
                            <Box p={1.5}>
                                <Stack direction={"column"} alignItems={"center"}>
                                    <Typography variant={"h5"}>{data.hosts}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Hosts`}
                                    </Typography>
                                </Stack>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                    <Grid size={4}>
                        <DarkFlatCard>
                            <Box p={1.5}>
                                <Stack direction={"column"} alignItems={"center"}>
                                    <Typography variant={"h5"}>{data.hostPorts}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Initiators`}
                                    </Typography>
                                </Stack>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                    <Grid size={4}>
                        <DarkFlatCard>
                            <Box p={1.5}>
                                <Stack direction={"column"} alignItems={"center"}>
                                    <Typography variant={"h5"}>{data.storagePorts}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Targets`}
                                    </Typography>
                                </Stack>
                            </Box>
                        </DarkFlatCard>
                    </Grid>
                    <Grid size={4}>
                        <Stack direction={"column"} spacing={1.5}>
                            <DarkFlatCard>
                                <Box p={1.5}>
                                    <Stack direction={"column"} alignItems={"center"}>
                                        <Typography variant={"h5"}>{data.volumes}</Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Volumes`}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </DarkFlatCard>
                            <DarkFlatCard>
                                <Box p={1.5}>
                                    <Stack direction={"column"} alignItems={"center"}>
                                        <Typography variant={"h5"}>{data.hostPorts}</Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Ports`}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </DarkFlatCard>
                        </Stack>
                    </Grid>
                    <Grid size={8}>
                        <DarkFlatCard sx={{ height: "100%" }}>
                            <CardContent>
                                <Stack sx={{ height: "100%" }} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                    <Box pb={1} sx={{ position: "relative", display: "inline-flex" }}>
                                        <CircularProgress
                                            variant="determinate"
                                            sx={{
                                                color: alpha(theme.palette.primary.main, 0.2),
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            size={84}
                                            thickness={4}
                                            value={100}
                                        />
                                        <CircularProgress thickness={4} size={84} variant={"determinate"} value={(data.nexusActive / data.nexusTotal) * 100} />

                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 6,
                                                right: 0,
                                                position: "absolute",
                                            }}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <Box display={"flex"} alignItems={"baseline"}>
                                                <Typography variant={"h6"}>{data.nexusActive}</Typography>
                                                <Typography variant={"caption"} color={"textSecondary"}>
                                                    &nbsp;{`/${data.nexusTotal}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Active Nexus`}
                                    </Typography>
                                </Stack>
                            </CardContent>
                        </DarkFlatCard>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

// ======================
// CmoMigrationInfoWithBoolCountCard
// ======================

interface CmoMigrationInfoCardProps {
    data: CmoMigrationSummary.AsObject;
}

const CmoMigrationInfoCard: React.FC<CmoMigrationInfoCardProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    const linearProgressStyle = useLinearProgressStyle();

    return (
        <Card sx={{ flexGrow: 1, marginTop: 2 }}>
            <ListSubheader>{"Active Migrations"}</ListSubheader>
            <Box p={2} pt={0}>
                <Stack direction={"column"} spacing={2}>
                    <DarkFlatCard>
                        <Box p={2}>
                            <Stack direction={"row"} spacing={1} alignItems={"baseline"} pb={1}>
                                <Typography variant={"h5"}>{formatKnownDataType(data.progressPercentage, KnownDataType.PERCENT)}</Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`(${formatKnownDataType(data.totalMigrated, KnownDataType.CAPACITY)} / ${formatKnownDataType(
                                        data.totalData,
                                        KnownDataType.CAPACITY
                                    )} migrated)`}
                                </Typography>
                            </Stack>
                            <LinearProgress sx={{ ...linearProgressStyle }} variant={"determinate"} value={data.progressPercentage} />
                        </Box>
                    </DarkFlatCard>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid size={6}>
                                <DarkFlatCard>
                                    <Box p={2}>
                                        <Typography variant={"h5"}>{data.sessionsActive}</Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Active Sessions`}
                                        </Typography>
                                    </Box>
                                </DarkFlatCard>
                            </Grid>
                            <Grid size={6}>
                                <DarkFlatCard>
                                    <Box p={2}>
                                        <Typography variant={"h5"}>{data.volumesTotal}</Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Volumes`}
                                        </Typography>
                                    </Box>
                                </DarkFlatCard>
                            </Grid>
                        </Grid>
                    </Box>
                    <DarkFlatCard>
                        <Box p={2}>
                            <Stack direction={"row"} spacing={1} alignItems={"baseline"} pb={1}>
                                <Typography variant={"h5"}>{formatKnownDataType(data.thinPercentage, KnownDataType.PERCENT)}</Typography>
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {`Thin Data Ratio`}
                                </Typography>
                            </Stack>
                            <LinearProgress variant={"determinate"} sx={{ ...linearProgressStyle }} value={data.thinPercentage} />
                            <Typography variant={"caption"} color={"textSecondary"}>
                                {`Total ${formatKnownDataType(data.totalThinData, KnownDataType.CAPACITY)} Reduced`}
                            </Typography>
                        </Box>
                    </DarkFlatCard>
                    <DarkFlatCard>
                        <Box p={2}>
                            <Stack direction={"row"} justifyContent={"space-between"} pb={1}>
                                <Box>
                                    <Typography variant={"h6"} sx={{ lineHeight: 1 }}>
                                        {formatKnownDataType(data.totalBase, KnownDataType.CAPACITY)}
                                    </Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Total Base Data`}
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: "right" }}>
                                    <Typography variant={"h6"} sx={{ lineHeight: 1 }}>
                                        {formatKnownDataType(data.totalChanged, KnownDataType.CAPACITY)}
                                    </Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {`Total Changed Data`}
                                    </Typography>
                                </Box>
                            </Stack>
                            <LinearProgress
                                sx={{
                                    ...linearProgressStyle,
                                    backgroundColor: (t: Theme) => t.palette.cirrus.main,
                                    [`& .${linearProgressClasses.bar}`]: {
                                        backgroundColor: (t: Theme) => t.palette.primary.light,
                                        borderRight: "3px solid white",
                                    },
                                    "& .MuiLinearProgress-dashed": {
                                        backgroundImage: "none",
                                        animation: "none",
                                    },
                                    "& .MuiLinearProgress-bar2Buffer": {
                                        backgroundColor: (t: Theme) => t.palette.secondary.main,
                                        borderRight: "none",
                                    },
                                }}
                                variant={"buffer"}
                                valueBuffer={data.totalBase > 0 ? 100 : 0}
                                value={data.totalBase + data.totalChanged ? (data.totalBase / (data.totalBase + data.totalChanged)) * 100 : 0}
                            />
                        </Box>
                    </DarkFlatCard>
                </Stack>
            </Box>
        </Card>
    );
};

// ======================
// PerformanceCard
// ======================

interface PerformanceCardProps {
    data: CmoDeploymentsSummary.AsObject;
}

const PerformanceCard: React.FC<PerformanceCardProps> = (p) => {
    const { data } = p;
    const linearProgressStyle = useLinearProgressStyle();
    if (!data.ioStats) {
        return (
            <Card>
                <ListSubheader>{"Performance"}</ListSubheader>
                <Box pt={2} pb={4} display={"flex"} justifyContent={"center"}>
                    <Typography>{`No Performance Data Available`}</Typography>
                </Box>
            </Card>
        );
    }
    const maxThroughputValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [data.migration.currentThroughput]);
    const maxIoValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [data.ioStats.totalThroughput]);
    return (
        <Card>
            <ListSubheader>{"Performance"}</ListSubheader>
            <Box p={2} pt={0}>
                <Stack direction={"row"} spacing={2}>
                    <Box width={"50%"}>
                        <DarkFlatCard>
                            <Box p={2}>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"}>
                                    <Typography variant={"h6"}>{formatKnownDataType(data.ioStats.totalThroughput, KnownDataType.THROUGHPUT)}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {formatKnownDataType(maxIoValue, KnownDataType.THROUGHPUT)}
                                    </Typography>
                                </Box>
                                <LinearProgress
                                    sx={{ ...linearProgressStyle }}
                                    color={"success"}
                                    variant={"determinate"}
                                    value={(data.ioStats.totalThroughput / maxIoValue) * 100}
                                />
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Current Production I/O"}
                                </Typography>
                            </Box>
                        </DarkFlatCard>
                    </Box>
                    <Box width={"50%"}>
                        <DarkFlatCard>
                            <Box p={2}>
                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"}>
                                    <Typography variant={"h6"}>{formatKnownDataType(data.migration.currentThroughput, KnownDataType.THROUGHPUT)}</Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {formatKnownDataType(maxThroughputValue, KnownDataType.THROUGHPUT)}
                                    </Typography>
                                </Box>
                                <LinearProgress
                                    sx={{ ...linearProgressStyle }}
                                    variant={"determinate"}
                                    value={(data.migration.currentThroughput / maxThroughputValue) * 100}
                                />
                                <Typography variant={"caption"} color={"textSecondary"}>
                                    {"Current Migration Rate"}
                                </Typography>
                            </Box>
                        </DarkFlatCard>
                    </Box>
                </Stack>
            </Box>
        </Card>
    );
};

// ======================
// CmoSystemOverviewDiagram
// ======================

interface CmoSystemOverviewDiagramProps {
    data: CmoDeploymentsSummary.AsObject;
}

export const CmoSystemOverviewDiagram: React.FC<CmoSystemOverviewDiagramProps> = (p) => {
    const { data } = p;
    const theme = useTheme();
    const connectorLineHeight = 48;
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    return (
        <Card sx={{ marginTop: 2 }}>
            <Stack direction={"column"} pt={2} pb={2} justifyContent={"center"} alignItems={"center"}>
                <Box>
                    <DarkFlatCard sx={{ width: "fit-content" }}>
                        <Box pt={2} pb={2} pr={8} pl={6}>
                            <Stack direction={"row"} spacing={3} justifyContent={"center"}>
                                <img height={72} width={"auto"} src={CmoHost} alt={"Host"} />
                                <Stack direction={"column"} spacing={1} justifyContent={"center"}>
                                    <Typography variant={"h6"} sx={{ lineHeight: 1 }}>
                                        {data.insertion.hosts} {"Hosts"}
                                    </Typography>
                                    <Chip size={"small"} label={`${data.insertion.hostPorts} Ports`} />
                                </Stack>
                            </Stack>
                        </Box>
                    </DarkFlatCard>
                </Box>
                <Box width={4} height={connectorLineHeight} sx={{ background: theme.palette.primary.main }} />
                <Box
                    sx={{
                        background: alpha(theme.palette.primary.main, 0.1),
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderRadius: 5,
                    }}
                    width={"60%"}
                >
                    <Box sx={{ padding: `16px 48px` }}>
                        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                            <Grid container spacing={1} justifyContent={"center"}>
                                {data.insertionMethodsList.map((method) => {
                                    return (
                                        <Grid key={method}>
                                            <Chip key={method} size={"small"} label={method} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <img height={72} width={"auto"} src={CmoSystem} alt={"CMO System"} />
                            <Typography variant={"h6"} sx={{ textAlign: "center" }}>{`${data.totalCmoSystems} CMO Systems`}</Typography>
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                {data.totalCmoSystems - data.cmoSystemsOnline > 0 && (
                                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                        <OnlineStatusIcon online={false} size={10} />
                                        <Typography variant={"caption"}>{`${data.totalCmoSystems - data.cmoSystemsOnline} Offline`}</Typography>
                                        <Typography variant={"caption"}> {`|`}</Typography>
                                    </Stack>
                                )}
                                <Typography variant={"caption"}>{`${data.nodeCount} Appliance Nodes`}</Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
                <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                    <Grid p={0} container spacing={0} justifyContent={"center"} width={"80%"}>
                        <Grid
                            size={{
                                xs: 12,
                                xl: 6,
                            }}
                        >
                            <Stack direction={"column"} alignItems={"center"}>
                                <Box width={4} height={connectorLineHeight} sx={{ background: theme.palette.primary.main }} />
                                <DarkFlatCard>
                                    <Box pr={4} pl={4} pt={2} pb={2}>
                                        <Stack direction={"column"} spacing={2} alignItems={"center"}>
                                            <Chip size={"small"} label={`${data.insertion.storagePorts} Ports`} />
                                            <Box sx={{ textAlign: "center" }} width={150}>
                                                <Typography variant={"h6"} sx={{ lineHeight: 1, textAlign: "center" }}>
                                                    {"Inserted Storage"}
                                                </Typography>
                                                <Typography variant={"caption"} color={"textSecondary"} sx={{ textAlign: "center" }}>
                                                    {data.insertion.storageVendorsList.join(", ")}
                                                </Typography>
                                            </Box>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                <img height={72} width={"auto"} src={CmoStorage} alt={"Storage"} />
                                                <Box p={2} sx={{ textAlign: "left" }}>
                                                    <Typography variant={"body1"} sx={{ fontWeight: 600, lineHeight: 0.8 }}>
                                                        {`${data.insertion.volumes} Volumes`}
                                                    </Typography>
                                                    <Typography variant={"caption"} color={"textSecondary"}>
                                                        {formatKnownDataType(data.insertion.storageCapacity, KnownDataType.CAPACITY)}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </DarkFlatCard>
                            </Stack>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                xl: 6,
                            }}
                        >
                            <Stack direction={"column"} alignItems={"center"}>
                                {isXl ? <Box width={4} height={connectorLineHeight} sx={{ background: theme.palette.primary.main }} /> : <Box height={16} />}
                                <DarkFlatCard>
                                    <Box pr={4} pl={4} pt={2} pb={2}>
                                        <Stack direction={"column"} spacing={2} alignItems={"center"}>
                                            <Chip size={"small"} label={`${data.destination.connectedPorts} Ports`} />
                                            <Box sx={{ textAlign: "center" }} width={150}>
                                                <Typography variant={"h6"} sx={{ lineHeight: 1, textAlign: "center" }}>
                                                    {"Destination Storage"}
                                                </Typography>
                                                <Typography variant={"caption"} color={"textSecondary"} sx={{ textAlign: "center" }}>
                                                    {data.destination.connectedStorageVendorsList.join(", ")}
                                                </Typography>
                                            </Box>
                                            <Stack direction={"row"} alignItems={"center"}>
                                                <img height={72} width={"auto"} src={CmoStorage} alt={"Storage"} />
                                                <Box p={2} sx={{ textAlign: "left" }}>
                                                    <Typography variant={"body1"} sx={{ fontWeight: 600, lineHeight: 0.8 }}>
                                                        {`${data.insertion.volumes} Volumes`}
                                                    </Typography>
                                                    <Typography variant={"caption"} color={"textSecondary"}>
                                                        {formatKnownDataType(data.insertion.storageCapacity, KnownDataType.CAPACITY)}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </DarkFlatCard>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>
        </Card>
    );
};
