import * as React from "react";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BlackTooltip: React.FC<TooltipProps> = styled(<TooltipProps,>({ ...props }) => (
    <Tooltip classes={{ popper: props.className }} children={props.children} title={props.title} {...props} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `rgba(0, 0, 0, .8)`,
        color: "white",
        boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: `rgba(0, 0, 0, .8)`,
    },
}));
