// ======================
// CmoMigrationSystemDetailsMigrationSessionsTab
// ======================

import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { CmoMigrationSessionsToggleButton, CmoMigrationSessionsToggleView } from "../sessions/CmoMigrationSessionsToggleView";

interface CmoMigrationSystemDetailsMigrationSessionsTabProps {
    projectId: string;
    systemId: string;
}

export const CmoMigrationSystemDetailsMigrationSessionsTab: React.FC<CmoMigrationSystemDetailsMigrationSessionsTabProps> = (p) => {
    const { projectId, systemId } = p;

    const [view, setView] = useState<"list" | "grid">("grid");

    return (
        <Box pt={1}>
            <Stack direction={"row"} spacing={1} pb={2}>
                <CmoMigrationSessionsToggleButton view={view} setView={setView} />
            </Stack>
            <CmoMigrationSessionsToggleView view={view} projectId={projectId} systemId={systemId} />
        </Box>
    );
};
