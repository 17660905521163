import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import {
    DeleteHostLicense,
    GetGalaxyMigrateDeploymentClusterDetails,
    GetHostLicenseShareableCapacityAmount,
    ListGalaxyMigrateDeployments,
    RemoveDeployment,
    RemoveOfflineDeployments,
} from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { DeploymentMutationKeys, DeploymentQueryKeys } from "../../common/QueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";
import { useAppServices } from "../app/services";
import { Box, Typography, useTheme } from "@mui/material";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import { DeploymentInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/deployment_pb";
import * as React from "react";

export const useListGalaxyMigrateDeployments = (
    projectId: string,
    onlyConnected: boolean = false,
    page: number,
    perPage: number,
    excludeHelperNodes?: boolean,
    systemIds?: string[],
    relayOnly?: boolean,
    clusterInfo?: boolean,
    applianceInfo?: boolean
) => {
    const apis = useGrpcApiStore();
    const sortFilterState = useGlobalTableSortFilterState().tableStateMap.get(DeploymentQueryKeys.listGmDeployments);
    const queryFn = async () => {
        const req = new ListGalaxyMigrateDeployments.Request()
            .setProjectId(projectId)
            .setPageParams(new PagerParams().setPage(page).setPerPage(perPage))
            .setOnlyConnected(onlyConnected)
            .setExcludeHelperNodes(excludeHelperNodes)
            .setBySystemIdsList(systemIds || [])
            .setOnlyCdcRelayEnabled(relayOnly || false)
            .setIncludeClusterInfo(clusterInfo || false)
            .setIncludeApplicationsInfo(applianceInfo || false);

        if (sortFilterState.sortState) {
            req.setSortParams(sortFilterState.sortFilterConfig.sort.getSortParam(sortFilterState.sortState));
        }

        for (let filter of sortFilterState.filters || []) {
            filter.fieldConfig.addFilterToRequest(req, filter.param);
        }

        const res = await apis.deploymentService.listGalaxyMigrateDeployments(req, null);
        return res?.toObject();
    };

    return useQuery({
        queryKey: [
            DeploymentQueryKeys.listGmDeployments,
            projectId,
            page,
            perPage,
            onlyConnected,
            excludeHelperNodes,
            systemIds,
            relayOnly,
            clusterInfo,
            applianceInfo,
            sortFilterState.sortState?.field.label,
            sortFilterState.sortState?.descending.label,
            sortFilterState.filters.map((f) => `${f.fieldConfig.label}: ${f.param.toObject().op} ${f.param.toObject().value}`),
        ],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useGetHostLicenseShareableCapacityAmount = (systemId: string) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new GetHostLicenseShareableCapacityAmount.Request().setSystemId(systemId);
        const res = await apis.deploymentService.getHostLicenseShareableCapacityAmount(req, null);
        return res?.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.GetHostLicenseShareableCapacityAmount, systemId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useRemoveGmDeployment = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (systemId: string) => {
        const req = new RemoveDeployment.Request().setSystemId(systemId);
        await apis.deploymentService.removeDeployment(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationFn,
        mutationKey: [DeploymentMutationKeys.removeDeployment],
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [DeploymentQueryKeys.listGmDeployments],
            });
        },
    });
};

export const useRemoveAllOfflineDeployments = (projectId: string) => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async () => {
        const req = new RemoveOfflineDeployments.Request().setProjectId(projectId);
        await apis.deploymentService.removeOfflineDeployments(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationFn,
        mutationKey: [DeploymentMutationKeys.removeAllOfflineDeployments],
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [DeploymentQueryKeys.listGmDeployments],
            });
        },
    });
};

export const useDeleteHostLicense = (from: "list" | "details") => {
    const { deploymentService, dialogService } = useAppServices();
    const t = useTheme();
    const dialogState = useGlobalDialogState();
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    const mutationFn = async (deployment: DeploymentInfo.AsObject) => {
        const confirmed = await dialogState.addConfirmDialog({
            title: "Delete Host License",
            message: (
                <Box>
                    <Typography>
                        THIS ACTION IS IRREVERSIBLE. By continuing, the current host license from {deployment.systemName} will be permanently deleted and will
                        no longer be renewable. If you wish to re-license this host in the future, a new Host Migration License will be required.
                    </Typography>
                    <br />
                    <Typography color={t.palette.warning.main}>
                        WARNING: Any remaining migration capacity quota and expiration time will not be recouped.
                    </Typography>
                    <br />
                </Box>
            ),
            autoConfirmationQuestionLine: true,
            typeToConfirm: "DELETE",
            okButtonProps: {
                color: "error",
            },
        });
        if (confirmed) {
            return await apis.deploymentService.deleteHostLicense(new DeleteHostLicense.Request().setSystemId(deployment.systemId), null);
        }
    };
    return useMutationTrackAndAlertError({
        mutationFn,
        mutationKey: [DeploymentMutationKeys.deleteHostLicense],
        onSuccess: async () => {
            if (from === "list") {
                await queryClient.refetchQueries({
                    queryKey: [DeploymentQueryKeys.listGmDeployments],
                });
            }
            if (from === "details") {
                await deploymentService.currentDeployment.fetchData();
            }
        },
    });
};

export const useGetGalaxyMigrateDeploymentClusterDetails = (projectId: string, clusterId: number) => {
    const apis = useGrpcApiStore();

    const queryFn = async () => {
        const req = new GetGalaxyMigrateDeploymentClusterDetails.Request().setProjectId(projectId).setClusterId(clusterId);
        const res = await apis.deploymentService.getGalaxyMigrateDeploymentClusterDetails(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [DeploymentQueryKeys.getGmDeploymentClusterDetails, projectId, clusterId],
        queryFn,
    });
};
