import { useNavigate, useParams } from "react-router-dom";
import { INTEGRATIONS_SUBROUTE } from "../../app/AppRoutes";
import { generateIntegrationsSubRoutePath } from "../IntegrationsCommon";
import { AzureRegion, AzureStorageProductRedundancy } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { convertTimestampObjectToDate } from "../../../common/utils/formatter";
import { useAppServices } from "../../app/services";
import { FeatureFlag } from "../../app/AppGlobalService";
import { Theme, useTheme } from "@mui/material";
import { CSSProperties } from "react";

export const useIsAzureStoragePlannerEnabled = () => {
    const { appGlobalService } = useAppServices();
    return appGlobalService.isFeatureEnabled(FeatureFlag.AZURE_CONSUMPTION_PLANNER);
};
export const AZURE_STORAGE_PLANNER_ROUTE = "azure-consumption-planner";

export const useNavigateToCreateNewAzureStoragePlannerAssessment = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const subroute = `${INTEGRATIONS_SUBROUTE.CREATE}/${AZURE_STORAGE_PLANNER_ROUTE}`;

    const p = generateIntegrationsSubRoutePath(subroute, projectId);
    return () => {
        navigate(p);
    };
};

export const useNavigateToAzureStoragePlanList = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const subroute = `${INTEGRATIONS_SUBROUTE.AZURE_STORAGE_PLANNER}`;

    const p = generateIntegrationsSubRoutePath(subroute, projectId);
    return () => {
        navigate(p);
    };
};

export const useNavigateToAzureStoragePlanDetails = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (storagePlanId: string) => {
        const subroute = `${INTEGRATIONS_SUBROUTE.AZURE_STORAGE_PLANNER}/${storagePlanId}`;
        const p = generateIntegrationsSubRoutePath(subroute, projectId);
        navigate(p);
    };
};
export interface AzureStoragePlannerStepProps {}

export const getAzureRegionDisplayNameFromEnumValue = (region: AzureRegion) => {
    const key = Object.keys(AzureRegion).find((k) => AzureRegion[k as keyof typeof AzureRegion] === region);
    return getAzureRegionDisplayName(key as keyof AzureRegion);
};

export const getAzureRegionDisplayName = (region: keyof AzureRegion) => {
    const splitName = region.split("_");
    return splitName
        .map((s) => {
            if (s === "US") {
                return "US";
            }
            if (s === "UK") {
                return "UK";
            }
            if (s === "UAE") {
                return "UAE";
            }
            return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
        })
        .join(" ");
};

export const getAzureStorageRedundancyDisplayName = (redundancy: AzureStorageProductRedundancy) => {
    if (redundancy === AzureStorageProductRedundancy.AZURE_STORAGE_REDUNDANCY_LOCAL) {
        return "LRS";
    }
    if (redundancy === AzureStorageProductRedundancy.AZURE_STORAGE_REDUNDANCY_ZONAL) {
        return "ZRS";
    } else {
        return "Unknown";
    }
};

export const getHostHistoryInsufficient = (registeredAt: Timestamp.AsObject, analyzePeriodMinutes: number): boolean => {
    const now = new Date();
    const registeredAtDate = convertTimestampObjectToDate(registeredAt);
    const diff = now.getTime() - registeredAtDate.getTime();
    const diffMinutes = diff / (1000 * 60);
    return diffMinutes < analyzePeriodMinutes;
};

export const useKeyValueTableStyle = (): CSSProperties => {
    const t = useTheme();
    return {
        width: "100%",
        borderWidth: 1.5,
        borderStyle: "solid",
        borderColor: `#3B4252`,
        borderRadius: 10,
        borderSpacing: 0,
    };
};

export const renderKeyValueTableColGroups = (numOfColGroups: number, theme: Theme) => {
    const colGroup = (
        <>
            <col style={{}} />
            <col
                style={{
                    minWidth: 200,
                }}
            />
        </>
    );
    const colGroupArray = [];
    for (let i = 0; i < numOfColGroups; i++) {
        colGroupArray.push(colGroup);
    }
    return colGroupArray;
};
