import { isDevelopment } from "../../common/utils/util";
import { Request, RpcError, StatusCode, UnaryInterceptor, UnaryResponse } from "grpc-web";
import { makeAutoObservable } from "mobx";
import { ProjectServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Project_apiServiceClientPb";
import { DeploymentServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Deployment_apiServiceClientPb";
import { IntegrationServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Integration_apiServiceClientPb";
import { getApiEndpoint } from "./grpcCommon";
import { PrivateEditionLocalServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Pelocal_apiServiceClientPb";
import { GeneralServiceClient } from "gc-web-proto/galaxycompletepb/apipb/ApiServiceClientPb";
import { AuthenticationServiceClient, LoginServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Auth_apiServiceClientPb";
import { HelpServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Help_apiServiceClientPb";
import { GalaxyMigrateServiceClient } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/Galaxymigrate_apiServiceClientPb";
import { WorkflowServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Workflow_apiServiceClientPb";
import { ChecklistServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Checklist_apiServiceClientPb";
import { LicenseBillingServiceClient } from "gc-web-proto/galaxycompletepb/apipb/License_apiServiceClientPb";
import { ReportServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Report_apiServiceClientPb";
import { SupportServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Support_apiServiceClientPb";

// https://grpc.io/blog/grpc-web-interceptor/#unary-interceptor-example

export class GRPCServices {
    private addr: string = `${getApiEndpoint()}`;

    onAuthError: (err: Error) => void;

    generalService: GeneralServiceClient;
    loginService: LoginServiceClient;
    authService: AuthenticationServiceClient;
    projectService: ProjectServiceClient;
    deploymentService: DeploymentServiceClient;
    helpService: HelpServiceClient;
    // galaxy migrate
    gmService: GalaxyMigrateServiceClient;
    integrationService: IntegrationServiceClient;
    workflowService: WorkflowServiceClient;
    checklistService: ChecklistServiceClient;
    licenseBillingService: LicenseBillingServiceClient;
    supportService: SupportServiceClient;
    privateEditionLocalService: PrivateEditionLocalServiceClient;
    reportService: ReportServiceClient;

    constructor() {
        // interceptor in reverse order
        const baseOptions = {
            withCredentials: true,
            unaryInterceptors: [isDevelopment() ? new reqResPrinterInterceptor() : null, this.makeAuthInterceptor()].filter((a) => !!a),
        };

        const addr = this.addr;
        this.generalService = new GeneralServiceClient(addr, null, { ...baseOptions });
        this.loginService = new LoginServiceClient(addr, null, { ...baseOptions });
        this.authService = new AuthenticationServiceClient(addr, null, { ...baseOptions });
        this.projectService = new ProjectServiceClient(addr, null, { ...baseOptions });
        this.deploymentService = new DeploymentServiceClient(addr, null, { ...baseOptions });
        this.gmService = new GalaxyMigrateServiceClient(addr, null, { ...baseOptions });
        this.helpService = new HelpServiceClient(addr, null, { ...baseOptions });
        this.integrationService = new IntegrationServiceClient(addr, null, { ...baseOptions });
        this.workflowService = new WorkflowServiceClient(addr, null, { ...baseOptions });
        this.checklistService = new ChecklistServiceClient(addr, null, { ...baseOptions });
        this.licenseBillingService = new LicenseBillingServiceClient(addr, null, { ...baseOptions });
        this.supportService = new SupportServiceClient(addr, null, { ...baseOptions });
        this.privateEditionLocalService = new PrivateEditionLocalServiceClient(addr, null, { ...baseOptions });
        this.reportService = new ReportServiceClient(addr, null, { ...baseOptions });
        makeAutoObservable(this);
    }

    private makeAuthInterceptor(): UnaryInterceptor<any, any> {
        const handler = (e: Error) => {
            console.debug("authentication error detected. clearing token");
            this.onAuthError(e);
        };

        return new authInterceptor(handler);
    }

    getAddress() {
        return this.addr;
    }
}

export class authInterceptor<REQ = any, RESP = any> implements UnaryInterceptor<REQ, RESP> {
    private onAuthenticationError: (e: Error) => void;

    constructor(onAuthenticationError: (e: Error) => void) {
        this.onAuthenticationError = onAuthenticationError;
    }

    async intercept(
        request: Request<REQ, RESP>,
        invoker: (request: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>
    ): Promise<UnaryResponse<REQ, RESP>> {
        const metadata = request.getMetadata();
        // if (!!token) {
        //     metadata["authorization"] = "Bearer " + token;
        // }

        try {
            return await invoker(request);
        } catch (e) {
            if ((e as RpcError).code === StatusCode.UNAUTHENTICATED) {
                this.onAuthenticationError(e as RpcError);
            }
            throw e;
        }
    }
}

export class reqResPrinterInterceptor<REQ = any, RESP = any> implements UnaryInterceptor<REQ, RESP> {
    async intercept(
        request: Request<REQ, RESP>,
        invoker: (request: Request<REQ, RESP>) => Promise<UnaryResponse<REQ, RESP>>
    ): Promise<UnaryResponse<REQ, RESP>> {
        const enabled = isDevelopment();
        try {
            const res = await invoker(request);
            if (enabled) {
                // @ts-ignore
                console.groupCollapsed(`gRPC Done - ${res.getStatus().code} - ${request.getMethodDescriptor().name}`);
                // @ts-ignore
                console.debug("request", request.getRequestMessage().toObject());
                // @ts-ignore
                console.debug("response", res.getResponseMessage().toObject());
                console.groupEnd();
            }
            return res;
        } catch (e) {
            // @ts-ignore
            console.groupCollapsed(`gRPC Done w/Error ${request.getMethodDescriptor().name}: ${e.message}`);
            // @ts-ignore
            console.debug("request", request.getRequestMessage().toObject());
            console.groupEnd();
            throw e;
        }
    }
}
