// ======================
// CmoMigrationSessionDetailsInfoTab
// ======================

import { CmoMigrationSessionDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cmo_pb";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    circularProgressClasses,
    darken,
    LinearProgress,
    linearProgressClasses,
    ListSubheader,
    Stack,
    Theme,
    Typography,
} from "@mui/material";
import {
    getCmoMigrationQosImpactLevelLabel,
    getCmoMigrationSessionStatusLabel,
    getCmoMigrationSessionVerificationStatusLabel,
    useLinearProgressStyle,
} from "../CmoMigrationCommon";
import React from "react";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { KVTable } from "../../../common/table/KVTable";
import { getAxesMaxValue } from "../../../common/utils/statsUtil";
import { FcCheckmark } from "react-icons/fc";
import { MdOutlineClose } from "react-icons/md";
import { CmoMigrationSessionVerificationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cmo_migration_session_verification_status_pb";
import Grid from "@mui/material/Grid2";

interface CmoMigrationSessionDetailsInfoTabProps {
    data: CmoMigrationSessionDetails.AsObject;
}

export const CmoMigrationSessionDetailsInfoTab: React.FC<CmoMigrationSessionDetailsInfoTabProps> = (p) => {
    const { data } = p;
    const maxValue = getAxesMaxValue(KnownDataType.THROUGHPUT, [
        data.sessionInfo.sessionStats.currentThroughput,
        data.sessionInfo.sessionStats.averageTransferThroughput,
        data.sessionInfo.sessionStats.averageThroughput,
    ]);
    const progressStyle = useLinearProgressStyle(true);

    return (
        <Box pt={1}>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        lg: 3,
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <CardContent>
                            <Box textAlign={"center"} pb={3}>
                                <Typography variant={"h5"}>{getCmoMigrationSessionStatusLabel(data.sessionInfo.sessionStatus)}</Typography>
                            </Box>
                            <Stack height={"100%"} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                <Box sx={{ position: "relative", display: "inline-flex" }}>
                                    <CircularProgress
                                        sx={{
                                            [`& .${circularProgressClasses.circle}`]: {
                                                color: "black",
                                            },
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        thickness={2}
                                        size={175}
                                        variant={"determinate"}
                                        value={100}
                                    />
                                    <CircularProgress
                                        sx={{
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: "round",
                                            },
                                        }}
                                        thickness={2}
                                        size={175}
                                        variant={"determinate"}
                                        color={"primary"}
                                        value={data.sessionInfo.sessionStats.progressPercentage}
                                    />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 6,
                                            right: 0,
                                            position: "absolute",
                                        }}
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                    >
                                        <Box display={"flex"} alignItems={"baseline"}>
                                            <Typography variant={"h4"}>
                                                {formatKnownDataType(data.sessionInfo.sessionStats.progressPercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Stack>
                            <Box textAlign={"center"} pt={3}>
                                <Typography variant={"body2"}>
                                    {`${formatKnownDataType(data.sessionInfo.sessionStats.totalMigrated, KnownDataType.CAPACITY)} / ${formatKnownDataType(
                                        data.sessionInfo.sessionStats.totalData,
                                        KnownDataType.CAPACITY
                                    )}`}
                                </Typography>
                                <Typography variant={"overline"}>{"Migrated"}</Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        lg: 4.5,
                    }}
                >
                    <Stack direction={"column"} spacing={2}>
                        <Card>
                            <CardContent>
                                <Box pb={2}>
                                    <Box>
                                        <Typography fontWeight={600}>{"Est. Time Remaining"}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            {data.sessionInfo.sessionStats.currentThroughput > 0
                                                ? formatDurationFromSeconds(
                                                      Math.round(data.sessionInfo.sessionStats.totalRemaining / data.sessionInfo.sessionStats.currentThroughput)
                                                  )
                                                : "--"}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box pb={2}>
                                    <Box pb={1}>
                                        <Typography fontWeight={600}>{"Migration Rate"}</Typography>
                                    </Box>
                                    <Box pb={1}>
                                        <LinearProgress
                                            sx={{ ...progressStyle }}
                                            value={(data.sessionInfo.sessionStats.currentThroughput / maxValue) * 100}
                                            variant={"determinate"}
                                        />
                                        <Box pt={0.5}>
                                            <Typography variant={"body1"} color={"textSecondary"}>
                                                {`${formatKnownDataType(data.sessionInfo.sessionStats.currentThroughput, KnownDataType.THROUGHPUT)} (Current)`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box pt={1}>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                [`& .${linearProgressClasses.bar}`]: {
                                                    backgroundColor: (t: Theme) => darken(t.palette.primary.main, 0.3),
                                                },
                                            }}
                                            value={(data.sessionInfo.sessionStats.averageThroughput / maxValue) * 100}
                                            variant={"determinate"}
                                        />
                                        <Box pt={0.5}>
                                            <Typography variant={"body1"} color={"textSecondary"}>
                                                {`${formatKnownDataType(data.sessionInfo.sessionStats.averageThroughput, KnownDataType.THROUGHPUT)} (Average)`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box pb={1}>
                                        <Typography fontWeight={600}>{"Transfer Rate"}</Typography>
                                    </Box>
                                    <Box>
                                        <LinearProgress
                                            sx={{ ...progressStyle }}
                                            color={"secondary"}
                                            value={(data.sessionInfo.sessionStats.averageTransferThroughput / maxValue) * 100}
                                            variant={"determinate"}
                                        />
                                        <Stack direction={"row"} justifyContent={"space-between"} pt={0.5}>
                                            <Typography variant={"body1"} color={"textSecondary"}>
                                                {`${formatKnownDataType(
                                                    data.sessionInfo.sessionStats.averageTransferThroughput,
                                                    KnownDataType.THROUGHPUT
                                                )} (Average)`}
                                            </Typography>
                                            <Typography variant={"body1"} color={"textSecondary"}>
                                                {`${formatKnownDataType(maxValue, KnownDataType.THROUGHPUT)}`}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <Box pb={1}>
                                    <Typography fontWeight={600}>{"Thin Data Ratio"}</Typography>
                                </Box>
                                <LinearProgress sx={{ ...progressStyle }} value={data.sessionInfo.sessionStats.thinPercentage} variant={"determinate"} />
                                <Box pt={0.5}>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        {`${formatKnownDataType(data.sessionInfo.sessionStats.thinPercentage, KnownDataType.PERCENT)} (${formatKnownDataType(
                                            data.sessionInfo.sessionStats.totalThinData,
                                            KnownDataType.CAPACITY
                                        )} Reduced)`}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                        <Card>
                            <Box p={2}>
                                <Stack direction={"row"} justifyContent={"space-between"} pb={1}>
                                    <Box>
                                        <Typography variant={"body1"} sx={{ lineHeight: 1 }}>
                                            {formatKnownDataType(data.sessionInfo.sessionStats.totalBase, KnownDataType.CAPACITY)}
                                        </Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Total Base Data`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: "right" }}>
                                        <Typography variant={"body1"} sx={{ lineHeight: 1 }}>
                                            {formatKnownDataType(data.sessionInfo.sessionStats.totalChanged, KnownDataType.CAPACITY)}
                                        </Typography>
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`Total Changed Data`}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <LinearProgress
                                    sx={{
                                        ...progressStyle,
                                        backgroundColor: (t: Theme) => t.palette.cirrus.main,
                                        [`& .${linearProgressClasses.bar}`]: {
                                            backgroundColor: (t: Theme) => t.palette.primary.light,
                                            borderRight: "3px solid white",
                                        },
                                        "& .MuiLinearProgress-dashed": {
                                            backgroundImage: "none",
                                            animation: "none",
                                        },
                                        "& .MuiLinearProgress-bar2Buffer": {
                                            backgroundColor: (t: Theme) => t.palette.secondary.main,
                                            borderRight: "none",
                                        },
                                    }}
                                    variant={"buffer"}
                                    valueBuffer={data.sessionInfo.sessionStats.totalBase > 0 ? 100 : 0}
                                    value={
                                        data.sessionInfo.sessionStats.totalBase + data.sessionInfo.sessionStats.totalChanged
                                            ? (data.sessionInfo.sessionStats.totalBase /
                                                  (data.sessionInfo.sessionStats.totalBase + data.sessionInfo.sessionStats.totalChanged)) *
                                              100
                                            : 0
                                    }
                                />
                                <Box pt={2}>
                                    <Typography variant={"body1"} sx={{ lineHeight: 1 }}>
                                        {formatKnownDataType(data.sessionInfo.sessionStats.totalCurrentChangedData, KnownDataType.CAPACITY)}
                                    </Typography>
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {"Current Changed Data"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Stack>
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        lg: 4.5,
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <ListSubheader>{"Session Configuration"}</ListSubheader>
                        <KVTable
                            data={[
                                {
                                    key: "Session ID",
                                    value: data.sessionInfo.localSessionId,
                                },
                                {
                                    key: "Project",
                                    value: data.sessionInfo.project.name,
                                },
                                {
                                    key: "CMO System",
                                    value: data.sessionInfo.deployment.systemName,
                                },
                                {
                                    key: "Created",
                                    value: formatKnownDataType(data.sessionInfo.createdAt, KnownDataType.DATE),
                                },
                                {
                                    key: "Current Impact Level",
                                    value: getCmoMigrationQosImpactLevelLabel(data.sessionInfo.currentQosLevel),
                                },
                                {
                                    key: "Duty Cycle",
                                    value: formatKnownDataType(data.sessionInfo.activeMigrationTimePercentage, KnownDataType.PERCENT),
                                },
                                {
                                    key: "Compression Ratio",
                                    value: `${data.sessionInfo.sessionStats.compressionRatio.toFixed(2)} : 1`,
                                },
                                {
                                    key: "Volumes Completed",
                                    value: `${data.sessionInfo.totalVolumesCompleted}/${data.sessionInfo.totalVolumes}`,
                                },
                            ]}
                        />
                    </Card>
                </Grid>
            </Grid>
            <SessionParametersSection data={data} />
        </Box>
    );
};

// ======================
// SessionParametersSection
// ======================

interface SessionParametersSectionProps {
    data: CmoMigrationSessionDetails.AsObject;
}

const SessionParametersSection: React.FC<SessionParametersSectionProps> = (p) => {
    const { data } = p;
    const verificationUnknown =
        data.sessionInfo.verificationStatus === CmoMigrationSessionVerificationStatus.CmoMigrationSessionVerificationStatus.VERIFICATION_UNKNOWN;
    return (
        <Box pt={2}>
            <Typography variant={"h6"}>{"Session Parameters"}</Typography>
            <Box pt={2}>
                <Card>
                    <Grid container spacing={2}>
                        <Grid>
                            <Grid container rowSpacing={2} columnSpacing={6} p={2}>
                                <Grid>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Description"}
                                    </Typography>
                                    <Typography variant={"body2"}>{data.sessionInfo.description || "N/A"}</Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Session End"}
                                    </Typography>
                                    <Typography variant={"body2"}>{formatKnownDataType(data.sessionInfo.endTime, KnownDataType.DATE) || "N/A"}</Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Auto-Sync"}
                                    </Typography>
                                    <Typography variant={"body2"}>
                                        {data.sessionInfo.autoResyncDuration > 0 ? `${data.sessionInfo.autoResyncDuration}m` : "Off"}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Block Size"}
                                    </Typography>
                                    <Typography variant={"body2"}>
                                        {formatKnownDataType(data.sessionInfo.migrationBlockSize, KnownDataType.CAPACITY) || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*<Grid>*/}
                        {/*    <Divider orientation={'vertical'}/>*/}
                        {/*</Grid>*/}
                        <Grid>
                            <Grid container rowSpacing={2} columnSpacing={4} p={2}>
                                <Grid>
                                    <Stack direction={"row"} spacing={3} height={"100%"}>
                                        <Box>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                {"Encryption"}
                                            </Typography>
                                            <BooleanIcon bool={data.sessionInfo.encryptionEnabled} />
                                        </Box>

                                        {/*<Divider orientation={"vertical"}/>*/}
                                    </Stack>
                                </Grid>
                                <Grid>
                                    <Stack direction={"row"} spacing={3} height={"100%"}>
                                        <Box>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                {"Thin Disk"}
                                            </Typography>
                                            <BooleanIcon bool={data.sessionInfo.thinDestination} />
                                        </Box>

                                        {/*<Divider orientation={"vertical"}/>*/}
                                    </Stack>
                                </Grid>
                                <Grid>
                                    <Stack direction={"row"} spacing={3} height={"100%"}>
                                        <Box>
                                            <Typography variant={"body2"} color={"textSecondary"}>
                                                {"Auto Path Retry"}
                                            </Typography>
                                            <BooleanIcon bool={data.sessionInfo.autoPathRetry} />
                                        </Box>

                                        {/*<Divider orientation={"vertical"}/>*/}
                                    </Stack>
                                </Grid>
                                <Grid>
                                    <Typography variant={"body2"} color={"textSecondary"}>
                                        {"Compression"}
                                    </Typography>
                                    <BooleanIcon bool={data.sessionInfo.hasCompression} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
            {!verificationUnknown && (
                <Box pt={2}>
                    <Card sx={{ height: "100%" }}>
                        <ListSubheader>{"LUN Verification"}</ListSubheader>
                        <KVTable
                            data={[
                                {
                                    key: "Verification Status",
                                    value: getCmoMigrationSessionVerificationStatusLabel(data.sessionInfo.verificationStatus),
                                },
                                {
                                    key: "Verification Speed",
                                    value: formatKnownDataType(data.sessionInfo.verificationThroughput, KnownDataType.THROUGHPUT),
                                },
                                {
                                    key: "Verification Percent",
                                    value: formatKnownDataType(data.sessionInfo.verificationProgress, KnownDataType.PERCENT),
                                },
                            ]}
                        />
                    </Card>
                </Box>
            )}
        </Box>
    );
};

// ======================
// BooleanIcon
// ======================

interface BooleanIconProps {
    bool: boolean;
}

const BooleanIcon: React.FC<BooleanIconProps> = (p) => {
    const { bool } = p;
    return bool ? <FcCheckmark size={20} /> : <MdOutlineClose color={"red"} size={20} />;
};
