// Project: GalaxyComplete
// Created: 10/31/20 by sammy
// File: FormattedDisplay

import * as React from "react";
import { observer } from "mobx-react-lite";
import { formatKnownDataType, KnownDataType } from "./utils/formatter";
import { Tooltip, Typography } from "@mui/material";

// ======================
// FormattedDisplay
// ======================
interface FormattedDisplayProps {
    dataType: KnownDataType;
    value: any;
}

export const FormattedDisplay: React.FC<FormattedDisplayProps> = observer((p) => {
    const v = formatKnownDataType(p.value, p.dataType);
    if (p.value instanceof Date && p.dataType === KnownDataType.DATE_RELATIVE) {
        return (
            <Tooltip title={formatKnownDataType(p.value, KnownDataType.DATE)} arrow>
                <span>{v}</span>
            </Tooltip>
        );
    }
    return v;
});
