// ======================
// HostLicenseTransactionSection
// ======================

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useAppServices } from "../../app/services";
import { useInitData } from "../../core/data/DataLoaderHooks";
import { Box, Card, ListSubheader, Theme, Typography, useTheme } from "@mui/material";
import { ServerListData } from "../../core/data/ListData";
import { ListHostLicenseTransactions } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { HostLicenseTxInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { ColumnDef, DataTable } from "../../../common/table/DataTable";
import { getLicenseTransactionTypeDisplayValue } from "../../license/LicenseCommon";
import { convertTimestampObjectToDate, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { formatDistanceStrict, isEqual } from "date-fns";
import { LicenseVaultTransactionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_transaction_type_pb";
interface HostLicenseTransactionSectionProps {}

export const HostLicenseTransactionSection: React.FC<HostLicenseTransactionSectionProps> = observer((p) => {
    const { deploymentService } = useAppServices();

    const poll = async () => {
        await deploymentService.galaxyMigrateHostLicenseTransactions.fetchData();
    };
    useInitData({
        poll: poll,
        pollInterval: 3,
        deinit: () => deploymentService.galaxyMigrateHostLicenseTransactions.resetData(),
    });

    return (
        <Card>
            <ListSubheader>Host License Transaction History</ListSubheader>
            <HostLicenseTransactionTable onTableStateChange={poll} serverData={deploymentService.galaxyMigrateHostLicenseTransactions} />
        </Card>
    );
});

// ======================
// HostLicenseTransactionTable
// ======================

interface HostLicenseTransactionTableProps {
    onTableStateChange: () => void;
    serverData: ServerListData<ListHostLicenseTransactions.Response.AsObject, HostLicenseTxInfo.AsObject>;
}

const HostLicenseTransactionTable: React.FC<HostLicenseTransactionTableProps> = observer((p) => {
    const t = useTheme();
    const cols: ColumnDef<HostLicenseTxInfo.AsObject>[] = [
        {
            id: "description",
            label: "Description",
            getter: (r) => r,
            renderer: (_, r) => {
                return (
                    <Box>
                        <Typography>{r.description}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {getLicenseTransactionTypeDisplayValue(r.type)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "capacity",
            label: "Migration Capacity",
            getter: (r) => r,
            renderer: (_, r) => {
                return (
                    <Box>
                        <Typography>{getMigrationCapacityTextByTransactionType(r.migrationCapacityChanged, r.type, t)}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            {formatKnownDataType(r.migrationCapacityAfter, KnownDataType.CAPACITY)} Left
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "expiration",
            label: "Expiration",
            getter: (r) => r,
            renderer: (_, r) => {
                const previousExpDate = convertTimestampObjectToDate(r.expirationBefore);
                const newExpDate = convertTimestampObjectToDate(r.expirationAfter);
                const extension = formatDistanceStrict(previousExpDate, newExpDate);

                if (r.type === LicenseVaultTransactionType.LicenseVaultTransactionType.CONSUMPTION && isEqual(previousExpDate, newExpDate)) {
                    return "--";
                }

                return (
                    <Box>
                        <Typography color={t.palette.success.main}>{extension}</Typography>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            to {formatKnownDataType(newExpDate, KnownDataType.DATE)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            id: "created",
            label: "Created",
            getter: (r) => formatKnownDataType(convertTimestampObjectToDate(r.timestamp), KnownDataType.DATE),
        },
    ];
    return (
        <DataTable
            cols={cols}
            loading={p.serverData.loading}
            rows={p.serverData.data?.itemsList}
            state={p.serverData.tableState}
            onTableStateChange={p.onTableStateChange}
            pagerMeta={p.serverData.data?.pagerMeta}
        />
    );
});

const getMigrationCapacityTextByTransactionType = (
    capacity: number,
    transactionType: LicenseVaultTransactionType.LicenseVaultTransactionType,
    theme: Theme
) => {
    if (transactionType === LicenseVaultTransactionType.LicenseVaultTransactionType.CREDIT) {
        return <Typography color={theme.palette.success.main}>{formatKnownDataType(capacity, KnownDataType.CAPACITY)}</Typography>;
    }
    if (
        transactionType === LicenseVaultTransactionType.LicenseVaultTransactionType.CONSUMPTION ||
        transactionType === LicenseVaultTransactionType.LicenseVaultTransactionType.TRANSFER
    ) {
        return <Typography color={"error"}>({formatKnownDataType(Math.abs(capacity), KnownDataType.CAPACITY)})</Typography>;
    }
};
