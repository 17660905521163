import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { CreateLabel, DeleteLabel, ListAllLabels, UpdateLabel } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { DeploymentMutationKeys, ProjectMutationKeys, ProjectQueryKeys } from "../../common/QueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AddDeploymentLabel, RemoveDeploymentLabel } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";

export const useListProjectLabels = (projectId: string) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListAllLabels.Request().setProjectId(projectId);
        const res = await apis.projectService.listAllLabels(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [ProjectQueryKeys.listAllLabels, projectId],
        queryFn: queryFn,
        refetchInterval: 60 * 1000,
    });
};

export const useCreateNewLabel = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (req: CreateLabel.Request) => {
        const res = await apis.projectService.createLabel(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectMutationKeys.createLabel],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.listAllLabels],
            });
        },
    });
};

export const useUpdateLabel = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (req: UpdateLabel.Request) => {
        const res = await apis.projectService.updateLabel(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectMutationKeys.updateLabel],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.listAllLabels],
            });
        },
    });
};

export const useDeleteLabel = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (req: DeleteLabel.Request) => {
        const res = await apis.projectService.deleteLabel(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [ProjectMutationKeys.deleteLabel],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [ProjectQueryKeys.listAllLabels],
            });
        },
    });
};

export const useAddDeploymentLabel = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (req: AddDeploymentLabel.Request) => {
        const res = await apis.deploymentService.addDeploymentLabel(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [DeploymentMutationKeys.addDeploymentLabel],
        mutationFn: mutationFn,
    });
};

export const useRemoveDeploymentLabel = () => {
    const apis = useGrpcApiStore();
    const mutationFn = async (req: RemoveDeploymentLabel.Request) => {
        const res = await apis.deploymentService.removeDeploymentLabel(req, null);
        return res.toObject();
    };

    return useMutationTrackAndAlertError({
        mutationKey: [DeploymentMutationKeys.removeDeploymentLabel],
        mutationFn: mutationFn,
    });
};
