// ======================
// AwsParams
// ======================

import { AutoAlloc } from "gc-web-proto/galaxymigratepb/galaxy_migrate_types_pb";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { useMigrateOpsLocalDataMigrationWizardState } from "./MigrateOpsLocalDataMigrationWizardState";
import { useMountEffect } from "../../../../common/hooks/hookslib";
import * as React from "react";

interface AwsParamsProps {
    defaultParams: AutoAlloc.VolumeParams.AWS.AsObject;
}

export const AwsParams: React.FC<AwsParamsProps> = (p) => {
    const { defaultParams } = p;

    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    const getVolumeTypeLabel = (type: AutoAlloc.VolumeParams.AWS.VolumeType) => {
        if (type === AutoAlloc.VolumeParams.AWS.VolumeType.GP2) {
            return "General Purpose SSD Gen 2 (GP2)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.GP3) {
            return "General Purpose SSD Gen 3 (GP3)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.IO1) {
            return "Provisioned IOPS SSD Gen 1 (IO1)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.IO2) {
            return "Provisioned IOPS SSD Gen 2 (IO2)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.ST1) {
            return "Throughput Optimized HDD (ST1)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.SC1) {
            return "Cold HDD (SC1)";
        } else if (type === AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD) {
            return "Magnetic (Standard)";
        }
    };

    const volumeTypesSelection = [
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.GP2,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.GP2),
            value: "GP2",
        },
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.GP3,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.GP3),
            value: "GP3",
            customIops: true,
        },
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.IO1,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.IO1),
            value: "IO1",
            customIops: true,
        },

        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.IO2,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.IO2),
            value: "IO2",
            customIops: true,
        },
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.ST1,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.ST1),
            value: "ST1",
        },
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.SC1,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.SC1),
            value: `SC1`,
        },
        {
            type: AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD,
            label: getVolumeTypeLabel(AutoAlloc.VolumeParams.AWS.VolumeType.STANDARD),
            value: "Standard",
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.aws) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                aws: {
                    volume_type: volumeTypesSelection.find((t) => t.type === defaultParams.volumeType)?.value,
                    iops: defaultParams.iops,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Class"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={volumeTypesSelection.find((t) => t.type === defaultParams.volumeType)?.value}
                            value={currentParams?.aws?.volume_type}
                            onChange={(e) => {
                                if (!volumeTypesSelection.filter((s) => s.customIops === true).find((s) => s.value === e.target.value)) {
                                    wizardState.setOperationJsonField(
                                        ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "aws"],
                                        {
                                            volume_type: e.target.value,
                                        }
                                    );
                                } else {
                                    wizardState.setOperationJsonField(
                                        [
                                            "config",
                                            "migrateops_local_data_migration",
                                            "destination",
                                            "default_auto_allocation_volume_params",
                                            "aws",
                                            "volume_type",
                                        ],
                                        e.target.value
                                    );
                                }
                            }}
                        >
                            {volumeTypesSelection.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        defaultValue={defaultParams.iops}
                        value={currentParams?.aws?.iops}
                        label={"IOPS"}
                        type={"number"}
                        disabled={!volumeTypesSelection.filter((s) => s.customIops === true).find((s) => s.value === currentParams?.aws?.volume_type)}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "aws", "iops"],
                                Number(e.target.value)
                            );
                        }}
                    />
                    <Stack direction={"row"} spacing={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"secondary"}
                                    value={currentParams?.aws?.ebs_encryption}
                                    onChange={(e) => {
                                        wizardState.setOperationJsonField(
                                            [
                                                "config",
                                                "migrateops_local_data_migration",
                                                "destination",
                                                "default_auto_allocation_volume_params",
                                                "aws",
                                                "ebs_encryption",
                                            ],
                                            e.target.checked
                                        );
                                    }}
                                />
                            }
                            label={"EBS Encryption"}
                        />
                        <TextField
                            fullWidth
                            variant={"filled"}
                            disabled={!currentParams?.aws?.ebs_encryption}
                            label={"KMS Key"}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "aws", "kms_key"],
                                    e.target.value
                                );
                            }}
                        />
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
};

interface PureCBSParamsProps {
    defaultParams: AutoAlloc.VolumeParams.PureCBS.AsObject;
}

export const PureCbsParams: React.FC<PureCBSParamsProps> = (p) => {
    const { defaultParams } = p;

    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.purecbs) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                purecbs: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    {"No volume params to set."}
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// AzureParams
// ======================

interface AzureParamsProps {
    defaultParams: AutoAlloc.VolumeParams.Azure.AsObject;
}

export const AzureParams: React.FC<AzureParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    const storageTypesSelection = [
        {
            type: AutoAlloc.VolumeParams.Azure.StorageType.ULTRA_SSD,
            label: "Ultra Disk Storage",
            value: "ULTRA_SSD",
            customIops: true,
        },
        {
            type: AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM_SSD_V2,
            label: "Premium SSD v2",
            value: "PREMIUM_SSD_V2",
            customIops: true,
        },
        {
            type: AutoAlloc.VolumeParams.Azure.StorageType.PREMIUM,
            label: "Premium SSD",
            value: "PREMIUM_SSD",
        },
        {
            type: AutoAlloc.VolumeParams.Azure.StorageType.STANDARD_SSD,
            label: "Standard SSD",
            value: "STANDARD_SSD",
        },
        {
            type: AutoAlloc.VolumeParams.Azure.StorageType.STANDARD,
            label: "Standard HDD",
            value: "STANDARD",
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.azure) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                azure: {
                    storage_type: storageTypesSelection.find((t) => t.type === defaultParams.storageType)?.value,
                    iops: defaultParams.iops,
                    throughput: defaultParams.throughput,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Class"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={storageTypesSelection.find((t) => t.type === defaultParams.storageType)?.value}
                            value={currentParams?.azure?.storage_type}
                            onChange={(e) => {
                                if (!storageTypesSelection.filter((s) => s.customIops === true).find((s) => s.value === e.target.value)) {
                                    wizardState.setOperationJsonField(
                                        ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "azure"],
                                        {
                                            storage_type: e.target.value,
                                        }
                                    );
                                } else {
                                    wizardState.setOperationJsonField(
                                        [
                                            "config",
                                            "migrateops_local_data_migration",
                                            "destination",
                                            "default_auto_allocation_volume_params",
                                            "azure",
                                            "storage_type",
                                        ],
                                        e.target.value
                                    );
                                }
                            }}
                        >
                            {storageTypesSelection.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.azure?.iops}
                        label={"IOPS"}
                        type={"number"}
                        disabled={!storageTypesSelection.filter((s) => s.customIops === true).find((s) => s.value === currentParams?.azure?.storage_type)}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "azure", "iops"],
                                Number(e.target.value)
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.azure?.throughput}
                        disabled={!storageTypesSelection.filter((s) => s.customIops === true).find((s) => s.value === currentParams?.azure?.storage_type)}
                        label={"Throughput"}
                        type={"number"}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">MB/s</InputAdornment>,
                        }}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "azure", "throughput"],
                                Number(e.target.value)
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

interface DgsParamsProps {
    defaultParams: AutoAlloc.VolumeParams.DGS.AsObject;
}

export const DgsParams: React.FC<DgsParamsProps> = (p) => {
    const { defaultParams } = p;

    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.dgs) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                dgs: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    {"No volume params to set."}
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// NetAppParams
// ======================

interface NetAppParamsProps {
    defaultParams: AutoAlloc.VolumeParams.NetApp.AsObject;
}

export const NetAppParams: React.FC<NetAppParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.netapp) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                netapp: {
                    fractional_reserve: defaultParams.fractionalReserve,
                    snapshot_reserve: defaultParams.snapshotReserve,
                    additional_capacity_percent: defaultParams.additionalCapacityPercent,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.netapp?.qos_policy_name}
                        label={"QOS Policy Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "netapp",
                                    "qos_policy_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Fractional Reserve"}</InputLabel>
                        <Select
                            variant={"filled"}
                            value={currentParams?.netapp?.fractional_reserve}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    [
                                        "config",
                                        "migrateops_local_data_migration",
                                        "destination",
                                        "default_auto_allocation_volume_params",
                                        "netapp",
                                        "fractional_reserve",
                                    ],
                                    Number(e.target.value)
                                );
                            }}
                        >
                            <MenuItem value={0}>{"0"}</MenuItem>
                            <MenuItem value={100}>{"100"}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.netapp?.snapshot_reserve}
                        label={"Snapshot Reserve"}
                        type={"number"}
                        helperText={"The percentage of the volume reserved for snapshots. Enter a number between 0 and 99."}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
                        }}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "netapp",
                                    "snapshot_reserve",
                                ],
                                Number(e.target.value)
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.netapp?.additional_capacity_percent}
                        label={"Additional Capacity Percent"}
                        type={"number"}
                        helperText={"An additional percent capacity to reserve for the volume over the lun capacity."}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{"%"}</InputAdornment>,
                        }}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "netapp",
                                    "additional_capacity_percent",
                                ],
                                Number(e.target.value)
                            );
                        }}
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"secondary"}
                                    onChange={(e) => {
                                        wizardState.setOperationJsonField(
                                            [
                                                "config",
                                                "migrateops_local_data_migration",
                                                "destination",
                                                "default_auto_allocation_volume_params",
                                                "netapp",
                                                "volume_guarantee",
                                            ],
                                            e.target.checked
                                        );
                                    }}
                                />
                            }
                            label={"Volume Guarantee"}
                        />
                        <FormHelperText>
                            {
                                "Determines if the entire size of the volume is reserved up front even if the volume is not fully utilized. If checked, the entire size of the volume is reserved. Otherwise, the volume is thin provisioned. "
                            }
                        </FormHelperText>
                    </Box>
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// PowerstoreParams
// ======================

interface PowerStoreParams {
    defaultParams: AutoAlloc.VolumeParams.EMCPowerStore.AsObject;
}

export const PowerStoreParams: React.FC<PowerStoreParams> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.powerstore) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                powerstore: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.powerstore?.protection_policy_name}
                        label={"Protection Policy Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "powerstore",
                                    "protection_policy_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.powerstore?.performance_policy_name}
                        label={"Performance Policy Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "powerstore",
                                    "performance_policy_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// PowerMaxParams
// ======================

interface PowerMaxParamsProps {
    defaultParams: AutoAlloc.VolumeParams.EMCPowerMax.AsObject;
}

export const PowerMaxParams: React.FC<PowerMaxParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    const serviceLevelOptions = [
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.DIAMOND,
            value: "Diamond",
            label: "Diamond",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.PLATINUM,
            value: "Platinum",
            label: "Platinum",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.GOLD,
            value: "Gold",
            label: "Gold",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.SILVER,
            value: "Silver",
            label: "Silver",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.BRONZE,
            value: "Bronze",
            label: "Bronze",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerMax.ServiceLevel.OPTIMIZED,
            value: "Optimized",
            label: "Optimized",
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.powermax) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                powermax: {
                    service_level: serviceLevelOptions.find((s) => s.type === defaultParams.serviceLevel)?.value,
                    storage_pool: "",
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Class"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={serviceLevelOptions.find((t) => t.type === defaultParams.serviceLevel)?.value}
                            value={currentParams?.powermax?.service_level}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    [
                                        "config",
                                        "migrateops_local_data_migration",
                                        "destination",
                                        "default_auto_allocation_volume_params",
                                        "powermax",
                                        "service_level",
                                    ],
                                    e.target.value
                                );
                            }}
                        >
                            {serviceLevelOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.powermax?.storage_pool}
                        label={"Storage Pool"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "powermax",
                                    "storage_pool",
                                ],
                                e.target.value
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// GcpParams
// ======================

interface GcpParamsProps {
    defaultParams: AutoAlloc.VolumeParams.GCP.AsObject;
}

export const GcpParams: React.FC<GcpParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    const getDiskTypeLabel = (type: AutoAlloc.VolumeParams.GCP.DiskType) => {
        if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD) {
            return "Standard Persistent Disk";
        } else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED) {
            return "Balanced Persistent Disk";
        } else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD) {
            return "SSD Persistent Disk";
        } else if (type === AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME) {
            return "Extreme Persistent Disk";
        }
    };
    const diskTypeOptions = [
        {
            type: AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD,
            value: "PD_STANDARD",
            label: getDiskTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_STANDARD),
        },
        {
            type: AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED,
            value: "PD_BALANCED",
            label: getDiskTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_BALANCED),
        },
        {
            type: AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD,
            value: "PD_SSD",
            label: getDiskTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_SSD),
        },

        {
            type: AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME,
            value: "PD_EXTREME",
            label: getDiskTypeLabel(AutoAlloc.VolumeParams.GCP.DiskType.PD_EXTREME),
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.gcp) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                gcp: {
                    disk_type: diskTypeOptions.find((s) => s.type === defaultParams.diskType)?.value,
                    iops: defaultParams.iops,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Type"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={diskTypeOptions.find((t) => t.type === defaultParams.diskType)?.value}
                            value={currentParams?.gcp?.disk_type}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "gcp", "disk_type"],
                                    e.target.value
                                );
                            }}
                        >
                            {diskTypeOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.gcp?.iops}
                        label={"IOPS"}
                        type={"number"}
                        disabled={currentParams?.gcp?.disk_type !== "PD_EXTREME"}
                        helperText={"Enter a number from 10,000 - 120,000."}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "gcp", "iops"],
                                Number(e.target.value)
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// OracleParams
// ======================

interface OracleParamsProps {
    defaultParams: AutoAlloc.VolumeParams.Oracle.AsObject;
}

export const OracleParams: React.FC<OracleParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.oracle) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                oracle: {
                    vpus: defaultParams.vpus,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.oracle?.vpus}
                        label={"VPUs"}
                        type={"number"}
                        helperText={"Enter a number between 0 - 120."}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "oracle", "vpus"],
                                Number(e.target.value)
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// SilkSdpParams
// ======================

interface SilkSdpParamsProps {
    defaultParams: AutoAlloc.VolumeParams.SilkSdp.AsObject;
}

export const SilkSdpParams: React.FC<SilkSdpParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.silksdp) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                silksdp: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.silksdp?.volume_group_name}
                        label={"Volume Group Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "silksdp",
                                    "volume_group_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// DigitalOceanParams
// ======================

interface DigitalOceanParamsProps {
    defaultParams: AutoAlloc.VolumeParams.DigitalOcean.AsObject;
}

export const DigitalOceanParams: React.FC<DigitalOceanParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.digitalocean) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                digitalocean: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.digitalocean?.size_gib}
                        label={"Block Storage Volume Size"}
                        type={"number"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "digitalocean",
                                    "size_gib",
                                ],
                                Number(e.target.value)
                            );
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">{"GiB"}</InputAdornment>,
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// PowerFlexParams
// ======================

interface PowerFlexParamsProps {
    defaultParams: AutoAlloc.VolumeParams.EMCPowerFlex.AsObject;
}

export const PowerFlexParams: React.FC<PowerFlexParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    const volumeTypeOptions = [
        {
            type: AutoAlloc.VolumeParams.EMCPowerFlex.PowerFlexVolumeType.THICK_PROVISIONED,
            value: "THICK_PROVISIONED",
            label: "Thick-Provisioned",
        },
        {
            type: AutoAlloc.VolumeParams.EMCPowerFlex.PowerFlexVolumeType.THIN_PROVISIONED,
            value: "THIN_PROVISIONED",
            label: "Thin-Provisioned",
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.powerflex) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                powerflex: {
                    disk_type: volumeTypeOptions.find((s) => s.type === defaultParams.volumeType)?.value,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.powerflex?.storage_pool_name}
                        label={"Storage Pool Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "powerflex",
                                    "storage_pool_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.powerflex?.protection_domain_name}
                        label={"Protection Domain Name"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "powerflex",
                                    "protection_domain_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Type"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={volumeTypeOptions.find((t) => t.type === defaultParams.volumeType)?.value}
                            value={currentParams?.powerflex?.volume_type}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    [
                                        "config",
                                        "migrateops_local_data_migration",
                                        "destination",
                                        "default_auto_allocation_volume_params",
                                        "powerflex",
                                        "volume_type",
                                    ],
                                    e.target.value
                                );
                            }}
                        >
                            {volumeTypeOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// AzureSanParams
// ======================

interface AzureSanParamsProps {
    defaultParams: AutoAlloc.VolumeParams.AzureElasticSAN.AsObject;
}

export const AzureSanParams: React.FC<AzureSanParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.azuresan) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                azuresan: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.azuresan?.iqn}
                        label={"IQN"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "azuresan", "iqn"],
                                e.target.value
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.azuresan?.address}
                        label={"Address"}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "azuresan", "address"],
                                e.target.value
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// VmwareParams
// ======================

interface VmwareParamsProps {
    defaultParams: AutoAlloc.VolumeParams.VMware.AsObject;
}

export const VmwareParams: React.FC<VmwareParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;
    const provisioningOptions = [
        {
            type: AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THIN,
            value: "THIN",
            label: "Thin Provisioning",
        },
        {
            type: AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THICK,
            value: "THICK",
            label: "Thick Provisioning",
        },
        {
            type: AutoAlloc.VolumeParams.VMware.DiskProvisioningMode.THICK_EAGER_ZEROED,
            value: "THICK_EAGER_ZEROED",
            label: "Thick Eager Zeroed",
        },
    ];

    const diskModeOptions = [
        {
            type: AutoAlloc.VolumeParams.VMware.DiskMode.PERSISTENT,
            value: "PERSISTENT",
            label: "Persistent",
        },
        {
            type: AutoAlloc.VolumeParams.VMware.DiskMode.NONPERSISTENT,
            value: "NONPERSISTENT",
            label: "Non-Persistent",
        },
        {
            type: AutoAlloc.VolumeParams.VMware.DiskMode.INDEPENDENT_PERSISTENT,
            value: "INDEPENDENT_PERSISTENT",
            label: "Independent Persistent",
        },
        {
            type: AutoAlloc.VolumeParams.VMware.DiskMode.INDEPENDENT_NONPERSISTENT,
            value: "INDEPENDENT_NONPERSISTENT",
            label: "Independent Non-Persistent",
        },
    ];

    useMountEffect(() => {
        if (!currentParams?.vmware) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                vmware: {
                    provisioning: defaultParams.provisioning,
                    disk_mode: defaultParams.diskMode,
                },
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.vmware?.datastore}
                        label={"Datastore"}
                        helperText={"If not specified, same datastore as virtual machine (vmx file) will be used."}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "vmware", "datastore"],
                                e.target.value
                            );
                        }}
                    />
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.vmware?.directory}
                        label={"Directory"}
                        helperText={
                            "Directory inside the datastore to place the vmdk. If not specified, same folder as virtual machine will be used. Folder will be created automatically if it does not already exist."
                        }
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "vmware", "directory"],
                                e.target.value
                            );
                        }}
                    />
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Provisioning"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={provisioningOptions.find((t) => t.type === defaultParams.provisioning)?.value}
                            value={currentParams?.vmware?.provisioning}
                            onChange={(e) => {
                                if (e.target.value !== "THICK_EAGER_ZEROED") {
                                    wizardState.setOperationJsonField(
                                        [
                                            "config",
                                            "migrateops_local_data_migration",
                                            "destination",
                                            "default_auto_allocation_volume_params",
                                            "vmware",
                                            "sharing",
                                        ],
                                        false
                                    );
                                }
                                wizardState.setOperationJsonField(
                                    [
                                        "config",
                                        "migrateops_local_data_migration",
                                        "destination",
                                        "default_auto_allocation_volume_params",
                                        "vmware",
                                        "provisioning",
                                    ],
                                    e.target.value
                                );
                            }}
                        >
                            {provisioningOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Disk Mode"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={diskModeOptions.find((t) => t.type === defaultParams.diskMode)?.value}
                            value={currentParams?.vmware?.disk_mode}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    [
                                        "config",
                                        "migrateops_local_data_migration",
                                        "destination",
                                        "default_auto_allocation_volume_params",
                                        "vmware",
                                        "disk_mode",
                                    ],
                                    e.target.value
                                );
                            }}
                        >
                            {diskModeOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"secondary"}
                                    checked={currentParams?.vmware?.sharing}
                                    disabled={currentParams?.vmware?.provisioning !== "THICK_EAGER_ZEROED"}
                                    onChange={(e) => {
                                        wizardState.setOperationJsonField(
                                            [
                                                "config",
                                                "migrateops_local_data_migration",
                                                "destination",
                                                "default_auto_allocation_volume_params",
                                                "vmware",
                                                "sharing",
                                            ],
                                            e.target.checked
                                        );
                                    }}
                                />
                            }
                            label={"Sharing"}
                        />
                        <FormHelperText>
                            {"If checked, multi-writer mode will be used when allocating new volume. Only applicable for Thick Eager Zeroed provisioning."}
                        </FormHelperText>
                    </Box>
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// NutanixParams
// ======================

interface NutanixParamsProps {
    defaultParams: AutoAlloc.VolumeParams.Nutanix.AsObject;
}

export const NutanixParams: React.FC<NutanixParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.nutanix) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                nutanix: {},
            });
        }
    });

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.nutanix?.storage_container_name}
                        label={"Storage Container Name"}
                        helperText={`If not specified, disks will be created in same storage container as the virtual machine.`}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "nutanix",
                                    "storage_container_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"secondary"}
                                    checked={currentParams?.nutanix?.native_vdisk}
                                    onChange={(e) => {
                                        wizardState.setOperationJsonField(
                                            [
                                                "config",
                                                "migrateops_local_data_migration",
                                                "destination",
                                                "default_auto_allocation_volume_params",
                                                "nutanix",
                                                "native_vdisk",
                                            ],
                                            e.target.checked
                                        );
                                    }}
                                />
                            }
                            label={"Native VDisk"}
                        />
                        <FormHelperText>
                            {
                                "If specified, the volume will be created as a disk directly attached to the VM instead of a volume group virtual disk. You will not be able to share these disks with other VMs."
                            }
                        </FormHelperText>
                    </Box>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.nutanix?.volume_group_name}
                        label={"Volume Group Name"}
                        helperText={`Optionally customize name of the volume group. Only applicable if native_vdisk is false. If not specified or if the group does not exist, a new volume group will be created for the disk. If an existing group with the same name already exists, and this vm already has access to the vg, it will be reused.`}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "nutanix",
                                    "volume_group_name",
                                ],
                                e.target.value
                            );
                        }}
                    />
                </Stack>
            </Card>
        </Box>
    );
};

// ======================
// OvirtParams
// ======================

interface OvirtParamsProps {
    defaultParams: AutoAlloc.VolumeParams.Ovirt.AsObject;
}

export const OvirtParams: React.FC<OvirtParamsProps> = (p) => {
    const { defaultParams } = p;
    const wizardState = useMigrateOpsLocalDataMigrationWizardState();
    const currentParams = wizardState.operationJson.config.migrateops_local_data_migration.destination.default_auto_allocation_volume_params;

    useMountEffect(() => {
        if (!currentParams?.ovirt) {
            wizardState.setOperationJsonField(["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params"], {
                ovirt: {},
            });
        }
    });

    const interfaceOptions = [
        {
            type: AutoAlloc.VolumeParams.Ovirt.DiskInterface.VIRTIO_SCSI,
            value: "VIRTIO_SCSI",
            label: "Virtio SCSCI",
        },
        {
            type: AutoAlloc.VolumeParams.Ovirt.DiskInterface.VIRTIO,
            value: "VIRTIO",
            label: "Virtio",
        },
    ];

    return (
        <Box>
            <Card elevation={0}>
                <Stack p={2} direction={"column"} spacing={2}>
                    <TextField
                        fullWidth
                        variant={"filled"}
                        value={currentParams?.ovirt?.storage_domain}
                        label={"Storage Domain"}
                        helperText={`If not specified, volume will be created in same storage domain as the VM.`}
                        onChange={(e) => {
                            wizardState.setOperationJsonField(
                                [
                                    "config",
                                    "migrateops_local_data_migration",
                                    "destination",
                                    "default_auto_allocation_volume_params",
                                    "ovirt",
                                    "storage_domain",
                                ],
                                e.target.value
                            );
                        }}
                    />
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={"secondary"}
                                    checked={currentParams?.ovirt?.sparse}
                                    onChange={(e) => {
                                        wizardState.setOperationJsonField(
                                            [
                                                "config",
                                                "migrateops_local_data_migration",
                                                "destination",
                                                "default_auto_allocation_volume_params",
                                                "ovirt",
                                                "sparse",
                                            ],
                                            e.target.checked
                                        );
                                    }}
                                />
                            }
                            label={"Sparse"}
                        />
                        <FormHelperText>{"If specified, sparse provisioning will be used when creating the disk."}</FormHelperText>
                    </Box>
                    <FormControl fullWidth>
                        <InputLabel variant={"filled"}>{"Interface"}</InputLabel>
                        <Select
                            variant={"filled"}
                            defaultValue={interfaceOptions.find((t) => t.type === defaultParams.pb_interface)?.value}
                            value={currentParams?.ovirt?.interface}
                            onChange={(e) => {
                                wizardState.setOperationJsonField(
                                    ["config", "migrateops_local_data_migration", "destination", "default_auto_allocation_volume_params", "ovirt", "interface"],
                                    e.target.value
                                );
                            }}
                        >
                            {interfaceOptions.map((v) => {
                                return (
                                    <MenuItem key={v.value} value={v.value}>
                                        {v.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </Card>
        </Box>
    );
};

export const renderAutoAllocParametersOptions = (module: IntegrationModule, defaultParams: AutoAlloc.VolumeParams.AsObject) => {
    if (module === IntegrationModule.AWS) {
        return <AwsParams defaultParams={defaultParams.aws} />;
    }
    if (module === IntegrationModule.PURE || module === IntegrationModule.FC_PURE) {
        return <PureCbsParams defaultParams={defaultParams.purecbs} />;
    }
    if (module === IntegrationModule.AZURE) {
        return <AzureParams defaultParams={defaultParams.azure} />;
    }
    if (module === IntegrationModule.DGS) {
        return <DgsParams defaultParams={defaultParams.dgs} />;
    }
    if (module === IntegrationModule.NETAPP || module === IntegrationModule.AWS_FSX_NETAPP) {
        return <NetAppParams defaultParams={defaultParams.netapp} />;
    }
    if (module === IntegrationModule.POWERSTORE || module === IntegrationModule.FC_POWERSTORE) {
        return <PowerStoreParams defaultParams={defaultParams.powerstore} />;
    }
    if (module === IntegrationModule.FC_POWERMAX) {
        return <PowerMaxParams defaultParams={defaultParams.powermax} />;
    }
    if (module === IntegrationModule.GCP) {
        return <GcpParams defaultParams={defaultParams.gcp} />;
    }
    if (module === IntegrationModule.ORACLE) {
        return <OracleParams defaultParams={defaultParams.oracle} />;
    }
    if (module === IntegrationModule.SILKSDP) {
        return <SilkSdpParams defaultParams={defaultParams.silksdp} />;
    }
    if (module === IntegrationModule.DIGITALOCEAN) {
        return <DigitalOceanParams defaultParams={defaultParams.digitalocean} />;
    }
    if (module === IntegrationModule.POWERFLEX) {
        return <PowerFlexParams defaultParams={defaultParams.powerflex} />;
    }
    if (module === IntegrationModule.AZURESAN) {
        return <AzureSanParams defaultParams={defaultParams.azuresan} />;
    }
    if (module === IntegrationModule.VMWAREDATASTORE) {
        return <VmwareParams defaultParams={defaultParams.vmware} />;
    }
    if (module === IntegrationModule.NUTANIX) {
        return <NutanixParams defaultParams={defaultParams.nutanix} />;
    }
    if (module === IntegrationModule.OVIRT) {
        return <OvirtParams defaultParams={defaultParams.ovirt} />;
    }
    return null;
};
