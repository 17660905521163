import { useAppServices } from "../../app/services";
import { GmMigrationAutoAllocationState } from "../GmMigrationService";

export const useGmAutoAllocationState = () => {
    const { gmMigrationService } = useAppServices();
    return gmMigrationService.autoAllocationState;
};

export interface VendorAllocateVolumesStepProps {
    allocateFunc: () => Promise<void>;
    state: GmMigrationAutoAllocationState;
}
