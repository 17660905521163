// Project: GalaxyComplete
// Created: 9/25/20 by sammy
// File: misc

import * as React from "react";
import { LightModeTheme } from "../modules/app/AppTheme";
import { observer } from "mobx-react-lite";
import { Divider, DividerProps } from "@mui/material";
import { useAppServices } from "../modules/app/services";

// ======================
// LightDivider
// ======================
interface LightDividerProps {}

export const LightDivider: React.FC<LightDividerProps & Partial<DividerProps>> = (p) => {
    return (
        <LightModeTheme>
            <Divider {...p} />
        </LightModeTheme>
    );
};

export const useDownloadFileFromURL = () => {
    const { dialogService } = useAppServices();

    const downloadFile = async (url: string, fileName: string) => {
        const req = new Request(url, {
            method: "GET",
        });
        try {
            const response = await fetch(req);
            if (!response.ok) {
                throw new Error("Failed to download file.");
            }

            //create blog and download link from file
            const blob = await response.blob();
            const downloadLink = URL.createObjectURL(blob);

            //create hidden a element and download file from it
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            a.href = downloadLink;
            a.setAttribute("download", fileName);
            a.click();

            //clean up
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        } catch (e) {
            return await dialogService.addErrorAlertDialog(e as Error);
        }
    };

    return downloadFile;
};
