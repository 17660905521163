// ======================
// OutlinedCard
// ======================

import { Card, CardProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

interface OutlinedCardProps {
    children: React.ReactNode;
    cardProps?: Partial<CardProps>;
    sx?: SxProps<Theme>;
}

export const OutlinedCard: React.FC<OutlinedCardProps> = (p) => {
    const { children, cardProps, sx } = p;
    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: (t: Theme) => t.palette.cirrus.light,
                borderWidth: 2,
                borderStyle: "solid",
                borderColor: (t: Theme) => t.palette.border.main,
                ...sx,
            }}
            {...cardProps}
        >
            {children}
        </Card>
    );
};
