import { GRPCServices } from "../grpc/grpcapi";
import { DialogService } from "../core/dialog/DialogService";
import { ProgressService } from "../core/progress/ProgressService";
import { makeAutoObservable } from "mobx";
import { ServerData } from "../core/data/ServerData";
import { GetProjectDetails, ListProjects } from "gc-web-proto/galaxycompletepb/apipb/project_api_pb";
import { ServerListData } from "../core/data/ListData";
import { ProjectDetails, ProjectListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/project_pb";
import { FilterParams, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { getProjectListFilterConfig } from "./ProjectCommon";

export class ProjectService {
    private api: GRPCServices;
    dialogService: DialogService;
    progressService: ProgressService;

    myProjects = new ServerListData<ListProjects.Response.AsObject, ProjectListItem.AsObject, ListProjects.Request>(
        10,
        getProjectListFilterConfig()
    ).setDataFetcher(this.fetchMyProjects.bind(this));
    currentProject = new ServerData<ProjectDetails>().setDataFetcher(this.fetchCurrentProjectDetails.bind(this));

    constructor(api: GRPCServices, dialogService: DialogService, progressService: ProgressService) {
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
        makeAutoObservable(this);
    }

    async fetchMyProjects(isAdmin: boolean = false, pagerParams?: PagerParams) {
        let request = new ListProjects.Request()
            .setPageParams(pagerParams || this.myProjects.pagerParam)
            .setSortParams(this.myProjects.sortParam || null)
            .setIsAdmin(isAdmin);

        this.myProjects.addFilterParamsToRequest(request);
        request.addFilterParams(
            new ListProjects.Request.Filter().setIsSupporting(new FilterParams.BoolFilter().setValue(false).setOp(FilterParams.BoolFilter.Op.EQUAL))
        );
        //return mockProjectsListData2.toObject()

        const res = await this.api.projectService.listProjects(request, null);
        return res.toObject();
    }

    async fetchCurrentProjectDetails(projectId?: string) {
        projectId = projectId || this.currentProjectID;
        const request = new GetProjectDetails.Request().setProjectId(projectId);
        const res = await this.api.projectService.getProjectDetails(request, null);
        return res.getProjectDetails();
    }

    clearCurrentProject() {
        this.currentProject.resetData();
    }

    get currentProjectID() {
        return this.currentProject.ready ? this.currentProject.data.getInfo().getProjectId() : null;
    }
}
