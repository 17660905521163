// ======================
// CmoMigrationSystemDetailsScreen
// ======================

import { useParams } from "react-router-dom";
import { ScreenContainer } from "../../layout/ScreenCommon";
import { CmoSystemHealthIconButton, useNavigateToCmoSystemsList } from "../CmoMigrationCommon";
import { BackButton } from "../../../common/CommonButtons";
import { useGetCmoIoStats, useGetCmoSystemDetails } from "../cmo_migration_hooks";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { Stack, Typography } from "@mui/material";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { CmoMigrationSystemDetailsApplianceNodesTab } from "./CmoMigrationSystemDetailsApplianceNodesTab";
import { CmoMigrationSystemDetailsOverviewTab } from "./CmoMigrationSystemDetailsOverviewTab";
import { CmoMigrationSystemDetailsVolumesTab } from "./CmoMigrationSystemDetailsVolumesTab";
import { CmoMigrationSystemDetailsActivitiesTab } from "./CmoMigrationSystemDetailsActivitiesTab";
import { GetCmoIOStats } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/cmo_api_pb";
import { CmoMigrationSystemDetailsMigrationSessionsTab } from "./CmoMigrationSystemDetailsMigrationSessionsTab";
import Grid from "@mui/material/Grid2";

interface CmoMigrationSystemDetailsScreenProps {}

export const CmoMigrationSystemDetailsScreen: React.FC<CmoMigrationSystemDetailsScreenProps> = (p) => {
    const { projectId, systemId } = useParams();
    const goToCmoSystemsList = useNavigateToCmoSystemsList();
    const cmoSystemDetails = useGetCmoSystemDetails(systemId);
    const cmoIoStats = useGetCmoIoStats(new GetCmoIOStats.Request().setSystemId(systemId));

    const tabConfig: TabConfig[] = [
        {
            label: "Overview",
            renderer: () => <CmoMigrationSystemDetailsOverviewTab ioStats={cmoIoStats} systemInfo={cmoSystemDetails.data?.system.info} projectId={projectId} />,
        },
        {
            label: "Appliance Nodes",
            renderer: () => <CmoMigrationSystemDetailsApplianceNodesTab systemInfo={cmoSystemDetails.data?.system.info} />,
        },
        {
            label: "Inserted Volumes",
            renderer: () => <CmoMigrationSystemDetailsVolumesTab systemId={systemId} />,
        },
        {
            label: "Migration Sessions",
            renderer: () => <CmoMigrationSystemDetailsMigrationSessionsTab projectId={projectId} systemId={systemId} />,
        },
        {
            label: "Activities",
            renderer: () => <CmoMigrationSystemDetailsActivitiesTab systemId={systemId} connected={cmoSystemDetails.data?.system.info.deployment.connected} />,
        },
    ];

    return (
        <ScreenContainer>
            <BackButton navFunction={goToCmoSystemsList} label={"Back To CMO Systems"} />
            <QueryResultWrapper queryResult={cmoSystemDetails}>
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography variant={"h4"}>{cmoSystemDetails.data?.system.info.deployment.systemName}</Typography>
                    <CmoSystemHealthIconButton
                        healthStatus={cmoSystemDetails.data?.system.info.healthStatus}
                        systemId={cmoSystemDetails.data?.system.info.deployment.systemId}
                        iconProps={{ size: 22 }}
                    />
                </Stack>
                <Grid container spacing={1}>
                    <Grid>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {`Registered at ${formatKnownDataType(
                                cmoSystemDetails.data?.system.info.deployment.registeredAt,
                                KnownDataType.DATE
                            )} (${formatKnownDataType(cmoSystemDetails.data?.system.info.deployment.registeredAt, KnownDataType.DATE_RELATIVE)})`}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {"·"}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {`Last check-in ${formatKnownDataType(cmoSystemDetails.data?.system.info.deployment.lastCheckin, KnownDataType.DATE_RELATIVE)}`}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {"·"}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography variant={"subtitle2"} color={"textSecondary"}>
                            {cmoSystemDetails.data?.system.info.primaryAppliance.insertionMethod}
                        </Typography>
                    </Grid>
                </Grid>
                <TabGroup configs={tabConfig} />
            </QueryResultWrapper>
        </ScreenContainer>
    );
};
