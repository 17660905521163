// ======================
// AzureStoragePlannerDescriptionStep
// ======================

import { AzureStoragePlannerStepProps, getAzureRegionDisplayName } from "./AzureStoragePlannerHelpers";
import { Box, Stack, Typography } from "@mui/material";
import { AzureStoragePlannerDescriptionFormFields, useAzureStoragePlannerState } from "./AzureStoragePlannerState";
import * as React from "react";
import * as yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { FormSelect, FormSelectableCardConfig, FormSelectableCardsGroup, FormTextField } from "../../../common/form/FormComponents";
import ManagedDisksOnly from "../../../assets/azure/managed_disks.png";
import ElasticSanAndManagedDisks from "../../../assets/azure/elastic_san_and_managed_disks.png";
import { AzureRegion, AzureStorageProductRedundancy } from "gc-web-proto/galaxycompletepb/apipb/domainpb/azure_pb";
import { StepperNavButtons } from "../../../common/stepper/StepperComponents";

import { useMountEffect } from "../../../common/hooks/hookslib";

export const AzureStoragePlannerDescriptionStep: React.FC<AzureStoragePlannerStepProps> = (p) => {
    const plannerState = useAzureStoragePlannerState();
    const initialValues = plannerState.descriptionFields;

    useMountEffect(() => {
        if (!plannerState.descriptionFields.planName) {
            if (plannerState.descriptionFieldsSubmitted) {
                plannerState.setDescriptionFieldsSubmitted(false);
            }
        }
    });

    const schema = yup.object({
        planName: yup.string().required("Plan Name is required"),
        planDescription: yup.string(),
        analyzePeriodMinutes: yup.number().required(),
        storageGrowthPercent: yup.number().required(),
        workloadGrowthPercent: yup.number().required(),
        region: yup.number().required("Region is required"),
        storageRedundancy: yup.number().required(),
        noElasticSan: yup.boolean().required(),
    });

    const ioAccessHistoryOptions: FormSelectableCardConfig[] = [
        {
            title: <Typography>{"15 Minutes"}</Typography>,
            value: 15,
            mainContentPadding: 0,
            cardProps: {
                sx: {
                    width: 150,
                },
            },
        },
        {
            title: <Typography>{"1 Day"}</Typography>,
            value: 1440,
            mainContentPadding: 0,
            cardProps: {
                sx: {
                    width: 150,
                },
            },
        },
        {
            title: <Typography>{"7 Days"}</Typography>,
            value: 1440 * 7,
            mainContentPadding: 0,
            cardProps: {
                sx: {
                    width: 150,
                },
            },
        },
    ];

    const workloadGrowthValues = [-25, -10, 0, 10, 25];
    const workloadGrowthOptions: FormSelectableCardConfig[] = workloadGrowthValues.map((n) => {
        return {
            title: <Typography>{!!n ? `${n}%` : "No Change"}</Typography>,
            value: n,
            mainContentPadding: 0,
            cardProps: {
                sx: {
                    width: 150,
                },
            },
        };
    });

    const storageGrowthValues = [0, 10, 25, 50, 100];

    const storageGrowthOptions: FormSelectableCardConfig[] = storageGrowthValues.map((n) => {
        return {
            title: <Typography>{!!n ? `${n}%` : "No Change"}</Typography>,
            value: n,
            mainContentPadding: 0,
            cardProps: {
                sx: {
                    width: 150,
                },
            },
        };
    });

    const azureStorageProductOptions: FormSelectableCardConfig[] = [
        {
            icon: <img height={100} src={ElasticSanAndManagedDisks} alt={"Azure Elastic SAN + Azure Managed Disks"} />,
            title: "Azure Elastic SAN + Azure Managed Disks",
            value: false,
        },
        {
            icon: <img height={100} src={ManagedDisksOnly} alt={"Azure Managed Disks Only"} />,
            title: "Azure Managed Disks Only",
            value: true,
        },
    ];

    const azureRegionSelectionList = Object.keys(AzureRegion).map((key) => {
        return {
            label: getAzureRegionDisplayName(key as keyof AzureRegion),
            value: AzureRegion[key as keyof typeof AzureRegion],
        };
    });

    const submit = async (values: AzureStoragePlannerDescriptionFormFields, formikHelpers: FormikHelpers<AzureStoragePlannerDescriptionFormFields>) => {
        plannerState.setDescriptionFields(values);
        plannerState.setDescriptionFieldsSubmitted(true);
    };

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
            {(props) => {
                const getContinueDisabled = () => {
                    return plannerState.descriptionFieldsSubmitted ? false : !props.isValid || !props.dirty;
                };
                return (
                    <>
                        <Typography variant={"h5"}>{`1. Description`}</Typography>
                        <Typography>{`Please provide a brief summary of the consumption planner for future reference.`}</Typography>
                        <Box pt={2} pb={2}>
                            <Box pb={2}>
                                <FormTextField variant={"filled"} required placeholder={"Enter Plan Name"} label={"Plan Name"} name={"planName"} fullWidth />
                            </Box>
                            <Box>
                                <FormTextField variant={"filled"} label={"Plan Description"} fullWidth name={"planDescription"} />
                            </Box>
                        </Box>
                        <Typography variant={"h5"}>{`2. Preferences`}</Typography>
                        <Typography>{`Please select the following that best fits your criteria:`}</Typography>
                        <Stack pt={2} pb={2} spacing={2}>
                            <Typography variant={"h6"}>{`a. How much I/O access history should we analyze?`}</Typography>
                            <FormSelectableCardsGroup options={ioAccessHistoryOptions} name={"analyzePeriodMinutes"} />
                        </Stack>
                        <Stack pt={2} pb={2} spacing={2}>
                            <Typography variant={"h6"}>{`b. How do you expect your workload to grow after migration?`}</Typography>
                            <FormSelectableCardsGroup options={workloadGrowthOptions} name={"workloadGrowthPercent"} />
                        </Stack>
                        <Stack pt={2} pb={2} spacing={2}>
                            <Typography variant={"h6"}>{`c. How much additional storage capacity do you need for your new destination volumes?`}</Typography>
                            <FormSelectableCardsGroup options={storageGrowthOptions} name={"storageGrowthPercent"} />
                        </Stack>
                        <Stack pt={2} pb={2} spacing={2}>
                            <Typography variant={"h6"}>{`d. Which Azure Storage Product do you want to include?`}</Typography>
                            <FormSelectableCardsGroup options={azureStorageProductOptions} name={"noElasticSan"} />
                        </Stack>
                        <Box pt={2} pb={2}>
                            <Box pb={2}>
                                <FormSelect name={"region"} label={"Region"} selectionList={azureRegionSelectionList} />
                            </Box>
                            <Box>
                                <FormSelect
                                    name={"storageRedundancy"}
                                    label={"Redundancy"}
                                    selectionList={[
                                        {
                                            label: "Locally Redundant Storage (LRS)",
                                            value: AzureStorageProductRedundancy.AZURE_STORAGE_REDUNDANCY_LOCAL,
                                        },
                                        {
                                            label: "Zone Redundant Storage (ZRS)",
                                            value: AzureStorageProductRedundancy.AZURE_STORAGE_REDUNDANCY_ZONAL,
                                        },
                                    ]}
                                />
                            </Box>
                        </Box>
                        <StepperNavButtons
                            nextButtonProps={{
                                disabled: getContinueDisabled(),
                                onClick: props.submitForm,
                            }}
                        />
                    </>
                );
            }}
        </Formik>
    );
};
