import { observer } from "mobx-react-lite";
import { Alert, Box, Card, CardContent, ListSubheader, Typography, useTheme } from "@mui/material";
import { HostLicenseInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import {
    DEFAULT_LICENSE_TIME_LIMIT_IN_SECONDS,
    getIsHostLicenseExpired,
    getLicenseCapacityColor,
    getLicenseDurationLeftString,
    getLicenseExpirationDateColor,
} from "./HostLicenseCommon";
import { MdSettings } from "react-icons/md";
import { ActionConfig, ActionMenuButton } from "../../../common/actions/CommonActions";
import { LicenseIcon } from "../../../common/CommonIcons";
import * as React from "react";
import { useAppServices } from "../../app/services";
import { HostBasedLicenseView, LicensingLearnMoreLink } from "../../license/LicenseCommon";
import { useDeleteHostLicense } from "../../deployment/deployment_hooks";
import Grid from "@mui/material/Grid2";

// ======================
// HostLicenseInfoCard
// ======================

interface HostLicenseInfoCardProps {
    data: HostLicenseInfo;
}

export const GalaxyMigrateDeploymentHostLicenseInfoCard: React.FC<HostLicenseInfoCardProps> = observer((p) => {
    const { deploymentService } = useAppServices();
    const t = useTheme();

    if (!p.data) {
        return null;
    }

    const deleteLicense = useDeleteHostLicense("details");

    const capacityPercentage =
        (p.data.getMigrationCapacityRemaining() / (p.data.getMigrationCapacityConsumed() + p.data.getMigrationCapacityRemaining())) * 100;
    const timePercentage = (p.data.getTimeRemaining().getSeconds() / DEFAULT_LICENSE_TIME_LIMIT_IN_SECONDS) * 100;

    const isLicenseExpired = getIsHostLicenseExpired(p.data.getExpireAt().toDate());

    const getLicenseActions = (): ActionConfig[] => {
        return [
            {
                id: "delete-license",
                name: "Delete Current Host License",
                action: async () => await deleteLicense(deploymentService.currentDeployment.data.getInfo().getDeployment().toObject()),
                icon: <LicenseIcon />,
            },
        ];
    };

    return (
        <HostBasedLicenseView>
            {isLicenseExpired && (
                <Box pb={2}>
                    <Alert severity={"error"}>
                        Warning! Host Migration License has expired at {formatKnownDataType(p.data.getExpireAt().toDate(), KnownDataType.DATE)}. Cirrus Migrate
                        Cloud on this host is now operating in limited mode (grace period).
                        <LicensingLearnMoreLink />
                    </Alert>
                </Box>
            )}
            <Box pb={2}>
                <Card>
                    <ListSubheader>Host License Status</ListSubheader>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid
                                size={{
                                    xs: 12,
                                    lg: 6,
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid>
                                        <Box maxWidth={220}>
                                            <CircularProgressbarWithChildren
                                                value={capacityPercentage}
                                                strokeWidth={3}
                                                styles={buildStyles({
                                                    pathColor: getLicenseCapacityColor(p.data.getMigrationCapacityRemaining(), t),
                                                    trailColor: t.palette.cirrus.main,
                                                })}
                                            >
                                                <Box textAlign={"center"}>
                                                    <Typography variant={"h5"} color={getLicenseCapacityColor(p.data.getMigrationCapacityRemaining(), t, true)}>
                                                        {formatKnownDataType(p.data.getMigrationCapacityRemaining(), KnownDataType.CAPACITY)}
                                                    </Typography>
                                                    <Typography variant={"overline"}>Capacity Remaining</Typography>
                                                </Box>
                                            </CircularProgressbarWithChildren>
                                        </Box>
                                    </Grid>
                                    <Grid>
                                        <Box maxWidth={220}>
                                            <CircularProgressbarWithChildren
                                                value={timePercentage}
                                                strokeWidth={3}
                                                styles={buildStyles({
                                                    pathColor: getLicenseExpirationDateColor(p.data.getExpireAt().toDate(), t),
                                                    trailColor: t.palette.cirrus.main,
                                                })}
                                            >
                                                <Box textAlign={"center"}>
                                                    <Typography variant={"h5"} color={getLicenseExpirationDateColor(p.data.getExpireAt().toDate(), t, true)}>
                                                        {getLicenseDurationLeftString(p.data.getExpireAt().toDate())}
                                                        {isLicenseExpired && (
                                                            <>
                                                                <br />
                                                                {formatKnownDataType(p.data.getExpireAt().toDate(), KnownDataType.DATE_RELATIVE)}
                                                            </>
                                                        )}
                                                    </Typography>
                                                    <Typography variant={"overline"}>Time Remaining</Typography>
                                                    <br />
                                                    <Typography
                                                        variant={"overline"}
                                                        color={getLicenseExpirationDateColor(p.data.getExpireAt().toDate(), t, true)}
                                                    >
                                                        {formatKnownDataType(p.data.getExpireAt().toDate(), KnownDataType.DATE)}
                                                    </Typography>
                                                </Box>
                                            </CircularProgressbarWithChildren>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    lg: 6,
                                }}
                            >
                                <Box width={"100%"}>
                                    <Box pb={2} width={"100%"}>
                                        <Card sx={{ backgroundColor: t.palette.cirrus.main }}>
                                            <CardContent>
                                                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Box>
                                                        <Typography variant={"overline"} color={isLicenseExpired ? "error" : t.palette.success.main}>
                                                            {isLicenseExpired &&
                                                                `License expired on ${formatKnownDataType(p.data.getExpireAt().toDate(), KnownDataType.DATE)}`}
                                                            {!isLicenseExpired && "License Activated"}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <ActionMenuButton title={"Host License Actions"} actions={getLicenseActions()} icon={<MdSettings />} />
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                sm: 6,
                                            }}
                                        >
                                            <Card sx={{ backgroundColor: t.palette.cirrus.main }}>
                                                <CardContent>
                                                    <Typography variant={"overline"} color={"textPrimary"}>
                                                        {formatKnownDataType(p.data.getActivatedAt().toDate(), KnownDataType.DATE)}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        License Activated
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                sm: 6,
                                            }}
                                        >
                                            <Card sx={{ backgroundColor: t.palette.cirrus.main }}>
                                                <CardContent>
                                                    <Typography variant={"overline"} color={"textPrimary"}>
                                                        {formatKnownDataType(p.data.getMigrationCapacityConsumed(), KnownDataType.CAPACITY)}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        Capacity Consumed
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </HostBasedLicenseView>
    );
});
