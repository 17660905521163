// ======================
// CreateNewIntegration
// ======================

import { observer } from "mobx-react-lite";
import {
    getIntegrationModuleFromRoute,
    IntegrationRedirectAlert,
    INTEGRATIONS_TYPE_SUBROUTE,
    useNavigateToEnabledIntegrations,
    useNavigateToIntegrations,
} from "../IntegrationsCommon";
import { useParams } from "react-router-dom";
import { useAppServices } from "../../app/services";
import { IntegrationForm } from "./IntegrationForm";
import React from "react";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { IntegrationParams } from "gc-web-proto/galaxycompletepb/apipb/integration_api_pb";
import { useGmAutoAllocationState } from "../../cmcMigration/autoallocation/GmAutoAllocationCommon";
import { v4 as uuid } from "uuid";
import { useAddProjectIntegration } from "../integration_hooks";
import { useIntegrationRedirectState } from "../IntegrationRedirectState";

interface CreateNewIntegrationProps {}

export const CreateNewIntegration: React.FC<CreateNewIntegrationProps> = observer((p) => {
    const goBackToMainIntegrationsScreen = useNavigateToIntegrations();
    const goToEnabledIntegrations = useNavigateToEnabledIntegrations();

    const { integrationType } = useParams();
    const { deploymentService } = useAppServices();
    const redirectState = useIntegrationRedirectState();
    const autoAllocationState = useGmAutoAllocationState();
    const addProjectIntegration = useAddProjectIntegration();
    const type = getIntegrationModuleFromRoute(integrationType as INTEGRATIONS_TYPE_SUBROUTE);

    const onSubmit = async (
        module: IntegrationModule,
        friendlyName: string,
        deploymentId: string,
        connParams?: IntegrationParams.ConnParams,
        verboseLogging?: boolean
    ) => {
        const statusId = uuid();
        if (!!deploymentId) {
            autoAllocationState.setNewStatusId(statusId);
            const deploymentDetails = await deploymentService.fetchDeploymentDetails(deploymentId);
            autoAllocationState.setDeployment(deploymentDetails);
            autoAllocationState.setShowProgressDialog(true);
            await addProjectIntegration.mutateAsync({
                module,
                friendlyName,
                deploymentId,
                statusId,
                connParams,
                verboseLogging,
            });
            await autoAllocationState.waitForProgressDialogToClose();
        } else {
            await addProjectIntegration.mutateAsync({
                module,
                friendlyName,
                deploymentId,
                statusId,
                connParams,
                verboseLogging,
            });
        }
    };

    const onSubmitted = () => {
        if (!!redirectState.preselectedHost) {
            redirectState.redirectFunc();
            redirectState.resetIntegrationRedirectState();
        } else {
            goToEnabledIntegrations();
        }
    };

    return (
        <>
            {!!redirectState && <IntegrationRedirectAlert alertConfig={redirectState.alertConfig} boxProps={{ pb: 2, pr: 2, pl: 2 }} />}
            <IntegrationForm onCancel={goBackToMainIntegrationsScreen} onSubmit={onSubmit} newIntegrationType={type} onSubmitted={onSubmitted} />
        </>
    );
});
