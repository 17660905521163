// ======================
// GeneratedLicenseRequestSection
// ======================

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigateToExternalLink } from "../help/HelpCommon";
import { useNavigateToProjectLicenseDetailsScreen } from "../management/ProjectManagementCommon";
import {
    useDiscardPendingPeLicenseRequest,
    useGetPendingPeLicenseRequest,
    useGetPrivateEditionLocalStatus,
    useSubmitPeLicenseReceipt,
} from "./private_edition_hooks";
import { SubmitPeLicenseReceipt } from "gc-web-proto/galaxycompletepb/apipb/pelocal_api_pb";
import { QueryResultWrapper } from "../core/data/QueryResultWrapper";
import { Alert, Box, Button, Card, Link, TextField, Typography } from "@mui/material";
import { PeLicenseInteractionType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/pe_license_interaction_type_pb";
import { TransactionCounterInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/license_pb";

import { getCounterTypeDisplayValue, getLicenseValueFormat } from "../license/LicenseCommon";
import { ClipboardButton, ClipboardText } from "../../common/clipboard/ClipboardComponents";
import { ScreenContainer, ScreenTitleBar } from "../layout/ScreenCommon";
import { getCdcSaaSUrlForThisPE, getPeLicenseInteractionTypeDisplayValue } from "./PrivateEditionCommon";
import { useGlobalDialogState } from "../core/dialog/GlobalDialogState";
import xbytes from "xbytes";
import { LicenseVaultCounterType } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/license_vault_counter_type_pb";

interface PrivateEditionLocalPendingRequestScreenProps {}

export const PrivateEditionLocalPendingRequestScreen: React.FC<PrivateEditionLocalPendingRequestScreenProps> = observer((p) => {
    const pendingLicenseRequest = useGetPendingPeLicenseRequest();
    const peLocalStatus = useGetPrivateEditionLocalStatus();
    const navToLicensePage = useNavigateToProjectLicenseDetailsScreen();
    return (
        <ScreenContainer>
            <QueryResultWrapper queryResult={pendingLicenseRequest}>
                <ScreenTitleBar
                    title={`License ${getPeLicenseInteractionTypeDisplayValue(
                        pendingLicenseRequest.data?.licenseRequest?.interactionType,
                        peLocalStatus.data?.status?.licenseActivated
                    )} Request`}
                />
                <PrivateEditionLocalPendingLicenseRequestTxBox onTxFinished={() => navToLicensePage()} />
            </QueryResultWrapper>
        </ScreenContainer>
    );
});

interface PrivateEditionLocalPendingLicenseRequestTxBoxProps {
    // callback after tx finished. includes both discard and submit
    onTxFinished: () => void;
}

export const PrivateEditionLocalPendingLicenseRequestTxBox = (p: PrivateEditionLocalPendingLicenseRequestTxBoxProps) => {
    const globalDialogState = useGlobalDialogState();

    const pendingLicenseRequest = useGetPendingPeLicenseRequest();
    const peLocalStatus = useGetPrivateEditionLocalStatus();

    const submitReceipt = useSubmitPeLicenseReceipt();
    const discardRequest = useDiscardPendingPeLicenseRequest();

    const cdcProjectName = peLocalStatus?.data?.status?.cdcProjectName;
    const cdcUrl = getCdcSaaSUrlForThisPE(peLocalStatus?.data?.status);
    const navigateToCDC = useNavigateToExternalLink(cdcUrl);

    const [receipt, setReceipt] = useState("");

    const licenseRequestClipboardID = "generatedRequest";

    const canDiscard = pendingLicenseRequest.data?.licenseRequest?.interactionType !== PeLicenseInteractionType.PeLicenseInteractionType.CHECK_IN;

    const handleSubmitReceipt = async () => {
        await submitReceipt
            .mutateAsync(new SubmitPeLicenseReceipt.Request().setReceipt(receipt))
            .then(() => {
                if (p.onTxFinished) {
                    p.onTxFinished();
                }
            })
            .catch((e) => {});
    };

    const handleDiscardRequest = async () => {
        const confirmed = await globalDialogState.addConfirmDialog({
            title: "Discard License Request",
            message: (
                <Box pb={2}>
                    <Typography>
                        {
                            "If your license request has already been submitted to Cirrus Data Cloud, DO NOT discard this request because your license has already been consumed. Stay on the screen and contact support if you need any assistance."
                        }
                    </Typography>
                </Box>
            ),
        });

        if (confirmed) {
            await discardRequest
                .mutateAsync()
                .then(() => {
                    if (p.onTxFinished) {
                        p.onTxFinished();
                    }
                })
                .catch((e) => {});
        }
    };

    // if fetched and there is no license request, don't ever show an empty frame
    if (pendingLicenseRequest.isSuccess && !pendingLicenseRequest.data?.licenseRequest?.signedRequest) {
        return null;
    }

    const requestLicenseCountersCount = pendingLicenseRequest.data?.licenseRequest?.countersList.length || 0;

    return (
        <QueryResultWrapper queryResult={pendingLicenseRequest}>
            <Box pt={2}>
                {requestLicenseCountersCount > 0 && (
                    <>
                        <Typography>{"The following licenses will be consumed from your Cirrus Data Cloud project license:"}</Typography>
                        <br />
                        <Card variant={"outlined"}>
                            <Box p={2}>
                                <Box>
                                    <Typography variant={"overline"} color={"textSecondary"}>
                                        {pendingLicenseRequest.data?.licenseRequest?.description}
                                        {pendingLicenseRequest.data?.licenseRequest?.interactionType ===
                                        PeLicenseInteractionType.PeLicenseInteractionType.ACTIVATION
                                            ? `${getCounterTypeDisplayValue(
                                                  LicenseVaultCounterType.LicenseVaultCounterType.PRIVATE_EDITION_VIRTUAL_APPLIANCE
                                              )}: ${getLicenseValueFormat(
                                                  1,
                                                  LicenseVaultCounterType.LicenseVaultCounterType.PRIVATE_EDITION_VIRTUAL_APPLIANCE
                                              )}`
                                            : ""}
                                    </Typography>
                                </Box>
                                {pendingLicenseRequest.data?.licenseRequest?.countersList.map((c: TransactionCounterInfo.AsObject, i) => {
                                    return (
                                        <Box key={i}>
                                            <Typography key={i} variant={"overline"} color={"textSecondary"}>
                                                {getCounterTypeDisplayValue(c.counterType.value)}: {getLicenseValueFormat(c.amount, c.counterType.value)}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Card>
                        <br />
                    </>
                )}
                <Typography variant={"h5"}>1. Upload License Request to Cirrus Data Cloud</Typography>
                <Typography color={"textSecondary"}>
                    {"Copy the license request below and upload it to your project in Cirrus Data Cloud  "}
                    {"("}
                    <Link onClick={navigateToCDC}>{cdcProjectName || "https://cloud.cirrusdata.com"}</Link>
                    {`)`}
                </Typography>
                <br />

                <Box pb={2}>
                    <Card sx={{ backgroundColor: "#000000", width: "100%", wordBreak: "break-word" }}>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Box p={2}>
                                <ClipboardText clipboardId={licenseRequestClipboardID}>
                                    {pendingLicenseRequest.data?.licenseRequest?.signedRequest}
                                </ClipboardText>
                            </Box>
                            <Box p={2}>
                                <ClipboardButton clipboardId={licenseRequestClipboardID} iconButton />
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <Typography variant={"h5"}>2. Enter License Receipt</Typography>
                <Typography color={"textSecondary"}>
                    {`Once your license request has been submitted to Cirrus Data Cloud, please paste it below to complete the license transaction`}
                </Typography>
                <br />
                <TextField
                    fullWidth
                    multiline
                    minRows={20}
                    variant={"filled"}
                    label={"License Receipt"}
                    value={receipt}
                    onChange={(e) => {
                        setReceipt(e.target.value);
                    }}
                />
                <Box pt={2} pb={2}>
                    <Alert severity={"warning"}>
                        {" "}
                        {
                            "Warning: DO NOT power off or restart your virtual appliance while request is pending. Stay on the screen and contact support if you need any assistance."
                        }
                    </Alert>
                </Box>
                <Typography variant={"caption"} color={"textSecondary"}>
                    {
                        "If the license request generated is incorrect and it has not been processed by Cirrus Data Cloud, you can safely discard the request and re-create it."
                    }
                </Typography>
                <Box display={"flex"} justifyContent={"center"} pt={2}>
                    {canDiscard && (
                        <Box pr={1}>
                            <Button variant={"outlined"} color={"error"} onClick={handleDiscardRequest}>
                                {`Discard Request`}
                            </Button>
                        </Box>
                    )}
                    <Box pl={1}>
                        <Button variant={"contained"} onClick={handleSubmitReceipt} disabled={!receipt}>
                            {"Upload License Receipt"}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </QueryResultWrapper>
    );
};
